import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import fp from "lodash/fp"
import React from "react"
import { useTranslation } from "react-i18next"
import { useBoolean } from "usehooks-ts"

import { Option } from "types/graphql"
import { SourcePay } from "types/graphql.enums"
import {
  useBudgetedTotal,
  useFieldLabelHelpers,
  usePositionFieldArray,
} from "v2/react/components/positions/positionForm/hooks"
import { LegacyDateInput } from "v2/react/components/positions/positionForm/inputs"
import { usePositionFormContext } from "v2/react/components/positions/positionForm/PositionFormProvider"
import { CurrencyInput, SelectWithWrapper } from "v2/react/shared/forms"
import { Popover, PopoverContent, PopoverTrigger } from "v2/react/shared/overlay/Popover"
import { formatCurrency } from "v2/react/utils/currency"
import { idFromUniqueKey } from "v2/react/utils/uniqueKey"

import { BasePayFormFields } from "./planningTab/BasePayFormFields"
import { FormFundingSource } from "./planningTab/FormFundingSource"
import { CreateVariablePayPopover, VariablePayForm } from "./planningTab/VariablePayForm"

/** Based on app/views/v2/positions/form/position_management_tabs/_planning.html.slim */
function PlanningTab() {
  const { tLabel } = useFieldLabelHelpers()
  const {
    positionFormAbilities,
    positionFormCollections: { hiringPriorities, positionStatuses },
  } = usePositionFormContext()

  return (
    <div id="planning-tab" className="tab-panel p-4">
      <div className="mb-6 grid-cols-3 gap-4 grid">
        <SelectWithWrapper
          defaultValue
          id="position_position_position_status_id"
          label={tLabel("position.positionStatusId")}
          name="position.positionStatusId"
          options={positionStatuses}
          useInReactHookForm
        />
        <SelectWithWrapper
          defaultValue
          id="position_position_hiring_priority"
          label={tLabel("position.hiringPriority")}
          name="position.hiringPriority"
          options={hiringPriorities}
          useInReactHookForm
        />
        <LegacyDateInput
          id="position_projected_hire_date"
          label={tLabel("position.projectedHireDate")}
          name="position.projectedHireDate"
        />
      </div>

      {positionFormAbilities.canUpdateBudgetingData && <BudgetTable />}
      {positionFormAbilities.canUpdateBudgetingData && <FormFundingSource />}
    </div>
  )
}

/** Based on app/views/v2/shared/_budget_table.html.slim */
function BudgetTable() {
  const { t } = useTranslation()
  const { tLabel } = useFieldLabelHelpers()

  const {
    setTrue: showVariablePayPopover,
    setFalse: hideVariablePayPopover,
    setValue: setIsVariablePayPopoverShown,
    value: isVariablePayPopoverShown,
  } = useBoolean()

  const {
    positionFormAbilities: { canCreateBudgetedVariablePayType },
  } = usePositionFormContext()

  const { fields: variablePayFields, insert } = usePositionFieldArray({
    keyName: "formId", // Ensures react-hook-form doesn't overwrite actual id
    name: "position.variablePaysAttributes",
  })

  const handleAddVariablePayType = (option: Option) => {
    const payTypesByLabel = fp.map(fp.prop(["variablePayType", "label"]), variablePayFields)
    const targetIndex = fp.sortedIndex(option.label, payTypesByLabel)

    insert(targetIndex, {
      amount: null,
      payType: SourcePay.Amount,
      id: "",
      variablePayType: { ...option, id: idFromUniqueKey(option.id) },
    })

    hideVariablePayPopover()
  }

  const budgetedTotal = useBudgetedTotal()

  return (
    <div
      className="budget-table mb-4 rounded-md-sm border border-solid border-neutral-8 bg-transparent"
      data-model-type="Position"
    >
      <div className="budget-table__header items-center justify-between border-0 border-b border-solid border-neutral-8 p-4 flex">
        {t("v2.shared.budget_table.position.header_title")}

        <div className="items-center space-x-2 flex">
          {canCreateBudgetedVariablePayType ? (
            <Popover
              open={isVariablePayPopoverShown}
              placement="bottom-end"
              onOpenChange={setIsVariablePayPopoverShown}
            >
              <PopoverTrigger asChild>
                <button
                  className="btn--sm btn--secondary"
                  id="add-variable-pay"
                  onPointerUp={showVariablePayPopover}
                  type="button"
                >
                  <FontAwesomeIcon icon={["far", "plus"]} />
                  {t("v2.defaults.add")}
                </button>
              </PopoverTrigger>
              <PopoverContent className="z-50">
                <CreateVariablePayPopover
                  onAdd={handleAddVariablePayType}
                  onCancel={hideVariablePayPopover}
                />
              </PopoverContent>
            </Popover>
          ) : null}
        </div>
      </div>
      <div className="p-4">
        <div className="budget-table__row mb-4 items-start gap-4 grid sm:grid-cols-3">
          <div className="h-full truncate leading-10">{tLabel("position.positionBasePay")}</div>
          <div className="col-span-2 flex-col gap-4 flex sm:flex-row sm:items-start">
            <BasePayFormFields />
          </div>
        </div>

        <div className="variable-pays flex-col gap-4 flex">
          {variablePayFields.map((variablePay, index) => (
            <VariablePayForm
              index={index}
              key={variablePay.formId}
              entity={variablePay.variablePayType}
            />
          ))}
        </div>
      </div>
      <div className="budget-table__footer items-center gap-4 border-0 border-t border-solid border-neutral-8 bg-neutral-3 p-4 grid sm:grid-cols-3">
        <label htmlFor="budgeted_total" className="sm:col-span-2">
          {t("v2.shared.budget_table.position.footer_title")}
        </label>
        <CurrencyInput
          id="budgeted_total"
          name="budgeted_total"
          readOnly
          wrapperClassName="relative readonly"
          value={formatCurrency({ value: budgetedTotal, omitSymbol: true, trailing: true })}
        />
      </div>
    </div>
  )
}

export { PlanningTab }
