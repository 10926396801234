/* eslint-disable react/jsx-props-no-spreading */
import { CalendarDate } from "@internationalized/date"
import cn from "classnames"
import React, { useRef } from "react"
import { DateRange, mergeProps, useCalendarCell, usePress } from "react-aria"
import { CalendarState, RangeCalendarState } from "react-stately"

import { OnDateClick } from "v2/react/shared/forms/DateInputs/shared/Calendar"

interface CalendarCellProps {
  ariaLabel: string
  date: CalendarDate
  state: RangeCalendarState | CalendarState
  currentDate: CalendarDate
  onDateClick?: OnDateClick
}

function CalendarCell({ ariaLabel, date, state, currentDate, onDateClick }: CalendarCellProps) {
  const ref = useRef<HTMLButtonElement>(null)
  const {
    cellProps,
    buttonProps,
    isSelected,
    isOutsideVisibleRange,
    isDisabled,
    isUnavailable,
    formattedDate,
  } = useCalendarCell({ date }, state, ref)
  const today = currentDate.toString()

  const { isStartDate, isMiddleDate, isEndDate, isSingleDate } = getDateType(date, state)

  const { pressProps } = usePress({
    onPress: () => {
      onDateClick?.(date)
    },
    ref,
  })

  return (
    <td {...cellProps} aria-label={ariaLabel}>
      <div
        className={cn("cell-wrapper my-1.5 items-center justify-center flex", {
          "bg-primary-8": isMiddleDate && !isSingleDate,
          "start-date": isStartDate && !isEndDate,
          "end-date": isEndDate && !isStartDate,
        })}
      >
        <button
          {...mergeProps(buttonProps, pressProps)}
          ref={ref}
          hidden={isOutsideVisibleRange}
          tabIndex={0}
          className={cn("cell bg-transparent", {
            selected: isSelected || isStartDate || isEndDate,
            disabled: isDisabled,
            unavailable: isUnavailable,
            today: date.toString() === today,
            "start-date": isStartDate,
            "middle-date": isMiddleDate,
            "end-date": isEndDate,
            "single-date": isSingleDate,
          })}
          type="button"
        >
          {formattedDate}
        </button>
      </div>
    </td>
  )
}

const getDateType = (date: CalendarDate, state: RangeCalendarState | CalendarState) => {
  let isStartDate = false
  let isMiddleDate = false
  let isEndDate = false
  let isSingleDate = false

  if ("highlightedRange" in state && state.highlightedRange) {
    isStartDate = isRangeStart(date, state.highlightedRange)
    isMiddleDate = isRangeMiddle(date, state.highlightedRange)
    isEndDate = isRangeEnd(date, state.highlightedRange)
    isSingleDate = isStartDate && isEndDate
  } else {
    isSingleDate = state.value?.toString() === date.toString()
  }

  return { isStartDate, isMiddleDate, isEndDate, isSingleDate }
}

const isRangeStart = (date: CalendarDate, range: DateRange | null) => {
  if (!range || !range.start) return false
  return date.toString() === range.start.toString()
}

const isRangeEnd = (date: CalendarDate, range: DateRange | null) => {
  if (!range || !range.end) return false
  return date.toString() === range.end.toString()
}

const isRangeMiddle = (date: CalendarDate, range: DateRange | null) => {
  if (!range || !range.start || !range.end) return false
  return date.toString() > range.start.toString() && date.toString() < range.end.toString()
}

export { CalendarCell }
