import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

import {
  CopyInlineContent,
  useCopyInline,
  ValueToCopy,
} from "v2/react/shared/collection/menus/Dropdown/CopyInline"
import { DropdownItem } from "v2/react/shared/collection/menus/Dropdown/DropdownItem"

const CopyInlineDropdownItem = ({
  valueToCopy,
  label,
}: {
  valueToCopy: ValueToCopy
  label: string
}) => {
  const { initiateCopyValue, showCopyIndicator } = useCopyInline({ valueToCopy })

  return (
    <DropdownItem
      onClick={initiateCopyValue}
      closeAfterClick={false}
      withIcon={<FontAwesomeIcon icon={["far", "clone"]} />}
    >
      <CopyInlineContent label={label} showCopyIndicator={showCopyIndicator} />
    </DropdownItem>
  )
}

export { CopyInlineDropdownItem }
