/**
 * Matches on variable_pay_type_<number>_type (i.e. amount or percent)
 */
const variablePayTypeRegex = /^variable_pay_type_\w+_type$/

/**
 * Matches on variable_pay_type_<number> (i.e. the rate)
 */
const variablePayRegex = /^variable_pay_type_\d+$/

const orgUnitTypeRegex = /^org_unit_type_\d+$/

export { variablePayTypeRegex, variablePayRegex, orgUnitTypeRegex }
