import { Badges } from "v2/react/shared/tables/TableUtilities/FilterTable/Badges"
import { Button } from "v2/react/shared/tables/TableUtilities/FilterTable/Button"
import { Panel } from "v2/react/shared/tables/TableUtilities/FilterTable/Panel"

const FilterTable = {
  Button,
  Panel,
  Badges,
}

export { FilterTable }
