import React from "react"

import { Modal } from "v2/react/shared/overlay/Modal"
import { SaveFooter } from "v2/react/shared/overlay/Modal/SaveFooter"

import ChooseColumnsListForm from "./ChooseColumnsListForm"
import { useGridExportModal } from "./useGridExportModal"

interface Props {
  isOpen: boolean
  onClose: () => void
  renderedPositionIds?: number[]
  exportAsCSV?: (ids: number[], columns: string[]) => void
}

export default function GridExportModal({
  isOpen,
  onClose,
  renderedPositionIds,
  exportAsCSV,
}: Props) {
  const { handleSave, isSaving, setSelectedExportColumns } = useGridExportModal({
    renderedPositionIds,
    exportAsCSV,
    onClose,
  })
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={"Export".t("org_chart")}>
      <div className="p-6">
        <ChooseColumnsListForm onSelectedColumnsChange={setSelectedExportColumns} />
      </div>
      <SaveFooter
        isSaving={isSaving}
        onSave={handleSave}
        enabledText={"Export".t("org_chart")}
        disabledText={"export_disabled".t("button_defaults")}
      />
    </Modal>
  )
}
