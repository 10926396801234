import { globals } from "./options"

const exportStyles = Object.freeze({
  card: {
    fill: globals.cardColor,
  },
  color: {
    fill: "none",
  },
  chart_name: {
    "font-family": globals.fontFamily,
    "font-size": "20px",
    fill: "#333",
  },
  name: {
    "font-family": globals.fontFamily,
    "font-size": "13px",
    "font-weight": 700,
  },
  text: {
    "font-family": globals.fontFamily,
    "font-size": "11px",
    "font-weight": 500,
  },
  chart_section_name: {
    "font-family": globals.fontFamily,
    fill: "rgba(012, 020, 75, .80)",
    "font-size": "11px",
    "font-weight": 700,
  },
  direct_reports_text: {
    "font-family": globals.fontFamily,
    "font-size": "12px",
    "font-weight": "bold",
  },
  stats_bar_text: {
    "font-family": globals.fontFamily,
    "font-size": "10px",
    "font-weight": "bold",
    fill: "rgba(012, 020, 075, 0.64)",
  },
  in_progress_text: {
    "font-family": globals.fontFamily,
    "font-size": "11px",
    "font-weight": "500",
    fill: "rgba(128, 90, 0, 1)",
  },
  in_progress_bg: {
    fill: "rgba(255, 245, 218, 1)",
  },
  chain_of_command: {
    "font-family": globals.fontFamily,
    "font-size": "10px",
    "font-weight": "bold",
    fill: "rgba(012, 020, 075, 0.64)",
  },
  page_num: {
    "font-family": globals.fontFamily,
    fill: "#333",
    "font-size": "9px",
    "font-style": "italic",
  },
  parent_page_num: {
    "font-family": globals.fontFamily,
    fill: "rgba(012, 020, 075, 0.64)",
    "font-size": "11px",
    "font-style": "italic",
  },
  title_text: {
    "font-family": globals.fontFamily,
    "font-size": "11px",
    "font-weight": 500,
  },
})

export const exportHorizontalPadding = 12
export const exportVerticalPadding = 12
export default exportStyles
