import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React from "react"

import type { BannerType } from "v2/react/shared/status/AlertBanner"

interface Props {
  wrapperId?: string
  type?: BannerType
  wrapperClass?: string
  icon?: FontAwesomeIconProps["icon"]
  iconClass?: string
  children: React.ReactNode
}

const Notice = ({
  wrapperId,
  type = "neutral",
  wrapperClass,
  icon = ["far", "info-circle"],
  iconClass,
  children,
}: Props) => (
  <div id={wrapperId} className={cn(`alert-${type} flex`, wrapperClass)}>
    <div className="items-start self-stretch">
      <FontAwesomeIcon className={cn("mr-2", "text-2xl", iconClass)} icon={icon} />
    </div>
    {children}
  </div>
)

export { Notice }
