import cn from "classnames"
import React from "react"

function IconWithBorder({ src, variant }: { src?: string; variant?: string }) {
  return (
    <div
      className={cn(
        "icon-with-border shrink-0 items-center justify-center rounded border border-solid border-neutral-8 bg-white flex",
        variant === "sm" && "h-6 w-6 px-[0.313rem] py-1.5",
        variant !== "sm" && "h-10 w-10 p-3",
      )}
    >
      <div className="h-full w-full items-center justify-center flex">
        <img src={src} className="!m-0 h-full w-full object-contain" alt="" />
      </div>
    </div>
  )
}

export { IconWithBorder }
