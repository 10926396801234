import { Chart, ChartApprover } from "types/graphql.d"
import { CurrentUser } from "v2/redux/slices/ApprovalSlice"

export interface StringLookup {
  [key: string]: string
}

export const approvalInProgress = (approvers: ChartApprover[]): boolean =>
  !!approvers.find((approver: ChartApprover) => approver.status) || false

export const configureApproval = (currentUser: CurrentUser, approvers: ChartApprover[]): boolean =>
  currentUser.canConfigure && !approvers.length

export const requestApproval = (
  currentUser: CurrentUser,
  approvers: ChartApprover[],
  chart: Chart,
): boolean => currentUser.isCollaborator && approvers.length > 0 && !chart.status

export const submittedApproval = (chart: Chart): boolean => chart.status !== null
