import React from "react"
import { CodeBlock, tomorrowNight } from "react-code-blocks"

function Typography() {
  return (
    <>
      <h1 id="type" className="mb-12">
        Typography
      </h1>

      <section>
        <section>
          <section className="mb-16 grid-cols-2 grid">
            <div className="mb-6">
              <h2 className="text-large">h1-large</h2>
              <p>class: text-xl-bold</p>
              <p>font-size: 32px / 2rem</p>
              <p>line-height: 40px / 2.5rem</p>
              <p>font-weight: 700</p>
            </div>

            <div className="mb-6">
              <h2 className="text-large">h2-medium</h2>
              <p>class: text-large</p>
              <p>font-size: 16px / 1rem</p>
              <p>line-height: 24px / 1.5rem</p>
              <p>font-weight: 700</p>
            </div>

            <div className="mb-6">
              <h2 className="text-large">h3-small / body-medium-bold</h2>
              <p>class: text-base-bold</p>
              <p>font-size: 13px / 0.8125rem</p>
              <p>line-height: 20px / 1.25rem</p>
              <p>font-weight: 700</p>
            </div>

            <div className="mb-6">
              <h1 className="text-large">body-large</h1>
              <p>class: text-xl</p>
              <p>font-size: 24px / 2rem</p>
              <p>line-height: 40px / 2.5rem</p>
              <p>font-weight: 400</p>
            </div>

            <div className="mb-6">
              <h1 className="text-large">body</h1>
              <p>class: text-base</p>
              <p>font-size: 13px / 0.8125rem</p>
              <p>line-height: 20px / 1.25rem</p>
              <p>font-weight: 500</p>
            </div>

            <div className="mb-6">
              <h1 className="text-large">body-small</h1>
              <p>text-sm</p>
              <p>font-size: 11px / 0.6875rem</p>
              <p>line-height: 16px / 1rem</p>
              <p>font-weight: 500</p>
            </div>
          </section>

          <h2>Example Styles</h2>
          <div className="mb-8 flex-col items-center gap-2 flex">
            <h1 className="text-xl-bold">H1 Heading large</h1>
            <h2 className="text-large">H2 Heading medium</h2>
            <h3 className="text-base-bold">H3 Heading small / Body Medium Bold</h3>
            <p className="text-xl">P Body large</p>
            <p className="text-base">P Body</p>
            <span className="text-sm">Span Body small</span>
          </div>

          <h2>Example Code</h2>
          <div className="font-mono text-base">
            <CodeBlock text={headingExample} theme={tomorrowNight} language="javascript" />
          </div>
        </section>

        <section>
          <h2>Links</h2>
          <a href="#type">Example link</a>
          <div className="mt-4 font-mono text-base">
            <CodeBlock text={linkExample} theme={tomorrowNight} language="javascript" />
          </div>
        </section>
      </section>

      <section>
        <h2>Address tag</h2>

        <address>
          Address line 1 <br />
          Address line 2 <br />
          City, State, Zip code
        </address>

        <div className="mt-4 font-mono text-base">
          <CodeBlock text={addressExample} theme={tomorrowNight} language="javascript" />
        </div>
      </section>
    </>
  )
}

export default Typography

const headingExample = `<h1 className="text-xl-bold">Heading large</h1>
<h2 className="text-large">Heading medium</h2>
<h3 className="text-base-bold">Heading small</h3>
<p className="text-xl">Body large</p>
<p className="text-base-bold">Body bold</p>
<p className="text-base">Body</p>
<span className="text-sm">Body small</span>`

const linkExample = `<a href="#">Example link</a>`

const addressExample = `<address>
Address line 1 <br />
Address line 2 <br />
City, State, Zip code
</address>`
