import { ControlProps } from "@jsonforms/core"
import fp from "lodash/fp"
import React, { useEffect, useState } from "react"
import { z } from "zod"

import {
  ControlHandle,
  EmbeddedPositionTypeControl,
} from "v2/react/components/positionTypes/EmbeddedPositionTypeControl"
import {
  PositionTypesConnectionNode,
  UNIQUE_KEY_FOR_PENDING_POSITION_TYPE,
} from "v2/redux/GraphqlApi/PositionTypesApi"

import { useValidation } from "./hooks/useValidation"
import { InputErrorText } from "./InputErrorText"

type JsonPositionTypeData = z.infer<typeof controlSchema>

type ControlPropsSubset = Pick<
  ControlProps,
  | "config"
  | "data"
  | "enabled"
  | "handleChange"
  | "id"
  | "label"
  | "path"
  | "schema"
  | "uischema"
  | "errors"
>

function JsonPositionTypeControl({
  config,
  data,
  enabled,
  handleChange,
  id,
  label,
  path,
  schema,
  errors,
}: ControlPropsSubset) {
  const embeddedControlRef = React.useRef<ControlHandle>(null)
  const positionTypeForEmbeddedControl = usePositionTypeForEmbeddedControl(data)
  useEffect(() => {
    const { current: embeddedControl } = embeddedControlRef
    if (!embeddedControl) return

    const currentPositionType = embeddedControl.currentPositionType
    if (arePositionTypesEqual(positionTypeForEmbeddedControl, currentPositionType)) return

    embeddedControl.replacePositionType(positionTypeForEmbeddedControl)
  }, [positionTypeForEmbeddedControl])

  const { showError, errorMessage } = useValidation({
    path,
    schema,
    submitting: config.submitting,
    schemaError: errors,
  })

  const handleChangeByForwarding = (node: PositionTypesConnectionNode | undefined) => {
    const uniqueKey =
      node?.uniqueKey === UNIQUE_KEY_FOR_PENDING_POSITION_TYPE ? undefined : node?.uniqueKey
    handleChange(path, {
      id: uniqueKey ?? "",
      jobCode: node?.jobCode,
      jobCodeTitleLabel: node?.jobCodeTitleLabel ?? "",
      title: node?.title,
    })
  }

  return (
    <EmbeddedPositionTypeControl
      controlRef={embeddedControlRef}
      createIsVirtual
      inputClassName={showError ? "border--error" : undefined}
      disabled={!enabled}
      fieldFeedback={<InputErrorText showError={showError} message={errorMessage} />}
      idForIdInput={`${id}_id`}
      idForJobCodeTitleLabelInput={`${id}_job_code_title_label`}
      initialValue={positionTypeForEmbeddedControl}
      label={label}
      nameForIdInput={`${id}[id]`}
      nameForJobCodeTitleLabelInput={`${id}[job_code_title_label]`}
      onChange={handleChangeByForwarding}
    />
  )
}

const usePositionTypeForEmbeddedControl = (data: unknown) => {
  const normalized = toPositionTypeForControl(controlSchema.parse(data))
  const [positionTypeData, setPositionTypeData] = useState(normalized)

  if (!fp.isEqual(positionTypeData, normalized)) setPositionTypeData(normalized)

  return positionTypeData
}

// Represents the JSON form data.
const controlSchema = z.object({
  id: z.string(),
  jobCode: z.string().optional().nullable(),
  jobCodeTitleLabel: z.string(),
  title: z.string().optional().nullable(),
})

const toPositionTypeForControl = (data: JsonPositionTypeData) => {
  if (fp.isEmpty(data.id) && fp.isEmpty(data.jobCodeTitleLabel)) return undefined

  return {
    uniqueKey: fp.isEmpty(data.id) ? UNIQUE_KEY_FOR_PENDING_POSITION_TYPE : data.id,
    jobCodeTitleLabel: data.jobCodeTitleLabel,
    jobCode: data.jobCode,
    title: data.title,
  }
}

const arePositionTypesEqual = (
  lhs?: PositionTypesConnectionNode,
  rhs?: PositionTypesConnectionNode,
) => (lhs && rhs && fp.matches(lhs)(rhs) ? true : lhs === rhs)

export { JsonPositionTypeControl }
