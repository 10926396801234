import React from "react"

interface IconWrapperProps {
  icon: React.ReactNode
  children: React.ReactNode
  iconPosition?: "left" | "right"
}

const IconWrapper = ({ icon, children, iconPosition = "left" }: IconWrapperProps) => (
  <div className="items-center gap-2 flex">
    {iconPosition === "left" && icon}
    {children}
    {iconPosition === "right" && icon}
  </div>
)

export type { IconWrapperProps }
export { IconWrapper }
