import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import fp from "lodash/fp"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useOnClickOutside } from "usehooks-ts"

import { useSaveCell } from "v2/react/components/headcountPlanning/hooks/useSaveCell"
import { CompareValues } from "v2/react/components/headcountPlanning/TableDatasheet/CompareValues"
import { DropdownMenu } from "v2/react/components/headcountPlanning/TableDatasheet/DropdownMenu"
import { useAutocompleteList } from "v2/react/components/headcountPlanning/TableDatasheet/hooks/useAutocompleteList"
import { useCellHandlers } from "v2/react/components/headcountPlanning/TableDatasheet/hooks/useCellHandlers"
import {
  Cell,
  useCellState,
} from "v2/react/components/headcountPlanning/TableDatasheet/hooks/useCellState"
import { useFollowUpHandlers } from "v2/react/components/headcountPlanning/TableDatasheet/hooks/useFollowUpHandlers"
import { StrikethroughCell } from "v2/react/components/headcountPlanning/TableDatasheet/StrikethroughCell"
import { StyleLayers } from "v2/react/components/headcountPlanning/TableDatasheet/StyleLayers"
import { Column, FieldType } from "v2/react/components/headcountPlanning/TableDatasheet/types"
import { usePositionTypesAutocompleteQueryWithState } from "v2/react/components/positionTypes/hooks/usePositionTypesAutocompleteQueryWithState"
import { AddPositionTypeModal } from "v2/react/components/positionTypes/modals/AddPositionTypeModal"
import { makeCodeNameFromInputStringFn } from "v2/react/utils/codeNames"
import { PositionTypesConnectionNode } from "v2/redux/GraphqlApi/PositionTypesApi"
import { useAppSelector } from "v2/redux/store"

import { HeadcountPlanDatasheetRow } from "./types"

type PositionTypeCellProps = {
  column: Column<HeadcountPlanDatasheetRow>
  row: HeadcountPlanDatasheetRow
  headcountPlanId: string
  participantId?: string
  readOnly: boolean
}

type SaveCustomPositionTypeFnArg = {
  cell: Cell
  completeFollowUp: () => void
  exitFollowUp: () => void
}

// Shape of position type data embedded in headcount plans.
type EmbeddedPositionType = {
  id: string
  label: string
  jobCode?: string | null
  title?: string | null
}

const BLANK_TITLE = {
  id: "",
  label: "",
  jobCode: null,
  title: null,
}

const codeNameFromInputString = makeCodeNameFromInputStringFn(({ code, name }) => ({
  jobCode: code,
  title: name,
}))

export function PositionTypeCell({
  column,
  row,
  participantId,
  headcountPlanId,
  readOnly,
}: PositionTypeCellProps) {
  const { t } = useTranslation()

  const { positionAttributes, positionAttributesWithEdits } = row
  const currentPositionType: EmbeddedPositionType | undefined = positionAttributesWithEdits.title
  const currentValue = currentPositionType?.label ?? ""
  const compareValue =
    row.type === "modified" && "title" in row.payload ? positionAttributes.title.label : null

  // Search + input
  const [searchTerm, setSearchTerm] = useState(currentValue)
  const revertChanges = useCallback(() => setSearchTerm(currentValue), [currentValue])
  const { positionTypes } = usePositionTypesAutocompleteQueryWithState({
    currentTerm: currentValue,
    searchTerm,
  })
  const options = useMemo(
    () =>
      positionTypes.map((node) => ({
        id: node.uniqueKey,
        label: node.jobCodeTitleLabel,
        title: node.title,
        jobCode: node.jobCode,
      })),
    [positionTypes],
  )
  // This helps ensure values update when an external event, such as "reverting"
  // changes, occurs.
  useEffect(() => {
    setSearchTerm(currentValue)
  }, [currentValue])

  // A person's company must have position management or succession planning in
  // order to set a job code.
  const featureFlags = useAppSelector((state) => state.session.featureFlags)
  const canSetJobCode = Boolean(
    featureFlags?.positionManagement || featureFlags?.successionPlanning,
  )

  const saveFn = useSaveCell<EmbeddedPositionType>(headcountPlanId, "title", row, participantId)
  const cell = useCellState<EmbeddedPositionType>({
    columnId: column.id,
    currentValue: currentPositionType,
    fieldType: FieldType.SuggestedAutocomplete,
    rowId: row.id,

    hasNotChanged: (value: EmbeddedPositionType | null | undefined) =>
      fp.equals(currentPositionType, value),
    onEditing: () => document.getElementById(`${column.id}-${row.id}-input`)?.focus(),
    onSaved: (state) => setSearchTerm(state.value?.label ?? ""),
    // The backend won't detect an empty/blank value properly, it needs a hash
    // with 1+ blank props. So fallback to a hash with a blank id/label
    saveFn: (state) => saveFn(state.value ?? BLANK_TITLE),
  })
  const autocompleteList = useAutocompleteList({ showList: cell.isEditing })
  const activeOption = options[autocompleteList.activeIndex ?? -1]
  const cellRef = autocompleteList.refs.reference as React.MutableRefObject<HTMLDivElement>

  const inputValue = searchTerm.trim()
  const isWriting = cell.isEditing || cell.isErroredEditing
  const shouldCreateBeforeCellExit =
    isWriting && !activeOption && inputValue !== currentValue && inputValue.length > 0
  const shouldFollowUpBeforeCellExit = shouldCreateBeforeCellExit && canSetJobCode
  const followUpHandlersArg = {
    cell,
    inputRef: autocompleteList.refs.domReference,
    revertChanges,
    shouldFollowUpBeforeCellExit,
  }
  const {
    beginFollowUp,
    cancelFollowUp,
    completeFollowUp,
    doNotRequireFollowUp,
    exitFollowUp,
    isInFollowUp,
    maybeBeginFollowUpFromKeyboardEvent,
    requireFollowUp,
  } = useFollowUpHandlers(followUpHandlersArg)

  // Wrap `beginFollowUp` and pass as click handler
  const beginFollowUpOrAddInline = () => {
    if (shouldFollowUpBeforeCellExit) {
      beginFollowUp()
      return
    }

    cell.dispatch({
      type: "save",
      value: {
        id: "custom",
        title: searchTerm,
        jobCode: null,
        label: searchTerm,
      },
    })
  }

  const { handleCellClick, handleCellKeyUp } = useCellHandlers(cell, cellRef, {
    // If the search term hasn't changed, we assume no change, period. When the
    // user can set the job code, the "add" flow dispatches save directly, and
    // this isn't applicable in that case.
    getSaveValue: () => {
      if (inputValue === currentValue) return currentPositionType
      if (activeOption) return activeOption

      return {
        id: "custom",
        title: searchTerm,
        jobCode: null,
        label: searchTerm,
      }
    },
    moveDown: () =>
      autocompleteList.setActiveIndex((prev) =>
        prev === null ? 0 : Math.min(options.length - 1, prev + 1),
      ),
    moveUp: () =>
      autocompleteList.setActiveIndex((prev) => (prev === null ? 0 : Math.max(0, prev - 1))),
  })
  const handleChangeInSearchTerm: React.ChangeEventHandler<HTMLInputElement> = (ev) =>
    setSearchTerm(ev.target.value)
  const handleFollowUpSaved = useSaveCustomPositionTypeFn({
    cell,
    completeFollowUp,
    exitFollowUp,
  })

  const hideCompareValue =
    cell.isEditing ||
    cell.isSaving ||
    cell.isSaved ||
    cell.isErrored ||
    cell.isErroredEditing ||
    cell.isErroredSelected ||
    cell.isErroredSaving
  // We disable the input while the modal is open to help ensure it doesn't
  // compete for element focus.
  const disableInput = (!cell.isEditing && !cell.isErroredEditing) || isInFollowUp
  const showAddButton = currentValue !== inputValue && canSetJobCode
  // We show the dropdown menu even if there aren't any options so the user can
  // click that sweet sweet add button.
  const showDropdownMenu = !isInFollowUp && isWriting && (options.length > 0 || showAddButton)

  useEffect(() => {
    if (isWriting && shouldFollowUpBeforeCellExit) requireFollowUp()
    else if (isWriting) doNotRequireFollowUp()
  }, [doNotRequireFollowUp, isWriting, requireFollowUp, shouldFollowUpBeforeCellExit])

  // If a follow up is necessary, we'll trigger it if a user clicks out of the
  // cell. Otherwise, if we're "writing" and the user is clicking away, we
  // need to revert any changes since they won't be saved.
  useOnClickOutside(cellRef, (ev) => {
    // Ensure we ignore clicks that are "outside" due to a click in our
    // autocomplete list.
    const { current: autocompleteContainer } = autocompleteList.refs.floating
    const targetIsDOMNode = ev.target && ev.target instanceof Node
    if (targetIsDOMNode && autocompleteContainer?.contains(ev.target)) return
    if (!isWriting) return

    if (shouldFollowUpBeforeCellExit) beginFollowUpOrAddInline()
    else if (!isInFollowUp) revertChanges()
  })

  if (row.excluded) {
    return <StrikethroughCell value={currentValue} />
  }

  if (readOnly) {
    return (
      <CompareValues compareValue={compareValue} className="non-editable-cell">
        <span>{currentValue}</span>
      </CompareValues>
    )
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return (
    <>
      {isInFollowUp ? (
        <AddPositionTypeModal
          initialData={codeNameFromInputString(searchTerm)}
          isOpen={isInFollowUp}
          onClose={cancelFollowUp}
          onSave={handleFollowUpSaved}
          validateOnly
        />
      ) : null}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        id={`${column.id}-${row.id}`}
        className={`editable-cell position-${column.id}-input`}
        onClick={handleCellClick}
        onKeyDown={maybeBeginFollowUpFromKeyboardEvent}
        onKeyUp={handleCellKeyUp}
      >
        <CompareValues compareValue={hideCompareValue ? null : compareValue}>
          <input
            id={`${column.id}-${row.id}-input`}
            ref={autocompleteList.refs.setReference}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...autocompleteList.getReferenceProps()}
            className="bg-white"
            value={searchTerm}
            onChange={handleChangeInSearchTerm}
            disabled={disableInput}
            name={column.id}
          />
        </CompareValues>

        <DropdownMenu
          showList={showDropdownMenu}
          floatingRef={autocompleteList.refs.setFloating}
          floatingStyles={{
            // eslint-disable-next-line react/jsx-props-no-spreading
            ...autocompleteList.floatingStyles,
            zIndex: 5,
            marginLeft: "-1rem",
          }}
          floatingProps={autocompleteList.getFloatingProps}
          wrapperClasses="SelectField"
          context={autocompleteList.context}
        >
          {options.map((option, index) => (
            <li
              role="option"
              className={cn("TableAutocompleteField__result", {
                highlight: autocompleteList.activeIndex === index,
              })}
              aria-selected={autocompleteList.activeIndex === index}
              key={option.id}
              ref={(node) => {
                autocompleteList.listRef.current[index] = node
              }}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...autocompleteList.getItemProps({
                onClick: () => {
                  if (option.id === currentPositionType?.id)
                    setSearchTerm(currentPositionType.label)
                  cell.dispatch({ type: "save", value: option })
                },
              })}
            >
              <div>{option.label}</div>
            </li>
          ))}
          {showAddButton ? (
            <div className="AutocompleteField__footer align-center justify-center px-4 py-2 flex">
              <button
                className="btn--sm btn--primary"
                onMouseDown={beginFollowUpOrAddInline}
                type="button"
              >
                <FontAwesomeIcon icon={["far", "plus"]} />
                {t("v2.defaults.add_entity", { entity: column.label })}
              </button>
            </div>
          ) : null}
        </DropdownMenu>

        <StyleLayers cell={cell} fieldType={FieldType.SuggestedAutocomplete} />
      </div>
    </>
  )
}

function useSaveCustomPositionTypeFn({
  cell,
  completeFollowUp,
  exitFollowUp,
}: SaveCustomPositionTypeFnArg) {
  const doCompleteFollowUpRef = useRef(false)
  useEffect(() => {
    // Bail early; no work needs to be done.
    if (!doCompleteFollowUpRef.current) return

    // Value saved, complete the follow up process and exit.
    if (cell.state === "saved") {
      completeFollowUp()
      doCompleteFollowUpRef.current = false
    }

    // Adding the change to the headcount plan failed. Error handling already
    // occurs thanks to `useCellState` and friends, we just need to exit the
    // follow up process.
    if (cell.state === "errored") {
      exitFollowUp()
      doCompleteFollowUpRef.current = false
    }
  }, [completeFollowUp, cell.state, exitFollowUp])

  return useCallback(
    (node: PositionTypesConnectionNode) => {
      doCompleteFollowUpRef.current = true

      cell.dispatch({
        type: "save",
        value: {
          id: "custom",
          title: node.title,
          jobCode: node.jobCode,
          label: node.jobCodeTitleLabel,
        },
      })
    },
    [cell],
  )
}
