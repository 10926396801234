import { EntityId } from "@reduxjs/toolkit"
import fp from "lodash/fp"
import { useEffect, useMemo, useRef } from "react"

import { NotificationTopic } from "types/graphql.enums"
import OperationStore from "v2/operation_store"
import { useGraphqlChannelSubscription } from "v2/redux/slices/GraphqlCableSlice/hooks"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

import { ProcessingStatus } from "./types"
import { batchSelectors, entityEventLogsSelectors, subscribed } from "./utils"

const operationId = OperationStore.getOperationId("NotificationPublishedSubscription")

type BatchNotificationsHookArg = {
  subjectId: EntityId
}

type EntityEventLogHookArg = {
  subjectId: EntityId
}

type HookArg = {
  subjectId?: EntityId | null
  localOnly?: boolean
  onProcessingDone?: () => void
}

export function useProcessingNotifications(arg: HookArg = {}) {
  const { subjectId, localOnly } = arg
  const subscriptionArg = useMemo(
    () => ({
      args: { operationId, variables: { subjectId, topic: NotificationTopic.Processing } },
      localOnly,
      subjectId,
    }),
    [localOnly, subjectId],
  )
  useGraphqlChannelSubscription(subscriptionArg)

  const dispatch = useAppDispatch()
  useEffect(() => {
    if (subjectId) dispatch(subscribed({ subjectId, topic: NotificationTopic.Processing }))
  }, [dispatch, subjectId])

  const id = subjectId ?? ""
  const state = useAppSelector((state) => state.notification.processing.entities[id])
  const onProcessingDoneRef = useRef(arg.onProcessingDone)
  useEffect(() => {
    const { current: onProcessingDone } = onProcessingDoneRef
    if (state?.status === ProcessingStatus.Done) onProcessingDone?.()
  }, [state?.status])

  const current = state?.current ?? 0
  const outOf = state?.outOf ?? 0
  const rawPct = outOf === 0 ? 0 : (current / outOf) * 100

  const percent = fp.pipe(fp.round, fp.clamp(0, 100))(rawPct)
  const processing = state?.status === ProcessingStatus.Active

  return { state, processing, percent }
}

export function useEffectWhenBatchOpened(onBatchOpened: () => void) {
  // Batch "opened" events use the current company id as the subject id. This
  // whole approach is hacky, so I'll probably revisit it.
  const subjectId = useAppSelector((state) => state.session.currentCompanyId)
  const subscriptionArg = useMemo(
    () => ({
      args: { operationId, variables: { subjectId, topic: NotificationTopic.BatchEvents } },
      subjectId,
    }),
    [subjectId],
  )

  useGraphqlChannelSubscription(subscriptionArg)

  const handleBatchOpenedRef = useRef(onBatchOpened)
  const batchOpenedHits = useAppSelector((state) => state.notification.batchOpenedHits)
  useEffect(() => {
    if (batchOpenedHits > 0) handleBatchOpenedRef.current()
  }, [batchOpenedHits])
}

export function useBatchNotifications({ subjectId }: BatchNotificationsHookArg) {
  const subscriptionArg = useMemo(
    () => ({
      args: { operationId, variables: { subjectId, topic: NotificationTopic.BatchEvents } },
      subjectId,
    }),
    [subjectId],
  )

  useGraphqlChannelSubscription(subscriptionArg)
  return useAppSelector((state) => batchSelectors.selectById(state, subjectId))
}

export function useEntityEventLog({ subjectId }: EntityEventLogHookArg) {
  const fallbackState = useMemo(() => ({ subjectId, events: [] }), [subjectId])
  const subscriptionArg = useMemo(
    () => ({
      args: { operationId, variables: { subjectId, topic: NotificationTopic.EntityEvents } },
      subjectId,
    }),
    [subjectId],
  )

  useGraphqlChannelSubscription(subscriptionArg)

  const dispatch = useAppDispatch()
  useEffect(() => {
    if (subjectId) dispatch(subscribed({ subjectId, topic: NotificationTopic.EntityEvents }))
  }, [dispatch, subjectId])

  return useAppSelector(
    (state) => entityEventLogsSelectors.selectById(state, subjectId) ?? fallbackState,
  )
}
