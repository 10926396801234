import {
  ChartNodeCurrentCompensationQuery,
  Collection,
  Compensation,
  Maybe,
  VariablePay,
} from "types/graphql"
import { BasePay, SourcePay } from "types/graphql.enums"
import { NodeRow } from "v2/react/components/orgChart/Datasheet/types"
import { FollowUpModalProps } from "v2/react/components/orgChart/OrgChartDatasheet/FollowUpModals"
import { CompensationFieldType } from "v2/react/components/orgChart/OrgChartDatasheet/Modals/CompensationFollowUp"
import {
  BasePayItem,
  VariablePayItem,
} from "v2/react/components/orgChart/OrgChartDatasheet/Modals/CompensationFollowUp/CompensationTable"
import {
  errorMessagesFor,
  ErrorsType,
} from "v2/react/components/orgChart/OrgChartDatasheet/Modals/errors"
import {
  basePayTypeCast,
  isVariablePayAmount,
  isVariablePayType,
  variablePayTypeCast,
} from "v2/react/components/orgChart/OrgChartDatasheet/Modals/helpers"
import { Changes } from "v2/react/components/orgChart/OrgChartDatasheet/Modals/types"
import { prepareCurrencyValue } from "v2/react/utils/currency"
import { maybeGetIDFromUniqueKey } from "v2/react/utils/uniqueKey"
import { EnhancedNodeInterface, FieldKey } from "v2/redux/slices/NodeSlice/types"

const getDescription = ({
  fieldType,
  personName,
  label,
  changes,
}: {
  fieldType: CompensationFieldType
  personName: string
  label: string
  changes: Changes
}) => {
  let changedFrom = changes.from
  let changedTo = changes.to

  if (["basePayType", "variablePayType"].includes(fieldType)) {
    changedFrom = changedFrom && `${changes.from}`.t("org_chart")
    changedTo = changedTo && `${changedTo}`.t("org_chart")
  } else if (["basePayAmount", "variablePayAmount"].includes(fieldType)) {
    changedTo = (changedTo && prepareCurrencyValue({ value: changedTo, omitSymbol: false })) || null
  }

  const translationArgs: string[] = [label, personName, changedFrom, changedTo].filter(
    isNotNullOrUndefined,
  )

  const isAmountType = ["basePayAmount", "variablePayAmount"].includes(fieldType)
  const prefix = !changes.from ? "create_new" : "create"

  const translationString = isAmountType
    ? `${prefix}_compensation_record_with_pay_amount_description`
    : `${prefix}_compensation_record_with_pay_type_description`

  return translationString.t("org_chart", null, null, null, translationArgs)
}

const getChangedTo = (
  fieldType: CompensationFieldType,
  value: FollowUpModalProps["field"]["value"],
) => {
  const inputID = maybeGetIDFromUniqueKey(value)
  if (fieldType === "basePayType") return basePayTypeCast(inputID)
  if (fieldType === "variablePayType") return variablePayTypeCast(inputID)
  return value
}

const getChangedFrom = (
  fieldType: CompensationFieldType,
  fieldKey: FieldKey,
  row: FollowUpModalProps["row"],
) => {
  if (fieldType === "hoursPerWeek") {
    return row.data.hours_per_week || null
  }
  if (fieldType === "basePayType") {
    const payType = row.data.base_pay_type?.toLowerCase()
    return payType ? basePayTypeCast(payType) : null
  }
  if (fieldType === "basePayAmount") {
    return row.data.base_pay || null
  }
  if (fieldType === "variablePayType") {
    const payType = row.data.variable_pays_original?.find((vp) => vp.field_id === fieldKey)
      ?.pay_type
    return payType ? variablePayTypeCast(payType) : null
  }
  if (fieldType === "variablePayAmount") {
    return row.data.variable_pays_original?.find((vp) => vp.field_id === fieldKey)?.amount || null
  }
  return null
}

const getFieldType = (fieldKey: FieldKey) => {
  if (isVariablePayType(fieldKey)) return "variablePayType"
  if (isVariablePayAmount(fieldKey)) return "variablePayAmount"
  if (fieldKey === "base_pay") return "basePayAmount"
  if (fieldKey === "hours_per_week") return "hoursPerWeek"
  return "basePayType"
}

function isNotNullOrUndefined<T>(input: null | undefined | T): input is T {
  return input != null
}

const getChanges = ({
  fieldType,
  fieldKey,
  row,
  value,
}: {
  fieldType: CompensationFieldType
  fieldKey: FieldKey
  row: NodeRow<EnhancedNodeInterface>
  value: Maybe<string>
}) => ({
  from: getChangedFrom(fieldType, fieldKey, row),
  to: getChangedTo(fieldType, value),
})

const getModalCopy = ({
  fieldType,
  personName,
  label,
  changes,
}: {
  fieldType: CompensationFieldType
  personName: string
  label: string
  changes: Changes
}) => {
  const title = "Create Compensation Record".t("org_chart")
  const description = getDescription({
    fieldType,
    personName,
    label,
    changes,
  })

  return {
    title,
    description,
  }
}

interface GetCompensationDataProps {
  result?: ChartNodeCurrentCompensationQuery
  fieldKey: FieldKey
  changes: Changes
  fieldType: CompensationFieldType
}

const getCompensationData = ({
  result,
  fieldKey,
  changes,
  fieldType,
}: GetCompensationDataProps) => {
  const currentCompensation = result?.nodeContainer?.node?.current_compensation
  const existingVariablePays = currentCompensation?.variable_pays
  const variablePayTypeCollection =
    result?.currentCompany?.collections?.variablePayTypes?.options?.nodes

  return {
    variablePays: getVariablePayItems({
      existingVariablePays,
      variablePayTypeCollection,
      fieldKey,
      changes,
      fieldType,
    }),
    basePay: getBasePay({
      basePayType: currentCompensation?.base_pay_type,
      basePay: currentCompensation?.base_pay,
      hoursPerWeek: currentCompensation?.hours_per_week,
      currencyCode: currentCompensation?.currency_code,
      fieldType,
      changes,
    }),
  }
}

interface GetVariablePayItemsProps {
  existingVariablePays?: Omit<VariablePay, "field_id">[]
  variablePayTypeCollection?: Collection["options"]["nodes"]
  fieldKey: FieldKey
  changes: Changes
  fieldType: CompensationFieldType
}

const getExistingVariablePay = (
  existingVariablePays: GetVariablePayItemsProps["existingVariablePays"],
  variablePayTypeId: string,
) =>
  existingVariablePays?.find(
    (variablePay) => variablePay?.variable_pay_type_id === variablePayTypeId,
  )

const getExistingVariablePayAmount = (
  existingVariablePay: Omit<VariablePay, "field_id"> | undefined,
  payType: Maybe<SourcePay> | undefined,
) =>
  payType === SourcePay.Percent ? existingVariablePay?.amount : existingVariablePay?.formatted_value

const getVariablePayValue = (
  isChangedVariablePay: boolean,
  fieldType: string,
  changes: Changes,
  existingVariablePayAmount: Maybe<string> | undefined,
) => {
  if (isChangedVariablePay && fieldType === "variablePayAmount") {
    return changes.to
  }
  if (isChangedVariablePay) {
    // If the variable pay type is changed, we clear the variable pay amount
    return null
  }
  return existingVariablePayAmount
}

const getVariablePayType = (
  isChangedVariablePay: boolean,
  fieldType: string,
  changes: Changes,
  existingPayType: Maybe<SourcePay> | undefined,
) => {
  if (isChangedVariablePay && fieldType === "variablePayType") return changes.to as SourcePay
  return existingPayType || SourcePay.Amount
}

const getVariablePayItems = ({
  existingVariablePays,
  variablePayTypeCollection,
  fieldKey,
  changes,
  fieldType,
}: GetVariablePayItemsProps) => {
  const changedVariablePayTypeId = fieldKey.replace(/_type$/, "")

  return variablePayTypeCollection?.map((variablePayType) => {
    const { id: variablePayTypeId, label: variablePayTypeLabel } = variablePayType
    const existingVariablePay = getExistingVariablePay(existingVariablePays, variablePayTypeId)
    const existingPayType = existingVariablePay?.pay_type
    const existingVariablePayAmount = getExistingVariablePayAmount(
      existingVariablePay,
      existingPayType,
    )
    const isChangedVariablePay = changedVariablePayTypeId === variablePayTypeId

    const variablePayItem: VariablePayItem = {
      label: variablePayTypeLabel,
      id: variablePayTypeId,
      value: getVariablePayValue(
        isChangedVariablePay,
        fieldType,
        changes,
        existingVariablePayAmount,
      ),
      payType: getVariablePayType(isChangedVariablePay, fieldType, changes, existingPayType),
      useAttentionState: isChangedVariablePay,
    }

    return variablePayItem
  })
}

interface GetBasePayProps {
  hoursPerWeek?: Compensation["hours_per_week"]
  basePay?: Compensation["base_pay"]
  basePayType?: Compensation["base_pay_type"]
  currencyCode?: Compensation["currency_code"]
  fieldType: CompensationFieldType
  changes: Changes
}

const getInitialCurrencyValue = (
  basePayChanged: boolean,
  basePayTypeChanged: boolean,
  changes: Changes,
  basePay: Compensation["base_pay"] | undefined,
) => {
  if (basePayChanged) return changes.to
  if (basePayTypeChanged) return null
  return basePay
}

const getInitialPayType = (
  basePayTypeChanged: boolean,
  hoursPerWeekChanged: boolean,
  changes: Changes,
  basePayType: Compensation["base_pay_type"] | undefined,
) => {
  if (basePayTypeChanged) return changes.to as BasePay
  if (changes.from) return basePayType || BasePay.Salary
  if (hoursPerWeekChanged) return BasePay.Hourly
  return BasePay.Salary
}

const getInitialHoursPerWeek = (
  hoursPerWeekChanged: boolean,
  changes: Changes,
  hoursPerWeek: Compensation["hours_per_week"],
) => {
  if (hoursPerWeekChanged) {
    const parsedHoursPerWeek = changes.to ? parseFloat(changes.to) : 0
    if (Number.isNaN(parsedHoursPerWeek)) return 0
    return parsedHoursPerWeek
  }

  return hoursPerWeek
}

const getBasePay = ({
  fieldType,
  basePay,
  basePayType,
  currencyCode,
  hoursPerWeek,
  changes,
}: GetBasePayProps): BasePayItem => {
  const basePayChanged = fieldType === "basePayAmount"
  const basePayTypeChanged = fieldType === "basePayType"
  const hoursPerWeekChanged = fieldType === "hoursPerWeek"

  const initialCurrencyValue = getInitialCurrencyValue(
    basePayChanged,
    basePayTypeChanged,
    changes,
    basePay,
  )
  const initialPayType = getInitialPayType(
    basePayTypeChanged,
    hoursPerWeekChanged,
    changes,
    basePayType,
  )

  return {
    modelType: "compensation",
    currencyCode: currencyCode || "USD",
    initialCurrencyValue: initialCurrencyValue || "",
    initialHoursPerWeek: getInitialHoursPerWeek(hoursPerWeekChanged, changes, hoursPerWeek),
    initialPayType,
    label: "Base Pay".t("compensation", "Base Pay"),
    useAttentionState: basePayTypeChanged || hoursPerWeekChanged,
  }
}

const maybeAddErrorsToBasePay = (basePay: BasePayItem, errors: ErrorsType): BasePayItem => {
  if (!errors) return basePay

  return {
    ...basePay,
    errors: {
      currency: errorMessagesFor("base_pay", errors),
      hoursPerWeek: errorMessagesFor("hours_per_week", errors),
    },
  }
}

const maybeAddErrorsToVariablePays = (
  variablePayItems: VariablePayItem[] | undefined,
  errors: ErrorsType,
): VariablePayItem[] | undefined => {
  if (!variablePayItems) return undefined
  if (!errors) return variablePayItems

  return variablePayItems.map((item) => ({
    ...item,
    error: errorMessagesFor(item.id, errors),
  }))
}

export {
  getChanges,
  getFieldType,
  getCompensationData,
  maybeAddErrorsToBasePay,
  maybeAddErrorsToVariablePays,
  getModalCopy,
}
