import classNames from "classnames"
import React from "react"

import { InputErrorBanner } from "v2/react/shared/forms/InputErrorBanner"

interface InputFieldsetProps {
  children: React.ReactNode
  label?: string
  errors?: string[]
  showErrorMessage?: boolean
  className?: string
  labelPlacement?: "top" | "left"
}

const InputFieldset = ({
  children,
  label,
  errors,
  showErrorMessage = true,
  className,
  labelPlacement = "top",
}: InputFieldsetProps) => {
  const hasErrors = errors && errors.length > 0

  const isLeftLabel = labelPlacement === "left"

  return (
    <fieldset className={classNames("input-fieldset", className)}>
      {/* Errors display above the entire fieldset when the label is to the left. */}
      {hasErrors && showErrorMessage && isLeftLabel && (
        <div className="mb-2">
          <InputErrorBanner errors={errors} />
        </div>
      )}
      <div
        className={classNames({
          "input-fieldset__left-label-wrapper": isLeftLabel,
          "input-fieldset__top-label-wrapper": !isLeftLabel,
        })}
      >
        {label && (
          <div className="input-fieldset__label">
            <legend className={classNames({ "text-status-critical": hasErrors })}>{label}</legend>
          </div>
        )}
        {/* Errors display below the label when the lable is on top. */}
        {hasErrors && showErrorMessage && !isLeftLabel && (
          <div className="mb-2">
            <InputErrorBanner errors={errors} />
          </div>
        )}
        <div className="input-fieldset__input">{children}</div>
      </div>
    </fieldset>
  )
}

export { InputFieldset }
