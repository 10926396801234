import {
  Control,
  FieldArrayPath,
  useController,
  UseControllerProps,
  useFieldArray,
  UseFieldArrayProps,
  useFormState,
  useWatch,
} from "react-hook-form"

import { FieldName, FieldValues } from "v2/react/components/positions/positionForm/types"

/**
 * Convenience hook which wraps `useWatch` so you can omit generic type info
 * while working with a well typed return value.
 *
 * @example
 * ```ts
 * usePositionWatchField({ name: "position.position_base_pay" })
 * ```
 */
const usePositionWatchField = <Key extends FieldName>(props: { name: Key }) =>
  useWatch<FieldValues, Key>(props)

/**
 * Convenience hook which wraps `useFieldArray` so you can omit generic type
 * info while working with a well typed return value.
 *
 * @example
 * ```ts
 * usePositionFieldArray({ name: "position.position_funding_sources_attributes", keyName: "formId" })
 * ```
 */
const usePositionFieldArray = <Key extends FieldArrayPath<FieldValues>>(
  props: UseFieldArrayProps<FieldValues, Key, "formId">,
) => useFieldArray(props)

/**
 * Convenience hook which wraps `useController` so you can omit generic type
 * info while working with a well typed return value.
 *
 * @example
 * ```ts
 * const controller = usePositionController({
 *   name: "position.funding_source_allocation_type"
 * })
 * ```
 */
const usePositionController = <Key extends FieldName>(
  props: UseControllerProps<FieldValues, Key>,
) => useController<FieldValues, Key>(props)

const usePositionFormState = <Key extends FieldName>(
  props: Partial<{
    control?: Control<FieldValues> | undefined
    disabled?: boolean
    name?: Key | Key[]
    exact?: boolean
  }>,
) => useFormState(props)

export { usePositionController, usePositionFieldArray, usePositionWatchField, usePositionFormState }
