import fp from "lodash/fp"
import { createSelector } from "reselect"

import { RootState } from "v2/redux/store"

import { cellStateSelectors, errorSetSelectors } from "../DatasheetSlice"
import { buildId, IdObject } from "./cellStateMachines"
import { translateReduxToCellState } from "./translators"

const withFieldKey = (_i1: unknown, _i2: unknown, f: string) => f
const withBool = (_i1: unknown, _id2: unknown, _i3: unknown, i4: boolean) => i4

/**
 * @public
 * @returns an error message for the cell if applicable; undefined otherwise.
 */
export const selectCellErrorMessage = createSelector(
  [errorSetSelectors.selectById, withFieldKey, withBool],
  (errorSet, fieldKey, isFirst) => {
    if (!errorSet) return undefined
    if (isFirst && errorSet.baseErrors.length > 0) return fp.join(". ", errorSet.baseErrors)
    if (fieldKey in errorSet.fieldErrors && errorSet.fieldErrors[fieldKey].length > 0)
      return fp.join(". ", errorSet.fieldErrors[fieldKey])

    // This appeases ESLint.
    return undefined
  },
)

export const selectAnyErrors = (state: RootState) => {
  const errorSets = errorSetSelectors.selectAll(state)
  return errorSets.length > 0
}

export const selectCellState = (obj: IdObject) => (state: RootState) => {
  const storedCellState = cellStateSelectors.selectById(state, buildId(obj))
  return translateReduxToCellState(storedCellState, obj, state.datasheet.cursor)
}
