import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { ReactElement, useState } from "react"
import AnimateHeight from "react-animate-height"

import { useGetPositionForListQuery } from "v2/redux/GraphqlApi"

const ANIMATION_DURATION = 250

interface PositionListItemProps {
  isSaving: boolean
  positionId: string
  onItemRemoved: (event: React.MouseEvent<HTMLElement>) => void
}

const PositionListItem = ({ isSaving, positionId, onItemRemoved }: PositionListItemProps) => {
  const [height, setHeight] = useState<0 | "auto">("auto")
  const handleRemoveValueClick = (e: React.MouseEvent<HTMLElement>) => {
    setHeight(0)
    onItemRemoved(e)
  }
  const { data: positionData, isLoading } = useGetPositionForListQuery({ positionId })

  const position = positionData?.position

  if (!position) return null

  return (
    // For list item animations, an adaptation with credit to:
    // https://muffinman.io/react-animate-height/
    // https://github.com/Stanko/react-animate-height#demo
    <AnimateHeight className="position-list-item" duration={ANIMATION_DURATION} height={height}>
      <div className="position-list-items__content">
        <div className="position-list-items__left">
          <div>{[position.title, position.systemIdentifier].join(", ")}</div>
          <LabelFilledBy
            filledBy={position.filledByFormatted || ""}
            isLoading={isLoading}
            lastFilledBy={position.lastFilledByFormatted || ""}
            positionId={position.uniqueKey || ""}
          />
        </div>
        <div className="position-list-items__right">
          <button
            disabled={isSaving}
            onClick={handleRemoveValueClick}
            data-value={position.uniqueKey}
            type="button"
            className="position-list-items__remove"
          >
            <FontAwesomeIcon icon={["far", "times"]} size="lg" />
          </button>
        </div>
      </div>
    </AnimateHeight>
  )
}
interface LabelFilledByProps {
  filledBy: string
  isLoading: boolean
  lastFilledBy: string
  positionId: string
}

const LabelFilledBy = ({
  filledBy,
  isLoading,
  lastFilledBy,
  positionId,
}: LabelFilledByProps): ReactElement | null => {
  const filledByNames = filledBy !== "" ? filledBy : lastFilledBy
  const label = filledBy !== "" ? "filled_by".t("position") : "last_filled_by".t("position")
  const value = isLoading ? <>&hellip;</> : filledByNames

  if (!isLoading && positionId === "") return null
  if (!isLoading && filledBy === "" && lastFilledBy === "") return null

  // The placeholder here has vertical content, and we only axe it and the
  // filled by section if the results are empty (an open position with no prior
  // people).
  return (
    <div className="position-list-items__subtext">
      {label} {value}
    </div>
  )
}

export { ANIMATION_DURATION, PositionListItem }
