import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import fp from "lodash/fp"

type SuccessionPlanPanelTabType = "summary" | "compare" | "matrix"

interface SuccessionPlanPanelState {
  activeSuccessionPlanPanelTab: SuccessionPlanPanelTabType | null
  activePositionId: string | null
  isOpen: boolean
}

const InitialState: SuccessionPlanPanelState = {
  activePositionId: null,
  activeSuccessionPlanPanelTab: "summary",
  isOpen: false,
}

const SuccessionPlanPanelSlice = createSlice({
  name: "successionPlanPanel",
  initialState: InitialState,
  reducers: {
    closeSuccessionPlanSidebar: (state) => {
      return fp.pipe(fp.set("isOpen", false), fp.set("activePositionId", null))(state)
    },
    openSuccessionPlanSidebar: (
      state,
      { payload }: PayloadAction<{ positionId: string | null }>,
    ) => {
      return fp.pipe(
        fp.set("isOpen", true),
        fp.set("activeSuccessionPlanPanelTab", "summary"),
        fp.set("activePositionId", payload.positionId),
      )(state)
    },
    setActivePositionId: (state, { payload }: PayloadAction<string | null>) =>
      fp.set("activePositionId", payload)(state),
    setActiveSuccessionPlanPanelTab: (
      state,
      { payload }: PayloadAction<SuccessionPlanPanelTabType>,
    ) => fp.set("activeSuccessionPlanPanelTab", payload)(state),
    setIsOpen: (state, { payload }: PayloadAction<boolean>) => fp.set("isOpen", payload)(state),
  },
})

export { SuccessionPlanPanelSlice, SuccessionPlanPanelState }
export const {
  setActiveSuccessionPlanPanelTab,
  closeSuccessionPlanSidebar,
  openSuccessionPlanSidebar,
} = SuccessionPlanPanelSlice.actions
