import classNames from "classnames"
import React from "react"

import { preventPropagationOfSyntheticEvent, useOutsideClick } from "v2/react/hooks/useOutsideClick"

interface Props {
  children: React.ReactNode
  className?: string
  contentWrapperClassName?: string
  ignoreOutsideClick?: boolean
  isOpen: boolean
  toggleOpen: () => void
  toggleClose: () => void
  triggerTextContent: React.ReactNode
  triggerButtonClasses?: string
  rightAlign?: boolean
}

function Dropdown({
  children,
  className,
  contentWrapperClassName,
  ignoreOutsideClick,
  isOpen,
  toggleOpen,
  toggleClose,
  triggerTextContent,
  triggerButtonClasses,
  rightAlign,
}: Props) {
  const dropdownMenuRef = React.useRef<HTMLElement>(null)
  const dropdownRef = useOutsideClick<HTMLDivElement>((ev) => {
    if (ev.target instanceof HTMLElement && dropdownMenuRef.current?.contains(ev.target)) {
      return
    }
    if (!ignoreOutsideClick) toggleClose()
  })
  const alignClass = rightAlign ? "react-dropdown__content--right" : "react-dropdown__content--left"
  const contentClass = classNames("react-dropdown__content", alignClass, contentWrapperClassName)

  return (
    <div
      className={classNames("react-dropdown relative inline", className)}
      onClick={preventPropagationOfSyntheticEvent}
      role="presentation"
      ref={dropdownRef}
    >
      <button className={triggerButtonClasses} type="button" onClick={toggleOpen}>
        {triggerTextContent}
      </button>
      {isOpen ? (
        <section className={contentClass} ref={dropdownMenuRef}>
          {children}
        </section>
      ) : null}
    </div>
  )
}

export { Dropdown }

Dropdown.defaultProps = {
  triggerButtonClasses: "btn",
  rightAlign: true,
}
