import React, { useEffect, useState } from "react"

import { SsoProviderType } from "v2/react/components/company/generalSettings/types"

import { SsoProviderOptionRestrictions } from "./SsoProviderOptionRestrictions"

interface SsoProviderOptionsProps {
  isOktaConfigured: boolean
  onChange: (field: string, updatedData: string[]) => void
  permittedSsoProviders: string[]
  ssoAzureTenantIdRestrictions: string[]
  ssoGoogleDomainRestrictions: string[]
}

function SsoProviderOptions({
  isOktaConfigured,
  onChange,
  permittedSsoProviders,
  ssoAzureTenantIdRestrictions,
  ssoGoogleDomainRestrictions,
}: SsoProviderOptionsProps) {
  const [selectedSsoProviders, setSelectedSsoProviders] = useState<string[]>(
    permittedSsoProviders || [],
  )

  useEffect(() => {
    setSelectedSsoProviders(permittedSsoProviders)
  }, [permittedSsoProviders])

  const handleCheckboxChange = (isSelected: boolean, value: string) => {
    let updatedValues
    if (isSelected && !selectedSsoProviders.includes(value)) {
      updatedValues = [...selectedSsoProviders, value]
    } else if (!isSelected) {
      updatedValues = selectedSsoProviders.filter((p) => p !== value)
    } else {
      updatedValues = selectedSsoProviders
    }
    setSelectedSsoProviders(updatedValues)
    onChange("permittedSsoProviders", updatedValues)
  }

  return (
    <>
      <div>
        <SsoProviderOption
          checked={selectedSsoProviders.includes(SsoProviderType.GOOGLE_SSO)}
          onChange={handleCheckboxChange}
          value={SsoProviderType.GOOGLE_SSO}
        />
        <SsoProviderOptionRestrictions
          initialValues={ssoGoogleDomainRestrictions}
          onChange={onChange}
          providerName={SsoProviderType.GOOGLE_SSO}
          showRestriction={selectedSsoProviders.includes(SsoProviderType.GOOGLE_SSO)}
        />
      </div>
      <div>
        <SsoProviderOption
          checked={selectedSsoProviders.includes(SsoProviderType.AZURE_SSO)}
          onChange={handleCheckboxChange}
          value={SsoProviderType.AZURE_SSO}
        />
        <SsoProviderOptionRestrictions
          initialValues={ssoAzureTenantIdRestrictions}
          onChange={onChange}
          providerName={SsoProviderType.AZURE_SSO}
          showRestriction={selectedSsoProviders.includes(SsoProviderType.AZURE_SSO)}
        />
      </div>
      {isOktaConfigured && (
        <div>
          <SsoProviderOption
            checked={selectedSsoProviders.includes(SsoProviderType.OKTA_SSO)}
            onChange={handleCheckboxChange}
            value={SsoProviderType.OKTA_SSO}
          />
        </div>
      )}
    </>
  )
}

interface SsoProviderOptionProps {
  checked: boolean
  onChange: (isSelected: boolean, value: string) => void
  value: string
}

function SsoProviderOption({ checked, onChange, value }: SsoProviderOptionProps) {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isSelected: boolean = event.currentTarget.checked
    const value = event.currentTarget.value
    onChange(isSelected, value)
  }

  return (
    <label
      className="radio"
      htmlFor={`company-permitted-sso-providers-${value}`}
      style={{ marginTop: ".313rem" }}
    >
      <input
        type="checkbox"
        checked={checked}
        id={`company-permitted-sso-providers-${value}`}
        name="company[permitted_sso_providers][]"
        onChange={handleCheckboxChange}
        value={value}
      />
      {providerLabel(value)}
    </label>
  )
}

const providerLabel = (value: string): string => {
  if (value === SsoProviderType.AZURE_SSO) {
    return "sso_provider_microsoft".t("general_settings")
  }
  if (value === SsoProviderType.GOOGLE_SSO) {
    return "sso_provider_google".t("general_settings")
  }
  return "sso_provider_okta".t("general_settings")
}

export { SsoProviderOptions }
