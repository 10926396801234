import { z } from "zod"

import { TextColors } from "v2/color_coding/color_coder"

import { globals } from "../constants/options"

const DataSchema = z.object({
  avatar: z.string().nullable(),
  id: z.string(),
  klass: z.string(),
  person_id: z.number().nullable(),
  depth: z.number(),
  textColorDomain: z.enum(["darkText", "lightText"]).nullable(),
})
type Data = z.infer<typeof DataSchema>

const openPositionAvatar = `<svg xmlns="http://www.w3.org/2000/svg" width="${globals.avatarHeight}" height="${globals.avatarHeight}"><rect width="${globals.avatarHeight}" height="${globals.avatarHeight}" fill="${TextColors.darkText.extraLight}"/></svg>`
const openPositionAvatarBase64 = `data:image/svg+xml;base64,${btoa(openPositionAvatar)}`

function setImageSource(this: SVGImageElement) {
  return window.d3
    .select(this)
    .attr("href", (d) => d.avatar || d.data?.avatar || openPositionAvatarBase64)
    .attr("data-id", (d) => d.id)
    .attr("data-klass", (d) => d.klass)
}

// This appends the avatar elements to each node in an enter selection.
const appendAvatars = (
  enterSelection: d3.Selection<Data>,
  clickHandler: (this: SVGElement) => void,
) =>
  enterSelection
    .append("svg:image")
    .attr("class", "avatar")
    .attr("x", -(globals.avatarHeight / 2))
    .attr("y", globals.nodePadding)
    .attr("width", globals.avatarHeight)
    .attr("height", globals.avatarHeight)
    .attr("clip-path", (d) => {
      // No clipping for the company node avatar
      if (d.klass === "Company") {
        return ""
      }

      return "url(#avatar-clip-circle)"
    })
    .each(setImageSource)
    .each(clickHandler)

const avatars = Object.freeze({
  append: appendAvatars,
  setImageSource,
})

export default avatars
