import * as F from "@fortawesome/fontawesome-svg-core"

const ATTRIBUTES_WATCHED_FOR_MUTATION = [
  "class",
  "data-prefix",
  "data-icon",
  "data-fa-transform",
  "data-fa-mask",
]

class IconCommandCenter {
  observer: MutationObserver
  abandoned: boolean

  static buildAndMount() {
    const instance = new IconCommandCenter()
    F.config.observeMutations = false
    instance.mount()
    return instance
  }

  static establishCommand() {
    return this.buildAndMount()
  }

  constructor() {
    this.abandoned = false
    this.observer = new MutationObserver((entries) => {
      for (const entry of entries) {
        if (
          entry.type === "attributes" &&
          ATTRIBUTES_WATCHED_FOR_MUTATION.indexOf(entry.attributeName || "NEVER") >= 0
        )
          F.dom.i2svg({ node: entry.target, callback: () => null })
        else if (entry.type === "childList" && entry.addedNodes.length > 0)
          F.dom.i2svg({ node: entry.target, callback: () => null })
      }
    })
  }

  mount() {
    if (this.abandoned) return
    F.dom.i2svg({ node: document.querySelector("body")!, callback: () => null })
    this.watch()
  }

  unmount() {
    if (this.abandoned) return
    this.stopWatching()
  }

  watch() {
    if (this.abandoned) return
    this.observer.observe(document.querySelector("body")!, {
      childList: true,
      attributes: true,
      characterData: true,
      subtree: true,
    })
  }

  stopWatching() {
    if (this.abandoned) return
    this.observer.disconnect()
  }

  abandonCommand() {
    this.stopWatching()
    F.dom.watch()
    this.abandoned = true
  }
}

export { IconCommandCenter }
