import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useTranslation } from "react-i18next"

import { ProfilePanelBoxSetting, ProfilePanelBoxSettingSelection } from "types/graphql"
import { DraggableList } from "v2/react/shared/collection/DraggableList/DraggableList"

import AdminLabel from "./AdminLabel"

interface SettingsBoxesShownProps {
  adminBoxes: ProfilePanelBoxSetting[]
  onReorder: (items: ProfilePanelBoxSettingSelection[]) => void
  onUpdate: (items: ProfilePanelBoxSettingSelection[]) => void
  shownBoxes: ProfilePanelBoxSettingSelection[]
}

const SettingsBoxesShown: React.FC<SettingsBoxesShownProps> = ({
  adminBoxes,
  onReorder,
  onUpdate,
  shownBoxes,
}) => {
  const { t } = useTranslation()
  const dragContainerRef = React.useRef<HTMLDivElement>(null)

  if (!shownBoxes.length) return null

  const handleUpdate = (item: ProfilePanelBoxSettingSelection) => {
    onUpdate([
      ...shownBoxes.filter((box: ProfilePanelBoxSettingSelection) => box.name !== item.name),
    ])
  }

  return (
    <DraggableList
      dragContainerRef={dragContainerRef}
      handleReorder={onReorder}
      items={shownBoxes}
      useListGroupStyle
    >
      {shownBoxes.map((item: ProfilePanelBoxSettingSelection) => (
        <DraggableList.Item
          dragContainerRef={dragContainerRef}
          item={item}
          itemClasses="py-2"
          key={`${item.name}`}
        >
          <div className="w-full gap-3 flex">
            <FontAwesomeIcon
              className="mt-1 items-start self-stretch"
              icon={["fas", "grip-vertical"]}
              size="sm"
            />
            <div className="!m-0 w-full min-w-0 cursor-pointer items-start self-stretch font-medium transition-colors flex">
              <div className="w-full min-w-0 break-words">{t(`v2.profile_panel.${item.name}`)}</div>
              <AdminLabel adminBoxes={adminBoxes} item={item} t={t} />
            </div>
            <div>
              <button
                className="btn--sm btn--secondary"
                onClick={() => handleUpdate(item)}
                type="button"
              >
                {t("v2.defaults.hide")}
              </button>
            </div>
          </div>
        </DraggableList.Item>
      ))}
    </DraggableList>
  )
}

export { SettingsBoxesShown }
