import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { TFunction } from "i18next"
import React, { ReactElement, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"

import { Person, Position, PositionType } from "types/graphql"
import { Avatar } from "v2/react/shared/icons/Avatar"
import { BlueIcon } from "v2/react/shared/icons/BlueIcon"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import { pjaxModalFor } from "v2/react/utils/pjax"
import { UrlHelper } from "v2/react/utils/urls"
import { useLazyGetPositionTypePositionsQuery } from "v2/redux/GraphqlApi/PositionTypesApi"

const PAGE_SIZE = 10

interface Props {
  positionType: PositionType
}

function Positions({ positionType }: Props) {
  const [allPositions, setAllPositions] = React.useState<Position[]>([])
  const { t } = useTranslation()
  const scrollBoxRef = useRef<null | HTMLDivElement>(null)

  // https://stackoverflow.com/questions/62587993/how-to-render-usequery-both-on-first-render-and-onclick-reactjs-graphql
  const [queryPositionsTrigger, { data: positionsData, isLoading: positionDataLoading }] =
    useLazyGetPositionTypePositionsQuery()

  useEffect(() => {
    queryPositionsTrigger({
      id: `position_type_${positionType.id}`,
      first: PAGE_SIZE,
    })
  }, [positionType.id, queryPositionsTrigger])

  useEffect(() => {
    setAllPositions((prevValue) => [
      ...prevValue,
      ...(positionsData?.positionType?.positions?.nodes || []),
    ])
  }, [positionsData])

  useEffect(() => {
    // If subsequent pages of positions are loaded, scroll to the bottom of the
    // list to show the new positions.
    if (scrollBoxRef.current && allPositions.length > PAGE_SIZE) {
      // See: https://stackoverflow.com/a/65371198
      scrollBoxRef.current.scroll({ top: scrollBoxRef.current.scrollHeight, behavior: "smooth" })
    }
  }, [allPositions, scrollBoxRef])

  if (positionDataLoading) return null
  if (!positionsData?.positionType?.totalPositionCount)
    return <EmptyPositions positionTypeId={positionType.id} t={t} />

  const endCursor = positionsData.positionType.positions?.pageInfo?.endCursor
  const nextPageExists = positionsData.positionType.positions?.pageInfo?.hasNextPage

  return (
    <div className="module-card">
      <div className="module-card__header">
        <div className="module-title">
          {t("v2.position_types.show.positions", {
            count: positionsData.positionType.totalPositionCount || 0,
          })}
        </div>
        <button
          className="module-action"
          onClick={() => pjaxModalFor(UrlHelper.newPositionPath(positionType.id))}
          type="button"
        >
          <span>
            <FontAwesomeIcon icon={["far", "plus"]} />
          </span>
          {t("v2.position_types.show.add_position")}
        </button>
      </div>
      <div
        className="module-card__body max-h-[400px] gap-0 overflow-y-auto border-0 border-solid border-neutral-8 p-0"
        ref={scrollBoxRef}
      >
        <PositionRows positions={allPositions} t={t} />
      </div>
      {!positionDataLoading && nextPageExists && (
        <div className="justify-center border-0 border-t border-solid border-neutral-8 p-4 flex">
          <button
            className="bg-white text-neutral-64"
            type="button"
            onClick={() => {
              queryPositionsTrigger({
                id: `position_type_${positionType.id}`,
                first: PAGE_SIZE,
                after: endCursor,
              })
            }}
          >
            <span>{t("v2.position_types.show.view_more", { count: PAGE_SIZE })}</span>
            <span className="pl-2">
              <FontAwesomeIcon icon={["far", "chevron-down"]} />
            </span>
          </button>
        </div>
      )}
      {positionDataLoading && (
        <div className="p-4">
          <Spinner style={{ position: "relative", height: "1rem", width: "1rem" }} />
        </div>
      )}
    </div>
  )
}

const EmptyPositions = ({ positionTypeId, t }: { positionTypeId: string; t: TFunction }) => (
  <div className="module-card">
    <div className="module-card__header">
      <div className="module-title">{t("v2.position_types.show.positions", { count: 0 })}</div>
    </div>

    <div className="module-card__body">
      <div className="flex-col gap-4 pb-8 pt-6 flex">
        <div className="justify-center flex">
          <button
            className="bg-white"
            type="button"
            onClick={() => pjaxModalFor(UrlHelper.newPositionPath(positionTypeId))}
          >
            <BlueIcon icon={["far", "chair-office"]} size="med-lg" />
          </button>
        </div>
        <div className="justify-center text-neutral-64 flex">
          {t("v2.position_types.show.no_positions")}
        </div>
        <div className="justify-center flex">
          <button
            className="btn--large btn--primary"
            onClick={() => pjaxModalFor(UrlHelper.newPositionPath(positionTypeId))}
            type="button"
          >
            <span>
              <FontAwesomeIcon icon={["far", "plus"]} />
            </span>
            {t("v2.position_types.show.add_position")}
          </button>
        </div>
      </div>
    </div>
  </div>
)

const PositionRow = ({ position, t }: { position: Position; t: TFunction }) => {
  const primaryPerson = position.people ? position.people[0] : null
  return (
    <a
      className="border-0 border-t border-solid border-neutral-8 px-4 py-4 text-base text-neutral-100 no-underline duration-200 ease-in-out flex hover:bg-neutral-3-solid hover:no-underline"
      href={UrlHelper.positionShowPath(position?.id || "")}
    >
      <div className="items-center pr-2 flex">
        {primaryPerson?.avatarThumbUrl ? (
          <Avatar person={primaryPerson} size="med" />
        ) : (
          <BlueIcon icon={["far", "chair-office"]} size="med-lg" />
        )}
      </div>
      <div>
        <div>{`${t("v2.position_types.show.position_id")} ${position.systemIdentifier}`}</div>
        <FilledBy filledBy={position.filledBy || []} lastFilledBy={position.lastFilledBy} t={t} />
      </div>
    </a>
  )
}

const PositionRows = ({ positions, t }: { positions: Position[]; t: TFunction }) => {
  if (!positions.length) return null

  return (positions || []).map((position: Position) => (
    <PositionRow position={position} key={position.id} t={t} />
  ))
}

type FilledByProps = {
  filledBy: Person[]
  lastFilledBy?: Person | null
  t: TFunction
}

const FilledBy = ({ filledBy, lastFilledBy, t }: FilledByProps): ReactElement => {
  if (filledBy && filledBy.length > 0) {
    return (
      <div className="text-neutral-64">
        {t("v2.position_types.show.filled_by")}:{" "}
        {filledBy.map((person: Person) => person.name).join(", ")}
      </div>
    )
  }
  if (lastFilledBy && lastFilledBy?.name) {
    return (
      <div className="text-neutral-64">
        {t("v2.position_types.show.last_filled_by")}: {lastFilledBy?.name}
      </div>
    )
  }
  return (
    <div className="w-fit rounded-xl bg-status-caution-light px-1 py-0 text-sm text-status-caution">
      {t("v2.position_types.show.open_position")}
    </div>
  )
}

export { Positions }
