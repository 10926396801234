import React from "react"
import { useTranslation } from "react-i18next"

import { useCurrentActiveSession } from "v2/react/hooks/useSessionHooks"
import { SelectWithWrapper, TextInput } from "v2/react/shared/forms"

import { LocationInput, OrgUnitInputs, PositionImportanceInput } from "./inputs"
import { usePositionFormContext } from "./PositionFormProvider"

function NonPositionManagementContent() {
  const { t } = useTranslation()
  const { hasFeature } = useCurrentActiveSession()
  const {
    positionFormCollections: { employeeTypes },
  } = usePositionFormContext()

  return (
    <>
      <OrgUnitInputs wrapperClassName="mb-6" />
      <LocationInput wrapperClassName="mb-6" />
      <div className="gap-4 flex">
        <div className="mb-6 grow">
          <SelectWithWrapper
            id="position_employee_type_id"
            label={t("v2.simple_form.labels.position.employee_type_id")}
            options={employeeTypes}
            name="position.employeeTypeId"
            useInReactHookForm
          />
        </div>
        <div className="mb-6 grow">
          <TextInput
            id="position_fte"
            name="position.fte"
            label={t("v2.simple_form.labels.position.fte")}
            useInReactHookForm
          />
        </div>
        <div className="mb-6 grow">
          <TextInput
            label={t("v2.simple_form.labels.position.company_code")}
            id="position_company_code"
            name="position.companyCode"
            useInReactHookForm
          />
        </div>
      </div>
      <div className="gap-4 flex">
        <div className="mb-6 grow">
          <TextInput
            className="mb-0"
            label={t("v2.simple_form.labels.position.external_identifier")}
            id="position_external_identifier"
            name="position.externalIdentifier"
            useInReactHookForm
          />
        </div>
      </div>
      {hasFeature("successionPlanning") ? (
        <div className="gap-4 flex">
          <div className="grow">
            <PositionImportanceInput wrapperClassName="!mb-0" />
          </div>
        </div>
      ) : null}
    </>
  )
}

export { NonPositionManagementContent }
