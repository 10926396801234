import React, { ChangeEvent, KeyboardEvent, MouseEvent, useRef, useState } from "react"

import { PositionSeatChangeIntent } from "types/graphql.enums"
import {
  errorMessagesFor,
  prepareErrors,
  useErrors,
} from "v2/react/components/orgChart/OrgChartDatasheet/Modals/errors"
import { usePositionSeatChange } from "v2/react/components/orgChart/OrgChartDatasheet/Modals/hooks/usePositionSeatChange"
import { InputCollection } from "v2/react/shared/forms/InputCollection"
import { TextInput } from "v2/react/shared/forms/TextInput"
import { Modal } from "v2/react/shared/overlay/Modal"
import { SaveFooter } from "v2/react/shared/overlay/Modal/SaveFooter"
import { useDatasheetListenerActions } from "v2/redux/listeners/datasheetListeners"

interface Props {
  modalOpen: boolean
  rowId: string
  personName?: string
  handleModalClose: (saved?: boolean) => void
}

function CreatePersonModal({ modalOpen, rowId, personName, handleModalClose }: Props) {
  const { first, middle, last } = parseFullName(personName) || {}
  const { fieldSaved } = useDatasheetListenerActions()
  const initialFirst = first || ""
  const initialMiddle = middle || ""
  const initialLast = last || ""

  const [formData, setFormData] = useState({
    first_name: initialFirst,
    middle_initial: initialMiddle,
    last_name: initialLast,
  })
  const [isSaving, setIsSaving] = useState(false)
  const { errors, setErrors } = useErrors()

  const firstNameRef = useRef<HTMLInputElement>(null)
  const [saveNewPerson] = usePositionSeatChange()

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSubmit = async (
    event: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
    setIsSaving(true)
    const hasErrors = validationCheck({
      firstName: formData.first_name,
      lastName: formData.last_name,
    })
    if (hasErrors) {
      setIsSaving(false)
      setErrors(hasErrors)
      return
    }

    const response = await saveNewPerson({
      personName: formData,
      rowId,
      intent: PositionSeatChangeIntent.NewPerson,
      cancelOptimisticUpdate: true,
    })

    if (response?.ok) {
      fieldSaved({ id: rowId, fieldKey: "name" })
      handleModalClose(true)
    } else {
      const errors = response?.errors
      setErrors(errors ? prepareErrors(errors) : {})
      setIsSaving(false)
    }
  }

  const focusFirstName = () => firstNameRef.current?.focus()

  // The Cell click/keydown handlers are triggered by closing the modal here, so we need to
  // stop propagation to prevent that.
  const handleModalCloseWithStopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation()
    handleModalClose()
  }

  // The mouseDown handler in webpack/v2/react/shared/Datasheet/Cell.tsx is interfering
  // with selecting inputs in this modal. This is a hack to stop that, but long term
  // we are planning on moving this modal higher up on the DOM and hooking it up
  // to the official™ follow up flow.
  const handleMouseDownPropagation = (e: React.MouseEvent) => e.stopPropagation()

  return (
    <Modal
      isOpen={modalOpen}
      onClose={handleModalCloseWithStopPropagation}
      title={"create person record".t("org_chart")}
      onAfterOpen={focusFirstName}
      shouldReturnFocusAfterClose={false}
    >
      <form onMouseDownCapture={handleMouseDownPropagation}>
        <div className="react-modal__body">
          <InputCollection>
            <TextInput
              inputRef={firstNameRef}
              id="firstName"
              name="first_name"
              label={"First Name".t("profile")}
              errors={errorMessagesFor("first_name", errors)}
              onChange={handleInputChange}
              defaultValue={initialFirst}
            />
            <TextInput
              id="middleInitial"
              name="middle_initial"
              label={"Middle Initial".t("profile")}
              errors={errorMessagesFor("middle_initial", errors)}
              onChange={handleInputChange}
              defaultValue={initialMiddle}
            />
            <TextInput
              id="lastName"
              name="last_name"
              label={"Last Name".t("profile")}
              errors={errorMessagesFor("last_name", errors)}
              onChange={handleInputChange}
              defaultValue={initialLast}
            />
          </InputCollection>
        </div>
        <SaveFooter isSaving={isSaving} onSave={handleSubmit} />
      </form>
    </Modal>
  )
}

const parseFullName = (inputValue = "") => {
  const parts = inputValue.split(" ")
  const name = { first: "", middle: "", last: "" }

  switch (parts.length) {
    case 1:
      name.first = parts[0]
      break
    case 2:
      name.first = parts[0]
      name.last = parts[1]
      break
    case 3:
      name.first = parts[0]
      name.middle = parts[1]
      name.last = parts[2]
      break
    default:
      name.first = parts[0]
      name.middle = parts[1]
      name.last = parts.slice(2).join(" ")
      break
  }

  return name
}

const validationCheck = ({ firstName, lastName }: { firstName: string; lastName: string }) => {
  let firstNameError = ""
  let lastNameError = ""

  if (!firstName.trim().length) {
    firstNameError = `${"First Name".t("profile")} ${"blank_validation_lowercase".t("defaults")}`
  }
  if (!lastName.trim().length) {
    lastNameError = `${"Last Name".t("profile")} ${"blank_validation_lowercase".t("defaults")}`
  }

  if (!firstNameError && !lastNameError) return null

  return {
    first_name: firstNameError,
    last_name: lastNameError,
  }
}

export { CreatePersonModal }
