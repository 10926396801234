import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ControlProps } from "@jsonforms/core"
import React, { FC, useState } from "react"

const JsonNumberInput: FC<ControlProps> = ({ data, id, handleChange, label, path, visible }) => {
  const [number, setNumber] = useState(data)

  const handleChangeEvent = (value: string | number) => {
    setNumber(value)
    handleChange(path, Number(value))
  }

  if (!visible) return null

  return (
    <div className="input-group">
      <label htmlFor={id}>{label}</label>
      <div className="number-input__input-wrapper relative">
        <input
          type="number"
          step={1}
          className="input number-input__input"
          name={id}
          id={id}
          value={number}
          onChange={(event) => handleChangeEvent(event.target.value)}
        />
        <button
          className="number-input__increase"
          type="button"
          onClick={() => handleChangeEvent(Number(number) + 1)}
        >
          <FontAwesomeIcon icon={["fas", "caret-up"]} />
        </button>
        <button
          className="number-input__decrease"
          type="button"
          disabled={number - 1 <= 0}
          onClick={() => handleChangeEvent(Number(number) - 1)}
        >
          <FontAwesomeIcon icon={["fas", "caret-down"]} />
        </button>
      </div>
    </div>
  )
}

export { JsonNumberInput }
