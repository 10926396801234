import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { useSelectList } from "v2/react/hooks/useSelectList"
import { DropdownMenu } from "v2/react/shared/collection/menus/DropdownMenu"
import { Link } from "v2/react/shared/navigation/Links"

interface MoreActionsCellProps<T> {
  activeItem: T
  openDeleteModal?: (activeItem: T) => void
  openEditModal?: (activeItem: T) => void
  viewActiveItem?: (activeItem: T) => string
}

const MoreActionsCell = <T,>({
  activeItem,
  openDeleteModal,
  openEditModal,
  viewActiveItem,
}: MoreActionsCellProps<T>) => {
  const { t } = useTranslation()
  const [showMenu, setShowMenu] = useState(false)

  const { context, floatingStyles, getFloatingProps, refs } = useSelectList({
    showList: showMenu,
    setShowList: setShowMenu,
    floatOverrides: { placement: "bottom-start" },
    offset: -10,
  })

  const handleEdit = () => {
    setShowMenu(false)
    openEditModal?.(activeItem)
  }

  const handleDelete = () => {
    setShowMenu(false)
    openDeleteModal?.(activeItem)
  }

  return (
    <div className="dropdown--react nav-overflow-menu">
      <button
        className="btn dropdown-link"
        type="button"
        onClick={() => setShowMenu((curr) => !curr)}
        ref={refs.setReference}
      >
        <FontAwesomeIcon icon={["far", "ellipsis-h"]} className="icon-14" />
      </button>

      <DropdownMenu
        context={context}
        showList={showMenu}
        floatingRef={refs.setFloating}
        floatingStyles={floatingStyles}
        floatingProps={getFloatingProps}
        wrapperClasses="dropdown-menu !block !w-fit right-0 p-2 z-0 mt-2"
      >
        {viewActiveItem && (
          <Link to={viewActiveItem(activeItem)} classes="dropdown-menu-link">
            <FontAwesomeIcon icon={["far", "eye"]} className="icon-14 mr-2" />
            {t("v2.defaults.view")}
          </Link>
        )}
        {openEditModal && (
          <button className="dropdown-menu-link" type="button" onClick={handleEdit}>
            <FontAwesomeIcon icon={["far", "pencil"]} className="icon-14 mr-2" />
            {t("v2.defaults.edit")}
          </button>
        )}
        {openDeleteModal && (
          <>
            <hr className="my-2" />
            <button
              className="dropdown-menu-link text-status-critical"
              type="button"
              onClick={handleDelete}
            >
              <FontAwesomeIcon icon={["far", "trash-alt"]} className="icon-14 mr-2" />
              {t("v2.defaults.delete")}
            </button>
          </>
        )}
      </DropdownMenu>
    </div>
  )
}

export { MoreActionsCell }
