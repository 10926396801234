import React from "react"

interface ValueProps {
  changeSinceRelativeDate: number
  relativeDate: string
}

export const PercentChange = ({ changeSinceRelativeDate, relativeDate }: ValueProps) => (
  <div className="f-10">
    {changeSinceRelativeDate > 0 && <i className="widget-comparison-arrow far fa-arrow-up" />}
    {changeSinceRelativeDate < 0 && <i className="widget-comparison-arrow far fa-arrow-down" />}
    &nbsp;
    {changeSinceRelativeDate !== 0
      ? "percent_change_since".t("dashboard", null, null, null, [
          Math.abs(changeSinceRelativeDate || 0),
          relativeDate,
        ])
      : "no_change_since".t("dashboard", null, null, null, [relativeDate])}
  </div>
)
