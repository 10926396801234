import { useTranslation } from "react-i18next"

import { Error as GraphQLError } from "types/graphql"
import {
  mapFieldValuesToPositionAttributes,
  mapGraphQLErrorsIntoForm,
} from "v2/react/components/positions/positionForm/helpers"
import { SubmitHandler } from "v2/react/components/positions/positionForm/types"
import { idFromUniqueKey } from "v2/react/utils/uniqueKey"
import {
  PositionFormCollections,
  usePositionsCreateMutation,
  usePositionsUpdateMutation,
} from "v2/redux/GraphqlApi/PositionsApi"

type UseUpsertPositionSubmitHandlerArg = {
  onClose: () => void
  positionFormCollections: PositionFormCollections | undefined
  uniqueKey?: string | null
}

type PositionSavedEventDetail = {
  key: "position.added" | "position.updated"
  id: string | number
  uniqueKey: string
}

function useUpsertPositionSubmitHandler({
  onClose,
  positionFormCollections,
  uniqueKey,
}: UseUpsertPositionSubmitHandlerArg) {
  const [updatePosition, updateState] = usePositionsUpdateMutation()
  const [createPosition, createState] = usePositionsCreateMutation()
  const { t } = useTranslation()

  const save: SubmitHandler = async (fieldValues, setError) => {
    let errors: GraphQLError[] | null | undefined
    let savedUniqueKey: string | null
    const attributes = mapFieldValuesToPositionAttributes(fieldValues)

    try {
      if (uniqueKey) {
        const result = await updatePosition({ id: uniqueKey, attributes }).unwrap()
        errors = result.positionsUpdate?.errors
        savedUniqueKey = uniqueKey
      } else {
        const result = await createPosition({ attributes }).unwrap()
        errors = result.positionsCreate?.errors
        savedUniqueKey = result.positionsCreate?.position?.uniqueKey ?? null
      }

      if (errors?.length) {
        mapGraphQLErrorsIntoForm({
          errors,
          positionFormCollections,
          setError,
          t,
        })

        return { ok: false }
      }

      if (!savedUniqueKey) {
        setError("root.base", { message: t("v2.defaults.error") })
        return { ok: false }
      }

      return { ok: true, uniqueKey: savedUniqueKey }
    } catch (error) {
      if (window.Sentry) window.Sentry.captureException(error)
      setError("root.base", { message: t("v2.defaults.error") })
      return { ok: false }
    }
  }

  const handleSubmit: SubmitHandler = async (fieldValues, setError) => {
    globalThis.NProgress?.start()

    const saveResult = await save(fieldValues, setError)
    if (saveResult.ok) {
      const eventKey = uniqueKey ? "position.updated" : "position.added"
      const event = new CustomEvent<PositionSavedEventDetail>(eventKey, {
        bubbles: true,
        detail: {
          key: eventKey,
          id: idFromUniqueKey(saveResult.uniqueKey),
          uniqueKey: saveResult.uniqueKey,
        },
      })

      onClose()
      globalThis.dispatchEvent(event)
    }

    globalThis.NProgress?.done()

    return saveResult
  }

  return {
    ...(uniqueKey ? updateState : createState),
    handleSubmit,
  }
}

export { useUpsertPositionSubmitHandler }
