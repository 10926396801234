import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import fp from "lodash/fp"

interface RequisitionState {
  editApproversModalOpen: boolean
  editNotifyeessModalOpen: boolean
  editRecruitersModalOpen: boolean
  reqModalOpen: boolean
}

const initialState: RequisitionState = {
  editApproversModalOpen: false,
  editNotifyeessModalOpen: false,
  editRecruitersModalOpen: false,
  reqModalOpen: false,
}

const RequisitionSlice = createSlice({
  name: "approval",
  initialState,
  reducers: {
    setEditApproversModalOpen: (state, { payload }: PayloadAction<boolean>) =>
      fp.set("editApproversModalOpen", payload)(state),
    setEditNotifyeesModalOpen: (state, { payload }: PayloadAction<boolean>) =>
      fp.set("editNotifyeessModalOpen", payload)(state),
    setEditRecruitersModalOpen: (state, { payload }: PayloadAction<boolean>) =>
      fp.set("editRecruitersModalOpen", payload)(state),
    setReqModalOpen: (state, { payload }: PayloadAction<boolean>) =>
      fp.set("reqModalOpen", payload)(state),
  },
})

export const {
  setEditApproversModalOpen,
  setEditNotifyeesModalOpen,
  setEditRecruitersModalOpen,
  setReqModalOpen,
} = RequisitionSlice.actions
export { RequisitionSlice, RequisitionState }
