import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { CurrentCompanyOrgChartSettingsQuery } from "types/graphql.d"
import { gqlFetch } from "v2/graphql_client"
import OperationStore from "v2/operation_store"
import RootProvider from "v2/react/components/RootProvider"
import { Pulser } from "v2/react/shared/loaders/Pulser"
import { Modal } from "v2/react/shared/overlay/Modal"

import { OrgChartDefaultSettingsForm } from "./OrgChartDefaultSettingsForm"
import { DisplayMode } from "./types"

type DisplayFieldOptions = NonNullable<
  CurrentCompanyOrgChartSettingsQuery["currentCompany"]
>["settings"]["orgChart"]["displayFieldOptions"]

const ModuleHeader = ({
  dataLoaded,
  handleEditButtonClick,
}: {
  dataLoaded: boolean
  handleEditButtonClick: () => void
}) => {
  const { t } = useTranslation()
  return (
    <div className="module-card__header">
      <div className="module-title">{t("v2.general_settings.org_chart_default_settings")}</div>
      {dataLoaded && (
        <button
          type="button"
          className="module-action company-settings-org-chart-defaults-action"
          onClick={handleEditButtonClick}
        >
          {t("v2.defaults.edit")}
        </button>
      )}
    </div>
  )
}

const ModuleContent = ({
  selectedFieldsInOrder,
  displayMode,
}: {
  selectedFieldsInOrder: DisplayFieldOptions
  displayMode: DisplayMode
}) => {
  const { t } = useTranslation()
  return (
    <>
      <div className="module-card-list-item">
        <div>{t("v2.general_settings.chart_view")}</div>
        <div>{t(`v2.general_settings.display_mode_${displayMode}`)}</div>
      </div>
      <div className="w-full flex-col gap-1 flex">
        {selectedFieldsInOrder.length ? (
          selectedFieldsInOrder.map((field, i) => {
            const isFirst = i === 0
            return (
              <div key={field.id} data-testid={field.id} className="w-full justify-end flex">
                {isFirst && <div className="mr-auto">{t("v2.general_settings.fields")}</div>}
                <div className="min-w-0 break-words">{field.label}</div>
              </div>
            )
          })
        ) : (
          <div className="module-card-list-item">{t("v2.general_settings.fields")}</div>
        )}
      </div>
    </>
  )
}

function OrgChartDefaultSettingsInner() {
  const [editModalIsOpen, setEditModalIsOpen] = useState(false)
  const [displayFieldOptions, setDataOptions] = useState<DisplayFieldOptions>()
  const [selectedFields, setSelectedFields] = useState<string[]>()
  const [displayMode, setDisplayMode] = useState<DisplayMode>("tree")
  const [forceThreeLevel, setForceThreeLevel] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    const fetchSettings = async () => {
      const operationId = OperationStore.getOperationId("CurrentCompanyOrgChartSettings")
      const result = await gqlFetch<CurrentCompanyOrgChartSettingsQuery>({
        operationId,
      })

      const settings = result.data?.currentCompany?.settings?.orgChart
      if (settings) {
        setSelectedFields(settings.authorizedSelectedDisplayFields)
        setDataOptions(settings.displayFieldOptions)
        setDisplayMode(settings.defaultDisplayMode as DisplayMode)
        setForceThreeLevel(settings.forceThreeLevel as boolean)
      } else {
        // eslint-disable-next-line no-console
        console.error(result.errors)
      }
    }

    fetchSettings()
  }, [])

  const handleEditButtonClick = () => setEditModalIsOpen(true)

  const closeModal = () => setEditModalIsOpen(false)

  const onSaveSuccess = (
    updatedSelectedFields: string[],
    updatedDataOptions: DisplayFieldOptions,
    updatedDisplayMode: DisplayMode,
  ) => {
    setSelectedFields(updatedSelectedFields)
    setDataOptions(updatedDataOptions)
    setDisplayMode(updatedDisplayMode)
    setEditModalIsOpen(false)
  }

  const selectedFieldsInOrder: DisplayFieldOptions | undefined = selectedFields
    ?.map((fieldKey) => displayFieldOptions?.find((field) => field.id === fieldKey))
    .filter((field) => !!field) as DisplayFieldOptions | undefined
  const dataLoaded = !!displayFieldOptions && !!displayMode && !!selectedFieldsInOrder

  return (
    <div>
      <div className="module-card">
        <ModuleHeader dataLoaded={dataLoaded} handleEditButtonClick={handleEditButtonClick} />
        <div className="module-card__body">
          {dataLoaded ? (
            <ModuleContent
              selectedFieldsInOrder={selectedFieldsInOrder}
              displayMode={displayMode}
            />
          ) : (
            <Pulser />
          )}
        </div>
      </div>
      {dataLoaded && (
        <Modal
          isOpen={editModalIsOpen}
          onClose={closeModal}
          title={t("v2.general_settings.edit_org_chart_default_settings")}
        >
          <OrgChartDefaultSettingsForm
            selectedFieldsInOrder={selectedFieldsInOrder}
            displayFieldOptions={displayFieldOptions}
            displayMode={displayMode}
            forceThreeLevel={forceThreeLevel}
            onSaveSuccess={onSaveSuccess}
          />
        </Modal>
      )}
    </div>
  )
}

function OrgChartDefaultSettings() {
  return (
    <RootProvider>
      <OrgChartDefaultSettingsInner />
    </RootProvider>
  )
}

export default OrgChartDefaultSettings
export type { DisplayFieldOptions }
