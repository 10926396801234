import fp from "lodash/fp"

import { DynamicFieldValueInterface, NodeInterface } from "types/graphql.d"

const UniqueKeyRegex = Object.freeze(/^([a-zA-Z_]+)_([1-9][0-9]*)$/)
const DynamicFieldRegex = /^(custom_field|org_unit_type|variable_pay_type)_[1-9][0-9]*(_type)?$/

enum DynamicFieldCollectionNamesByType {
  org_unit_type = "org_units",
  custom_field = "custom_field_values",
  variable_pay_type = "variable_pays",
}

type DynamicFieldTypeKey = keyof typeof DynamicFieldCollectionNamesByType
type DynamicFieldCollectionName = (typeof DynamicFieldCollectionNamesByType)[DynamicFieldTypeKey]

/** @returns {boolean} a value indicating if the field key is the unique key of a dynamic field. */
const isDynamicField = (fieldKey: string) => DynamicFieldRegex.test(fieldKey)

/**
 * Builds a hash of dynamic field keys indexed by their corresponding collection name.
 *
 * @example
 *   const keys = ['org_unit_type_1', 'org_unit_type_2', 'variable_pay_type_1', 'custom_field_1']
 *   groupDynamicFieldKeysByCollectionName(keys)
 *   //=> {
 *   //     org_units: ['org_unit_type_1', 'org_unit_type_2],
 *   //     variable_pays: ['variable_pay_type_1'],
 *   //     custom_field_values: ['custom_field_1]
 *   //   }
 * @example
 *   const keys = ['custom_field_1', 'irrelevant_key']
 *   groupDynamicFieldKeysByCollectionName(keys)
 *   //=> { custom_field_values: ['custom_field_1'] }
 * @param fieldKeys
 * @returns {object}
 */
const groupDynamicFieldKeysByCollectionName = (fieldKeys: string[]) =>
  fp.reduce(
    (memo, fieldKey): { [key in DynamicFieldCollectionName]?: string[] } => {
      const match = fieldKey.match(DynamicFieldRegex)
      const maybeKey = match && match[1]
      if (!maybeKey) return memo

      const key = maybeKey as keyof typeof DynamicFieldCollectionNamesByType
      const collectionName = DynamicFieldCollectionNamesByType[key]
      return fp.update(collectionName, (curr) => fp.concat([fieldKey], curr || []), memo)
    },
    {},
    fieldKeys,
  )

/**
 * @example
 *   const customFields = [{ field_id: 'custom_field_1', formatted_value: 'Ghost' }]
 *   const node = { name: 'Jane Doe', custom_fields: customFields }
 *   maybeNormalizeDynamicField(node, 'custom_fields')
 *   //=> { name: 'Jane Doe', custom_fields: { custom_field_1: 'Ghost' } }
 * @public
 * @returns {NodeInterface} a node with `field` normalized as a hash (if present).
 */
const maybeNormalizeDynamicField = (node: NodeInterface, field: keyof NodeInterface) => {
  const dynamicFieldHashes = node[field] as DynamicFieldValueInterface[]
  if (!dynamicFieldHashes || !Array.isArray(dynamicFieldHashes)) return node

  const normalized = fp.reduce(
    (memo, dynamicFieldHash) =>
      fp.set(dynamicFieldHash.field_id, dynamicFieldHash.formatted_value)(memo),
    {},
    dynamicFieldHashes,
  )

  return fp.pipe(
    fp.set(field, normalized),
    fp.set(`${field}_original`, dynamicFieldHashes),
    updateEditableFieldsIfPresent(dynamicFieldHashes),
  )(node)
}

function updateEditableFieldsIfPresent(dynamicFieldValues: DynamicFieldValueInterface[]) {
  return (node: NodeInterface) => {
    if (!node.editable_fields) return node

    const whereCanEdit = fp.filter<DynamicFieldValueInterface>(fp.propEq("can_edit", true))
    const mapToFieldId = fp.map<DynamicFieldValueInterface, "field_id">("field_id")
    const unionWithEditableFields = fp.union(node.editable_fields)
    const getNextEditableFields = fp.pipe(whereCanEdit, mapToFieldId, unionWithEditableFields)

    return fp.set("editable_fields", getNextEditableFields(dynamicFieldValues), node)
  }
}

/**
 * @public
 * @returns {NodeInterface} a node with all present dynamic field collections normalized as hashes.
 */
const normalizeDynamicFields = (node: NodeInterface): NodeInterface => {
  if (!node) return node
  const fields: (keyof NodeInterface)[] = ["custom_field_values", "org_units", "variable_pays"]
  return fields.reduce(maybeNormalizeDynamicField, node)
}

/**
 * @public
 * @returns {number} an integer id from a string key.
 */
const stringKeyToNumericId = (stringKey: string) =>
  fp.pipe(fp.replace(UniqueKeyRegex, "$2"), parseFloat)(stringKey)

export {
  DynamicFieldRegex,
  groupDynamicFieldKeysByCollectionName,
  isDynamicField,
  maybeNormalizeDynamicField,
  normalizeDynamicFields,
  stringKeyToNumericId,
}
