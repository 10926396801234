import React from "react"

import { Person } from "types/graphql"
import { PersonList } from "v2/react/shared/collection/PersonList"
import { PersonSearchInput } from "v2/react/shared/forms/PersonSearchInput"
import { personNameAsc } from "v2/react/utils/sorts"

interface Props {
  people: Person[]
  onPeopleUpdated: (selectedPeople: Person[]) => void
  description: string
  title: string
}

function PersonSelection({ description, onPeopleUpdated, people, title }: Props) {
  const onPersonRemove = (removedPerson: Person) => {
    const updatedPeople: Person[] = (people || []).filter((p) => p.id !== removedPerson.id)
    onPeopleUpdated(updatedPeople)
  }

  const onPersonSelect = (newPerson: Person) => {
    const updatedPeople: Person[] = [...people, newPerson]
    onPeopleUpdated(updatedPeople.slice().sort(personNameAsc))
  }

  return (
    <div className="approvals-selection-form-section">
      <div className="approvals-selection-form-title">{title}</div>
      <div className="approvals-selection-form-description">{description}</div>
      <PersonSearchInput
        htmlForId="person_search_input"
        omitValues={(people || []).map((p) => p.id)}
        onSelect={onPersonSelect}
        placeholder={`${"example_abbreviated".t("defaults")}: ${"example_name".t("defaults")}`}
      />
      <div className="mt-4">
        <PersonList isSaving={false} onRemove={onPersonRemove} selectedPeople={people} />
      </div>
    </div>
  )
}

export { PersonSelection }
