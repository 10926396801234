import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { range } from "lodash"
import React from "react"

import { HeadcountPlanParticipant } from "types/graphql.d"
import { HeadcountPlanParticipantStatus } from "types/graphql.enums"

interface Props {
  continuationLineLevels: number[]
  headcountPlanId: string
  isLastSibling: boolean
  level: number
  participant: HeadcountPlanParticipant
}

function ParticipantTableRow({
  continuationLineLevels,
  headcountPlanId,
  isLastSibling,
  level,
  participant,
}: Props) {
  return (
    <tr>
      <td>
        <div className="hcp-overview-table__environment-label">
          {level > 0 &&
            range(level - 1).map((i) => (
              <div key={i} className="hcp-overview-table__node-label">
                {hasConnectionLineToDownlineSibling(continuationLineLevels, i + 1) && (
                  <div className="hcp-overview-table__node-line-connect-sibling" />
                )}
              </div>
            ))}
          <div className={classNames("hcp-overview-table__node-label", { "w-0": level === 0 })}>
            {level > 0 && (
              <>
                {isLastSibling ? (
                  <div className="hcp-overview-table__node-line-last-sibling" />
                ) : (
                  <div className="hcp-overview-table__node-line-sibling" />
                )}

                <div className="hcp-overview-table__node-line-horizontal" />
              </>
            )}
          </div>
          <FontAwesomeIcon icon={["far", "sitemap"]} className="fa-w-14" />
          <a
            href={`/headcount_plans/${headcountPlanId}/participants/${participant.id}`}
            className="hcp-overview-table__env-link"
          >
            {participant.position?.title || "untitled_position".t("headcount_plan")}
          </a>
        </div>
      </td>
      <td>
        <div className="items-center flex">
          {participant.person.avatarThumbUrl && (
            <img
              className="circle mr-2"
              src={participant.person.avatarThumbUrl}
              alt={participant.person.name}
            />
          )}
          <span>{participant.person.name}</span>
        </div>
      </td>
      <td>{departmentName(participant)}</td>
      <td>{participant.status && <StatusBadge status={participant.status} />}</td>
    </tr>
  )
}

const StatusBadge = ({ status }: { status: HeadcountPlanParticipantStatus }) => {
  const className = `badge--${mapStatusToColor(status)}`
  return <div className={className}>{`participant_status_${status}`.t("headcount_plan")}</div>
}

const mapStatusToColor = (status: HeadcountPlanParticipantStatus) => {
  switch (status) {
    case HeadcountPlanParticipantStatus.Complete:
      return "purple"
    case HeadcountPlanParticipantStatus.InProgress:
      return "yellow"
    case HeadcountPlanParticipantStatus.Invited:
      return "gray"
    default:
      return "gray"
  }
}

const departmentName = (participant: HeadcountPlanParticipant): string => {
  if (!participant.position?.orgUnits) return ""
  const departmentUnits = participant.position.orgUnits.filter(
    (u) => u.orgUnitType?.name === "Department",
  )
  if (departmentUnits.length < 1) return ""
  return departmentUnits[0].name as string
}

const hasConnectionLineToDownlineSibling = (continuationLineLevels: number[], level: number) =>
  continuationLineLevels.includes(level)

export { ParticipantTableRow }
