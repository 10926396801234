import classNames from "classnames"
import fp from "lodash/fp"
import React, { useEffect, useRef, useState } from "react"

import { ChartSectionDropdown } from "v2/react/components/orgChart/SuperPanel/ChartSectionsTab/ChartSectionDropdown"
import { ChartSectionExpandToggle } from "v2/react/components/orgChart/SuperPanel/ChartSectionsTab/ChartSectionExpandToggle"
import { convertHextoRGBA } from "v2/react/utils/colors"
import { collapseOrExpandChartSection } from "v2/redux/slices/ContainerSlice"
import { selectIsChartSectionCollapsed } from "v2/redux/slices/ContainerSlice/containerSelectors"
import type { ChartSectionTreeNode } from "v2/redux/slices/ContainerSlice/types"
import { selectIsFilterActive } from "v2/redux/slices/GridSlice/gridSelectors"
import { asyncToggleChartSectionFilter } from "v2/redux/slices/GridSlice/gridThunks"
import { selectShowChartSectionColors } from "v2/redux/slices/VisualizationSlice/visualizationSelectors"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

const BASE_SECTION_HEIGHT = 34 // height = 32 + border = 2
interface ChartSectionRowProps {
  chartSection: ChartSectionTreeNode
  editingDisabled: boolean
  /**
   * Indicates that the user has proper access to edit the chart section. This
   * does not implicate `editingDisabled`.
   */
  hasAccessToEdit: boolean
  previousSectionTall?: boolean
}

function ChartSectionRow({
  chartSection,
  editingDisabled,
  hasAccessToEdit,
  previousSectionTall,
}: ChartSectionRowProps) {
  const { id, color, name, level } = chartSection
  const enableChartSections = useAppSelector(selectShowChartSectionColors)
  const sectionRowRef = useRef<HTMLDivElement>(null)
  const [sectionRowHeight, setSectionRowHeight] = useState(BASE_SECTION_HEIGHT)
  const [isHovered, setHovered] = useState(false)

  const isCollapsed = useAppSelector((state) => selectIsChartSectionCollapsed(state, id))
  const isFiltered = useAppSelector((state) => selectIsFilterActive(state, "chart_section", name))
  const displayMode = useAppSelector((state) => state.visualization.displayMode)
  const dispatch = useAppDispatch()

  const handleToggleChartSectionFilterClick = () =>
    dispatch(asyncToggleChartSectionFilter(id, name))
  const handleToggleChartSectionCollapsedClick = () =>
    dispatch(collapseOrExpandChartSection({ id }))

  useEffect(() => {
    if (sectionRowRef.current) {
      const height = sectionRowRef.current.offsetHeight
      setSectionRowHeight(height)
    }
  }, [sectionRowRef])

  const rowClasses = classNames({
    "chart-section-row": true,
    filtered: isFiltered,
    collapsed: isCollapsed,
  })

  const sectionClasses = classNames("section-content flex min-h-[2rem] pl-4", {
    "section-row--tall": sectionRowHeight > BASE_SECTION_HEIGHT,
    "previous-section--tall": previousSectionTall,
  })

  const colorSolidStyle =
    enableChartSections && displayMode !== "grid"
      ? convertHextoRGBA(color, 1)
      : "rgba(012, 020, 075, .03)"
  const colorOpaqueStyle =
    enableChartSections && displayMode !== "grid"
      ? convertHextoRGBA(color, 0.16)
      : "rgba(012, 020, 075, .03)"

  const activeStyles = {
    backgroundColor: colorOpaqueStyle,
    border: `1px solid ${colorSolidStyle}`,
    transition: "all 200ms ease-in",
  }

  const mapChildrenSections = fp.map((childSection: ChartSectionTreeNode) => (
    <ChartSectionRow
      key={`chart-section-row-${childSection.id}`}
      chartSection={childSection}
      editingDisabled={editingDisabled}
      hasAccessToEdit={hasAccessToEdit}
      previousSectionTall={sectionRowHeight > BASE_SECTION_HEIGHT}
    />
  ))

  return (
    <li className={rowClasses}>
      <div
        className={classNames(
          "color-wrapper mb-1.5 rounded border border-solid border-transparent",
        )}
        ref={sectionRowRef}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        style={isFiltered || isHovered ? activeStyles : {}}
      >
        <div className={sectionClasses} style={{ paddingLeft: `${level + 1.4}rem` }}>
          {displayMode !== "grid" && (
            <span
              className={classNames("color-box mb-1.5 w-2 rounded", {
                "border border-solid border-neutral-8": enableChartSections,
              })}
              style={{
                height: `${sectionRowHeight}px`,
                backgroundColor: enableChartSections ? colorSolidStyle : "transparent",
              }}
            />
          )}
          {chartSection.descendantsCount > 0 && (
            <ChartSectionExpandToggle
              onClick={handleToggleChartSectionCollapsedClick}
              wrapperClasses={sectionRowHeight > BASE_SECTION_HEIGHT ? "!top-[4px]" : ""}
            />
          )}

          <button className="filter" onClick={handleToggleChartSectionFilterClick} type="button">
            {name}
          </button>

          <ChartSectionDropdown
            editingDisabled={editingDisabled}
            hasAccessToEdit={hasAccessToEdit}
            id={id}
            isFiltered={isFiltered}
            isHovered={isHovered}
            name={name}
          />
        </div>
      </div>

      {chartSection.descendantsCount > 0 ? (
        <ul className={classNames({ hidden: isCollapsed })}>
          {mapChildrenSections(chartSection.children)}
        </ul>
      ) : null}
    </li>
  )
}

export { ChartSectionRow }
