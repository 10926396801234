import {
  ColumnDef,
  getCoreRowModel,
  OnChangeFn,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table"
import { useMemo } from "react"

import { updatePagination, updateSearch, updateSort } from "v2/redux/slices/TableSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

/**
 * When you want to manage table state (sorting, pagination, filtering, etc.)
 * manually (i.e. on the server), this hook comes in handy.
 *
 * If, by contrast, you want to load all the data at once and let the client
 * handle sorting, filtering, and pagination, see `useClientTable`.
 */
const useServerTable = <TData extends object>({
  data,
  columns,
  sorting,
  pagination,
  onSortingChange,
  onPaginationChange,
  totalRows,
}: {
  data: TData[]
  // When using `createColumnHelper`, to build table columns, the types get
  // pretty specific, so we need to allow for any type here.
  // see: https://github.com/TanStack/table/discussions/5218
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<TData, any>[]
  sorting: SortingState
  pagination: PaginationState
  onSortingChange: OnChangeFn<SortingState>
  onPaginationChange: OnChangeFn<PaginationState>
  totalRows: number
}) => {
  const pageCount = useMemo(
    () => (totalRows ? Math.ceil(totalRows / pagination.pageSize) : -1),
    [pagination.pageSize, totalRows],
  )

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      pagination,
    },
    pageCount,
    onSortingChange,
    onPaginationChange,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    manualPagination: true,
  })

  return table
}

/**
 * This hook is useful for managing the sorting, pagination, and search state of
 * a table that is backed by a server. It uses the TableSlice to manage the
 * state.
 */
const useTableState = () => {
  const sorting = useAppSelector((state) => state.table.sorting)
  const pagination = useAppSelector((state) => state.table.pagination)
  const search = useAppSelector((state) => state.table.search)
  const dispatch = useAppDispatch()

  // see: https://stackoverflow.com/questions/74721400/tanstack-table-how-can-i-use-redux-action-in-onpaginationchange-instead-of-set
  const setSorting: OnChangeFn<SortingState> = (updater) => {
    if (typeof updater === "function") {
      const nextState = updater(sorting)
      dispatch(updateSort(nextState))
    }
  }

  const setPagination: OnChangeFn<PaginationState> = (updater) => {
    if (typeof updater === "function") {
      const nextState = updater(pagination)
      dispatch(updatePagination(nextState))
    }
  }

  const setSearch = (search: string) => {
    dispatch(updateSearch(search))
  }

  return { sorting, pagination, search, setSorting, setPagination, setSearch }
}

export { useServerTable, useTableState }
