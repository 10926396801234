import React from "react"

import { Collections, HeadcountPlan, HeadcountPlanParticipant } from "types/graphql"
import { HeadcountPlanDatasheet } from "v2/react/components/headcountPlanning/HeadcountPlanDatasheet"
import { useHeadcountPlanDatasheet } from "v2/react/hooks/useHeadcountPlanDatasheet"

import { ParticipantBanner } from "./ParticipantBanner"

interface Props {
  headcountPlanId: string
  participantId: string
  displayState: "approved" | "original"
  setDisplayState: (value: "approved" | "original") => void
  headcountPlan: HeadcountPlan | undefined
  participant: HeadcountPlanParticipant | undefined
  dropdownCellCollections: Collections | undefined
  csvDownloadRef: React.RefObject<HTMLButtonElement>
  showGroupByDropzone?: boolean
}

export function ParticipantDatasheet({
  headcountPlanId,
  participantId,
  displayState,
  setDisplayState,
  headcountPlan,
  participant,
  dropdownCellCollections,
  csvDownloadRef,
  showGroupByDropzone,
}: Props) {
  const position = participant?.position
  const person = participant?.person
  const isFinalized = !!headcountPlan?.lockedAt
  const hasSubmitted = participant?.status === "complete"
  const currentDate = new Date().toISOString().split("T")[0].replace(/-/g, "")
  const csvDownloadName = `${headcountPlan?.name}_${person?.name}_${currentDate}`.replace(/\s/g, "")

  const { rows, columns } = useHeadcountPlanDatasheet({
    attributes: headcountPlan?.allowedAttributes ?? [],
    positions:
      (isFinalized && displayState === "approved"
        ? participant?.approvedPositions
        : participant?.allPositionsInPlan) ?? [],
    dropdownCellCollections: dropdownCellCollections ?? {},
    hideOptions: hasSubmitted,
    showStatus: isFinalized && displayState === "original",
    newPositionIdPrefix: headcountPlan?.newPositionIdPrefix,
    showFinalizedAndActiveOptions: headcountPlan?.isActive && displayState === "approved",
  })

  return (
    <>
      <ParticipantBanner
        displayState={displayState}
        setDisplayState={setDisplayState}
        headcountPlan={headcountPlan}
        participant={participant}
      />
      <HeadcountPlanDatasheet
        csvDownloadRef={csvDownloadRef}
        csvDownloadName={csvDownloadName}
        headcountPlanId={headcountPlanId}
        headcountPlan={headcountPlan}
        participantId={participantId}
        participantPositionId={position?.id}
        rows={rows}
        columns={columns}
        readOnly={hasSubmitted}
        showGroupByDropzone={showGroupByDropzone}
      />
    </>
  )
}
