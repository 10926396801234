import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import OrgChart from "org_chart/chart/orgChart"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

export interface Props {
  chart: OrgChart | null
}

export function OrgChartViewOptions({ chart }: Props) {
  const { t } = useTranslation()
  const [chartExpanded, setChartExpanded] = useState(true)
  const [zoomInDisabled, setZoomInDisabled] = useState(false)
  const [zoomOutDisabled, setZoomOutDisabled] = useState(false)

  if (!chart) return <span />

  const toggle = () => {
    if (chartExpanded) collapseChart()
    else expandChart()

    centerRoot()
  }

  const collapseChart = () => {
    chart.collapseChartToDepth()
    setChartExpanded(false)
  }

  const expandChart = () => {
    chart.expandRecursively(chart.getRootNode().attributes)
    setChartExpanded(true)
  }

  const centerRoot = () => {
    chart.center(chart.getRootNode())
  }

  const zoomIn = () => zoom(chart.getZoom() + 0.1)

  const zoomOut = () => zoom(chart.getZoom() - 0.1)

  const zoom = (scale: number) => {
    chart.zoom(scale)
    handleZoomButtons()
  }

  const handleZoomButtons = () => {
    if (chart.getZoom().toFixed(2) >= chart.getMaxZoom().toFixed(2)) setZoomOutDisabled(true)
    else setZoomOutDisabled(false)

    if (chart.getZoom().toFixed(2) <= chart.getMinZoom().toFixed(2)) setZoomInDisabled(true)
    else setZoomInDisabled(false)
  }

  return (
    <div className="absolute -bottom-12 right-4">
      <span className="chart-controls border--main rounded-md p-[1px] flex">
        <button
          type="button"
          className="btn btn--secondary tooltip tooltip-right rounded-r-none !shadow-none hover:shadow-none"
          onClick={centerRoot}
        >
          <FontAwesomeIcon icon={["far", "home"]} />
          <span className="tooltip-content tooltip-content--sm">
            {t("v2.orgchart.view_options.home")}
          </span>
        </button>
        <button
          type="button"
          className="btn btn--secondary tooltip tooltip-right rounded-none !shadow-none hover:shadow-none"
          onClick={toggle}
        >
          <FontAwesomeIcon icon={["far", chartExpanded ? "compress-alt" : "expand-alt"]} />
          <span className="tooltip-content tooltip-content--sm">
            {chartExpanded
              ? t("v2.orgchart.view_options.collapse")
              : t("v2.orgchart.view_options.expand")}
          </span>
        </button>
        <button
          type="button"
          className={cn(
            "btn btn--secondary tooltip tooltip-right rounded-none !shadow-none hover:shadow-none",
            { disabled: zoomInDisabled },
          )}
          onClick={zoomOut}
        >
          <FontAwesomeIcon icon={["far", "search-minus"]} />
          <span className="tooltip-content tooltip-content--sm">
            {t("v2.orgchart.view_options.zoom_out")}
          </span>
        </button>
        <button
          type="button"
          className={cn(
            "btn btn--secondary tooltip tooltip-right rounded-l-none !shadow-none hover:shadow-none",
            { disabled: zoomOutDisabled },
          )}
          onClick={zoomIn}
        >
          <FontAwesomeIcon icon={["far", "search-plus"]} />
          <span className="tooltip-content tooltip-content--sm">
            {t("v2.orgchart.view_options.zoom_in")}
          </span>
        </button>
      </span>
    </div>
  )
}
