import fp from "lodash/fp"
import React from "react"
import { createRoot } from "react-dom/client"
import { useTranslation } from "react-i18next"

import { appContainer } from "v2/appContainer"
import { ColorCodingTabContent } from "v2/react/components/orgChart/SuperPanel/ColorCodingTab/ColorCodingTabContent"
import { DrawerTab } from "v2/react/components/orgChart/SuperPanel/DrawerTab"
import { DrawerTabFragment } from "v2/react/components/orgChart/SuperPanel/DrawerTabFragment"
import { setContainerKey } from "v2/redux/slices/ContainerSlice"
import { asyncPatchPreferences } from "v2/redux/slices/ContainerSlice/containerThunks"
import { configureColorCoding } from "v2/redux/slices/VisualizationSlice"
import { selectColorCodingConfigForActiveTab } from "v2/redux/slices/VisualizationSlice/visualizationSelectors"
import { useAppDispatch, useAppSelector } from "v2/redux/store"
import { StoreProvider } from "v2/redux/StoreProvider"

/**
 * Encapsulates the entire Color Coding tab within the Super Panel. Use
 * `mountStandaloneColorCodingTab` to create and mount a standalone variant of
 * this tab (useful for our tree/three-level/card views).
 */
function ColorCodingTab() {
  const { t } = useTranslation()
  const { isEnabled } = useAppSelector(selectColorCodingConfigForActiveTab)
  const dispatch = useAppDispatch()

  const handleClose = () => dispatch(asyncPatchPreferences({ super_panel_open: false }))

  return (
    <DrawerTab
      id="tab-color-coding"
      isActive={isEnabled}
      onClickClose={handleClose}
      title={t("v2.orgchart.utilitynav.color_coding")}
    >
      <ColorCodingTabContent />
    </DrawerTab>
  )
}

type ColorCodingConfig = {
  cardColorField: string
  isCardColorEnabled: boolean
  edgeColorField: string
  isEdgeColorEnabled: boolean
  isActive?: boolean
  activeColorCodingTab?: "card" | "edge"
  onCloseClick?: () => void
}

const pickColorCodingConfigForState = fp.pick([
  "cardColorField",
  "isCardColorEnabled",
  "edgeColorField",
  "isEdgeColorEnabled",
  "activeColorCodingTab",
])

function mountStandaloneColorCodingTab(container: Element, initialConfig: ColorCodingConfig) {
  const store = appContainer.reduxStore
  store.dispatch(setContainerKey(window.gon.chart_settings_key))
  store.dispatch(configureColorCoding(pickColorCodingConfigForState(initialConfig)))

  createRoot(container).render(
    <StoreProvider>
      <DrawerTabFragment
        onClickClose={initialConfig.onCloseClick}
        isActive={!!initialConfig.isActive}
        title={"Color Coding".t("org_chart")}
      >
        <ColorCodingTabContent useLegacyEventsToTriggerRefetch />
      </DrawerTabFragment>
    </StoreProvider>,
  )
}

export { ColorCodingTab, mountStandaloneColorCodingTab }
