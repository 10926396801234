/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState } from "react"
import { useForm } from "react-hook-form"

import { FormStyleProvider } from "v2/react/shared/forms/FormStyleProvider"
import { DEFAULT_POSITION_FORM_ABILITIES } from "v2/redux/GraphqlApi/PositionsApi"

import { BaseFormErrors } from "./BaseFormErrors"
import { mapPositionDataToFieldValues } from "./helpers"
import { NonPositionManagementContent } from "./NonPositionManagementContent"
import { PositionDetails } from "./PositionDetails"
import { PositionFormProvider } from "./PositionFormProvider"
import { PositionManagementTabs } from "./positionManagementTabs"
import { FieldValues, PositionData, PositionFormProps } from "./types"

const DEFAULT_POSITION_DATA: PositionData = {}

/** Based on app/views/v2/positions/_form.html.slim */
function PositionForm({
  chartKey,
  children,
  defaults,
  positionData = DEFAULT_POSITION_DATA,
  positionFormAbilities = DEFAULT_POSITION_FORM_ABILITIES,
  positionFormCollections,
  onSubmit,
}: React.PropsWithChildren<PositionFormProps>) {
  const [defaultValues] = useState(() =>
    mapPositionDataToFieldValues(positionData, positionFormCollections, defaults),
  )
  const methods = useForm<FieldValues>({
    defaultValues,
  })
  const formRef = useRef<HTMLFormElement>(null)
  const boundSubmitFn = (data: FieldValues, ev?: React.BaseSyntheticEvent) => {
    onSubmit?.(data, methods.setError, ev)
  }
  const handleSubmit: React.FormEventHandler = (ev) => {
    if (!onSubmit) return

    // Clear errors before trying to submit. If we embrace client side
    // validations, we may want to revisit this.
    methods.clearErrors()
    methods.handleSubmit(boundSubmitFn)(ev)
  }

  return (
    <PositionFormProvider
      {...methods}
      chartKey={chartKey}
      initialFieldValues={defaultValues}
      positionData={positionData}
      positionFormAbilities={positionFormAbilities}
      positionFormCollections={positionFormCollections}
    >
      <FormStyleProvider defaultErrorIconPlacement="top">
        <form ref={formRef} onSubmit={handleSubmit}>
          {children}
        </form>
      </FormStyleProvider>
    </PositionFormProvider>
  )
}

PositionForm.BaseFormErrors = BaseFormErrors
PositionForm.NonPositionManagementContent = NonPositionManagementContent
PositionForm.PositionDetails = PositionDetails
PositionForm.PositionManagementTabs = PositionManagementTabs

export { PositionForm, NonPositionManagementContent }
export * from "./helpers"
export * from "./hooks"
export * from "./BaseFormErrors"
export * from "./PositionDetails"
export * from "./PositionFormProvider"
export * from "./positionManagementTabs"
export * from "./types"
