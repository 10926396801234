import React, { useCallback, useMemo, useRef } from "react"

import { ScreenStackContext, ScrollToArg } from "v2/redux/slices/AdpChangeSlice"

/**
 * @see {@link webpack/v2/redux/slices/AdpChangeSlice/hooks.ts}
 */
export function ScreenStackProvider({ children }: React.PropsWithChildren) {
  const latestScrollTopRef = useRef(0)
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const changeLatestScrollTop = useCallback(
    (val: number) => {
      latestScrollTopRef.current = val
    },
    [latestScrollTopRef],
  )
  const scrollTo = useCallback(
    (arg: ScrollToArg) => scrollContainerRef.current?.scrollTo(arg),
    [scrollContainerRef],
  )

  const value = useMemo(
    () => ({
      changeLatestScrollTop,
      latestScrollTopRef,
      scrollContainerRef,
      scrollTo,
    }),
    [changeLatestScrollTop, latestScrollTopRef, scrollContainerRef, scrollTo],
  )

  return <ScreenStackContext.Provider value={value}>{children}</ScreenStackContext.Provider>
}
