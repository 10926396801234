import { SaveApproverStateInput } from "types/graphql.d"
import OperationStore from "v2/operation_store"
import { GraphqlApi } from "v2/redux/GraphqlApi"

const saveApproverStateOperationId = OperationStore.getOperationId("SaveApproverState")

const ApprovalsApi = GraphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    saveApproverState: builder.mutation({
      query: (params: { input: SaveApproverStateInput }) => ({
        operationId: saveApproverStateOperationId,
        variables: { input: params.input },
      }),
      invalidatesTags: (result) =>
        result.saveApproverState?.errors.length > 0 ? [] : ["Approvers", "JobRequisition"],
    }),
  }),
})

export const { useSaveApproverStateMutation } = ApprovalsApi
