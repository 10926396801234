import React from "react"
import { useTranslation } from "react-i18next"

import { PositionNestedJobRequisition } from "types/graphql"
import { SendToAts } from "v2/react/pages/org_chart"
import { StatusBadge } from "v2/react/shared/status/StatusBadge"

type InternalReqInfoProps = {
  requisitions: PositionNestedJobRequisition[]
}

function InternalReqInfo({ requisitions }: InternalReqInfoProps) {
  const { t } = useTranslation()

  const approvalStateLast = (req: PositionNestedJobRequisition) =>
    !(req.approvalState && !req.clientIdentifier && req.approvalState === "approved") &&
    !(req.status && req.clientIdentifier)

  return (
    <div className="w-full">
      {requisitions.map((req, i) => (
        <div key={req.id}>
          <div className="-mt-4 border-0 border-b border-solid border-b-neutral-8-solid">
            <div className="items-center justify-between p-4 flex">
              <div>{t("v2.job_requisitions.fields.req_id")}</div>
              <div>{req.systemUid}</div>
            </div>
          </div>
          <div
            className={
              approvalStateLast(req) && i + 1 === requisitions.length
                ? "-mb-4"
                : "border-0 border-b border-solid border-b-neutral-8-solid"
            }
          >
            <div className="items-center justify-between p-4 flex">
              <div>{t("v2.job_requisitions.fields.req_status")}</div>
              <div>
                {req.approvalState ? (
                  <StatusBadge status={req.approvalState} displayType="button" />
                ) : (
                  t("v2.defaults.dash")
                )}
              </div>
            </div>
          </div>
          {req.approvalState && !req.clientIdentifier && req.approvalState === "approved" && (
            <div
              className={
                (req.status && req.clientIdentifier) || i + 1 < requisitions.length
                  ? "border-0 border-b border-solid border-b-neutral-8-solid"
                  : "-mb-4"
              }
            >
              <div className="items-center justify-between p-4 flex">
                <div className="whitespace-nowrap">
                  {t("v2.job_requisitions.fields.external_req_id")}
                </div>
                <SendToAts
                  asLink={false}
                  asDropdownMenuLink={false}
                  asButton
                  jobRequisitionId={req.id}
                  systemUid={req.systemUid ?? undefined}
                  title={req.jobTitle}
                  replacementFor={req.replacementFor ?? ""}
                  rmInfoOnly
                  positionId=""
                  orgUnitNames={[]}
                  locationName=""
                  jobDescription={req.jobDescription ?? ""}
                  numPositions={Number(req.numPositions)}
                />
              </div>
            </div>
          )}
          {req.status && req.clientIdentifier && (
            <div className="border-0 border-b border-solid border-b-neutral-8-solid">
              <div className="items-center justify-between p-4 flex">
                <div>{t("v2.job_requisitions.fields.external_req_id")}</div>
                <div>{req.clientIdentifier}</div>
              </div>
            </div>
          )}
          {req.status && req.clientIdentifier && (
            <div
              className={
                i + 1 < requisitions.length
                  ? "border-0 border-b border-solid border-b-neutral-8-solid"
                  : "-mb-4"
              }
            >
              <div className="items-center justify-between p-4 flex">
                <div>{t("v2.job_requisitions.fields.external_req_status")}</div>
                <div>
                  {req.status ? <StatusBadge status={req.status} displayType="button" /> : "-"}
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export { InternalReqInfo }
