import $ from "jquery"
import ReactRailsUJS from "react_ujs"

const BASE_URL = "/orgchart/metrics"
const SELECTOR = "#org-chart-metrics"

class ChartMetrics {
  constructor(type = "full_chart", topId = null) {
    this.type = type
    this.topId = topId
    this.$element = $(SELECTOR)
  }

  /**
   * @param {string} type The type of chart being viewed.
   * @param {integer} topId The ID of the top node (if viewing by top position)
   */
  setChartContext(type, topId = null) {
    this.type = type
    this.topId = topId
  }

  static updateMetricsSetting(setting = {}) {
    window.App.Preferences.update({
      org_chart: {
        chart_settings: {
          [window.gon.chart_settings_key]: setting,
        },
      },
    })
  }

  toggleExpanded() {
    const $metrics = this.$element.find(".org-chart-metrics-inner")
    const shouldExpand = $metrics.hasClass("collapsed")
    $metrics.toggleClass("collapsed", !shouldExpand)

    ChartMetrics.updateMetricsSetting({ show_expanded_metrics: shouldExpand })
  }

  disableMetrics() {
    this.$element.addClass("hidden")
    const tooltipText = "Show Metrics Box".t("org_chart")
    $("[data-action=toggle-metrics]")
      .removeClass("metrics-active")
      .find(".tooltip-content")
      .text(tooltipText)

    ChartMetrics.updateMetricsSetting({ show_metrics: false })
  }

  viewActual() {
    this.$element.find('[data-stats-toggle="actual"]').hide()
    this.$element.find('[data-stats-toggle="percent-change"]').show()
    this.$element.find("[data-actual-difference-value]").each(function setActualDifferenceText() {
      const newValue = $(this).data("actual-difference-value")
      $(this).text(newValue)
    })
  }

  viewPercentChange() {
    this.$element.find('[data-stats-toggle="percent-change"]').hide()
    this.$element.find('[data-stats-toggle="actual"]').show()
    this.$element.find("[data-percent-change-value]").each(function setPercentChangeText() {
      const newValue = $(this).data("percent-change-value")
      $(this).text(newValue)
    })
  }

  setLoadingState(isForceLoad = false) {
    if (isForceLoad && this.$element.html() === "") {
      this.$element.addClass("loading")
      this.$element.html(
        '<div class="org-chart-metrics-inner"><div class="inner"><div class="spinner"></div></div></div>',
      )
    } else {
      this.$element
        .find(".metric-value")
        .html('<span class="pulser"><span></span><span></span><span></span></span>')
    }
  }

  initHandlers() {
    this.$element
      .find(".toggle-metrics-expand")
      .off("click")
      .on("click", () => this.toggleExpanded.call(this))
    this.$element.find("[data-stats-toggle]").off("click")
    this.$element.find("[data-stats-toggle]").on("click", (e) => this.changeStatsView.call(this, e))
    ReactRailsUJS.mountComponents("#close-metrics")
    ReactRailsUJS.mountComponents("#metrics-settings")
    this.$element.find(".group").on("click", (e) => this.toggleBreakdown.call(this, e))
  }

  load(chartViewType, chartViewId, isForceLoad = false) {
    let url = BASE_URL
    url = `${url}?metrics_type=${chartViewType}`
    if (chartViewId) {
      url = `${url}&metrics_id=${chartViewId}`
    }
    this.setLoadingState(isForceLoad)

    $.ajax({
      url,
    }).done((responseBody) => {
      this.$element.html(responseBody)
      this.$element.removeClass("loading")
      this.initHandlers.call(this)
    })
  }

  changeStatsView(e) {
    // Can be "actual" or "change"
    const statsType = $(e.currentTarget).data("stats-toggle")

    if (statsType === "actual") {
      ChartMetrics.updateMetricsSetting({ show_metrics_percentage: false })
      return this.viewActual()
    }

    ChartMetrics.updateMetricsSetting({ show_metrics_percentage: true })
    return this.viewPercentChange()
  }

  toggleBreakdown(e) {
    const $row = $(e.target).parents("tr")
    const key = $row.data("stats-row-for")
    this.$element
      .find(`[data-stats-row-for=${key}_filled], [data-stats-row-for=${key}_open]`)
      .toggle()
    $row.find(".fa-caret-down").toggleClass("hidden")
    $row.find(".fa-caret-up").toggleClass("hidden")
    const openRows = this.$element
      .find(".fa-caret-down.hidden")
      .parents("tr")
      .map(function () {
        return this.dataset.statsRowFor
      })
      .get()
    ChartMetrics.updateMetricsSetting({ metrics_breakdown_open_for: openRows })
  }
}

export default ChartMetrics
