/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const showTitleChangeNotice = function (initialTitle, selectedTitle, attributesToChangeHash) {
  const $notice = $("#title-change-notice")
  const $description = $notice.find("#title-change-notice-description")
  $description.find(".old-title").text(initialTitle)
  $description.find(".new-title").text(selectedTitle)
  const $dataList = $notice.find("#title-change-notice-data-list")
  const entries = _.sortBy(Object.entries(attributesToChangeHash), (entry) => entry[0])
  _.each(entries, (entry) => $dataList.append(`<li>${entry[0]}: ${entry[1]}</li>`))
  return $notice.removeClass("hidden")
}

const hideTitleChangeNotice = () => $("#title-change-notice").addClass("hidden")

const events = function (scope) {
  const initialTitle = $(scope).find("#title-change-notice").data("initial-title")
  $(scope).off("createdOrSelectedPositionType")
  $(scope).on("createdOrSelectedPositionType", function (e) {
    const attributesToChangeHash = e.detail?.controlledAttributeNamesWithSetValuesHash
    if (!attributesToChangeHash || !(Object.keys(attributesToChangeHash).length > 0)) {
      return
    }
    return showTitleChangeNotice(initialTitle, e.detail.jobCodeTitleLabel, attributesToChangeHash)
  })

  $(scope).off("changedPositionTypeSearch")
  return $(scope).on("changedPositionTypeSearch", () => hideTitleChangeNotice())
}

export { events }
