import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import { PersonAutocomplete } from "v2/react/components/positions/positionForm/inputs/PersonAutocomplete"
import { FieldValues } from "v2/react/components/positions/positionForm/types"

type NewPersonRowProps = {
  canRemove?: boolean
  onRemoveNewRow?: React.PointerEventHandler<HTMLButtonElement>
  onSelectPerson: (person: FieldValues["position"]["people"][0]) => void
}

/**
 * @todo Register on partial input (for errors)?
 * @todo New person subform support which creates/adds person inline.
 */
function NewPersonRow({ canRemove, onRemoveNewRow, onSelectPerson }: NewPersonRowProps) {
  const { t } = useTranslation()

  return (
    <div className="person-form">
      <div className="relative w-full items-center justify-between flex">
        <PersonAutocomplete
          id="position_people_new_name"
          name="position.people.new.name"
          onSelectPerson={onSelectPerson}
        />
        {canRemove && (
          <button
            className={cn("unassign-button skip-tab ml-3 bg-transparent !p-0")}
            onPointerUp={onRemoveNewRow}
            title={t("v2.shared.inputs.filled_by_input.unassign_person")}
            tabIndex={-1}
            type="button"
          >
            <FontAwesomeIcon icon={["far", "times"]} className="icon-14" />
          </button>
        )}
      </div>
    </div>
  )
}

export { NewPersonRow, NewPersonRowProps }
