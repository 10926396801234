import { ControlProps } from "@jsonforms/core"
import React, { ChangeEvent, FC } from "react"

import { isDisabledFromSchema } from "v2/react/shared/jsonForms/JsonFormControls/utils/forms"

import { useValidation } from "./hooks/useValidation"
import { InputErrorText } from "./InputErrorText"
import { oneOfDataItem } from "./types"

const JsonRadioInput: FC<ControlProps> = ({
  data,
  enabled,
  handleChange,
  id,
  label,
  path,
  schema,
  uischema,
  errors,
  config,
}) => {
  const isDisabled = isDisabledFromSchema({ enabled, schema })
  const handleChangeEvent = (event: ChangeEvent<HTMLInputElement>, constValue: string) =>
    handleChange(path, constValue)

  const options: oneOfDataItem[] = schema.oneOf || []
  const { showError, errorMessage } = useValidation({
    path,
    schema,
    submitting: config.submitting,
    schemaError: errors,
  })

  return (
    <div className="input-group">
      <label>{label}</label>
      {uischema.options?.subLabel && <p className="m-0">{uischema.options.subLabel}</p>}
      {options &&
        options.map((option: oneOfDataItem, index: number) => (
          <div key={option.const} className={index !== options.length - 1 ? "mb-1" : ""}>
            <label htmlFor={`${path} ${option.const}`} className="radio m-0">
              <input
                checked={data === option.const}
                className="input"
                disabled={isDisabled}
                id={`${path} ${option.const}`}
                name={id}
                onChange={(event) => handleChangeEvent(event, option.const as string)}
                type="radio"
                value={option.const}
              />
              {option.title}
            </label>
          </div>
        ))}
      <InputErrorText message={errorMessage} showError={showError} />
    </div>
  )
}

export { JsonRadioInput }
