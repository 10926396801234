import OperationStore, { OperationName } from "v2/operation_store"

/**
 * Generates a boilerplate query function for RTK Query endpoints.
 */
export const queryOperation =
  <QueryVariables = void>(operationName: OperationName) =>
  (variables: QueryVariables) => ({
    operationId: OperationStore.getOperationId(operationName),
    variables,
  })

/**
 * Generates a boilerplate mutation function for RTK Query endpoints
 */
export const mutationOperation =
  <MutationInput>(operationName: OperationName) =>
  ({ input }: { input: MutationInput }) =>
    flatMutationOperation(operationName)(input)

/**
 * Generates a boilerplate mutation function for RTK Query endpoints
 */
export const flatMutationOperation =
  <MutationInput>(operationName: OperationName) =>
  (input: MutationInput) => ({
    operationId: OperationStore.getOperationId(operationName),
    variables: { input },
  })

export const flatSplitMutationOperation =
  <MutationInput>(operationName: OperationName) =>
  (variables: MutationInput) => ({
    operationId: OperationStore.getOperationId(operationName),
    variables,
  })
