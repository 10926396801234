import React from "react"
import { useTranslation } from "react-i18next"

import { CustomFieldValue, FeatureFlags } from "types/graphql"
import { ProfilePanelMode } from "types/graphql.enums"
import { ModuleListItem } from "v2/react/components/orgChart/ProfilePanel/ModuleListItem"
import { useSelectedBox } from "v2/react/components/orgChart/ProfilePanel/Settings/hooks/useSelectedBox"
import { AccordionModulePanel } from "v2/react/shared/layout/AccordionModulePanel"
import { useAppSelector } from "v2/redux/store"

interface CustomFieldsProps {
  featureFlags: FeatureFlags
}

function CustomFields({ featureFlags }: CustomFieldsProps) {
  const { currentBox, toggleBox } = useSelectedBox("custom_fields", ProfilePanelMode.Position)
  const position = useAppSelector((state) => state.profilePanel.position)
  const { t } = useTranslation()

  if (!currentBox || !position) return null
  if (!featureFlags.customFields || !position.customFieldValues?.length) return null

  const headerContent = <div className="title">{t("v2.profile_panel.custom_fields")}</div>

  return (
    <AccordionModulePanel
      headerContent={headerContent}
      additionalToggleAction={toggleBox}
      collapsed={currentBox.collapsed}
      title={currentBox.name}
    >
      {position.customFieldValues?.map((customField: CustomFieldValue) => (
        <ModuleListItem key={customField.field_id}>
          <div>{customField.name}</div>
          <div>{customField.formatted_value}</div>
        </ModuleListItem>
      ))}
    </AccordionModulePanel>
  )
}

export { CustomFields }
