import React from "react"

export function useOptimisticValue(currentValueFromServer: string) {
  const [optimisticValue, setOptimisticValue] = React.useState<string | null>(null)
  React.useEffect(() => {
    if (currentValueFromServer === optimisticValue) {
      setOptimisticValue(null)
    }
  }, [currentValueFromServer, optimisticValue])
  return [optimisticValue, setOptimisticValue] as const
}
