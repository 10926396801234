import cn from "classnames"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"

type TabProps = {
  Content: React.FC
  keepMounted?: boolean
  label: string
  tabKey: string
}

type TabButtonProps = {
  isActive: boolean
  label: string
  onPointerUp: React.PointerEventHandler
}

type TabRegistry = {
  active?: TabProps
  tabs: TabProps[]
}

const TabContext = React.createContext<{
  register: (tab: TabProps) => void
  unregister: (tab: TabProps) => void
  // eslint-disable-next-line @typescript-eslint/no-empty-function
}>({ register: () => {}, unregister: () => {} })

/**
 * First draft for a generic "tabs" component where a list of tabs controls what
 * content is actively shown.
 *
 * To prevent awkward splitting of code responsible for rendering the actual
 * tabs and the code responsible for rendering the tab content, a `Tab`
 * component is meant to be used with this. It registers the tab and the
 * content.
 *
 * @example
 * ```tsx
 * <Tabs>
 *   <Tab tabKey="tab-1" label="Tab #1" Content={FirstTabContent} />
 *   {someCheck && <Tab tabKey="tab-2" label="Tab #2" Content={SecondTabContent} />
 * </Tabs>
 * ```
 */
const Tabs = ({ children }: React.PropsWithChildren) => {
  const {
    api,
    chooseTab,
    isActive,
    registry: { active, tabs },
  } = useTabRegistry()

  return (
    <TabContext.Provider value={api}>
      {/* Callers should nest <Tab /> calls which register the tab (here) */}
      {children}

      <div className="border--main rounded-lg bg-white">
        <div className="border-0 border-b border-solid border-neutral-8 p-2">
          <ul className="btn-group m-0 w-full list-none flex">
            {tabs.map((tab) => (
              <TabButton
                isActive={isActive(tab)}
                key={`tab-button-${tab.tabKey}`}
                label={tab.label}
                onPointerUp={chooseTab(tab)}
              />
            ))}
          </ul>
        </div>

        {tabs.map((tab) => (
          <div
            key={`tab-${tab.tabKey}`}
            className={cn(tab.keepMounted && !isActive(tab) && "hidden")}
          >
            {tab.tabKey === active?.tabKey || tab.keepMounted ? <tab.Content /> : null}
          </div>
        ))}
      </div>
    </TabContext.Provider>
  )
}

/**
 * Registers an individual tab and its content; must be called within `Tabs`.
 *
 * #### Props
 *
 * - `Content`: the component responsible for rendering the tab's content.
 * - `label`: user facing label for the tab.
 * - `keepMounted` (default: `true`): whether to keep the tab's content in the
 *   tree when the tab is inactive. Setting to false can help w/ performance if
 *   a tab's contents cause an excessive amount of work or side-effects.
 * - `tabKey`: a key identifying the tab. **Must be unique**.
 *
 * @example
 * ```tsx
 * <Tabs>
 *   <Tab
 *     Content={ExpensiveComponent}
 *     label="Sweet Tab"
 *     keepMounted={false}
 *     tabKey="expensive-tab"
 *   />
 * </Tabs>
 * ```
 */
function Tab({ Content, keepMounted = true, label, tabKey }: TabProps) {
  const { register, unregister } = useContext(TabContext)

  useEffect(() => {
    register({ Content, keepMounted, label, tabKey })

    return () => unregister({ Content, label, keepMounted, tabKey })
  }, [Content, keepMounted, label, register, tabKey, unregister])

  return null
}

/** @private */
const TabButton = ({ isActive, label, onPointerUp }: TabButtonProps) => (
  <li
    className={cn("btn !mb-0 mb-0 flex-1 overflow-hidden !p-0 text-center", isActive && "active")}
  >
    <button
      className="btn--ghost h-full w-full py-2.5 text-inherit block hover:no-underline"
      onPointerUp={onPointerUp}
      type="button"
    >
      {label}
    </button>
  </li>
)

/** @private */
function useTabRegistry() {
  const [registry, updateRegistry] = useState<TabRegistry>({ tabs: [] })

  const register = useCallback(
    (tab: TabProps) => {
      updateRegistry(({ active, tabs }) => ({
        active: active ?? tab,
        tabs: [...tabs, tab],
      }))
    },
    [updateRegistry],
  )

  const unregister = useCallback(
    (target: TabProps) => {
      updateRegistry(({ active, tabs }) => {
        const tabsWithoutTarget = tabs.filter((tab) => tab.tabKey !== target.tabKey)

        return {
          active: active?.tabKey === target.tabKey ? tabsWithoutTarget[0] : active,
          tabs: tabsWithoutTarget,
        }
      })
    },
    [updateRegistry],
  )

  const chooseTab = useCallback(
    (tab: TabProps) => () => {
      updateRegistry((registry) => ({ ...registry, active: tab }))
    },
    [updateRegistry],
  )

  const isActive = useCallback(
    ({ tabKey }: TabProps) => registry.active?.tabKey === tabKey,
    [registry],
  )

  const api = useMemo(() => ({ register, unregister }), [register, unregister])

  return {
    api,
    chooseTab,
    isActive,
    registry,
  }
}

export { Tabs, Tab }
