import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useTranslation } from "react-i18next"

import { useAddChangeToHeadcountPlanMutation } from "v2/redux/GraphqlApi/HeadcountPlanningApi"

import { PositionMenuButton } from "./PositionMenuButton"

/**
 * These are keys we want to ignore when duplicating a position. They're either
 * related to person data, or else they trigger validation errors when set on
 * new positions.
 *
 * See: `HeadcountPlanning::AllowedAttributes`
 */
const IGNORE_KEYS = [
  "filled_by_name",
  "filled_by_id",
  "planned_termination_date",
  "projected_hiring_date",
]

export function DuplicatePositionButton({
  headcountPlanId,
  participantId,
  participantPositionId,
  payload,
  onSuccess,
}: {
  headcountPlanId: string
  participantId?: string
  participantPositionId?: string
  payload: { [key: string]: string | number }
  onSuccess: () => void
}) {
  const [mutate, mutation] = useAddChangeToHeadcountPlanMutation()
  const { t } = useTranslation()

  React.useEffect(() => {
    if (mutation.data?.addChangeToHeadcountPlan?.headcountPlanChangeCreated) {
      onSuccess()
    }
  }, [mutation.data, onSuccess])

  const filteredPayload = Object.keys(payload).reduce((acc, key) => {
    if (IGNORE_KEYS.includes(key)) {
      return acc
    }
    return { ...acc, [key]: payload[key] }
  }, {})

  return (
    <PositionMenuButton
      icon={<FontAwesomeIcon icon={["fal", "clone"]} />}
      text={t("v2.headcount_plan.duplicate_position")}
      onClick={() => {
        mutate({
          input: {
            action: "create_new",
            headcountPlanId,
            headcountPlanParticipantId: participantId,
            parentPositionId: participantPositionId,
            payload: filteredPayload,
            revisionNumber: 0,
          },
        })
      }}
    />
  )
}
