import { DateValue, parseDate } from "@internationalized/date"
import type { Args, Meta, StoryObj } from "@storybook/react"
import dayjs from "dayjs"
import React from "react"

import RootProvider from "v2/react/components/RootProvider"
import { DatePicker } from "v2/react/shared/forms/DateInputs/DatePicker"

const meta: Meta<typeof DatePicker> = {
  title: "Shared/DateInputs/DatePicker",
  component: DatePicker,
  decorators: [
    (Story) => (
      <RootProvider>
        <div style={{}}>
          <Story />
        </div>
      </RootProvider>
    ),
  ],
}

export default meta
type Story = StoryObj<typeof DatePicker>

export const DatePickerUncontrolled: Story = {
  args: {
    label: "Date Picker",
    errorMessage: "Oops wrong date!",
  },
  render: ({ label, errorMessage }: Args) => (
    <DatePicker
      label={label}
      errorMessage={errorMessage}
      id="story-example-uncontrolled"
      wrapperClassName="w-[280px]"
    />
  ),
}

const DatePickerWithValue = () => {
  const [date, setDate] = React.useState<DateValue>(
    parseDate(dayjs().subtract(1, "year").format("YYYY-MM-DD")),
  )

  return (
    <DatePicker
      label="Date Picker"
      wrapperClassName="w-[280px]"
      id="story-example-controlled"
      value={date}
      onChange={setDate}
    />
  )
}

export const DatePickerControlled: Story = {
  render: () => DatePickerWithValue(),
}
