import React from "react"

interface Props {
  loading: boolean
}

function ProgressBar({ loading }: Props) {
  return (
    <div className="react-progress">
      <div className="react-progress-bar">
        <div
          className={`react-progress-bar__fill ${
            loading ? "react-progress-bar__fill--loading" : ""
          }`}
        />
      </div>
    </div>
  )
}

export { ProgressBar }
