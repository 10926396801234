import { IconName } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { ReactNode } from "react"

export interface EmptyContent {
  buttonIcon?: IconName
  buttonClass?: string
  buttonText: ReactNode
  icon: IconName
  note: ReactNode
  onClick: () => void
  showButton?: boolean
}
interface EmptyStateProps {
  buttonIcon?: IconName
  buttonClass?: string
  buttonText: ReactNode
  icon: IconName
  note: ReactNode
  onClick: () => void
  showButton?: boolean
}

function EmptyState({
  buttonIcon,
  buttonClass,
  buttonText,
  icon,
  note,
  onClick,
  showButton = true,
}: EmptyStateProps) {
  return (
    <div className="flex-col gap-4 pb-6 pt-8 flex">
      <div className="justify-center flex">
        <button className="bg-white" type="button" onClick={onClick}>
          <div className="icon--round">
            <FontAwesomeIcon icon={["far", icon]} className="fa-lg" />
          </div>
        </button>
      </div>
      <div className="justify-center text-center text-neutral-64 flex">{note}</div>
      <div className="justify-center flex">
        {showButton && (
          <button
            className={buttonClass || "btn--large btn--secondary"}
            type="button"
            onClick={onClick}
          >
            <FontAwesomeIcon icon={["far", buttonIcon || "plus"]} />
            {buttonText}
          </button>
        )}
      </div>
    </div>
  )
}

export { EmptyState }
