import { ControlProps } from "@jsonforms/core"
import React, { FC } from "react"

import { Avatar } from "v2/react/shared/icons/Avatar"
import { useGetPositionForListQuery } from "v2/redux/GraphqlApi"

const JsonModuleFilledByItem: FC<ControlProps> = ({ data, visible }) => {
  const { data: positionData } = useGetPositionForListQuery({ positionId: data.id })

  if (!visible || (!data.filledBy && !data.lastFilledBy)) return null

  if (!positionData || !positionData.position) return null
  const people = positionData.position?.people

  const currentlyFilled = !!people?.length
  const lastFilledBy = !people?.length && !!positionData.position?.lastFilledByFormatted?.length
  const lastFilledByAvatar = positionData.position?.lastFilledByAvatarThumbUrl
  const lastFilledByName = positionData.position?.lastFilledByFormatted

  const avatarDisplay = () => (
    <div className="flex">
      {people?.map(
        (person) =>
          person && (
            <div key={person.id} className="circle circle-sm circle-border mx-[-4px]">
              <Avatar person={person} />
            </div>
          ),
      )}
    </div>
  )

  const nameDisplay = () => {
    const isNotLast = (index: number) => people && people.length > 1 && index !== people.length - 1

    return (
      <div className="flex">
        {people?.map((person, index) => (
          <div key={person.id}>
            {person.fullName}
            {isNotLast(index) && ", "}
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className="module-card-list-item">
      <label className="text-neutral-64">{lastFilledByLabel(currentlyFilled, lastFilledBy)}</label>
      <div className="items-center gap-2 flex">
        {currentlyFilled && (
          <>
            {avatarDisplay()}
            {nameDisplay()}
          </>
        )}
        {lastFilledBy && lastFilledByAvatar && lastFilledByName && (
          <>
            <div key={lastFilledByName} className="circle circle-sm circle-border mx-[-4px]">
              <img className="circle m-0" src={lastFilledByAvatar} alt={lastFilledByName} />
            </div>
            <p>{lastFilledByName}</p>
          </>
        )}
      </div>
    </div>
  )
}

export { JsonModuleFilledByItem }

const lastFilledByLabel = (currentlyFilled: boolean, previouslyFilled: boolean): string | null => {
  if (currentlyFilled) return "currently_filled_by".t("job_requisition")
  if (previouslyFilled) return "last_filled_by".t("job_requisition")
  return null
}
