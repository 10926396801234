import cn from "classnames"
import React from "react"

import { Person } from "types/graphql"

interface PersonSearchResultProps {
  active?: boolean
  person: Person
  showEmployeeId?: boolean
}

function PersonSearchResult({ active, person, showEmployeeId }: PersonSearchResultProps) {
  return (
    <div className={cn("list-group-item", { highlight: active })}>
      <div className="list-group-item-thumb circle circle-med shrink-0">
        <img alt={person.name || ""} src={person.avatarThumbUrl || ""} />
      </div>
      <div className="flex-col items-start flex">
        <div className="list-group-item-title break-all text-base-bold">{person.name}</div>
        <div className="text-neutral-64">{person.primaryPosition?.title || ""}</div>
        {showEmployeeId && <div className="text-neutral-64">{person.employeeId}</div>}
      </div>
    </div>
  )
}

export default PersonSearchResult
