import type { Args, Meta, StoryObj } from "@storybook/react"
import React, { useState } from "react"

import { ListTextInput } from "./ListTextInput"

const meta: Meta<typeof ListTextInput> = {
  title: "Shared/ListTextInput",
  component: ListTextInput,
  decorators: [
    (Story) => (
      <div style={{ margin: "20px 50% 20px 20px" }}>
        <Story />
      </div>
    ),
  ],
}

export default meta

// Credit to: https://medium.com/urbandataanalytics/changing-args-values-state-in-storybook-without-usestate-hook-15cc096716f7
const StateWrapperTemplate = ({ placeholder, value }: Args) => {
  const [currentValue, setCurrentValue] = useState(value ?? [])
  return (
    <ListTextInput
      placeholder={placeholder}
      onChange={(...params) => {
        setCurrentValue(...params)
      }}
      value={currentValue}
    />
  )
}

type Story = StoryObj<typeof ListTextInput>

export const Main: Story = {
  args: {
    placeholder: "Enter a new value here",
    value: ["First Value", "Another Value"],
  },
  render: ({ placeholder, value }: Args) => (
    <StateWrapperTemplate placeholder={placeholder} value={value} />
  ),
}
