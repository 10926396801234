import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React, { useState } from "react"
import { CodeBlock, tomorrowNight } from "react-code-blocks"

import { preventPropagationOfSyntheticEvent, useOutsideClick } from "v2/react/hooks/useOutsideClick"

function Inputs() {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useOutsideClick<HTMLDivElement>(() => setIsOpen(false))

  return (
    <>
      <h1>Inputs</h1>
      <section className="my-8">
        <h2>Text Input</h2>
        <div className="my-2">
          <p>
            Basic inputs can have properties like a label and error message. Place these elements
            inside of <code>input-group</code> to have them stack in a vertical manner.
          </p>
          <p>
            To provide error styles, wrap the input and error in a div and apply{" "}
            <code>border--error</code> to the input and <code>text-status-critical</code> can be
            applied to the error text.
          </p>
        </div>
        <div className="gap-8 flex">
          <div className="input-group">
            <label htmlFor="name">Full Name: </label>
            <input className="input min-w-14" type="text" name="name" placeholder="Full Name" />
          </div>
          <div className="input-group">
            <label htmlFor="email">Email: </label>
            <div>
              <input
                className="input border--error min-w-14"
                type="text"
                name="email"
                placeholder="Full Name"
              />
              <div className="mt-1 items-center text-status-critical flex">
                <FontAwesomeIcon
                  icon={["far", "exclamation-circle"]}
                  className="mr-2 text-inherit"
                />
                <span>Email must be provided.</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-5 my-4 font-mono text-base">
          <CodeBlock text={inputExample} theme={tomorrowNight} language="javascript" />
        </div>
      </section>

      <section className="my-8">
        <h2>Input Groups</h2>
        <div className="my-2">
          <p>
            With an input group, you can add a prefix icon (think search inputs) or a suffix icon.
          </p>
          <p>A few notes:</p>
          <ol>
            <li className="mb-0 mt-2">
              If you are using a suffix icon, you will want to add <code>suffix-pad</code> and
              optionally <code>suffix-pad--large</code> class to the input in order to provide it
              with adequate padding.
            </li>
            <li className="mb-0 mt-2">
              If you are using a prefix icon, you will want to add <code>prefix-pad</code> class to
              the input in order to provide it with adequate padding.
            </li>
            <li>The color will be adjusted for the prefix icon if the input is disabled.</li>
            <li>
              Both prefix and suffix elements should follow the input within the{" "}
              <code>input-group</code>.
            </li>
            <li>
              The container with the <code>input-group</code> class will also need{" "}
              <code>relative</code> applied in order to position the icons.
            </li>
            <li>The suffix icon only appears when the placeholder is not shown.</li>
          </ol>
        </div>
        <div className="gap-8 flex">
          <div className="input-group relative">
            <input
              className="input prefix-pad suffix-pad min-w-14"
              type="text"
              placeholder=""
              onChange={() => 2 + 2}
              value="Why do dogs make weird noises in their sleep?"
            />
            <span className="prefix">
              <FontAwesomeIcon className="icon-14 prefix" icon={["far", "search"]} />
            </span>
            <FontAwesomeIcon className="icon-10 suffix" icon={["far", "times"]} />
          </div>
          <div className="input-group relative">
            <input
              className="input prefix-pad min-w-14"
              type="text"
              placeholder=""
              onChange={() => 2 + 2}
              value="Why do dogs make weird noises in their sleep?"
            />
            <FontAwesomeIcon className="icon-14 prefix" icon={["far", "search"]} />
          </div>
          <div className="input-group relative h-fit">
            <input
              className="input suffix-pad suffix-pad--large"
              type="text"
              onChange={() => 2 + 2}
              value=""
            />
            <p className="suffix suffix--large">hrs/wk</p>
          </div>
        </div>
        <div className="col-span-5 my-4 font-mono text-base">
          <CodeBlock text={inputGroupExample} theme={tomorrowNight} language="javascript" />
        </div>
      </section>

      <section className="my-8">
        <h2>Select Input</h2>
        <div className="my-2">
          <p>
            To create a select input, wrap the select with an element that has the{" "}
            <code>select</code> class.
          </p>
        </div>
        <div className="gap-8 flex">
          <div className="select max-w-xs">
            <select onChange={() => 2 + 2} defaultValue="default">
              <option value="default" disabled>
                Favorite Parks and Rec Character?
              </option>
              <option value="leslie">Leslie Knope</option>
              <option value="ann">Ann Perkins</option>
              <option value="april">April Ludgate</option>
              <option value="ron">Ron Swanson</option>
              <option value="andy">Andy Dwyer</option>
            </select>
          </div>
          <div>
            <div className="select border--error max-w-xs">
              <select onChange={() => 2 + 2} defaultValue="default">
                <option value="default" disabled>
                  Favorite Parks and Rec Character?
                </option>
                <option value="leslie">Leslie Knope</option>
                <option value="ann">Ann Perkins</option>
                <option value="april">April Ludgate</option>
                <option value="ron">Ron Swanson</option>
                <option value="andy">Andy Dwyer</option>
              </select>
            </div>
            <div className="mt-1 items-center text-status-critical flex">
              <FontAwesomeIcon icon={["far", "exclamation-circle"]} className="mr-2 text-inherit" />
              <span>You must choose a character.</span>
            </div>
          </div>
        </div>
        <div className="col-span-5 my-4 font-mono text-base">
          <CodeBlock text={selectExample} theme={tomorrowNight} language="javascript" />
        </div>
      </section>

      <section className="my-8">
        <h2>Select Input v2</h2>
        <div className="my-2">
          <p>
            Sometimes we may need more than text content in a dropdown. In that case, we can
            implement an <code>input-with-dropdown-selector</code>. A few things to note here:
          </p>
          <ol>
            <li>
              In <code>slim</code> files, it will be necessary to wrap the content of the{" "}
              <code>dropdown-menu-link</code> content with the <code>selection-icon</code> class in
              order to select the content of that item
            </li>
            <li>
              A &quot;selected&quot; icon is placed inside each <code>dropdown-menu-link</code>.
              Only the <code>dropdown-menu-link</code> with the <code>active</code> class will
              display the icon.
            </li>
            <li>
              Note: if this element is implemented in React, you will want to utilize the{" "}
              <code>useOutsideClick</code> hook. See the Dropdowns page for more details.
            </li>
          </ol>
        </div>
        <div className="w-56">
          <div className="input-with-dropdown-selector">
            <div className="input-group">
              <label htmlFor="importance">Position Importance</label>
              <div
                role="button"
                tabIndex={0}
                className="dropdown position-importance-selector"
                onClick={preventPropagationOfSyntheticEvent}
                onKeyDown={preventPropagationOfSyntheticEvent}
                ref={dropdownRef}
              >
                <button
                  type="button"
                  className="dropdown-link input !bg-white pl-4"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <div className="selection-icon">
                    <div className="position-importance-icon medium">
                      <FontAwesomeIcon icon={["fas", "exclamation"]} />
                    </div>
                    <div className="text">Medium</div>
                  </div>
                  <FontAwesomeIcon icon={["far", "chevron-down"]} className="ml-auto" />
                </button>
                <div className={classNames("dropdown-menu right-0", { open: isOpen })}>
                  <div className="dropdown-menu-link" data-selector-value="">
                    <div className="selection-icon">
                      <div className="position-importance-icon none">
                        <FontAwesomeIcon icon={["fas", "exclamation"]} />
                      </div>
                      <div className="text">None</div>
                      <FontAwesomeIcon
                        icon={["fas", "check-circle"]}
                        className="selected-indicator"
                      />
                    </div>
                  </div>
                  <div className="dropdown-menu-link" data-selector-value="low">
                    <div className="selection-icon">
                      <div className="position-importance-icon low">
                        <FontAwesomeIcon icon={["fas", "exclamation"]} />
                      </div>
                      <div className="text">Low</div>
                      <FontAwesomeIcon
                        icon={["fas", "check-circle"]}
                        className="selected-indicator"
                      />
                    </div>
                  </div>
                  <div className="dropdown-menu-link active" data-selector-value="medium">
                    <div className="selection-icon">
                      <div className="position-importance-icon medium">
                        <FontAwesomeIcon icon={["fas", "exclamation"]} />
                      </div>
                      <div className="text">Medium</div>
                      <FontAwesomeIcon
                        icon={["fas", "check-circle"]}
                        className="selected-indicator"
                      />
                    </div>
                  </div>
                  <div className="dropdown-menu-link" data-selector-value="high">
                    <div className="selection-icon">
                      <div className="position-importance-icon high">
                        <FontAwesomeIcon icon={["fas", "exclamation"]} />
                      </div>
                      <div className="text">High</div>
                      <FontAwesomeIcon
                        icon={["fas", "check-circle"]}
                        className="selected-indicator"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-5 my-4 font-mono text-base">
          <CodeBlock text={inputWithDropdownExample} theme={tomorrowNight} language="javascript" />
        </div>
      </section>
    </>
  )
}

export default Inputs

const inputExample = `// with label only:
<div className="input-group">
  <label htmlFor="name">Full Name: </label>
  <input className="input min-w-14" type="text" name="name" placeholder="Full Name" />
</div>

// with error message:
<div className="input-group">
  <label htmlFor="email">Email: </label>
  <div>
    <input
      className="input min-w-14 border--error"
      type="text"
      name="email"
      placeholder="Full Name"
    />
    <div className="mt-1 flex items-center text-status-critical">
      <FontAwesomeIcon
        icon={["far", "exclamation-circle"]}
        className="text-inherit mr-2"
      />
      <span>Email must be provided.</span>
    </div>
  </div>`

const inputGroupExample = `// With prefix and suffix icons:
<div className="input-group relative">
  <input
    className="min-w-14 input prefix-pad suffix-pad"
    type="text"
    placeholder=""
    value="Why do dogs make weird noises in their sleep?"
  />
  <span className="prefix">
    <FontAwesomeIcon className="icon-14 prefix" icon={["far", "search"]} />
  </span>
  <FontAwesomeIcon className="icon-10 suffix" icon={["far", "times"]} />
</div>

// Without suffix icon:
<div className="input-group relative">
  <input
    className="min-w-14 input prefix-pad"
    type="text"
    placeholder=""
    value="Why do dogs make weird noises in their sleep?"
  />
  <FontAwesomeIcon className="icon-14 prefix" icon={["far", "search"]} />
</div>

// With large suffix:
<div className="input-group relative h-fit">
  <input
    className="input suffix-pad suffix-pad--large"
    type="text"
    onChange={() => 2 + 2}
    value=""
  />
  <p className="suffix suffix--large">
    hrs/wk
  </p>
</div>
`

const selectExample = `<div className="select max-w-xs">
  <select defaultValue="default">
    <option value="default" disabled>Favorite Parks and Rec Character?</option>
    <option value="leslie">Leslie Knope</option>
    <option value="ann">Ann Perkins</option>
    <option value="april">April Ludgate</option>
    <option value="ron">Ron Swanson</option>
    <option value="andy">Andy Dwyer</option>
  </select>
</div>`

const inputWithDropdownExample = `<div className="input-with-dropdown-selector">
  <div className="input-group">
    <label htmlFor="importance">Position Importance</label>
    <div
      role="button"
      tabIndex={0}
      className="dropdown position-importance-selector"
      onClick={preventPropagationOfSyntheticEvent}
      onKeyDown={preventPropagationOfSyntheticEvent}
      ref={dropdownRef}
    >
      <button
        type="button"
        className="dropdown-link input !bg-white pl-4"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="selection-icon">
          <div className="position-importance-icon medium">
            <FontAwesomeIcon icon={["fas", "exclamation"]} />
          </div>
          <div className="text">Medium</div>
        </div>
        <FontAwesomeIcon icon={["far", "chevron-down"]} className="ml-auto" />
      </button>
      <div className={classNames("dropdown-menu right-0", { open: isOpen })}>
        <div className="dropdown-menu-link" data-selector-value="">
          <div className="selection-icon">
            <div className="position-importance-icon none">
              <FontAwesomeIcon icon={["fas", "exclamation"]} />
            </div>
            <div className="text">None</div>
            <FontAwesomeIcon
              icon={["fas", "check-circle"]}
              className="selected-indicator"
            />
          </div>
        </div>
        <div className="dropdown-menu-link" data-selector-value="low">
          <div className="selection-icon">
            <div className="position-importance-icon low">
              <FontAwesomeIcon icon={["fas", "exclamation"]} />
            </div>
            <div className="text">Low</div>
            <FontAwesomeIcon
              icon={["fas", "check-circle"]}
              className="selected-indicator"
            />
          </div>
        </div>
        <div className="dropdown-menu-link active" data-selector-value="medium">
          <div className="selection-icon">
            <div className="position-importance-icon medium">
              <FontAwesomeIcon icon={["fas", "exclamation"]} />
            </div>
            <div className="text">Medium</div>
            <FontAwesomeIcon
              icon={["fas", "check-circle"]}
              className="selected-indicator"
            />
          </div>
        </div>
        <div className="dropdown-menu-link" data-selector-value="high">
          <div className="selection-icon">
            <div className="position-importance-icon high">
              <FontAwesomeIcon icon={["fas", "exclamation"]} />
            </div>
            <div className="text">High</div>
            <FontAwesomeIcon
              icon={["fas", "check-circle"]}
              className="selected-indicator"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>`
