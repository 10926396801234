import cn from "classnames"
import React from "react"

import { InputErrorText } from "v2/react/shared/forms/InputErrorText"
import { NumericInput } from "v2/react/shared/forms/NumericInput"

interface NumericRangeInputProps {
  minId: string
  minName: string
  minDefaultValue?: number
  minOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  minPlaceHolder?: string
  maxId: string
  maxName: string
  maxDefaultValue?: number
  maxOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  maxPlaceHolder?: string
  error: string
  fieldLabel: string
}

function NumericRangeInput({
  minId,
  minName,
  minDefaultValue,
  minOnChange,
  minPlaceHolder,
  maxId,
  maxName,
  maxDefaultValue,
  maxOnChange,
  maxPlaceHolder,
  error,
  fieldLabel,
}: NumericRangeInputProps) {
  return (
    <div>
      <label className="mb-2">{fieldLabel}</label>
      <div
        className={cn("rounded-lg flex", {
          "border--error": !!error.length,
          "focus-within:shadow-none hover:shadow-none": error.length === 0,
        })}
      >
        <NumericInput
          id={minId}
          name={minName}
          defaultValue={minDefaultValue}
          onChange={minOnChange}
          placeholder={minPlaceHolder}
          inputClassName={cn("rounded-r-none", {
            "range-error focus:border--main-hover": !!error.length,
          })}
          wrapperClassName="focus-within:z-1 hover:z-1"
        />
        <NumericInput
          id={maxId}
          name={maxName}
          defaultValue={maxDefaultValue}
          onChange={maxOnChange}
          placeholder={maxPlaceHolder}
          inputClassName={cn("rounded-l-none", {
            "range-error focus:border--main-hover": !!error.length,
          })}
          wrapperClassName="focus-within:z-1 relative -left-px -mr-px"
        />
      </div>
      {!!error.length && <InputErrorText message={error} />}
    </div>
  )
}

export { NumericRangeInput }
