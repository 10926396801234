import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { PlanColumns } from "v2/react/components/headcountPlanning/PlanColumns"
import { PlanDates } from "v2/react/components/headcountPlanning/PlanDates"
import { PlanTitle } from "v2/react/components/headcountPlanning/PlanTitle"
import { FormElements } from "v2/react/components/headcountPlanning/types"
import { useDateRange } from "v2/react/shared/forms/DateInputs/DateRange/hooks/useDateRange"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import PageNav from "v2/react/shared/navigation/PageNav"
import { TitleBlockLarge } from "v2/react/shared/navigation/PageNav/TitleBlock"
import { errorFor } from "v2/react/utils/errors"
import { UrlHelper } from "v2/react/utils/urls"
import {
  useGetHeadcountPlanDefaultAttributesQuery,
  useStartNewHeadcountPlanMutation,
} from "v2/redux/GraphqlApi/HeadcountPlanningApi"

import { PlanNewPositionIdPrefix } from "./PlanNewPositionIdPrefix"
import { StickyFooterBar } from "./StickyFooterBar"

export function Setup() {
  const attributes = useGetHeadcountPlanDefaultAttributesQuery(null)
  const [mutate, mutation] = useStartNewHeadcountPlanMutation()
  const { dateRange, handleDateRangeChange } = useDateRange({})

  useEffect(() => {
    if (mutation.data?.startNewHeadcountPlan?.headcountPlanCreated) {
      const id = mutation.data.startNewHeadcountPlan.headcountPlanId
      window.location.href = UrlHelper.headcountPlanInvitationsPath(
        id,
        `?cancelTo=${UrlHelper.headcountPlansPath()}&mode=invite`,
      )
    }
  }, [mutation.data])

  const { t } = useTranslation()

  if (attributes.isLoading) return <Spinner />

  return (
    <div>
      <PageNav>
        <TitleBlockLarge>
          <h1>{t("v2.headcount_plan.setup.header")}</h1>
        </TitleBlockLarge>
      </PageNav>
      <div className="justify-center py-6 flex">
        <form
          id="setup-form"
          className="list-group mx-4 mb-16 w-full rounded-xl bg-white p-6 sm:w-[544px]"
          onSubmit={(event) => {
            event.preventDefault()
            const target = event.target as HTMLFormElement
            const elements = target.elements as FormElements
            const input = {
              name: elements.planTitle.value,
              newPositionIdPrefix: elements.newPositionIdPrefix.value,
              startDate: dateRange?.start?.toString() || "",
              endDate: dateRange?.end?.toString() || "",
              allowedAttributes: Array.from(elements["columns[]"])
                .filter((el) => el.checked)
                .map((el) => el.value),
            }
            mutate({ input })
          }}
        >
          <p className="text-status-critical">
            {mutation.isSuccess &&
              errorFor("startNewHeadcountPlan", mutation.data?.startNewHeadcountPlan?.errors)}
          </p>
          <div className="gap-4 flex">
            <PlanTitle
              isSuccess={mutation.isSuccess}
              errors={mutation.data?.startNewHeadcountPlan?.errors}
            />
            <PlanNewPositionIdPrefix
              isSuccess={mutation.isSuccess}
              errors={mutation.data?.startNewHeadcountPlan?.errors}
              defaultValue={attributes.currentData?.headcountPlanDefaults?.newPositionIdPrefix}
            />
          </div>
          <PlanDates
            dateRange={dateRange}
            onChange={handleDateRangeChange}
            errors={mutation.data?.startNewHeadcountPlan?.errors}
          />
          <PlanColumns columns={attributes.currentData?.headcountPlanDefaults?.attributes} />
        </form>
      </div>
      <StickyFooterBar innerClassName="w-full sm:w-[544px]">
        <a
          href={UrlHelper.headcountPlansPath()}
          className="btn--large btn--secondary"
          type="submit"
        >
          {t("v2.defaults.cancel")}
        </a>
        <button className="btn--large btn--primary" type="submit" form="setup-form">
          {mutation.isLoading ? `${t("v2.defaults.saving")}...` : t("v2.defaults.continue")}
        </button>
      </StickyFooterBar>
    </div>
  )
}
