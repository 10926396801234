import parse from "html-react-parser"
import sanitizeHtml from "sanitize-html"

// Useful for determining the actual length of content in a wysiwyg editor
const removeHtmlTagsAndTrim = (html: string) =>
  sanitizeHtml(html, {
    allowedTags: [],
    allowedAttributes: {},
  })
    .replace(/\u00a0/g, " ")
    .trim()

const sanitizeParse = (html: string) => parse(sanitizeHtml(html))

export { removeHtmlTagsAndTrim, sanitizeParse }
