import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import type { Option } from "types/graphql"
import { FieldsetWithSearch } from "v2/react/shared/forms/FieldsetWithSearch"
import { useFieldsetWithSearch } from "v2/react/shared/forms/FieldsetWithSearch/hooks/useFieldsetWithSearch"
import { Footer } from "v2/react/shared/layout/Sidebar/Footer"
import { UnappliedChangesModal } from "v2/react/shared/tables/TableUtilities/UnappliedChangesModal"

interface Props {
  fieldsetId: string
  allColumns: Option[]
  selectedColumns: string[]
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

const BasicTableSettingsPanelContent = ({
  fieldsetId,
  allColumns,
  selectedColumns,
  onSubmit,
}: Props) => {
  const { t } = useTranslation()

  const {
    selectedFields,
    unselectedFields,
    formKey,
    handleReorder,
    handleSearchSelect,
    handleFieldCheckboxChange,
    fieldsChanged,
    resetState,
  } = useFieldsetWithSearch({
    selectedFieldKeys: selectedColumns,
    allFields: allColumns,
  })

  const showFooter = fieldsChanged
  UnappliedChangesModal.useSetUnappliedChanges(showFooter)

  const handleCancel = () => {
    resetState()
  }

  return (
    <form onSubmit={onSubmit} key={formKey} className={cn("h-full", { "!pb-10": showFooter })}>
      <div className="h-full overflow-y-scroll p-3">
        <div className="input-group">
          <FieldsetWithSearch<Option>
            id={fieldsetId}
            description={t("v2.person_settings.edit_positions_page_settings.columns_to_display")}
            selectedItems={selectedFields}
            searchableItems={unselectedFields}
            onReorder={handleReorder}
            onSearchSelect={handleSearchSelect}
            onCheckboxChange={handleFieldCheckboxChange}
          />
        </div>
      </div>
      <Footer show={showFooter} onCancel={handleCancel} />
    </form>
  )
}

export { BasicTableSettingsPanelContent }
