/* eslint-disable camelcase, no-underscore-dangle */
import { FundingSourceAllocation } from "types/graphql.enums"
import { usePositionFormContext } from "v2/react/components/positions/positionForm/PositionFormProvider"
import { safeNumber } from "v2/react/utils/safeNumber"

import { useBudgetedTotal } from "./useBudgetedTotal"
import { usePositionWatchField } from "./useConvenientReactHookFormHooks"

function useFundingSourceState() {
  const {
    positionFormCollections: { fundingSources },
  } = usePositionFormContext()
  const fsps = usePositionWatchField({ name: "position.fundingSourcePositionsAttributes" })
  const budgetingTotal = useBudgetedTotal()
  const fsAllocationType = usePositionWatchField({
    name: "position.fundingSourceAllocationType",
  })
  const fspsNotMarkedForDestroy = fsps?.filter(
    (fsp) => !(fsp._destroy ? Boolean(fsp._destroy) : false),
  )

  const unusedFundingSources = fundingSources
    .filter(
      (fundingSource) =>
        !fspsNotMarkedForDestroy?.find(
          ({ fundingSourceId }) => fundingSourceId === fundingSource.id,
        ),
    )
    .map((fundingSource) => ({ ...fundingSource, label: fundingSource.name ?? "" }))

  const totalAmount =
    fspsNotMarkedForDestroy?.reduce(
      (memo, curr) =>
        fsAllocationType === FundingSourceAllocation.PercentOfBudget
          ? (safeNumber(curr.percentOfBudget) / 100) * budgetingTotal + memo
          : safeNumber(curr.amount) + memo,
      0,
    ) ?? 0

  return {
    allocationType: fsAllocationType,
    budgetedVsFundedDiff: budgetingTotal - totalAmount,
    count: fsps?.length ?? 0,
    countNotMarkedForDestroy: fspsNotMarkedForDestroy?.length ?? 0,
    fsps,
    fspsNotMarkedForDestroy,
    totalAmount,
    totalPercent: fsps?.reduce((memo, curr) => safeNumber(curr.percentOfBudget) + memo, 0) ?? 0,
    unusedFundingSources,
  }
}

export { useFundingSourceState }
