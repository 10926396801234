/** @private */
const hasMigrated = (storage, namespace) =>
  storage.getItem(`${namespace}.migratedLegacyDepartments`)

/** @private */
const setMigrated = (storage, namespace) =>
  storage.setItem(`${namespace}.migratedLegacyDepartments`, true)

const LegacyDepartmentKeyMigrator = Object.freeze({
  /**
   * Renames legacy department keys in local storage so they reference chart
   * sections instead.
   *
   * @param {Storage} storage
   * @param {string} namespace
   * @returns {void}
   */
  async maybeRunAsync(storage, namespace) {
    try {
      if (!storage || hasMigrated(storage, namespace)) return

      const keyRegex = new RegExp(`^${namespace}.*department_.*$`)
      const storageLength = storage.length
      for (let i = 0; i < storageLength; i += 1) {
        const oldKey = storage.key(i)
        const item = storage.getItem(oldKey)

        // eslint-disable-next-line no-continue
        if (!item || !(oldKey || "").match(keyRegex)) continue

        const newKey = oldKey.replace("department_", "chart_section_")

        storage.setItem(newKey, item)
        storage.removeItem(oldKey)
      }
    } catch (error) {
      if (window.Sentry) {
        window.Sentry.captureException(error)
      }
    } finally {
      // Since this is best-effort, always flag as done.
      setMigrated(storage, namespace)
    }
  },
})

export default LegacyDepartmentKeyMigrator
