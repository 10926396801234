import React, { useEffect, useState } from "react"

import { FilterType } from "types/graphql.enums"
import { DEFAULT_GROUP_BY } from "v2/react/components/dashboard/config"
import { getFiltersForDrillDown } from "v2/react/components/dashboard/helpers/filters"
import { GroupData } from "v2/react/components/dashboard/types"
import { BarGrid } from "v2/react/components/dashboard/widgets/shared/BarGrid"
import { DrillDown } from "v2/react/components/dashboard/widgets/shared/DrillDown"
import { Title } from "v2/react/components/dashboard/widgets/shared/Title"
import { Value } from "v2/react/components/dashboard/widgets/shared/Value"
import { Select } from "v2/react/shared/forms/Select"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import { Modal } from "v2/react/shared/overlay/Modal"
import { NONE_KEY_SUFFIX } from "v2/react/utils/collections"
import { entityFromUniqueKey, idFromUniqueKey } from "v2/react/utils/uniqueKey"
import {
  useGetVacancyRateDataByGroupQuery,
  useGetVacancyRateDataQuery,
} from "v2/redux/GraphqlApi/DashboardApi"
import { updateFilters, updateInitialFilter, updateSort } from "v2/redux/slices/DrillDownSlice"
import { useAppDispatch } from "v2/redux/store"

const VacancyRate = () => {
  const [selectedGroup, setSelectedGroup] = useState(DEFAULT_GROUP_BY)
  const [groupChanged, setGroupChanged] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const dispatch = useAppDispatch()

  const vacancyRateQuery = useGetVacancyRateDataQuery({
    chart_key: `chart_${window.gon.official_chart_id}`,
    group_by: { field: DEFAULT_GROUP_BY },
  })

  const { vacancyRate, comparison, initialGroups, options } = vacancyRateQuery.data ?? {}

  const groupedDataQuery = useGetVacancyRateDataByGroupQuery(
    {
      chart_key: `chart_${window.gon.official_chart_id}`,
      group_by: { field: selectedGroup },
    },
    {
      skip: !groupChanged,
    },
  )

  const groups = groupedDataQuery.data ?? undefined

  // Re-enables grid drag once the data loads.
  // Necessary b/c the drag handle doesn't render
  // until the data is loaded.
  useEffect(() => {
    if (!vacancyRateQuery.data) return

    const { grid } = window

    if (grid) {
      grid.enableMove(false)
      grid.enableMove(true)
    }
  }, [vacancyRateQuery.data])

  const handleGroupChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const updatedGroup = e.target.value
    setSelectedGroup(updatedGroup)
    setGroupChanged(true)
  }

  const openModal = (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    const initialFilterKey = e.currentTarget.id
    const keySuffix = initialFilterKey && idFromUniqueKey(initialFilterKey)
    const initialFilterValue = e.currentTarget.dataset.entryLabel || undefined

    const initialFilter = initialFilterValue
      ? {
          type: FilterType.Inclusion,
          field: selectedGroup,
          value: {
            in: [keySuffix === NONE_KEY_SUFFIX ? keySuffix : initialFilterValue],
          },
        }
      : undefined

    dispatch(updateSort([]))
    dispatch(updateInitialFilter({ filterKey: selectedGroup, filterValue: initialFilterKey }))
    dispatch(updateFilters(getFiltersForDrillDown("vacancyRate", initialFilter)))
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const groupEntries = groups || initialGroups

  const dataLoaded = vacancyRate !== undefined && options

  return dataLoaded ? (
    <>
      <Modal
        isOpen={isModalOpen}
        title={"Open Positions".t("dashboard")}
        onClose={closeModal}
        size="xxl"
      >
        <DrillDown widget="vacancyRate" />
      </Modal>

      <Title
        title={"Vacancy Rate".t("dashboard")}
        description={"vacancy_rate_description".t("dashboard")}
      />

      <div className="mt-4">
        <Value value={vacancyRate} comparison={comparison} handleClick={openModal} />
      </div>

      <Select
        options={options}
        selected={selectedGroup}
        onSelect={handleGroupChange}
        className="mb-2"
      />

      {!groupedDataQuery.isFetching && groupEntries ? (
        <BarGrid entries={formatEntries(groupEntries)} handleClick={openModal} />
      ) : (
        <div className="grow">
          <Spinner style={{ position: "relative" }} />
        </div>
      )}
    </>
  ) : (
    <Spinner />
  )
}

const formatEntries = (entries: GroupData[]) =>
  entries.map((group) => ({
    label: group.label,
    id:
      group.label === "None".t("options")
        ? `${entityFromUniqueKey(group.id)}_${NONE_KEY_SUFFIX}`
        : group.id,
    width: group.value,
    metric: group.value,
  }))

export { VacancyRate }
