import { TFunction } from "i18next"
import fp from "lodash/fp"
import { useTranslation } from "react-i18next"

import { Option } from "types/graphql"
import { BasePay } from "types/graphql.enums"
import {
  SimpleEntry,
  WatchEntry,
} from "v2/react/components/positions/positionFieldValuesDiff/types"
import {
  isCustomFieldEntry,
  isOrgUnitEntry,
  isSimpleEntryFor,
  isVariablePayEntry,
} from "v2/react/components/positions/positionFieldValuesDiff/utils/entryHelpers"
import { useFieldLabelHelpers } from "v2/react/components/positions/positionForm"
import { formatCurrency } from "v2/react/utils/currency"
import { safeNumber } from "v2/react/utils/safeNumber"
import { PositionFormCollections } from "v2/redux/GraphqlApi/PositionsApi"

type DiffEntryRendererProps = {
  positionFormCollections: PositionFormCollections
  entry: WatchEntry
  render: (props: SingleEntryProps) => React.ReactNode
}

type SingleEntryProps = {
  entry: WatchEntry
  label: React.ReactNode
  current: React.ReactNode
  initial: React.ReactNode
}

const DiffEntryRenderer = ({ positionFormCollections, entry, render }: DiffEntryRendererProps) => {
  const { t } = useTranslation()
  const { tLabel } = useFieldLabelHelpers()

  if (isOrgUnitEntry(entry)) {
    return render({
      entry,
      current: entry.current?.fullName,
      initial: entry.initial?.fullName,
      label: entry.label,
    })
  }

  if (isVariablePayEntry(entry)) {
    return render({
      entry,
      current: entry.current?.formattedString,
      initial: entry.initial?.formattedString,
      label: entry.label,
    })
  }

  if (isCustomFieldEntry(entry)) {
    return render({
      entry,
      current: entry.current?.value,
      initial: entry.initial?.value,
      label: entry.label,
    })
  }

  if (isSimpleEntryFor(entry, "position.budgetedBaseCompensation")) {
    return render({
      entry,
      current: buildBudgetedBaseCompensationLabel(entry.current, t),
      initial: buildBudgetedBaseCompensationLabel(entry.initial, t),
      label: tLabel(entry.fieldName),
    })
  }

  if (
    isSimpleEntryFor(entry, "position.importance") ||
    isSimpleEntryFor(entry, "position.hiringPriority")
  ) {
    return render({
      entry,
      current: isBlank(entry.current) ? null : t(`v2.defaults.${entry.current}`),
      initial: isBlank(entry.initial) ? null : t(`v2.defaults.${entry.initial}`),
      label: tLabel(entry.fieldName),
    })
  }

  if (isSimpleEntryFor(entry, "position.isAssistant")) {
    return render({
      entry,
      current: entry.current ? t("v2.defaults.assistant") : t("v2.defaults.standard"),
      initial: entry.initial ? t("v2.defaults.assistant") : t("v2.defaults.standard"),
      label: tLabel(entry.fieldName),
    })
  }

  if (isSimpleEntryFor(entry, "position.employeeTypeId")) {
    return render({
      entry,
      current: getOptionLabel(positionFormCollections.employeeTypes, entry.current),
      initial: getOptionLabel(positionFormCollections.employeeTypes, entry.initial),
      label: tLabel(entry.fieldName),
    })
  }

  if (isSimpleEntryFor(entry, "position.positionBasePayType")) {
    return render({
      entry,
      current: getOptionLabel(positionFormCollections.positionBasePayTypes, entry.current),
      initial: getOptionLabel(positionFormCollections.positionBasePayTypes, entry.initial),
      label: tLabel(entry.fieldName),
    })
  }

  if (isSimpleEntryFor(entry, "position.flsaClassification")) {
    return render({
      entry,
      current: getOptionLabel(positionFormCollections.flsaClassifications, entry.current),
      initial: getOptionLabel(positionFormCollections.flsaClassifications, entry.initial),
      label: tLabel(entry.fieldName),
    })
  }

  if (isSimpleEntryFor(entry, "position.eeocClassification")) {
    return render({
      entry,
      current: getOptionLabel(positionFormCollections.eeocClassifications, entry.current),
      initial: getOptionLabel(positionFormCollections.eeocClassifications, entry.initial),
      label: tLabel(entry.fieldName),
    })
  }

  if (isSimpleEntryFor(entry, "position.positionBasePay")) {
    return render({
      entry,
      current: formatCurrencyOrBlank(entry.current),
      initial: formatCurrencyOrBlank(entry.initial),
      label: tLabel(entry.fieldName),
    })
  }

  if (isSimpleEntryFor(entry, "position.totalBudgetedCompensation")) {
    return render({
      entry,
      current: formatCurrencyOrBlank(entry.current),
      initial: formatCurrencyOrBlank(entry.initial),
      label: tLabel(entry.fieldName),
    })
  }

  if (isSimpleEntryFor(entry, "position.positionType")) {
    return render({
      entry,
      current: entry.current?.jobCodeTitleLabel,
      initial: entry.initial?.jobCodeTitleLabel,
      label: tLabel(entry.fieldName),
    })
  }

  if (isSimpleEntryFor(entry, "position.locationId")) {
    return render({
      entry,
      current: getOptionLabel(positionFormCollections.locations, entry.current),
      initial: getOptionLabel(positionFormCollections.locations, entry.initial),
      label: tLabel(entry.fieldName),
    })
  }

  if (isSimpleEntryFor(entry, "position.positionStatusId")) {
    return render({
      entry,
      current: getOptionLabel(positionFormCollections.positionStatuses, entry.current),
      initial: getOptionLabel(positionFormCollections.positionStatuses, entry.initial),
      label: tLabel(entry.fieldName),
    })
  }

  return render({
    entry,
    current: entry.current,
    initial: entry.initial,
    label: tLabel(entry.fieldName),
  })
}

function isBlank(value: string | null | undefined) {
  return fp.isNil(value) || (typeof value === "string" && value.trim() === "")
}

function formatCurrencyOrBlank(value: string | null | number | undefined) {
  if ((typeof value === "string" && value.trim() === "") || fp.isNil(value)) {
    return null
  }

  return formatCurrency({
    value: safeNumber(value, { from: "currency" }),
    omitSymbol: false,
    trailing: true,
  })
}

function getOptionLabel<CollectionItem extends Option>(
  collection: CollectionItem[],
  value: string | null | undefined,
) {
  return collection.find(({ id }) => value === id)?.label
}

function buildBudgetedBaseCompensationLabel(
  snapshot: SimpleEntry<"position.budgetedBaseCompensation">["current"],
  t: TFunction,
) {
  if (fp.isNil(snapshot) || fp.isNil(snapshot?.amount)) return null

  const amount = formatCurrency({
    value: safeNumber(snapshot.amount),
    omitSymbol: false,
    trailing: true,
  })

  if (snapshot.payType === BasePay.Hourly) {
    return t("v2.positions.form.total_budgeted_base_compensation_hourly", {
      amount,
      hours_per_week: snapshot.hoursPerWeek,
    })
  }

  return t("v2.positions.form.total_budgeted_base_compensation_salary", { amount })
}

export { DiffEntryRenderer, DiffEntryRendererProps, SingleEntryProps }
