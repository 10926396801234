import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import AnimateHeight from "react-animate-height"

import { Person } from "types/graphql.d"

const ANIMATION_DURATION = 250

interface PersonListItemProps {
  isSaving: boolean
  person: Person
  onItemRemoved: (event: React.MouseEvent<HTMLElement>) => void
}

const PersonListItem = ({ isSaving, person, onItemRemoved }: PersonListItemProps) => {
  const [height, setHeight] = useState<0 | "auto">("auto")
  const handleRemoveValueClick = (e: React.MouseEvent<HTMLElement>) => {
    setHeight(0)
    onItemRemoved(e)
  }

  return (
    // For list item animations, an adaptation with credit to:
    // https://muffinman.io/react-animate-height/
    // https://github.com/Stanko/react-animate-height#demo
    <AnimateHeight duration={ANIMATION_DURATION} height={height}>
      <div className="gap-3 flex">
        <div className="border--main grow items-center gap-2.5 rounded-lg bg-white px-3 py-2.5 flex">
          <img alt={person.name} className="circle circle-sm" src={person.avatarThumbUrl || ""} />
          <span>{person.name}</span>
        </div>
        <button
          className="bg-transparent p-0"
          disabled={isSaving}
          onClick={handleRemoveValueClick}
          data-value={person.id}
          type="button"
        >
          <FontAwesomeIcon icon={["far", "times"]} size="lg" />
        </button>
      </div>
    </AnimateHeight>
  )
}

export { ANIMATION_DURATION, PersonListItem }
