/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const attachVariablePayRowEvents = function () {
  $(".variable-pay-pay-type-field")
    .off("change")
    .on("change", function () {
      toggleVariableSymbol.call(this, true)
      return calculateTotalPay()
    })
  $(".variable-pay-amount-field").off("keyup").on("keyup", calculateTotalPay)
  $(".select select, .relative .input:not([readonly])")
    .on("focus", function () {
      return $(this).parents(".select, .relative").addClass("active")
    })
    .on("blur", function () {
      return $(this).parents(".select, .relative").removeClass("active")
    })
  return $(".variable-pay-name-wrapper")
    .off("mouseover")
    .on("mouseover", maybeAttachOverflowTooltip)
}

var maybeAttachOverflowTooltip = function () {
  const $variablePayNameWrapper = $(this)
  if ($variablePayNameWrapper.hasClass("tooltip")) {
    return
  }

  const $variablePayName = $variablePayNameWrapper.find(".variable-pay-name")
  const isOverflow = $variablePayName[0].offsetWidth < $variablePayName[0].scrollWidth

  if (isOverflow) {
    const tooltipElement = `\
<div class='tooltip-content tooltip-content--10rem whitespace-normal'>
  ${$variablePayName.text()}
</div>\
`
    $variablePayNameWrapper.addClass("tooltip tooltip-center")
    return $variablePayNameWrapper.append(tooltipElement)
  }
}

const initVariablePaySymbol = function () {
  return toggleVariableSymbol.call(this)
}

const correspondingAmountField = function (payTypeField) {
  const this_id = payTypeField.attr("id")
  const correspondingAmountFieldSelector = this_id.replace(/_pay_type/, "_amount")
  return $(`#${correspondingAmountFieldSelector}`)
}

var toggleVariableSymbol = function (resetAmountValue) {
  resetAmountValue ??= false
  const payType = $(this).val()
  const $amountField = correspondingAmountField($(this))
  if (resetAmountValue) {
    $amountField.val("")
    $amountField.attr("value", "")
    $amountField.trigger("focus")
  }

  const $input_add_on = $amountField.siblings(".prefix")
  if (payType === "amount") {
    $input_add_on.find(".currency-icon").show()
    return $input_add_on.find(".fa-percent").hide()
  } else {
    $input_add_on.find(".currency-icon").hide()
    return $input_add_on.find(".fa-percent").show()
  }
}

const resetVariablePayRowIndex = function (index, row) {
  const $row = $(row)
  $row.attr("data-index", index)
  $row.find('[name*="[variable_pays_attributes]"]').each(function (i, el) {
    return $(this).attr(
      "name",
      $(this)
        .attr("name")
        .replace(/(-?)\d+/, index),
    )
  })
  return $row.find('[id*="_variable_pays_attributes"]').each(function (i, el) {
    return $(this).attr(
      "id",
      $(this)
        .attr("id")
        .replace(/(-?)\d+/, index),
    )
  })
}

const resetVariablePayRowIndexes = function () {
  $("[data-variable-pay-row]:not(.template-row)").each((index, row) =>
    resetVariablePayRowIndex(index, row),
  )
  return attachVariablePayRowEvents()
}

const setAddVariablePayButtonState = function (disable) {
  const $addVariablePayButton = $("#add-variable-pay")
  return $addVariablePayButton?.prop("disabled", disable)?.toggleClass("disabled", disable)
}

const setModalSubmitButtonState = function (disable) {
  const $modalSubmitButton = $(".modal-footer input[type=submit]")
  return $modalSubmitButton?.prop("disabled", disable)?.toggleClass("disabled", disable)
}

const handleSettingButtonStates = function (disable) {
  setAddVariablePayButtonState(disable)
  return setModalSubmitButtonState(disable)
}

const handleShowAddVariablePayForm = function () {
  let disable
  $("#variable-pays-form").show()
  handleSettingButtonStates((disable = true))
  return $("[name=new_variable_pay_type_name]").trigger("focus")
}

const cancelAddVariablePay = function () {
  let disable
  clearVariablePaySaveError()
  $("#variable-pays-form").hide()
  handleSettingButtonStates((disable = false))
  return $("[name=new_variable_pay_type_name]").val("")
}

const renderVariablePayRow = function () {
  const name = $("#new_variable_pay_type_name").val()
  const variable_pay_type_id = $("#new_variable_pay_type_id").val()
  const $newForm = $(".variable-pay-form").last().clone()
  $newForm.removeClass("hidden template-row")
  $newForm.find(".form-error").removeClass(".form-error")
  $newForm.find(".form-error-message").remove()
  $newForm.find("select option[value=amount]").prop("selected", true)
  $newForm.find(".variable-pay-name").text(name)
  $newForm.find("input").attr("value", "")
  $newForm.find("input[name*=variable_pay_type_id]").attr("value", variable_pay_type_id)
  appendAlphabetically($newForm, name)
  resetVariablePayRowIndexes()
  $newForm.show()
  $.onmount()
  attachVariablePayRowEvents()
  return $newForm.find(".variable-pay-pay-type-field").trigger("change")
}

var appendAlphabetically = function ($form, name) {
  const names = variablePayTypeNames()
  if (names.length === 0) {
    return $(".variable-pays").append($form)
  }
  names.push(name)
  const targetIndex = names.sort().indexOf(name)
  if (targetIndex === 0) {
    return $(".variable-pays").prepend($form)
  } else {
    return $form.insertAfter(
      $(".variable-pays")
        .children(":visible")
        .eq(targetIndex - 1),
    )
  }
}

var variablePayTypeNames = function () {
  const names = []
  $("[data-variable-pay-row]:not(.template-row):visible").each((index, row) =>
    names.push($(row).find(".variable-pay-name").text()),
  )
  return names
}

const autofillNewVariablePayType = function (data) {
  $("#new_variable_pay_type_id").val(data.id)
  return $("#new_variable_pay_type_name").val(data.name)
}

const addVariablePaySaveError = function (message) {
  const $inputGroup = $("[name=new_variable_pay_type_name]").closest(".input-group")
  $inputGroup.addClass("form-error")
  return $inputGroup
    .find("#create-variable-pay-type-error")
    .append($('<div class="form-error-message"></div>').text(message))
}

const clearHoursPerWeekErrors = function () {
  const $inputGroup = $("#hours-per-week").closest(".input-group")
  $inputGroup.removeClass("form-error")
  return $inputGroup.find(".form-error-message").remove()
}

const clearBasePayErrors = function () {
  const $inputGroup = $("#base-pay").closest(".input-group")
  $inputGroup.removeClass("form-error")
  return $inputGroup.find(".form-error-message").remove()
}

var clearVariablePaySaveError = function () {
  const $inputGroup = $("[name=new_variable_pay_type_name]").closest(".input-group")
  $inputGroup.removeClass("form-error")
  return $inputGroup.find("#create-variable-pay-type-error .form-error-message").remove()
}

const saveNewVariablePayType = function () {
  clearVariablePaySaveError()
  const model_type = $(".budget-table").data("model-type")
  const name = $("[name=new_variable_pay_type_name]").val()
  $(this)
    .attr("disabled", "disabled")
    .addClass("disabled")
    .text("add_disabled".t("button_defaults"))
  return $.post({
    url: App.endpoint(["companies", gon.current_company_id, "variable_pay_types"]),
    data: { name, model_type },
  })
    .done(function (response, status, xhr) {
      autofillNewVariablePayType(response)
      clearVariablePaySaveError()
      renderVariablePayRow()
      return cancelAddVariablePay()
    })
    .fail(function (jqXHR) {
      const errorMessage = jqXHR.responseJSON.error
      return addVariablePaySaveError(errorMessage)
    })
    .always(() => {
      return $(this)
        .attr("disabled", false)
        .text("add_enabled".t("button_defaults"))
        .removeClass("disabled")
    })
}

var calculateTotalPay = function () {
  const $basePayInput = $("#base-pay")
  if (!($basePayInput.length > 0)) {
    return
  }

  const $basePayTypeInput = $("#base-pay-type")
  let basePay = App.Helpers.parseCurrency($basePayInput.val()) || 0

  if ($basePayTypeInput.val() === "hourly") {
    const hoursPerWeek = $("#hours-per-week").val()
    basePay = basePay * hoursPerWeek * 52
  }

  const variablePaySelector = (index, attribute_name) =>
    `[data-variable-pay-row]:not(.template-row) [id*='_variable_pays_attributes_${index}_${attribute_name}']`

  let totalVariablePay = 0
  let indexOfVariablePays = 0

  const variablePayAmount = function (index, basePay) {
    let variablePay
    const $variablePayInput = $(variablePaySelector(index, "amount"))
    const $variablePayTypeInput = $(variablePaySelector(index, "pay_type"))

    if (!$variablePayInput.is(":visible")) {
      return 0
    }

    if ($variablePayTypeInput.val() === "percent") {
      variablePay = $variablePayInput.val().replace(/[^0-9.]/g, "")
      variablePay = (variablePay / 100) * basePay
    } else {
      variablePay = App.Helpers.parseCurrency($variablePayInput.val()) || 0
    }

    return variablePay
  }

  while (true) {
    var variablePayExists = $(variablePaySelector(indexOfVariablePays, "id")).length === 1
    if (!variablePayExists) {
      break
    }

    totalVariablePay += variablePayAmount(indexOfVariablePays, basePay)
    indexOfVariablePays += 1
  }

  const total = basePay + totalVariablePay
  $("#total_").val(App.Helpers.formatCurrency(total, { omitSymbol: true, trailing: true }))

  return window.FundingSources.updateFundingSourceAmounts()
}

const toggleHourlyFields = function () {
  if ($("#base-pay-type").val() === "salary") {
    $(".hourly-field").hide()
    $(".hourly-field").parents(".input-group").hide()
    return $("#base-pay").parents(".input-group").addClass("last-three")
  } else {
    $(".hourly-field").show()
    $(".hourly-field").parents(".input-group").show()
    return $("#base-pay").parents(".input-group").removeClass("last-three")
  }
}

const maybeDestroyVariablePayRecords = function () {
  const recordsToDestroy = $("[data-variable-pay-row]:not(.template-row)").filter(function () {
    const variablePayAmount = $(this).find(".variable-pay-amount-field").val().trim()

    return (
      $(this).find(".id-hidden-field").val().length !== 0 &&
      (variablePayAmount.length === 0 || variablePayAmount === "0")
    )
  })
  return recordsToDestroy.each(function () {
    return $(this).find('[name*="_destroy"]').val("true")
  })
}

const addVariablePayFormEvents = function ($addVariablePayForm) {
  if ($addVariablePayForm.length === 0) {
    return
  }
  const formIsVisible = () => $addVariablePayForm.is(":visible")

  // Ensures the popover closes when clicking outside of it
  $(document).mousedown(function (e) {
    if (!formIsVisible()) {
      return
    }
    const isVariablePayFormClicked =
      $addVariablePayForm.is(e.target) || $addVariablePayForm.has(e.target).length > 0

    if (!isVariablePayFormClicked) {
      return cancelAddVariablePay()
    }
  })

  // Ensures the popover closes when tabbing out of it
  $addVariablePayForm.on("focusout", function (e) {
    if (!formIsVisible()) {
      return
    }
    return setTimeout(function () {
      if (document.activeElement === document.body) {
        return
      }
      if ($addVariablePayForm.has(document.activeElement).length) {
        return
      }
      return cancelAddVariablePay()
    }, 0)
  })

  $addVariablePayForm
    .find("[data-action=cancel-add-variable-pay]")
    .off("click")
    .on("click", cancelAddVariablePay)
  return $addVariablePayForm
    .find("[data-action=save-new-variable-pay-type]")
    .off("click")
    .on("click", saveNewVariablePayType)
}

const tableEvents = function (scope) {
  const $scope = $(scope)
  toggleHourlyFields()
  $scope.find(".variable-pay-pay-type-field").each(initVariablePaySymbol)
  calculateTotalPay()

  addVariablePayFormEvents($scope.find("#variable-pays-form"))
  $scope
    .find("[data-action=add-variable-pay]")
    .off("click")
    .on("click", handleShowAddVariablePayForm)

  $scope.find("[data-action=save]").on("click", maybeDestroyVariablePayRecords)

  $scope.find("#base-pay-type").on("change", function () {
    $("#base-pay").val("")
    clearBasePayErrors()
    clearHoursPerWeekErrors()
    toggleHourlyFields()
    return calculateTotalPay()
  })

  attachVariablePayRowEvents()

  return $scope.find("#base-pay, #hours-per-week").on("keyup", calculateTotalPay)
}

export { tableEvents }
