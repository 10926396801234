/* eslint-disable react/jsx-props-no-spreading */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { isNil } from "lodash"
import React, { forwardRef, useEffect, useState } from "react"
import { useController } from "react-hook-form"
import { useBoolean } from "usehooks-ts"

import { InputWrapper } from "v2/react/shared/forms/InputWrapper"
import { MaybeNumeric, safeNumber } from "v2/react/utils/safeNumber"

import { UseInReactHookFormProp } from "./types"

interface PercentInputProps {
  defaultValue?: string | undefined | number
  value?: string | undefined | number
  errors?: string
  id: string
  inputClassName?: string
  formatDefaultValue?: boolean
  label?: string
  locale?: string
  name: string
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  placeholder?: string
  showErrorMessage?: boolean
  useAttentionState?: boolean
  useInReactHookForm?: UseInReactHookFormProp
  useSuffix?: boolean
  wrapperClassName?: string
}

type PercentInputWithoutWrapperProps = Omit<
  PercentInputProps,
  "label" | "showErrorMessage" | "wrapperClassName" | "useInReactHookForm"
>

const PercentInputWithoutWrapper = forwardRef<HTMLInputElement, PercentInputWithoutWrapperProps>(
  (
    {
      defaultValue,
      value,
      errors,
      formatDefaultValue,
      id,
      inputClassName,
      locale = window.gon?.current_user_locale,
      name,
      onBlur,
      onChange,
      onFocus,
      placeholder = "",
      useAttentionState,
      useSuffix = true,
    },
    ref,
  ) => {
    const { setFalse: loseFocus, setTrue: gainFocus, value: isFocused } = useBoolean()
    const [inputValue, setInputValue] = useState<string>(
      getDefaultValue(defaultValue, locale, formatDefaultValue),
    )

    const handleBlur: React.FocusEventHandler<HTMLInputElement> = (ev) => {
      loseFocus()
      onBlur?.(ev)
    }
    const handleFocus: React.FocusEventHandler<HTMLInputElement> = (ev) => {
      gainFocus()
      onFocus?.(ev)
    }
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value)
      onChange?.(e)
    }

    const showAttention = useAttentionState && !isFocused && !errors && !inputValue.trim()

    const inputProps = {
      ref,
      type: "text",
      className: classNames("input", useSuffix ? "suffix-pad" : "prefix-pad", inputClassName),
      placeholder,
      id,
      name,
      onChange: handleChange,
      onFocus: handleFocus,
      onBlur: handleBlur,
    }

    return (
      <div
        className={classNames("relative", {
          active: isFocused,
          attention: showAttention,
        })}
      >
        <div className={useSuffix ? "suffix" : "prefix"}>
          <FontAwesomeIcon icon={["far", "percent"]} />
        </div>
        {value === undefined ? (
          <input
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...inputProps}
            defaultValue={isNil(defaultValue) ? "" : `${defaultValue}`}
          />
        ) : (
          <input
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...inputProps}
            value={value}
          />
        )}
      </div>
    )
  },
)

const RegisteredPercentInputWithoutWrapper = forwardRef<
  HTMLInputElement,
  PercentInputWithoutWrapperProps
>(
  (
    { defaultValue, name, onBlur, onChange, locale = window.gon.current_user_locale, ...props },
    ref,
  ) => {
    const { field } = useController({ name, defaultValue })
    const fieldValue = field.value
    const [inputValue, setInputValue] = useState(
      getDefaultValue(field.value, locale, props.formatDefaultValue)?.toString() ?? "",
    )

    // In order to support formatting the default value, we have to manage the
    // field value. But we need to react to changes in field.value that may have
    // come through something such as react-hook-form's `setValue`. Handle here.
    useEffect(() => {
      setInputValue((current) =>
        safeNumber(current, { fallback: null }) !== safeNumber(fieldValue, { fallback: null })
          ? fieldValue?.toString()
          : current,
      )
    }, [fieldValue])

    return (
      <PercentInputWithoutWrapper
        {...props}
        name={name}
        onBlur={(ev) => {
          field.onBlur()
          onBlur?.(ev)
        }}
        onChange={(ev) => {
          setInputValue(ev.target.value)
          field.onChange(safeNumber(ev.target.value, { fallback: null }))
          onChange?.(ev)
        }}
        ref={ref ?? field.ref}
        value={inputValue}
      />
    )

    return null
  },
)

const PercentInput = forwardRef<HTMLInputElement, PercentInputProps>(
  (
    {
      defaultValue,
      value,
      errors,
      formatDefaultValue,
      id,
      inputClassName,
      label,
      locale,
      name,
      onBlur,
      onChange,
      onFocus,
      placeholder = "",
      showErrorMessage = true,
      useAttentionState,
      useSuffix = true,
      wrapperClassName,
      useInReactHookForm,
    },
    ref,
  ) => {
    const InternalComponent = useInReactHookForm
      ? RegisteredPercentInputWithoutWrapper
      : PercentInputWithoutWrapper
    return (
      <InputWrapper
        label={label}
        id={id}
        errors={errors}
        name={name}
        showErrorMessage={showErrorMessage}
        className={wrapperClassName}
        useInReactHookForm={useInReactHookForm}
      >
        <InternalComponent
          defaultValue={defaultValue}
          formatDefaultValue={formatDefaultValue}
          id={id}
          inputClassName={inputClassName}
          locale={locale}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          placeholder={placeholder}
          ref={ref}
          useAttentionState={useAttentionState}
          useSuffix={useSuffix}
          value={value}
        />
      </InputWrapper>
    )
  },
)

const getDefaultValue = (initial: MaybeNumeric, locale: string, formatDefaultValue?: boolean) => {
  const number = safeNumber(initial, { fallback: null })
  if (number === null) return ""
  if (!formatDefaultValue) return `${number}`

  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number)
}

export {
  PercentInput,
  PercentInputProps,
  PercentInputWithoutWrapper,
  PercentInputWithoutWrapperProps,
  RegisteredPercentInputWithoutWrapper,
}
