import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { motion } from "framer-motion"
import React, { ReactNode } from "react"

interface RemovableFieldProps {
  children: ReactNode
  id: string
  removeField: (id: string) => void
}

/**
 *
 * This component is a basic wrapper for a removable field or other item. It absolutely
 * positions an 'x' icon in the upper right corner
 *
 * Props:
 * - `children`: A ReactNode representing the internal field or item
 * - `id`: A string for the id of the the field or item being wrapped
 * - `removeField`: A callback function to to remove the field or item from being displayed
 * Usage:
 * ```
 *  <RemovableField id={field.id} removeField={onRemove}>
 *    <SelectorModuleButtonGroup options={filterBy} onSelect={handleSelect} fieldLabel={field.label}/>
 *  </RemovableField>
 * ```
 */
const RemovableField = ({ children, id, removeField }: RemovableFieldProps) => (
  <motion.div layout="position" className="group relative">
    <button
      className="absolute right-0 z-1 h-5 cursor-pointer place-content-center rounded border-none bg-transparent p-2 opacity-0 transition-all grid hover:bg-neutral-8 group-hover:opacity-100"
      id={`remove-${id}-filter`}
      data-testid="remove-field"
      onClick={() => removeField(id)}
      type="button"
    >
      <FontAwesomeIcon icon={["far", "times"]} />
    </button>
    {children}
  </motion.div>
)

export { RemovableField }
