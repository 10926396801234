import { isEmpty } from "lodash/fp"
import React, { useEffect, useState } from "react"

import { CursorSaveOptions } from "v2/react/components/orgChart/Datasheet/types"
import { FollowUpModalProps } from "v2/react/components/orgChart/OrgChartDatasheet/FollowUpModals"
import { usePreparedUpdatePersonPositionNodeMutation } from "v2/react/components/orgChart/OrgChartDatasheet/hooks/useUpdatePersonPositionNodeFunc"
import { TitleChangeFollowUpModal } from "v2/react/components/orgChart/OrgChartDatasheet/Modals/TitleChangeFollowUp/TitleChangeFollowUpModal"
import { AddPositionTypeModal } from "v2/react/components/positionTypes/modals/AddPositionTypeModal"
import { PositionTypesConnectionNode } from "v2/redux/GraphqlApi/PositionTypesApi"
import { useDatasheetListenerActions } from "v2/redux/listeners/datasheetListeners"
import {
  getIdAndNameFromEncoded,
  getInitialDataFromEncoded,
  isEncodedInitialData,
  toEncodedIdNamePair,
} from "v2/redux/slices/GridSlice/gridHelpers/orgUnits"
import { preparePersonPositionNodeAttributes } from "v2/redux/slices/NodeSlice/mutations/preparePersonPositionNodeAttributes"
import { useFetchPositionTypeControlledAttributesWithValuesQuery } from "v2/redux/slices/NodeSlice/NodeApi"
import { UpdateInlineAttributeInput } from "v2/redux/slices/NodeSlice/types"
import { useAppSelector } from "v2/redux/store"

interface InternalFollowUpProps extends FollowUpModalProps {
  cancelFollowUp: () => void
  execute: (
    input: UpdateInlineAttributeInput,
    options?: CursorSaveOptions,
  ) => Promise<{
    ok: boolean
  }>
  onSaved: () => void
}

/**
 * This component is responsible for handling the follow-up modal for title
 * changes. There are actually two distinct follow-up flows here.
 *
 * ## Flow 1: Change to Existing Position Type w/ Controlled Attributes
 *
 * It fetches the controlled attributes for the new position type and if there
 * are any attributes set on that position type, it confirms with the user that
 * they still want to make the change. This is done b/c the position will take
 * on any set attributes from the new position type.
 *
 * If there are no controlled attributes set on the position type, then the
 * modal is skipped and the title change is saved immediately.
 *
 * ## Flow 2: Creating a New Position Type
 *
 * Presents a modal to create a new job definition (a/k/a position type). Makes
 * no change if the user cancels out.
 */
const TitleChangeFollowUp = ({ isOpen, field, row }: FollowUpModalProps) => {
  const { cancelFollowUp, saveModalSuccess } = useDatasheetListenerActions()
  const { execute } = usePreparedUpdatePersonPositionNodeMutation()

  const props = {
    cancelFollowUp,
    field,
    row,
    isOpen,
    execute,
    onSaved: saveModalSuccess,
  }

  return isEncodedInitialData(field.value) ? (
    <AddPositionTypeFollowUp {...props} /> // eslint-disable-line react/jsx-props-no-spreading
  ) : (
    <ChangeToExistingFollowUp {...props} /> // eslint-disable-line react/jsx-props-no-spreading
  )
}

const AddPositionTypeFollowUp = ({
  cancelFollowUp,
  execute,
  field,
  isOpen,
  row,
  onSaved,
}: InternalFollowUpProps) => {
  const { code, name } = getInitialDataFromEncoded(field.value)
  const featureFlags = useAppSelector((state) => state.session.featureFlags)

  const handleSaveWithCreated = async (positionType: PositionTypesConnectionNode) => {
    const attributes = preparePersonPositionNodeAttributes({
      featureFlags,
      fieldKey: field.fieldKey,
      node: row.data,
      value: toEncodedIdNamePair(positionType.uniqueKey, positionType.jobCodeTitleLabel ?? ""),
    })

    // It is expected that the NodeApi module will dispatch error information
    // into Redux, so there's no special handling here.
    const result = await execute({ id: row.id, attributes, primaryTrigger: field.fieldKey })
    if (result.ok) onSaved()
  }

  return (
    <AddPositionTypeModal
      initialData={{ jobCode: code, title: name }}
      isOpen={isOpen}
      onClose={cancelFollowUp}
      onSave={handleSaveWithCreated}
    />
  )
}

const ChangeToExistingFollowUp = ({
  cancelFollowUp,
  execute,
  field,
  isOpen,
  row,
  onSaved,
}: InternalFollowUpProps) => {
  const [isSaving, setIsSaving] = useState(false)
  const { id, name } = getIdAndNameFromEncoded(field.value ?? "")

  const { data, isError, isLoading, isSuccess } =
    useFetchPositionTypeControlledAttributesWithValuesQuery({ id }, { skip: !isOpen })

  const controlledAttributeNamesWithSetValuesHash =
    data?.positionType?.controlledAttributeNamesWithSetValuesHash
  const noControlledAttributesSetOnPositionType =
    isSuccess && isEmpty(controlledAttributeNamesWithSetValuesHash)
  const featureFlags = useAppSelector((state) => state.session.featureFlags)

  const handleSubmit = async (
    e?: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>,
  ) => {
    if (e) {
      e.preventDefault()
      setIsSaving(true)
    }

    const attributes = preparePersonPositionNodeAttributes({
      fieldKey: field.fieldKey,
      node: row.data,
      value: field.value,
      featureFlags,
    })

    // It is expected that the NodeApi module will dispatch error information
    // into Redux, so there's no special handling here.
    const result = await execute({ id: row.id, attributes, primaryTrigger: field.fieldKey })
    if (result.ok) onSaved()
  }

  useEffect(() => {
    // If there are no controlled attributes set on the position type, then
    // there's no need to prompt the user for a confirmation, so we proceed
    // with the save.
    // Note: This creates a bit of a delay in the UX as the data fetches. Future
    // improvements could include adding a loadings state to the cell, or
    // including the necessary data in the title dropdown so we could skip
    // the follow up flow entirely if we don't need it.
    if (noControlledAttributesSetOnPositionType) {
      handleSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noControlledAttributesSetOnPositionType])

  if (isLoading || isError || noControlledAttributesSetOnPositionType) {
    return null
  }

  return (
    <TitleChangeFollowUpModal
      maybeInitialTitle={row.data.title}
      maybeNewTitle={name}
      controlledAttributeNamesWithSetValuesHash={controlledAttributeNamesWithSetValuesHash}
      isOpen={isOpen}
      isSaving={isSaving}
      handleClose={cancelFollowUp}
      handleSubmit={handleSubmit}
    />
  )
}

export { TitleChangeFollowUp }
