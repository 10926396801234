import { ControlProps } from "@jsonforms/core"
import React, { FC } from "react"

import { sanitizeParse } from "v2/react/utils/sanitizeParse"

const JsonModuleListDescriptionItem: FC<ControlProps> = ({ data, visible }) => {
  if (!visible) return null

  return (
    <div className="module-card-list-item">
      {data && <div className="col-span-3">{sanitizeParse(data)}</div>}
    </div>
  )
}

export { JsonModuleListDescriptionItem }
