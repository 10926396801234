import React from "react"
import { useTranslation } from "react-i18next"

import { PositionType } from "types/graphql"
import {
  CellData,
  ColumnCell,
  jobLevelCell,
  payGradeCell,
} from "v2/react/components/positions/Show/Details/ColumnCell"
import {
  customFieldTitleLabel,
  customFieldValueLabel,
  eeocValue,
  flsaValue,
} from "v2/react/components/positions/Show/Details/utils"
import { SmallEditButton } from "v2/react/components/positionTypes/Show/SmallEditButton"
import { StatusBadge } from "v2/react/shared/status/StatusBadge"
import { pjaxModalFor } from "v2/react/utils/pjax"
import { UrlHelper } from "v2/react/utils/urls"

interface DetailsProps {
  positionType: PositionType
  positionTypeId: string
  positionManagement: boolean | null | undefined
  canReadPayGrade: boolean
}

function Details({
  positionType,
  positionTypeId,
  canReadPayGrade,
  positionManagement,
}: DetailsProps) {
  const { t } = useTranslation()

  const handleEdit = () => pjaxModalFor(UrlHelper.editPositionTypePath(positionTypeId))

  let cellData: CellData[] = [
    { title: t("v2.position_types.show.title"), content: positionType.title || "-" },
    { title: t("v2.position_types.show.job_code"), content: positionType.jobCode || "-" },
    {
      title: t("v2.position_types.show.job_family"),
      content: (
        <a href={parentUrl(positionType)}>
          {positionType.jobFamily?.name || t("v2.position_types.show.unassigned")}
        </a>
      ),
    },
  ]
  if (positionManagement)
    cellData = cellData.concat([
      {
        title: t("v2.position_types.show.flsa_classification"),
        content: flsaValue(positionType, t),
      },
    ])
  cellData = cellData.concat([
    {
      title: t("v2.position_types.show.importance"),
      content: <StatusBadge displayType="button" status={positionType.importance || "none"} />,
    },
  ])
  if (positionManagement)
    cellData = cellData.concat([
      {
        title: t("v2.position_types.show.eeoc_classification"),
        content: eeocValue(positionType, t),
      },
    ])
  cellData = cellData.concat([
    {
      title: t("v2.position_types.show.department"),
      content: positionType.department?.label || "-",
    },
    ...(positionManagement
      ? [
          ...jobLevelCell(t, positionType),
          ...(canReadPayGrade ? payGradeCell(t, positionType) : []),
        ]
      : []),
    ...(positionType.customFieldValues || []).map((cfv) => ({
      title: customFieldTitleLabel(cfv),
      content: customFieldValueLabel(cfv),
    })),
  ])

  return (
    <div data-testid="details-module" className="module-card">
      <div className="module-card__header">
        <div className="module-title">{t("v2.position_types.show.details")}</div>
        <SmallEditButton onClick={handleEdit} />
      </div>
      <div className="module-card__body gap-0 border-0 p-0">
        <div className="flex-wrap flex">
          {cellData.map((cell: CellData, index) => (
            <ColumnCell
              content={cell.content}
              cellCount={cellData.length}
              index={index}
              // eslint-disable-next-line react/no-array-index-key
              key={`cell-${index}`}
              title={cell.title}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export { Details }

const parentUrl = (positionType: PositionType): string => {
  if (positionType.jobFamily?.id && positionType.jobFamily?.parent?.id)
    return UrlHelper.jobFamilyWithSubFamilyPath(
      positionType.jobFamily.id,
      positionType.jobFamily.parent.id,
    )

  if (positionType.jobFamily?.id) {
    return UrlHelper.jobFamilyPath(positionType.jobFamily.id)
  }
  return UrlHelper.unassignedJobFamilyPath()
}
