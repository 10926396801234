import React from "react"

type Collaborator = {
  uniqueKey: string
  person: {
    name: string
    avatarThumbUrl: string
  }
}
type Props = {
  collaborators: Collaborator[]
}
export function Collaborators({ collaborators }: Props) {
  const limit = collaborators.length === 5 ? 3 : 4
  return (
    <div className="circles collaborators">
      {collaborators.length >= 5 ? (
        <div className="circle-count circle circle-sm circle-border circle-solid tooltip tooltip-right mx-[-2px] box-content">
          <span className="count pr-1 text-[10px]">+{collaborators.length - limit}</span>
          <div className="tooltip-content tooltip-content--sm tooltip-right align-center flex-col hidden 1225:flex">
            {collaborators.slice(limit).map((collaborator) => (
              <div key={collaborator.uniqueKey}>{collaborator.person.name}</div>
            ))}
          </div>
        </div>
      ) : null}
      {collaborators.slice(0, limit).map((collaborator) => (
        <div
          key={collaborator.uniqueKey}
          className="circle circle-collaborator circle-sm circle-border tooltip tooltip-right mx-[-2px] box-content"
        >
          <img alt={collaborator.person.name} src={collaborator.person.avatarThumbUrl} />
          <div className="tooltip-content tooltip-content--sm hidden 1225:flex">
            {collaborator.person.name}
          </div>
        </div>
      ))}
    </div>
  )
}
