import { RawEditorOptions } from "tinymce"

const tinyMceDefaultConfig = (id: string): RawEditorOptions => {
  // See the rails version on window.tinymceDefaults. The setup callback can be
  // configured on an as-needed basis each time this function is used, since
  // it will be use case specific.
  const options: RawEditorOptions = {
    branding: false,
    browser_spellcheck: true,
    contextmenu: false,
    formats: {
      underline: { exact: true, inline: "u" },
    },
    height: 250,
    icons: "built-icons",
    inline_styles: false,
    link_target_list: [
      { text: "Current tab", value: "" },
      { text: "New tab", value: "_blank" },
    ],
    menubar: false,
    plugins: "link lists",
    selector: `textarea[id="${id}"]`,
    toolbar: "bold italic underline fontselect | bullist numlist | link unlink",
    init_instance_callback: (editor) => {
      editor.on(
        "focus",
        () =>
          editor.editorContainer
            .querySelector(".tox-editor-container")
            ?.classList.add("editor-focus"),
      )
      editor.on(
        "blur",
        () =>
          editor.editorContainer
            .querySelector(".tox-editor-container")
            ?.classList.remove("editor-focus"),
      )
    },
  }
  // Presently, this server side configuration value relies on the prior
  // definition on the global window object. Future frontend build definitions
  // (and things like storybook and jest) may warrant an adjustment to this.
  if (window.tinymceDefaults?.content_css) {
    options.content_css = window.tinymceDefaults.content_css
  }
  return options
}

export { tinyMceDefaultConfig }
