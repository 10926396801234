import { globals } from "../../constants/options"

const defineColorClip = function (
  svg: d3.Selection<SVGElement>,
  nodeHeight: number,
): d3.Selection<SVGElement> {
  svg
    .select("defs")
    .append("clipPath")
    .attr("id", "color-clip")
    .append("rect")
    .attr("x", -(globals.nodeWidth / 2))
    .attr("y", 0)
    .attr("height", nodeHeight)
    // The edge color code will occupy 2/3 of the node radius
    .attr("width", globals.nodeRadius * (2 / 3))

  return svg
}

export default defineColorClip
