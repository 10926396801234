import cn from "classnames"
import { times } from "lodash"
import React from "react"
import { useMediaQuery } from "usehooks-ts"

import { Candidate } from "types/graphql"
import { CandidateBadge } from "v2/react/components/succession/SuccessionPlanPanel/Matrix/CandidateBadge"
import { MatrixIcon } from "v2/react/components/succession/SuccessionPlanPanel/Matrix/Modal/MatrixIcon"
import {
  generateConditionalBackground,
  generateConditionalBorder,
} from "v2/react/components/succession/SuccessionPlanPanel/utils/successionUtils"
import { CardType, GridItem } from "v2/redux/slices/MatrixSlice"

interface GridItemCardProps {
  candidates: Candidate[]
  cardType: CardType
  gridItem: GridItem
  hideIfEmpty: boolean
  itemIndex: number
  performance: string
  potential: string
}

function GridItemCard({
  candidates,
  cardType,
  gridItem,
  hideIfEmpty,
  itemIndex,
  performance,
  potential,
}: GridItemCardProps) {
  const is1016orAbove = useMediaQuery("(min-width: 1016px)")

  const isMatch = (candidate: Candidate) =>
    candidate.performanceLevel === performance && candidate.potentialLevel === potential

  const matchingCandidates = candidates.filter(isMatch)

  if (!is1016orAbove && matchingCandidates.length === 0 && hideIfEmpty) return null

  return (
    <div
      className="matrix__grid-item border--main overflow-hidden rounded-lg max-[1016px]:min-h-fit"
      style={generateConditionalBorder(cardType, gridItem.color, "lg")}
    >
      <div className="relative h-fit items-center justify-between flex">
        <div
          className="absolute h-full w-full opacity-20"
          style={generateConditionalBackground(cardType, gridItem.color)}
        />
        <div className="px-3 py-2.5">
          <p className="line-clamp-1 text-base">{gridItem.label}</p>
        </div>
        {!is1016orAbove && (
          <MatrixIcon
            addItemBorder
            addMatrixBorder={false}
            palette={times(9, (i) =>
              i === itemIndex ? "rgba(012, 020, 075, 1)" : "rgba(012, 020, 075, .08)",
            )}
          />
        )}
      </div>
      <div
        className={cn(
          "border--main h-full max-h-[12rem] w-full flex-col gap-2.5 overflow-y-auto flex",
          { "p-3": matchingCandidates.length > 0 || is1016orAbove },
        )}
        style={generateConditionalBackground(cardType, gridItem.color)}
      >
        {matchingCandidates.map((candidate) => (
          <CandidateBadge candidate={candidate} key={candidate.id} />
        ))}
      </div>
    </div>
  )
}

export { GridItemCard }
