import React, { ReactNode } from "react"

import { NavHamburger } from "v2/react/shared/navigation/NavHamburger"

interface Props {
  children: ReactNode
}

function TitleBlock({ children }: Props) {
  return (
    <div className="title-block h-full items-center flex">
      <NavHamburger />
      {children}
    </div>
  )
}

function TitleBlockSmall({ children }: Props) {
  return (
    <div className="title-block-sm h-full items-center flex">
      <NavHamburger />
      {children}
    </div>
  )
}

function TitleBlockLarge({ children }: Props) {
  return (
    <div className="title-block-lg h-full items-center flex">
      <NavHamburger />
      {children}
    </div>
  )
}

interface TitleHeaderWithParentProps {
  parentTitle: string | null
  parentTitleUrl?: string
  title: string | ReactNode
}

function TitleHeaderWithParent({ parentTitle, parentTitleUrl, title }: TitleHeaderWithParentProps) {
  return (
    <div>
      <h3 className="text-sm text-neutral-64">
        {parentTitleUrl ? (
          <a className="text-neutral-64" href={parentTitleUrl}>
            {parentTitle}
          </a>
        ) : (
          <span>{parentTitle}</span>
        )}
      </h3>
      <h1 className="whitespace-nowrap text-large">{title}</h1>
    </div>
  )
}

export { TitleBlock, TitleBlockSmall, TitleBlockLarge, TitleHeaderWithParent }
