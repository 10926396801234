import { globals } from "../constants/options"
import { measureText } from "./nodePositioningHelper"

// Truncate text within a text element based on the inner width of an org chart
// node. The original text is passed to an optional callback.
function truncate(textElement, style, position, showLabels, callback) {
  let { maxWidth } = globals

  if (showLabels && position !== "center") {
    maxWidth /= 2
  }

  maxWidth -= 5

  const value = this.text()
  let shortValue = value
  if (!value) {
    return
  }
  if (!(measureText(value, style).width > maxWidth)) {
    return
  }

  if (position === "right") {
    this.attr("text-anchor", "end")
  }

  // 3 explained:
  //   - 0 based index for slice (1)
  //   - 2 for adding the '..' to the end
  let maxLength = value.length - 3
  while (measureText(shortValue, style).width > maxWidth) {
    shortValue = `${value.slice(0, maxLength)}..`
    maxLength -= 1
  }
  this.text(shortValue)
  if (callback) {
    callback.call(this, value)
  }
}

export default truncate
