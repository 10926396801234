import cn from "classnames"
import { TFunction } from "i18next"
import React from "react"
import { useTranslation } from "react-i18next"

import { Option } from "types/graphql.d"
import { useSelectList } from "v2/react/hooks/useSelectList"
import { DropdownMenu } from "v2/react/shared/collection/menus/DropdownMenu"

export type MenuOption<T> = Omit<Option, "id"> & { id: T; selected: boolean }

type CheckboxMenuProps<T> = {
  fieldLabel: string
  fieldId: string
  options: MenuOption<T>[]
  onSelect: (optionId: string) => void
}

export function CheckboxMenu<T extends string = string>({
  fieldLabel,
  fieldId,
  options,
  onSelect,
}: CheckboxMenuProps<T>) {
  const { t } = useTranslation()
  const [showList, setShowList] = React.useState(false)
  const { context, floatingStyles, getFloatingProps, refs } = useSelectList({
    showList,
    setShowList,
    floatOverrides: { placement: "bottom-start" },
    offset: 8,
  })

  const handleButtonKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === "Tab" && showList) {
      setShowList(false)
    }
  }

  return (
    <div className="input-group !mb-6" data-testid="checkbox-menu" id={`${fieldId}-checkbox-menu`}>
      <div className="mb-2 items-center justify-between flex">
        <span className="text-base-bold">{fieldLabel}</span>
      </div>
      <div className="dropdown--react">
        <div
          className={cn("select--responsive", { "bg-neutral-3 text-neutral-64": !options.length })}
        >
          <button
            type="button"
            onClick={() => setShowList((s) => !s)}
            ref={refs.setReference}
            onKeyDown={handleButtonKeyDown}
            className="dropdown-link hover:border--main-hover overflow-hidden text-ellipsis whitespace-nowrap"
            disabled={!options.length}
          >
            {getButtonText<T>(options, t)}
          </button>
        </div>
        <DropdownMenu
          context={context}
          showList={showList}
          floatingRef={refs.setFloating}
          floatingStyles={floatingStyles}
          floatingProps={getFloatingProps}
          wrapperClasses="dropdown-menu overflow-y-auto !block !w-inherit !max-w-[16rem] right-0 p-2 z-10 !m-0"
        >
          {options.map((option) => (
            <button
              className="dropdown-menu-link !m-0 cursor-pointer gap-1.5 p-[10px] pl-1 flex"
              key={option.id}
              onClick={() => onSelect(option.id)}
              type="button"
            >
              <input
                type="checkbox"
                id={`${fieldId}-${option.id}-checkbox`}
                checked={option.selected}
                className="!mr-0 mt-0.5 h-4 w-4"
                readOnly
              />
              <span className="side-label w-inherit hyphens-auto break-words break-all text-base">
                {option.label}
              </span>
            </button>
          ))}
        </DropdownMenu>
      </div>
    </div>
  )
}

const getButtonText = <T,>(options: MenuOption<T>[], t: TFunction) => {
  let isPlaceholder = false
  let text = ""
  if (!options.length) {
    isPlaceholder = true
    text = t("v2.defaults.no_options")
  }

  const selectedOptions = options.filter((option) => option.selected)
  if (selectedOptions.length === 0) {
    isPlaceholder = true
    text = t("v2.defaults.select_an_option")
  } else if (selectedOptions.length === 1) {
    isPlaceholder = false
    text = selectedOptions[0].label
  } else {
    isPlaceholder = false
    text = `${selectedOptions[0].label} +${selectedOptions.length - 1}`
  }

  return <span className={cn(isPlaceholder ? "text-neutral-64" : "text-neutral-100")}>{text}</span>
}
