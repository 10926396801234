import { t } from "i18next"
import React, { FormEvent } from "react"

import { PayGrade, PayGradeDestroyInput } from "types/graphql"
import { Modal, SaveFooter } from "v2/react/shared/overlay/Modal"
import { usePayGradeDestroyMutation } from "v2/redux/GraphqlApi/PayGradeApi"

interface Props {
  isOpen: boolean
  payGrade: PayGrade
  onClose: () => void
}

const DeleteModal = ({ payGrade, isOpen, onClose }: Props) => {
  const [mutate, { isLoading }] = usePayGradeDestroyMutation()

  const onSubmit = async (event: FormEvent<Element>) => {
    event.preventDefault()

    const input: PayGradeDestroyInput = {
      payGradeId: payGrade.id,
    } as PayGradeDestroyInput

    await mutate(input).unwrap()

    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t("v2.pay_grades.index.delete_pay_grade", {
        code: payGrade.code,
        name: payGrade.name ? ` - ${payGrade.name}` : "",
      })}
      size="md"
    >
      <form onSubmit={onSubmit}>
        <div className="react-modal__body flex-col gap-2 flex">
          {t("v2.pay_grades.index.confirm_delete")}
        </div>
        <SaveFooter
          isSaving={isLoading}
          onCancel={onClose}
          saveType="critical"
          enabledText={t("v2.defaults.delete")}
        />
      </form>
    </Modal>
  )
}

export { DeleteModal }
