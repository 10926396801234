import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import fp from "lodash/fp"
import { z } from "zod"

import { basePalettes } from "v2/react/components/successionSettings/palettes"

const GridItemSchema = z
  .object({
    id: z.number(),
    label: z.string().min(1),
    color: z.string().min(1),
  })
  .required()

const CardTypeSchema = z.union([
  z.literal("color_full"),
  z.literal("color_edge"),
  z.literal("color_none"),
])

const PaletteSchema = z.union([
  z.literal("palette_traditional"),
  z.literal("palette_blue"),
  z.literal("palette_red"),
  z.literal("palette_custom"),
])

const AxisModeSchema = z.union([z.literal("performance"), z.literal("potential")])

const FullMatrixSchema = z.object({
  cardType: CardTypeSchema,
  paletteType: PaletteSchema,
  axisMode: AxisModeSchema,
  gridItems: z.array(GridItemSchema),
})

type GridItem = z.infer<typeof GridItemSchema>
type CardType = z.infer<typeof CardTypeSchema>
type PaletteType = z.infer<typeof PaletteSchema>
type AxisModeType = z.infer<typeof AxisModeSchema>

interface PaletteOption {
  palette: string[]
  text: PaletteType
}

interface MatrixState {
  basePaletteOptions: PaletteType[]
  viewablePaletteOptions: PaletteType[]
  cardOptions: CardType[]
  gridItems: GridItem[]
  axisMode: AxisModeType
  cardType: CardType
  paletteType: PaletteType
  labelErrors: boolean
}

const checkForPaletteMatch = (items: GridItem[], palettes: PaletteType[]) => {
  let matched: PaletteType | undefined

  palettes.forEach((option) => {
    if (basePalettes[option].every((color, i) => items[i].color === color)) matched = option
  })

  return matched
}

const updateItemColorByPalette = (item: GridItem, index: number, palette: PaletteType) => ({
  ...item,
  color: basePalettes[palette][index],
})

const slices = (size: number, items: GridItem[]) => {
  const collection = []
  for (let i = 0; i < items.length; i += size) {
    collection.push(items.slice(i, i + size))
  }
  return collection
}

const gridItemsForAxisMode = (
  newMode: AxisModeType,
  currentMode: AxisModeType,
  items: GridItem[],
) => {
  if (newMode === currentMode) return items
  if (newMode === "performance") {
    return [...items].sort((a, b) => a.id - b.id)
  }
  const invertedIds = [9, 6, 3, 8, 5, 2, 7, 4, 1]
  return [...items].sort((a, b) => invertedIds.indexOf(a.id) - invertedIds.indexOf(b.id))
}

const initialState: MatrixState = {
  basePaletteOptions: ["palette_traditional", "palette_blue", "palette_red"],
  viewablePaletteOptions: ["palette_traditional", "palette_blue", "palette_red"],
  cardOptions: ["color_full", "color_edge", "color_none"],
  gridItems: [],
  axisMode: "performance",
  cardType: "color_full",
  paletteType: "palette_traditional",
  labelErrors: false,
}

const MatrixSlice = createSlice({
  name: "matrix",
  initialState,
  reducers: {
    setCardType: (state, { payload }: PayloadAction<CardType>) =>
      fp.set("cardType", payload)(state),
    setGridItems: (state, { payload }: PayloadAction<GridItem[]>) =>
      fp.set("gridItems", payload)(state),
    setLabelErrors: (state, { payload }: PayloadAction<boolean>) =>
      fp.set("labelErrors", payload)(state),
    setPalette: (state, { payload }: PayloadAction<PaletteType>) =>
      fp.set("paletteType", payload)(state),
    setAxisMode: (state, { payload }: PayloadAction<AxisModeType>) =>
      fp.pipe(
        fp.set("gridItems", gridItemsForAxisMode(payload, state.axisMode, state.gridItems)),
        fp.set("axisMode", payload),
      )(state),
    updateGridItemColor: (
      state,
      { payload: { gridItem, newColor } }: PayloadAction<{ gridItem: GridItem; newColor: string }>,
    ) => {
      const updatedItems = state.gridItems.map((item) =>
        item.id === gridItem.id ? { ...item, color: newColor } : item,
      )

      const matched = checkForPaletteMatch(updatedItems, state.basePaletteOptions)
      const updatedPalette = matched || "palette_custom"

      return fp.pipe(
        fp.set("gridItems", updatedItems),
        fp.set("paletteType", updatedPalette),
      )(state)
    },
    updateGridItemLabel: (
      state,
      { payload: { gridItem, newLabel } }: PayloadAction<{ gridItem: GridItem; newLabel: string }>,
    ) => {
      const updatedItems = state.gridItems.map((item) =>
        item.id === gridItem.id ? { ...item, label: newLabel } : item,
      )
      return fp.set("gridItems", updatedItems)(state)
    },
    updatePalette: (state, { payload }: PayloadAction<PaletteType>) => {
      const updatedItems =
        payload !== "palette_custom"
          ? state.gridItems.map((item, index) => updateItemColorByPalette(item, index, payload))
          : state.gridItems

      const updatedPalettes =
        payload !== "palette_custom"
          ? state.basePaletteOptions
          : [...state.basePaletteOptions, "palette_custom"]

      return fp.pipe(
        fp.set("gridItems", updatedItems),
        fp.set("viewablePaletteOptions", updatedPalettes),
        fp.set("paletteType", payload),
      )(state)
    },
  },
})

export const {
  setGridItems,
  setCardType,
  setLabelErrors,
  setPalette,
  setAxisMode,
  updatePalette,
  updateGridItemColor,
  updateGridItemLabel,
} = MatrixSlice.actions
export {
  MatrixSlice,
  MatrixState,
  GridItem,
  PaletteType,
  CardType,
  PaletteOption,
  AxisModeType,
  AxisModeSchema,
  FullMatrixSchema,
  GridItemSchema,
  CardTypeSchema,
  PaletteSchema,
}
