import classNames from "classnames"
import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from "react"

import { Person } from "types/graphql"
import { useAutoComplete } from "v2/react/hooks/useAutocomplete"
import { usePersonSearch } from "v2/react/hooks/usePersonSearch"
import { DropdownMenu } from "v2/react/shared/collection/menus/DropdownMenu"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"
import { getCookie } from "v2/react/utils/cookies"
import { StoreProvider } from "v2/redux/StoreProvider"

interface Props {
  placeholder?: string
  tooltipContent?: string
}

function NavSearchInner({ placeholder, tooltipContent }: Props) {
  const [isFocused, setIsFocused] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)
  const nav = document.querySelector(".nav")
  const navToggle = document.querySelector(".nav-toggle")

  const handleTooltip = () => {
    if (getCookie("nav_open_state") === "true" || !tooltipContent) return
    setShowTooltip(true)
  }

  const {
    showResultList,
    setShowResultList,
    inputValue,
    setInputValue,
    peopleResult,
    handleInputChange,
  } = usePersonSearch({
    omitValues: [],
    fieldValue: "",
  })

  const {
    activeIndex,
    setActiveIndex,
    listRef,
    refs,
    floatingStyles,
    context,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
  } = useAutoComplete({ showList: showResultList, setShowList: setShowResultList })

  useEffect(() => {
    if (isFocused) {
      refs.domReference.current?.focus()
    } else {
      refs.domReference.current?.blur()
    }
  }, [isFocused, refs])

  const handleChangeEvent = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    handleInputChange(event)

    if (value.length > 0) {
      setShowResultList(true)
      setActiveIndex(0)
    } else {
      setShowResultList(false)
    }
  }

  const expandInput = () => {
    const navShrunk = nav?.classList.contains("shrink-nav")
    if (navShrunk && navToggle) {
      setShowTooltip(false)
      navToggle.dispatchEvent(
        new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1,
        }),
      )
    }
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && activeIndex != null && peopleResult[activeIndex]) {
      handleResultClick(peopleResult[activeIndex])
    }
  }

  const handleResultClick = (person: Person) => {
    setInputValue(person.name)
    setActiveIndex(null)
    setShowResultList(false)
    setIsFocused(false)
    document.location.href = `/people/${person.id}`
  }

  return (
    <div>
      <Tooltip open={showTooltip} placement="right" gapSize={10}>
        <TooltipTrigger onMouseEnter={handleTooltip} onMouseLeave={() => setShowTooltip(false)}>
          <div
            role="button"
            tabIndex={0}
            className="search nav-search mt-4 w-auto"
            onClick={expandInput}
            onKeyDown={expandInput}
          >
            <div className="search-field relative">
              <div className="prefix">
                <i className="far fa-search" />
              </div>
              <input
                className="input prefix-pad"
                type="search"
                placeholder={placeholder}
                ref={refs.setReference}
                /* eslint-disable react/jsx-props-no-spreading */
                {...getReferenceProps({
                  onKeyDown: handleKeyDown,
                  onChange: handleChangeEvent,
                })}
              />
            </div>
          </div>
        </TooltipTrigger>
        <TooltipContent className="react-tooltip-content">
          <span className="break-all">{tooltipContent}</span>
        </TooltipContent>
      </Tooltip>
      <DropdownMenu
        showList={showResultList && !!inputValue.trim().length}
        floatingRef={refs.setFloating}
        floatingStyles={floatingStyles}
        floatingProps={getFloatingProps}
        wrapperClasses="autocomplete-container"
        context={context}
      >
        <div className="list-group autocomplete-list">
          {peopleResult.map((person, index) => (
            <div
              role="option"
              data-id={person.id}
              data-name={person.name}
              aria-selected={activeIndex === index}
              key={person.id}
              className={personClassName(activeIndex, index)}
              ref={(node) => {
                listRef.current[index] = node
              }}
              /* eslint-disable react/jsx-props-no-spreading */
              {...getItemProps({
                onClick: () => handleResultClick(person),
              })}
            >
              <div className="list-group-item-thumb circle circle-med shrink-0">
                <img alt={person.name || ""} src={person.avatarThumbUrl || ""} />
              </div>
              <div className="list-group-item-text">
                <div className="list-group-item-title">{person.name}</div>
                <div>{person.primaryPosition?.title || " "}</div>
              </div>
            </div>
          ))}
          {peopleResult?.length === 0 && inputValue.trim().length > 0 && (
            <div className="AutocompleteField__no-result">
              <p className="AutocompleteField__no-result-text">
                {"No Positions Found".t("org_chart")}
              </p>
            </div>
          )}
        </div>
      </DropdownMenu>
    </div>
  )
}

function NavSearch({ placeholder, tooltipContent }: Props) {
  return (
    <StoreProvider>
      <NavSearchInner placeholder={placeholder} tooltipContent={tooltipContent} />
    </StoreProvider>
  )
}

export { NavSearch }

const personClassName = (activeIndex: number | null, index: number) =>
  classNames("list-group-item", { highlight: activeIndex === index })
