import { ControlProps, JsonSchema7 } from "@jsonforms/core"
import dayjs from "dayjs"
import React, { FC } from "react"

import { sanitizeParse } from "v2/react/utils/sanitizeParse"

import { oneOfDataItem } from "./types"

interface formatterLookup {
  [key: string]: (data: string) => string
}

const JsonModuleListItem: FC<ControlProps> = ({ data, label, schema, uischema, visible }) => {
  const multiselectItems = schema.items as JsonSchema7
  const options: oneOfDataItem[] =
    schema.oneOf || (multiselectItems && multiselectItems.oneOf) || []

  const nestedMultiselectItems =
    schema.properties?.optionValues && (schema.properties?.optionValues.items as JsonSchema7)
  const nestedOptions: oneOfDataItem[] =
    (schema.properties?.optionValues && schema.properties?.optionValues.oneOf) ||
    (nestedMultiselectItems && nestedMultiselectItems.oneOf) ||
    []

  const formatter = uischema.options?.formatValue ? formatMap[uischema.options?.formatValue] : null
  const checkForFormat = (data: string) => (formatter ? formatter(data) : data)

  const simpleDisplay = (data: string) => {
    if (!data) return <span />

    // Json form data is sanitized on the backend saves for rich text,
    // but to account for any data we sanitize the html here as well.
    return <span>{sanitizeParse(checkForFormat(data))}</span>
  }

  const optionsDisplay = () => {
    if (!data) return <span />
    return options.map((option) => {
      if (data && data.includes(option.const)) {
        return <div key={option.const}>{option.title}</div>
      }
      return null
    })
  }

  const nestedOptionsDisplay = () => {
    if (!data) return <span />
    return nestedOptions.map((option) => {
      if (data && data.optionValues.includes(option.const)) {
        const otherDetails = option.const === "other" && data.otherDetails
        return (
          <div key={option.const}>
            {option.title}
            {otherDetails && `: ${otherDetails}`}
          </div>
        )
      }
      return null
    })
  }

  if (!visible) return null

  const determineDisplayType = () => {
    if (options.length) return optionsDisplay()
    if (nestedOptions.length) return nestedOptionsDisplay()
    // This may need some adjusting when we implement fileUpload types so that we can use the linkDisplay
    if (!data) return ""
    const value = schema.type === "object" ? data.name : data
    return simpleDisplay(value)
  }

  return (
    <div className="module-card-list-item">
      <label className="text-neutral-64">{label}</label>
      <div>{determineDisplayType()}</div>
    </div>
  )
}

export { JsonModuleListItem }

// data values that require additional formatting to display will need
// a `formatValue` property added to their `options` that maps to
// a key in the below object
const currencyFormat = (data: string): string => window.App.Helpers.formatCurrency(data)
const dateFormat = (data: string): string => dayjs(data).format("MM/DD/YYYY")

const formatMap: formatterLookup = {
  currency: currencyFormat,
  date: dateFormat,
}
