import React from "react"

import { ApproverType, NoteType } from "v2/react/shared/status/statusUtils"
import { createdAtDesc } from "v2/react/utils/sorts"

interface ApproverNoteProps {
  approver: ApproverType
  layoutClasses?: string
  notes: NoteType[] | ApproverType[]
}

function ApproverNote({ approver, layoutClasses, notes }: ApproverNoteProps) {
  let approverNote
  if (!notes.length) approverNote = null

  approverNote =
    notes.length && "body" in notes[0]
      ? mostRecentNote(notes as NoteType[], approver)
      : mostRecentApproverNote(notes as ApproverType[], approver)
  if (!approverNote) return <span />

  return (
    <div className={layoutClasses}>
      <div className="whitespace-normal rounded-lg rounded-tl-none bg-primary-3 p-2 text-sm italic text-neutral-64">
        &ldquo;{approverNote}&rdquo;
      </div>
    </div>
  )
}

export { ApproverNote }

const mostRecentNote = (notes?: NoteType[] | null, approver?: ApproverType | null) => {
  if (!notes || !approver) return null
  const mostRecentNote = (notes || [])
    .slice()
    .sort(createdAtDesc)
    .filter((n) => n && n.person.id === approver.person.id && n.status === approver.status)

  return mostRecentNote && mostRecentNote.length > 0 ? mostRecentNote[0].body : null
}

const mostRecentApproverNote = (notes: ApproverType[], approver?: ApproverType | null) => {
  if (!notes || !approver) return null
  const note = notes.filter((n) => n.person.id === approver?.person.id)

  return note && note.length > 0 ? note[0].note : null
}
