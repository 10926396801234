import {
  CustomFieldValueEntry,
  CustomFieldValueFieldName,
  OrgUnitEntry,
  OrgUnitFieldName,
  SimpleEntry,
  VariablePayEntry,
  VariablePayFieldName,
  WatchEntry,
  WatchFieldNameOfSimpleField,
} from "v2/react/components/positions/positionFieldValuesDiff/types"

export const isCustomFieldValueFieldName = (key: unknown): key is CustomFieldValueFieldName =>
  typeof key === "string" && /custom_field_[0-9]+/.test(key)
export const isOrgUnitFieldName = (key: unknown): key is OrgUnitFieldName =>
  typeof key === "string" && /org_unit_type_[0-9]+/.test(key)
export const isVariablePayFieldName = (key: unknown): key is VariablePayFieldName =>
  typeof key === "string" && /variable_pay_type_[0-9]+/.test(key)

export const isOrgUnitEntry = (entry: WatchEntry): entry is OrgUnitEntry =>
  isOrgUnitFieldName(entry.fieldName)
export const isCustomFieldEntry = (entry: WatchEntry): entry is CustomFieldValueEntry =>
  isCustomFieldValueFieldName(entry.fieldName)
export const isVariablePayEntry = (entry: WatchEntry): entry is VariablePayEntry =>
  isVariablePayFieldName(entry.fieldName)
export const isSimpleEntry = (entry: WatchEntry): entry is SimpleEntry =>
  !isOrgUnitEntry(entry) && !isCustomFieldEntry(entry) && !isVariablePayEntry(entry)
export const isSimpleEntryFor = <FieldName extends WatchFieldNameOfSimpleField>(
  entry: WatchEntry,
  fieldName: FieldName,
): entry is SimpleEntry<FieldName> => isSimpleEntry(entry) && entry.fieldName === fieldName
