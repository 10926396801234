import React from "react"
import { Provider } from "react-redux"

import { appContainer } from "v2/appContainer"

import { AppStore } from "./store"

const StoreProvider = ({
  children,
  store: callerStore,
}: React.PropsWithChildren<{ store?: AppStore }>) => (
  <Provider store={callerStore ?? appContainer.reduxStore}>{children}</Provider>
)

export { StoreProvider }
