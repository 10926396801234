import React from "react"
import { useTranslation } from "react-i18next"

import { FeatureFlags, ProfilePanelPermissions } from "types/graphql"
import { ProfilePanelMode } from "types/graphql.enums"
import { ModuleListItem } from "v2/react/components/orgChart/ProfilePanel/ModuleListItem"
import { useSelectedBox } from "v2/react/components/orgChart/ProfilePanel/Settings/hooks/useSelectedBox"
import { AccordionModulePanel } from "v2/react/shared/layout/AccordionModulePanel"
import { sanitizeParse } from "v2/react/utils/sanitizeParse"
import { useAppSelector } from "v2/redux/store"

interface JobDescriptionProps {
  abilities: ProfilePanelPermissions
  featureFlags: FeatureFlags
}

function JobDescription({ abilities, featureFlags }: JobDescriptionProps) {
  const { currentBox, toggleBox } = useSelectedBox("job_description", ProfilePanelMode.Position)
  const position = useAppSelector((state) => state.profilePanel.position)
  const { t } = useTranslation()

  if (!currentBox) return null
  if (!featureFlags.positionManagement) return null

  const headerContent = <div className="title">{t("v2.profile_panel.job_description")}</div>

  return abilities.canReadRestrictedPositionFields && position?.description ? (
    <AccordionModulePanel
      headerContent={headerContent}
      additionalToggleAction={toggleBox}
      collapsed={currentBox.collapsed}
      title={currentBox.name}
    >
      <ModuleListItem>
        <div>{sanitizeParse(position.description)}</div>
      </ModuleListItem>
    </AccordionModulePanel>
  ) : null
}

export { JobDescription }
