import React from "react"

import { selectCursor } from "v2/redux/slices/DatasheetSlice/cursor/cursorSelectors"
import { onNothing } from "v2/redux/slices/DatasheetSlice/cursor/cursorStates"
import { useAppSelector } from "v2/redux/store"

import { Cell } from "./useCellState"

type CellRef = React.RefObject<HTMLDivElement>

export function useCursorSync<TValue>({
  cell,
  cellRef,
  value,
}: {
  cell: Cell
  cellRef: CellRef
  value: TValue
}) {
  const cursor = useAppSelector(selectCursor)

  React.useEffect(() => {
    if (onNothing(cursor) || cursor.rowId !== cell.rowId || cursor.columnId !== cell.columnId) {
      if (cell.isEditing || cell.isErroredEditing) {
        cell.dispatch({ type: "save", value })
      }
    }
  }, [cursor, cell, value, cellRef])
}
