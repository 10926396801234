import { skipToken } from "@reduxjs/toolkit/dist/query"
import dayjs from "dayjs"
import { motion } from "framer-motion"
import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"

import { SuccessionPlanPanelPermissions } from "types/graphql"
import { MatrixTab } from "v2/react/components/succession/SuccessionPlanPanel/Matrix/MatrixTab"
import { PanelHeader } from "v2/react/components/succession/SuccessionPlanPanel/PanelHeader"
import { SummaryTab } from "v2/react/components/succession/SuccessionPlanPanel/Summary/SummaryTab"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import { opaqueBasic } from "v2/react/utils/framerAnimationVariants"
import { pjaxReload } from "v2/react/utils/pjax"
import { UrlHelper } from "v2/react/utils/urls"
import {
  useGetPanelSuccessionPlanQuery,
  useGetSuccessionPlanPanelPermissionsQuery,
} from "v2/redux/GraphqlApi/SuccessionApi"
import { useAppSelector } from "v2/redux/store"

interface Props {
  hidePanel: () => void
  positionId: string | null
}

function SuccessionPlanPanel({ positionId, hidePanel }: Props) {
  const { t } = useTranslation()
  const activeTab = useAppSelector(
    (state) => state.successionPlanPanel.activeSuccessionPlanPanelTab,
  )
  // This forces the panel to refetch data when opened to handle cases where the succession plan
  // or related data has been altered in other parts of the app - ie altering filled by info in the position modal
  const { data } = useGetPanelSuccessionPlanQuery(positionId ? { positionId } : skipToken, {
    refetchOnMountOrArgChange: true,
  })
  const { data: permissions } = useGetSuccessionPlanPanelPermissionsQuery(
    positionId ? { positionId } : skipToken,
  )
  const position = data?.position
  const panelPermissions: SuccessionPlanPanelPermissions | undefined =
    permissions?.successionPlanPanelPermissions
  const successionPlan = data?.position?.successionPlan
  const pjaxContainerRef = useRef<HTMLDivElement>(null)
  const showTimestamp =
    successionPlan && successionPlan.lastUpdatedAt && successionPlan.lastUpdatedBy

  useEffect(() => {
    if (!positionId) return

    const scrollContainer = document.querySelector(".panel-scroll-container")
    const selector = "#succession-plan-panel-pjax-container"
    // NOTE: The current expected behavior is that when a pjax modal is loaded
    // on top of another existing pjax modal, the lower modal will be replaced.
    // This behavior happens on the Succession Plan Insights page, where the
    // compare settings close the insight details modal. Note that the react
    // matrix setting persist on top of the lower modal (a nicer UX for the
    // multiple modals case).

    pjaxReload({
      url: UrlHelper.successionPlanPanelPath(positionId),
      container: selector,
    })

    // scroll to top of panel when positionId changes
    scrollContainer?.scrollTo({ top: 0, behavior: "smooth" })
  }, [positionId])

  useEffect(() => {
    // Updates the link in the node menu when candidates are added/removed
    // Only applicable to orgchart view
    if (
      data?.position &&
      data.position.successionPlan &&
      window.location.href.includes("orgchart")
    ) {
      window.$.get({
        url: window.App.endpoint(["positions", "organize", window.gon.selected_chart.id]),
        data: { ids: [Number(data.position.id)] },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }).done((data: any) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data.forEach((positionData: any) => {
          const p = window.App.OrgChart.findPosition(positionData.id)
          p.set(positionData)
          window.App.OrgChart.syncPosition(p)
        })
      })
    }
  }, [data?.position])

  return (
    <motion.div
      data-position-id={positionId}
      variants={opaqueBasic}
      initial={opaqueBasic.hidden}
      animate={opaqueBasic.shown}
      exit={opaqueBasic.hidden}
      className="sp-panel"
    >
      {position && panelPermissions && (
        <PanelHeader
          canManageSuccessionSettings={panelPermissions.canManageSuccessionSettings}
          hidePanel={hidePanel}
          pjaxContainerRef={pjaxContainerRef}
          position={position}
        />
      )}
      <div className="flex-col gap-6 p-6 flex">
        {(!position || !panelPermissions) && <Spinner />}
        {position && panelPermissions && (
          <>
            {activeTab === "summary" && (
              <SummaryTab
                position={position}
                canEditPosition={panelPermissions.canEditPosition}
                canEditSuccessionPlan={panelPermissions.canEditSuccessionPlan}
              />
            )}
            {activeTab === "matrix" && (
              <MatrixTab candidates={successionPlan?.candidates || []} position={position} />
            )}
          </>
        )}
        <div>
          <div id="succession-plan-panel-pjax-container" ref={pjaxContainerRef} />
          {showTimestamp && (
            <p className="succession-plan__time-stamp h-fit flex-grow-0 text-neutral-64">
              {t("v2.succession_plan_panel.last_updated", {
                person: successionPlan.lastUpdatedBy,
                date: dayjs(successionPlan.lastUpdatedAt).format("MMMM D, YYYY"),
              })}
            </p>
          )}
        </div>
      </div>
    </motion.div>
  )
}

export { SuccessionPlanPanel }
