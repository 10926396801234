import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { LayoutGroup, motion } from "framer-motion"
import React from "react"

import { generateConditionalColors } from "v2/react/components/succession/SuccessionPlanPanel/utils/successionUtils"
import { colorWheel } from "v2/react/components/successionSettings/palettes"
import { preventPropagationOfSyntheticEvent } from "v2/react/hooks/useOutsideClick"
import { ColorPicker } from "v2/react/shared/forms/ColorPicker"
import { CardType, GridItem, setLabelErrors } from "v2/redux/slices/MatrixSlice"
import { useAppDispatch } from "v2/redux/store"

interface Props {
  item: GridItem
  cardStyle: CardType
  onUpdateItemLabel: (item: GridItem, newLabel: string) => void
  onUpdateItemColor: (item: GridItem, newColor: string) => void
}

function GridItemEditable({ item, cardStyle, onUpdateItemLabel, onUpdateItemColor }: Props) {
  const dispatch = useAppDispatch()

  const afterSubmit = (color: string) => {
    onUpdateItemColor(item, color)
  }

  const handleLabelChange = (e: React.FormEvent<HTMLInputElement>) => {
    checkForError(e.currentTarget.value.trim())
    onUpdateItemLabel(item, e.currentTarget.value.trim())
  }

  const checkForError = (value: string) => dispatch(setLabelErrors(value.length === 0))

  return (
    <motion.div
      className="matrix__grid-item border--main min-h-[11rem] gap-2 rounded-lg p-2 flex"
      initial={false}
      animate={generateConditionalColors(cardStyle, item.color, "lg")}
    >
      <LayoutGroup>
        <motion.div
          layout="size"
          transition={{
            type: "spring",
            stiffness: 800,
            damping: 60,
            duration: 0.2,
            delayChildren: 0.5,
          }}
          className="flex-1"
        >
          <input
            defaultValue={item.label || ""}
            name={`item-${item.id}`}
            onChange={handleLabelChange}
            className="input matrix__grid-item-title px-3 py-1"
          />
        </motion.div>
        {cardStyle !== "color_none" && (
          <motion.div
            layout="size"
            transition={{
              type: "spring",
              stiffness: 800,
              damping: 60,
              duration: 0.2,
            }}
            className="relative cursor-pointer"
            onClick={preventPropagationOfSyntheticEvent}
          >
            <ColorPicker
              colors={colorWheel}
              currentColor={item.color}
              dropdownPlacement="bottom-end"
              includeNoColorOption
              noColorHex="#f8f8fa"
              onSubmit={afterSubmit}
              popoverTriggerClassNames="bg-transparent p-0"
            >
              <div className="customize__color-header hover:border--main-hover border--main relative h-10 items-center justify-between gap-2 rounded-lg bg-white px-2.5 py-3 flex">
                <div className="h-4 w-4 rounded-sm" style={{ backgroundColor: item.color }} />
                <FontAwesomeIcon icon={["far", "chevron-down"]} />
              </div>
            </ColorPicker>
          </motion.div>
        )}
      </LayoutGroup>
    </motion.div>
  )
}

export { GridItemEditable }
