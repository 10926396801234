import React, { FormEvent, useState } from "react"
import { useTranslation } from "react-i18next"

import { Error, PayGrade, PayGradeCreateInput } from "types/graphql"
import { PayGradeForm } from "v2/react/components/payGrades/Index/PayGradeForm"
import { Modal } from "v2/react/shared/overlay/Modal"
import { ErrorNotice } from "v2/react/shared/status/ErrorNotice"
import { usePayGradeCreateMutation } from "v2/redux/GraphqlApi/PayGradeApi"

interface Props {
  isOpen: boolean
  payGrade?: PayGrade
  onClose: () => void
}

function CreateModal({ isOpen, payGrade, onClose }: Props) {
  const { t } = useTranslation()
  const [errors, setErrors] = useState<Error[]>([])
  const [mutate, { isLoading }] = usePayGradeCreateMutation()

  const handleClose = () => {
    setErrors([])
    onClose()
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()

    const form = event.target as HTMLFormElement
    const formData = new FormData(form)
    const input: PayGradeCreateInput = {
      code: formData.get("code"),
      currencyCode: formData.get("currency_code"),
      minimum: Number(formData.get("min")),
      maximum: Number(formData.get("max")),
      name: formData.get("name"),
      periodType: formData.get("period_type"),
    } as PayGradeCreateInput

    mutate(input)
      .unwrap()
      .then((result) => {
        if (result.payGradeCreate?.payGrade?.id) {
          handleClose()
        } else if (result.payGradeCreate?.errors) {
          setErrors(result.payGradeCreate?.errors)
        }
      })
      .catch(() => {
        setErrors([{ message: t("v2.defaults.error") }])
      })
  }

  const anyUnhandledErrors = errors.some((error) => !error.path || error.path.length === 0)

  return (
    <Modal isOpen={isOpen} onClose={handleClose} title={t("v2.pay_grades.index.add_pay_grade")}>
      {anyUnhandledErrors && <ErrorNotice wrapperClassName="pt-6 px-6" />}
      <PayGradeForm
        errors={errors}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        payGrade={payGrade}
        setErrors={setErrors}
      />
    </Modal>
  )
}

export { CreateModal }
