import { motion } from "framer-motion"
import React from "react"
import { useTranslation } from "react-i18next"

import { opaqueScale } from "v2/react/utils/framerAnimationVariants"

interface ClearAllButtonProps {
  onClick: () => void
}

const ClearAllButton = ({ onClick }: ClearAllButtonProps) => {
  const { t } = useTranslation()

  return (
    <motion.button
      layout
      type="button"
      id="clear-all-filters"
      className="removable hover bg-inherit text-sm text-primary-80 hover:underline"
      onClick={onClick}
      initial="hidden"
      animate="shown"
      variants={opaqueScale}
      exit="hidden"
    >
      {t("v2.defaults.clear_all")}
    </motion.button>
  )
}

export { ClearAllButton }
