import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

interface SaveFooterProps {
  disabledText?: string
  enabledText?: string
  isSaving: boolean
  onCancel?: () => void
  onSave?: (e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => void
  saveType?: "critical" | "primary"
}

const SaveFooter = ({
  disabledText,
  enabledText,
  isSaving,
  onCancel,
  onSave,
  saveType = "primary",
}: SaveFooterProps) => {
  const { t } = useTranslation()
  const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === "Enter" && onSave) {
      onSave(e)
    }
  }

  return (
    <div className="react-modal__footer gap-2">
      {onCancel && (
        <button
          type="button"
          className="btn--large btn--secondary"
          onClick={onCancel}
          disabled={isSaving}
        >
          {"Cancel".t("defaults")}
        </button>
      )}

      <button
        className={cn(`btn--large btn--${saveType}`, { "btn-disabled": isSaving })}
        disabled={isSaving}
        onClick={onSave}
        onKeyDown={handleKeyDown}
        type="submit"
      >
        {isSaving ? disabledText ?? t("v2.defaults.saving") : enabledText ?? t("v2.defaults.save")}
      </button>
    </div>
  )
}

export { SaveFooter, SaveFooterProps }
