import {
  CreateSuccessionPlanCandidateInput,
  CreateSuccessionPlanCandidateMutation,
  CreateSuccessionPlanCommentInput,
  CreateSuccessionPlanCommentMutation,
  CurrentCompanySuccessionAttributeLevelsSettingsQuery,
  CurrentCompanySuccessionAttributeLevelsSettingsQueryVariables,
  DestroySuccessionPlanCandidateInput,
  DestroySuccessionPlanCandidateMutation,
  DestroySuccessionPlanCommentInput,
  DestroySuccessionPlanCommentMutation,
  SuccessionPanelSettingsQuery,
  UpdatePersonSuccessionPanelSettingsInput,
  UpdatePersonSuccessionPanelSettingsMutation,
  UpdateSuccessionPlanCandidateInput,
  UpdateSuccessionPlanCandidateMutation,
  UpdateSuccessionPlanCandidateOrderInput,
  UpdateSuccessionPlanCandidateOrderMutation,
  UpdateSuccessionPlanSummaryInput,
  UpdateSuccessionPlanSummaryMutation,
  type GetPanelSuccessionPlanQuery,
  type GetPanelSuccessionPlanQueryVariables,
  type SuccessionPlanPanelPermissionsQuery,
  type SuccessionPlanPanelPermissionsQueryVariables,
  type SuccessionTableSettingsQuery,
  type SuccessionTableSettingsQueryVariables,
  type UpdatePersonSuccessionTableSettingsInput,
  type UpdatePersonSuccessionTableSettingsMutation,
} from "types/graphql"
import OperationStore from "v2/operation_store"
import { GraphqlApi } from "v2/redux/GraphqlApi"
import { queryOperation } from "v2/redux/utils/endpoints"

export const SuccessionApi = GraphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    createSuccessionPlanCandidate: builder.mutation<
      CreateSuccessionPlanCandidateMutation,
      CreateSuccessionPlanCandidateInput
    >({
      query: (input) => ({
        operationId: OperationStore.getOperationId("CreateSuccessionPlanCandidate"),
        variables: { input },
      }),
      invalidatesTags: ["SuccessionPlan"],
    }),
    createSuccessionPlanComment: builder.mutation<
      CreateSuccessionPlanCommentMutation,
      CreateSuccessionPlanCommentInput
    >({
      query: (input) => ({
        operationId: OperationStore.getOperationId("CreateSuccessionPlanComment"),
        variables: { input },
      }),
      invalidatesTags: ["SuccessionPlan"],
    }),
    deleteSuccessionPlanCandidate: builder.mutation<
      DestroySuccessionPlanCandidateMutation,
      DestroySuccessionPlanCandidateInput
    >({
      query: (input) => ({
        operationId: OperationStore.getOperationId("DestroySuccessionPlanCandidate"),
        variables: { input },
      }),
      invalidatesTags: ["SuccessionPlan"],
    }),
    deleteSuccessionPlanComment: builder.mutation<
      DestroySuccessionPlanCommentMutation,
      DestroySuccessionPlanCommentInput
    >({
      query: (input) => ({
        operationId: OperationStore.getOperationId("DestroySuccessionPlanComment"),
        variables: { input },
      }),
      invalidatesTags: ["SuccessionPlan"],
    }),
    getPanelSuccessionPlan: builder.query<
      GetPanelSuccessionPlanQuery,
      GetPanelSuccessionPlanQueryVariables
    >({
      query: queryOperation<GetPanelSuccessionPlanQueryVariables>("GetPanelSuccessionPlan"),
      providesTags: ["SuccessionPlan"],
    }),
    getSuccessionPanelSettings: builder.query<SuccessionPanelSettingsQuery, {}>({
      query: queryOperation<{}>("SuccessionPanelSettings"),
      providesTags: ["SuccessionPanelSettings"],
    }),
    getSuccessionPlanAttributeLevels: builder.query<
      CurrentCompanySuccessionAttributeLevelsSettingsQuery,
      CurrentCompanySuccessionAttributeLevelsSettingsQueryVariables
    >({
      query: queryOperation<CurrentCompanySuccessionAttributeLevelsSettingsQueryVariables>(
        "CurrentCompanySuccessionAttributeLevelsSettings",
      ),
    }),
    getSuccessionPlanPanelPermissions: builder.query<
      SuccessionPlanPanelPermissionsQuery,
      SuccessionPlanPanelPermissionsQueryVariables
    >({
      query: queryOperation<SuccessionPlanPanelPermissionsQueryVariables>(
        "SuccessionPlanPanelPermissions",
      ),
    }),
    getSuccessionTableSettings: builder.query<
      SuccessionTableSettingsQuery,
      SuccessionTableSettingsQueryVariables
    >({
      query: queryOperation<SuccessionTableSettingsQueryVariables>("SuccessionTableSettings"),
      providesTags: ["SuccessionTableSettings"],
    }),
    updatePersonSuccessionPanelSettings: builder.mutation<
      UpdatePersonSuccessionPanelSettingsMutation,
      UpdatePersonSuccessionPanelSettingsInput
    >({
      query: (input) => ({
        operationId: OperationStore.getOperationId("UpdatePersonSuccessionPanelSettings"),
        variables: { input },
      }),
      invalidatesTags: ["SuccessionPanelSettings"],
    }),
    updatePersonSuccessionTableSettings: builder.mutation<
      UpdatePersonSuccessionTableSettingsMutation,
      UpdatePersonSuccessionTableSettingsInput
    >({
      query: (input) => ({
        operationId: OperationStore.getOperationId("UpdatePersonSuccessionTableSettings"),
        variables: { input },
      }),
      invalidatesTags: ["SuccessionTableSettings"],
    }),
    updateSuccessionPlanCandidate: builder.mutation<
      UpdateSuccessionPlanCandidateMutation,
      UpdateSuccessionPlanCandidateInput
    >({
      query: (input) => ({
        operationId: OperationStore.getOperationId("UpdateSuccessionPlanCandidate"),
        variables: { input },
      }),
      invalidatesTags: ["SuccessionPlan"],
    }),
    updateSuccessionPlanSummary: builder.mutation<
      UpdateSuccessionPlanSummaryMutation,
      UpdateSuccessionPlanSummaryInput
    >({
      query: (input) => ({
        operationId: OperationStore.getOperationId("UpdateSuccessionPlanSummary"),
        variables: { input },
      }),
      invalidatesTags: ["SuccessionPlan"],
    }),
    updateSuccessionPlanCandidateOrder: builder.mutation<
      UpdateSuccessionPlanCandidateOrderMutation,
      UpdateSuccessionPlanCandidateOrderInput
    >({
      query: (input) => ({
        operationId: OperationStore.getOperationId("UpdateSuccessionPlanCandidateOrder"),
        variables: { input },
      }),
      invalidatesTags: ["SuccessionPlan"],
    }),
  }),
})

export const {
  useCreateSuccessionPlanCandidateMutation,
  useCreateSuccessionPlanCommentMutation,
  useDeleteSuccessionPlanCandidateMutation,
  useDeleteSuccessionPlanCommentMutation,
  useGetPanelSuccessionPlanQuery,
  useGetSuccessionPanelSettingsQuery,
  useGetSuccessionPlanAttributeLevelsQuery,
  useGetSuccessionPlanPanelPermissionsQuery,
  useGetSuccessionTableSettingsQuery,
  useUpdatePersonSuccessionPanelSettingsMutation,
  useUpdatePersonSuccessionTableSettingsMutation,
  useUpdateSuccessionPlanSummaryMutation,
  useUpdateSuccessionPlanCandidateMutation,
  useUpdateSuccessionPlanCandidateOrderMutation,
} = SuccessionApi
