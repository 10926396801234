/* eslint-disable react/jsx-props-no-spreading, jsx-a11y/no-noninteractive-tabindex, jsx-a11y/anchor-is-valid */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"
import { useBoolean } from "usehooks-ts"

import {
  useFieldLabelHelpers,
  usePositionFieldArray,
} from "v2/react/components/positions/positionForm/hooks"
import { NewPersonRow } from "v2/react/components/positions/positionForm/positionDetails/NewPersonRow"
import { PersonRow } from "v2/react/components/positions/positionForm/positionDetails/PersonRow"

/** Based on app/views/v2/shared/inputs/_filled_by_input.html.slim */
const FilledByInput = () => {
  const { t } = useTranslation()
  const { tLabel } = useFieldLabelHelpers()
  const {
    setFalse: hideNewRowInput,
    setTrue: showNewRowInput,
    value: isNewRowInputShown,
  } = useBoolean()

  const anyFilledByErrors = false
  const {
    append: appendPerson,
    fields: people,
    remove: removePerson,
  } = usePositionFieldArray({
    name: "position.people",
    keyName: "formId",
  })
  const canShowAddPersonButton = !isNewRowInputShown && people.length > 0

  return (
    <div className={cn("input-group", anyFilledByErrors && "form-error")}>
      <fieldset className="m-0">
        <legend id="filled-by-label" className="mb-1 cursor-default font-bold">
          {tLabel("position.people")}
        </legend>

        <div id="filled-by-container">
          <div id="filled-by-list" className="flex-col gap-4 flex">
            {people.length === 0 && (
              <NewPersonRow canRemove={false} onSelectPerson={appendPerson} />
            )}
            {people.length > 0 &&
              people.map(({ formId }, index) => (
                <PersonRow index={index} key={formId} onRemovePress={() => removePerson(index)} />
              ))}
            {/* The subsequent entry supports adding >1 people to the position,
                while the `NewPersonRow` a few lines above is for adding the
                initial person. Different constraints, linear is simple. */}
            {isNewRowInputShown && (
              <NewPersonRow
                canRemove
                onRemoveNewRow={hideNewRowInput}
                onSelectPerson={(person) => {
                  appendPerson(person)
                  hideNewRowInput()
                }}
              />
            )}
          </div>

          {canShowAddPersonButton && (
            <button
              className="btn--ghost label-link mt-2 select-none text-neutral-80 hover:no-underline"
              onClick={showNewRowInput}
              type="button"
            >
              <FontAwesomeIcon icon={["far", "plus"]} />
              <span className="text-sm font-bold">{t("v2.positions.form.add_person")}</span>
            </button>
          )}
        </div>
      </fieldset>
    </div>
  )
}

export { FilledByInput }
