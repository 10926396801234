import classNames from "classnames"
import React, { ReactNode } from "react"
import { defaultMemoize } from "reselect"

import { Maybe, NodeInterface } from "types/graphql"
import { Column, NodeRow } from "v2/react/components/orgChart/Datasheet/types"
import { BlueIcon } from "v2/react/shared/icons/BlueIcon"
import { formattedNodeProp } from "v2/redux/slices/NodeSlice/nodeHelpers/nodeProps"

interface DisplayValueCellProps<TNode> {
  column: Column<TNode>
  className?: string
  isFirst: boolean
  isLast: boolean
  mergeAvatarAndName: boolean
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  row: NodeRow
  style: React.CSSProperties
  value: string
}

function DisplayValueCell<TNode>({
  column,
  className,
  isFirst,
  isLast,
  mergeAvatarAndName,
  onMouseEnter,
  onMouseLeave,
  row,
  style,
  value,
}: DisplayValueCellProps<TNode>) {
  const avatar = checkForAvatar(row, column)

  return (
    <div
      style={cellStyle(style, isFirst, row.color)}
      className={classNames(className, nodeClassName(isLast), {
        "nested-avatar items-center gap-3 flex": avatar,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {mergeAvatarAndName && avatar}
      {value}
    </div>
  )
}

const nodeClassName = (isLast: boolean) => classNames("GridBody-cell", { last: isLast })

const cellStyle = defaultMemoize((style, isFirst, color?: Maybe<string>) => {
  if (color && isFirst) return { ...(style || {}), borderLeft: `5px solid ${color}` }
  if (isFirst) return { ...(style || {}), borderLeft: "none" }

  return style || {}
})

export { DisplayValueCell, DisplayValueCellProps }

function checkForAvatar<TNode>(row: NodeRow, column: Column<TNode>): ReactNode {
  const data = row.data as NodeInterface

  if (column.fieldKey !== "name" || !("avatar" in data)) return null
  const imageSrc = formattedNodeProp(
    column.fieldKey as keyof NodeInterface,
    row.data as NodeInterface,
  )

  return imageSrc ? (
    <img
      role="presentation"
      className="circle circle-sm m-0"
      alt="user avatar"
      src={formattedNodeProp("avatar", row.data as NodeInterface)}
    />
  ) : (
    <BlueIcon icon={["far", "chair-office"]} size="sm" />
  )
}
