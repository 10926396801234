import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import fp from "lodash/fp"
import { createStructuredSelector } from "reselect"

import { GetPersonQuery, GetPositionQuery, Person, Position } from "types/graphql"
import { ProfilePanelMode } from "types/graphql.enums"
import { RootState } from "v2/redux/store"

const selectProfileSettings = createStructuredSelector({
  profilePanelMode: (state: RootState) => state.profilePanel.profilePanelMode,
  isOfficialChart: (state: RootState) => state.profilePanel.isOfficialChart,
  isOpen: (state: RootState) => state.profilePanel.isOpen,
  person: (state: RootState) => state.profilePanel.person,
  personId: (state: RootState) => state.profilePanel.personId,
  position: (state: RootState) => state.profilePanel.position,
  positionId: (state: RootState) => state.profilePanel.positionId,
})

type ResolvedPerson = Person | GetPersonQuery["person"]
type ResolvedPosition = Position | GetPositionQuery["position"]

interface ProfilePanelState {
  profilePanelMode: ProfilePanelMode | null
  isOfficialChart: boolean
  isOpen: boolean
  person: Person | null
  personId: string | null
  position: Position | null
  positionId: string | null
}

const InitialState: ProfilePanelState = {
  profilePanelMode: null,
  isOfficialChart: true,
  isOpen: false,
  person: null,
  personId: null,
  position: null,
  positionId: null,
}

const ProfilePanelSlice = createSlice({
  name: "profilePanel",
  initialState: InitialState,
  reducers: {
    closeProfilePlanSidebar: (state) => {
      return fp.pipe(
        fp.set("isOpen", false),
        fp.set("positionId", null),
        fp.set("position", null),
      )(state)
    },
    openProfilePanelSidebar: (
      state,
      { payload }: PayloadAction<{ personId: string | null; positionId: string | null }>,
    ) => {
      return fp.pipe(
        fp.set("isOpen", true),
        fp.set("positionId", payload.positionId),
        fp.set("personId", payload.personId),
      )(state)
    },
    setProfilePanelMode: (state, { payload }: PayloadAction<ProfilePanelMode>) =>
      fp.set("profilePanelMode", payload)(state),
    setIsOfficialChart: (state, { payload }: PayloadAction<boolean>) =>
      fp.set("isOfficialChart", payload)(state),
    setProfilePerson: (state, { payload }: PayloadAction<ResolvedPerson | null>) =>
      fp.set("person", payload)(state),
    setPersonId: (state, { payload }: PayloadAction<string | null>) =>
      fp.set("personId", payload)(state),
    setProfilePosition: (state, { payload: options }: PayloadAction<ResolvedPosition | null>) =>
      fp.set("position", options)(state),
    setPositionId: (state, { payload }: PayloadAction<string | null>) =>
      fp.set("positionId", payload)(state),
    updatePersonAvatar: (state, { payload }: PayloadAction<string>) =>
      fp.set("person.avatarThumbUrl", payload)(state),
    updatePersonAttribute: (
      state,
      { payload }: PayloadAction<{ key: keyof Person; value: string }>,
    ) => fp.set(`person.${payload.key}`, payload.value)(state),
  },
})

export { ProfilePanelSlice, ProfilePanelState, selectProfileSettings }
export const {
  closeProfilePlanSidebar,
  openProfilePanelSidebar,
  setProfilePanelMode,
  setIsOfficialChart,
  setProfilePerson,
  setPersonId,
  setProfilePosition,
  setPositionId,
  updatePersonAttribute,
  updatePersonAvatar,
} = ProfilePanelSlice.actions
