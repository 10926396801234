import type { ListenerEffectAPI } from "@reduxjs/toolkit"

import { selectCursor, selectCursorIndices } from "v2/redux/slices/GridSlice/cursor/cursorSelectors"
import { onDifferent } from "v2/redux/slices/GridSlice/cursor/cursorStates"
import type { CursorEventDetail } from "v2/redux/slices/GridSlice/cursor/types"
import type { AppDispatch, RootState } from "v2/redux/store"

type DispatchArg = { api: ListenerEffectAPI<RootState, AppDispatch> }

export const cursorEventTarget = new EventTarget()

export function dispatchCellCursorTargetChangeAfterFollowUp({ api: { getState } }: DispatchArg) {
  const cursor = selectCursor(getState())
  const cursorIndices = selectCursorIndices(getState())

  const detail: CursorEventDetail = {
    originalCursor: cursor,
    originalCursorIndices: cursorIndices,
    cursor,
    cursorIndices,
  }

  cursorEventTarget.dispatchEvent(makeEvent("cellCursorTargetChange", detail))
}

export function dispatchCursorEvents({ api: { getState, getOriginalState } }: DispatchArg) {
  const originalCursor = selectCursor(getOriginalState())
  const originalCursorIndices = selectCursorIndices(getOriginalState())
  const cursor = selectCursor(getState())
  const cursorIndices = selectCursorIndices(getState())

  const detail: CursorEventDetail = { originalCursor, originalCursorIndices, cursor, cursorIndices }

  if (onDifferent(originalCursor, cursor))
    cursorEventTarget.dispatchEvent(makeEvent("cellCursorTargetChange", detail))

  if (originalCursor.state !== cursor.state)
    cursorEventTarget.dispatchEvent(makeEvent("cellCursorStateChange", detail))
}

const makeEvent = (eventKey: string, detail: CursorEventDetail) =>
  new CustomEvent<CursorEventDetail>(eventKey, { detail })
