import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ControlProps } from "@jsonforms/core"
import classNames from "classnames"
import React, { FC, KeyboardEvent, useRef, useState } from "react"

import { useSelectList } from "v2/react/hooks/useSelectList"
import { DropdownMenu } from "v2/react/shared/collection/menus/DropdownMenu"
import { convertBase64 } from "v2/react/utils/files"

import { useValidation } from "./hooks/useValidation"
import { InputErrorText } from "./InputErrorText"

interface JsonFileUploadField {
  fileBase64Data: string
  title: string
  uploadId: string
  uploadUrl: string
}

const JsonFileUpload: FC<ControlProps> = ({
  data,
  handleChange,
  id,
  label,
  path,
  errors,
  config,
  schema,
}) => {
  const fileInput = useRef<HTMLInputElement | null>(null)
  const [fileName, setFileName] = useState<string | null>(data?.title || "")
  const [showMenu, setShowMenu] = useState(false)
  const {
    activeIndex,
    setActiveIndex,
    listRef,
    refs,
    floatingStyles,
    context,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
  } = useSelectList({
    showList: showMenu,
    setShowList: setShowMenu,
    floatOverrides: { placement: "bottom-start" },
  })
  const { showError, errorMessage } = useValidation({
    path,
    schema,
    submitting: config.submitting,
    schemaError: errors,
  })

  const options = [
    {
      label: "replace_file".t("button_defaults"),
      action: () => fileInput.current?.click(),
    },
    {
      label: "remove_file".t("button_defaults"),
      action: () => {
        if (fileInput.current) {
          fileInput.current.value = ""
          setFileName(null)
          const value: JsonFileUploadField = {
            fileBase64Data: "",
            title: "",
            uploadId: "",
            uploadUrl: "",
          }
          handleChange(path, value)
        }
      },
    },
  ]

  const handleOpen = () => {
    refs.domReference.current?.focus()
    setActiveIndex(showMenu ? null : 0)
  }

  const handleResultClick = () => {
    if (activeIndex !== null) options[activeIndex].action()
    setActiveIndex(null)
    setShowMenu(false)
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && activeIndex != null) {
      options[activeIndex].action()
    }
  }

  const handleFileUpload = async () => {
    const files = fileInput.current?.files

    if (files && files[0]) {
      setFileName(files[0].name)
      const base64 = await convertBase64(files[0])
      const value: JsonFileUploadField = {
        fileBase64Data: base64 as string,
        title: files[0].name,
        uploadId: "",
        uploadUrl: "",
      }
      handleChange(path, value)
    }
  }

  return (
    <div className="input-group file-upload-input">
      <p className="mb-1 font-bold">{label}</p>
      {fileName && (
        <div className="mb-1 items-center flex">
          <button
            className="btn--sm btn--secondary"
            type="button"
            ref={refs.setReference}
            /* eslint-disable react/jsx-props-no-spreading */
            {...getReferenceProps({
              onClick: handleOpen,
              onKeyDown: handleKeyDown,
            })}
          >
            <FontAwesomeIcon icon={["fas", "caret-down"]} size="xs" />
          </button>
          {data?.uploadId && data.uploadId !== "" && data.uploadUrl ? (
            <a
              className="link ml-2"
              href={data.uploadUrl}
              rel="noopener noreferrer"
              target="_blank"
            >
              {fileName}
            </a>
          ) : (
            <p className="ml-2">{fileName}</p>
          )}
          <DropdownMenu
            showList={showMenu}
            floatingRef={refs.setFloating}
            floatingStyles={{ ...floatingStyles, minWidth: "10rem", left: 0 }}
            floatingProps={getFloatingProps}
            wrapperClasses="SelectField"
            context={context}
          >
            {options.map((option, index) => (
              <div
                role="option"
                aria-selected={activeIndex === index}
                key={option.label}
                className={textClassName(activeIndex, index)}
                ref={(node) => {
                  listRef.current[index] = node
                }}
                /* eslint-disable react/jsx-props-no-spreading */
                {...getItemProps({
                  onClick: handleResultClick,
                  onKeyDown: handleKeyDown,
                })}
              >
                <div className="SelectField__result-text truncate">{option.label}</div>
              </div>
            ))}
          </DropdownMenu>
        </div>
      )}

      {!fileName || fileName === "" ? (
        <label htmlFor={id} className="btn--large btn--secondary">
          <FontAwesomeIcon icon={["far", "plus"]} size="1x" />
          {"add_file".t("button_defaults")}
          <input ref={fileInput} type="file" name={id} id={id} onChange={handleFileUpload} />
        </label>
      ) : (
        <div>
          <label htmlFor={id} className="hidden">
            <input ref={fileInput} type="file" name={id} id={id} onChange={handleFileUpload} />
          </label>
        </div>
      )}
      <InputErrorText message={errorMessage} showError={showError} />
    </div>
  )
}

export { JsonFileUpload }

const textClassName = (activeIndex: number | null, index: number) =>
  classNames("SelectField__result", { highlight: activeIndex === index })
