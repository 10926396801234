import type { CalendarDate, DateValue } from "@internationalized/date"
import dayjs from "dayjs"
import quarterOfYear from "dayjs/plugin/quarterOfYear"

dayjs.extend(quarterOfYear)

type PresetDateRangeKey =
  | "tomorrow"
  | "today"
  | "yesterday"
  | "current_month"
  | "current_week"
  | "current_quarter"
  | "current_year"
  | "last_week"
  | "last_month"
  | "last_quarter"
  | "last_year"
  | "last_12_months"
  | "month_to_date"
  | "next_month"
  | "next_quarter"
  | "next_week"
  | "next_year"
  | "quarter_to_date"
  | "year_to_date"
  | "custom_date_range"

type DateRangeOption = Exclude<PresetDateRangeKey, "custom_date_range">

interface Range {
  start: dayjs.Dayjs
  end: dayjs.Dayjs
  label: string
  value: DateRangeOption
}

const buildYearOptions = ({
  minYear,
  maxYear,
  activeYear,
}: {
  minYear: number
  maxYear: number
  activeYear: number
}) => {
  const range = maxYear - minYear + 1
  const activeStart = Math.max(activeYear - 5, minYear)
  const activeEnd = Math.min(activeYear + 5, maxYear)

  // We truncate the options if the total span is greater than 50 years.
  if (range > 50) {
    const earlyYears = Array.from({ length: 10 }, (_, i) => ({
      id: minYear + i,
      label: minYear + i,
    }))
    const lateYears = Array.from({ length: 10 }, (_, i) => ({
      id: maxYear - 9 + i,
      label: maxYear - 9 + i,
    }))
    const activeYears = Array.from({ length: activeEnd - activeStart + 1 }, (_, i) => ({
      id: activeStart + i,
      label: activeStart + i,
    }))

    const uniqueYears = new Map()
    ;[...earlyYears, ...activeYears, ...lateYears].forEach((year) => uniqueYears.set(year.id, year))
    return Array.from(uniqueYears.values()).sort((a, b) => a.id - b.id)
  }

  return Array.from({ length: range }, (_, i) => ({ id: minYear + i, label: minYear + i }))
}

const buildDateRangeOptions = (
  currentDate: CalendarDate,
  quickSelectOptions: DateRangeOption[],
): Record<DateRangeOption, Range> => {
  const today = dayjs(currentDate.toString())

  const options: Record<DateRangeOption, Range> = {
    tomorrow: {
      start: today.add(1, "day").startOf("day"),
      end: today.add(1, "day").endOf("day"),
      label: today.add(1, "day").format("MMM D, YYYY"),
      value: "tomorrow",
    },
    today: {
      start: today.startOf("day"),
      end: today.endOf("day"),
      label: today.format("MMM D, YYYY"),
      value: "today",
    },
    yesterday: {
      start: today.subtract(1, "day").startOf("day"),
      end: today.subtract(1, "day").endOf("day"),
      label: today.subtract(1, "day").format("MMM D, YYYY"),
      value: "yesterday",
    },
    current_week: {
      start: today.startOf("week"),
      end: today.endOf("week"),
      label: `${today.startOf("week").format("MMM D")} - ${today
        .endOf("week")
        .format("MMM D, YYYY")}`,
      value: "current_week",
    },
    current_month: {
      start: today.startOf("month"),
      end: today.endOf("month"),
      label: today.startOf("month").format("MMM YYYY"),
      value: "current_month",
    },
    current_quarter: {
      start: today.startOf("quarter"),
      end: today.endOf("quarter"),
      label: `${today.startOf("quarter").format("MMM D")} - ${today
        .endOf("quarter")
        .format("MMM D, YYYY")}`,
      value: "current_quarter",
    },
    current_year: {
      start: today.startOf("year"),
      end: today.endOf("year"),
      label: today.startOf("year").format("YYYY"),
      value: "current_year",
    },
    last_week: {
      start: today.subtract(1, "week").startOf("week"),
      end: today.subtract(1, "week").endOf("week"),
      label: `${today.subtract(1, "week").startOf("week").format("MMM D")} - ${today
        .subtract(1, "week")
        .endOf("week")
        .format("MMM D, YYYY")}`,
      value: "last_week",
    },
    last_month: {
      start: today.subtract(1, "month").startOf("month"),
      end: today.subtract(1, "month").endOf("month"),
      label: today.subtract(1, "month").startOf("month").format("MMM YYYY"),
      value: "last_month",
    },
    last_quarter: {
      start: today.subtract(1, "quarter").startOf("quarter"),
      end: today.subtract(1, "quarter").endOf("quarter"),
      label: `${today.subtract(1, "quarter").startOf("quarter").format("MMM D")} - ${today
        .subtract(1, "quarter")
        .endOf("quarter")
        .format("MMM D, YYYY")}`,
      value: "last_quarter",
    },
    last_year: {
      start: today.subtract(1, "year").startOf("year"),
      end: today.subtract(1, "year").endOf("year"),
      label: today.subtract(1, "year").startOf("year").format("YYYY"),
      value: "last_year",
    },
    last_12_months: {
      start: today.subtract(1, "year"),
      end: today,
      label: `${today.subtract(1, "year").format("MMM D, YYYY")} - ${today.format("MMM D, YYYY")}`,
      value: "last_12_months",
    },
    month_to_date: {
      start: today.startOf("month"),
      end: today,
      label: `${today.startOf("month").format("MMM D")} - ${today.format("MMM D, YYYY")}`,
      value: "month_to_date",
    },
    quarter_to_date: {
      start: today.startOf("quarter"),
      end: today,
      label: `${today.startOf("quarter").format("MMM D")} - ${today.format("MMM D, YYYY")}`,
      value: "quarter_to_date",
    },
    year_to_date: {
      start: today.startOf("year"),
      end: today,
      label: `${today.startOf("year").format("MMM D")} - ${today.format("MMM D, YYYY")}`,
      value: "year_to_date",
    },
    next_week: {
      start: today.add(1, "week").startOf("week"),
      end: today.add(1, "week").endOf("week"),
      label: `${today.add(1, "week").startOf("week").format("MMM D")} - ${today
        .add(1, "week")
        .endOf("week")
        .format("MMM D, YYYY")}`,
      value: "next_week",
    },
    next_month: {
      start: today.add(1, "month").startOf("month"),
      end: today.add(1, "month").endOf("month"),
      label: today.add(1, "month").startOf("month").format("MMM YYYY"),
      value: "next_month",
    },
    next_quarter: {
      start: today.add(1, "quarter").startOf("quarter"),
      end: today.add(1, "quarter").endOf("quarter"),
      label: `${today.add(1, "quarter").startOf("quarter").format("MMM D")} - ${today
        .add(1, "quarter")
        .endOf("quarter")
        .format("MMM D, YYYY")}`,
      value: "next_quarter",
    },
    next_year: {
      start: today.add(1, "year").startOf("year"),
      end: today.add(1, "year").endOf("year"),
      label: today.add(1, "year").startOf("year").format("YYYY"),
      value: "next_year",
    },
  }

  return quickSelectOptions.reduce(
    (acc, item) => {
      acc[item] = options[item]

      return acc
    },
    {} as Record<DateRangeOption, Range>,
  )
}

type DateRangeOptions = ReturnType<typeof buildDateRangeOptions>

const matchedSelectedRange = (
  startDate: DateValue,
  endDate: DateValue,
  dateRangeOptions: DateRangeOptions,
) =>
  Object.keys(dateRangeOptions).find((date) => {
    const rangeKey = date as DateRangeOption

    if (
      dateRangeOptions[rangeKey].start.format("YYYY-MM-DD") === startDate.toString() &&
      dateRangeOptions[rangeKey].end.format("YYYY-MM-DD") === endDate.toString()
    ) {
      return dateRangeOptions[rangeKey]
    }

    return null
  })

export { buildDateRangeOptions, buildYearOptions, matchedSelectedRange }
export type { DateRangeOption, PresetDateRangeKey }
