import React from "react"

interface Props {
  label: string
  value: string | number | null | undefined
}

export default function PositionDetail({ label, value }: Props) {
  return (
    <div>
      <dt className="text-neutral-500">{label}</dt>
      <dd className="text-neutral-900">{value}</dd>
    </div>
  )
}
