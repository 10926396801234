import { selectTimezone } from "v2/redux/slices/SessionSlice/selectors"
import { useAppSelector } from "v2/redux/store"

/**
 * Returns the current person's timezone, set in
 * app/controllers/application_controller.rb and preloaded
 * into redux state through app/helpers/redux_helper.rb.
 *
 * If the timezone is not set, it defaults to the browser's
 * timezone.
 */
const useTimeZone = () => {
  const timeZone =
    useAppSelector(selectTimezone) ||
    (typeof window !== "undefined" && Intl.DateTimeFormat().resolvedOptions().timeZone) ||
    "UTC"

  return timeZone
}

export { useTimeZone }
