import fp from "lodash/fp"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"

import { isElementThatCollectsKeyboardInput } from "v2/react/utils/elements"

import { FieldValues } from "../../positionForm"
import { WatchEntry, WatchFieldName } from "../types"
import { diffPositionData } from "../utils/diffPositionData"

type UseDiffOfFieldValuesArg = {
  current: Nullish<FieldValues>
  basis: Nullish<FieldValues>
  watchFieldNames: WatchFieldName[]
}

function useDiffOfFieldValues({ current, basis, watchFieldNames }: UseDiffOfFieldValuesArg) {
  const [diff, setDiff] = useState<Record<string, WatchEntry>>(() =>
    diffPositionData(basis, current, watchFieldNames),
  )

  // Two flags which we use to help control when processing occurs. These help
  // us wait for a user click to register before processing changes. Without
  // this, we can accidentally cause layout shifts before a click is
  // registered. If that happens, the user's click can miss and it leads to a
  // bad experience.
  const waitForMouseUpRef = useRef(false)
  const doComputeDiffOnMouseUpRef = useRef(false)

  // Track what the caller gave us such that we can choose when and how to
  // change this.
  const callerDataRef = useRef({
    basis,
    current,
    watchFieldNames,
  })

  const computeDiff = useCallback(() => {
    setDiff((currentDiff) => {
      const { current: callerData } = callerDataRef
      const { basis, current, watchFieldNames } = callerData
      const nextDiff = diffPositionData(basis, current, watchFieldNames)
      return fp.isEqual(nextDiff, currentDiff) ? currentDiff : nextDiff
    })
  }, [])

  useEffect(() => {
    callerDataRef.current = {
      basis,
      current,
      watchFieldNames,
    }

    if (waitForMouseUpRef.current || isElementThatCollectsKeyboardInput(document.activeElement)) {
      doComputeDiffOnMouseUpRef.current = true
      return
    }

    computeDiff()
  }, [current, basis, computeDiff, watchFieldNames])

  // DOM event handlers for firing `processPendingQueue`. Helps delay
  // processing until after a user finishes making changes to an input.
  useEffect(() => {
    const handleFocusOut = () => {
      if (waitForMouseUpRef.current) doComputeDiffOnMouseUpRef.current = true
      else computeDiff()
    }
    const handleMouseDown = () => {
      waitForMouseUpRef.current = true
    }
    const handleMouseUp = () => {
      if (doComputeDiffOnMouseUpRef.current) computeDiff()
      waitForMouseUpRef.current = false
      doComputeDiffOnMouseUpRef.current = false
    }

    document.addEventListener("focusout", handleFocusOut, { passive: true })
    document.addEventListener("mousedown", handleMouseDown, { passive: true })
    document.addEventListener("mouseup", handleMouseUp, { passive: true })
    return () => {
      document.removeEventListener("mouseup", handleMouseUp)
      document.removeEventListener("mousedown", handleMouseDown)
      document.removeEventListener("focusout", handleFocusOut)
    }
  }, [computeDiff])

  const diffEntries = useMemo(() => Object.values(diff), [diff])

  return { diff, diffEntries }
}

export { useDiffOfFieldValues }
