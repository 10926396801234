import classNames from "classnames"
import { motion, Reorder, useMotionValue, useMotionValueEvent } from "framer-motion"
import React, { useState } from "react"

interface Props<ItemType> {
  children: React.ReactNode
  dragContainerRef: React.RefObject<HTMLDivElement>
  item: ItemType
  itemClasses?: string
  useLayoutProperty?: true | "position" | undefined
}

export function DraggableListItem<ItemType>({
  children,
  dragContainerRef,
  item,
  itemClasses,
  useLayoutProperty,
}: Props<ItemType>) {
  const [isDragging, setIsDragging] = useState(false)

  const y = useMotionValue(0)

  // If the drag is canceled by clicking on it after it's been released,
  // this resets the y value so it returns it the correct position.
  useMotionValueEvent(y, "animationCancel", () => y.set(0))

  return (
    <Reorder.Item
      as="div"
      value={item}
      className={classNames("list-group-item", itemClasses, { dragging: isDragging })}
      dragConstraints={dragContainerRef}
      dragElastic={0}
      dragTransition={{
        bounceStiffness: 200,
        bounceDamping: 20,
        modifyTarget: () => 0, // Ensures item always goes to the correct position
      }}
      layout={useLayoutProperty}
      onDragStart={() => setIsDragging(true)}
      onDragEnd={() => setIsDragging(false)}
      whileDrag={{ boxShadow: "0 0.125rem 0.25rem 0 rgba(0,0,0,0.20)" }}
      style={{ y }}
    >
      {useLayoutProperty ? (
        <motion.div
          layout
          transition={{
            layout: {
              duration: 0.2,
            },
          }}
        >
          {children}
        </motion.div>
      ) : (
        children
      )}
    </Reorder.Item>
  )
}
