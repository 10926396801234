import classNames from "classnames"
import fp from "lodash/fp"
import React, { useState } from "react"

import { AtsOptions, PositionNestedJobRequisition } from "types/graphql"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import { StatusBadge } from "v2/react/shared/status/StatusBadge"
import {
  useGetAtsOptionsQuery,
  useGetPositionForRequisitionQuery,
} from "v2/redux/GraphqlApi/JobRequisitionsApi"
import { StoreProvider } from "v2/redux/StoreProvider"

import { AdpRmForm, AdpRmInfo, AdpRmInitSuccess, initAdpRmForm } from "./AdpRmForm"
import { GreenhouseForm, GreenhouseInitSuccess, initGreenhouseForm } from "./GreenhouseForm"
import { initLeverForm, LeverForm, LeverInitSuccess } from "./LeverForm"
import { Trigger } from "./Trigger"
import { InitFailure } from "./utils"

const EMPTY_JOB_REQUISITIONS: PositionNestedJobRequisition[] = []

const APPROVAL_STATE_PERMITS_SEND = ["approved", "closed", "denied", "canceled"]
const NON_BLOCKING_EXTERNAL_STATUSES_FOR_SEND = ["canceled", "closed", "filled", "rejected"]
const NON_VISIBLE_EXTERNAL_STATUSES = ["canceled", "closed", "rejected"]
const VISIBLE_APPROVAL_STATES = [
  "pending_approval",
  "changes_requested",
  "no_configuration",
  "approved",
]

function init(
  title: string,
  departments: string[],
  location: string,
  atsOptions: AtsOptions,
  replacementFor?: string,
  jobDescription?: string,
  numPositions?: number,
) {
  if (atsOptions.integration === "greenhouse") {
    return initGreenhouseForm(title, departments, location, atsOptions)
  }
  if (atsOptions.integration === "lever") {
    return initLeverForm(title, departments, location)
  }
  if (atsOptions.integration === "adp_rm") {
    return initAdpRmForm(
      title,
      String(replacementFor),
      String(jobDescription),
      Number(numPositions),
    )
  }

  return {
    success: false,
    message: "unable_to_get_ats_data".t("ats"),
  } as InitFailure
}

export type SetFormDataFieldActionType = {
  field: keyof SendToAtsFormData
  value: string
}

interface SendToAtsFormData {
  externalTemplateId?: string
  externalDepartmentId?: string
  externalLocationId?: string
  jobTitle?: string
  systemUid?: string
  jobDescription?: string
}

type SendToAtsProps = {
  asDropdownMenuLink: boolean
  positionId: string
  title: string
  orgUnitNames: string[]
  locationName: string
  withIcon?: boolean
  asLink: boolean
  linkTitle?: string
  replacementFor?: string
  rmInfoOnly: boolean
  asButton?: boolean
  jobRequisitionId?: string
  systemUid?: string
  jobDescription?: string
  numPositions?: number
}

function WithProvider({
  asDropdownMenuLink,
  positionId,
  title,
  orgUnitNames,
  locationName,
  withIcon,
  asLink,
  linkTitle,
  replacementFor,
  rmInfoOnly,
  asButton,
  jobRequisitionId,
  systemUid,
  jobDescription,
  numPositions,
}: SendToAtsProps) {
  const { data, isLoading: atsOptionsLoading } = useGetAtsOptionsQuery()

  const { positionDataLoading, connectedRequisitions, internalRequisitions, canSend } =
    usePositionJobRequisitions(`position_${positionId}`)

  if (atsOptionsLoading || positionDataLoading) {
    return (
      <div className="text-center">
        <Spinner style={spinnerStyleProps} />
      </div>
    )
  }

  if (!data?.atsOptions) {
    return (
      <div>
        <p>ATS Integration Error</p>
      </div>
    )
  }

  const initData = init(
    title,
    orgUnitNames,
    locationName,
    data.atsOptions,
    replacementFor,
    jobDescription,
    numPositions,
  )

  if (!initData.success) {
    return (
      <div>
        <p>ATS Integration Error</p>
        <p>{initData.message}</p>
      </div>
    )
  }

  if (asButton) {
    return canSend ? (
      <SendToAtsBtn
        asDropdownMenuLink={false}
        asLink={asLink}
        withIcon={withIcon}
        linkTitle={linkTitle}
        initData={initData}
        positionId={positionId}
        rmInfoOnly={rmInfoOnly}
        smallButton
        jobRequisitionId={jobRequisitionId}
        systemUid={systemUid}
      />
    ) : (
      ""
    )
  }

  if (asDropdownMenuLink) {
    return canSend ? (
      <SendToAtsBtn
        asDropdownMenuLink={asDropdownMenuLink}
        asLink={asLink}
        withIcon={withIcon}
        linkTitle={linkTitle}
        initData={initData}
        positionId={positionId}
        rmInfoOnly={rmInfoOnly}
        jobRequisitionId={jobRequisitionId}
        systemUid={systemUid}
      />
    ) : (
      ""
    )
  }

  return (
    <>
      <ActiveRequisitionList requisitions={internalRequisitions.filter(isInternalReqVisible)} />
      <ConnectedRequisitions requisitions={connectedRequisitions.filter(isExternalReqVisible)} />
      {canSend ? (
        <SendToAtsBtn
          asLink={asLink}
          linkTitle={linkTitle}
          asDropdownMenuLink={asDropdownMenuLink}
          withIcon={withIcon}
          initData={initData}
          positionId={positionId}
          rmInfoOnly={rmInfoOnly}
          jobRequisitionId={jobRequisitionId}
          systemUid={systemUid}
        />
      ) : (
        ""
      )}
    </>
  )
}

const isExternalReq = (val: PositionNestedJobRequisition) => val.clientIdentifier && val.status
const isExternalReqVisible = (req: PositionNestedJobRequisition) =>
  !NON_VISIBLE_EXTERNAL_STATUSES.includes(req.status ?? "")
const isExternalReqNonBlockingForSend = (req: PositionNestedJobRequisition) =>
  NON_BLOCKING_EXTERNAL_STATUSES_FOR_SEND.includes(req.status ?? "")
const isInternalReq = fp.negate(isExternalReq)
const isInternalReqVisible = (req: PositionNestedJobRequisition) =>
  VISIBLE_APPROVAL_STATES.includes(req.approvalState ?? "")
const isInternalReqEligibleForSend = (req: PositionNestedJobRequisition) =>
  APPROVAL_STATE_PERMITS_SEND.includes(req.approvalState ?? "")

function usePositionJobRequisitions(positionId: string) {
  const { data, isLoading } = useGetPositionForRequisitionQuery({ positionId })

  const jobRequisitions: PositionNestedJobRequisition[] =
    data?.position?.allJobRequisitions ?? EMPTY_JOB_REQUISITIONS

  const connectedRequisitions = jobRequisitions.filter(isExternalReq)
  const internalRequisitions = jobRequisitions.filter(isInternalReq)

  const allConnectedReqsDoNotBlockSend = fp.all(
    isExternalReqNonBlockingForSend,
    connectedRequisitions,
  )
  const someInternalReqEligibleForSend = fp.some(isInternalReqEligibleForSend, internalRequisitions)
  const canSend =
    fp.isEmpty(jobRequisitions) ||
    (allConnectedReqsDoNotBlockSend && fp.isEmpty(internalRequisitions)) ||
    (allConnectedReqsDoNotBlockSend && someInternalReqEligibleForSend)

  return {
    positionData: data,
    positionDataLoading: isLoading,
    connectedRequisitions,
    internalRequisitions,
    canSend,
  }
}

const spinnerStyleProps: React.CSSProperties = {
  position: "relative",
  top: 0,
  left: 0,
  margin: 0,
}

interface RequisitionProps {
  requisitions: PositionNestedJobRequisition[]
}
function ActiveRequisitionList({ requisitions }: RequisitionProps) {
  return (
    <div className="w-full">
      {requisitions.map((req: PositionNestedJobRequisition) => (
        <div key={req.id}>
          <div className="justify-between flex">
            <div>{"req_id".t("ats")}</div>
            <div>{req.systemUid}</div>
          </div>
          <div className="justify-between flex">
            <div>{"approval_status".t("ats")}</div>
            <div>
              {req.approvalState ? (
                <StatusBadge status={req.approvalState} displayType="button" />
              ) : (
                "-"
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

function ConnectedRequisitions({ requisitions }: RequisitionProps) {
  return (
    <div className="w-full">
      {requisitions.map((cr) => (
        <div key={cr.id}>
          <div className="mb-4 justify-between flex">
            <div>{"external_req_id".t("ats")}</div>
            <div>{cr.clientIdentifier}</div>
          </div>
          <div className="mb-4 justify-between flex">
            <div>{"external_req_status".t("ats")}</div>
            <div>{cr.status ? <StatusBadge status={cr.status} displayType="button" /> : "-"}</div>
          </div>
        </div>
      ))}
    </div>
  )
}

interface SendToAtsBtnProps {
  asLink: boolean
  asDropdownMenuLink: boolean
  linkTitle: string | undefined
  withIcon: boolean | undefined
  initData: LeverInitSuccess | GreenhouseInitSuccess | AdpRmInitSuccess
  positionId: string
  rmInfoOnly?: boolean
  smallButton?: boolean
  jobRequisitionId?: string
  systemUid?: string
}

function SendToAtsBtn({
  asDropdownMenuLink,
  withIcon,
  initData,
  positionId,
  asLink,
  linkTitle,
  rmInfoOnly,
  smallButton,
  jobRequisitionId,
  systemUid,
}: SendToAtsBtnProps) {
  const [isOpen, setIsOpen] = useState(false)

  const openModal = (e: React.SyntheticEvent) => {
    e.preventDefault()
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const isReplacement =
    initData.integration === "adp_rm" && rmInfoOnly && !!initData.data.replacementFor
  const standardNumPositions = isReplacement ? 0 : 1

  return (
    <div className={classNames(smallButton ? "justify-end" : "justify-center", "w-full flex")}>
      <Trigger
        smallButton={smallButton}
        asLink={asLink}
        linkTitle={linkTitle}
        asDropdownMenuLink={asDropdownMenuLink}
        onClick={openModal}
        withIcon={withIcon}
      />
      {initData.integration === "greenhouse" && (
        <GreenhouseForm
          closeModal={closeModal}
          formSchema={initData.schema}
          initialFormData={initData.data}
          isOpen={isOpen}
          options={initData.options}
          positionId={jobRequisitionId ? null : positionId}
          jobRequisitionId={jobRequisitionId}
          systemUid={systemUid}
        />
      )}
      {initData.integration === "lever" && (
        <LeverForm
          closeModal={closeModal}
          formSchema={initData.schema}
          initialFormData={initData.data}
          isOpen={isOpen}
          positionId={jobRequisitionId ? null : positionId}
          jobRequisitionId={jobRequisitionId}
          systemUid={systemUid}
        />
      )}
      {initData.integration === "adp_rm" && !rmInfoOnly && (
        <AdpRmForm
          closeModal={closeModal}
          formSchema={initData.schema}
          initialFormData={initData.data}
          isOpen={isOpen}
          positionId={positionId}
        />
      )}
      {initData.integration === "adp_rm" && rmInfoOnly && (
        <AdpRmInfo
          closeModal={closeModal}
          isOpen={isOpen}
          requisition={{
            jobTitle: initData.data.jobTitle,
            jobDescription:
              (initData.data.jobDescription ?? "") === "undefined"
                ? ""
                : initData.data.jobDescription ?? "",
            replacementFor: initData.data.replacementFor ?? "",
            systemUid: systemUid ?? "",
            jobRequisitionId: jobRequisitionId ?? "",
            isReplacement: !!isReplacement,
            numPositions: Number.isNaN(Number(initData.data.numPositions))
              ? standardNumPositions
              : Number(initData.data.numPositions),
          }}
        />
      )}
    </div>
  )
}

function SendToAts({
  asDropdownMenuLink = false,
  asLink = false,
  linkTitle,
  positionId,
  title,
  locationName,
  orgUnitNames,
  withIcon,
  replacementFor,
  rmInfoOnly,
  asButton,
  jobRequisitionId,
  systemUid,
  jobDescription,
  numPositions,
}: SendToAtsProps) {
  return (
    <StoreProvider>
      <WithProvider
        asLink={asLink}
        linkTitle={linkTitle}
        asDropdownMenuLink={asDropdownMenuLink}
        positionId={positionId}
        title={title}
        locationName={locationName}
        orgUnitNames={orgUnitNames}
        withIcon={withIcon}
        replacementFor={replacementFor}
        rmInfoOnly={rmInfoOnly}
        asButton={asButton}
        jobRequisitionId={jobRequisitionId}
        systemUid={systemUid}
        jobDescription={jobDescription}
        numPositions={numPositions}
      />
    </StoreProvider>
  )
}

export { SendToAts }
