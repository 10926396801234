import React from "react"

import { CompareValues } from "./CompareValues"
import { useCellHandlers } from "./hooks/useCellHandlers"
import { useCellState } from "./hooks/useCellState"
import { useCursorSync } from "./hooks/useCursorSync"
import { StandardCellInput } from "./StandardCellInput"
import { StyleLayers } from "./StyleLayers"
import { FieldType, SaveFn } from "./types"

type Props = {
  currentValue: string
  compareValue?: string | null
  saveFn: SaveFn
  inputType?: string
  inputPlaceholder?: string
  onSaved?: () => void
  onErrored?: () => void
  columnId: string
  rowId: string
  editable?: boolean
}
export function StandardEditableCell({
  saveFn,
  onSaved,
  onErrored,
  currentValue,
  compareValue,
  inputPlaceholder,
  columnId,
  rowId,
  editable,
  inputType = "text",
}: Props) {
  const inputRef = React.useRef<HTMLInputElement>(null)

  const cell = useCellState({
    currentValue,
    fieldType: FieldType.Standard,
    rowId,
    columnId,
    editable,
    saveFn: (state) => saveFn(state.value),
    onSaved: () => onSaved?.(),
    onErrored: () => onErrored?.(),
    onEditing: () => {
      if (inputRef.current) {
        inputRef.current.disabled = false
        inputRef.current?.focus()
      }
    },
  })

  const cellRef = React.useRef<HTMLDivElement>(null)
  useCursorSync({ cell, cellRef, value: inputRef.current?.value ?? currentValue })

  const { handleCellClick, handleCellKeyUp } = useCellHandlers(cell, cellRef, {
    getSaveValue: () => inputRef.current?.value ?? currentValue,
  })

  const shouldHideCompareValue =
    cell.isEditing ||
    cell.isSaving ||
    cell.isSaved ||
    cell.isErrored ||
    cell.isErroredEditing ||
    cell.isErroredSaving

  return (
    // eslint-disable-next-line jsx-a11y/interactive-supports-focus
    <div
      role="button"
      ref={cellRef}
      className="editable-cell"
      id={`${columnId}-${rowId}`}
      onClick={handleCellClick}
      onKeyUp={handleCellKeyUp}
    >
      <CompareValues compareValue={shouldHideCompareValue ? null : compareValue}>
        <StandardCellInput
          ref={inputRef}
          name={columnId}
          disabled={!(cell.isEditing || cell.isErroredEditing)}
          type={inputType}
          defaultValue={currentValue}
          placeholder={cell.isEditing || cell.isErroredEditing ? inputPlaceholder : undefined}
        />
      </CompareValues>
      <StyleLayers cell={cell} fieldType={FieldType.Standard} />
    </div>
  )
}
