import { EntityId } from "@reduxjs/toolkit"
import fp from "lodash/fp"
import { createSelector, createStructuredSelector } from "reselect"

import { chartSectionSelectors } from "v2/redux/slices/ContainerSlice"
import type {
  ChartSection,
  ChartSectionTreeIndex,
  ChartSectionTreeNode,
  Field,
} from "v2/redux/slices/ContainerSlice/types"
import type { RootState } from "v2/redux/store"

// We don't want to display this field separately, but this constraint only
// applies for the spreadsheet. TW-34798128
const OMIT_FROM_SPREADSHEET = ["position_job_code"]

/**
 * @public
 * @returns {array} an array of the node container's enabled fields.
 */
const selectFields = createSelector(
  [(state) => state.container.fields, (_: unknown, omit: string[] = OMIT_FROM_SPREADSHEET) => omit],
  (fields, omit) => fp.reject(({ fieldKey }) => omit.includes(fieldKey), fields),
)

/**
 * @public
 * @returns a field dictionary keyed by "fieldKey".
 */
const selectFieldIndex = createSelector(selectFields, (fields) =>
  fp.indexBy<Field>(fp.prop("fieldKey"), fields),
)

/**
 * @public
 * @returns {object} an index of chart sections colors keyed by chart section
 *   name. This is not related to normal color coding functionality. This is
 *   different from chart section color codes.
 */
const selectChartSectionColorsByName = createSelector(
  chartSectionSelectors.selectAll,
  (chartSections) =>
    fp.pipe(
      fp.map(({ name, color }) => [name, color]),
      fp.fromPairs,
    )(chartSections),
)

const selectChartSectionsCollapsedIndex = (state: RootState) =>
  state.container.chartSectionsCollapsedIndex
const withId = (_: unknown, id: EntityId) => id

const selectIsChartSectionCollapsed = createSelector(
  [selectChartSectionsCollapsedIndex, withId],
  (chartSectionsCollapsedIndex, id) => !!chartSectionsCollapsedIndex[id.toString()],
)

const getChartSectionTreeNodes = (
  treeIndex: ChartSectionTreeIndex,
  sectionsLookup: { [key in EntityId]?: ChartSection },
  level: number,
): ChartSectionTreeNode[] => {
  const idsAtCurrentDepth = fp.sortBy(fp.identity, fp.keys(treeIndex))
  const sectionsAtCurrentDepth = fp.compact(fp.props(idsAtCurrentDepth, sectionsLookup))

  return fp.map((section: ChartSection) => {
    const descendantTreeIndex = treeIndex[section.id] || {}
    const children = getChartSectionTreeNodes(descendantTreeIndex, sectionsLookup, level + 1)
    const descendantsCount = fp.sumBy("descendantsCount", children) + children.length
    const descendantIds = [
      ...fp.map("id", children),
      ...children.reduce((acc, child) => acc.concat(child.descendantIds), [] as string[]),
    ]

    return {
      ...section,
      level,
      children,
      descendantsCount,
      selfAndDescendantsCount: descendantsCount + 1,
      hasChildren: descendantsCount > 0,
      descendantIds,
    }
  }, sectionsAtCurrentDepth)
}

/**
 * @public
 * @returns a tree of chart sections (array of recursing objects, sorted by id).
 */
const selectChartSectionsTree = createSelector(
  chartSectionSelectors.selectEntities,
  (state: RootState) => state.container.chartSectionTreeIndex,
  (sectionLookup, treeIndex) => getChartSectionTreeNodes(treeIndex, sectionLookup, 2),
)

const selectOrderClause = (state: RootState) => state.grid.sorting

/**
 * @public
 * @returns a hash holding the column and direction used for sorting.
 */
const selectOrderingDetails = createStructuredSelector({
  sortColumn: createSelector(
    selectOrderClause,
    selectFieldIndex,
    ({ fieldKey }, columnLookup) =>
      fp.prop(fieldKey, columnLookup) ||
      fp.prop(Object.values(columnLookup)[0].fieldKey, columnLookup),
  ),
  sortDirection: createSelector(selectOrderClause, ({ direction }) => direction),
})

export {
  chartSectionSelectors,
  OMIT_FROM_SPREADSHEET,
  selectChartSectionColorsByName,
  selectChartSectionsTree,
  selectFieldIndex,
  selectFields,
  selectIsChartSectionCollapsed,
  selectOrderingDetails,
}
