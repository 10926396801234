import { KeyboardEvent, RefObject, useCallback, useRef } from "react"

type InputRefObject = RefObject<HTMLInputElement | HTMLSelectElement>
type KeyUpHandler = (event: KeyboardEvent) => void
type DeferKeyUpHandler = (func: KeyUpHandler) => void
type MaybeDeferrable = { type: "unit" } | { type: "func"; func: KeyUpHandler }
type FuncOnNextKeyUpArg = {
  preventDefault?: boolean
  stopPropagation?: boolean
}

/**
 * Utility for enqueueing a function until a keyup event.
 *
 * This is useful for delaying effects that should trigger due to a keydown
 * event, but may be adversely affected unless handling is deferred to the
 * subsequent keyup event.
 */
export function useFuncOnNextKeyUp(arg?: FuncOnNextKeyUpArg): [KeyUpHandler, DeferKeyUpHandler] {
  const { preventDefault, stopPropagation } = arg || {}
  const deferrable = useRef<MaybeDeferrable>({ type: "unit" })

  const deferUntilKeyUp = useCallback(
    (func: (ev: KeyboardEvent) => void) => {
      deferrable.current = { type: "func", func }
    },
    [deferrable],
  )

  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (deferrable.current.type === "unit") return
      if (preventDefault) event.preventDefault()
      if (stopPropagation) event.stopPropagation()

      // Extract the func and reset our ref.
      const { func } = deferrable.current
      deferrable.current = { type: "unit" }

      func(event)
    },
    [deferrable, preventDefault, stopPropagation],
  )

  return [handleKeyUp, deferUntilKeyUp]
}

/**
 * Prepares a callback which stops propagation of a mouse event and prevents
 * double click behavior when a rapid succession of clicks brings the
 * underlying input into focus.
 */
export function useStandardCellInputMouseDownHandler(inputRef: InputRefObject) {
  const eventCountersRef = useRef({ mouseDown: 0 })
  const handleMouseDown = useCallback(
    (event: React.MouseEvent | MouseEvent) => {
      event.stopPropagation()

      const { current: eventCounters } = eventCountersRef

      // Only prevent default behavior if the event is >2 ahead of our internal
      // counter.
      const nextCounter = eventCounters.mouseDown + 1
      if (event.detail > nextCounter) event.preventDefault()

      // We only bump our internal counters if the mousedown occurs while the
      // underlying input is focused. If it's not focused, return now so we
      // can prevent the default behavior of the next mousedown assuming it
      // occurs in rapid succession.
      if (document.activeElement !== inputRef.current) return

      // Once here, set the internal counter to the value encapsulated by the
      // event to preserve default behavior going forward.
      eventCounters.mouseDown = event.detail
    },
    [eventCountersRef, inputRef],
  )

  return handleMouseDown
}
