import classNames from "classnames"
import React, { useImperativeHandle, useRef } from "react"

import { Maybe } from "types/graphql.d"
import { CursorConnection, NodeRow } from "v2/react/components/orgChart/Datasheet/types"

import { useStandardCellInputMouseDownHandler } from "./hooks"

type EditableTextCellProps<TNode> = {
  className?: string
  currentValue?: string
  cursorConnection: CursorConnection
  isFirst: boolean
  isLast: boolean
  row: NodeRow<TNode>
  style: React.CSSProperties
}

/**
 * A vanilla text input with props and behavior to support the spreadsheet.
 *
 * @public
 */
function EditableTextCell<TNode>({
  className,
  currentValue,
  cursorConnection,
  isFirst,
  isLast,
  row,
  style,
}: EditableTextCellProps<TNode>) {
  const inputRef = useRef<HTMLInputElement>(null)
  const handleMouseDown = useStandardCellInputMouseDownHandler(inputRef)

  useImperativeHandle(
    cursorConnection.cellInputRef,
    () => ({
      blur: () => inputRef.current?.blur(),
      focus: (initial?: string) => {
        if (inputRef.current && initial) inputRef.current.value = initial ?? ""
        inputRef.current?.focus()
      },
      getValue: () => inputRef.current?.value ?? "",
    }),
    [inputRef],
  )

  // NO-OP if value does not change (to preserve user tab surfing).
  const handleBlur: React.FocusEventHandler<HTMLInputElement> = (ev) => {
    if (ev.currentTarget.value === currentValue) return
    cursorConnection.saveWrite(ev.currentTarget.value, { transitionKeyCanMove: true })
  }

  const wrapperClass = classNames(className, "GridBody-cell", "EditableTextCell", {
    last: isLast,
    "--color-coded": isFirst && row.color,
  })

  return (
    <div className={wrapperClass} style={prepareStyle(style, isFirst, row.color)}>
      <input
        className="EditableTextCell__input"
        defaultValue={currentValue}
        // We set the id to editable-content under the assumption that there is
        // only ever one selected cell at a time, hence there is only one
        // #editable-content in the DOM.
        id="editable-content"
        onBlur={handleBlur}
        onKeyDown={cursorConnection.keyDownListenerWhileWriting}
        onKeyUp={cursorConnection.keyUpListenerWhileWriting}
        onMouseDown={handleMouseDown}
        ref={inputRef}
        type="text"
      />
    </div>
  )
}

const prepareStyle = (style: React.CSSProperties, isFirst: boolean, color?: Maybe<string>) => {
  // Avoid building a new hash if we aren't going to add any info.
  if (!isFirst || !color) return style

  // If this is the first cell, and a color is present, it needs to be shown along
  // the starting border. This color is the result of color coding the cell's row
  // as a whole.
  return { ...style, borderLeftColor: color }
}

export { EditableTextCell, EditableTextCellProps }
