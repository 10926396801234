/* eslint-disable react/jsx-props-no-spreading */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CalendarDate, parseDate } from "@internationalized/date"
import React from "react"
import { AriaButtonProps, Key, usePress } from "react-aria"
import {
  Button as AriaButton,
  Heading as AriaHeading,
  ListBox as AriaListBox,
  ListBoxItem as AriaListBoxItem,
  Popover as AriaPopover,
  Select as AriaSelect,
  SelectValue as AriaSelectValue,
} from "react-aria-components"
import { CalendarState, RangeCalendarState } from "react-stately"

import { CalendarGrid } from "v2/react/shared/forms/DateInputs/shared/Calendar/CalendarGrid"

type OnDateClick = (date: CalendarDate) => void

interface SelectOption {
  id: number
  label: number | string
}

interface CalendarProps {
  state: RangeCalendarState | CalendarState
  title: string
  months: SelectOption[]
  activeMonth: number
  years: SelectOption[]
  activeYear: number
  calendarRef: React.RefObject<HTMLDivElement>
  prevButtonProps: AriaButtonProps<"button">
  nextButtonProps: AriaButtonProps<"button">
  calendarPressProps?: ReturnType<typeof usePress>["pressProps"]
  onDateClick?: OnDateClick
  onSetVisibleRange?: (date: CalendarDate) => void
}

const Calendar = ({
  state,
  title,
  months,
  activeMonth,
  years,
  activeYear,
  calendarRef,
  prevButtonProps,
  nextButtonProps,
  calendarPressProps,
  onDateClick,
  onSetVisibleRange,
}: CalendarProps) => {
  const setVisibleRangeByMonth = (key: Key) => {
    const date = new Date(Number(activeYear), Number(key), 1).toISOString().split("T")[0]
    onSetVisibleRange?.(parseDate(date))
  }

  const setVisibleRangeByYear = (key: Key) => {
    const date = new Date(Number(key), Number(activeMonth), 1).toISOString().split("T")[0]
    onSetVisibleRange?.(parseDate(date))
  }

  return (
    <div {...calendarPressProps} ref={calendarRef} className="calendar">
      <header className="items-center justify-between border-b-neutral-8 py-3 flex">
        <AriaButton
          {...prevButtonProps}
          className="focus-visible:border--focus w-6 rounded-lg bg-transparent text-center"
        >
          <FontAwesomeIcon icon={["far", "chevron-left"]} />
        </AriaButton>
        <AriaHeading title={title}>
          <div className="items-center gap-2 flex">
            <VisibleRangeSelector
              ariaLabel="Select Month"
              items={months}
              onChange={setVisibleRangeByMonth}
              selectedKey={activeMonth}
            />
            <VisibleRangeSelector
              ariaLabel="Select Year"
              items={years}
              onChange={setVisibleRangeByYear}
              selectedKey={activeYear}
            />
          </div>
        </AriaHeading>
        <AriaButton
          {...nextButtonProps}
          className="focus-visible:border--focus w-6 rounded-lg bg-transparent text-center"
        >
          <FontAwesomeIcon icon={["far", "chevron-right"]} />
        </AriaButton>
      </header>
      <CalendarGrid state={state} onDateClick={onDateClick} />
    </div>
  )
}

interface RangeSelectorProps {
  ariaLabel: string
  items: SelectOption[]
  onChange: (key: Key) => void
  selectedKey: Key
}

function VisibleRangeSelector({ ariaLabel, items, onChange, selectedKey }: RangeSelectorProps) {
  return (
    <AriaSelect
      onSelectionChange={onChange}
      selectedKey={selectedKey}
      aria-labelledby={ariaLabel}
      className="Select select--xs"
    >
      <AriaButton className="min-w-[3.5rem] items-center justify-between gap-2 !px-2 !py-0 !flex">
        <AriaSelectValue />
        <FontAwesomeIcon icon={["fas", "caret-down"]} />
      </AriaButton>
      <AriaPopover className="elevation !max-h-[17rem] overflow-y-auto rounded-lg bg-white p-2">
        <AriaListBox>
          {items.map((item) => (
            <AriaListBoxItem
              id={item.id}
              key={item.id}
              textValue={item.label.toString()}
              className="box-border w-full cursor-pointer items-center gap-x-1.5 rounded bg-transparent px-3 py-2.5 flex hover:bg-neutral-3"
            >
              <p className="mr-auto">{item.label}</p>
              {item.id === selectedKey ? (
                <FontAwesomeIcon icon={["fas", "check-circle"]} className="selected h-3.5 w-3.5" />
              ) : (
                <div className="w-3.5" />
              )}
            </AriaListBoxItem>
          ))}
        </AriaListBox>
      </AriaPopover>
    </AriaSelect>
  )
}

export { Calendar }
export type { OnDateClick }
