import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { ChangeEvent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDebounce } from "usehooks-ts"

interface Props {
  onChange: (value: string) => void
  onClear: () => void
  placeholder?: string
  value: string
}

function SearchInput({ onChange, onClear, placeholder, value }: Props) {
  const { t } = useTranslation()
  const [internalValue, setInternalValue] = useState(value || "")
  const debouncedValue = useDebounce(internalValue, 350)

  useEffect(() => {
    onChange(debouncedValue)
  }, [debouncedValue, onChange])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInternalValue(e.target.value)
  }

  const handleClear = () => {
    setInternalValue("")
    onClear()
  }

  return (
    <div className="input-group relative mb-0">
      <input
        className="input suffix-pad prefix-pad min-w-14"
        placeholder={placeholder || `${t("v2.defaults.search")}...`}
        onChange={handleChange}
        value={internalValue}
      />
      {internalValue.length > 0 && (
        <FontAwesomeIcon
          icon={["far", "times"]}
          className="suffix icon-10 cursor-pointer"
          onClick={handleClear}
        />
      )}
      <FontAwesomeIcon icon={["far", "search"]} className="prefix icon-14" />
    </div>
  )
}

export { SearchInput }
