import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React, { useRef } from "react"
import { AriaButtonProps, useButton } from "react-aria"

interface ButtonProps extends AriaButtonProps<"button"> {
  isActive: boolean
  isDisabled?: boolean
  showBorderOnFocus: boolean
  onClick?: () => void
}

/* eslint-disable react/jsx-props-no-spreading */
function QuickSelectButton({
  isActive,
  isDisabled,
  showBorderOnFocus,
  onClick,
  ...props
}: ButtonProps) {
  const ref = useRef(null)
  const { buttonProps } = useButton(
    {
      ...props,
      onPress: (e) => {
        if (isDisabled) return

        onClick?.()
        if (!isActive) {
          props.onPress?.(e)
        }
      },
    },
    ref,
  )

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <button
      {...buttonProps}
      ref={ref}
      disabled={isDisabled}
      className={cn(
        "menu-trigger border--main focus-visible:border--focus relative -left-0.5 -mr-0.5 rounded-r-lg px-3 ease-in-out focus:z-2",
        isActive ? "bg-neutral-3" : "bg-white",
        {
          "focus:border--focus": showBorderOnFocus,
          "hover:border--main-hover": !isDisabled,
          "disabled !bg-neutral-3": isDisabled,
        },
      )}
      type="button"
    >
      <FontAwesomeIcon icon={["fas", "caret-down"]} />
    </button>
  )
}

export { QuickSelectButton }
