import classNames from "classnames"
import { isNil } from "lodash"
import React, { useState } from "react"

import { InputWrapper } from "v2/react/shared/forms/InputWrapper"
import { safeNumber } from "v2/react/utils/safeNumber"

import { useWithReactHookFormRegister } from "./hooks/useWithReactHookFormHooks"
import { UseInReactHookFormProp } from "./types"

interface NumericInputProps {
  defaultValue?: string | undefined | number
  disabled?: boolean
  errors?: string
  id?: string
  inputClassName?: string
  label?: string
  max?: string
  min?: string
  name?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  readOnly?: boolean
  showErrorMessage?: boolean
  step?: string
  useAttentionState?: boolean
  useInReactHookForm?: UseInReactHookFormProp
  wrapperClassName?: string
}

type NumericInputWithoutWrapperProps = Omit<
  NumericInputProps,
  "inputClassName" | "label" | "wrapperClassName" | "useAttentionState" | "errors" | "label"
> & {
  className?: string
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  value?: string | number
}

const NumericInputWithoutWrapper = ({
  className,
  defaultValue,
  disabled,
  id,
  max,
  min,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  readOnly,
  step = "any",
  useInReactHookForm,
  value,
}: NumericInputWithoutWrapperProps) => (
  <input
    type="number"
    id={id}
    name={name}
    className={classNames("numeric", "input", className)}
    disabled={disabled}
    min={min}
    max={max}
    step={step}
    defaultValue={defaultValue}
    onFocus={onFocus}
    onBlur={onBlur}
    onChange={onChange}
    placeholder={placeholder}
    readOnly={readOnly}
    value={value}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...useWithReactHookFormRegister({ useInReactHookForm, name })?.(name ?? "", {
      setValueAs: (value) => safeNumber(value, { fallback: null }),
    })}
  />
)

const NumericInput = ({
  defaultValue,
  disabled,
  errors,
  id,
  inputClassName,
  label,
  max,
  min = "0",
  name,
  onChange,
  placeholder = "",
  readOnly,
  showErrorMessage = true,
  step = "any",
  useAttentionState = false,
  useInReactHookForm,
  wrapperClassName,
}: NumericInputProps) => {
  const [isFocused, setIsFocused] = useState(false)
  const [inputValue, setInputValue] = useState<string>(isNil(defaultValue) ? "" : `${defaultValue}`)

  const showAttention = useAttentionState && !isFocused && !errors && !inputValue.trim()

  const handleFocus = () => setIsFocused(true)
  const handleBlur = () => setIsFocused(false)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    onChange?.(e)
  }

  return (
    <InputWrapper
      label={label}
      errors={errors}
      showErrorMessage={showErrorMessage}
      id={id}
      className={classNames(wrapperClassName, {
        attention: showAttention,
      })}
      name={name}
      useInReactHookForm={useInReactHookForm}
    >
      <NumericInputWithoutWrapper
        className={classNames("numeric input", inputClassName)}
        defaultValue={isNil(defaultValue) ? "" : `${defaultValue}`}
        disabled={disabled}
        id={id}
        max={max}
        min={min}
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        placeholder={placeholder}
        readOnly={readOnly}
        step={step}
        useInReactHookForm={useInReactHookForm}
      />
    </InputWrapper>
  )
}

export { NumericInput, NumericInputWithoutWrapper }
