import React from "react"

import {
  useFieldLabelHelpers,
  useIsFieldUneditable,
} from "v2/react/components/positions/positionForm/hooks"
import {
  LocationInput,
  OrgUnitInputs,
  PositionImportanceInput,
} from "v2/react/components/positions/positionForm/inputs"
import { usePositionFormContext } from "v2/react/components/positions/positionForm/PositionFormProvider"
import { NumericInput, SelectWithWrapper, TextInput } from "v2/react/shared/forms"

function PositionInfoTab() {
  const { tLabel } = useFieldLabelHelpers()
  const {
    positionFormCollections: { eeocClassifications, employeeTypes, flsaClassifications },
  } = usePositionFormContext()
  const isEeocUneditable = useIsFieldUneditable("eeoc_classification")
  const isFlsaUneditable = useIsFieldUneditable("flsa_classification")

  return (
    <div id="position-info-tab" className="tab-panel p-4">
      <OrgUnitInputs wrapperClassName="mb-4" />

      <div className="grid-cols-3 gap-4 grid">
        <LocationInput />
        <PositionImportanceInput wrapperClassName="!mb-4" />
      </div>

      <div className="grid-cols-3 gap-4 grid">
        <SelectWithWrapper
          defaultValue
          id="position_employee_type_id"
          label={tLabel("position.employeeTypeId")}
          options={employeeTypes}
          name="position.employeeTypeId"
          wrapperClassName="!mb-4"
          useInReactHookForm
        />
        <NumericInput
          id="position_fte"
          name="position.fte"
          label={tLabel("position.fte")}
          wrapperClassName="!mb-4"
          useInReactHookForm
        />
        <SelectWithWrapper
          defaultValue
          disabled={isFlsaUneditable}
          label={tLabel("position.flsaClassification")}
          id="position_flsa_classification"
          name="position.flsaClassification"
          options={flsaClassifications}
          wrapperClassName="!mb-4"
          useInReactHookForm
        />
      </div>

      <SelectWithWrapper
        defaultValue
        disabled={isEeocUneditable}
        label={tLabel("position.eeocClassification")}
        id="position_eeoc_classification"
        name="position.eeocClassification"
        options={eeocClassifications}
        wrapperClassName="!mb-4"
        useInReactHookForm
      />

      <div className="grid-cols-2 gap-4 grid">
        <TextInput
          className="mb-0"
          label={tLabel("position.companyCode")}
          id="position_company_code"
          name="position.companyCode"
          useInReactHookForm
        />
        <TextInput
          className="mb-0"
          label={tLabel("position.externalIdentifier")}
          id="position_external_identifier"
          name="position.externalIdentifier"
          useInReactHookForm
        />
      </div>
    </div>
  )
}

export { PositionInfoTab }
