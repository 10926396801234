import { useCallback, useState } from "react"
import { useBoolean } from "usehooks-ts"

import { useDatasheetContext } from "v2/react/components/headcountPlanning/TableDatasheet/context"
import { transitionTableCursor } from "v2/redux/slices/DatasheetSlice"
import { selectCursor } from "v2/redux/slices/DatasheetSlice/cursor/cursorSelectors"
import {
  inEitherWriteState,
  writingOnEditableCell,
  writingOnEditableCellNeedsFollowUp,
} from "v2/redux/slices/DatasheetSlice/cursor/cursorStates"
import { CellCursor, CursorState } from "v2/redux/slices/DatasheetSlice/cursor/types"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

import { TransitionKeys } from "./cursorKeyMovements"
import { createMovedCursor } from "./moveCursor"
import { extractMoveAfterTo } from "./useCellHandlers"
import { Cell } from "./useCellState"

type FollowUpHandlersArg = {
  cell: Cell
  inputRef?: React.RefObject<HTMLInputElement>
  revertChanges?: () => void
  shouldFollowUpBeforeCellExit: boolean
}

/**
 * Provides handler functions, and a flag, for handling follow up interactions
 * with a cell so hotkeys and state transitions function smoothly.
 */
export function useFollowUpHandlers({
  cell,
  inputRef,
  revertChanges,
  shouldFollowUpBeforeCellExit,
}: FollowUpHandlersArg) {
  const [nextCursorAfterCompletedFollowUp, setNextCursor] = useState<CellCursor | null>(null)
  const { setTrue: enterFollowUp, setFalse: exitFollowUp, value: isInFollowUp } = useBoolean(false)

  const { nextFromCurrent, nextFromKeyboardEvent, transitionCursor } = useCursorTargets(cell)

  const cursor = useAppSelector(selectCursor)
  const isWritingOnCell =
    inEitherWriteState(cursor) && cursor.columnId === cell.columnId && cursor.rowId === cell.rowId

  const requireFollowUp = useCallback(() => {
    if (!isWritingOnCell) return
    if (writingOnEditableCellNeedsFollowUp(cursor)) return

    transitionCursor({ ...cursor, state: CursorState.WritingOnEditableNeedsFollowUp })
  }, [cursor, isWritingOnCell, transitionCursor])
  const doNotRequireFollowUp = useCallback(() => {
    if (!isWritingOnCell) return
    if (writingOnEditableCell(cursor)) return

    transitionCursor({ ...cursor, state: CursorState.WritingOnEditable })
  }, [cursor, isWritingOnCell, transitionCursor])
  const exitFollowUpWithCleanUp = useCallback(() => {
    exitFollowUp()
    setNextCursor(null)
  }, [exitFollowUp])

  /**
   * Utility function that can be used to intercept keydown events in order to
   * begin the follow up process (when applicable). If this will begin the
   * follow up process, it squelches the event.
   */
  const maybeBeginFollowUpFromKeyboardEvent: React.KeyboardEventHandler = useCallback(
    (ev) => {
      const matchTransition = TransitionKeys.matchEvent(ev.nativeEvent)
      const writingAndShouldFollowUp = isWritingOnCell && shouldFollowUpBeforeCellExit
      if (matchTransition && writingAndShouldFollowUp && !isInFollowUp) {
        ev.preventDefault()
        ev.stopPropagation()

        setNextCursor(nextFromKeyboardEvent(ev))
        enterFollowUp()
      }
    },
    [
      enterFollowUp,
      isWritingOnCell,
      isInFollowUp,
      nextFromKeyboardEvent,
      shouldFollowUpBeforeCellExit,
    ],
  )

  const beginFollowUp = useCallback(() => {
    // If we don't have a cell to transition to after follow up, set it to the
    // current cell.
    if (!nextCursorAfterCompletedFollowUp) setNextCursor(nextFromCurrent())

    inputRef?.current?.blur()
    enterFollowUp()
  }, [enterFollowUp, inputRef, nextFromCurrent, nextCursorAfterCompletedFollowUp])
  const cancelFollowUp = useCallback(() => {
    revertChanges?.()
    exitFollowUpWithCleanUp()
    if (nextCursorAfterCompletedFollowUp) transitionCursor(nextCursorAfterCompletedFollowUp)
  }, [exitFollowUpWithCleanUp, nextCursorAfterCompletedFollowUp, revertChanges, transitionCursor])
  const completeFollowUp = useCallback(() => {
    exitFollowUpWithCleanUp()
    if (nextCursorAfterCompletedFollowUp) transitionCursor(nextCursorAfterCompletedFollowUp)
  }, [exitFollowUpWithCleanUp, transitionCursor, nextCursorAfterCompletedFollowUp])

  return {
    requireFollowUp,
    doNotRequireFollowUp,
    beginFollowUp,
    cancelFollowUp,
    completeFollowUp,
    exitFollowUp: exitFollowUpWithCleanUp,
    isInFollowUp,
    maybeBeginFollowUpFromKeyboardEvent,
  }
}

/**
 * Helps with determining a suitable cell that we can transition to. Written
 * with follow up interactions in mind.
 */
export const useCursorTargets = (cell: Cell) => {
  const datasheetContext = useDatasheetContext()
  const appDispatch = useAppDispatch()
  const cursor = useAppSelector(selectCursor)

  const nextFromCurrent: () => CellCursor = useCallback(
    () =>
      inEitherWriteState(cursor)
        ? { ...cursor, state: CursorState.OnEditableTransitionNext }
        : cursor,
    [cursor],
  )

  const nextFromKeyboardEvent = useCallback(
    (event: React.KeyboardEvent) => {
      const cursorIfInWrite =
        inEitherWriteState(cursor) &&
        cursor.columnId === cell.columnId &&
        cursor.rowId === cell.rowId
          ? cursor
          : undefined
      const nativeEvent = "nativeEvent" in event ? event.nativeEvent : event
      if (!cursorIfInWrite) return cursor

      const options = extractMoveAfterTo(cursorIfInWrite, nativeEvent)
      if (!options) return cursor

      return createMovedCursor(cursor, datasheetContext.table, options.moveAfterTo)
    },
    [cursor, cell, datasheetContext],
  )

  const transitionCursor = useCallback(
    (nextCursor: CellCursor) => appDispatch(transitionTableCursor(nextCursor)),
    [appDispatch],
  )

  return { nextFromCurrent, nextFromKeyboardEvent, transitionCursor }
}
