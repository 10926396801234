import React from "react"
import { useTranslation } from "react-i18next"

import { FeatureFlags, Option } from "types/graphql"
import { BuilderContainer } from "v2/react/components/careerLadders/BuilderContainer"
import Interest from "v2/react/components/opportunities/Show/Interest"
import { PeopleList } from "v2/react/components/opportunities/Show/PeopleList"
import { Requirements } from "v2/react/components/opportunities/Show/Requirements"
import { Responsibilities } from "v2/react/components/opportunities/Show/Responsibilities"
import { Summary } from "v2/react/components/opportunities/Show/Summary"
import RootProvider from "v2/react/components/RootProvider"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import PageNav from "v2/react/shared/navigation/PageNav"
import { TitleBlock, TitleHeaderWithParent } from "v2/react/shared/navigation/PageNav/TitleBlock"
import { maybeGetIDFromUniqueKey } from "v2/react/utils/uniqueKey"
import { UrlHelper } from "v2/react/utils/urls"
import { useGetFeatureFlagsQuery } from "v2/redux/GraphqlApi"
import { useGetPositionTypeDetailsQuery } from "v2/redux/GraphqlApi/PositionTypesApi"

interface ShowProps {
  careerLadderUniqueKey?: string
  favorited: boolean
  positionTypeUniqueKey: string
  positionTypeTitle: string
}

function WithProvider({
  careerLadderUniqueKey,
  favorited,
  positionTypeUniqueKey,
  positionTypeTitle,
}: ShowProps) {
  const { t } = useTranslation()
  const { data: positionTypeResult, isLoading } = useGetPositionTypeDetailsQuery({
    id: positionTypeUniqueKey,
  })
  const { data, isFetching } = useGetFeatureFlagsQuery()

  if (
    !data ||
    !data?.currentCompany?.featureFlags ||
    isFetching ||
    isLoading ||
    !positionTypeResult ||
    !positionTypeResult?.positionType
  )
    return <Spinner />

  const ff: FeatureFlags = data.currentCompany?.featureFlags

  const skillTags = (
    positionTypeResult.positionType.tags?.filter((tag) => tag.tagDomain.name === "skills") || []
  ).map((skill) => ({ label: skill.value, ...skill })) as Option[]
  const characteristicsTags = (
    positionTypeResult.positionType.tags?.filter(
      (tag) => tag.tagDomain.name === "worker_characteristics",
    ) || []
  ).map((characteristic) => ({ label: characteristic.value, ...characteristic })) as Option[]

  return (
    <>
      <PageNav>
        <TitleBlock>
          <TitleHeaderWithParent
            parentTitle={t("v2.opportunities.title")}
            parentTitleUrl={UrlHelper.opportunitiesRootPath()}
            title={positionTypeTitle}
          />
        </TitleBlock>
      </PageNav>
      <div className="page-pad">
        <div className="grid-cols-1 grid lg:grid-cols-[2fr_1fr] lg:gap-8">
          <div>
            <div className="lg:hidden">
              <Interest
                favorited={favorited}
                positionTypeId={maybeGetIDFromUniqueKey(positionTypeUniqueKey) || ""}
              />
            </div>
            <Summary summary={positionTypeResult.positionType.summary || ""} />
            <Requirements skillTags={skillTags} characteristicsTags={characteristicsTags} />
            <Responsibilities
              responsibilities={positionTypeResult.positionType.tasksAndResponsibilities || ""}
            />
          </div>
          <div>
            <div className="hidden lg:block">
              <Interest favorited={favorited} positionTypeId={positionTypeUniqueKey} />
            </div>
            <PeopleList positionType={positionTypeResult.positionType} />
            {(ff.positionManagement || ff.successionPlanning) && (
              <div className="is-radial group">
                <BuilderContainer
                  careerLadderUniqueKey={careerLadderUniqueKey || ""}
                  positionTypeUniqueKey={positionTypeUniqueKey || ""}
                  smallMode
                  positionAsLink
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

function Show({
  careerLadderUniqueKey,
  favorited,
  positionTypeUniqueKey,
  positionTypeTitle,
}: ShowProps) {
  return (
    <RootProvider>
      <WithProvider
        careerLadderUniqueKey={careerLadderUniqueKey}
        favorited={favorited}
        positionTypeUniqueKey={positionTypeUniqueKey}
        positionTypeTitle={positionTypeTitle}
      />
    </RootProvider>
  )
}

export { Show }
