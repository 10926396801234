import fp from "lodash/fp"
import { z } from "zod"

type Error = {
  path?: string[] | readonly (string | number)[] | null
  message: string
}

const emptyErrorHash: { [key: string]: string } = {}

export function errorFor(name: string, errors?: Error[]) {
  return errors?.find((error) => error.path?.[0] === name)?.message
}

export function normalizeErrors(maybeErrors?: unknown) {
  const parseResult = gqlErrorsArraySchema.safeParse(maybeErrors)
  if (!parseResult.success) return []

  const { data: errors } = parseResult

  return errors.map(({ message, path }) => ({ error: message, path: path ?? undefined }))
}

/**
 * Converts an array of errors from a GQL endpoint into a hash of messages
 * keyed by field; if necessary, nests.
 */
export function errorHash(maybeErrors?: unknown, startingFromPath: string[] = []) {
  const parseResult = gqlErrorsArraySchema.safeParse(maybeErrors)
  if (!parseResult.success) return {}

  const { data: errors } = parseResult

  const byMatchingPath = fp.matches({ path: startingFromPath })
  const intoErrorHash = (memo: { [key: string]: string }, error: Error) => {
    const errorPath = fp.without(startingFromPath, error.path)
    return fp.isEmpty(errorPath) ? memo : fp.set(errorPath, error.message, memo)
  }

  const selectMatchingErrors = fp.filter<Error>(byMatchingPath)
  const reduceIntoErrorHash = fp.reduce(intoErrorHash, emptyErrorHash)

  return fp.pipe(selectMatchingErrors, reduceIntoErrorHash)(errors)
}

const gqlErrorsSchema = z
  .object({
    __typename: z.literal("Error").optional(),
    message: z.string(),
    details: z.any().optional().nullable(),
    location: z.array(z.string()).optional().nullable(),
    path: z.array(z.string()).optional().nullable(),
  })
  .passthrough()
const gqlErrorsArraySchema = z.array(gqlErrorsSchema)
