import fp from "lodash/fp"

/**
 * @example
 * ```ts
 * codeNameFromInputString("") //=> { code: undefined, name: undefined }
 * codeNameFromInputString("foo") //=> { code: undefined, name: "foo" }
 * codeNameFromInputString("foo -") //=> { code: undefined, name: "foo" }
 * codeNameFromInputString("foo - bar") //=> { code: "foo", name: "bar" }
 * codeNameFromInputString("foo - bar - baz") //=> { code: "foo", name: "bar - baz" }
 *
 * // IMPORTANT - priority always given to name:
 * codeNameFromInputString("- bar") // => { code: undefined, name: "bar" }
 * ```
 */
export function codeNameFromInputString(inputString: string) {
  const parts = fp.split("-", inputString)
  const head = fp.pipe(fp.head, fp.trim)(parts)
  const tail = fp.pipe(fp.tail, fp.join("-"), fp.trim)(parts)

  if (head === "" && tail === "") return { code: undefined, name: undefined }
  if (head === "") return { code: undefined, name: tail }
  if (tail === "") return { code: undefined, name: head }

  return { code: head, name: tail }
}

/**
 * Builds a function that parses a code+name and transforms the result as
 * necessary.
 *
 * @example
 * ```ts
 * const quickFn = makeCodeNameFromInputStringFn(({ name, code }) => ({
 *   code,
 *   title: name
 * }))
 *
 * quickFn("") //=> { code: undefined, title: undefined }
 * quickFn("foo - baz") //=> { code: "foo", title: "baz" }
 * ```
 */
export const makeCodeNameFromInputStringFn =
  <T>(mapFn: (arg: { code?: string; name?: string }) => T) =>
  (inputString: string) =>
    mapFn(codeNameFromInputString(inputString))

/**
 * Builds a normalized code+name label/string from a hash of attributes; empty
 * or blank strings are treated as NULL values; leading and trailing whitespace
 * is trimmed; empty values results in an empty string.
 *
 * @example
 * ```ts
 * codeNameLabelFromAttributes({ code: "Code", name: "Name" }) //=> "Code - Name"
 * codeNameLabelFromAttributes({ name: "Name" }) //=> "Name"
 * codeNameLabelFromAttributes({ code: "Code" }) //=> "Code"
 * codeNameLabelFromAttributes({ code: "  ", name: "Name" }) //=> "Name"
 * codeNameLabelFromAttributes({ code: " Code ", name: " Label "}) //=> "Code - Label"
 * ```
 */
export const codeNameLabelFromAttributes = ({
  code,
  name,
}: {
  code?: string | null
  name?: string | null
}) => {
  const trimmedCode = code?.trim() ?? ""
  const trimmedName = name?.trim() ?? ""

  if (trimmedCode === "") return trimmedName
  if (trimmedName === "") return trimmedCode
  return `${trimmedCode} - ${trimmedName}`
}
