import { Meta } from "@storybook/react"
import React from "react"

import { RemovePositionModal } from "v2/react/components/orgChart/ProfilePanel/PositionHeader/RemovePositionModal"
import RootProvider from "v2/react/components/RootProvider"

const meta: Meta<typeof RemovePositionModal> = {
  title: "Components/RemovePositionModal",
  component: RemovePositionModal,
  decorators: [
    (Story) => (
      <RootProvider>
        <Story />
      </RootProvider>
    ),
  ],
}
export default meta

export const NoChildren = {
  args: {
    editingDisabled: true,
    isHeadcountPlanner: true,
    isOfficialChart: true,
    isOpen: true,
    hasChildren: false,
  },
}

export const WithChildren = {
  args: {
    editingDisabled: true,
    isHeadcountPlanner: true,
    isOfficialChart: true,
    isOpen: true,
    hasChildren: true,
  },
}
