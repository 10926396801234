import { useCallback } from "react"

import { FeatureFlags } from "types/graphql"
import { Role } from "types/graphql.enums"
import { SessionActiveState, SessionState } from "v2/redux/slices/SessionSlice"
import { useAppSelector } from "v2/redux/store"

type EnhancedSessionState<SessionKind extends SessionState> = SessionKind & {
  hasFeature: (featureFlag: keyof FeatureFlags) => boolean
  isLimitedAdmin: boolean
  isOfficialChartKey: (chartKey: string) => boolean
}

/**
 * Provides the current session state and some extras e.g. feature checking.
 */
function useCurrentSession(): EnhancedSessionState<SessionState> {
  const sessionState = useAppSelector((state) => state.session)

  return useWithEnhancements(sessionState)
}

/**
 * Provides the current session state with stricter types given the session's
 * active status. Generally safe for use on authenticated pages besides "Choose
 * Company".
 *
 * @see useCurrentSession
 */
function useCurrentActiveSession(): EnhancedSessionState<SessionActiveState> {
  const sessionState = useAppSelector((state) => state.session)

  if (!isSessionActiveState(sessionState)) {
    const message = `
      Cannot access a non-active session. An active session has both a current
      user and person. Current session status is "${sessionState.status}".
    `
    throw new Error(message)
  }

  return useWithEnhancements(sessionState)
}

/** @private */
function useWithEnhancements<SessionKind extends SessionState>(sessionState: SessionKind) {
  const { featureFlags, officialChartKey, roleScopes } = sessionState
  const isLimitedAdmin = roleScopes.some(({ roleName }) => roleName === Role.LimitedAdmin)
  const isOfficialChartKey = useCallback(
    (chartKey: string) => chartKey === officialChartKey,
    [officialChartKey],
  )
  const hasFeature = useCallback(
    (featureFlag: keyof FeatureFlags) => Boolean(featureFlags?.[featureFlag]),
    [featureFlags],
  )

  return { ...sessionState, isLimitedAdmin, isOfficialChartKey, hasFeature }
}

/** @private */
const isSessionActiveState = (state: SessionState): state is SessionActiveState =>
  state.status === "active"

export { useCurrentActiveSession, useCurrentSession }
