const opaqueScaleTransition = {
  duration: 0.2,
  ease: [0.645, 0.045, 0.355, 1],
}

const opaqueScale = {
  hidden: {
    opacity: 0,
    scale: 0.9,
    transition: opaqueScaleTransition,
  },
  shown: {
    opacity: 1,
    scale: 1,
    transition: opaqueScaleTransition,
  },
}

const opaqueScaleDelayTransition = {
  duration: 0.2,
  delay: 0.1,
  ease: [0.645, 0.045, 0.355, 1],
}

const opaqueScaleDelay = {
  hidden: {
    opacity: 0,
    scale: 0.9,
    transition: opaqueScaleDelayTransition,
  },
  shown: {
    opacity: 1,
    scale: 1,
    transition: opaqueScaleDelayTransition,
  },
}

const opaqueDelay = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.2,
      ease: [0.645, 0.045, 0.355, 1],
    },
  },
  shown: {
    opacity: 1,
    transition: {
      duration: 0.2,
      delay: 0.3,
      ease: [0.645, 0.045, 0.355, 1],
    },
  },
}

const opaqueBasic = {
  hidden: {
    opacity: 0,
  },
  shown: {
    opacity: 1,
  },
}

export { opaqueBasic, opaqueDelay, opaqueScale, opaqueScaleDelay }
