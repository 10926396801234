import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import { useUpdatePersonHeadcountPlanningSettingsMutation } from "v2/redux/GraphqlApi/HeadcountPlanningApi"

type GroupByToggleButtonProps = {
  on: boolean | undefined
  setOn: React.Dispatch<React.SetStateAction<boolean | undefined>>
}
export function GroupByToggleButton({ on, setOn }: GroupByToggleButtonProps) {
  const [mutate] = useUpdatePersonHeadcountPlanningSettingsMutation()
  const { t } = useTranslation()

  const toggle = () => {
    setOn((on) => !on)
    mutate({
      input: { settings: { showGroupByDropzone: !on } },
    })
  }

  return (
    <div>
      <button
        className={classNames(
          "tab btn sm:btn--large btn--secondary sm:btn--secondary tooltip metrics-toggle w-8 sm:w-10",
          {
            "metrics-active": on,
            "!bg-neutral-3": on,
          },
        )}
        data-action="toggle-metrics"
        onClick={() => toggle()}
        type="button"
      >
        <FontAwesomeIcon icon={["far", "layer-group"]} />
        <span className="tooltip-content tooltip-content--sm">
          {t(`v2.headcount_plan.utility_nav.${on ? "hide" : "show"}_group_by`)}
        </span>
      </button>
    </div>
  )
}
