import { EntityId } from "@reduxjs/toolkit"

import type { Maybe } from "types/graphql"
import type { FieldType } from "v2/react/components/orgChart/Datasheet/types"
import type { Direction } from "v2/react/utils/enums"
import type { FieldKey } from "v2/redux/slices/NodeSlice/types"

export enum CursorState {
  /** Cursor is on nothing. */
  Unit = "Unit",
  /** Cursor is on an editable cell. */
  OnEditable = "OnEditable",
  /** Cursor is on an editable cell, prefer transition to new cell over write. */
  OnEditableTransitionNext = "OnEditableTransitionNext",
  /** Cursor is on a non-editable cell. */
  OnNonEditable = "OnNonEditable",
  /** Cursor is writing changes to an editable cell. */
  WritingOnEditable = "WritingOnEditable",
  /** Cursor is writing changes to an editable cell (with an initial value). */
  WritingOnEditableWithInitial = "WritingOnEditableWithInitial",
}

export type CellCursor =
  | CursorUnit
  | CursorOnNonEditableCell
  | CursorOnEditableCell
  | CursorOnEditableCellTransitionNext
  | CursorWritingOnEditableCell
  | CursorWritingOnEditableCellWithInitial

export interface CursorUnit {
  state: CursorState.Unit
}

export interface CursorOnNonEditableCell extends ActiveCursor {
  editable: false
  fieldType: undefined
  state: CursorState.OnNonEditable
}

export interface CursorOnEditableCell extends ActiveCursor {
  editable: true
  fieldType: FieldType
  state: CursorState.OnEditable
}

export interface CursorOnEditableCellTransitionNext extends ActiveCursor {
  editable: true
  fieldType: FieldType
  state: CursorState.OnEditableTransitionNext
}

export interface CursorWritingOnEditableCell extends ActiveCursor {
  editable: true
  fieldType: FieldType
  state: CursorState.WritingOnEditable
}

export interface CursorWritingOnEditableCellWithInitial extends ActiveCursor {
  editable: true
  fieldType: FieldType
  initial: string
  state: CursorState.WritingOnEditableWithInitial
}

interface ActiveCursor {
  currentValue: string | null
  enteredBy: "movement" | "placement" | "transition"
  fieldKey: FieldKey
  rowId: EntityId
  state: CursorState
}

export type CursorIndices = [number, number] | [undefined, undefined]

export type CursorEventDetail = {
  cursorIndices: CursorIndices
  cursor: CellCursor
  originalCursorIndices: CursorIndices
  originalCursor: CellCursor
}

export type EndWriteWithCursorPayload = { transitionKeyCanMove?: boolean }
export type BeginWriteWithCursorPayload = { clobber?: true | { withValue: Maybe<string> } }
export type MoveCursorPayload = {
  direction: Direction
  updateCurrentValue?: { with: string | null }
}

export type PlaceOrTransitionCursorPayload = {
  rowId: EntityId
  fieldKey: FieldKey
}
