import { usePositionWatchField } from "./useConvenientReactHookFormHooks"

/**
 * Indicates whether the field is a controlled attribute and uneditable.
 *
 * @example
 * ```ts
 * useIsFieldUneditable("custom_field_1") //=> true
 * useIsFieldUneditable("description") //=> false
 * ```
 */
function useIsFieldUneditable(fieldKey: string) {
  const positionType = usePositionWatchField({ name: "position.positionType" })

  return Boolean(positionType?.uneditablePositionTypeControlledAttributeKeys?.includes(fieldKey))
}

export { useIsFieldUneditable }
