import React, { ReactNode } from "react"

interface Props {
  children: ReactNode
}

function LinkBlock({ children }: Props) {
  return <div className="link-block">{children}</div>
}

function LinkBlockXSmall({ children }: Props) {
  return <div className="link-block-xs !px-0">{children}</div>
}

function LinkBlockSmall({ children }: Props) {
  return <div className="link-block-sm">{children}</div>
}

function LinkBlockLarge({ children }: Props) {
  return <div className="link-block-lg">{children}</div>
}

export { LinkBlock, LinkBlockXSmall, LinkBlockSmall, LinkBlockLarge }
