import React, { useEffect } from "react"
import { useDispatch } from "react-redux"

import { SaveChartApproverStatusInput } from "types/graphql.d"
import { ApprovalActionModal } from "v2/react/components/approvals/ApprovalActionModal"
import { ApprovalActionType } from "v2/react/shared/status/statusUtils"
import { pjaxReload } from "v2/react/utils/pjax"
import { useSaveApproverStatusMutation } from "v2/redux/GraphqlApi/ChartApprovalsApi"
import {
  setSelectedAction,
  setTempSelectedAction,
  toggleDropdown,
  toggleStatusModal,
} from "v2/redux/slices/ApprovalSlice"
import {
  selectChartId,
  selectCurrentAction,
  selectCurrentTempAction,
  selectStatusModal,
} from "v2/redux/slices/ApprovalSlice/approvalSelectors"
import { useAppSelector } from "v2/redux/store"

interface Props {
  submitterName: string | null | undefined
}

function ChartApprovalActionModal({ submitterName }: Props) {
  const [saveChartApproverStatus] = useSaveApproverStatusMutation()
  const chartId = useAppSelector(selectChartId) as number
  const isOpen = useAppSelector(selectStatusModal)
  const currentAction = useAppSelector(selectCurrentAction)
  const tempAction = useAppSelector(selectCurrentTempAction)

  const dispatch = useDispatch()

  useEffect(() => {
    if (tempAction) {
      dispatch(toggleDropdown(false))
    }
  }, [tempAction, dispatch])

  const onClose = (action: ApprovalActionType) => {
    dispatch(setSelectedAction(action))
    dispatch(toggleStatusModal(false))
    dispatch(setTempSelectedAction(null))
  }

  const onCloseNoSave = () => {
    if (currentAction) {
      dispatch(setSelectedAction(currentAction))
    } else {
      dispatch(setSelectedAction(null))
    }
    dispatch(toggleStatusModal(false))
    dispatch(setTempSelectedAction(null))
  }

  const onSave = (status: ApprovalActionType, note: string) => {
    const input: SaveChartApproverStatusInput = {
      chartId: chartId.toString(),
      status,
      note,
    }

    const saveApproverStatus = async () => {
      const result = await saveChartApproverStatus({ input }).unwrap()
      if (result.saveChartApproverStatus?.chart) {
        onClose(input.status as ApprovalActionType)
        dispatch(toggleDropdown(false))

        pjaxReload({
          url: "/charts",
          container: "[data-pjax-container='charts']",
        })
      }
      return (result.saveChartApproverStatus?.errors as Error[]) || []
    }

    return saveApproverStatus()
  }

  return (
    <ApprovalActionModal
      approvable={"chart".t("org_chart")}
      isOpen={isOpen}
      onSave={onSave}
      onCloseNoSave={onCloseNoSave}
      selectedAction={tempAction}
      submitterName={submitterName}
    />
  )
}

export { ChartApprovalActionModal }
