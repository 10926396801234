import classNames from "classnames"
import React, { ChangeEvent, CSSProperties, KeyboardEvent, useEffect } from "react"
import { defaultMemoize } from "reselect"

import { Maybe } from "types/graphql.d"
import { Column, NodeRow } from "v2/react/components/orgChart/Datasheet/types"
import { useAutoComplete } from "v2/react/hooks/useAutocomplete"
import { Response, useAutocompleteSearch } from "v2/react/hooks/useAutocompleteSearch"
import { DropdownMenu } from "v2/react/shared/collection/menus/DropdownMenu"

interface TextCompleteProps<TNode, T, R> {
  row: NodeRow<TNode>
  column: Column<TNode>
  isFirst: boolean
  isLast: boolean
  noBorder?: boolean
  style: CSSProperties
  html: string | null
  focusCell: boolean
  onCellChange: (row: NodeRow<TNode>, column: Column<TNode>, value: Maybe<string>) => Promise<void>
  omitValueField?: keyof R
  dataFetchFn: (search: string) => Promise<Response<T>>
  mapToDataFn: (data: Response<T>) => R[]
}

function TextComplete<TNode, T, R>({
  row,
  column,
  isFirst,
  isLast,
  noBorder,
  style,
  html,
  focusCell,
  onCellChange,
  omitValueField,
  dataFetchFn,
  mapToDataFn,
}: TextCompleteProps<TNode, T, R>) {
  const {
    showResultList,
    setShowResultList,
    inputValue,
    setInputValue,
    listResult,
    handleInputChange,
  } = useAutocompleteSearch({
    omitValues: html ? [html] : [],
    fieldValue: html || "",
    omitValueField,
    dataFetchFn,
    mapToDataFn,
  })

  const {
    activeIndex,
    setActiveIndex,
    listRef,
    refs,
    floatingStyles,
    context,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
  } = useAutoComplete({ showList: showResultList, setShowList: setShowResultList })

  useEffect(() => {
    if (focusCell) {
      refs.domReference.current?.focus()
    } else {
      refs.domReference.current?.blur()
    }
  }, [focusCell, refs])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    handleInputChange(event)

    if (value) {
      setShowResultList(true)
      setActiveIndex(0)
    } else {
      setShowResultList(false)
    }
  }

  const handleBlur = (item: string) => {
    setActiveIndex(null)
    setShowResultList(false)
    onCellChange(row, column, item)
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && activeIndex != null && listResult[activeIndex]) {
      const item = listResult[activeIndex]
      const newValue = item["name" as keyof typeof item] as string
      setInputValue(newValue)
      handleBlur(newValue)
    }
  }

  const handleResultClick = (item: string) => {
    setInputValue(item)
    handleBlur(item)
  }

  return (
    <>
      <input
        style={prepareStyle(style, isFirst, row.color, noBorder)}
        className={nodeClassName(isLast)}
        aria-autocomplete="list"
        value={inputValue}
        ref={refs.setReference}
        /* eslint-disable react/jsx-props-no-spreading */
        {...getReferenceProps({
          onKeyDown: handleKeyDown,
          onChange: handleChange,
        })}
      />
      <DropdownMenu
        showList={showResultList && !!inputValue.trim().length}
        floatingRef={refs.setFloating}
        floatingStyles={{
          ...floatingStyles,
          minHeight: listResult.length > 0 ? "2.75rem" : "0",
        }}
        floatingProps={getFloatingProps}
        wrapperClasses="TextField"
        context={context}
      >
        {listResult && listResult.length > 0
          ? listResult.map((item, index) => (
              <div
                role="option"
                data-id={item["id" as keyof typeof item] as string}
                data-name={item["name" as keyof typeof item] as string}
                aria-selected={activeIndex === index}
                key={item["id" as keyof typeof item] as string}
                className={textClassName(activeIndex, index)}
                ref={(node) => {
                  listRef.current[index] = node
                }}
                /* eslint-disable react/jsx-props-no-spreading */
                {...getItemProps({
                  onClick: () => handleResultClick(item["name" as keyof typeof item] as string),
                })}
              >
                <div className="TextField__result-text truncate">
                  {item["name" as keyof typeof item] as string}
                </div>
              </div>
            ))
          : null}
      </DropdownMenu>
    </>
  )
}

export { TextComplete }

const nodeClassName = (isLast: boolean) =>
  classNames("GridBody-cell bg-transparent", { last: isLast })
const textClassName = (activeIndex: number | null, index: number) =>
  classNames("TextField__result", { highlight: activeIndex === index })

const prepareStyle = defaultMemoize((style, isFirst, color?: Maybe<string>, noBorder?: boolean) => {
  const base = color && isFirst ? { ...(style || {}), borderLeft: `5px solid ${color}` } : style
  if (noBorder) {
    return {
      ...base,
      borderBottomWidth: 0,
      borderRightWidth: 0,
      borderTopWidth: 0,
      borderLeftWidth: 0,
      zIndex: 11,
    }
  }
  return { ...base, zIndex: 11 }
})
