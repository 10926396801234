import { useLayoutEffect, useRef, useState } from "react"

/* 
  https://stackoverflow.com/questions/65807408/update-ui-when-useref-div-width-changes
  This seemed like a good approach for handling the width of the page navs
  The ref can be set on one element and the width can then be used to adjust another
*/

export const useObserveElement = <T extends HTMLElement>() => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const ref = useRef<T>(null)

  useLayoutEffect(() => {
    const currentRef = ref.current
    const observer = new ResizeObserver((entries) => {
      setWidth(entries[0].contentRect.width)
      setHeight(entries[0].contentRect.height)
    })

    if (currentRef) {
      observer.observe(currentRef)
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef)
      }
    }
  }, [])

  return { height, width, ref }
}
