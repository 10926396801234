import React, { FormEvent, useState } from "react"
import { useTranslation } from "react-i18next"

import { Error, JobLevel, JobLevelCreateInput } from "types/graphql"
import { JobLevelForm } from "v2/react/components/jobLevels/Index/JobLevelForm"
import { Modal } from "v2/react/shared/overlay/Modal"
import { useJobLevelCreateMutation } from "v2/redux/GraphqlApi/JobLevelApi"

interface Props {
  isOpen: boolean
  jobLevel?: JobLevel
  onClose: () => void
}

function CreateModal({ isOpen, jobLevel, onClose }: Props) {
  const { t } = useTranslation()
  const [errors, setErrors] = useState<Error[] | undefined | null>([])
  const [codeError, setCodeError] = useState("")
  const [mutate, { isLoading }] = useJobLevelCreateMutation()

  const handleClose = () => {
    setErrors([])
    setCodeError("")
    onClose()
  }

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()

    const form = event.target as HTMLFormElement
    const formData = new FormData(form)
    const input: JobLevelCreateInput = {
      code: formData.get("code"),
      name: formData.get("name"),
      order: Number(formData.get("order")?.toString() || ""),
    } as JobLevelCreateInput

    const result = await mutate(input).unwrap()

    if (result.jobLevelCreate?.jobLevel?.id) {
      handleClose()
    } else if (result.jobLevelCreate?.errors) {
      setErrors(result.jobLevelCreate?.errors)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} title={t("v2.job_levels.index.add_job_level")}>
      <JobLevelForm
        codeError={codeError}
        errors={errors}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        jobLevel={jobLevel}
        setCodeError={setCodeError}
      />
    </Modal>
  )
}

export { CreateModal }
