import { Maybe } from "graphql/jsutils/Maybe"
import { isNil } from "lodash"

type ParseCurrencyOptions = {
  currency?: string
  locale?: string
}

const isBlankString = (maybeString: Maybe<string>) =>
  typeof maybeString === "string" && maybeString.trim() === ""

const NumberOrZero = (value: string | number) => {
  if (typeof value === "number") return value

  // See https://camchenry.com/blog/parsefloat-vs-number for a decent table
  // that compares `parseFloat` with `Number`.
  //
  // ASSUMPTION: Restricting valid input to base 10 numbers is ok.
  const number = parseFloat(value)
  return Number.isNaN(number) ? 0.0 : number
}

/**
 * Parses a string or number into a number.
 * @param value - The value to parse.
 * @returns The parsed number or 0.00 if the value is not a number.
 * @public
 * */
const parseCurrency = (value: string | number, options?: ParseCurrencyOptions) => {
  if (typeof value === "number") return value

  const Helpers = (window.App || {}).Helpers || {}
  const maybeParsed =
    Helpers.parseCurrency && !isBlankString(value) ? Helpers.parseCurrency(value, options) : value
  return NumberOrZero(maybeParsed)
}

interface FormatCurrencyProps {
  value: number
  omitSymbol?: boolean
  trailing?: boolean
}

/**
 * Formats a number into a currency string.
 * @param value - The value to format.
 * @param omitSymbol - Whether to omit the currency symbol.
 * @param trailing - Whether to place the currency symbol at the end of the string.
 * @returns The formatted currency string, or the input value if no helper is
 *   defined on the window.
 * @public
 * */
const formatCurrency = ({ value, omitSymbol, trailing }: FormatCurrencyProps) => {
  const Helpers = (window.App || {}).Helpers || {}
  const maybeFormatted: string | number = Helpers.formatCurrency
    ? Helpers.formatCurrency(value, { omitSymbol, trailing })
    : value
  return typeof maybeFormatted === "string" ? maybeFormatted : maybeFormatted.toString()
}

const prepareCurrencyValue = ({
  value,
  omitSymbol = true,
}: {
  value: string | number | undefined | null
  omitSymbol?: boolean
}) =>
  !isNil(value)
    ? formatCurrency({
        value: parseCurrency(value),
        omitSymbol,
        trailing: true,
      })
    : undefined

export { parseCurrency, formatCurrency, prepareCurrencyValue, ParseCurrencyOptions }
