import { createEntityAdapter } from "@reduxjs/toolkit"
import { Recipe } from "@reduxjs/toolkit/dist/query/core/buildThunks"
import { Mutex } from "async-mutex"
import fp from "lodash/fp"
import { normalizeDynamicFields } from "org_chart/chart/utils/relationalNodeDataStore/dynamicFields"

import {
  ChartNodeCurrentCompensationQuery,
  ChartNodeCurrentCompensationQueryVariables,
  Error as GqlError,
  Maybe,
  NodeInterface,
  PersonPositionNodeChangedPayload,
  PositionTypeControlledAttributesWithValuesQuery,
  PositionTypeControlledAttributesWithValuesQueryVariables,
  UpdatePersonPositionNodeInput,
  UpdatePersonPositionNodeMutation,
  UpdatePersonPositionNodePayload,
} from "types/graphql"
import { Change } from "types/graphql.enums"
import { gqlFetch } from "v2/graphql_client"
import OperationStore from "v2/operation_store"
import { GraphqlApi } from "v2/redux/GraphqlApi"
import {
  markNodeIdForDeletion,
  nodeChangesetLoaded,
  nodeUpdateBegin,
  nodeUpdateFail,
  removeNode,
} from "v2/redux/slices/NodeSlice"
import { prepareOptimisticChanges } from "v2/redux/slices/NodeSlice/mutations/prepareOptimisticChanges"
import {
  makeNodeChangeset,
  pickChanges,
} from "v2/redux/slices/NodeSlice/nodeHelpers/nodeChangesets"
import { selectGqlQueryArgForNodeChangedSubscription } from "v2/redux/slices/NodeSlice/nodeQuerySelectors"
import {
  FetchPageOfNodesArg,
  InfiniteNodeListApi,
  NodeApiBuilder,
  PagedNodeState,
  QueryResultType,
  ServerBroadcast,
  UpdateInlineAttributeInput,
  UpdateNodeApi,
} from "v2/redux/slices/NodeSlice/types"
import { AppDispatch, RootState } from "v2/redux/store"

const ChartNodeCurrentCompensation = OperationStore.getOperationId("ChartNodeCurrentCompensation")

type ErrMatch = (err: GqlError) => boolean
const matchBaseError: ErrMatch = fp.propEq(["path"], ["updatePersonPositionNode"])
const matchCustomFieldValue: ErrMatch = fp.matches({ path: ["attributes", "custom_field_values"] })
const matchVariablePay: ErrMatch = fp.matches({ path: ["attributes", "variable_pays"] })
const matchOrgUnit: ErrMatch = fp.pipe(fp.propOr("", ["path", 1]), fp.isMatch(/position_org_unit/))
const hasLockVersionError: (
  payload: Pick<UpdatePersonPositionNodePayload, "errors">,
) => boolean = ({ errors }) =>
  fp.any((err) => fp.isEqual(err.path, ["attributes", "lock_version"]), errors || [])

// Set up an entity adapter for person-position nodes. It provides a normalized
// data structure and simple selectors and actions for reading/updating state.
const nodeAdapter = createEntityAdapter<NodeInterface>({
  selectId: (node: NodeInterface) => node.id,
})
const emptyInfo = { pageInfo: null, priorPages: [], nextCursor: [] }
const initialState = nodeAdapter.getInitialState({ info: emptyInfo })
const fixedInfiniteListCacheKey = Object.freeze({
  operationName: "NodeContainerPeopleAndPositionsConnection",
  query: "<CACHE_KEY>",
  variables: { key: "<CACHE_KEY>" },
})

const patchMutex = new Mutex()

/**
 * Provides endpoints for fetching an infinite list of nodes in pages
 * (fetchPageOfNodes), fetching an individual node (fetchNode), and updating a
 * node (updatePersonPositionNode).
 *
 * @public
 */
const NodeApi = GraphqlApi.enhanceEndpoints({ addTagTypes: ["Node"] }).injectEndpoints({
  endpoints: (builder) => ({
    fetchPageOfNodes: buildFetchPageOfNodesEndpoint(builder),
    updatePersonPositionNode: buildUpdatePersonPositionNode(builder),
    fetchPositionTypeControlledAttributesWithValues: builder.query<
      PositionTypeControlledAttributesWithValuesQuery,
      PositionTypeControlledAttributesWithValuesQueryVariables
    >({
      query: (params) => ({
        operationId: OperationStore.getOperationId("PositionTypeControlledAttributesWithValues"),
        variables: params,
      }),
    }),
    fetchCurrentCompensationOfNode: builder.query<
      ChartNodeCurrentCompensationQuery,
      ChartNodeCurrentCompensationQueryVariables
    >({
      query: (params) => ({
        operationId: ChartNodeCurrentCompensation,
        variables: params,
      }),
      providesTags: (result, error) =>
        result && !error ? [{ type: "Node", id: result.nodeContainer?.node?.id }] : [],
    }),
  }),
})

// Internal selectors for use within the NodeApi
const selectors = nodeAdapter.getSelectors<PagedNodeState>(fp.identity)
const selectInfiniteListState = NodeApi.endpoints.fetchPageOfNodes.select(fixedInfiniteListCacheKey)
const nodeSelectors = nodeAdapter.getSelectors(
  (state: RootState) => selectInfiniteListState(state).data || initialState,
)

// Utils
const { util } = NodeApi
const { updateQueryData } = util
const nodeStateUpdates = (updateRecipe: Recipe<PagedNodeState>) =>
  updateQueryData("fetchPageOfNodes", fixedInfiniteListCacheKey, updateRecipe)

// -- Builders

/**
 * This uses some unusual functionality to support an "infinite" list. The
 * linked SO answer provides a better overview, but here's a quick rundown:
 *
 * - Pages are merged into the same cache entry. The default behavior would put
 *   these in their own entry (see serializeQueryArgs and merge).
 * - The default behavior determines if a fetch is executed by cache key. This
 *   is overridden to use the pagination cursor (see forceRefetch).
 *
 * We use the same cache entry since the calling component only retains a
 * "subscription" to the latest cache entry. This leads to a problem where
 * prior data is ejected after some time, causing the rows to disappear in the
 * UI.
 *
 * @see https://stackoverflow.com/a/74844699
 * @see https://redux-toolkit.js.org/rtk-query/api/createApi#serializequeryargs-1
 */
function buildFetchPageOfNodesEndpoint(builder: NodeApiBuilder) {
  return builder.query<PagedNodeState, FetchPageOfNodesArg>({
    providesTags: [{ type: "Node", id: "list" }],
    query: (queryOptions) => queryOptions,
    serializeQueryArgs: () => fixedInfiniteListCacheKey,
    forceRefetch: ({ currentArg, endpointState }) =>
      shouldFetchNextPageOfNodes(currentArg, endpointState?.data as PagedNodeState),
    transformResponse: transformPageOfNodesResponse,
    merge: mergeNextPageOfNodes,
    onCacheEntryAdded: syncFromServerChangesWhileEntryIsAdded,
  })
}

/**
 * Builds an endpoint that updates a person position node optimistically (it rolls back failed
 * updates).
 */
function buildUpdatePersonPositionNode(builder: NodeApiBuilder) {
  return builder.mutation<UpdatePersonPositionNodeMutation, UpdateInlineAttributeInput>({
    queryFn: async (arg, api) => {
      await patchMutex.waitForUnlock()
      const releaseMutex = await patchMutex.acquire()
      const stateBefore = api.getState() as RootState
      const nodeBefore = nodeSelectors.selectById(stateBefore, arg.id)
      const priorLockVersion: number | undefined = nodeBefore?.lock_version
      const finalArg: UpdatePersonPositionNodeInput = {
        id: arg.id,
        attributes: {
          ...arg.attributes,
          lock_version: priorLockVersion || arg.attributes.lock_version || 0,
        },
      }

      try {
        const result = await gqlFetch<UpdatePersonPositionNodeMutation, GqlError[]>({
          operationId: OperationStore.getOperationId("UpdatePersonPositionNode"),
          variables: { input: finalArg },
        })

        // RTK Query expects a return result of { error: <BLAH> }
        if (result.errors) return { error: result.errors }

        // If the lock version > the current lock version, always store that.
        const nextLockVersion = result.data.updatePersonPositionNode.node?.lock_version
        const lockVersionBump = nodeStateUpdates((state) =>
          nodeAdapter.updateOne(state, { id: arg.id, changes: { lock_version: nextLockVersion } }),
        )
        if (nextLockVersion && priorLockVersion && nextLockVersion > priorLockVersion)
          api.dispatch(lockVersionBump)
        else if (nextLockVersion && hasLockVersionError(result.data.updatePersonPositionNode))
          api.dispatch(lockVersionBump)
        else if (nextLockVersion && !priorLockVersion) api.dispatch(lockVersionBump)

        return { data: result.data }
      } finally {
        releaseMutex()
      }
    },
    onQueryStarted: applyOptimisticUpdate,
    invalidatesTags: (result: UpdatePersonPositionNodeMutation | undefined) => {
      if (!result?.updatePersonPositionNode.node?.id) return []
      return [{ type: "Node", id: result.updatePersonPositionNode.node.id }]
    },
  })
}

/** @private */
function transformPageOfNodesResponse(response: QueryResultType) {
  const nodes = fp.map(normalizeDynamicFields, response.nodeContainer?.nodePage?.nodes || [])
  const pageInfo = response.nodeContainer?.nodePage?.pageInfo || null
  const { hasNextPage, endCursor } = pageInfo || {}

  return nodeAdapter.setAll(
    {
      ...initialState,
      info: {
        pageInfo,
        priorPages: [],
        nextCursor: hasNextPage && endCursor ? endCursor : (false as string | false),
      },
    },
    nodes,
  )
}

/** @private */
function mergeNextPageOfNodes(current: PagedNodeState, incoming: PagedNodeState) {
  const priorPages = current.info.priorPages || []
  const nextPriorPages =
    current.info.pageInfo !== null ? [current.info.pageInfo, ...priorPages] : priorPages

  return fp.pipe(
    (next: PagedNodeState) => fp.set(["info", "priorPages"], nextPriorPages, next),
    (next: PagedNodeState) => fp.set(["info", "pageInfo"], incoming.info.pageInfo, next),
    (next: PagedNodeState) => fp.set(["info", "nextCursor"], incoming.info.nextCursor, next),
    (next: PagedNodeState) => nodeAdapter.upsertMany(next, selectors.selectAll(incoming)),
  )(current)
}

/** @private */
function shouldFetchNextPageOfNodes(queryArg?: FetchPageOfNodesArg, currentState?: PagedNodeState) {
  // When this is the first fetch, return true.
  if (!currentState) return true

  if (!currentState.info.nextCursor) return false
  if (currentState.info.nextCursor !== queryArg?.variables.endCursor) return false

  return true
}

/**
 * Subscribes to the chart's (or list's) ActionCable channel in order to push
 * real time changes emitted by the server into the infinite list.
 *
 * This subscribes once an initial request for a batch of the infinite list is
 * made. The initiation adds a "cache entry" in rtk lingo. If/when rtk-query
 * ejects the infinite list from the cache/state, this unsubscribes from the
 * ActionCable channel.
 *
 * @private
 */
async function syncFromServerChangesWhileEntryIsAdded(
  arg: FetchPageOfNodesArg,
  api: InfiniteNodeListApi,
) {
  const containerKey = arg.variables.key
  if (!containerKey) return

  // Wait for the initial query to resolve before establishing a socket
  // connection. This will throw if the request fails or is aborted. We don't
  // need to handle this, rtk-query uses it to prevent memory leaks and clean
  // up.
  await api.cacheDataLoaded
  const unsubscribe = subscribeToNodeEventsFromServer(api.getState, api.dispatch)

  // Blocks until the infinite list is removed from the cache/state.
  await api.cacheEntryRemoved
  unsubscribe()
}

let consumer: Maybe<ActionCable.Cable> = null

function subscribeToNodeEventsFromServer(
  getState: InfiniteNodeListApi["getState"],
  dispatch: AppDispatch,
) {
  consumer = consumer || window.ActionCable.createConsumer("/cable")
  const sub = consumer.subscriptions.create("GraphqlChannel", {
    connected() {
      this.perform("execute", selectGqlQueryArgForNodeChangedSubscription(getState() as RootState))
    },
    received: (message: ServerBroadcast<PersonPositionNodeChangedPayload>) => {
      const matchCreated: (type: string) => boolean = fp.eq(Change.Create)
      const matchUpdated: (type: string) => boolean = fp.eq(Change.Update)
      const matchDeleted: (type: string) => boolean = fp.eq(Change.Destroy)
      const matchHandled = fp.anyPass([matchCreated, matchDeleted, matchUpdated])
      const data = message.result.data?.personPositionNodeChanged
      if (!data) return

      const id = data.nodeId
      if (typeof id !== "string" || !matchHandled(data.changeType)) return

      const incomingNode = data.node
      if (!incomingNode && !matchDeleted(data.changeType)) return

      // Once here, we know the message has a supported type + id.
      const currentPersonId = (getState() as RootState).session.currentPersonId
      const bySelf = currentPersonId === data.triggeredBy?.id

      // Handle a server event indicating a given person-position node was
      // deleted. We handle deletions caused by the current user differently in
      // order to provide the UI a chance to finish any flows or animations.
      if (bySelf && matchDeleted(data.changeType)) {
        dispatch(markNodeIdForDeletion(id))
        return
      }
      if (matchDeleted(data.changeType)) {
        dispatch(removeNode(id))
        return
      }

      if (!incomingNode) return

      const node = normalizeDynamicFields(incomingNode)
      if (typeof id !== "string" || !matchHandled(data.changeType)) return

      const before = nodeSelectors.selectById(getState() as RootState, id)
      const lockVersion = node.lock_version
      if (before && matchUpdated(data.changeType)) {
        dispatch(
          nodeStateUpdates((state) =>
            nodeAdapter.upsertOne(state, { ...before, lock_version: lockVersion }),
          ),
        )
      }

      // If we receive an update event, and it doesn't have keys, it was likely
      // due to a change in lock_version. Update if relevant, but otherwise
      // return.
      //
      // A possible optimization could expand on this, bailing after updating
      // the lock version, if none of the changed fields affect the user's
      // session.
      const changeKeys = data.changedAttributes || []
      if (matchUpdated(data.changeType) && changeKeys.length === 0) return

      if (matchUpdated(data.changeType)) {
        const updates = nodeStateUpdates((state) =>
          nodeAdapter.updateOne(state, { id: node.id, changes: pickChanges(changeKeys, node) }),
        )
        dispatch(updates)
      } else {
        const inserts = nodeStateUpdates((state) => nodeAdapter.setOne(state, node))
        dispatch(inserts)
      }

      const after = nodeSelectors.selectById(getState() as RootState, id)
      if (!after) return

      // Due to optimistic updates, fields the current user changed may not get
      // detected in the `changeset`. Pass the server keys explicitly since
      // making it here signals success.
      const changeset = makeNodeChangeset(data, after, before)
      const forgetFields = bySelf ? changeKeys : []
      dispatch(nodeChangesetLoaded({ changeset, bySelf, forgetFields }))
    },
  })

  return sub.unsubscribe
}

async function applyOptimisticUpdate(arg: UpdateInlineAttributeInput, api: UpdateNodeApi) {
  if (arg.cancelOptimisticUpdate) return

  const replacePathWithPrimaryTrigger = fp.set("path", ["attributes", arg.primaryTrigger])
  const deletePath = fp.unset("path")
  const withNormalizedPath = fp.cond([
    [matchCustomFieldValue, replacePathWithPrimaryTrigger],
    [matchOrgUnit, replacePathWithPrimaryTrigger],
    [matchVariablePay, replacePathWithPrimaryTrigger],
    [matchBaseError, deletePath],
    [fp.always(true), fp.identity],
  ]) as (err: GqlError) => GqlError

  // Signal that an update is beginning so any auxiliary state can be cleaned.
  api.dispatch(nodeUpdateBegin(arg))

  // Apply an optimistic update to the node data so the change reflects
  // immediately. The result of this is captured with `patch`, which
  // provides `patch.undo()` to rollback the change if desired.
  const stateBefore = api.getState() as RootState
  const nodeBefore = nodeSelectors.selectById(stateBefore, arg.id)
  const updateAttrs = { id: arg.id, changes: prepareOptimisticChanges(nodeBefore, arg.attributes) }
  const optimisticChange = nodeStateUpdates((state) => nodeAdapter.updateOne(state, updateAttrs))
  const patch = api.dispatch(optimisticChange)

  try {
    // Await the server's response to the mutation. The mutation may fail
    // without throwing in some cases (e.g. validation errors).
    const fullResult = await api.queryFulfilled
    const mutationResult = fullResult.data.updatePersonPositionNode
    if (mutationResult && mutationResult.errors && mutationResult.errors.length > 0) {
      const normalizedErrors = fp.map(withNormalizedPath, mutationResult.errors)
      api.dispatch(nodeUpdateFail({ id: arg.id, errors: normalizedErrors }))
    }
  } catch (error: unknown) {
    // Try to unwrap an expected error (the { error: ... } returned by
    // buildUpdatePersonPositionNode. If successful, capture the error and
    // return.
    if (error && typeof error === "object") {
      const errorHash = error as { error?: GqlError[] }
      if (errorHash.error && Array.isArray(errorHash.error)) {
        const normalizedErrors = fp.map(withNormalizedPath, errorHash.error)
        api.dispatch(nodeUpdateFail({ id: arg.id, errors: normalizedErrors }))
        return
      }
    }

    // If here, the error is truly unexpected. Capture the error for Sentry and
    // show the user an appropriate fallback.
    if (typeof window !== "undefined" && typeof window.Sentry !== "undefined")
      window.Sentry.captureException(error)

    patch.undo()
    api.dispatch(
      nodeUpdateFail({
        id: arg.id,
        errors: [withNormalizedPath({ message: "generic_error".t("org_chart", "datasheet") })],
      }),
    )
  }
}

const updatePagedNodeState = (
  dispatch: AppDispatch,
  withRecipe: (adapter: typeof nodeAdapter) => Recipe<PagedNodeState>,
) => dispatch(nodeStateUpdates(withRecipe(nodeAdapter)))

export { NodeApi, fixedInfiniteListCacheKey, nodeAdapter, nodeSelectors, updatePagedNodeState }
export const {
  useFetchPageOfNodesQuery,
  useUpdatePersonPositionNodeMutation,
  useFetchCurrentCompensationOfNodeQuery,
  useFetchPositionTypeControlledAttributesWithValuesQuery,
} = NodeApi
