/* eslint-disable no-param-reassign */
import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit"
import fp from "lodash/fp"

import { RootState } from "../store"
import { buildId, cellReducer, machine } from "./DatasheetSlice/cellStateMachines"
import { cursorUnit } from "./DatasheetSlice/cursor/cursorActions"
import { transitionValid } from "./DatasheetSlice/cursor/cursorStates"
import { translateCellStateToRedux, translateReduxToCellState } from "./DatasheetSlice/translators"
import { CellEvent, CellState, DatasheetErrorSet, DatasheetState } from "./DatasheetSlice/types"

const errorsAdapter = createEntityAdapter<DatasheetErrorSet>()
const cellsAdapter = createEntityAdapter<CellState>()

const initialState: DatasheetState = {
  cursor: cursorUnit,
  errors: errorsAdapter.getInitialState(),
  cells: cellsAdapter.getInitialState(),
}

const internalErrorSelectors = errorsAdapter.getSelectors((state: DatasheetState) => state.errors)
const internalCellSelectors = cellsAdapter.getSelectors((state: DatasheetState) => state.cells)

const DatasheetSlice = createSlice({
  name: "datasheet",
  initialState,
  reducers: {
    transitionTableCursor: (state, { payload: next }: PayloadAction<DatasheetState["cursor"]>) =>
      transitionValid(state.cursor, next) ? fp.assign(state, { cursor: next }) : state,
    updateCellState: (state, { payload: event }: PayloadAction<CellEvent>) => {
      const storedCellState = internalCellSelectors.selectById(state, buildId(event))
      const cellState = translateReduxToCellState(storedCellState, event, state.cursor)
      const newCellState = cellReducer(cellState, event, machine)

      const translated = translateCellStateToRedux(
        newCellState,
        state.cursor,
        internalErrorSelectors.selectById(state, event.rowId),
      )

      if (translated.storedCellState) {
        cellsAdapter.setOne(state.cells, translated.storedCellState)
      } else {
        cellsAdapter.removeOne(state.cells, translated.id)
      }

      state.cursor = translated.cursor

      if (translated.errorSet) {
        errorsAdapter.setOne(state.errors, translated.errorSet)
      } else {
        errorsAdapter.removeOne(state.errors, event.rowId)
      }
    },
  },
})

const errorSetSelectors = errorsAdapter.getSelectors((state: RootState) => state.datasheet.errors)
const cellStateSelectors = cellsAdapter.getSelectors((state: RootState) => state.datasheet.cells)

export const { transitionTableCursor, updateCellState } = DatasheetSlice.actions
export { DatasheetSlice, errorSetSelectors, cellStateSelectors }
