import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

interface ButtonProps {
  active: boolean
  onClick: () => void
}

const EditTableSettingsButton = ({ active, onClick }: ButtonProps) => {
  const { t } = useTranslation()

  return (
    <button
      id="edit-table-settings"
      type="button"
      className={cn("tooltip tooltip-left btn tooltip sm:btn--large btn--secondary w-8 sm:w-10", {
        "is-active !bg-neutral-3": active,
      })}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={["far", "sliders-h"]} />
      <div className="tooltip-content tooltip-content--sm">
        {t("v2.positions.index.edit_settings")}
      </div>
    </button>
  )
}

export { EditTableSettingsButton }
