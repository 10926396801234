import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

interface CloseButtonProps {
  onClose?: () => void
}

const CloseButton = ({ onClose }: CloseButtonProps) => (
  <button type="button" className="close btn--icon btn--ghost" onClick={onClose}>
    <FontAwesomeIcon icon={["far", "times"]} className="my-1" />
  </button>
)

export { CloseButton }
