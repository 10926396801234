import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import type { PaginationState, SortingState } from "@tanstack/react-table"
import fp from "lodash/fp"

import { DEFAULT_PAGE_SIZE } from "v2/react/constants"

interface TableState {
  sorting: SortingState
  pagination: PaginationState
  search: string
}

const InitialState: TableState = {
  sorting: [],
  pagination: {
    pageSize: DEFAULT_PAGE_SIZE,
    pageIndex: 0,
  },
  search: "",
}

/**
 * Use this slice along with the useServerTable hook to manage sorting,
 * pagination, and search state.
 */
const TableSlice = createSlice({
  name: "Table",
  initialState: InitialState,
  reducers: {
    updateSort: (state, { payload }: PayloadAction<SortingState>) =>
      fp.pipe(fp.set("sorting", payload), fp.set("pagination", InitialState.pagination))(state),
    updatePagination: (state, { payload }: PayloadAction<PaginationState>) =>
      fp.set("pagination", payload)(state),
    updateSearch: (state, { payload }: PayloadAction<string>) => fp.set("search", payload)(state),
  },
})

export const { updateSort, updatePagination, updateSearch } = TableSlice.actions
export { TableSlice, TableState }
