import { debounce } from "lodash"
import { useCallback, useMemo, useState } from "react"

export interface Response<T> {
  data: T
}

interface Props<T, R> {
  omitValues: string[]
  fieldValue: string
  omitValueField?: keyof R
  dataFetchFn: (search: string) => Promise<Response<T>>
  mapToDataFn: (data: Response<T>) => R[]
}

function useAutocompleteSearch<T, R>({
  omitValues,
  fieldValue,
  omitValueField: omitField,
  dataFetchFn,
  mapToDataFn,
}: Props<T, R>) {
  const [showResultList, setShowResultList] = useState(false)
  const [inputValue, setInputValue] = useState(fieldValue || "")
  const [listResult, setListResult] = useState<R[]>([])

  const loadResult = useCallback(
    (items: R[]) => {
      if (omitField) {
        const itemsToShow = items.filter(
          (item) => !(omitValues || []).includes(item[omitField] as string),
        )
        setListResult(itemsToShow)
      } else {
        setListResult(items)
      }
    },
    [omitValues, omitField, setListResult],
  )

  const searchItems = useCallback(
    async (searchValue: string) => {
      if (!searchValue || searchValue === "") return

      const result = await dataFetchFn(searchValue)
      const mappedResult = mapToDataFn(result)

      loadResult(mappedResult)

      setShowResultList(true)
    },
    [dataFetchFn, mapToDataFn, loadResult],
  )

  const updateSearch = useCallback(
    (value: string) => {
      if (value === "") setShowResultList(false)
      searchItems(value)
    },
    [searchItems, setShowResultList],
  )

  const handleInputChangeDebounced = useMemo(() => debounce(updateSearch, 350), [updateSearch])

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value)
    handleInputChangeDebounced(e.currentTarget.value)
  }

  return {
    showResultList,
    setShowResultList,
    inputValue,
    setInputValue,
    listResult,
    handleInputChange,
  }
}

export { useAutocompleteSearch }
