import type { Meta, StoryObj } from "@storybook/react"
import React, { ChangeEvent, useState } from "react"
import { useTranslation } from "react-i18next"

import RootProvider from "v2/react/components/RootProvider"

import { PercentRangeInput } from "./PercentRangeInput"

const meta: Meta<typeof PercentRangeInput> = {
  title: "Inputs/PercentRangeInput",
  component: PercentRangeInput,
  decorators: [
    (Story) => (
      <RootProvider>
        <div style={{ margin: "20px 50% 20px 20px" }}>
          <Story />
        </div>
      </RootProvider>
    ),
  ],
}

export default meta

const StateWrapperTemplate = () => {
  const { t } = useTranslation()
  const [minValue, setMinValue] = useState<number>()
  const [maxValue, setMaxValue] = useState<number>()
  const [error, setError] = useState("")

  const handleMinChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value)
    setMinValue(value)
    validate(value, maxValue)
  }

  const handleMaxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value)
    setMaxValue(value)
    validate(minValue, value)
  }

  const validate = (min: number | undefined, max: number | undefined) => {
    if (max && min && min > max) {
      setError(t("v2.errors.messages.lte"))
    } else {
      setError("")
    }
  }

  return (
    <PercentRangeInput
      minId="min"
      minName="min"
      minDefaultValue={minValue}
      minOnChange={handleMinChange}
      minPlaceHolder={t("v2.defaults.min")}
      maxId="max"
      maxName="max"
      maxDefaultValue={maxValue}
      maxOnChange={handleMaxChange}
      maxPlaceHolder={t("v2.defaults.max")}
      error={error}
      fieldLabel="Budgeted Base Pay"
    />
  )
}

type Story = StoryObj<typeof PercentRangeInput>

export const Main: Story = {
  render: () => <StateWrapperTemplate />,
}
