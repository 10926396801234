import { ProfilePanelBoxSetting } from "types/graphql"
import { useGetProfilePlanSettingsQuery } from "v2/redux/GraphqlApi/PeopleApi"

function useSortedBoxes() {
  const { data: ppSettingsData } = useGetProfilePlanSettingsQuery({})

  const sortedBoxes =
    ppSettingsData?.currentPerson?.settings?.profilePanelSettings?.profilePanelSortedBoxes || []
  const allBoxes: ProfilePanelBoxSetting[] =
    ppSettingsData?.currentPerson?.settings?.profilePanelSettings?.profilePanelBoxes || []
  const adminBoxes: ProfilePanelBoxSetting[] =
    ppSettingsData?.currentPerson?.settings?.profilePanelSettings?.profilePanelAdminBoxes || []

  return {
    allBoxes,
    adminBoxes,
    sortedBoxes,
  }
}

export { useSortedBoxes }
