import fp from "lodash/fp"

import { NodeInterface } from "types/graphql"
import { Filter } from "v2/redux/slices/GridSlice/types"
import { nodeProp } from "v2/redux/slices/NodeSlice/nodeHelpers/nodeProps"

/**
 * Prepares a function that accepts a node and returns a value indicating
 * whether it meets the criteria of `filter`.
 *
 * @public
 */
function makePredicate({ fieldKey, term }: Filter) {
  return (node: NodeInterface) => {
    const value = nodeProp(fieldKey, node)

    return Array.isArray(value) ? fp.includes(term, value) : fp.equals(term, value)
  }
}

export { makePredicate }
