import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import type { Option } from "types/graphql.d"

import { useSelectList } from "../../../hooks/useSelectList"
import { DropdownMenu } from "./DropdownMenu"

const listWidth = 216

type MenuOption<T> = Omit<Option, "id"> & { id: T }

type BasicSelectMenuProps<T> = {
  id: string
  className?: string
  options: MenuOption<T>[]
  selectedOptionId: T
  setSelectedOptionId: (optionId: T) => void
}
export function BasicSelectMenu<T extends string = string>({
  id,
  selectedOptionId,
  setSelectedOptionId,
  className,
  options,
}: BasicSelectMenuProps<T>) {
  const [showList, setShowList] = React.useState(false)
  const { refs, floatingStyles, getFloatingProps, context, getReferenceProps, getItemProps } =
    useSelectList({
      showList,
      setShowList,
      offset: { mainAxis: 4 },
      disableSizeMiddleware: true,
    })

  // This is a bit of a hack to ensure focus properly transfers to the input
  // when the dropdown is opened.
  const handleOpen = () => {
    refs.domReference.current?.focus()
  }

  const makeHandleOptionClick = (option: (typeof options)[0]) => () => {
    setSelectedOptionId(option.id)
    setShowList(false)
  }

  const { t } = useTranslation()

  return (
    <div>
      <div className={classNames("select--responsive", className)}>
        <button
          id={id}
          type="button"
          className="truncate"
          ref={refs.setReference}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getReferenceProps({ onClick: handleOpen })}
        >
          {options.find((option) => option.id === selectedOptionId)?.label ||
            t("v2.defaults.select_an_option")}
        </button>
      </div>
      <DropdownMenu
        showList={showList}
        floatingRef={refs.setFloating}
        floatingStyles={{ ...floatingStyles, width: `${listWidth}px` }}
        floatingProps={getFloatingProps}
        context={context}
        wrapperClasses="basic-select-menu"
      >
        {options.map((option) => (
          <li
            key={option.id}
            className="basic-select-menu__item"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getItemProps({
              onClick: makeHandleOptionClick(option),
            })}
          >
            <span>{option.label}</span>
            {selectedOptionId === option.id ? (
              <FontAwesomeIcon icon={["fas", "check-circle"]} className="text-neutral-100" />
            ) : (
              <div />
            )}
          </li>
        ))}
      </DropdownMenu>
    </div>
  )
}
