import { useDebugValue, useMemo } from "react"

import { StringFieldSuggestion, TagsFieldSuggestion } from "types/graphql"

import {
  deriveValue,
  Fallbacks,
  findBestMatchingSuggestion,
  isStringSuggestion,
  isTagsSuggestion,
  mapToStateFlags,
} from "./useTypedFieldWithSuggestion/fieldSuggestionHelpers"
import {
  useActual,
  useFieldSuggestionWithAppliedEvents,
  useStateChangeCallbackEffect,
  useSuggestionErrorsEffect,
} from "./useTypedFieldWithSuggestion/internalHooks"
import { toTag } from "./useTypedFieldWithSuggestion/tagFieldHelpers"
import {
  FieldSuggestion,
  FieldValueWithSuggestionHash,
  HookArg,
  RecordWithSuggestions,
  TagValue,
} from "./useTypedFieldWithSuggestion/types"

const EMPTY_STRING = ""
const EMPTY_SUGGESTIONS: FieldSuggestion[] = []
const EMPTY_TAG_VALUES: TagValue[] = []
const EMPTY_TAGS: string[] = []
const NONE = "none"

/**
 * Builds a hash for a string value and related suggestion.
 */
export function useStringWithFieldSuggestion<Record extends RecordWithSuggestions>({
  addGenerateError,
  getActual,
  getUniqueKey,
  field,
  onStateChange,
  record,
  withEphemeralEventsApplied = false,
  hasAiFeatureFlag = true,
}: HookArg<string, StringFieldSuggestion, Record>): FieldValueWithSuggestionHash<string> {
  const uniqueKey = getUniqueKey(record)
  const suggestions = record.fieldSuggestions ?? EMPTY_SUGGESTIONS
  const existingSuggestion = findBestMatchingSuggestion(suggestions, field, isStringSuggestion)
  const fieldSuggestion = useFieldSuggestionWithAppliedEvents<StringFieldSuggestion>(
    existingSuggestion ?? Fallbacks.stringFieldSuggestion(field),
    uniqueKey,
    withEphemeralEventsApplied,
  )

  const actual = useActual(record, field, getActual)
  const fallback = EMPTY_STRING
  const initiallySuggested = fieldSuggestion?.initializedStringValue ?? fallback
  const suggested = fieldSuggestion?.stringValue ?? null
  const value = deriveValue(actual, initiallySuggested, fallback, fieldSuggestion, hasAiFeatureFlag)

  useDebugValue({ fieldSuggestion, suggestionState: fieldSuggestion?.state ?? NONE })
  useStateChangeCallbackEffect({
    callbackFn: onStateChange?.handler,
    fallback,
    initiallySuggested,
    suggested,
    suggestion: fieldSuggestion ?? null,
    targetStates: onStateChange?.whenChangesTo,
  })
  useSuggestionErrorsEffect({ addGenerateError, uniqueKey, field })

  return {
    actual,
    fallback,
    initiallySuggested,
    suggested,
    value,

    fieldSuggestion,
    suggestionState: fieldSuggestion?.state ?? NONE,

    ...mapToStateFlags(fieldSuggestion ?? null),
  }
}

export function useTagsWithFieldSuggestion<Record extends RecordWithSuggestions>({
  addGenerateError,
  getActual,
  getUniqueKey,
  field,
  onStateChange,
  record,
  withEphemeralEventsApplied = false,
  hasAiFeatureFlag = true,
}: HookArg<TagValue[], TagsFieldSuggestion, Record>): FieldValueWithSuggestionHash<TagValue[]> {
  const uniqueKey = getUniqueKey(record)
  const suggestions = record.fieldSuggestions ?? EMPTY_SUGGESTIONS
  const existingSuggestion = findBestMatchingSuggestion(suggestions, field, isTagsSuggestion)
  const fieldSuggestion = useFieldSuggestionWithAppliedEvents<TagsFieldSuggestion>(
    existingSuggestion ?? Fallbacks.tagsFieldSuggestion(field),
    uniqueKey,
    withEphemeralEventsApplied,
  )

  const actual = useActual(record, field, getActual)
  const fallback = EMPTY_TAG_VALUES
  const tags = fieldSuggestion?.tags ?? EMPTY_TAGS
  const initialized = fieldSuggestion?.initializedTags ?? EMPTY_TAGS
  const initiallySuggested = useMemo(() => initialized.map(toTag), [initialized])
  const suggested = useMemo(() => tags.map(toTag), [tags])
  const value = deriveValue(actual, initiallySuggested, fallback, fieldSuggestion, hasAiFeatureFlag)

  useDebugValue({ fieldSuggestion, suggestionState: fieldSuggestion?.state ?? NONE })
  useStateChangeCallbackEffect({
    callbackFn: onStateChange?.handler,
    fallback,
    initiallySuggested,
    suggested,
    suggestion: fieldSuggestion ?? null,
    targetStates: onStateChange?.whenChangesTo,
  })
  useSuggestionErrorsEffect({ addGenerateError, uniqueKey, field })

  return {
    actual,
    fallback,
    initiallySuggested,
    suggested,
    value,

    fieldSuggestion,
    suggestionState: fieldSuggestion?.state ?? NONE,

    ...mapToStateFlags(fieldSuggestion ?? null),
  }
}
