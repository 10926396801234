import { AnimatePresence } from "framer-motion"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { MotionOpaqueBasic } from "v2/react/components/succession/SuccessionPlanPanel/shared/MotionWrappers"
import { EditDeleteMenu } from "v2/react/components/succession/SuccessionPlanPanel/Summary/EditDeleteMenu"
import { TextInput } from "v2/react/shared/forms/TextInput"
import { WysiwygTextArea } from "v2/react/shared/forms/WysiwygTextArea"
import { Modal, ModalFooter } from "v2/react/shared/overlay/Modal"
import { removeHtmlTagsAndTrim, sanitizeParse } from "v2/react/utils/sanitizeParse"
import { useUpdateSuccessionPlanCandidateMutation } from "v2/redux/GraphqlApi/SuccessionApi"

interface CandidateNoteFormProps {
  candidateId: string
  candidateName?: string
  info: string
  internalCandidate: boolean
  positionId: string
  setShowEditor: React.Dispatch<React.SetStateAction<boolean>>
  showEditor: boolean
}

const CandidateNoteForm = ({
  candidateId,
  candidateName,
  info,
  internalCandidate,
  positionId,
  setShowEditor,
  showEditor,
}: CandidateNoteFormProps) => {
  const { t } = useTranslation()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [outsiderName, setOutsiderName] = useState(candidateName || "")
  const [note, setNote] = useState(info)
  const [mutate] = useUpdateSuccessionPlanCandidateMutation()

  const handleDeleteNote = () => {
    setShowMenu(false)
    setShowConfirmation(true)
  }

  const handleEditNote = () => {
    setShowMenu(false)
    setShowEditor(true)
  }

  const handleSave = () => {
    const trimmedNote = removeHtmlTagsAndTrim(note)

    mutate({
      candidateId,
      positionId,
      additionalInfo: trimmedNote.length > 0 ? note : null,
      outsiderName: internalCandidate ? null : outsiderName,
    })

    if (!trimmedNote.length) {
      setNote("")
    }

    setShowEditor(false)
  }

  const handleConfirmationClose = () => {
    setShowConfirmation(false)
  }

  if (internalCandidate) {
    return (
      <>
        <MotionOpaqueBasic classNames="group/note candidate-note rounded-lg bg-neutral-3 p-3" show>
          <AnimatePresence>
            <MotionOpaqueBasic key="editor" show={showEditor}>
              <div className="flex-col gap-2 flex">
                <WysiwygTextArea
                  defaultValue={note}
                  id={`candidate_${candidateId}_additional_info`}
                  onChange={setNote}
                />
                <div className="justify-end gap-2 flex">
                  <button
                    className="btn btn--secondary"
                    onClick={() => setShowEditor(false)}
                    type="button"
                  >
                    {t("v2.defaults.cancel")}
                  </button>
                  <button className="btn btn--primary" onClick={handleSave} type="button">
                    {t("v2.defaults.save")}
                  </button>
                </div>
              </div>
            </MotionOpaqueBasic>

            <MotionOpaqueBasic key="content" show={info.length > 0 && !showEditor}>
              <div className="note justify-between gap-2 flex">
                <div className="formatted-block">{sanitizeParse(info)}</div>
                <EditDeleteMenu
                  buttonClasses="btn--icon btn--ghost opacity-0 transition-all duration-150 ease-out group-hover/note:opacity-100"
                  deleteButtonText={t("v2.notes.delete.delete_note")}
                  editButtonText={t("v2.notes.edit.edit_note")}
                  onDelete={handleDeleteNote}
                  onEdit={handleEditNote}
                  setShowMenu={setShowMenu}
                  showMenu={showMenu}
                />
              </div>
            </MotionOpaqueBasic>
            <MotionOpaqueBasic key="empty" show={info.length === 0 && !showEditor}>
              <button
                className="btn btn--secondary"
                onClick={() => setShowEditor(true)}
                type="button"
              >
                {t("v2.notes.edit.add_note")}
              </button>
            </MotionOpaqueBasic>
          </AnimatePresence>
        </MotionOpaqueBasic>
        <CandidateNoteConfirmationModal
          candidateId={candidateId}
          isOpen={showConfirmation}
          onClose={handleConfirmationClose}
          positionId={positionId}
        />
      </>
    )
  }

  return (
    <>
      <div className="flex-col gap-4 p-4 flex">
        <TextInput
          id="outsider_name"
          name="outsider_name"
          className="label-bold"
          defaultValue={outsiderName}
          onChange={(event) => setOutsiderName(event.target.value)}
          label={t("v2.succession_plan_panel.candidate_form.name")}
        />
        <WysiwygTextArea
          defaultValue={note}
          id={`candidate_${candidateId}_additional_info`}
          onChange={setNote}
        />
      </div>
      <div className="justify-end gap-2 border-t-neutral-8 p-4 flex">
        <button className="btn btn--secondary" onClick={() => setShowEditor(false)} type="button">
          {t("v2.defaults.cancel")}
        </button>
        <button className="btn btn--primary" onClick={handleSave} type="button">
          {t("v2.defaults.save")}
        </button>
      </div>
    </>
  )
}

export { CandidateNoteForm }

interface CandidateNoteConfirmationModalProps {
  candidateId: string
  isOpen: boolean
  onClose: () => void
  positionId: string
}

const CandidateNoteConfirmationModal = ({
  candidateId,
  isOpen,
  onClose,
  positionId,
}: CandidateNoteConfirmationModalProps) => {
  const { t } = useTranslation()
  const [removeNote, { isLoading }] = useUpdateSuccessionPlanCandidateMutation()

  const handleSubmit = () => {
    removeNote({
      candidateId,
      positionId,
      additionalInfo: null,
    })
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t("v2.succession_plan_panel.modals.clear_candidate_note_confirm.delete")}
      footer={
        <ModalFooter
          disabled={isLoading}
          onClose={onClose}
          onSave={handleSubmit}
          saveButtonText={t("v2.defaults.delete")}
          useCriticalButton
        />
      }
    >
      <div className="p-6">
        {t("v2.succession_plan_panel.modals.clear_candidate_note_confirm.message")}
      </div>
    </Modal>
  )
}
