/**
 * Resize the svg when the chart container's size changes.
 *
 * Note: The base svg does not have data bound to it, contrary to the
 * typing here. Added SVGSVGElement to clarify that we've selected the svg
 * through d3 but not bound data to it.
 */
const attachResizeObserver = (
  svg: d3.Selection<SVGSVGElement>,
  selector: string,
): ResizeObserver | null => {
  const currentViewerElement = document.querySelector(selector)

  if (!currentViewerElement) {
    return null
  }

  const observer = new ResizeObserver((entries) => {
    const rect = entries[0].contentRect
    svg.attr("height", rect.height).attr("width", rect.width)

    svg
      .select("rect.safari-pointer-events-fix")
      .attr("height", rect.height)
      .attr("width", rect.width)
  })

  observer.observe(currentViewerElement)

  return observer
}

export default { attachResizeObserver }
