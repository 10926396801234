import { useMergeRefs } from "@floating-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React, { useEffect, useImperativeHandle, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useBoolean, useOnClickOutside } from "usehooks-ts"

import { useAutoComplete } from "v2/react/hooks/useAutocomplete"
import { DropdownMenu } from "v2/react/shared/collection/menus/DropdownMenu"

type AutocompleteProps<SelectableValue> = {
  AutocompleteOption?: (props: AutocompleteOptionProps<SelectableValue>) => React.ReactNode
  canCreateFromValue?: boolean
  className?: string
  controlRef?: React.MutableRefObject<{ focus: () => void } | null>
  disabled?: boolean
  defaultValue?: string
  emptyMessage?: React.ReactNode
  getKey?: (value: SelectableValue) => string
  getValueFromSelected?: (value: SelectableValue) => string | null
  id?: string
  isFetching?: boolean
  name?: string
  onBlur?: (arg: AutocompleteBlurArg, ev: React.FocusEvent<HTMLInputElement>) => void
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onCreatePress?: React.PointerEventHandler
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  onSelect?: (option: SelectableValue, ev: React.SyntheticEvent) => void
  onSelectCreate?: React.KeyboardEventHandler
  options: SelectableValue[]
  placeholder?: string
  readOnly?: boolean
  value?: string
  wrapperClassName?: string
}

/* eslint-disable react/no-unused-prop-types */
type AutocompleteOptionProps<SelectableValue> = {
  getValueFromSelected?: (value: SelectableValue) => string | null
  index: number
  option: SelectableValue
  searchTerm: string
}
/* eslint-enable react/no-unused-prop-types */

type AutocompleteBlurArg = {
  isRelatedAutocompleteOption: boolean
  searchTerm: string
  searchTermOnFocus: string
}

/**
 * First draft.
 */
function AutocompleteWithRef<SelectableValue>(
  {
    AutocompleteOption = DefaultAutocompleteOption,
    canCreateFromValue,
    className = "input",
    controlRef,
    defaultValue,
    disabled,
    emptyMessage,
    getKey,
    getValueFromSelected,
    id,
    isFetching,
    name,
    onBlur,
    onChange,
    onCreatePress,
    onFocus,
    onSelect,
    onSelectCreate,
    options,
    placeholder,
    readOnly,
    value,
    wrapperClassName,
  }: AutocompleteProps<SelectableValue>,
  ref: React.Ref<HTMLElement>,
) {
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState(value ?? defaultValue ?? "")
  const [searchTermOnFocus, setSearchTermOnFocus] = useState(value ?? defaultValue ?? "")
  const showCreateOption = Boolean(canCreateFromValue && searchTerm.trim().length)
  const {
    setFalse: hideOptionSet,
    setTrue: showOptionSet,
    setValue: setIsOptionSetShown,
    value: isOptionSetShown,
  } = useBoolean()

  // Maintains a flag that we use to detect when a user chose an option using
  // their keyboard (we use this to derive the arg we pass to our `onBlur`
  // callback).
  const blurDueToKeyboardInteractionRef = useRef(false)

  useEffect(() => {
    setSearchTerm(value ?? "")
  }, [value])

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
    setSearchTerm(ev.target.value)
    showOptionSet()
    onChange?.(ev)
  }

  const handleFocus: React.FocusEventHandler<HTMLInputElement> = (ev) => {
    setSearchTermOnFocus(ev.target.value)
    onFocus?.(ev)
  }

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = (ev) => {
    const node = ev.relatedTarget instanceof HTMLButtonElement ? ev.relatedTarget : null
    const { current: blurDueToKeyboardInteraction } = blurDueToKeyboardInteractionRef
    blurDueToKeyboardInteractionRef.current = false

    const blurArg = {
      searchTerm,
      searchTermOnFocus,
      isRelatedAutocompleteOption:
        containerRef.current?.contains(node) ||
        refs.floating.current?.contains(node) ||
        blurDueToKeyboardInteraction,
    }

    onBlur?.(blurArg, ev)
  }

  const {
    activeIndex,
    context,
    floatingStyles,
    getFloatingProps,
    getItemProps,
    getReferenceProps,
    listRef,
    refs,
    setActiveIndex,
  } = useAutoComplete({ setShowList: setIsOptionSetShown, showList: isOptionSetShown })

  const makeClickHandlerThatEmitsSelectChoice =
    (payload: SelectableValue) => (ev: React.SyntheticEvent) => {
      onSelect?.(payload, ev)
      hideOptionSet()
    }

  const containerRef = useRef<HTMLDivElement>(null)
  const divRef = useMergeRefs([containerRef, ref])
  useOnClickOutside(containerRef, (ev) => {
    if (!showOptionSet) return

    const node = ev.target instanceof Node ? ev.target : null
    if (!refs.floating.current?.contains(node)) hideOptionSet()
  })

  const handleCreatePress: React.PointerEventHandler = (ev) => {
    hideOptionSet()
    onCreatePress?.(ev)
  }

  const handleInputKeyDown: React.KeyboardEventHandler = (ev) => {
    if (ev.key === "Enter") {
      setIsOptionSetShown(false)

      // If the keypress wasn't to interact with an option, bail now to allow
      // default handling.
      if (typeof activeIndex !== "number" || activeIndex > options.length) return

      ev.preventDefault()
      ev.stopPropagation()
      if (activeIndex === options.length) onSelectCreate?.(ev)
      if (options[activeIndex]) onSelect?.(options[activeIndex], ev)

      blurDueToKeyboardInteractionRef.current = true
      refs.domReference.current?.blur()
    } else if (ev.key === "Escape") {
      ev.preventDefault()
      ev.stopPropagation()

      setIsOptionSetShown(false)
      setActiveIndex(null)
    }
  }

  const inputRef = refs.reference as React.MutableRefObject<HTMLInputElement | null>
  useImperativeHandle(controlRef, () => ({ focus: () => inputRef.current?.focus() }), [inputRef])

  return (
    <div className={wrapperClassName} ref={divRef}>
      <input
        autoComplete="off"
        className={className}
        defaultValue={defaultValue}
        disabled={disabled}
        id={id}
        name={name}
        placeholder={placeholder}
        readOnly={readOnly}
        ref={refs.setReference}
        type="text"
        value={searchTerm}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getReferenceProps({
          onBlur: handleBlur,
          onChange: handleChange,
          onFocus: handleFocus,
          onKeyDown: handleInputKeyDown,
        })}
      />
      <DropdownMenu
        showList={isOptionSetShown}
        floatingRef={refs.setFloating}
        floatingStyles={floatingStyles}
        floatingProps={getFloatingProps}
        wrapperClasses="list-group autocomplete-list z-20"
        context={context}
      >
        {options.map((option, index) => (
          <button
            aria-selected={activeIndex === index}
            className={cn("list-group-item !cursor-pointer text-left", {
              active: activeIndex === index,
            })}
            key={getKey?.(option) ?? `option-${option}`}
            ref={(node) => {
              listRef.current[index] = node
            }}
            role="option"
            tabIndex={activeIndex === index ? 0 : -1}
            type="button"
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...getItemProps({ onPointerUp: makeClickHandlerThatEmitsSelectChoice(option) })}
          >
            <AutocompleteOption
              option={option}
              getValueFromSelected={getValueFromSelected}
              index={index}
              searchTerm={searchTerm}
            />
          </button>
        ))}

        {options.length === 0 && !isFetching && searchTerm.trim() !== "" && emptyMessage}

        {showCreateOption && options.length > 0 ? <hr className="mx-[-0.5rem] my-2" /> : null}

        {showCreateOption && (
          <button
            aria-selected={activeIndex === options.length}
            className={cn(
              "list-group-item group-[.highlight]:bg-neutral-3",
              "hover:bg-neutral-3",
              "w-full rounded-md p-2.5 text-left text-neutral-100 block",
              { active: activeIndex === options.length },
            )}
            ref={(node) => {
              listRef.current[options.length] = node
            }}
            onPointerUp={handleCreatePress}
            role="option"
            tabIndex={activeIndex === options.length ? 0 : -1}
            type="button"
          >
            <FontAwesomeIcon icon={["far", "plus"]} className="mr-2" />
            <span className="truncate">
              {t("v2.defaults.create")} <span className="font-bold">{searchTerm}</span>
            </span>
          </button>
        )}
      </DropdownMenu>
    </div>
  )
}

type ForwardRefFn<R> = <P>(
  p: AutocompleteProps<P> & React.RefAttributes<R>,
) => React.ReactElement | null
const Autocomplete = React.forwardRef(AutocompleteWithRef) as ForwardRefFn<HTMLDivElement>

function DefaultAutocompleteOption<SelectableValue>({
  option,
  getValueFromSelected,
}: AutocompleteOptionProps<SelectableValue>) {
  return <div className="list-group-item-title">{getValueFromSelected?.(option)}</div>
}

export { Autocomplete, AutocompleteBlurArg, AutocompleteProps, AutocompleteOptionProps }
