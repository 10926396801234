import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import type { UtilityButtonProps } from "v2/react/shared/tables/TableUtilities/UtilityButtons"

const Button = ({ active, onUtilityToggle }: UtilityButtonProps) => {
  const { t } = useTranslation()

  return (
    <button
      id="filter-table"
      type="button"
      className={cn("tooltip tooltip-left btn tooltip sm:btn--large btn--secondary w-8 sm:w-10", {
        "is-active !bg-neutral-3": active,
      })}
      onClick={() => onUtilityToggle("filter-table")}
    >
      <FontAwesomeIcon icon={["far", "filter"]} />
      <div className="tooltip-content tooltip-content--sm">{t("v2.defaults.filters")}</div>
    </button>
  )
}

export { Button }
