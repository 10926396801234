import React from "react"
import { useTranslation } from "react-i18next"

import { FeatureFlags } from "types/graphql"
import { ProfilePanelMode } from "types/graphql.enums"
import { ModuleListItem } from "v2/react/components/orgChart/ProfilePanel/ModuleListItem"
import { useSelectedBox } from "v2/react/components/orgChart/ProfilePanel/Settings/hooks/useSelectedBox"
import { AccordionModulePanel } from "v2/react/shared/layout/AccordionModulePanel"
import { useAppSelector } from "v2/redux/store"

interface BioEducationProps {
  featureFlags: FeatureFlags
}

function BioEducation({ featureFlags }: BioEducationProps) {
  const person = useAppSelector((state) => state.profilePanel.person)
  const { currentBox, toggleBox } = useSelectedBox("bio_education", ProfilePanelMode.Person)
  const { t } = useTranslation()

  if (!currentBox || !person) return null
  if (!featureFlags.bio || !person.bioEducations?.length) return null

  const headerContent = <div className="title">{t("v2.profile_panel.bio_education")}</div>

  return (
    <AccordionModulePanel
      headerContent={headerContent}
      additionalToggleAction={toggleBox}
      collapsed={currentBox.collapsed}
      title={currentBox.name}
    >
      {person.bioEducations?.map((education) => (
        <ModuleListItem key={education.id}>
          <div>
            <div>{education.degree}</div>
            {education.major && (
              <div>{t("v2.bio_educations.index.major", { major: education.major })}</div>
            )}
            {education.secondMajor && (
              <div>
                {t("v2.bio_educations.index.second_major", { second_major: education.secondMajor })}
              </div>
            )}
            {education.minor && (
              <div>{t("v2.bio_educations.index.minor", { minor: education.minor })}</div>
            )}
            <div>
              {education.school}, {education.year}
            </div>
          </div>
        </ModuleListItem>
      ))}
    </AccordionModulePanel>
  )
}

export { BioEducation }
