import classNames from "classnames"
import React, { CSSProperties } from "react"

interface SpinnerProps {
  className?: string
  style?: CSSProperties
  testId?: string
}

export const Spinner = ({ className, style = {}, testId }: SpinnerProps) => (
  <div className={classNames("spinner", className)} style={style} data-testid={testId} />
)

export const InlineSpinner = ({ className, style, testId }: SpinnerProps) => (
  <Spinner
    className={classNames(
      "ml-0 mt-0",
      "relative left-[initial] top-[initial]",
      "h-4 w-4",
      className,
    )}
    style={style}
    testId={testId}
  />
)
