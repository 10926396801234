import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import RootProvider from "v2/react/components/RootProvider"
import { ExportForm } from "v2/react/components/succession/ExportForm"
import PageNav from "v2/react/shared/navigation/PageNav"
import { ActionBlock } from "v2/react/shared/navigation/PageNav/ActionBlock"
import { LinkBlock } from "v2/react/shared/navigation/PageNav/LinkBlock"
import { Link, LinkGroup } from "v2/react/shared/navigation/PageNav/LinkGroup"
import { TitleBlock } from "v2/react/shared/navigation/PageNav/TitleBlock"
import { Modal } from "v2/react/shared/overlay/Modal"

interface Props {
  links: Link[]
  exportUrl?: string
}

function SuccessionNav({ links, exportUrl }: Props) {
  const { t } = useTranslation()
  const [exportModalOpen, setExportModalOpen] = useState(false)

  const handleOpenModal = () => {
    setExportModalOpen(true)
  }

  const handleCloseModal = () => {
    setExportModalOpen(false)
  }

  return (
    <RootProvider>
      <PageNav>
        <TitleBlock>
          <h1>{"succession".t("succession")}</h1>
        </TitleBlock>
        <LinkBlock>
          <LinkGroup links={links} />
        </LinkBlock>
        <ActionBlock>
          {exportUrl && (
            <>
              <Modal
                isOpen={exportModalOpen}
                onClose={handleCloseModal}
                title={t("v2.defaults.export")}
              >
                <ExportForm exportUrl={exportUrl} onSubmit={handleCloseModal} />
              </Modal>
              <button
                type="button"
                className="btn--large btn--secondary w-11"
                id="export-csv"
                onClick={handleOpenModal}
              >
                <FontAwesomeIcon icon={["far", "download"]} />
              </button>
            </>
          )}
        </ActionBlock>
      </PageNav>
    </RootProvider>
  )
}

export { SuccessionNav }
