import fp from "lodash/fp"
import { createSelector, createStructuredSelector } from "reselect"

import { RootState } from "v2/redux/store"

/**
 * @public
 * @returns {object} the complete color coding index.
 */
const selectColorCodeIndex = (state: RootState) => state.visualization.positionThemeOptions

/**
 * @public
 * @returns {array} an ordered array of color codes.
 */
const selectColorCodes = createSelector(
  [selectColorCodeIndex, (_, domainAttribute: string) => domainAttribute],
  (colorCodeIndex, domainAttribute) =>
    fp.sortBy("order", fp.values(colorCodeIndex[domainAttribute])),
)

const selectActiveColorCodingTab = (state: RootState) => state.visualization.activeColorCodingTab
const isCardActive = (state: RootState) => selectActiveColorCodingTab(state) === "card"

const selectColorFieldByActiveTab = (state: RootState) =>
  state.visualization[isCardActive(state) ? "cardColorField" : "edgeColorField"]

const selectColorCodingEnabledByActiveTab = (state: RootState) =>
  state.visualization[isCardActive(state) ? "isCardColorEnabled" : "isEdgeColorEnabled"]

const selectColorsIndexByActiveTab = (state: RootState) =>
  state.visualization[isCardActive(state) ? "cardColors" : "edgeColors"]

const selectActiveFieldByType = (state: RootState, type: "card" | "edge") =>
  state.visualization[type === "card" ? "cardColorField" : "edgeColorField"]

const selectActiveColorCodeDomainByType = (state: RootState, type: "card" | "edge") => {
  const maybeField = state.visualization[type === "card" ? "cardColorField" : "edgeColorField"]
  if (!maybeField) return {}

  return state.visualization[type === "card" ? "cardColors" : "edgeColors"][maybeField]
}

const selectColorCodesByActiveTabAndField = createSelector(
  selectColorsIndexByActiveTab,
  selectColorFieldByActiveTab,
  (index, field) => {
    if (!field || !index[field]) return []

    const colorCodesIndex = index[field]
    const allColorCodes = fp.values(colorCodesIndex)
    return fp.sortBy("order", allColorCodes)
  },
)

const selectEdgeColorCodingState = createStructuredSelector({
  enabled: (state: RootState) => state.visualization.isEdgeColorEnabled,
  colorableAttribute: (state: RootState) => selectActiveFieldByType(state, "edge"),
  colorCodesByName: (state: RootState) =>
    state.visualization.isEdgeColorEnabled ? state.visualization.edgeColorIdsByName : {},
  colorCodesById: (state: RootState) =>
    state.visualization.isEdgeColorEnabled ? selectActiveColorCodeDomainByType(state, "edge") : {},
})

const selectCardAndEdgeColorCodingAttributes = createSelector(
  (state: RootState) => state.visualization.cardColorField,
  (state: RootState) => state.visualization.edgeColorField,
  (cardColorField, edgeColorField) => fp.uniq(fp.compact([cardColorField, edgeColorField])),
)

const selectColorCodesQueryVariables = createStructuredSelector({
  key: (state: RootState) => state.container.containerKey,
  inDomains: selectCardAndEdgeColorCodingAttributes,
})

const selectColorCodingState = createStructuredSelector({
  cardColorField: (state: RootState) => state.visualization.cardColorField,
  cardColorIdsByName: (state: RootState) => state.visualization.cardColorIdsByName,
  cardColors: (state: RootState) => state.visualization.cardColors,
  edgeColorField: (state: RootState) => state.visualization.edgeColorField,
  edgeColorIdsByName: (state: RootState) => state.visualization.edgeColorIdsByName,
  edgeColors: (state: RootState) => state.visualization.edgeColors,
  isCardColorEnabled: (state: RootState) => state.visualization.isCardColorEnabled,
  isEdgeColorEnabled: (state: RootState) => state.visualization.isEdgeColorEnabled,
})

const selectColorCodingServerState = createStructuredSelector({
  chosen_field: (state: RootState) => state.visualization.edgeColorField,
  enable_fields: (state: RootState) => state.visualization.isEdgeColorEnabled,
  enable_secondary_fields: (state: RootState) => state.visualization.isCardColorEnabled,
  secondary_chosen_field: (state: RootState) => state.visualization.cardColorField,
})

/**
 * @returns an object with values useful for building the UI to configure color
 *   coding.
 */
const selectColorCodingConfigForActiveTab = createStructuredSelector({
  activeTab: selectActiveColorCodingTab,
  field: selectColorFieldByActiveTab,
  isEnabled: selectColorCodingEnabledByActiveTab,
  colorCodes: selectColorCodesByActiveTabAndField,
})

/**
 * @returns an array of color coding domain attributes (NodeInterface fields).
 */
const selectColorCodingFields = (state: RootState) => state.visualization.colorCodeAttributes

export {
  selectActiveFieldByType,
  selectColorCodes,
  selectColorCodesByActiveTabAndField,
  selectColorFieldByActiveTab,
  selectActiveColorCodeDomainByType,
  selectColorCodingConfigForActiveTab,
  selectColorCodingFields,
  selectEdgeColorCodingState,
  selectColorCodingState,
  selectColorCodesQueryVariables,
  selectColorCodingServerState,
}
