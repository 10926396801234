import { useEffect, useRef } from "react"

/**
 *
 * Custom hook initiates a ref with useEffect to assign and remove
 * an event listener. Checks for clicks outside of `ref` before dispatching
 * callback.
 */
const useOutsideClick = <T extends HTMLElement>(callback: (event: MouseEvent) => void) => {
  const ref = useRef<T>(null)

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (ref.current && !ref.current?.contains(event.target as Node)) {
        callback(event)
      }
    }

    document.addEventListener("mousedown", handleClick)

    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  }, [ref, callback])

  return ref
}

/**
 * Stop propagation of clicks made within the component so the callback
 * attached by the earlier effect doesn't close this. React dispatches synthetic
 * events, so the call to `nativeEvent.stopImmediatePropagation()` is crucial.
 */
const preventPropagationOfSyntheticEvent = (ev: React.SyntheticEvent) => {
  if (ev && ev.nativeEvent && ev.nativeEvent.stopImmediatePropagation) {
    ev.nativeEvent.stopImmediatePropagation()
  }

  ev.preventDefault()
}

export { useOutsideClick, preventPropagationOfSyntheticEvent }
