import { TFunction } from "i18next"
import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import {
  UpdateCompanyOrgChartSettingsInput,
  UpdateCompanyOrgChartSettingsInputObject,
  UpdateCompanyOrgChartSettingsMutation,
} from "types/graphql.d"
import { gqlFetch } from "v2/graphql_client"
import OperationStore from "v2/operation_store"
import { FieldsetWithSearch } from "v2/react/shared/forms/FieldsetWithSearch"
import { useFieldsetWithSearch } from "v2/react/shared/forms/FieldsetWithSearch/hooks/useFieldsetWithSearch"
import { FormDropdown } from "v2/react/shared/forms/FormDropdown"
import { SaveFooter } from "v2/react/shared/overlay/Modal/SaveFooter"

import type { DisplayFieldOptions } from "./OrgChartDefaultSettings"
import { DisplayMode } from "./types"

interface DisplayModeOptions {
  value: DisplayMode
  label: string
  labelDescription?: string
  iconPath?: string
}

const buildDisplayModeOptions = (forceThreeLevel: boolean, t: TFunction): DisplayModeOptions[] => {
  let displayModes: DisplayMode[] = ["tree", "three_level", "grid"]
  if (forceThreeLevel) {
    displayModes = displayModes.filter((displayMode) => displayMode !== "tree")
  }

  return displayModes.map((displayMode) => ({
    value: displayMode,
    label: t(`v2.general_settings.display_mode_${displayMode}`),
    labelDescription: t(`v2.general_settings.display_mode_${displayMode}_description`),
    iconPath: window.App.Helpers.imagePath(`view-as-${displayMode}-icon.png`),
  }))
}

interface Props {
  selectedFieldsInOrder: DisplayFieldOptions
  displayFieldOptions: DisplayFieldOptions
  displayMode: DisplayMode
  forceThreeLevel: boolean
  onSaveSuccess: (
    updatedSelectedDisplayFields: string[],
    updatedFields: DisplayFieldOptions,
    updatedDisplayMode: DisplayMode,
  ) => void
}

function OrgChartDefaultSettingsForm({
  selectedFieldsInOrder,
  displayFieldOptions,
  displayMode,
  forceThreeLevel,
  onSaveSuccess,
}: Props) {
  const [selectedDisplayMode, setSelectedDisplayMode] = useState<DisplayMode>(displayMode)
  const [isSaving, setIsSaving] = useState(false)
  const { t } = useTranslation()
  const {
    selectedFields: selectedAdvancedFields,
    unselectedFields: unselectedAdvancedFields,
    handleReorder,
    handleSearchSelect,
  } = useFieldsetWithSearch({
    selectedFieldOptions: selectedFieldsInOrder.filter((field) => field.type === "advanced"),
    allFields: displayFieldOptions,
  })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    // Read the form data
    const form = e.target as HTMLFormElement
    const formData = new FormData(form)
    const formKeys = Array.from(formData.keys())

    const settingsData: UpdateCompanyOrgChartSettingsInputObject = {
      displayFieldsDefaults: formKeys,
      defaultDisplayMode: selectedDisplayMode,
    }

    const input: UpdateCompanyOrgChartSettingsInput = {
      settings: settingsData,
    }

    const saveSettings = async () => {
      setIsSaving(true)

      const operationId = OperationStore.getOperationId("UpdateCompanyOrgChartSettings")

      const result = await gqlFetch<UpdateCompanyOrgChartSettingsMutation>({
        operationId,
        variables: { input },
      })

      const settings = result.data.updateCompanyOrgChartSettings?.companyOrgChartSettings
      const selectedDisplayFieldKeys = settings?.authorizedSelectedDisplayFields || []

      if (settings) {
        onSaveSuccess(
          selectedDisplayFieldKeys,
          settings.displayFieldOptions,
          settings.defaultDisplayMode as DisplayMode,
        )
      } else {
        const errors = result.data.updateCompanyOrgChartSettings?.errors || []
        // eslint-disable-next-line no-console
        console.error(errors)
      }

      setIsSaving(false)
    }

    saveSettings()
  }

  const basicFields: DisplayFieldOptions = displayFieldOptions.filter(
    (field) => field.type === "basic",
  )
  const displayModeOptions = useMemo(
    () => buildDisplayModeOptions(forceThreeLevel, t),
    [forceThreeLevel, t],
  )

  const makeLabel = (field: DisplayFieldOptions[0]) => (
    <div className="justify-between flex">
      <div className="min-w-0 break-words">{field.label}</div>
      {field.admin && (
        <div className="!ml-2 mt-0.5 flex-shrink-0 break-normal text-sm text-neutral-64">
          {t("v2.general_settings.admin_only")}
        </div>
      )}
    </div>
  )

  return (
    <form onSubmit={handleSubmit}>
      <div className="react-modal__body">
        <div className="mb-4 cursor-default">
          {t("v2.general_settings.org_chart_default_settings_msg")}
        </div>
        <h2 className="mb-2 cursor-default">{t("v2.general_settings.chart_view")}</h2>
        <FormDropdown<DisplayMode>
          label={t("v2.general_settings.view_as")}
          options={displayModeOptions}
          selected={selectedDisplayMode}
          onSelect={(value: DisplayMode) => setSelectedDisplayMode(value)}
        />
        <h2 className="mb-2 cursor-default">{t("v2.general_settings.fields")}</h2>
        <div className="input-group">
          <strong className="cursor-default">
            {t("v2.general_settings.show_hide_basic_fields")}
          </strong>
          <div>
            {basicFields.map((field) => (
              <label key={field.id} htmlFor={field.id} className="checkbox">
                <input
                  type="checkbox"
                  id={field.id}
                  name={field.id}
                  defaultChecked={selectedFieldsInOrder.some(
                    (selectedField) => selectedField.id === field.id,
                  )}
                />
                <span>{field.label}</span>
              </label>
            ))}
          </div>
        </div>

        <div className="input-group">
          <FieldsetWithSearch<DisplayFieldOptions[0]>
            label={t("v2.general_settings.show_hide_advanced_fields")}
            description={t("v2.general_settings.drag_and_drop_to_reorder")}
            id="org-chart-company-default-display-fields"
            selectedItems={selectedAdvancedFields}
            searchableItems={unselectedAdvancedFields}
            onReorder={handleReorder}
            onSearchSelect={handleSearchSelect}
            makeLabel={makeLabel}
            useBorder
          />
        </div>
      </div>

      <SaveFooter isSaving={isSaving} />
    </form>
  )
}

export { OrgChartDefaultSettingsForm }
