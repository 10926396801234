import React from "react"

import { Position } from "types/graphql"

interface AutocompleteResultProps {
  position: Position
  isSelected: boolean
  className: string
  searchTerm: string
  refFn: (node: HTMLDivElement | null) => void
  getItemProps: (userProps?: React.HTMLProps<HTMLElement> | undefined) => Record<string, unknown>
  handleResultClick: (uniqueKey: string | undefined | null, positionLabel: string) => void
}

const AutocompleteResult: React.FC<AutocompleteResultProps> = ({
  position,
  isSelected,
  className,
  searchTerm,
  refFn,
  getItemProps,
  handleResultClick,
}) => {
  const people = position.people
  const peopleNames = people?.map((person) => person.name).join(", ")
  const positionTitle = position.title
  const positionSystemID = position.systemIdentifier
  const imageUrl = people?.[0]?.avatarThumbUrl
  const positionLabel = [peopleNames, positionTitle || positionSystemID].filter((n) => n).join(", ")

  const matchTerm = searchTerm?.toLowerCase()

  return (
    <div
      role="option"
      aria-selected={isSelected}
      key={position.uniqueKey}
      className={className}
      ref={refFn}
      /* eslint-disable react/jsx-props-no-spreading */
      {...getItemProps({
        onClick: () => handleResultClick(position.uniqueKey, positionLabel),
      })}
    >
      <div className="AutocompleteField__result-thumb">
        {imageUrl && <img alt={peopleNames || ""} src={imageUrl || ""} />}
      </div>
      <div className="AutocompleteField__result-text">
        {peopleNames && <div className="AutocompleteField__result-title">{peopleNames}</div>}

        {positionTitle && <div>{positionTitle}</div>}

        {/* This logic is meant to mirror the search partial
        see: app/views/v2/positions/search.html.slim */}
        {maybeShowEmployeeIds(people, matchTerm)}
        {maybeShowPositionID(positionSystemID, matchTerm)}
        {maybeShowBioFields(people, matchTerm)}
      </div>
    </div>
  )
}

const maybeShowEmployeeIds = (people: Position["people"], matchTerm: string) => {
  const employeeIds = people?.map((person) => person.employeeId)

  return employeeIds?.some((id) => id?.toLowerCase().includes(matchTerm)) ? (
    <div>
      <div>{`${"Employee ID".t("org_chart")}: ${employeeIds.join(", ")}`}</div>
    </div>
  ) : null
}

const maybeShowPositionID = (positionSystemID: string | undefined | null, matchTerm: string) =>
  positionSystemID?.toLowerCase().includes(matchTerm) ? (
    <div>
      <div>{`${"Position ID".t("org_chart")}: ${positionSystemID}`}</div>
    </div>
  ) : null

const maybeShowBioFields = (people: Position["people"], matchTerm: string) => {
  const skills = people?.flatMap((person) => person?.bioSkills)
  const licenses = people?.flatMap((person) => person?.bioLicenses)

  const isSkillsMatch = skills?.some((skill) => skill?.name.toLowerCase().includes(matchTerm))
  const isLicensesMatch = licenses?.some(
    (license) => license?.name.toLowerCase().includes(matchTerm),
  )

  return (
    (isSkillsMatch || isLicensesMatch) && (
      <div className="AutocompleteField__result-search-extras">
        {isSkillsMatch && (
          <div>
            {`${"Skills".t("org_chart")}: ${skills?.map((skill) => skill?.name).join(", ")}`}
          </div>
        )}
        {isLicensesMatch && (
          <div>
            {`${"Lic & Certs".t("org_chart")}: ${licenses
              ?.map((license) => license?.name)
              .join(", ")}`}
          </div>
        )}
      </div>
    )
  )
}

export { AutocompleteResult }
