import { Maybe } from "graphql/jsutils/Maybe"

import { Person } from "types/graphql"

type ApprovalActionType = "approved" | "changes_requested" | "denied"
interface DatePropertyMap {
  [key: string]: "approvedAt" | "changesRequestedAt" | "deniedAt" | "updatedAt"
}

/*
 * The following interfaces are generic so that the approver
 * components can work for both chartApproval and requisitionApproval
 */

interface ApproverType {
  approvedAt?: Maybe<Date>
  changesRequestedAt?: Maybe<Date>
  deniedAt?: Maybe<Date>
  id: string
  note?: string | null
  person: Person
  sortOrder?: number | null
  state?: Maybe<string> | undefined
  status?: Maybe<string> | undefined
  updatedAt?: Maybe<Date>
}

interface NoteType {
  body: string
  createdAt: Date
  person: Person
  status: string
}

const initialStatuses = ["awaiting_approval", "pending_approval"]

// Chart Approval and general Approval statuses include:
//   approved awaiting_approval canceled changes_requested denied  notified
//   pending pending_approval
// Job Requisition statuses include:
//   canceled closed filled offer_pending on_hold open pending_approval
//   rejected setup_in_progress
const statusColorLookup = (status: string) => {
  const statusColorMap: Record<string, string> = {
    approved: "green",
    awaiting_approval: "purple",
    at_risk: "yellow",
    canceled: "gray",
    closed: "gray",
    changes_requested: "yellow",
    complete: "green",
    denied: "red",
    filled: "green",
    high: "red",
    low: "green",
    medium: "yellow",
    none: "gray",
    no_configuration: "red",
    notified: "blue",
    offer_pending: "yellow",
    on_hold: "red",
    open: "blue",
    open_position: "yellow",
    not_defined: "white",
    pending: "purple",
    pending_approval: "purple",
    plan_approved: "green",
    rejected: "red",
    setup_in_progress: "yellow",
  }

  return statusColorMap[status]
}

const statusTextLookup = (status: string) => {
  const statusTextMap: Record<string, string> = {
    approved: "approved".t("status_defaults"),
    awaiting_approval: "awaiting_review".t("status_defaults"),
    at_risk: "at_risk".t("status_defaults"),
    canceled: "canceled".t("status_defaults"),
    closed: "closed".t("job_requisition"),
    changes_requested: "changes_requested".t("status_defaults"),
    complete: "complete".t("status_defaults"),
    denied: "denied".t("status_defaults"),
    filled: "filled".t("job_requisition"),
    high: "high".t("status_defaults"),
    low: "low".t("status_defaults"),
    medium: "medium".t("status_defaults"),
    no_configuration: "no_configuration".t("status_defaults"),
    none: "none".t("status_defaults"),
    notified: "notified".t("status_defaults"),
    offer_pending: "offer_pending".t("job_requisition"),
    on_hold: "on_hold".t("job_requisition"),
    open: "open".t("job_requisition"),
    open_position: "open_position".t("positions"),
    not_defined: "not_defined".t("status_defaults"),
    pending: "pending".t("status_defaults"),
    pending_approval: "pending_approval".t("status_defaults"),
    plan_approved: "plan_approved".t("status_defaults"),
    rejected: "rejected".t("job_requisition"),
    setup_in_progress: "setup_in_progress".t("job_requisition"),
  }

  return statusTextMap[status]
}

const checkIfNextApprover = <Approver extends ApproverType>(
  approvers: Approver[],
  approver: Approver,
) => {
  const currApprover = currentApprover(approvers)
  return approver.person.id === currApprover?.person.id || false
}

const currentApprover = (approvers: ApproverType[]) => {
  const currApprover = approvers.find(
    (a: ApproverType) => ("status" in a && !a.status) || ("state" in a && a.state !== "approved"),
  )
  return currApprover
}

const currentUserIsNextApprover = (approvers: ApproverType[], currentUserId: string | number) => {
  const currApprover = currentApprover(approvers)
  return currApprover?.person.id === currentUserId.toString() || false
}

const currentApproverStatus = (approvers: ApproverType[], currentUserId: string | number) => {
  const approver = approvers.find(
    (approver: ApproverType) => approver?.person.id === currentUserId.toString(),
  )

  if (!approver) return null

  return (("status" in approver && approver.status) ||
    ("state" in approver && approver.state)) as ApprovalActionType | null
}

export {
  ApprovalActionType,
  ApproverType,
  DatePropertyMap,
  NoteType,
  initialStatuses,
  checkIfNextApprover,
  currentApprover,
  currentApproverStatus,
  currentUserIsNextApprover,
  statusColorLookup,
  statusTextLookup,
}
