import React from "react"

import { Notice } from "v2/react/shared/status/Notice"

interface ErrorBoundaryProps {
  isError: boolean
  errorMessage?: string
  children: React.ReactNode
}

const ErrorFallback = ({ children, errorMessage, isError }: ErrorBoundaryProps) => {
  if (isError) {
    return (
      <div className="p-6">
        <Notice type="critical" icon={["far", "exclamation-triangle"]}>
          {errorMessage ?? "generic_error".t("org_chart", "datasheet")}
        </Notice>
      </div>
    )
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

export { ErrorFallback }
