import { rankWith, uiTypeIs } from "@jsonforms/core"

const AutocompleteControlTester = rankWith(3, uiTypeIs("AutocompleteControl"))
const BasePayControlTester = rankWith(3, uiTypeIs("BasePayControl"))
const CurrencyControlTester = rankWith(3, uiTypeIs("CurrencyControl"))
const DateControlTester = rankWith(3, uiTypeIs("DateControl"))
const FileControlTester = rankWith(3, uiTypeIs("ListFileControl"))
const FileUploadControlTester = rankWith(3, uiTypeIs("FileUploadControl"))
const ListBaseCompensationControlTester = rankWith(3, uiTypeIs("ListBaseCompensationControl"))
const ListItemControlTester = rankWith(3, uiTypeIs("ListItemControl"))
const ListItemDescriptionControlTester = rankWith(3, uiTypeIs("ListItemDescriptionControl"))
const ListItemFilledByControlTester = rankWith(3, uiTypeIs("ListItemFilledByControl"))
const ListItemSubtitledControlTester = rankWith(3, uiTypeIs("ListItemSubtitledControl"))
const ListItemTotalControlTester = rankWith(3, uiTypeIs("ListItemTotalControl"))
const ListOrgUnitsControlTester = rankWith(3, uiTypeIs("ListOrgUnitsControl"))
const ListTotalCompensationControlTester = rankWith(3, uiTypeIs("ListTotalCompensationControl"))
const ListVariableCompensationControlTester = rankWith(
  3,
  uiTypeIs("ListVariableCompensationControl"),
)
const MultiSelectControlTester = rankWith(3, uiTypeIs("MultiSelectControl"))
const NestedTextControlTester = rankWith(3, uiTypeIs("NestedTextControl"))
const NumberControlTester = rankWith(3, uiTypeIs("NumberControl"))
const NumericControlTester = rankWith(3, uiTypeIs("NumericControl"))
const OrgUnitControlTester = rankWith(3, uiTypeIs("OrgUnitControl"))
const PercentControlTester = rankWith(3, uiTypeIs("PercentControl"))
const PersonControlTester = rankWith(3, uiTypeIs("PersonControl"))
const PositionControlTester = rankWith(3, uiTypeIs("PositionControl"))
const PositionTypeControlTester = rankWith(3, uiTypeIs("PositionTypeControl"))
const RadioControlTester = rankWith(3, uiTypeIs("RadioControl"))
const SelectControlTester = rankWith(3, uiTypeIs("SelectControl"))
const TextAreaControlTester = rankWith(3, uiTypeIs("TextAreaControl"))
const TextControlTester = rankWith(3, uiTypeIs("TextControl"))
const TotalCompensationControlTester = rankWith(3, uiTypeIs("TotalCompensationControl"))
const VariablePayControlTester = rankWith(3, uiTypeIs("VariablePayControl"))

export {
  AutocompleteControlTester,
  BasePayControlTester,
  CurrencyControlTester,
  DateControlTester,
  FileControlTester,
  FileUploadControlTester,
  ListBaseCompensationControlTester,
  ListItemControlTester,
  ListItemDescriptionControlTester,
  ListItemFilledByControlTester,
  ListItemSubtitledControlTester,
  ListItemTotalControlTester,
  ListOrgUnitsControlTester,
  ListTotalCompensationControlTester,
  ListVariableCompensationControlTester,
  MultiSelectControlTester,
  NestedTextControlTester,
  NumberControlTester,
  NumericControlTester,
  OrgUnitControlTester,
  PercentControlTester,
  PersonControlTester,
  PositionControlTester,
  PositionTypeControlTester,
  RadioControlTester,
  SelectControlTester,
  TextAreaControlTester,
  TextControlTester,
  TotalCompensationControlTester,
  VariablePayControlTester,
}
