import React from "react"

interface FooterProps {
  columnLength: number
  content: string
}

export const TableFooter = ({ columnLength, content }: FooterProps) => (
  // z-index to 2 so it's above the loading state overlay
  // see TableBody.tsx
  // see table.scss
  <tfoot style={{ zIndex: 2 }}>
    <tr>
      <td colSpan={columnLength}>{content}</td>
    </tr>
  </tfoot>
)
