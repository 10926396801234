import React from "react"

import { ANIMATION_DURATION, PositionListItem } from "./PositionListItem"

interface PositionListProps {
  isSaving: boolean
  onRemove: (selectedId: string) => void
  positionIds: string[]
}

function PositionList({ isSaving, onRemove, positionIds }: PositionListProps) {
  const handleRemoveValueClick = (e: React.MouseEvent<HTMLElement>) => {
    const removeValue: string = e.currentTarget.getAttribute("data-value") || ""
    const removedItem: string | undefined = positionIds?.find((m: string) => m === removeValue)

    setTimeout(() => {
      // This is where the actual delete is happening, post-animation
      if (removedItem) {
        onRemove(removedItem)
      }
    }, ANIMATION_DURATION)
  }

  return (
    <div className="position-list-items mt-1">
      {positionIds.map((positionId) => (
        <PositionListItem
          isSaving={isSaving}
          key={`${positionId}`}
          positionId={positionId || ""}
          onItemRemoved={handleRemoveValueClick}
        />
      ))}
    </div>
  )
}

export { PositionList }
