import fp from "lodash/fp"
import qs from "qs"
import React, { useEffect, useState } from "react"
import { useBoolean } from "usehooks-ts"

import RootProvider from "v2/react/components/RootProvider"

import { UpsertModalProps, UpsertPositionModal } from "./UpsertPositionModal"

type UpsertPositionModalDOMBindingProps = {
  chartKey?: string
  enabled?: boolean
}

type ModalPropsFromEvent = Omit<UpsertModalProps, "isOpen" | "onClose">

type PjaxEventOptions = {
  container?: string
  url?: string
}

const EMPTY_MODAL_PROPS = {
  chartKey: "",
}

/** UpsertPositionModalDOMBindingProps -> React.ReactNode */
function UpsertPositionModalDOMBinding({ chartKey, enabled }: UpsertPositionModalDOMBindingProps) {
  const [modalProps, setModalProps] = useState<ModalPropsFromEvent>(EMPTY_MODAL_PROPS)
  const {
    setTrue: openPositionModal,
    setFalse: closePositionModal,
    value: isPositionModalOpen,
  } = useBoolean()
  const setModalPropsBackToEmpty = () => setModalProps(EMPTY_MODAL_PROPS)

  useEffect(() => {
    if (!chartKey || !enabled) return fp.noop
    if (typeof window === "undefined") return fp.noop
    if (typeof window.$ === "undefined") return fp.noop

    const handler = (ev: MouseEvent, arg2: unknown, pjaxOptions?: PjaxEventOptions) => {
      if (!pjaxOptions?.container?.match(/data-pjax-container.*modal.*/)) return

      const url = pjaxOptions.url ?? ""
      // Baseline props that we'll build on.
      const newModalProps: ModalPropsFromEvent = { chartKey }

      try {
        // Haven't really read the docs for `qs` yet, so there's probably a
        // cleaner approach. But for now...figure out where `?` starts so we can
        // cut off everything upto, and including, the `?`. Then parse the query
        // string to extract any useful props. Failure to do this leads to weird
        // parser results.
        const paramStart = url.indexOf("?")
        const maybeParams = paramStart ? qs.parse(url.substring(paramStart + 1)) : undefined
        if (
          maybeParams?.position &&
          !Array.isArray(maybeParams.position) &&
          typeof maybeParams.position === "object"
        ) {
          newModalProps.parentKey = maybeParams.position.parent_id
            ? `position_${maybeParams.position.parent_id}`
            : undefined
        }
      } catch (error) {
        // Error handling is what
      }

      if (url.includes("/positions/new")) {
        ev.preventDefault()
        setModalProps(newModalProps)
        openPositionModal()
        return
      }

      const match = url.match(/.*\/positions\/([0-9]+)\/edit.*/)
      if (match && typeof match[1] === "string") {
        ev.preventDefault()
        setModalProps({ ...newModalProps, uniqueKey: `position_${match[1]}` })
        openPositionModal()
      }
    }

    window.$(document).on("pjax:beforeSend", handler)
    return () => window.$(document).off("pjax:beforeSend", handler)
  }, [chartKey, enabled, openPositionModal])

  return (
    <RootProvider>
      <UpsertPositionModal
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...modalProps}
        isOpen={isPositionModalOpen}
        onAfterClose={setModalPropsBackToEmpty}
        onClose={closePositionModal}
      />
    </RootProvider>
  )
}

export { UpsertPositionModalDOMBinding }
