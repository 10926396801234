import { RootState } from "v2/redux/store"

/**
 * @public
 * @returns { number } the current chartId
 */
const selectChartId = (state: RootState) => state.approval.chartId

/**
 * @public
 * @returns { "approve" | "request_changes" | "deny" } the current selected action of the active approver
 */
const selectCurrentAction = (state: RootState) => state.approval.selectedAction

/**
 * @public
 * @returns { "approve" | "request_changes" | "deny" } the current temporary selected action of the active approver
 */
const selectCurrentTempAction = (state: RootState) => state.approval.tempSelectedAction

/**
 * @public
 * @returns {
 * currentPersonId: number
 * canConfigure: boolean
 * isCollaborator: boolean
 * } an object of properties relating to the current user
 */
const selectCurrentUser = (state: RootState) => state.approval.currentUser

/**
 * @public
 * @returns { boolean } determines whether to show the dropdown or not
 */
const selectDropdown = (state: RootState) => state.approval.approvalUI.dropdown
/**
 * @public
 * @returns { boolean } determines whether to show the configure approver modal
 */
const selectConfigureModal = (state: RootState) => state.approval.approvalUI.configureModal
/**
 * @public
 * @returns { boolean } determines whether to show the cancel-approval/edit-chart modal
 */
const selectCancelModal = (state: RootState) => state.approval.approvalUI.cancelModal

/**
 * @public
 * @returns { "cancel" | "edit" } determines which version of the cancel-approval/edit-chart modal to show
 */
const selectCancelModalType = (state: RootState) => state.approval.approvalUI.cancelType
/**
 * @public
 * @returns { boolean } determines whether to show the status action modal
 */
const selectStatusModal = (state: RootState) => state.approval.approvalUI.statusModal

export {
  selectChartId,
  selectCurrentAction,
  selectCurrentTempAction,
  selectCurrentUser,
  selectDropdown,
  selectConfigureModal,
  selectCancelModal,
  selectCancelModalType,
  selectStatusModal,
}
