import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { AnimatePresence, motion } from "framer-motion"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import {
  ProfilePanelBoxSetting,
  ProfilePanelBoxSettingSelection,
  ProfilePanelMode,
  UpdateProfilePanelBoxSettingsInput,
} from "types/graphql"
import { useUpdateProfilePlanBoxSettingsMutation } from "v2/redux/GraphqlApi/PeopleApi"

import { useSortedBoxes } from "./hooks/useSortedBoxes"
import { SettingsBoxesHidden } from "./SettingsBoxesHidden"
import { SettingsBoxesShown } from "./SettingsBoxesShown"

interface ProfilePanelSettingsProps {
  onClose: () => void
  mode: ProfilePanelMode
  showSettings: boolean
}

const ProfilePanelSettings: React.FC<ProfilePanelSettingsProps> = ({
  onClose,
  mode,
  showSettings,
}) => {
  const { t } = useTranslation()

  const [selectedShownBoxes, setSelectedShownBoxes] = useState<
    ProfilePanelBoxSettingSelection[] | null
  >(null)
  const [mutateSettings, { isLoading: settingsAreMutating }] =
    useUpdateProfilePlanBoxSettingsMutation()
  const { adminBoxes, allBoxes, sortedBoxes } = useSortedBoxes()

  const currentModeSelectedShownBoxes = (selectedShownBoxes || sortedBoxes).filter(
    (box: ProfilePanelBoxSettingSelection) => box.mode === mode,
  )

  const hiddenBoxes: ProfilePanelBoxSetting[] = allBoxes
    .filter((box: ProfilePanelBoxSetting) => box.mode === mode)
    .filter(
      (box: ProfilePanelBoxSetting) =>
        !currentModeSelectedShownBoxes.find(
          (shownBox: ProfilePanelBoxSettingSelection) => shownBox.name === box.name,
        ),
    )
    .sort((a: ProfilePanelBoxSetting, b: ProfilePanelBoxSetting) => a.name.localeCompare(b.name))

  const saveItems = () => {
    const otherModeBoxes = sortedBoxes.filter(
      (box: ProfilePanelBoxSettingSelection) => box.mode !== mode,
    )
    const updatedInput: ProfilePanelBoxSettingSelection[] = [
      ...otherModeBoxes,
      ...currentModeSelectedShownBoxes,
    ]

    const input: UpdateProfilePanelBoxSettingsInput = {
      params: updatedInput,
    }
    mutateSettings(input)
    handleClose()
  }

  const handleClose = () => {
    setSelectedShownBoxes(null)
    onClose()
  }

  // The min height is crucial here to occupy space during animations in/out
  return (
    <AnimatePresence>
      {showSettings && (
        <motion.div
          key="settings"
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { opacity: 1 },
            collapsed: { opacity: 0.25 },
          }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <div className="h-full min-h-screen w-full bg-white p-6">
            <div className="pb-2">
              <header className="flex">
                <button
                  className="-ml-2 mr-4 cursor-pointer bg-transparent text-neutral-80"
                  onClick={handleClose}
                  type="button"
                >
                  <FontAwesomeIcon
                    className="cursor-pointer"
                    icon={["far", "chevron-left"]}
                    size="lg"
                  />
                </button>
                <h1>{t("v2.profile_panel.configure_panel")}</h1>
              </header>
              <hr className="-ml-6 -mr-6" />
              <p className="mb-2">{t("v2.profile_panel.drag_and_drop_the_boxes")}</p>
            </div>
            <SettingsBoxesShown
              adminBoxes={adminBoxes}
              shownBoxes={currentModeSelectedShownBoxes}
              onUpdate={setSelectedShownBoxes}
              onReorder={setSelectedShownBoxes}
            />

            <SettingsBoxesHidden
              adminBoxes={adminBoxes}
              hiddenBoxes={hiddenBoxes}
              onUpdate={setSelectedShownBoxes}
              mode={mode}
              shownBoxes={currentModeSelectedShownBoxes}
            />
            {selectedShownBoxes && (
              <div className="justify-end gap-2 pt-4 flex">
                <button className="btn btn--secondary" onClick={handleClose} type="button">
                  {t("v2.defaults.cancel")}
                </button>
                <button className="btn btn--primary" type="button" onClick={saveItems}>
                  {settingsAreMutating ? `${t("v2.defaults.saving")}...` : t("v2.defaults.save")}
                </button>
              </div>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export { ProfilePanelSettings }
