import React from "react"
import { ChangeHandler, RefCallBack } from "react-hook-form"

import { TextInput } from "v2/react/shared/forms/TextInput"

function JobTitleField({
  defaultValue,
  error,
  name,
  onChange,
  show = true,
  textInputRef,
}: {
  defaultValue: string | undefined
  error: boolean
  name: string
  onChange: ChangeHandler
  show: boolean
  textInputRef: RefCallBack
}) {
  if (!show) return null

  return (
    <div className="mb-4 cursor-default">
      <TextInput
        defaultValue={defaultValue}
        errors={error ? "field_title_required".t("ats") : ""}
        id="title"
        inputRef={textInputRef}
        label={"field_title".t("ats")}
        name={name}
        onChange={onChange}
        required
      />
    </div>
  )
}

export { JobTitleField }
