import classNames from "classnames"
import fp from "lodash/fp"
import Toolbar from "org_chart/grid/components/Toolbar"
import React, { RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { defaultCellRangeRenderer, GridCellRangeProps } from "react-virtualized"
import { useWindowSize } from "usehooks-ts"

import { Maybe, NodeInterface } from "types/graphql"
import { ChartApprovalBar } from "v2/react/components/orgChart/ChartApproval/ChartApprovalBar"
import { MemoedDatasheet } from "v2/react/components/orgChart/Datasheet"
import { ColumnDropzone } from "v2/react/components/orgChart/Datasheet/ColumnDropzone"
import { Column } from "v2/react/components/orgChart/Datasheet/types"
import {
  isMaxWidthColumn,
  MAX_COL_WIDTH,
  MIN_COL_WIDTH,
  ROW_HEIGHT_PX,
  TABLE_BORDER,
} from "v2/react/components/orgChart/Datasheet/utils/constants"
import { ActiveCursor } from "v2/react/components/orgChart/OrgChartDatasheet/ActiveCursor"
import { CursorBeacon } from "v2/react/components/orgChart/OrgChartDatasheet/CursorBeacon"
import { renderFollowUpModal } from "v2/react/components/orgChart/OrgChartDatasheet/FollowUpModals"
import { OrgChartDatasheetCell } from "v2/react/components/orgChart/OrgChartDatasheet/OrgChartDatasheetCell"
import {
  PeripheralsProps,
  TOOLBAR_LARGE_SC_HEIGHT,
  TOOLBAR_SMALL_SC_HEIGHT,
} from "v2/react/components/orgChart/Peripherals"
import { LoadingIndicator } from "v2/react/shared/loaders/LoadingIndicator"
import {
  FIXED_NAV_SCREEN_WIDTH,
  NAV_COLLAPSED_WIDTH,
  NAV_FULL_WIDTH,
} from "v2/react/shared/navigation/useNavController"
import { getCookie } from "v2/react/utils/cookies"
import { useFetchCollectionsQuery } from "v2/redux/GraphqlApi"
import { selectChartId } from "v2/redux/slices/ApprovalSlice/approvalSelectors"
import {
  selectFields,
  selectOrderingDetails,
} from "v2/redux/slices/ContainerSlice/containerSelectors"
import { Field } from "v2/redux/slices/ContainerSlice/types"
import { setMergeAvatarAndName } from "v2/redux/slices/GridSlice"
import {
  addCollectionsToFields,
  buildCollectionsQueryFromKeys,
  getStaticCollectionFieldsFromColumns,
} from "v2/redux/slices/GridSlice/gridHelpers/collections"
import { selectGroupFieldKeys } from "v2/redux/slices/GridSlice/gridSelectors"
import { selectDatasheetRows } from "v2/redux/slices/GridSlice/gridSelectors/selectDatasheetRows"
import {
  asyncAddFilter,
  asyncGroupBy,
  asyncRemoveFilter,
  asyncToggleGrouping,
  asyncToggleOrderBy,
} from "v2/redux/slices/GridSlice/gridThunks"
import { GroupRow, SkeletonRow } from "v2/redux/slices/GridSlice/types"
import { useFetchPageOfNodesQuery } from "v2/redux/slices/NodeSlice/NodeApi"
import { selectGqlQueryArgForPageOfNodes } from "v2/redux/slices/NodeSlice/nodeQuerySelectors"
import { FieldKey } from "v2/redux/slices/NodeSlice/types"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

const InitialDimensions = { width: 360, height: 400 }

const ADD_COL_HEADER_SPACE = 54 // gap between text + icon + header padding

interface Props {
  peripheralsProps: PeripheralsProps
}

/**
 * This provides a UI similar to a spreadsheet for charts and lists (our Org
 * Charts). It helps users browse, search, compare, and change data for the
 * people and positions in their organization. Supporting this requires a high
 * degree of coordination at every level of the stack.
 *
 * NodeSlice and GridSlice are critical Redux slices for the datasheet.
 *
 * 1. The NodeSlice provides actual person-position data, and is responsible
 *   for their persistence.
 * 2. The GridSlice manages top-level view state and the "skeleton" that
 *   determines what rows are shown.
 *
 * Node data is generally reactive, grid data is generally not. There's a lot
 * of nuance and subtle edge cases due to the reactive nature of the NodeSlice
 * and the lazy nature of the GridSlice.
 */
function OrgChartDatasheet({ peripheralsProps }: Props) {
  const chartId = useAppSelector(selectChartId)
  const groups = useAppSelector(selectGroupFieldKeys)
  const showGroupData = useAppSelector((state) => state.visualization.showGroupData)
  const { sortColumn, sortDirection } = useAppSelector(selectOrderingDetails)
  const beaconRef = useRef<HTMLButtonElement>(null)
  const sheetRef = useRef<{
    contains: (element: Node | null) => boolean
    scrollToCell: (c: { rowIndex: number; columnIndex: number }) => void
  }>(null)
  // Control the cell cursor with keyboard events.
  const cellRangeRenderer = useCallback(
    ({ scrollLeft, scrollTop, styleCache, ...rest }: GridCellRangeProps) => [
      ...defaultCellRangeRenderer({ scrollLeft, scrollTop, styleCache, ...rest }),
      <CursorBeacon
        key="cursor-beacon"
        scrollLeft={scrollLeft}
        scrollTop={scrollTop}
        beaconRef={beaconRef}
      />,
      <ActiveCursor
        key="cell-cursor"
        styleCache={styleCache}
        beaconRef={beaconRef}
        sheetRef={sheetRef}
      />,
    ],
    [beaconRef, sheetRef],
  )

  // Use app dispatch which focuses the cursor beacon prior to returning its
  // result.
  const dispatch = useAppDispatchWithBeacon(beaconRef)

  // Dispatches a query to load NodeInterface records from the server in
  // batches.
  const queryArg = useAppSelector(selectGqlQueryArgForPageOfNodes)
  const batchQuery = useFetchPageOfNodesQuery(queryArg)

  // Extract columns/rows from Redux.
  const selectedFields = useAppSelector(selectFields)
  const [fields, setFields] = useState(
    useMemo(() => computeFieldWidths(selectedFields), [selectedFields]),
  )

  // Listen for container size changes so that columns can be resized
  // Particularly when columns fit grid exactly and the nav is collapsed
  // this allows the extra space to get distributed to the columns
  useEffect(() => {
    const orgchartContainer = document.querySelector(".org-chart-container")
    const resizeObserver = new ResizeObserver(() => setFields(computeFieldWidths(selectedFields)))

    if (orgchartContainer) resizeObserver.observe(orgchartContainer)

    return () => {
      if (orgchartContainer) resizeObserver.unobserve(orgchartContainer)
    }
  }, [setFields, selectedFields])

  const skeleton = useAppSelector(selectDatasheetRows)

  // Dispatches a query for the static collections
  const collectionFields = useMemo(() => getStaticCollectionFieldsFromColumns(fields), [fields])
  const { data: collections } = useFetchCollectionsQuery(
    buildCollectionsQueryFromKeys(collectionFields),
    {
      skip: collectionFields.length === 0,
    },
  )

  // Adds collections to columns.
  const columns = useMemo(
    () => addCollectionsToFields({ fields, collections, collectionFields }),
    [fields, collections, collectionFields],
  )

  useEffect(() => {
    if (
      columns.find((f) => f.fieldKey === "avatar") &&
      columns.find((f) => f.fieldKey === "name")
    ) {
      dispatch(setMergeAvatarAndName(true))
    } else {
      dispatch(setMergeAvatarAndName(false))
    }
  }, [columns, dispatch])

  // Extract follow up modal state from Redux.
  const followUpModal = useAppSelector((state) => state.grid.followUpModal)

  // Determine if avatar cell needs to be merged with the name cell
  const filteredColumns = useMemo(
    () =>
      columns.find((f) => f.fieldKey === "avatar") && columns.find((f) => f.fieldKey === "name")
        ? columns.filter((field) => field.fieldKey !== "avatar")
        : columns,
    [columns],
  )

  // Extract dimension information and normalize columns to use widths based on
  // the extracted information.
  const { fitToRows, gridWidth, heightWithoutToolbar, normalizedColumns, toolbarHeight } =
    useDatasheetDimensions(filteredColumns, skeleton, showGroupData)

  // Prepare callbacks which dispatch redux actions.
  const toggleOrderBy = useCallback(
    (fieldKey: FieldKey) => dispatch(asyncToggleOrderBy({ fieldKey })),
    [dispatch],
  )
  const addFilter = useCallback(
    (fieldKey: FieldKey, term: string) => dispatch(asyncAddFilter({ fieldKey, term })),
    [dispatch],
  )
  const removeFilter = useCallback(
    (fieldKey: FieldKey) => dispatch(asyncRemoveFilter(fieldKey)),
    [dispatch],
  )
  const addColumnGroup = useCallback(
    (fieldKey: string) => dispatch(asyncGroupBy({ fieldKey: fieldKey as FieldKey, useTo: "add" })),
    [dispatch],
  )
  const removeColumnGroup = useCallback(
    (fieldKey: string) =>
      dispatch(asyncGroupBy({ fieldKey: fieldKey as FieldKey, useTo: "remove" })),
    [dispatch],
  )
  const toggleGrouping = useCallback(
    (row: GroupRow) => dispatch(asyncToggleGrouping(row)),
    [dispatch],
  )

  const FollowUpModal = useMemo(() => renderFollowUpModal(followUpModal), [followUpModal])

  // The datasheet depends on both the batchQuery and collections data
  // to properly function, so we delay rendering until both pieces are loaded.
  const dataLoading = batchQuery.isLoading || (collectionFields.length > 0 && !collections)

  return (
    <>
      <Toolbar peripheralsProps={peripheralsProps} />
      <div
        className="org-chart-container page-pad-t page-pad-x grid-rows-[auto_1fr] grid"
        style={{ height: window.innerHeight - toolbarHeight }}
      >
        <DndProvider backend={HTML5Backend}>
          <div>
            {chartId && (
              // Only show inline if 640px +, otherwise show banner in Peripherals.tsx
              <div className="mb-4 hidden sm:block">
                <ChartApprovalBar chartId={chartId} />
              </div>
            )}
            {showGroupData && (
              <ColumnDropzone<NodeInterface>
                columns={normalizedColumns}
                groups={groups}
                onAddColumn={addColumnGroup}
                onRemoveColumn={removeColumnGroup}
              />
            )}
          </div>

          <div
            className={classNames(
              "GridContainer max-h-full w-inherit overflow-hidden rounded-xl border border-solid border-neutral-8-solid",
              { "h-fit": fitToRows },
            )}
          >
            <div className="h-full overflow-x-auto">
              <div className={loaderClassName(!dataLoading)}>
                <LoadingIndicator isLoading={dataLoading}>
                  <MemoedDatasheet<string, NodeInterface>
                    CellComponent={OrgChartDatasheetCell}
                    columns={normalizedColumns}
                    groups={groups}
                    height={heightWithoutToolbar}
                    onClearFilter={removeFilter}
                    onExpandCollapseGrouping={toggleGrouping}
                    onSelectFilter={addFilter}
                    onSortColumn={toggleOrderBy}
                    rows={skeleton}
                    sheetRef={sheetRef}
                    sheetWidth={gridWidth as number}
                    sortBy={sortColumn}
                    sortDirection={sortDirection}
                    cellRangeRenderer={cellRangeRenderer}
                  />
                </LoadingIndicator>
              </div>
              <div className="drawer-overlay" />
            </div>
          </div>
        </DndProvider>
        {FollowUpModal}
      </div>
    </>
  )
}

function useAppDispatchWithBeacon(beaconRef: RefObject<HTMLButtonElement>) {
  const dispatch = useAppDispatch()

  return useCallback(
    (...args: Parameters<typeof dispatch>) => {
      const result = dispatch(...args)
      beaconRef.current?.focus()
      return result
    },
    [beaconRef, dispatch],
  )
}

function useDatasheetDimensions(columns: Field[], rows: SkeletonRow[], showGroupData: boolean) {
  const [dimensions, setDimensions] = useState<{ width: number; height: number }>(InitialDimensions)
  const [normalizedColumns, setNormalizedColumns] = useState<Column<NodeInterface>[]>([])
  const { width } = useWindowSize()
  const [toolbarHeight, setToolbarHeight] = useState(
    width < 768 ? TOOLBAR_SMALL_SC_HEIGHT : TOOLBAR_LARGE_SC_HEIGHT,
  )

  const captureAndSetDimensions = useCallback(() => {
    const orgchartContainer = document.querySelector(".org-chart-container")
    const orgchartContainerWidth = orgchartContainer?.getBoundingClientRect().width || 0
    const orgchartContainerHeight = orgchartContainer?.getBoundingClientRect().height || 0

    setDimensions(() => ({
      width: orgchartContainerWidth,
      height: orgchartContainerHeight,
    }))
  }, [])

  const normalizeAndSetColumns = useCallback(() => {
    const orgchartContainer = document.querySelector(".org-chart-container")
    const orgchartContainerWidth = orgchartContainer?.getBoundingClientRect().width || 0

    const containerPadding = (() => {
      if (window.innerWidth >= 1120) return 64
      if (window.innerWidth >= 640) return 48
      return 24
    })()

    setNormalizedColumns(
      normalizeColumns(
        columns,
        Math.max(
          orgchartContainerWidth - containerPadding - TABLE_BORDER,
          fp.reduce((sum, column) => (column.width ?? 0) + sum, 0, columns),
        ),
      ),
    )
  }, [columns])

  useEffect(() => {
    const element = document.querySelector(".org-chart-container")

    const resizeObserver = new ResizeObserver(() => {
      captureAndSetDimensions()
      normalizeAndSetColumns()
    })

    if (element) resizeObserver.observe(element)

    return () => {
      if (element) resizeObserver.unobserve(element)
    }
  }, [captureAndSetDimensions, normalizeAndSetColumns, columns.length])

  useEffect(() => {
    captureAndSetDimensions()
    // Need this to run when hiding/showing the dropzone box for grouping data
  }, [captureAndSetDimensions, showGroupData])

  useEffect(() => {
    const alertTop = document.querySelector(".alert-top")?.getBoundingClientRect().height || 0
    const toolbarHeight =
      (width < 768 ? TOOLBAR_SMALL_SC_HEIGHT : TOOLBAR_LARGE_SC_HEIGHT) + alertTop
    setToolbarHeight(toolbarHeight)
  }, [width])

  const gridWidth = useMemo(
    () => fp.reduce((sum, column) => column.width + sum, 0, normalizedColumns),
    [normalizedColumns],
  )

  const availableHeight = dimensions.height - (toolbarHeight || 0)
  const totalRowHeight = rows.length * ROW_HEIGHT_PX
  const fitToRows = totalRowHeight < availableHeight
  const heightWithoutToolbar = fitToRows ? totalRowHeight : availableHeight

  return {
    fitToRows,
    gridWidth,
    heightWithoutToolbar,
    normalizedColumns,
    toolbarHeight,
  }
}

const loaderClassName = (isLoaded: boolean) =>
  classNames("GridLoader", { "GridLoader-is-loading": !isLoaded })

function normalizeColumns(columns: Field[], width: number): Column<NodeInterface>[] {
  const widthOrZero = fp.propOr<Field, "width", number>(0, "width")
  const definedColWidths = fp.sumBy(widthOrZero, columns)
  const colCountUsingAutoWidth = fp.sumBy((col) => (col.width ? 0 : 1), columns)
  const remainingWidth = width - definedColWidths

  const withNormalizedWidthValue = (width: Maybe<number>) => {
    if (typeof width === "number") return width
    if (colCountUsingAutoWidth === 0) return 0
    return remainingWidth / colCountUsingAutoWidth
  }

  return fp.map(fp.update("width", withNormalizedWidthValue), columns)
}

function computeFieldWidths(selectedFields: Field[]) {
  const navOpen = getCookie("nav_open_state") === "true"
  const navWidth =
    navOpen && window.innerWidth > FIXED_NAV_SCREEN_WIDTH ? NAV_FULL_WIDTH : NAV_COLLAPSED_WIDTH

  const fields: Field[] = selectedFields.map((field) => ({
    ...field,
    width: field.width
      ? field.width
      : Math.max(
          MIN_COL_WIDTH,
          Math.min(calculateTextWidth(field.fieldKey, field.label), MAX_COL_WIDTH),
        ),
  }))

  const totalWidth = fp.reduce((sum, field) => (field.width ?? 0) + sum, 0, fields)

  if (totalWidth > document.body.clientWidth - navWidth) return fields

  // Makes sure base columns spread to available width
  if (fields.length <= 3) return selectedFields

  return fields.map((field: Field) => {
    if (isMaxWidthColumn(field.fieldKey)) {
      return {
        ...field,
        width: MAX_COL_WIDTH,
      }
    }
    return {
      ...field,
      width: field.width === 40 ? 56 : undefined,
    }
  })
}

// https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript
function calculateTextWidth(fieldKey: string, text: string) {
  if (isMaxWidthColumn(fieldKey)) return MAX_COL_WIDTH

  const headerFont = "700 20px Satoshi, sans-serif"
  const canvas = document.createElement("canvas")
  const context = canvas.getContext("2d")
  if (!context) return MIN_COL_WIDTH
  context.font = headerFont
  const columnWidth = context.measureText(text).width
  return (columnWidth < MIN_COL_WIDTH ? MIN_COL_WIDTH : columnWidth) + ADD_COL_HEADER_SPACE
}

export { OrgChartDatasheet }
