import React, { ChangeEvent } from "react"

interface Props {
  inputName: string
  onChange: (selectedValue: boolean) => void
  value: boolean
}

function YesNoToggle({ inputName, onChange, value }: Props) {
  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value: boolean = event.currentTarget.value === "true"
    onChange(value)
  }

  return (
    <div className="mb-2">
      {/*
      NOTE: onChange/mouse handlers are impacted by jQuery events (they may 
      be stopped/removed if jQuery events are attached in related DOM elements)
      */}
      <label className="radio" htmlFor={`${inputName}-yes`}>
        <input
          type="radio"
          onChange={handleRadioChange}
          id={`${inputName}-yes`}
          name={inputName}
          value="true"
          checked={value}
        />
        {"Yes".t("defaults")}
      </label>
      <label className="radio" htmlFor={`${inputName}-no`}>
        <input
          type="radio"
          onChange={handleRadioChange}
          id={`${inputName}-no`}
          name={inputName}
          value="false"
          checked={!value}
        />
        {"No".t("defaults")}
      </label>
    </div>
  )
}

export { YesNoToggle }
