/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const handleShowAddNewPersonForm = function (e, $nameInput) {
  e.preventDefault()
  e.stopPropagation()

  // This is the distance the subform will be from the bottom of the input
  const SUB_FORM_MARGIN = 8

  // Add a disabled state to the main modal button (if it exists)
  toggleModalSubmitButtonState(true)

  const $autocompleteContainer = $nameInput.siblings(".autocomplete-container")
  $autocompleteContainer.hide()

  const fullName = $nameInput.val().trim()
  const firstName = fullName.substr(0, fullName.indexOf(" "))
  const lastName = fullName.substr(fullName.indexOf(" ") + 1)

  const $addNewPersonForm = $("#add-new-person-form")
  // Ensures that we have a pointer to the relevant name input
  $addNewPersonForm.data("for-name-input-id", $nameInput.attr("id"))

  const $firstNameInput = $addNewPersonForm.find("#add-new-person-form-first-name")
  const $lastNameInput = $addNewPersonForm.find("#add-new-person-form-last-name")

  $firstNameInput.val(!firstName || !lastName ? fullName : firstName)
  $lastNameInput.val(!firstName || !lastName ? "" : lastName)

  // Reinitialize create/cancel event handlers
  const $createNewPerson = $addNewPersonForm.find("#create-person-action")
  $createNewPerson.off("click")
  $createNewPerson.on("click", (e) => saveNewPerson(e, $nameInput))

  const $cancelCreatePerson = $addNewPersonForm.find("#cancel-create-person")
  $cancelCreatePerson.off("click")
  $cancelCreatePerson.on("click", (e) => handleCreatePersonFormCancel(e, $nameInput))

  // Position the add new person form below the name input, then focus the first name input
  const $inputContainer = $nameInput.parent()
  $addNewPersonForm.css(
    "top",
    $inputContainer.position().top + $inputContainer.height() + SUB_FORM_MARGIN,
  )
  $addNewPersonForm.removeClass("hidden")
  return $firstNameInput.focus()
}

var toggleModalSubmitButtonState = function (disable) {
  const $modalSubmitButton = $(".modal-footer input[type=submit]")

  if (disable) {
    $modalSubmitButton?.prop("disabled", true)
    return $modalSubmitButton?.addClass("disabled")
  } else {
    $modalSubmitButton?.prop("disabled", false)
    return $modalSubmitButton?.removeClass("disabled")
  }
}

const removeCreatePersonErrors = function () {
  $("#add-new-person-form .input-group").removeClass("form-error")
  return $(".create-person-error .form-error-message").remove()
}

const addPersonRow = function (e) {
  e.preventDefault()

  const $inputGroup = $(this).parents(".input-group")
  const $newForm = $inputGroup.find(".person-form").last().clone()
  $newForm.find(".autocomplete-container").remove()
  const oldIndex = parseInt($newForm.data("index"))
  const newIndex = oldIndex + 1
  $newForm.data("index", newIndex)
  $newForm.attr("data-index", newIndex)

  const $idInput = $newForm.find(".person-id")
  $idInput.val("")

  $idInput.attr("name", $idInput.attr("name").replace(oldIndex, newIndex))
  $idInput.attr("id", $idInput.attr("id").replace(oldIndex, newIndex))

  const $nameInput = $newForm.find(".person-name")
  $nameInput.val("")
  $nameInput.attr("name", $nameInput.attr("name").replace(oldIndex, newIndex))
  $nameInput.attr("id", $nameInput.attr("id").replace(oldIndex, newIndex))
  $nameInput.data("id", $nameInput.data("id").replace(oldIndex, newIndex))
  $nameInput.attr("data-id", $nameInput.data("id"))

  const $positionManager = $newForm.find(".position-manager-wrapper")
  $positionManager.empty()
  $positionManager.data(
    "pjax-container",
    $positionManager.data("pjax-container").replace(oldIndex, newIndex),
  )
  $positionManager.attr("data-pjax-container", $positionManager.data("pjax-container"))

  const $employeeTypeManager = $newForm.find(".employee-type-manager-wrapper")
  $employeeTypeManager.empty()
  $employeeTypeManager.data(
    "pjax-container",
    $employeeTypeManager.data("pjax-container").replace(oldIndex, newIndex),
  )
  $employeeTypeManager.attr("data-pjax-container", $employeeTypeManager.data("pjax-container"))

  $inputGroup.find("#filled-by-list").append($newForm)

  $.onmount()
  $newForm.each(applyPersonEvents)
  $("#add-person-row").addClass("hidden")
  $nameInput.trigger("focus")
  return updateExcludedPersonIds()
}

const handleUnassignButtonClick = function (e, $unassignButton) {
  e.preventDefault()

  const $targetNameInput = $unassignButton.siblings(".person-name")
  $targetNameInput.val("")
  $targetNameInput.attr("value", "")
  $targetNameInput.trigger("focus")

  const $inputWrapper = $unassignButton.parent()
  $inputWrapper.siblings(".person-id").val("")
  $inputWrapper.siblings(".position-manager-wrapper").empty()
  $inputWrapper.siblings(".employee-type-manager-wrapper").empty()

  if ($(".person-form").length > 1) {
    $unassignButton.parents(".person-form").remove()
  }

  handleUnassignButtonRendering()
  handleAddPersonButtonRendering()
  return updateExcludedPersonIds()
}

var applyPersonEvents = function () {
  const $personNameInput = $(this).find(".person-name")

  // Ensures the new person form can be accessed via keyboard
  $personNameInput.on("keydown", function (e) {
    const $highlighted = $(this).siblings(".autocomplete-container:visible").find(".highlight")
    if (!$highlighted?.hasClass("add-new-person")) {
      return
    }
    const key = e.which
    if (Keyboard.keyMatchesName(key, "enter")) {
      return handleShowAddNewPersonForm(e, $personNameInput)
    }
  })

  // Handles clearing things out / rendering dependencies when the name field is changed
  $personNameInput.on("input", function (e) {
    handleUnassignButtonRendering()
    const personIdExists = $(this).parent().siblings(".person-id").val().length > 0
    if (!personIdExists) {
      return
    }

    $(this).parent().siblings(".person-id").val("")
    $(this).parent().siblings(".position-manager-wrapper").empty()
    $(this).parent().siblings(".employee-type-manager-wrapper").empty()
    $("#add-person-row").addClass("hidden")
    return updateExcludedPersonIds()
  })

  $(this)
    .find(".unassign-button")
    .on("click", function (e) {
      return handleUnassignButtonClick(e, $(this))
    })

  $personNameInput.on("blur", function (e) {
    const $relatedTarget = $(e.relatedTarget)

    if ($relatedTarget.hasClass("unassign-button")) {
      handleUnassignButtonClick(e, $relatedTarget)
    }

    // If we're opening the add new person form, we don't want to clear the data.
    const EXCLUDED_IDS = ["show-add-new-person-form", "add-new-person-form-first-name"]
    const relatedTargetId = $relatedTarget.attr("id")
    if (EXCLUDED_IDS.includes(relatedTargetId)) {
      return
    }
    return handleNameInputBlur($(this))
  })

  return $personNameInput.on("autocomplete:selectSuggestion", function (e, selection) {
    const personId = $(selection).data("id")
    loadPositionManager($(this), personId)
    loadEmployeeTypeManager($(this), personId)
    $("#add-person-row").removeClass("hidden")
    $personNameInput.attr("value", $personNameInput.val())
    $personNameInput.siblings(".unassign-button").removeClass("hidden")
    return updateExcludedPersonIds()
  })
}

var handleNameInputBlur = function ($input) {
  const personIdExists = $input.parent().siblings(".person-id").val().length > 0
  const currentInput = $input.val()
  const storedInput = $input.attr("value")

  // We've cleared the input or otherwise changed the value, so we need to
  // clear the person data and associated managers.
  if (currentInput !== storedInput || !personIdExists) {
    const $inputWrapper = $input.parent()
    $inputWrapper.siblings(".person-id").val("")
    $inputWrapper.siblings(".position-manager-wrapper").empty()
    $inputWrapper.siblings(".employee-type-manager-wrapper").empty()
    $input.val("")
    $input.attr("value", "")
    handleUnassignButtonRendering()
    handleAddPersonButtonRendering()
    return updateExcludedPersonIds()
  }
}

var handleUnassignButtonRendering = function () {
  const $personForm = $(".person-form")
  if ($personForm.length === 1) {
    const valueIsPresent = $personForm.find(".person-name").val().length > 0
    const $unassignButton = $personForm.find(".unassign-button")

    if (valueIsPresent) {
      return $unassignButton.removeClass("hidden")
    } else {
      return $unassignButton.addClass("hidden")
    }
  }
}

var handleAddPersonButtonRendering = function () {
  // We show the add person button if there are no empty name fields.
  const $nameInputs = $(".person-form").find(".person-name")
  let anyEmptyNameFields = false

  $nameInputs.each(function (index, nameInput) {
    if ($(nameInput).val().trim().length === 0) {
      anyEmptyNameFields = true
      return
    }
  })
  const $addPersonButton = $("#add-person-row")

  if (anyEmptyNameFields) {
    return $addPersonButton.addClass("hidden")
  } else {
    return $addPersonButton.removeClass("hidden")
  }
}

// This ensures you can't attempt to add the same person to a position twice.
var updateExcludedPersonIds = function () {
  const $filledByAutocompletes = $(".person-name")
  const excludedIds = []
  $(".person-id").each((index, personId) => excludedIds.push($(personId).attr("value")))

  return $filledByAutocompletes.each(function (index, autocompleteInstance) {
    const $autocompleteInstance = $(autocompleteInstance)
    const autocompleteData = $autocompleteInstance.data()
    const autocompleteParams = autocompleteData["autocompleteParams"]
    const updatedAutocompleteParams = Object.assign(autocompleteParams, {
      exclude: excludedIds.join(","),
    })
    const updatedData = Object.assign(autocompleteData, updatedAutocompleteParams)
    return $autocompleteInstance.data(updatedData)
  })
}

var saveNewPerson = function (e, $nameInput) {
  e.preventDefault()
  removeCreatePersonErrors()
  const firstName = $("#add-new-person-form-first-name").val()
  const lastName = $("#add-new-person-form-last-name").val()

  // Set button to saving state
  const $createNewPerson = $("#create-person-action")
  $createNewPerson?.prop("disabled", true)
  $createNewPerson?.addClass("disabled")
  $createNewPerson?.text("add_disabled".t("button_defaults"))

  return $.ajax({
    type: "POST",
    url: "/api/app/v1/people",
    data: {
      person: { first_name: firstName, last_name: lastName },
      use_detailed_errors: true,
    },
    success(data) {
      const autocomplete = $nameInput.data("autocomplete-instance")
      autocomplete.cachedResponse = {}
      $nameInput.parent().siblings(".person-id").val(`${data["id"]}`)
      $nameInput.val(data["first_name"].concat(" ", data["last_name"]))
      $nameInput.attr("value", $nameInput.val())
      $nameInput.parent().show()

      $("#add-new-person-form").addClass("hidden")
      handleUnassignButtonRendering()
      handleAddPersonButtonRendering()
      removeCreatePersonErrors()
      return toggleModalSubmitButtonState(false)
    },
    error(e) {
      const errors = e["responseJSON"]["errors"]
      const lastNameErrors = errors["last_name"]
      const firstNameErrors = errors["first_name"]

      if (firstNameErrors) {
        $("#add-new-person-form-first-name").closest(".input-group").addClass("form-error")
        $("#add-new-person-form-first-name-error").append(
          $('<div class="form-error-message"></div>').text(firstNameErrors.join(", ")),
        )
      }
      if (lastNameErrors) {
        $("#add-new-person-form-last-name").closest(".input-group").addClass("form-error")
        return $("#add-new-person-form-last-name-error").append(
          $('<div class="form-error-message"></div>').text(lastNameErrors.join(", ")),
        )
      }
    },
    complete() {
      $createNewPerson?.removeClass("disabled")
      $createNewPerson?.prop("disabled", false)
      return $createNewPerson?.text("add_enabled".t("button_defaults"))
    },
  })
}

var loadPositionManager = function ($nameInput, personId) {
  const wrapper = $nameInput.parent().siblings(".position-manager-wrapper")
  const containerName = wrapper.data("pjax-container")
  const chartId = wrapper.data("chart-id")
  let url = `/positions/positions_manager/person/${personId}/chart/${chartId}`
  if ($(".modal").hasClass("edit-position-modal")) {
    const positionId = $(".modal form").attr("action").split("/").pop()
    url = `/positions/${positionId}/positions_manager/person/${personId}/chart/${chartId}`
  }

  $.pjax({
    url,
    container: `[data-pjax-container=${containerName}]`,
    push: false,
    data: { index: $nameInput.parents(".person-form").data("index") },
  })
  return ($.pjax.xhr = null)
}

var loadEmployeeTypeManager = function ($nameInput, personId) {
  const action = $(".modal form").prop("action")
  const containerName = $nameInput
    .parent()
    .siblings(".employee-type-manager-wrapper")
    .data("pjax-container")

  $.pjax({
    url: `${action}/employee_type_manager/${personId}`,
    container: `[data-pjax-container=${containerName}]`,
    push: false,
  }).done(function () {
    if ($(this).find(".employee-type-manager").text() === "") {
      return $("#position_employee_type_id").val(
        $(this).find(".employee-type-manager").data("employee-type-id"),
      )
    }
  })
  return ($.pjax.xhr = null)
}

var handleCreatePersonFormCancel = function (e, $nameInput) {
  // Remove the disabled state from the main modal button (if it exists)
  toggleModalSubmitButtonState(false)

  const $addNewPersonForm = $("#add-new-person-form")

  // Hide the form and clear inputs
  $addNewPersonForm.addClass("hidden")
  $addNewPersonForm.find("#add-new-person-form-first-name").val("")
  $addNewPersonForm.find("#add-new-person-form-last-name").val("")
  removeCreatePersonErrors()

  // If the cancel button or the name input was clicked, focus the name input
  const shouldFocusInput =
    $(e.target).attr("id") === "cancel-create-person" || $nameInput.is(e.target)
  if (shouldFocusInput) {
    e.preventDefault()
    $nameInput.focus()
  }

  const isFocusedOnInput = document.activeElement === $nameInput.get(0)
  // Show autocomplete if there's a value in the name input + we're focused on it
  if (isFocusedOnInput && $nameInput.val().length > 0) {
    const $autocompleteContainer = $nameInput.siblings(".autocomplete-container")
    return $autocompleteContainer.show()
  } else {
    return handleNameInputBlur($nameInput)
  }
}

const addNewPersonFormEvents = function () {
  const $addNewPersonForm = $("#add-new-person-form")
  const formIsVisible = () => $addNewPersonForm.is(":visible")
  const nameInput = () => $(`#${$addNewPersonForm.data("for-name-input-id")}`)

  // Ensures the popover closes when clicking outside of it
  $(document).mousedown(function (e) {
    if (!formIsVisible()) {
      return
    }
    if (!$addNewPersonForm.is(e.target) && !($addNewPersonForm.has(e.target).length > 0)) {
      return handleCreatePersonFormCancel(e, nameInput())
    }
  })

  // Ensures the popover closes when tabbing out of it
  $addNewPersonForm.on("focusout", function (e) {
    if (!formIsVisible()) {
      return
    }
    return setTimeout(function () {
      if (document.activeElement === document.body) {
        return
      }
      if ($addNewPersonForm.has(document.activeElement).length) {
        return
      }
      return handleCreatePersonFormCancel(e, nameInput())
    }, 0)
  })

  return $addNewPersonForm.on("keydown", function (e) {
    if (!formIsVisible()) {
      return
    }
    const key = e.which
    // Prevents enter from saving the parent modal - instead we save the subform.
    if (Keyboard.keyMatchesName(key, "enter") && $(e.target).hasClass("input")) {
      return saveNewPerson(e, nameInput())
    }
  })
}

const events = function (scope) {
  addNewPersonFormEvents()
  $(scope).find("[data-action=add-person-row]").off("click").on("click", addPersonRow)
  $(".person-form").each(applyPersonEvents)
  $(scope).on("autocomplete:renderSuggestions", function (e) {
    const $nameInput = $(e.target)
    const $showAddNewPersonForm = $nameInput
      .siblings(".autocomplete-container")
      .find("#show-add-new-person-form")
    return $showAddNewPersonForm
      .off("mousedown")
      .on("mousedown", (e) => handleShowAddNewPersonForm(e, $nameInput))
  })
  return $(scope).on("autocomplete:clearSelected", function (e) {
    const targetInputId = $(e.target).data("id")
    if (targetInputId?.startsWith("position_people_")) {
      handleAddPersonButtonRendering()
      return updateExcludedPersonIds()
    }
  })
}

export { events }
