import { globals } from "../constants/options"
import statsHelpers from "./statsHelpers"

function DragHelper(context, options, positioningHelper) {
  this.context = context
  this.options = options
  this.positioningHelper = positioningHelper
  const { d3 } = window

  const intersectArea = function (a, b) {
    const x12 = a.left + a.width
    const x22 = b.left + b.width
    const x11 = a.left
    const x21 = b.left
    const y12 = a.top + a.height
    const y22 = b.top + b.height
    const y11 = a.top
    const y21 = b.top
    const width = Math.max(0, Math.min(x12, x22) - Math.max(x11, x21))
    const height = Math.max(0, Math.min(y12, y22) - Math.max(y11, y21))
    const areas = [width * height, a.width * a.height]
    return (Math.min(areas[0], areas[1]) / Math.max(areas[0], areas[1])) * 100
  }
  this.intersectArea = intersectArea

  // Grab x and y translate coordinates for an element
  const translateCoordinates = (element) => {
    const transform = element.transform.baseVal.consolidate()
    if (!transform) {
      return [0, 0]
    }

    const { matrix } = element.transform.baseVal.consolidate()

    return [matrix.e, matrix.f]
  }

  const getIntersectingNodesByElement = function (element) {
    return context.selectAll("g.node").filter(function () {
      const potentialTargetNode = this
      if (potentialTargetNode === element) {
        return false
      }
      if (nodeIsDescendant.call(this)) return false

      const targetNodeData = d3.select(this).data()[0]

      if (
        !targetNodeData.is_assistant &&
        intersectArea(
          element.getBoundingClientRect(),
          potentialTargetNode.getBoundingClientRect(),
        ) > 50 &&
        (gon.can_manage_chart ||
          options.limitedAdminPositionIds?.indexOf(targetNodeData.id) >= 0 ||
          options.subordinateIds?.indexOf(targetNodeData.id) >= 0)
      ) {
        return true
      }
      return false
    })
  }

  const nodeDimensions = (element) => {
    const coordinates = translateCoordinates(element)
    return {
      left: coordinates[0],
      width: positioningHelper.getNodeWidth(),
      top: coordinates[1],
      height: positioningHelper.getNodeHeight(),
    }
  }

  const intersectAreaByTranslate = (elementA, elementB) =>
    intersectArea(nodeDimensions(elementA), nodeDimensions(elementB))
  this.intersectAreaByTranslate = intersectAreaByTranslate

  const getIntersectingNodesByBoundingBox = function (boundingBox) {
    return context.selectAll("g.node").filter(function () {
      const potentialTargetNode = this
      if (intersectArea(boundingBox, potentialTargetNode.getBoundingClientRect()) > 50) {
        return true
      }
      return false
    })
  }

  this.removeTempLinks = function () {
    context.selectAll("path.link.temporary").remove()
  }

  this.clearDropzones = function () {
    context
      .selectAll("g.node.shifted")
      .classed("shifted", false)
      .transition()
      .duration(options.animationDuration)
      .attr("transform", (d) => {
        d.x0 = d.x
        return `translate(${d.x0}, ${d.y0})`
      })

    return context.selectAll("g.node").classed("dropzone", false)
  }

  this.getTargetNodeByElement = function (element) {
    let intersectingElements = getIntersectingNodesByElement(element)[0]
    intersectingElements = intersectingElements.sort((a, b) =>
      d3.descending(
        intersectArea(element.getBoundingClientRect(), a.getBoundingClientRect()),
        intersectArea(element.getBoundingClientRect(), b.getBoundingClientRect()),
      ),
    )
    return d3.select(intersectingElements[0])
  }

  this.getTargetNodeByBoundingBox = function (boundingBox) {
    let intersectingElements = getIntersectingNodesByBoundingBox(boundingBox)[0]
    intersectingElements = intersectingElements.sort((a, b) =>
      d3.descending(
        intersectArea(boundingBox, a.getBoundingClientRect()),
        intersectArea(boundingBox, b.getBoundingClientRect()),
      ),
    )
    return d3.select(intersectingElements[0])
  }

  this.closestNodeInRow = function (element) {
    const coordinates = translateCoordinates(element)
    const eX = coordinates[0]
    const eY = coordinates[1]

    let nodesOnLevel = context.selectAll("g.node").filter(function () {
      const targetNodeData = d3.select(this).data()[0]
      if (
        this === element ||
        (!gon.can_manage_chart &&
          options.subordinateIds &&
          options.subordinateIds.indexOf(targetNodeData.id) < 0)
      ) {
        return false
      }

      if (nodeIsDescendant.call(this)) return false
      const pY = translateCoordinates(this)[1]

      if (Math.abs(pY - eY) < globals.closestNodeDistance) {
        return true
      }

      return false
    })[0]

    nodesOnLevel = nodesOnLevel.sort((a, b) => {
      const aX = translateCoordinates(a)[0]
      const bX = translateCoordinates(b)[0]

      return d3.ascending(Math.abs(eX - aX), Math.abs(eX - bX))
    })

    return d3.select(nodesOnLevel[0])
  }

  const closestNodeInColumn = function (element) {
    const eY = translateCoordinates(element)[1]
    const eX = translateCoordinates(element)[0]

    let nodesOnLevel = context.selectAll("g.node").filter(function (d) {
      const targetNodeData = d3.select(this).data()[0]
      if (
        this === element ||
        d.depth < 2 ||
        (!gon.can_manage_chart &&
          options.subordinateIds &&
          options.subordinateIds.indexOf(targetNodeData.id) < 0)
      ) {
        return false
      }
      if (nodeIsDescendant.call(this)) return false

      const pX = translateCoordinates(this)[0]
      return Math.abs(eX - pX) < globals.closestNodeDistance
    })[0]

    nodesOnLevel = nodesOnLevel.sort((a, b) => {
      const aY = translateCoordinates(a)[1]
      const bY = translateCoordinates(b)[1]

      return d3.ascending(Math.abs(eY - aY), Math.abs(eY - bY))
    })

    if (nodesOnLevel[0]) {
      return d3.select(nodesOnLevel[0])
    }

    return null
  }

  // During a drag, descending nodes are set to `visibility="hidden"`. This
  // ensures that "drop" events don't get fired on invisible descending nodes.
  const nodeIsDescendant = function () {
    return d3.select(this).attr("visibility") === "hidden"
  }

  this.closestNodeInColumn = closestNodeInColumn

  /**
   * Returns the closest column node to the draggable element
   * within a set distance.
   */
  this.condensedLeafNode = function (element) {
    const closestNode = closestNodeInColumn(element)
    if (!closestNode?.node()) {
      return null
    }

    const eY = d3.select(element).data()[0].y0
    const cY = closestNode.datum().y0

    if (Math.abs(eY - cY) < globals.condensedLeafDistance) {
      return closestNode
    }

    return null
  }

  this.sortNodesByOrder = function (nodes) {
    nodes
      .sort((a, b) => d3.ascending(a.sort_order, b.sort_order))
      // eslint-disable-next-line no-return-assign, no-param-reassign
      .forEach((node, index) => (node.sort_order = index))
  }

  this.sortNodesByXPosition = function (nodes) {
    nodes
      .sort((a, b) => d3.ascending(a.x0, b.x0))
      // eslint-disable-next-line no-return-assign, no-param-reassign
      .forEach((node, index) => (node.sort_order = index))
  }

  /**
   * Handles the drag and drop reordering logic for nodes in a classic tree
   * layout.
   *
   * Note: Mutates properties on the nodes passed in.
   *
   * @param {*} selectedParentNode
   * @param {*} previousParentNode
   */
  this.sortClassicTreeNodesAfterDrop = function (selectedParentNode, previousParentNode) {
    this.sortNodesByXPosition(selectedParentNode.children)
    const shouldSortPreviousParent =
      selectedParentNode !== previousParentNode && previousParentNode?.children
    if (shouldSortPreviousParent) {
      const previousParentInCondensedLayout =
        this.options.displayMode === "tree" &&
        this.options.condensed &&
        statsHelpers.numberOfGrandchildren(previousParentNode) === 0 &&
        statsHelpers.numberOfChildren(previousParentNode) > 2

      if (previousParentInCondensedLayout) {
        this.sortNodesByOrder(previousParentNode.children)
      } else {
        this.sortNodesByXPosition(previousParentNode.children)
      }
    }
  }

  /**
   * Handles the drag and drop reordering logic for nodes in a condensed tree
   * layout. The logic we enforce here is that dragging one node above another
   * node moves it directly in front in the sort order.
   *
   * The sort order in this layout goes from left to right, then down to the
   * next row.
   *
   * Note: Mutates properties on the nodes passed in.
   *
   * @param {*} selectedParentNode
   * @param {*} draggingNode
   */
  this.sortCondensedTreeNodesAfterDrop = function (selectedParentNode, draggingNode) {
    const isLeft = (node) => node.x0 < selectedParentNode.x0
    const previousParentNode = draggingNode.parent
    const shouldSortPreviousParent =
      selectedParentNode !== previousParentNode && previousParentNode?.children

    const levels = Array.from(
      selectedParentNode.children.reduce((acc, child) => {
        const level = acc.get(child.y0) || { left: null, right: null }
        // eslint-disable-next-line no-unused-expressions
        isLeft(child) ? (level.left = child) : (level.right = child)
        return acc.set(child.y0, level)
      }, new Map()),
    )
      .sort(([a], [b]) => a - b)
      .map(([, level]) => level)

    const levelCount = levels.length
    const childrenCount = selectedParentNode.children.length
    if (levelCount < Math.ceil(childrenCount / 2)) {
      // If the number of levels is less than half the number of
      // children, we're likely in a state where we're transitioning
      // from the classic sibling layout to the condensed tree layout.
      // In this case, we'll want to preserve whatever sort order
      // was present in the classic layout.
      this.sortClassicTreeNodesAfterDrop(selectedParentNode, previousParentNode)
      return
    }

    const draggingNodeIsLeft = isLeft(draggingNode)
    const indexOfDraggingNodeLevel = levels.findIndex(
      (level) => level.left === draggingNode || level.right === draggingNode,
    )
    const draggingNodeLevel = levels[indexOfDraggingNodeLevel]
    const prevLevel = levels[indexOfDraggingNodeLevel - 1]
    const nextLevel = levels[indexOfDraggingNodeLevel + 1]
    const levelAboveHasOnlyOneNode = !!prevLevel?.left !== !!prevLevel?.right
    const draggingNodeIsAtBottom = !levels?.[indexOfDraggingNodeLevel + 1]
    const draggingNodeIsAlone = !!draggingNodeLevel?.left + !!draggingNodeLevel?.right === 1
    const lastLevel = levels[levels.length - 1]

    let nodeAfterDraggingNode = null
    if (draggingNodeIsAtBottom && draggingNodeIsAlone) {
      // If the dragging node is at the bottom, place it at the end
      // of the tree.
      nodeAfterDraggingNode = null
    } else if (!draggingNodeIsAlone) {
      // It's rare, but in the case the user drags the node to be exactly
      // on the same level of another, we ensure the node is placed
      // correctly within that level.
      if (draggingNodeIsLeft) {
        nodeAfterDraggingNode = draggingNodeLevel.right
      } else {
        nodeAfterDraggingNode = nextLevel?.left || nextLevel?.right
      }
    } else if (
      levelAboveHasOnlyOneNode &&
      !(nextLevel === lastLevel && !nextLevel.right && !draggingNodeIsLeft)
    ) {
      // If the level above has only one node, we join the dragging node with
      // that level in the correct position.  Note the exception for when we are
      // dragging a node to an open bottom right position. In that case we want
      // to place that node at the bottom of the tree.
      if (draggingNodeLevel.left && prevLevel.right) {
        nodeAfterDraggingNode = prevLevel.right
      } else {
        nodeAfterDraggingNode = nextLevel?.left || nextLevel?.right
      }
    } else {
      // Otherwise, we ensure it is placed in the correct position by
      // identifying the node that should come after it.
      const twoLevelsAfter = levels[indexOfDraggingNodeLevel + 2]

      if (draggingNodeIsLeft) {
        nodeAfterDraggingNode =
          nextLevel?.left || nextLevel?.right || twoLevelsAfter?.left || twoLevelsAfter?.right
      } else {
        nodeAfterDraggingNode = nextLevel?.right || twoLevelsAfter?.left || twoLevelsAfter?.right
      }
    }

    // eslint-disable-next-line no-param-reassign
    draggingNode.sort_order =
      typeof nodeAfterDraggingNode?.sort_order === "number"
        ? nodeAfterDraggingNode.sort_order - 0.5
        : selectedParentNode.children.length

    this.sortNodesByOrder(selectedParentNode.children)
    if (shouldSortPreviousParent) {
      const previousParentInCondensedLayout =
        this.options.displayMode === "tree" &&
        this.options.condensed &&
        statsHelpers.numberOfGrandchildren(previousParentNode) === 0 &&
        statsHelpers.numberOfChildren(previousParentNode) > 1

      if (previousParentInCondensedLayout) {
        this.sortNodesByOrder(previousParentNode.children)
      } else {
        this.sortNodesByXPosition(previousParentNode.children)
      }
    }
  }
}

export default DragHelper
