import axios from "axios"
import React, { useEffect, useState } from "react"

import {
  LoginSettings,
  LoginSettingsErrors,
} from "v2/react/components/company/generalSettings/types"
import { REST_API_BASE_URL } from "v2/react/constants"

import { AuthenticationForm } from "./AuthenticationForm"

interface Props {
  companyId: number
  isOktaConfigured: boolean
  loginSettings?: LoginSettings
  onSaveSuccess: (updatedData: LoginSettings) => void
}

const defaultFormData: LoginSettings = {
  isSsoRequired: false,
  permittedSsoProviders: [],
  ssoAzureTenantIdRestrictions: [],
  ssoGoogleDomainRestrictions: [],
}

function AuthenticationFormContainer({
  companyId,
  loginSettings,
  isOktaConfigured,
  onSaveSuccess,
}: Props) {
  const [isSaving, setIsSaving] = useState(false)
  const [formErrors, setFormErrors] = useState<LoginSettingsErrors | undefined>()
  const [formData, setFormData] = useState<LoginSettings>(loginSettings || defaultFormData)

  useEffect(() => {
    setFormData(loginSettings || defaultFormData)
  }, [loginSettings])

  const onFormDataChange = (field: string, value: boolean | string | string[]) => {
    const updatedValues: LoginSettings = {
      ...formData,
      [field]: value,
    }
    setFormData(updatedValues)
  }

  const onSave = () => {
    const data = {
      company: {
        id: companyId,
        sso_required: formData?.isSsoRequired,
        permitted_sso_providers: formData?.permittedSsoProviders,
        sso_azure_tenant_id_restrictions: formData?.ssoAzureTenantIdRestrictions,
        sso_google_domain_restrictions: formData?.ssoGoogleDomainRestrictions,
      },
    }

    const saveLoginSettings = async () => {
      setIsSaving(true)
      await axios.post(`${REST_API_BASE_URL}/companies/${companyId}/login_settings.json`, data)
      setIsSaving(false)
      onSaveSuccess(formData)
    }

    saveLoginSettings().catch((error) => {
      setIsSaving(false)
      const errors: LoginSettingsErrors = error.response.data.errors
      setFormErrors(errors)
    })
  }

  return (
    <div>
      {loginSettings && (
        <AuthenticationForm
          formErrors={formErrors}
          isLoading={loginSettings === undefined}
          isSaving={isSaving}
          isOktaConfigured={isOktaConfigured}
          isSsoRequired={formData?.isSsoRequired || false}
          onFormDataChange={onFormDataChange}
          onSave={onSave}
          permittedSsoProviders={formData?.permittedSsoProviders || []}
          ssoGoogleDomainRestrictions={formData?.ssoGoogleDomainRestrictions || []}
          ssoAzureTenantIdRestrictions={formData?.ssoAzureTenantIdRestrictions || []}
        />
      )}
    </div>
  )
}

export { AuthenticationFormContainer }
