const isInputElement = (subject: unknown): subject is HTMLInputElement =>
  subject instanceof HTMLInputElement
const isTextAreaElement = (subject: unknown): subject is HTMLTextAreaElement =>
  subject instanceof HTMLTextAreaElement

const isElementThatCollectsKeyboardInput = (
  subject: unknown,
): subject is HTMLTextAreaElement | HTMLInputElement =>
  isInputElement(subject) || isTextAreaElement(subject)

export { isElementThatCollectsKeyboardInput, isInputElement, isTextAreaElement }
