import { ControlProps } from "@jsonforms/core"
import { useJsonForms } from "@jsonforms/react"
import React, { FC } from "react"

import { GenericFormShape } from "v2/react/components/jobRequisitions/RequisitionForm/types/GenericFormShape"

type ControlPropsSubset = Pick<ControlProps, "visible">
type OrgUnitData = { id: string; name: string; typeId: string; typeName: string }

const JsonModuleListOrgUnits: FC<ControlPropsSubset> = ({ visible }) => {
  const context = useJsonForms()
  const formData: GenericFormShape = context?.core?.data

  if (!visible) return null

  return (
    <>
      {(formData?.position?.orgUnits || []).map((orgUnit: OrgUnitData) => (
        <div className="module-card-list-item" key={`${orgUnit.typeId}`}>
          <label className="text-neutral-64">{orgUnit.typeName}</label>
          <div data-testid="module-list-item-value">{orgUnit.name}</div>
        </div>
      ))}
    </>
  )
}

export { JsonModuleListOrgUnits }
