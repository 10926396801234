import cn from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { opaqueScaleDelay } from "v2/react/utils/framerAnimationVariants"

const DEFAULT_INPUT_WIDTH = 112

interface Props {
  displayValue: string
  editingValue: string
  isEditing: boolean
  onSave: (inputValue: string) => Promise<void>
}

function EditableBadge({ displayValue, editingValue, isEditing, onSave }: Props) {
  const { t } = useTranslation()
  const [focusInput, setFocusInput] = useState(false)
  const [inputValue, setInputValue] = useState(editingValue)
  const [inputWidth, setInputWidth] = useState(calculateTextWidth(inputValue))
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (focusInput && inputRef) {
      inputRef.current?.focus()
    }
  }, [focusInput])

  const trimValue = (value: string) => (value.length > 40 ? value.slice(0, 40) : value)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(trimValue(e.target.value))
    setInputWidth(calculateTextWidth(e.target.value))
  }

  const handleEnterSubmit = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") handleSave()
  }

  const handleSave = () => {
    onSave(inputValue)
    setFocusInput(false)
  }

  return (
    <AnimatePresence>
      {isEditing && (focusInput || displayValue !== inputValue || !inputValue.length) ? (
        <motion.span initial="hidden" animate="shown" variants={opaqueScaleDelay}>
          <div
            className={cn("badge--gray !p-0 text-sm-bold !text-neutral-100", {
              "!w-[7rem]": !inputValue.length,
              "!w-fit !min-w-[1.5rem]": inputValue.length > 0,
            })}
          >
            <input
              className={cn(
                "h-full  border-none bg-transparent !px-1 placeholder:text-center placeholder:text-neutral-100",
                {
                  "text-center": inputValue.length > 0,
                  "!px-2": inputValue.length === 0,
                },
              )}
              data-testid="editable-badge-input"
              maxLength={40}
              onChange={handleChange}
              onBlur={handleSave}
              onKeyDown={handleEnterSubmit}
              placeholder={t("v2.career_ladders.builder.name_track")}
              ref={inputRef}
              style={{ width: inputWidth }}
              type="text"
              value={inputValue}
            />
          </div>
        </motion.span>
      ) : (
        <motion.button
          animate="shown"
          initial="hidden"
          variants={opaqueScaleDelay}
          className={cn("badge--blue badge--sm sm:badge py-0 text-xs sm:text-sm-bold", {
            "cursor-text": !isEditing,
          })}
          data-testid="editable-badge-button"
          onClick={() => setFocusInput(true)}
          type="button"
        >
          <span>{displayValue}</span>
        </motion.button>
      )}
    </AnimatePresence>
  )
}

export { EditableBadge }

// https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript
function calculateTextWidth(text: string) {
  const headerFont = "700 11px Satoshi, sans-serif"
  const canvas = document.createElement("canvas")
  const context = canvas.getContext("2d")
  if (!context) return 24
  context.font = headerFont
  const textWidth = context.measureText(text).width
  const noText = textWidth === 0
  if (noText) return DEFAULT_INPUT_WIDTH
  return textWidth < 8 ? 24 : textWidth + 16
}
