import { flow, set } from "lodash/fp"

import {
  EnrichedTableFilterQuery,
  EnrichedTableFilterQueryVariables,
  TableFilterSettingsQuery,
  TableFilterSettingsQueryVariables,
  UpdateSelectedFiltersForTableInput,
} from "types/graphql.d"
import { GraphqlApi } from "v2/redux/GraphqlApi"
import { flatMutationOperation, queryOperation } from "v2/redux/utils/endpoints"

/**
 * This API is meant to house endpoints that are configured
 * to work generally with tables throughout the app.
 */
const TablesApi = GraphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    getFilterSettings: builder.query<TableFilterSettingsQuery, TableFilterSettingsQueryVariables>({
      query: queryOperation<TableFilterSettingsQueryVariables>("TableFilterSettings"),
      providesTags: ["TableFilterSettings"],
    }),
    /**
     * Fetches a single enriched filter option for a given table and filter.
     * This is useful for when we add a new filter and need to fetch more
     * information about it, including a potential collection set.
     *
     * Note that we merge the result of this with any existing enriched filters
     * in the cache.
     */
    getEnrichedFilter: builder.query<EnrichedTableFilterQuery, EnrichedTableFilterQueryVariables>({
      query: queryOperation<EnrichedTableFilterQueryVariables>("EnrichedTableFilter"),
      onQueryStarted({ table }, { dispatch, queryFulfilled }) {
        queryFulfilled.then((result) => {
          const enrichedFilter =
            result.data?.currentPerson?.settings?.tableFilterSettings?.enrichedFilter
          if (!enrichedFilter) return

          dispatch(
            TablesApi.util.updateQueryData("getFilterSettings", { table }, (data) => {
              const existingFilters =
                data?.currentPerson?.settings?.tableFilterSettings?.enrichedSelectedFilters

              const alreadyExists = existingFilters?.some(
                (filter) => filter.id === enrichedFilter.id,
              )
              if (alreadyExists) return data

              return flow(
                set("currentPerson.settings.tableFilterSettings.enrichedSelectedFilters", [
                  ...(existingFilters || []),
                  ...[enrichedFilter],
                ]),
              )(data)
            }),
          )
        })
      },
    }),
    updateSelectedFilters: builder.mutation({
      query: flatMutationOperation<UpdateSelectedFiltersForTableInput>(
        "UpdateSelectedFiltersForTable",
      ),
      invalidatesTags: ["TableFilterSettings"],
    }),
  }),
})

export const {
  useGetEnrichedFilterQuery,
  useGetFilterSettingsQuery,
  useUpdateSelectedFiltersMutation,
} = TablesApi
export { TablesApi }
