import { Boundary } from "@floating-ui/react"
import dayjs from "dayjs"
import React from "react"

import { StylesProps } from "v2/react/components/orgChart/Datasheet/Cell/StyleLayers/StyleLayers"
import { ChangeMeta } from "v2/react/components/orgChart/Datasheet/types"
import { CELL_TOOLTIP_DELAY_MS } from "v2/react/components/orgChart/Datasheet/utils/constants"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"

interface Props {
  boundary?: Boundary
  changeInfo?: ChangeMeta
  currentValue?: string
  hidden?: boolean
  styles: Omit<StylesProps, "color">
}

function CellAuthorLayer({ boundary, changeInfo, currentValue, hidden, styles }: Props) {
  if (!changeInfo || hidden) return null

  return (
    <>
      <Tooltip
        altBoundary
        boundary={boundary}
        boundaryPadding={40}
        placement="top-end"
        showDelay={CELL_TOOLTIP_DELAY_MS}
      >
        <TooltipContent type="content-animated-wrapped" className="react-tooltip-content">
          {"change".t("org_chart", "datasheet", null, null, [
            changeInfo.priorValue || "''",
            currentValue || "''",
            changeInfo.authorName || "system".t("org_chart", "datasheet"),
            dayjs(changeInfo.timestamp).format("h:mm A"),
          ])}
        </TooltipContent>
        <TooltipTrigger asChild style={styles} type="button">
          <div className="Cell--changed Cell__border" />
        </TooltipTrigger>
      </Tooltip>
      {changeInfo.authorAvatarUrl ? (
        <div style={{ ...styles, position: "absolute", zIndex: 24, pointerEvents: "none" }}>
          <img
            src={changeInfo.authorAvatarUrl}
            alt={changeInfo.authorName as string}
            width={24}
            height={24}
            style={{
              borderRadius: 12,
              position: "absolute",
              right: 8,
              top: "50%",
              marginTop: -12,
            }}
          />
        </div>
      ) : null}
    </>
  )
}

export { CellAuthorLayer }
