import React, { createContext, useContext, useMemo } from "react"
import {
  FieldValues as AnyFieldValues,
  FormProvider,
  useFormContext,
  UseFormRegister,
  UseFormReturn,
} from "react-hook-form"

import { toAuxiliaryFormData } from "v2/redux/GraphqlApi/PositionsApi"

import { FieldValues, PositionFormContext } from "./types"

const InternalPositionFormContext = createContext<PositionFormContext>({
  chartKey: "",
  positionData: {},
  initialFieldValues: undefined,
  // TODO: ...toAuxiliaryFormData({ currentCompany: undefined })
  positionFormAbilities: {},
  positionFormCollections: toAuxiliaryFormData({ currentCompany: undefined })
    .positionFormCollections,
})

function usePositionFormContext() {
  const { register, ...formContext } = useFormContext<FieldValues>()
  return {
    ...useContext(InternalPositionFormContext),
    ...formContext,
    // Hack, for now, to ensure register can be passed around without issue.
    register: register as unknown as UseFormRegister<AnyFieldValues>,
  }
}

function PositionFormProvider({
  children,
  chartKey,
  initialFieldValues,
  positionFormAbilities,
  positionData,
  positionFormCollections,
  ...form
}: React.PropsWithChildren<UseFormReturn<FieldValues> & PositionFormContext>) {
  const internalState = useMemo(
    () => ({
      chartKey,
      initialFieldValues,
      positionData,
      positionFormAbilities,
      positionFormCollections,
    }),
    [chartKey, initialFieldValues, positionData, positionFormAbilities, positionFormCollections],
  )

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...form}>
      <InternalPositionFormContext.Provider value={internalState}>
        {children}
      </InternalPositionFormContext.Provider>
    </FormProvider>
  )
}

export { PositionFormProvider, usePositionFormContext }
