/* eslint-disable react/jsx-props-no-spreading */
import { getWeeksInMonth } from "@internationalized/date"
import React from "react"
import { useCalendarGrid, useLocale } from "react-aria"
import { CalendarState, RangeCalendarState } from "react-stately"

import { OnDateClick } from "v2/react/shared/forms/DateInputs/shared/Calendar"
import { CalendarCell } from "v2/react/shared/forms/DateInputs/shared/Calendar/CalendarCell"
import { useCurrentDate } from "v2/react/shared/forms/DateInputs/shared/Calendar/hooks/useCalendarDateContext"

interface CalendarGridProps {
  state: RangeCalendarState | CalendarState
  onDateClick?: OnDateClick
}

function CalendarGrid({ state, onDateClick }: CalendarGridProps) {
  const { locale } = useLocale()
  const currentDate = useCurrentDate()
  const { gridProps, headerProps, weekDays } = useCalendarGrid({}, state)

  // Get the number of weeks in the month so we can render the proper number of rows.
  const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale)
  const weeksInMonthKeys = Array.from({ length: weeksInMonth }, (_, index) => index)

  return (
    <table {...gridProps}>
      <thead {...headerProps}>
        <tr>
          {weekDays.map((day, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <th key={`${day}-${index}`}>{day}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {weeksInMonthKeys.map((weekIndex) => (
          <tr key={weekIndex}>
            {state.getDatesInWeek(weekIndex).map((date, i) =>
              date ? (
                <CalendarCell
                  key={`${date.day}-${date.month}-${date.year}`}
                  state={state}
                  date={date}
                  currentDate={currentDate}
                  ariaLabel={date.toString()}
                  onDateClick={onDateClick}
                />
              ) : (
                // eslint-disable-next-line react/no-array-index-key
                <td key={`${weekIndex}-${i}-no-content`} aria-label="No Content" />
              ),
            )}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export { CalendarGrid }
