import { motion } from "framer-motion"
import React from "react"

interface Entry {
  label: string
  id: string
  width: number
  metric: number
  color?: string
}

interface BarGridProps {
  entries: Entry[]
  animate?: boolean
  handleClick?: (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void
}

export const BarGrid = ({ entries, animate = true, handleClick }: BarGridProps) => {
  const WrapperComponent = handleClick ? "button" : "div"

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (handleClick && (event.key === "Enter" || event.key === " ")) {
      event.preventDefault()
      handleClick(event)
    }
  }

  return (
    <dl
      className={`widget-grid widget-grid--col3 border-bottom mb-2 ${handleClick && "hoverable"}`}
    >
      {entries.map((entry) => {
        const progressBarStyle = {
          ...(entry.color && { backgroundColor: entry.color }),
        }

        return (
          <WrapperComponent
            key={entry.id}
            id={entry.id}
            data-entry-label={entry.label}
            className="widget-grid-wrapper"
            onClick={handleClick}
            onKeyDown={handleClick ? handleKeyDown : undefined}
          >
            <dt className="f-11 widget-grid-item">{entry.label}</dt>
            <dd className="f-11 widget-grid-item">
              <div className="progress-bar-wrapper progress-bar-thin">
                <div className="progress-bar-background">
                  <motion.div
                    className="progress-bar progress-bar__primary-color rounded-corners"
                    style={progressBarStyle}
                    initial={animate ? { width: 0 } : { width: `${entry.width}%` }}
                    animate={animate ? { width: `${entry.width}%` } : {}}
                    transition={
                      animate ? { delay: 0.3, duration: 0.35, ease: [0.4, 0.0, 0.2, 1] } : {}
                    }
                  />
                </div>
              </div>
            </dd>
            <dd className="f-11 widget-grid-item">{`${entry.metric}%`}</dd>
          </WrapperComponent>
        )
      })}
    </dl>
  )
}
