import { parseDate } from "@internationalized/date"
import type { Args, Meta, StoryObj } from "@storybook/react"
import dayjs from "dayjs"
import React from "react"

import RootProvider from "v2/react/components/RootProvider"
import { DateRange } from "v2/react/shared/forms/DateInputs/DateRange/DateRange"
import { DateRangeOption } from "v2/react/shared/forms/DateInputs/utils/dates"

const meta: Meta<typeof DateRange> = {
  title: "Shared/DateInputs/DateRange",
  component: DateRange,
  decorators: [
    (Story) => (
      <RootProvider>
        <div style={{}}>
          <Story />
        </div>
      </RootProvider>
    ),
  ],
}

export default meta
type Story = StoryObj<typeof DateRange>

export const DateRangeUncontrolled: Story = {
  args: {
    label: "Date Range",
    errorMessage: "Oops wrong date!",
    quickSelectOptions: [
      "month_to_date",
      "quarter_to_date",
      "year_to_date",
      "last_month",
      "last_quarter",
      "last_year",
      "last_12_months",
    ],
  },
  render: ({ label, errorMessage, quickSelectOptions }: Args) => (
    <DateRange
      label={label}
      errorMessage={errorMessage}
      id="story-example-uncontrolled"
      quickSelectOptions={quickSelectOptions}
      className="w-[280px]"
    />
  ),
}

const DateRangeWithValue = () => {
  const [date, setDate] = React.useState({
    start: parseDate(dayjs().subtract(1, "year").format("YYYY-MM-DD")),
    end: parseDate(dayjs().format("YYYY-MM-DD")),
  })
  const quickSelectOptions: DateRangeOption[] = [
    "month_to_date",
    "quarter_to_date",
    "year_to_date",
    "last_month",
    "last_quarter",
    "last_year",
    "last_12_months",
  ]

  return (
    <DateRange
      label="Date Range"
      className="w-[280px]"
      id="story-example-controlled"
      value={date}
      onChange={setDate}
      maxValue={parseDate(dayjs().format("YYYY-MM-DD"))}
      quickSelectOptions={quickSelectOptions}
    />
  )
}

export const DateRangeControlled: Story = {
  render: () => DateRangeWithValue(),
}
