import React, { Ref } from "react"

interface CursorBeaconProps {
  beaconRef: Ref<HTMLButtonElement>
}

/**
 * The beacon complements the cursor by always being "visible" in the viewport
 * in order to act as a valid target for focus. It's the ideal target to use
 * for listening to keyboard events.
 */
function CursorBeacon({ beaconRef }: CursorBeaconProps) {
  return (
    <button
      id="cursor-beacon"
      className="ActiveCursor__beacon"
      ref={beaconRef}
      type="button"
      tabIndex={-1}
      style={{ top: 10, left: 10 }}
    >
      Cell Navigation
    </button>
  )
}

export { CursorBeacon }
