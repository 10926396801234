import React from "react"

import { CustomField } from "types/graphql"
import {
  useIsFieldUneditable,
  usePositionFieldArray,
} from "v2/react/components/positions/positionForm/hooks"
import { CurrencyInput, NumericInput, TextInput } from "v2/react/shared/forms"

type PositionCustomFieldInputProps = {
  customField: CustomField
  index: number
}

/** Based on app/views/v2/positions/form/position_management_tabs/_custom_fields.html.slim */
function CustomFieldsTab() {
  const { fields: customFieldValueFields } = usePositionFieldArray({
    keyName: "formId",
    name: "position.customFieldValuesAttributes",
  })

  return (
    <div id="custom-fields-tab" className="tab-panel flex-col gap-6 p-4 flex">
      {customFieldValueFields.map(({ customField }, index) => (
        <div key={customField.id}>
          <PositionCustomFieldInput customField={customField} index={index} />
        </div>
      ))}
    </div>
  )
}

function PositionCustomFieldInput({ customField, index }: PositionCustomFieldInputProps) {
  const isUneditable = useIsFieldUneditable(`custom_field_${customField.id}`)
  const name = `position.customFieldValuesAttributes.${index}.value` as const

  switch (customField.field_type) {
    case "numeric":
      return (
        <NumericInput
          disabled={isUneditable}
          id={`position_custom_field_values_attributes_${index}_value`}
          label={customField.name ?? undefined}
          name={name}
          useInReactHookForm
        />
      )
    case "currency":
      return (
        <CurrencyInput
          disabled={isUneditable}
          formatDefaultValue
          id={`position_custom_field_values_attributes_${index}_value`}
          label={customField.name ?? undefined}
          name={name}
          useInReactHookForm
        />
      )
    default:
      return (
        <TextInput
          disabled={isUneditable}
          id={`position_custom_field_values_attributes_${index}_value`}
          label={customField.name ?? undefined}
          name={name}
          useInReactHookForm
        />
      )
  }
}

export { CustomFieldsTab }
