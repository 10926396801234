import { isEmpty } from "lodash/fp"
import React, { useState } from "react"

import { Maybe, Person, Position } from "types/graphql"
import { PositionSeatChangeIntent } from "types/graphql.enums"
import {
  prepareErrors,
  useErrors,
} from "v2/react/components/orgChart/OrgChartDatasheet/Modals/errors"
import { usePositionSeatChange } from "v2/react/components/orgChart/OrgChartDatasheet/Modals/hooks/usePositionSeatChange"
import { InputErrorBanner } from "v2/react/shared/forms/InputErrorBanner"
import { RadioGroup } from "v2/react/shared/forms/RadioGroup"
import { Modal } from "v2/react/shared/overlay/Modal"
import { SaveFooter } from "v2/react/shared/overlay/Modal/SaveFooter"
import { useDatasheetListenerActions } from "v2/redux/listeners/datasheetListeners"

interface PositionSeatChangeProps {
  modalOpen: boolean
  rowId: string
  person: Person
  handleModalClose: (saved?: boolean) => void
}

const PositionSeatChangeModal = ({
  modalOpen,
  rowId,
  person,
  handleModalClose,
}: PositionSeatChangeProps) => {
  const [isSaving, setIsSaving] = useState(false)
  const { errors, setErrors } = useErrors()
  const [changePositionSeat] = usePositionSeatChange()
  const { fieldSaved } = useDatasheetListenerActions()

  // When this setting is true, the user is limited to using the "move"
  // position seat change intent, b/c the other options involve the use
  // of secondary positions.
  const disableSecondaryPositions =
    window.gon.company_settings.positions.disable_secondary_positions

  const { primaryPosition } = person
  if (!primaryPosition) {
    handleModalClose()
    throw new Error("Person has no primary position")
  }

  const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsSaving(true)

    const form = e.target as HTMLFormElement
    const formData = new FormData(form)

    const intent = parseIntent(formData.get("position-seat-change-intent"))
    if (!intent) {
      setErrors({ intent: "Invalid position seat change intent used." })
      setIsSaving(false)
      return
    }

    const response = await changePositionSeat({
      personId: person.uniqueKey || undefined,
      rowId,
      intent,
    })

    if (response?.ok) {
      fieldSaved({ id: rowId, fieldKey: "name" })
      handleModalClose(true)
    } else {
      const { errors: responseErrors } = response
      setErrors(responseErrors ? prepareErrors(responseErrors) : {})
      setIsSaving(false)
    }

    setIsSaving(false)
    handleModalClose()
  }

  // For reasons known only to the web developement pantheon of capricious gods, the react-modal
  // listeners seem to be interfering with the form submission + radio click events. This
  // is a hack to prevent that.
  const stopClickPropagation = (e: React.MouseEvent<HTMLFormElement, MouseEvent>) =>
    e.stopPropagation()
  const stopKeyDownPropagation = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === "Enter") {
      e.stopPropagation()
    }
  }
  // The Cell click/keydown handlers are triggered by closing the modal here, so we need to
  // stop propagation to prevent that.
  const handleModalCloseWithStopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation()
    handleModalClose()
  }

  return (
    <Modal
      isOpen={modalOpen}
      onClose={handleModalCloseWithStopPropagation}
      title={"Edit Employee Position".t("org_chart")}
      shouldReturnFocusAfterClose={false}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions  */}
      <form onSubmit={handleSave} onClick={stopClickPropagation} onKeyDown={stopKeyDownPropagation}>
        <div className="react-modal__body">
          {!isEmpty(errors) && <InputErrorBanner errors={Object.values(errors)} />}
          {disableSecondaryPositions ? (
            <PositionChangeWarning />
          ) : (
            <PositionSeatChangeForm person={person} primaryPosition={primaryPosition} />
          )}
        </div>

        <SaveFooter isSaving={isSaving} />
      </form>
    </Modal>
  )
}

const PositionChangeWarning = () => (
  <>
    <div className="alert alert-caution">
      {"will_be_removed_from_other_positions".t("org_chart")}
    </div>
    <input type="hidden" name="position-seat-change-intent" value={PositionSeatChangeIntent.Move} />
  </>
)

const PositionSeatChangeForm = ({
  person,
  primaryPosition,
}: {
  person: Person
  primaryPosition: Position
}) => {
  const { reportsToName, title } = primaryPosition
  const { name } = person

  const description = getDescription(name, title, reportsToName)

  const radioOptions = getRadioOptions(name, title || "")

  return (
    <>
      <div className="mb-4 cursor-default font-bold">{description}</div>
      <RadioGroup<PositionSeatChangeIntent>
        groupName="position-seat-change-intent"
        options={radioOptions}
        defaultChecked={PositionSeatChangeIntent.Dotted}
      />
    </>
  )
}

const isPositionSeatChangeIntent = (intent: unknown): intent is PositionSeatChangeIntent => {
  const intents = Object.values(PositionSeatChangeIntent)
  return intents.includes(intent as PositionSeatChangeIntent)
}

const parseIntent = (intent: unknown) => {
  if (isPositionSeatChangeIntent(intent)) {
    return intent
  }
  return null
}

// See: app/views/v2/positions/positions_manager.html.slim
// See also: app/assets/javascripts/application/org_chart/views/position_manager.js
const getDescription = (
  name: string,
  title: Maybe<string> | undefined,
  reportsTo: Maybe<string> | undefined,
): string => {
  const args: Array<string | null> = [name]

  if (title) args.push(title)
  if (reportsTo) args.push(reportsTo)

  let messageTemplate

  if (title && reportsTo) {
    messageTemplate = "%s already fills the %s position and reports to %s."
  } else if (title) {
    messageTemplate = "%s already fills the %s position."
  } else if (reportsTo) {
    messageTemplate = "%s already reports to %s."
  } else {
    messageTemplate = "%s already fills another position."
  }

  return `${messageTemplate.t(
    "org_chart",
    null,
    null,
    null,
    args,
  )} ${"What would you like to do?".t("org_chart", null, null, null, args)}`
}

const getRadioOptions = (personName: string, title: string) => [
  {
    label:
      "Leave %s in the %s position but add them here as well, with a dotted line relationship.".t(
        "org_chart",
        null,
        null,
        null,
        [personName, title],
      ),
    id: "make-position-dotted",
    value: PositionSeatChangeIntent.Dotted,
  },
  {
    label: "Move %s to this new position and remove them from the %s position.".t(
      "org_chart",
      null,
      null,
      null,
      [personName, title],
    ),
    id: "move-position",
    value: PositionSeatChangeIntent.Move,
  },
  {
    label:
      "Make this %s's primary position and change the %s position to a dotted line relationship.".t(
        "org_chart",
        null,
        null,
        null,
        [personName, title],
      ),
    id: "make-position-primary",
    value: PositionSeatChangeIntent.Primary,
  },
]

export { PositionSeatChangeModal }
