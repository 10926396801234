import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import type { PaginationState, SortingState } from "@tanstack/react-table"
import fp from "lodash/fp"

import { FilterAttributes } from "types/graphql.d"
import { DEFAULT_PAGE_SIZE } from "v2/react/constants"

import { RootState } from "../store"

type InitialFilter = {
  filterKey: string
  filterValue: string
}

interface DrillDownState {
  initialFilter: InitialFilter | null
  sorting: SortingState
  pagination: PaginationState
  filters: FilterAttributes[]
}

const InitialState: DrillDownState = {
  initialFilter: null,
  sorting: [],
  pagination: {
    pageSize: DEFAULT_PAGE_SIZE,
    pageIndex: 0,
  },
  filters: [],
}

const DrillDownSlice = createSlice({
  name: "drilldown",
  initialState: InitialState,
  reducers: {
    updateInitialFilter: (state, { payload }: PayloadAction<InitialFilter>) =>
      fp.pipe(fp.set("initialFilter", payload))(state),
    updateSort: (state, { payload }: PayloadAction<SortingState>) =>
      fp.pipe(fp.set("sorting", payload), fp.set("pagination", InitialState.pagination))(state),
    updatePagination: (state, { payload }: PayloadAction<PaginationState>) =>
      fp.set("pagination", payload)(state),
    updateFilter: (state, { payload }: PayloadAction<FilterAttributes>) => {
      const incomingFilter = payload

      // Filter out any existing filters that have the same field as the new filter
      const filteredExistingFilters = state.filters.filter(
        (filter) => filter.field !== incomingFilter.field,
      )

      // Add the new filter to the array
      const updatedFilters = [...filteredExistingFilters, incomingFilter]

      return fp.pipe(
        fp.set("filters", updatedFilters),
        fp.set("pagination", InitialState.pagination),
      )(state)
    },
    updateFilters: (state, { payload }: PayloadAction<FilterAttributes[]>) =>
      fp.set("filters", payload)(state),
    clearFilter: (state, { payload }: PayloadAction<string>) =>
      fp.set(
        "filters",
        state.filters.filter((filter) => filter.field !== payload),
      )(state),
  },
})

const fallbackFilter = { filterKey: null, filterValue: null }
const selectInitialFilter = (state: RootState) => state.drillDown.initialFilter ?? fallbackFilter

export const {
  updateInitialFilter,
  updateSort,
  updateFilters,
  updateFilter,
  clearFilter,
  updatePagination,
} = DrillDownSlice.actions
export { DrillDownSlice, DrillDownState, selectInitialFilter }
