import React from "react"

import {
  ColumnHeadProps,
  MemoedColumnHead,
} from "v2/react/components/orgChart/Datasheet/ColumnHead"
import { Column } from "v2/react/components/orgChart/Datasheet/types"
import { selectFilterValuesFunc } from "v2/redux/combinedSelectors"
import { useAppSelector } from "v2/redux/store"

type ForwardProps<TNode> = Pick<
  ColumnHeadProps<TNode>,
  "onClearFilter" | "onSelectFilter" | "onSortColumn" | "selectFilterValues"
>

interface GridHeaderProps<TNode, CType = TNode> extends ForwardProps<CType> {
  columns: Column<CType>[]
  sortBy: Omit<Column<CType>, "width">
  sortDirection: "asc" | "desc"
}

function GridHeader<TNode, CType = TNode>({
  columns,
  sortBy,
  sortDirection,
  onSelectFilter,
  onClearFilter,
  onSortColumn,
}: GridHeaderProps<TNode, CType>) {
  const selectFilterValues = useSelectFilterValues()

  return (
    <div className="Grid-header">
      {columns.map((column) => (
        <MemoedColumnHead
          key={`header-cell-${column.fieldKey.toString()}`}
          column={column}
          selectFilterValues={selectFilterValues}
          sorting={{ fieldKey: sortBy.fieldKey, direction: sortDirection }}
          onSortColumn={onSortColumn}
          onSelectFilter={onSelectFilter}
          onClearFilter={onClearFilter}
        />
      ))}
    </div>
  )
}

function useSelectFilterValues() {
  // TODO: Refactor this so it doesn't couple w/ redux.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useAppSelector(selectFilterValuesFunc) as any
}

export { GridHeader, GridHeaderProps }
