import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { CodeBlock, tomorrowNight } from "react-code-blocks"

function Alerts() {
  return (
    <>
      <h1 id="alerts">Alerts</h1>
      <p>Use alert messages to help explain what actions, or errors, have taken placed.</p>

      <section>
        <h2>Types of Alerts</h2>

        <section>
          <h3>Neutral</h3>
          <div className="alert-neutral mb-4">
            <a className="alert-close-icon" href="#alerts">
              <FontAwesomeIcon icon={["far", "times"]} />
            </a>
            <div className="text-base-bold">Neutral title</div>
            <div className="alert-description">
              Neutral description with <a href="#alerts">hyperlink</a>.
            </div>
          </div>

          <div className="font-mono text-base">
            <CodeBlock text={neutralExample} theme={tomorrowNight} language="javascript" />
          </div>
        </section>
      </section>

      <section>
        <h3>Informative</h3>
        <div className="alert mb-4">
          <a className="alert-close-icon" href="#alerts">
            <FontAwesomeIcon icon={["far", "times"]} />
          </a>
          <div className="text-base-bold">Info title</div>
          <div className="alert-description">
            Info description with <a href="#alerts">hyperlink</a>.
          </div>
        </div>

        <div className="font-mono text-base">
          <CodeBlock text={infoExample} theme={tomorrowNight} language="javascript" />
        </div>
      </section>

      <section>
        <h3>Success</h3>
        <div className="alert-success mb-4">
          <a className="alert-close-icon" href="#alerts">
            <FontAwesomeIcon icon={["far", "times"]} />
          </a>
          <div className="text-base-bold">Success title</div>
          <div className="alert-description">
            Success description with <a href="#alerts">hyperlink</a>.
          </div>
        </div>

        <div className="font-mono text-base">
          <CodeBlock text={successExample} theme={tomorrowNight} language="javascript" />
        </div>
      </section>

      <section>
        <h3>Caution</h3>
        <div className="alert-caution mb-4">
          <a className="alert-close-icon" href="#alerts">
            <FontAwesomeIcon icon={["far", "times"]} />
          </a>
          <div className="text-base-bold">Caution title</div>
          <div className="alert-description">
            Caution description with <a href="#alerts">hyperlink</a>.
          </div>
        </div>

        <div className="font-mono text-base">
          <CodeBlock text={cautionExample} theme={tomorrowNight} language="javascript" />
        </div>
      </section>

      <section>
        <h3>Critical</h3>
        <div className="alert-critical mb-4">
          <a className="alert-close-icon" href="#alerts">
            <FontAwesomeIcon icon={["far", "times"]} />
          </a>
          <div className="text-base-bold">Critical title</div>
          <div className="alert-description">
            Critical description with <a href="#alerts">hyperlink</a>.
          </div>
        </div>

        <div className="font-mono text-base">
          <CodeBlock text={criticalExample} theme={tomorrowNight} language="javascript" />
        </div>
      </section>

      <section>
        <h3>Pending</h3>
        <div className="alert-pending mb-4">
          <a className="alert-close-icon" href="#alerts">
            <FontAwesomeIcon icon={["far", "times"]} />
          </a>
          <div className="text-base-bold">Pending title</div>
          <div className="alert-description">
            Pending description with <a href="#alerts">hyperlink</a>.
          </div>
        </div>

        <div className="font-mono text-base">
          <CodeBlock text={pendingExample} theme={tomorrowNight} language="javascript" />
        </div>
      </section>
    </>
  )
}

export default Alerts

const neutralExample = `<div class="alert-neutral">
  <a class="alert-close-icon" href="#">
    <FontAwesomeIcon icon={["far", "times"]} />
  </a>
  <div class="text-base-bold">Neutral title</div>
  <div class="alert-description">Neutral description with <a href="#">hyperlink</a>.</div>
</div>`

const infoExample = `<div class="alert">
  <a class="alert-close-icon" href="#">
    <FontAwesomeIcon icon={["far", "times"]} />
  </a>
  <div class="text-base-bold">Info title</div>
  <div class="alert-description">Info description with <a href="#">hyperlink</a>.</div>
</div>`

const successExample = `<div class="alert-success">
  <a class="alert-close-icon" href="#">
    <FontAwesomeIcon icon={["far", "times"]} />
  </a>
  <div class="text-base-bold">Success title</div>
  <div class="alert-description">Success description with <a href="#">hyperlink</a>.</div>
</div>`

const cautionExample = `<div class="alert-caution">
  <a class="alert-close-icon" href="#">
    <FontAwesomeIcon icon={["far", "times"]} />
  </a>
  <div class="text-base-bold">Caution title</div>
  <div class="alert-description">Caution description with <a href="#">hyperlink</a>.</div>
</div>`

const criticalExample = `<div class="alert-critical">
  <a class="alert-close-icon" href="#">
    <FontAwesomeIcon icon={["far", "times"]} />
  </a>
  <div class="text-base-bold">Critical title</div>
  <div class="alert-description">Critical description with <a href="#">hyperlink</a>.</div>
</div>`

const pendingExample = `<div class="alert-pending">
  <a class="alert-close-icon" href="#">
    <FontAwesomeIcon icon={["far", "times"]} />
  </a>
  <div class="text-base-bold">Pending title</div>
  <div class="alert-description">Pending description with <a href="#">hyperlink</a>.</div>
</div>`
