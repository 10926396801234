import { Maybe } from "graphql/jsutils/Maybe"
import { TFunction } from "i18next"

import { Position } from "types/graphql"

/**
 * @returns
 * 1. <position title> if the position has a title
 * 2. "Untitled Position" if no title is present
 */
export const positionTitleDisplay = (position: Maybe<Position> | undefined, t: TFunction) => {
  if (!position) return t("v2.defaults.untitled_position")
  if (position?.title) return position.title
  return t("v2.defaults.untitled_position")
}

/**
 * @returns
 *  1. <parent position title> if the parent position and title are present
 *  2. "Untitled Position" if the parent position is present with no title
 *  3. "" if no parent position is present
 */
export const positionParentTitle = (position: Position, t: TFunction) => {
  if (position.parent?.title) {
    return position?.parent?.title
  }
  if (position.parent?.id) {
    return t("v2.defaults.untitled_position")
  }
  return ""
}

/**
 * @returns
 *  1. "Filled by " + <a comma-separated list of people names> if the parent position is filled
 *  2. "Open Position" if the parent position is unfilled
 *  3. "" if no parent is present
 */
export const positionParentNamesDisplay = (position: Position, t: TFunction) => {
  if (position.parent?.filledBy?.length) {
    return `${t("v2.succession_plan_panel.summary.filled_by")} ${position.parent.filledBy
      .map((person) => person.name)
      .join(", ")}`
  }
  if (position.parent && !position.parent?.filledBy?.length) {
    return t("v2.defaults.open_position")
  }
  return ""
}
