import { parseDate } from "@internationalized/date"
import { isEqual } from "lodash"
import React, { useState } from "react"
import type { DateRange as DateRangeType } from "react-aria"
import { useTranslation } from "react-i18next"

import { FilterOption } from "types/graphql"
import { DateRange } from "v2/react/shared/forms/DateInputs/DateRange/DateRange"
import { useDateRange } from "v2/react/shared/forms/DateInputs/DateRange/hooks/useDateRange"
import { DateRangeOption } from "v2/react/shared/forms/DateInputs/utils/dates"
import { FilterPanelState } from "v2/react/shared/tables/TableUtilities/FilterTable/hooks/useFilterPanelState"
import type { DateRangeValue } from "v2/react/shared/tables/TableUtilities/FilterTable/utils/filters"
import { RemovableField } from "v2/react/shared/tables/TableUtilities/shared/RemovableField"

const pastSelectOptions: DateRangeOption[] = [
  "today",
  "yesterday",
  "month_to_date",
  "quarter_to_date",
  "year_to_date",
  "last_week",
  "last_month",
  "last_quarter",
  "last_year",
]
const futureSelectOptions: DateRangeOption[] = [
  "tomorrow",
  "today",
  "current_week",
  "current_month",
  "current_quarter",
  "current_year",
  "next_week",
  "next_month",
  "next_quarter",
  "next_year",
]

const pastRanges = ["open_since"]

interface DateRangeFilterProps {
  field: FilterOption
  values: DateRangeValue
  onRemove: (id: string) => void
  onSelect?: (filterId: string, options: DateRangeValue) => void
  updateFieldErrors?: FilterPanelState["updateFieldErrors"]
}

function DateRangeFilter({
  field,
  values,
  onRemove,
  onSelect,
  updateFieldErrors,
}: DateRangeFilterProps) {
  const [error, setError] = useState("")
  const { dateRange, handleDateRangeChange: setDateRange } = useDateRange({
    startDate: values.min,
    endDate: values.max,
  })
  const { t } = useTranslation()

  const handleDateRangeChange = (range: DateRangeType) => {
    setDateRange(range)
    handleDateRangeSelection(range)
  }

  const handleDateRangeSelection = (activeDateRange?: DateRangeType | null) => {
    if (!activeDateRange) {
      clearErrors()
      return
    }
    if (isEqual(activeDateRange, dateRange)) return
    const min = activeDateRange.start?.toString()
    const max = activeDateRange.end?.toString()
    const range = { min, max }

    onSelect?.(field.id, range)
    validate(min, max)
  }

  const validate = (min: string | undefined, max: string | undefined) => {
    const minDate = min ? parseDate(min) : null
    const maxDate = max ? parseDate(max) : null

    if (maxDate && minDate && minDate > maxDate) {
      setError(t("v2.errors.messages.start_date_lte_end_date"))
      updateFieldErrors?.(field.id, "add")
    } else {
      clearErrors()
    }
  }

  const clearErrors = () => {
    setError("")
    updateFieldErrors?.(field.id, "remove")
  }

  const handleMenuOpenChange = (isOpen: boolean) => {
    setTimeout(() => {
      const dialog = document.querySelector(".Date-Range-Dialog")
      const formScroll = document.querySelector(".form--inner")
      const drawerScroll = document.querySelector(".drawer-section-content")

      if (isOpen || dialog !== null) {
        formScroll?.classList.add("!overflow-y-hidden")
        drawerScroll?.classList.add("!overflow-y-hidden")
      } else {
        formScroll?.classList.remove("!overflow-y-hidden")
        drawerScroll?.classList.remove("!overflow-y-hidden")
      }
    }, 200)
  }

  return (
    <RemovableField id={field.id} removeField={onRemove}>
      <div className="mb-6">
        <DateRange
          label={field.label}
          dialogInPortal
          id={`${field.id}-filter--date-range`}
          value={dateRange}
          onChange={handleDateRangeChange}
          onDateRangeSelection={handleDateRangeSelection}
          onMenuOpenChange={handleMenuOpenChange}
          quickSelectOptions={
            pastRanges.includes(field.id) ? pastSelectOptions : futureSelectOptions
          }
          size="sm"
          errorMessage={error}
          enforceRange={false}
        />
      </div>
    </RemovableField>
  )
}

export { DateRangeFilter }
