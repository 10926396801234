import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { FeatureFlags, Position } from "types/graphql"
import { useEditPosition } from "v2/react/components/positions/hooks/useEditPosition"
import {
  RequirementsModal,
  RequirementsTags,
} from "v2/react/components/positionTypes/modals/RequirementsModal"
import { EmptyContent, EmptyState } from "v2/react/components/positionTypes/Show/EmptyState"
import { SmallEditButton } from "v2/react/components/positionTypes/Show/SmallEditButton"
import { TagListDisplay } from "v2/react/components/positionTypes/Show/TagListDisplay"
import { useTagsWithFieldSuggestion } from "v2/react/hooks/useTypedFieldWithSuggestion"
import { normalizedDomainTagsProp } from "v2/react/hooks/useTypedFieldWithSuggestion/tagFieldHelpers"
import { sanitizeParse } from "v2/react/utils/sanitizeParse"

interface Props {
  canEditCharacteristics: boolean
  canEditSkills: boolean
  featureFlags: FeatureFlags
  position: Position
}

const Requirements = ({ canEditCharacteristics, canEditSkills, position, featureFlags }: Props) => {
  const { t } = useTranslation()
  const [editModalOpen, setEditModalOpen] = useState(false)
  const isAiAvailable = Boolean(featureFlags.aiGeneration) && Boolean(position.title)

  const uniqueKey = position.uniqueKey ?? `position_${position.id}`
  const {
    addSocketError,
    allErrors,
    cancelGeneratingFieldSuggestions,
    generateFieldSuggestions,
    resetAll,
    updatePosition,
    updateState,
  } = useEditPosition(uniqueKey)
  const { characteristicsTags, skillsTags } = useCombinedTagsFields({
    addSocketError,
    position,
    hasAiFeatureFlag: Boolean(featureFlags.aiGeneration),
  })
  const handleEdit = () => setEditModalOpen(true)
  const handleClose = () => {
    setEditModalOpen(false)
    cancelGeneratingFieldSuggestions(["skills", "worker_characteristics"])
    resetAll()
  }
  const handleSave = async ({ characteristics, skills }: RequirementsTags) =>
    updatePosition({
      skills: skills.map(({ label }) => label),
      workerCharacteristics: characteristics.map(({ label }) => label),
    })

  return (
    <>
      <div className="module-card">
        <div className="module-card__header">
          <div className="module-title">{t("v2.positions.show.requirements")}</div>
          {skillsTags?.length || characteristicsTags?.length ? (
            <SmallEditButton
              disabled={!canEditSkills && !canEditCharacteristics}
              disabledTooltipText={t("v2.positions.show.edit_disabled")}
              onClick={handleEdit}
            />
          ) : null}
        </div>
        <div className="module-card__body">
          <div className="items-center gap-2 flex">
            <FontAwesomeIcon icon={["far", "pencil-ruler"]} />
            {t("v2.positions.show.skills")}
          </div>
          {skillsTags?.length ? (
            <TagListDisplay tagList={skillsTags} />
          ) : (
            <EmptySkills handleEdit={handleEdit} isAiAvailable={isAiAvailable} />
          )}
          <hr className="mx-[-1rem] my-0" />
          <div className="items-center gap-2 flex">
            <FontAwesomeIcon icon={["far", "heart"]} />
            {t("v2.positions.show.characteristics")}
          </div>
          {characteristicsTags?.length ? (
            <TagListDisplay tagList={characteristicsTags} />
          ) : (
            <EmptyCharacteristics handleEdit={handleEdit} isAiAvailable={isAiAvailable} />
          )}
        </div>
      </div>
      <RequirementsModal
        canEditCharacteristics={canEditCharacteristics}
        canEditSkills={canEditSkills}
        characteristicTags={characteristicsTags}
        contentType="position"
        errors={allErrors}
        excludeRecord={uniqueKey}
        hasAiFeatureFlag={Boolean(featureFlags.aiGeneration)}
        isAiAvailable={isAiAvailable}
        isOpen={editModalOpen}
        isSaving={updateState.isLoading}
        modalTitle={t("v2.position_types.show.edit_job_definition_requirements")}
        onClose={handleClose}
        onRegenerateFieldSuggestion={generateFieldSuggestions}
        onSave={handleSave}
        record={position}
        skillTags={skillsTags}
        uniqueKey={uniqueKey}
      />
    </>
  )
}

export { Requirements }

const EmptySkills = ({
  handleEdit,
  isAiAvailable,
}: {
  handleEdit: () => void
  isAiAvailable: boolean
}) => {
  const { t } = useTranslation()
  const property = t("v2.positions.show.skills").toLowerCase()

  const emptySkills: EmptyContent = {
    buttonText: `${t("v2.defaults.add")} ${t("v2.positions.show.skills")}`,
    note: sanitizeParse(
      isAiAvailable
        ? t("v2.positions.show.ai_add_note_html", { count: 2, property })
        : t("v2.positions.show.no_ai_add_note_html", { count: 2, property }),
    ),
    icon: isAiAvailable ? "sparkles" : "edit",
    onClick: handleEdit,
  }

  return (
    <EmptyState
      buttonText={emptySkills.buttonText}
      note={emptySkills.note}
      icon={emptySkills.icon}
      onClick={emptySkills.onClick}
    />
  )
}

const EmptyCharacteristics = ({
  handleEdit,
  isAiAvailable,
}: {
  handleEdit: () => void
  isAiAvailable: boolean
}) => {
  const { t } = useTranslation()
  const property = t("v2.positions.show.characteristics").toLowerCase()

  const emptyCharacteristics: EmptyContent = {
    buttonText: `${t("v2.defaults.add")} ${t("v2.positions.show.characteristics")}`,
    note: sanitizeParse(
      isAiAvailable
        ? t("v2.positions.show.ai_add_note_html", { count: 2, property })
        : t("v2.positions.show.no_ai_add_note_html", { count: 2, property }),
    ),
    icon: isAiAvailable ? "sparkles" : "edit",
    onClick: handleEdit,
  }

  return (
    <EmptyState
      buttonText={emptyCharacteristics.buttonText}
      note={emptyCharacteristics.note}
      icon={emptyCharacteristics.icon}
      onClick={emptyCharacteristics.onClick}
    />
  )
}

type CombinedTagsArg = {
  addSocketError: (error: string) => void
  position: Position
  hasAiFeatureFlag: boolean
}

function useCombinedTagsFields({ addSocketError, position, hasAiFeatureFlag }: CombinedTagsArg) {
  const skillState = useTagsWithFieldSuggestion({
    addGenerateError: addSocketError,
    hasAiFeatureFlag,
    record: position,
    field: "skills",
    getActual: normalizedDomainTagsProp("skills", "tags"),
    getUniqueKey: (record) => record.uniqueKey ?? `position_${record.id}`,
  })

  const characteristicsState = useTagsWithFieldSuggestion({
    addGenerateError: addSocketError,
    hasAiFeatureFlag,
    record: position,
    field: "worker_characteristics",
    getActual: normalizedDomainTagsProp("worker_characteristics", "tags"),
    getUniqueKey: (record) => record.uniqueKey ?? `position_${record.id}`,
  })

  return {
    skillState,
    characteristicsState,
    characteristicsTags: characteristicsState.value,
    skillsTags: skillState.value,
  }
}
