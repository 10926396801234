import { startAppListening } from "../listenerMiddleware"
import { dispatchCursorEvents } from "../slices/DatasheetSlice/cursor/cursorEvents"
import { selectCursor } from "../slices/DatasheetSlice/cursor/cursorSelectors"

export function mountDatasheetListeners() {
  const cleanupStack = [startListeningForEmittableCursorChanges()]
  return () => cleanupStack.forEach((cleanup) => cleanup())
}

const startListeningForEmittableCursorChanges = () =>
  startAppListening({
    predicate: (_, current, prior) => selectCursor(current) !== selectCursor(prior),
    effect: async (_, api) => {
      dispatchCursorEvents({ api })
    },
  })
