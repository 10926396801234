import { includes } from "lodash/fp"

import { Maybe, Option } from "types/graphql"

export function findInitialOptionId(list: Maybe<Option[] | undefined>, labels: string[]) {
  if (!list) {
    return undefined
  }
  const item = list.find((item) => includes(item.label, labels))
  if (item) {
    return item.id
  }
  if (list.length > 0) {
    return list[0].id
  }
  return undefined
}

export function findOptionById(list: Maybe<Option[]> | undefined, id: string | undefined) {
  return list?.find((item) => item.id === id)
}
export function isForCreation(jobRequisitionId?: string) {
  if (!jobRequisitionId) return true
  if (jobRequisitionId === "") return true
  return false
}

export type InitFailure = {
  success: false
  message: string
}
