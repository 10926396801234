import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { CodeBlock, tomorrowNight } from "react-code-blocks"

function Buttons() {
  return (
    <>
      <h1 id="buttons">Buttons</h1>
      <p className="pb-4">
        Basic button styles have been created to help create a visual hiarchy of what is the primary
        action. The class <code>.btn</code> can be applied to all <code>a</code> and{" "}
        <code>button</code> tags.
      </p>

      <ButtonGrid title="Primary, Medium (default)">
        <button type="button" className="btn btn--primary">
          Button
        </button>
        <button type="button" className="btn btn--primary bg-gradient-white">
          Button
        </button>
        <button type="button" className="btn btn--primary bg-gradient-neutral-10">
          Button
        </button>
        <button type="button" className="btn btn--primary border--focus">
          Button
        </button>
        <button type="button" className="btn btn--primary" disabled>
          Button
        </button>

        <button type="button" className="btn btn--primary">
          <span>Button</span>
          <FontAwesomeIcon icon={["far", "arrow-right"]} />
        </button>
        <button type="button" className="btn btn--primary bg-gradient-white">
          <span>Button</span>
          <FontAwesomeIcon icon={["far", "arrow-right"]} />
        </button>
        <button type="button" className="btn btn--primary bg-gradient-neutral-10">
          <span>Button</span>
          <FontAwesomeIcon icon={["far", "arrow-right"]} />
        </button>
        <button type="button" className="btn btn--primary border--focus">
          <span>Button</span>
          <FontAwesomeIcon icon={["far", "arrow-right"]} />
        </button>
        <button type="button" className="btn btn--primary" disabled>
          <span>Button</span>
          <FontAwesomeIcon icon={["far", "arrow-right"]} />
        </button>

        <div className="col-span-5 my-2 font-mono text-base">
          <CodeBlock text={primaryDefault} theme={tomorrowNight} language="javascript" />
        </div>
        <div className="col-span-5 my-2 font-mono text-base">
          <CodeBlock text={primaryDefaultWIcon} theme={tomorrowNight} language="javascript" />
        </div>
      </ButtonGrid>

      <ButtonGrid title="Primary, Large">
        <button type="button" className="btn--large btn--primary">
          Button
        </button>
        <button type="button" className="btn--large btn--primary bg-gradient-white">
          Button
        </button>
        <button type="button" className="btn--large btn--primary bg-gradient-neutral-10">
          Button
        </button>
        <button type="button" className="btn--large btn--primary border--focus">
          Button
        </button>
        <button type="button" className="btn--large btn--primary" disabled>
          Button
        </button>

        <button type="button" className="btn--large btn--primary">
          <span>Button</span>
          <FontAwesomeIcon icon={["far", "arrow-right"]} />
        </button>
        <button type="button" className="btn--large btn--primary bg-gradient-white">
          <span>Button</span>
          <FontAwesomeIcon icon={["far", "arrow-right"]} />
        </button>
        <button type="button" className="btn--large btn--primary bg-gradient-neutral-10">
          <span>Button</span>
          <FontAwesomeIcon icon={["far", "arrow-right"]} />
        </button>
        <button type="button" className="btn--large btn--primary border--focus">
          <span>Button</span>
          <FontAwesomeIcon icon={["far", "arrow-right"]} />
        </button>
        <button type="button" className="btn--large btn--primary" disabled>
          <span>Button</span>
          <FontAwesomeIcon icon={["far", "arrow-right"]} />
        </button>

        <div className="col-span-5 my-2 font-mono text-base">
          <CodeBlock text={primaryLarge} theme={tomorrowNight} language="javascript" />
        </div>
        <div className="col-span-5 my-2 font-mono text-base">
          <CodeBlock text={primaryLargeWIcon} theme={tomorrowNight} language="javascript" />
        </div>
      </ButtonGrid>

      <ButtonGrid title="Primary, Small">
        <button type="button" className="btn--sm btn--primary">
          Button
        </button>
        <button type="button" className="btn--sm btn--primary bg-gradient-white">
          Button
        </button>
        <button type="button" className="btn--sm btn--primary bg-gradient-neutral-10">
          Button
        </button>
        <button type="button" className="btn--sm btn--primary border--focus">
          Button
        </button>
        <button type="button" className="btn--sm btn--primary" disabled>
          Button
        </button>

        <button type="button" className="btn--sm btn--primary">
          <span>Button</span>
          <FontAwesomeIcon icon={["far", "arrow-right"]} />
        </button>
        <button type="button" className="btn--sm btn--primary bg-gradient-white">
          <span>Button</span>
          <FontAwesomeIcon icon={["far", "arrow-right"]} />
        </button>
        <button type="button" className="btn--sm btn--primary bg-gradient-neutral-10">
          <span>Button</span>
          <FontAwesomeIcon icon={["far", "arrow-right"]} />
        </button>
        <button type="button" className="btn--sm btn--primary border--focus">
          <span>Button</span>
          <FontAwesomeIcon icon={["far", "arrow-right"]} />
        </button>
        <button type="button" className="btn--sm btn--primary" disabled>
          <span>Button</span>
          <FontAwesomeIcon icon={["far", "arrow-right"]} />
        </button>

        <div className="col-span-5 my-2 font-mono text-base">
          <CodeBlock text={primarySmall} theme={tomorrowNight} language="javascript" />
        </div>
        <div className="col-span-5 my-2 font-mono text-base">
          <CodeBlock text={primarySmallWIcon} theme={tomorrowNight} language="javascript" />
        </div>
      </ButtonGrid>

      <ButtonGrid title="Secondary, Medium (default)">
        <button type="button" className="btn btn--secondary">
          Button
        </button>
        <button type="button" className="btn btn--secondary bg-gradient-white">
          Button
        </button>
        <button type="button" className="btn btn--secondary bg-neutral-3">
          Button
        </button>
        <button type="button" className="btn btn--secondary border--focus">
          Button
        </button>
        <button type="button" className="btn btn--secondary" disabled>
          Button
        </button>

        <button type="button" className="btn btn--secondary">
          <span>Button</span>
          <FontAwesomeIcon icon={["far", "arrow-right"]} />
        </button>
        <button type="button" className="btn btn--secondary bg-gradient-white">
          <span>Button</span>
          <FontAwesomeIcon icon={["far", "arrow-right"]} />
        </button>
        <button type="button" className="btn btn--secondary bg-neutral-3">
          <span>Button</span>
          <FontAwesomeIcon icon={["far", "arrow-right"]} />
        </button>
        <button type="button" className="btn btn--secondary border--focus">
          <span>Button</span>
          <FontAwesomeIcon icon={["far", "arrow-right"]} />
        </button>
        <button type="button" className="btn btn--secondary" disabled>
          <span>Button</span>
          <FontAwesomeIcon icon={["far", "arrow-right"]} />
        </button>

        <div className="col-span-5 my-2 font-mono text-base">
          <CodeBlock text={secondaryDefault} theme={tomorrowNight} language="javascript" />
        </div>
        <div className="col-span-5 my-2 font-mono text-base">
          <CodeBlock text={secondaryDefaultWIcon} theme={tomorrowNight} language="javascript" />
        </div>
      </ButtonGrid>

      <ButtonGrid title="Secondary, Large">
        <button type="button" className="btn--large btn--secondary">
          Button
        </button>
        <button
          type="button"
          className="btn--large btn--secondary bg-gradient-white border--elevation"
        >
          Button
        </button>
        <button type="button" className="btn--large btn--secondary bg-neutral-3">
          Button
        </button>
        <button type="button" className="btn--large btn--secondary border--focus">
          Button
        </button>
        <button type="button" className="btn--large btn--secondary" disabled>
          Button
        </button>

        <button type="button" className="btn--large btn--secondary">
          <span>Button</span>
          <FontAwesomeIcon icon={["far", "arrow-right"]} />
        </button>
        <button
          type="button"
          className="btn--large btn--secondary bg-gradient-white border--elevation"
        >
          <span>Button</span>
          <FontAwesomeIcon icon={["far", "arrow-right"]} />
        </button>
        <button type="button" className="btn--large btn--secondary bg-neutral-3">
          <span>Button</span>
          <FontAwesomeIcon icon={["far", "arrow-right"]} />
        </button>
        <button type="button" className="btn--large btn--secondary border--focus">
          <span>Button</span>
          <FontAwesomeIcon icon={["far", "arrow-right"]} />
        </button>
        <button type="button" className="btn--large btn--secondary" disabled>
          <span>Button</span>
          <FontAwesomeIcon icon={["far", "arrow-right"]} />
        </button>

        <div className="col-span-5 my-2 font-mono text-base">
          <CodeBlock text={secondaryLarge} theme={tomorrowNight} language="javascript" />
        </div>
        <div className="col-span-5 my-2 font-mono text-base">
          <CodeBlock text={secondaryLargeWIcon} theme={tomorrowNight} language="javascript" />
        </div>
      </ButtonGrid>

      <ButtonGrid title="Secondary, Small">
        <button type="button" className="btn--sm btn--secondary">
          Button
        </button>
        <button
          type="button"
          className="btn--sm btn--secondary bg-gradient-white border--elevation"
        >
          Button
        </button>
        <button type="button" className="btn--sm btn--secondary bg-neutral-3">
          Button
        </button>
        <button type="button" className="btn--sm btn--secondary border--focus">
          Button
        </button>
        <button type="button" className="btn--sm btn--secondary" disabled>
          Button
        </button>

        <button type="button" className="btn--sm btn--secondary">
          <span>Button</span>
          <FontAwesomeIcon icon={["far", "arrow-right"]} />
        </button>
        <button type="button" className="btn--sm btn--secondary bg-gradient-white">
          <span>Button</span>
          <FontAwesomeIcon icon={["far", "arrow-right"]} />
        </button>
        <button type="button" className="btn--sm btn--secondary bg-neutral-3">
          <span>Button</span>
          <FontAwesomeIcon icon={["far", "arrow-right"]} />
        </button>
        <button type="button" className="btn--sm btn--secondary border--focus">
          <span>Button</span>
          <FontAwesomeIcon icon={["far", "arrow-right"]} />
        </button>
        <button type="button" className="btn--sm btn--secondary" disabled>
          <span>Button</span>
          <FontAwesomeIcon icon={["far", "arrow-right"]} />
        </button>

        <div className="col-span-5 my-2 font-mono text-base">
          <CodeBlock text={secondarySmall} theme={tomorrowNight} language="javascript" />
        </div>
        <div className="col-span-5 my-2 font-mono text-base">
          <CodeBlock text={secondarySmallWIcon} theme={tomorrowNight} language="javascript" />
        </div>
      </ButtonGrid>

      <ButtonGrid
        title="Critical"
        subheading="Critical buttons have the same sizing options as primary and secondary buttons. This class
          is often used for deletion and irreversible actions."
      >
        <button type="button" className="btn btn--critical">
          <span>Delete</span>
        </button>
        <button type="button" className="btn btn--critical bg-gradient-white">
          <span>Delete</span>
        </button>
        <button type="button" className="btn btn--critical bg-gradient-neutral-10">
          <span>Delete</span>
        </button>
        <button type="button" className="btn btn--critical border--focus">
          <span>Delete</span>
        </button>
        <button type="button" className="btn btn--critical" disabled>
          <span>Delete</span>
        </button>

        <div className="col-span-5 my-2 font-mono text-base">
          <CodeBlock text={criticalDefault} theme={tomorrowNight} language="javascript" />
        </div>
      </ButtonGrid>

      <ButtonGrid
        title="Ghost"
        subheading="Ghost buttons have the same sizing options as primary and secondary buttons. This class is
          often used for stand-alone icon buttons."
      >
        <button type="button" className="btn btn--ghost">
          <FontAwesomeIcon icon={["far", "trash-alt"]} />
        </button>
        <button type="button" className="btn btn--ghost bg-neutral-8">
          <FontAwesomeIcon icon={["far", "trash-alt"]} />
        </button>
        <button type="button" className="btn btn--ghost bg-neutral-3">
          <FontAwesomeIcon icon={["far", "trash-alt"]} />
        </button>
        <button type="button" className="btn btn--ghost border--focus">
          <FontAwesomeIcon icon={["far", "trash-alt"]} />
        </button>
        <button type="button" className="btn btn--ghost" disabled>
          <FontAwesomeIcon icon={["far", "trash-alt"]} />
        </button>

        <div className="col-span-5 my-2 font-mono text-base">
          <CodeBlock text={ghostDefault} theme={tomorrowNight} language="javascript" />
        </div>
      </ButtonGrid>

      <section>
        <h2>Grouped Buttons</h2>
        <p className="mb-3 mt-1">
          Wrap buttons with the class <code>.btn-group</code> to group buttons together. Add the
          class <code>.active</code> to the active button.
        </p>
        <section className="grid-cols-2 gap-2 pt-4 grid">
          <div className="btn-group w-fit">
            <button type="button" className="btn btn--secondary active">
              Datasheet
            </button>
            <button type="button" className="btn btn--secondary">
              Chart
            </button>
            <button type="button" className="btn btn--secondary">
              Timeline
            </button>
            <button type="button" className="btn btn--secondary">
              Insights
            </button>
          </div>
        </section>
      </section>

      <section className="mt-12">
        <h1>Links</h1>
        <p className="mb-3 mt-1">
          The class <code>.link</code> can be applied to all <code>a</code> and <code>button</code>{" "}
          tags.
        </p>

        <ButtonGrid title="Links (default)">
          <a href="#buttons" className="link">
            Action Link
          </a>
          <a href="#buttons" className="link underline">
            Action Link
          </a>
          <a href="#buttons" className="link text-primary-100 underline">
            Action Link
          </a>
          <a href="#buttons" className="link focus">
            Action Link
          </a>
          <a href="#buttons" className="link text-neutral-100 opacity-30">
            Action Link
          </a>
        </ButtonGrid>

        <ButtonGrid title="Links, Large">
          <a href="#buttons" className="link--large">
            <span>Action Link</span>
            <FontAwesomeIcon icon={["far", "arrow-right"]} />
          </a>
          <a href="#buttons" className="link--large underline">
            <span>Action Link</span>
            <FontAwesomeIcon icon={["far", "arrow-right"]} />
          </a>
          <a href="#buttons" className="link--large text-primary-100 underline">
            <span>Action Link</span>
            <FontAwesomeIcon icon={["far", "arrow-right"]} />
          </a>
          <a href="#buttons" className="link--large focus">
            <span>Action Link</span>
            <FontAwesomeIcon icon={["far", "arrow-right"]} />
          </a>
          <a href="#buttons" className="link--large text-neutral-100 opacity-30">
            <span>Action Link</span>
            <FontAwesomeIcon icon={["far", "arrow-right"]} />
          </a>
        </ButtonGrid>

        <ButtonGrid title="Links, Small">
          <button type="button" className="link--sm">
            Action Link
          </button>
          <button type="button" className="link--sm underline">
            Action Link
          </button>
          <button type="button" className="link--sm text-primary-100 underline">
            Action Link
          </button>
          <button type="button" className="link--sm focus">
            Action Link
          </button>
          <button type="button" className="link--sm" disabled>
            Action Link
          </button>
        </ButtonGrid>

        <div className="col-span-5 my-4 font-mono text-base">
          <CodeBlock text={links} theme={tomorrowNight} language="javascript" />
        </div>
      </section>
    </>
  )
}

export default Buttons

const primaryDefault = `<button type="button" className='btn btn--primary'>Button</button>`
const primaryDefaultWIcon = `<button type="button" className="btn btn--primary">
  <span>Button</span>
  <FontAwesomeIcon icon={["far", "arrow-right"]} />
</button>`

const primaryLarge = `<button type="button" className='btn--large btn--primary'>Button</button>`
const primaryLargeWIcon = `<button type="button" className="btn--large btn--primary">
  <span>Button</span>
  <FontAwesomeIcon icon={["far", "arrow-right"]} />
</button>`

const primarySmall = `<button type="button" className='btn--sm btn--primary'>Button</button>`
const primarySmallWIcon = `<button type="button" className="btn--sm btn--primary">
  <span>Button</span>
  <FontAwesomeIcon icon={["far", "arrow-right"]} />
</button>`

const secondaryDefault = `<button type="button" className='btn btn--secondary'>Button</button>`
const secondaryDefaultWIcon = `<button type="button" className="btn btn--secondary">
  <span>Button</span>
  <FontAwesomeIcon icon={["far", "arrow-right"]} />
</button>`

const secondaryLarge = `<button type="button" className='btn--large btn--secondary'>Button</button>`
const secondaryLargeWIcon = `<button type="button" className="btn--large btn--secondary">
  <span>Button</span>
  <FontAwesomeIcon icon={["far", "arrow-right"]} />
</button>`

const secondarySmall = `<button type="button" className='btn--sm btn--secondary'>Button</button>`
const secondarySmallWIcon = `<button type="button" className="btn--sm btn--secondary">
  <span>Button</span>
  <FontAwesomeIcon icon={["far", "arrow-right"]} />
</button>`

const criticalDefault = `<button type="button" className="btn btn--critical">
  <span>Delete</span>
</button>`

const ghostDefault = `<button type="button" className="btn btn--ghost">
  <FontAwesomeIcon icon={["far", "trash-alt"]} />
</button>`

const links = `<a href="#" className="link">Action Link</a>
<a href="#" className="link--sm">Action Link</a>
<a href="#" className="link--large">Action Link</a>`

const gridHeading = (
  <>
    <p>Default</p>
    <p>Hover</p>
    <p>Active</p>
    <p>Focus</p>
    <p>Disabled</p>
  </>
)

interface ButtonGridProps {
  title: string
  subheading?: string
  children: React.ReactNode
}

const ButtonGrid = ({ title, subheading, children }: ButtonGridProps) => (
  <section>
    <h2>{title}</h2>
    <p className="col-span-5 mb-3 mt-1">{subheading}</p>
    <section className="grid-cols-5 gap-2 grid">
      {gridHeading}
      {children}
    </section>
  </section>
)
