import {
  Collection,
  FilterAttributes,
  NodeInterface,
  OrderClauseAttributes,
  PageInfo,
  VacancyRateByGroupQuery,
  VacancyRateByGroupQueryVariables,
  VacancyRateFullDataQuery,
  VacancyRateFullDataQueryVariables,
} from "types/graphql"
import OperationStore from "v2/operation_store"
import { FullVacancyRateDataSchema, GroupDataSchema } from "v2/react/components/dashboard/types"
import { GraphqlApi } from "v2/redux/GraphqlApi"

interface DrillDownFilterQueryResultType {
  currentCompany?: {
    collections: {
      locations: Collection
      chartSections: Collection
      employeeTypes: Collection
      jobFamilies: Collection
      orgUnitTypes: Collection[]
      positionImportances: Collection
      positionStatuses: Collection
    }
  }
}

interface PaginatedNodesQueryResultType {
  nodeContainer?: {
    nodePage: {
      pageInfo: PageInfo
      totalCount: number
      offsetBasedNodePage: NodeInterface[]
    }
  }
}

interface PaginatedNodesQueryInput {
  query: string
  key: string
  filters: FilterAttributes[]
  orderClauses: OrderClauseAttributes[]
  page: number
}

const getVacancyRateDataOperationId = OperationStore.getOperationId("VacancyRateFullData")
const getVacancyRateByGroupOperationId = OperationStore.getOperationId("VacancyRateByGroup")

const DashboardApi = GraphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    getVacancyRateData: builder.query({
      query: (params: VacancyRateFullDataQueryVariables) => ({
        operationId: getVacancyRateDataOperationId,
        variables: params,
      }),
      transformResponse: (response: VacancyRateFullDataQuery) => {
        const { positionMetrics } = response || {}

        const parsedData = FullVacancyRateDataSchema.parse(positionMetrics)

        const vacancyRateData = parsedData.vacancyRate[0]
        const comparisonData = vacancyRateData.metadata.comparison

        return {
          vacancyRate: vacancyRateData.value,
          comparison:
            comparisonData.percent_change !== null
              ? {
                  changeSinceRelativeDate: comparisonData.percent_change,
                  relativeDate: comparisonData.date,
                }
              : undefined,
          initialGroups: parsedData.vacancyRateByGroup,
          options: parsedData.groupByOptions,
        }
      },
    }),
    getVacancyRateDataByGroup: builder.query({
      query: (params: VacancyRateByGroupQueryVariables) => ({
        operationId: getVacancyRateByGroupOperationId,
        variables: params,
      }),
      transformResponse: (response: VacancyRateByGroupQuery) => {
        const { vacancyRateByGroup } = response.positionMetrics || {}
        const parsedData = GroupDataSchema.array().parse(vacancyRateByGroup)

        return parsedData
      },
    }),
    fetchPaginatedNodes: builder.query<PaginatedNodesQueryResultType, PaginatedNodesQueryInput>({
      query: ({ query, key, filters, orderClauses, page }) => ({
        query,
        operationName: "NodeContainerPeopleAndPositionsConnection",
        variables: { key, filters, orderClauses, page },
      }),
    }),
    fetchDrillDownOptions: builder.query({
      query: (query) => ({ query, operationName: "CurrentCompanyCollections" }),
      transformResponse: (response: DrillDownFilterQueryResultType) => {
        const { collections } = response.currentCompany || {}

        return Object.values(collections || {}).flat()
      },
    }),
  }),
})

export const {
  useGetVacancyRateDataQuery,
  useGetVacancyRateDataByGroupQuery,
  useFetchPaginatedNodesQuery,
  useFetchDrillDownOptionsQuery,
} = DashboardApi
