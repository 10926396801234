/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
class PositionDataLoader {
  constructor(baseSvg, chart, endpoint) {
    this.svg = baseSvg
    this.chart = chart
    this.endpoint = endpoint
  }

  loadVisible() {
    const ids = []
    const personIds = []
    const visibleWindow = this.calculateVisibleWindow()
    const { chart } = this
    this.svg
      .selectAll("g.node")
      .filter((d) => {
        if (d.loaded === true || d.loading === true) {
          return false
        }

        return this.visibleInWindow(d, visibleWindow)
      })
      .each(function (d) {
        chart.renderNodeBase(this)
        d.loading = true
        if (d.id) {
          return ids.push(d.id)
        } else if (d.person_id) {
          return personIds.push(d.person_id)
        }
      })
    this.batchLoadByIds(ids)
    return this.batchLoadPersonIds(personIds)
  }

  visibleNodes() {
    const visibleWindow = this.calculateVisibleWindow(false)
    return this.svg.selectAll("g.node").filter((d) => {
      return this.visibleInWindow(d, visibleWindow)
    })
  }

  visibleInWindow(d, visibleWindow) {
    return (
      d.x >= visibleWindow.x.min &&
      d.y >= visibleWindow.y.min &&
      d.x <= visibleWindow.x.max &&
      d.y <= visibleWindow.y.max
    )
  }

  calculateVisibleWindow(preload) {
    preload ??= true
    const svgHeight = parseInt(this.svg.node().attributes.height.value)
    const svgWidth = parseInt(this.svg.node().attributes.width.value)
    const transformGroup = this.svg.select("g[transform]").node()
    const transformAttribute = transformGroup.attributes.transform.value
    const translateCoordinates = this.parseTranslateCoordinates(transformAttribute)

    if (!translateCoordinates) {
      return
    }

    const [translateX, translateY] = Array.from(translateCoordinates)
    const scale = this.parseScale(transformAttribute)
    const preloadPercentage = preload ? 0.25 : 0

    return {
      x: {
        min: -(translateX / scale) - (svgWidth * preloadPercentage) / scale,
        max: svgWidth / scale - translateX / scale + (svgWidth * preloadPercentage) / scale,
      },
      y: {
        min: -(translateY / scale) - (svgHeight * preloadPercentage) / scale,
        max: svgHeight / scale - translateY / scale + (svgHeight * preloadPercentage) / scale,
      },
    }
  }

  // Extract translate x and y from a translate attribute value.
  // Coerces each to a whole number.
  parseTranslateCoordinates(testString) {
    const match = testString.match(/translate\(([-\d\.]+)(?:,|\s)([-\d\.]+)\)/)

    if (!match) {
      return
    }
    return _.map([match[1], match[2]], (value) => parseInt(value))
  }

  parseScale(testString) {
    const match = testString.match(/scale\((.+)\)/)
    if (!match) {
      return 1
    }
    return parseFloat(match[1])
  }

  loadAllWithCallback(callback) {
    const ids = this.nonLoadedPositionIds()

    const promises = this.batchLoadByIds(ids)
    if (!promises) {
      return callback()
    }
    return Promise.all(promises).then(() => callback())
  }

  batchLoadByIds(positionIds) {
    if (!(positionIds.length > 0)) {
      return
    }

    const groups = []
    const batchSize = 50
    let i = 0
    const total = positionIds.length

    while (i < total) {
      groups.push(positionIds.slice(i, (i += batchSize)))
    }

    const promises = []
    for (var ids of Array.from(groups)) {
      promises.push(this.loadByIds(ids))
    }

    return promises
  }

  batchLoadPersonIds(personIds) {
    if (!(personIds.length > 0)) {
      return
    }

    const groups = []
    const batchSize = 50
    let i = 0
    const total = personIds.length

    while (i < total) {
      groups.push(personIds.slice(i, (i += batchSize)))
    }

    const promises = []
    for (var ids of Array.from(groups)) {
      promises.push(this.loadPersonByIds(ids))
    }

    return promises
  }

  loadByIds(positionIds) {
    return new Promise((resolve, reject) => {
      const prefix = this.endpoint.indexOf("?") < 0 ? "?" : "&"
      const paramName = "ids[]="
      const param = prefix + paramName + positionIds.join("&" + paramName)

      if (!this.endpoint) {
        throw new Error("Org Chart - Position endpoint is required for loading json data")
      }
      return $.getJSON(this.endpoint + param)
        .done((positions) => {
          const nodes = _.compact(
            _.map(positions, (position) => {
              const orgChartNode = this.chart.find(position.id)
              if (!orgChartNode) {
                return
              }

              // There is one case where we want to set the children count,
              // and that's for nodes that do not have a children count number
              // set. In 3-level mode, this can happen for reports of a 3rd level
              // node after their 3rd level parent is dragged from the 3rd level
              // to a higher level. Its children are currently being loaded in
              // without any children counts.
              if (!isNaN(orgChartNode.get("children_count"))) {
                position = _.omit(position, "children_count")
              }

              orgChartNode.set(Object.assign({}, position, { loaded: true, loading: false }))
              return orgChartNode
            }),
          )

          this.chart.trigger("positions-loaded")
          return resolve()
        })
        .fail(() => {
          reject()
          throw new Error(`Org Chart - Could not load json from: ${this.endpoint}`)
        })
    })
  }

  loadPersonByIds(personIds) {
    return new Promise((resolve, reject) => {
      const paramName = "ids[]="
      const param = "?" + paramName + personIds.join("&" + paramName)

      return $.getJSON("/api/app/v1/people/organize" + param)
        .done((people) => {
          const nodes = _.map(people, (person) => {
            const orgChartNode = this.chart.findByPersonId(person.id)
            if (!orgChartNode) {
              return
            }

            orgChartNode.set(_.omit(person, ["id"]))
            orgChartNode.set({ loaded: true, loading: false })
            return orgChartNode
          })

          nodes.forEach(this.chart.renderNode, { withChildren: false })
          this.chart.trigger("positions-loaded")
          return resolve()
        })
        .fail(() => {
          reject()
          throw new Error(`Org Chart - Could not load json from: ${this.endpoint}`)
        })
    })
  }

  nonLoadedPositionIds() {
    const allNodes = this.svg.selectAll("g.node").data()
    const nonLoaded = _.reject(allNodes, (node) => node.loaded === true || node.loading === true)
    return nonLoaded.map((d) => d.id).filter(Number)
  }
}

export default PositionDataLoader
