import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React from "react"
import invariant from "tiny-invariant"
import { useCopyToClipboard } from "usehooks-ts"

import { IconWrapper } from "v2/react/shared/collection/menus/Dropdown/DropdownItem/IconWrapper"

const INDICATOR_TIMEOUT = 2000

type ValueToCopy = string | null | undefined

const useCopyInline = ({ valueToCopy }: { valueToCopy: ValueToCopy }) => {
  const [copiedValue, copy] = useCopyToClipboard()
  const [showCopyIndicator, setShowCopyIndicator] = React.useState(false)

  const initiateCopyValue = () => {
    invariant(valueToCopy, "valueToCopy is required")
    copy(valueToCopy)
    setShowCopyIndicator(true)
    setTimeout(() => setShowCopyIndicator(false), INDICATOR_TIMEOUT)
  }

  return { copiedValue, initiateCopyValue, showCopyIndicator }
}

const CopyInlineContent = ({
  showCopyIndicator,
  label,
}: {
  showCopyIndicator: boolean
  label: string
}) => (
  <div className="items-center gap-1.5 flex">
    <span>{label}</span>
    <span
      data-testid="copy-indicator"
      className={cn(
        "ml-auto select-none text-sm/5 text-neutral-64 opacity-0 transition-opacity duration-200 ease-in-out",
        showCopyIndicator && "opacity-100",
      )}
    >
      {"Copied".t("org_chart")}
    </span>
  </div>
)

const CopyInlineButton = ({
  label,
  valueToCopy,
  className,
}: {
  label: string
  valueToCopy: string
  className?: string
}) => {
  const { initiateCopyValue, showCopyIndicator } = useCopyInline({ valueToCopy })

  return (
    <button type="button" onClick={initiateCopyValue} className={className}>
      <IconWrapper icon={<FontAwesomeIcon icon={["far", "clone"]} />}>
        <CopyInlineContent showCopyIndicator={showCopyIndicator} label={label} />
      </IconWrapper>
    </button>
  )
}

export { CopyInlineButton, CopyInlineContent, useCopyInline, ValueToCopy }
