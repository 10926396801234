import React from "react"
import { createRoot } from "react-dom/client"

import { EditSetup } from "v2/react/components/headcountPlanning/EditSetup"
import RootProvider from "v2/react/components/RootProvider"

const containerId = "react-headcount-plans-edit-setup-container"

function getHeadcountPlanId() {
  const el = document.getElementById(containerId)
  return parseInt(el?.dataset.headcountPlanId ?? "-1", 10)
}

$.onmount(`#${containerId}`, () => {
  const container = document.getElementById(containerId)
  const root = createRoot(container)
  root.render(
    <RootProvider>
      <EditSetup headcountPlanId={getHeadcountPlanId()} />
    </RootProvider>,
  )
})
