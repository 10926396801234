// d3 v3 support for callbacks after transition is over.
// Lifted from here:
// https://stackoverflow.com/questions/10692100/invoke-a-callback-at-the-end-of-a-transition
// In d3 v4+, you can listen for the 'end' event instead.
function transitionsEnded(transition, callback) {
  if (typeof callback !== "function") throw new Error("Wrong callback in endall")

  if (transition.size() === 0) callback()

  let n = 0
  transition
    .each(() => {
      n += 1
      return n - 1
    })
    .each("end", (...args) => {
      n -= 1
      if (!(n + 1)) callback.apply(this, args)
    })
}

export default transitionsEnded
