import { today } from "@internationalized/date"
import { useMemo } from "react"
import { DateValue, useLocale } from "react-aria"

import { useTimeZone } from "v2/react/hooks/useTimeZone"
import { buildYearOptions } from "v2/react/shared/forms/DateInputs/utils/dates"

type UseCalendarDateContext = ReturnType<typeof useCalendarDateContext>

interface UseCalendarDateContextProps {
  focusedDate: DateValue
  startDate?: DateValue | null
  endDate?: DateValue | null
  minValue?: DateValue | null
  maxValue?: DateValue | null
}

const useCalendarDateContext = ({
  focusedDate,
  startDate,
  endDate,
  minValue,
  maxValue,
}: UseCalendarDateContextProps) => {
  const locale = useLocale()
  const currentDate = useCurrentDate()

  const startMonth = monthWithAdjustedIndex(startDate?.month) ?? currentDate.month
  const startYear = startDate?.year ?? currentDate.year
  const endYear = endDate?.year ?? currentDate.year
  const currentYear = currentDate.year

  const activeMonth = monthWithAdjustedIndex(focusedDate?.month) ?? startMonth
  const activeYear = focusedDate?.year ?? startYear

  const months = useMemo(() => {
    const formatter = new Intl.DateTimeFormat(locale.locale, { month: "long" })
    return Array.from({ length: 12 }, (_, index) => {
      const date = new Date(0, index)
      return {
        id: index,
        label: formatter.format(date),
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const minYear = Math.min(minValue ? minValue.year : currentYear - 10, startYear, activeYear)
  const maxYear = Math.max(maxValue ? maxValue.year : currentYear + 10, endYear, activeYear)
  const years = useMemo(
    () => buildYearOptions({ minYear, maxYear, activeYear }),
    [minYear, maxYear, activeYear],
  )

  return {
    currentDate,
    months,
    activeMonth,
    years,
    activeYear,
  }
}

const useCurrentDate = () => {
  const timeZone = useTimeZone()
  return useMemo(() => today(timeZone), [timeZone])
}

const monthWithAdjustedIndex = (month?: number | null) => (month ? month - 1 : undefined)

export { useCalendarDateContext, useCurrentDate }
export type { UseCalendarDateContext }
