import React from "react"

import RootProvider from "v2/react/components/RootProvider"
import { closeProfilePlanSidebar } from "v2/redux/slices/ProfilePanelSlice"
import { openSuccessionPlanSidebar } from "v2/redux/slices/SuccessionPlanPanelSlice"
import { useAppDispatch } from "v2/redux/store"

import { PanelOpenerWithTooltipPageAction } from "./PanelOpenerWithTooltip"

interface Props {
  linkText: string
  positionId: string
  sortOrder: number
}

const WithProvider = ({ linkText, positionId, sortOrder }: Props) => {
  const dispatch = useAppDispatch()

  const showPlanPanel = () => {
    dispatch(openSuccessionPlanSidebar({ positionId }))
    dispatch(closeProfilePlanSidebar())
  }

  return (
    <PanelOpenerWithTooltipPageAction
      contentClasses="react-tooltip-content--sm mt-2"
      onClick={showPlanPanel}
      placement="default-centered"
      triggerClasses="bubble-link"
    >
      <span>#{sortOrder}</span>
      {linkText}
    </PanelOpenerWithTooltipPageAction>
  )
}

const BubbleButton = ({ linkText, positionId, sortOrder }: Props) => (
  <RootProvider>
    <WithProvider linkText={linkText} positionId={positionId} sortOrder={sortOrder} />
  </RootProvider>
)

export { BubbleButton }
