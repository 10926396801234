import fp from "lodash/fp"
import { useMemo } from "react"

import { AllowedAttribute } from "types/graphql"
import { WatchFieldName } from "v2/react/components/positions/positionFieldValuesDiff"
import { tryParseEntityAndId } from "v2/react/utils/uniqueKey"

type UseWatchFieldNamesFromAllowedAttributesArg = {
  allowedAttributes: Omit<AllowedAttribute, "isRemovable">[]
}

const reduceAllowedAttributesIntoWatchFieldNames = fp.pipe(
  fp.reduce(intoWatchFieldNames, []),
  fp.uniq,
)

function intoWatchFieldNames(
  memo: WatchFieldName[],
  allowedAttribute: Omit<AllowedAttribute, "isRemovable">,
): WatchFieldName[] {
  if (!allowedAttribute.isSelected) return memo

  let parsedEntity = tryParseEntityAndId(allowedAttribute.id, { only: "custom_field" })
  if (parsedEntity) return [...memo, allowedAttribute.id as `custom_field_${number}`]

  parsedEntity = tryParseEntityAndId(allowedAttribute.id, { only: "variable_pay_type" })
  if (parsedEntity)
    return [...memo, `variable_pay_type_${parsedEntity.id}` as `variable_pay_type_${number}`]

  parsedEntity = tryParseEntityAndId(allowedAttribute.id, { only: "org_unit_type" })
  if (parsedEntity) return [...memo, allowedAttribute.id as `org_unit_type_${number}`]

  switch (allowedAttribute.id) {
    case "reports_to":
      return [...memo, "position.parentId"]
    case "full_time_equivalent":
      return [...memo, "position.fte"]
    case "employee_type":
    case "location":
    case "position_status":
      return [...memo, `position.${fp.camelCase(allowedAttribute.id)}Id` as WatchFieldName]
    case "budgeted_base_pay_rate":
      return [...memo, "position.positionBasePay"]
    case "budgeted_base_pay_type":
      return [...memo, "position.positionBasePayType"]
    case "company_code":
    case "eeoc_classification":
    case "flsa_classification":
    case "position_hours_per_week":
    case "hiring_priority":
      return [...memo, `position.${fp.camelCase(allowedAttribute.id)}` as WatchFieldName]
    case "position_type":
      return [...memo, "position.isAssistant"]
    case "external_id":
      return [...memo, "position.externalIdentifier"]
    case "position_importance":
      return [...memo, "position.importance"]
    case "title":
      return [...memo, "position.positionType"]
    default:
      return memo
  }
}

const useWatchKeysFromAllowedAttributesForPositionDiff = ({
  allowedAttributes,
}: UseWatchFieldNamesFromAllowedAttributesArg) =>
  useMemo(() => reduceAllowedAttributesIntoWatchFieldNames(allowedAttributes), [allowedAttributes])

export { useWatchKeysFromAllowedAttributesForPositionDiff }
