import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React, { ReactNode } from "react"

import { Option } from "types/graphql"

export type MenuOption<T> = Omit<Option, "id"> & { id: T; selected: boolean }

interface SelectorModuleButtonGroupProps<T> {
  fieldId: string
  fieldLabel: string
  formatDisplay?: (option: MenuOption<T>) => ReactNode
  options: MenuOption<T>[]
  onSelect: (optionId: string) => void
}

/**
 *
 * This component is a basic wrapper to render a list of `SelectorModuleButton`.
 *
 * Props:
 * - `fieldLabel`: A string label for the field
 * - `formatDisplay`: (optional) A function that returns a ReactNode to display in the button. If not passed in, the display defaults to the option label.
 * - `options`: An array of all available options.
 * - `onSelect`: A callback function to update the state of the options array when an option's selected state changes
 * Usage:
 * ```
 *  <SelectorModuleButtonGroup options={filterBy} onSelect={setFilterBy} />
 * ```
 */
const SelectorModuleButtonGroup = <T extends string>({
  fieldId,
  fieldLabel,
  formatDisplay,
  options,
  onSelect,
}: SelectorModuleButtonGroupProps<T>) => (
  <div className="input-group !mb-6" data-testid="selector-module-button-group">
    <div className="mb-2 items-center justify-between flex">
      <span className="text-base-bold">{fieldLabel}</span>
    </div>
    <div className={cn("gap-1.5 grid", `grid-cols-${options.length}`)}>
      {options.map((option) => (
        <SelectorModuleButton
          key={option.id}
          formatDisplay={formatDisplay}
          fieldId={fieldId}
          option={option}
          onSelect={onSelect}
        />
      ))}
    </div>
  </div>
)

interface SelectorModuleButtonProps<T> {
  formatDisplay?: (option: MenuOption<T>) => ReactNode
  option: MenuOption<T>
  onSelect: (optionId: string) => void
  fieldId: string
}

/**
 *
 * This component renders a selectable button with our selector-module style.
 *
 * Props:
 * - `formatDisplay`: (optional) A function that returns a ReactNode to display in the button. If not passed in, the display defaults to the option label.
 * - `option`: The current option to be displayed in the button.
 * - `onSelect`: A callback function to update the state of the options array when an option's selected state changes
 * Usage:
 * ```
 * <SelectorModuleButton
 *   option={option}
 *   onSelect={onSelect}
 * />
 * ```
 */
export const SelectorModuleButton = <T extends string>({
  formatDisplay,
  option,
  onSelect,
  fieldId,
}: SelectorModuleButtonProps<T>) => (
  <div
    className={cn("selector-module-button", {
      selected: option.selected,
    })}
  >
    <label
      htmlFor={`${fieldId}-${option.id}-filter`}
      className="selector-module-button-body relative !mb-0 place-content-center text-base grid"
    >
      <input
        type="checkbox"
        id={`${fieldId}-${option.id}-filter`}
        className="absolute !m-0 h-full w-full cursor-pointer opacity-0"
        checked={option.selected}
        onChange={() => onSelect(option.id)}
      />
      <span className="selector-text !mb-0 leading-[14px]">
        {formatDisplay ? formatDisplay(option) : option.label}
      </span>
    </label>
    <div className="check">
      <FontAwesomeIcon icon={["fas", "check"]} />
    </div>
  </div>
)

export { SelectorModuleButtonGroup }
