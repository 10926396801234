import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React from "react"
import { ConnectDropTarget } from "react-dnd"
import { useTranslation } from "react-i18next"

/**
 *
 * The optional props are optional so that this component
 * can work with both the react-dnd library and the native
 * HTML drag and drop functionality
 */
interface DropzoneProps {
  children: React.ReactNode
  isDraggingOver: boolean
  dropzoneRef?: ConnectDropTarget
  onDrop?: (event: React.DragEvent<HTMLDivElement>) => void
  onDragExit?: (event: React.DragEvent<HTMLDivElement>) => void
  onDragOver?: (event: React.DragEvent<HTMLDivElement>) => void
}

function Dropzone({
  children,
  isDraggingOver,
  dropzoneRef,
  onDrop,
  onDragExit,
  onDragOver,
}: DropzoneProps) {
  return (
    <div
      className={classNames(
        "page-margin-b gap-2 rounded-lg border border-neutral-64 px-3 py-2 text-neutral-64 flex",
        {
          "border-solid border-neutral-8 bg-primary-3": isDraggingOver,
          "border-dashed border-neutral-8 bg-neutral-3": !isDraggingOver,
        },
      )}
      ref={dropzoneRef}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragExit={onDragExit}
    >
      {children}
    </div>
  )
}

export { Dropzone }

export const EmptyDropzone = () => {
  const { t } = useTranslation()

  return <p>{t("v2.defaults.empty_dropzone")}</p>
}

export interface DropzoneFilterProps {
  label: string
  onClick: () => void
}

export const DropzoneFilter = ({ label, onClick }: DropzoneFilterProps) => (
  <div className="badge--gray">
    <span>{label}</span>
    <div className="flex-col justify-center flex">
      <button type="button" className="bg-transparent px-[2px]" onClick={onClick}>
        <FontAwesomeIcon className="text-neutral-80" icon={["far", "times"]} />
      </button>
    </div>
  </div>
)
