/* eslint-disable react/jsx-props-no-spreading */
import cn from "classnames"
import React, { useRef, useState } from "react"
import { AriaDatePickerProps, DateValue, useDatePicker } from "react-aria"
import { useDatePickerState } from "react-stately"

import { PickerCalendar } from "v2/react/shared/forms/DateInputs/DatePicker/PickerCalendar"
import { DateField, type DateFieldProps } from "v2/react/shared/forms/DateInputs/shared/DateField"
import { Dialog } from "v2/react/shared/forms/DateInputs/shared/Dialog"
import { InputErrorText } from "v2/react/shared/forms/InputErrorText"

interface DatePickerProps extends AriaDatePickerProps<DateValue> {
  id: string
  wrapperClassName?: string
  size?: "sm"
  errorMessage?: string
  isDisabled?: boolean
  /**
   * Whether or not to render the date picker with an icon.
   * @default true
   */
  withIcon?: boolean
}

const DatePicker = ({
  id,
  wrapperClassName,
  size,
  errorMessage,
  isDisabled,
  withIcon = true,
  ...props
}: DatePickerProps) => {
  const [clickedSegmentRef, setClickedSegmentRef] = useState<
    React.RefObject<HTMLDivElement> | undefined
  >(undefined)
  const state = useDatePickerState(props)
  const ref = useRef<HTMLDivElement>(null)
  const dateFieldRef = useRef(null)
  const { groupProps, labelProps, fieldProps, dialogProps, calendarProps } = useDatePicker(
    props,
    state,
    ref,
  )
  const { isOpen } = state

  const handleSegmentFocus: DateFieldProps["onSegmentFocus"] = (_e, _date, ref) => {
    state.open()
    setClickedSegmentRef(ref)
  }

  const handleClickOutside = () => state.close()

  const handleDateFieldChange: NonNullable<DateFieldProps["onDateFieldChange"]> = (date) =>
    date && state.setValue(date)

  const handleDateFieldSelection: DateFieldProps["onDateFieldSelection"] = (e) => {
    const target = e.target
    if (!(target instanceof HTMLElement)) return

    target.blur()
    state.close()
  }

  return (
    <div id={id} className={cn("Date-Picker relative", wrapperClassName)}>
      <div {...labelProps} className="mb-1 items-center gap-x-1 flex">
        <span className="select-none font-bold text-neutral-100">{props.label}</span>
      </div>
      <div
        {...groupProps}
        ref={ref}
        className={cn("w-full rounded-lg bg-white flex", errorMessage ? "border--error" : "")}
      >
        <DateField
          fieldRef={dateFieldRef}
          onSegmentFocus={handleSegmentFocus}
          inputClassName={cn("relative", {
            "range-error focus-within:!border--main-hover": errorMessage,
            "focus-within:border--focus": !errorMessage,
            disabled: isDisabled,
          })}
          onDateFieldChange={handleDateFieldChange}
          onDateFieldSelection={handleDateFieldSelection}
          isSingleField
          isDisabled={isDisabled}
          withIcon={withIcon}
          {...fieldProps}
        />
      </div>
      {isOpen && ref.current && (
        <Dialog
          fieldId={id}
          fieldRef={dateFieldRef}
          size={size}
          focusRefOnMount={clickedSegmentRef}
          className={cn("box-border w-[280px]")}
          onClickOutside={handleClickOutside}
          triggerCoordinates={ref.current.getBoundingClientRect()}
          {...dialogProps}
        >
          <PickerCalendar {...calendarProps} />
        </Dialog>
      )}
      {errorMessage && <InputErrorText message={errorMessage} />}
    </div>
  )
}

export { DatePicker }
