/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const Utils = {
  camelToSnakeCase(str) {
    return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
  },
  camelToKebabCase(str) {
    return str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`)
  },
}

const Selectors = {
  fundingSourceRows(d) {
    return fundingSourceRows(d)
  },
  totalRow() {
    return $("#fs-total-row")
  },
}

const State = {
  allocationType() {
    return $(".funding-source-position-table a.dropdown-menu-link.active").data("selectorValue")
  },
  rowCount() {
    return fundingSourceRows().length
  },
  autocompleteData(d) {
    return getFundingSourceAutocompleteData(d)
  },
  totalAnnualAmount(d) {
    return getTotalAnnualAmount(d)
  },
  tooltip(d) {
    return getDataForTooltip(d)
  },
  fundingSourceIds(d) {
    return fundingSourceIdsPresent(d)
  },
  currentAmountForRow(d) {
    return getCurrentAmountForRow(d)
  },
}

const Events = {
  addFundingSource() {
    return addFundingSource
  },
  cancelAddFundingSource() {
    return cancelAddFundingSource
  },
  addFundingSourceRow() {
    return addFundingSourceRow
  },
  removeFundingSourceRow() {
    return removeFundingSourceRow
  },
  toggleAllocationType() {
    return toggleAllocationType
  },
}

const Types = {
  PERCENT: "percent_of_budget",
  AMOUNT: "amount",
}

const Constants = {
  SUB_FORM_MARGIN: 8,
}

// Selectors

var fundingSourceRows = function (include) {
  include ??= []
  let selector = ".fs-row"
  include.forEach((klass) => (selector = selector.concat(", ", klass)))
  return $(selector)
}

// State

var getFundingSourceAutocompleteData = function (keys = null) {
  const dataKeys = keys || [
    "fundingSourceId",
    "fundingSourceAmount",
    "amountAllocatedToOtherPositions",
    "name",
    "dateRange",
  ]
  const autocompleteData = $("#position_funding_source_autocomplete").data()
  const data = {}
  dataKeys.forEach((key) => (data[key] = autocompleteData[key]))
  return data
}

var getTotalAnnualAmount = function (asFloat) {
  asFloat ??= false
  const val = $("#total_").val()
  if (!asFloat) {
    return val
  }
  return App.Helpers.parseCurrency(val) || 0.0
}

var getDataForTooltip = function ($row) {
  const data = $row.data()
  const allocatedToThisPosition = State.currentAmountForRow($row)
  const fundingSourceAmount = data["fundingSourceAmount"]
  const allocatedToOtherPositions = data["amountAllocatedToOtherPositions"]
  const calculatedData = {
    allocatedToThisPosition,
    remainingUnallocated: fundingSourceAmount - allocatedToOtherPositions - allocatedToThisPosition,
  }
  return Object.assign(data, calculatedData)
}

var fundingSourceIdsPresent = function () {
  const funding_source_ids = []
  Selectors.fundingSourceRows().each((index, row) =>
    funding_source_ids.push($(row).data("fundingSourceId")),
  )
  return funding_source_ids
}

var getCurrentAmountForRow = function ($row) {
  let row_amount = null
  if (State.allocationType() === Types.AMOUNT) {
    row_amount = $row.find(".funding-source-amount-field").val()
  } else {
    row_amount = $row.find('input[id^="amount_text"]').val()
  }
  return App.Helpers.parseCurrency(row_amount) || 0.0
}

// Events

var addFundingSource = function () {
  let disable
  const $fundingSourcePopover = $("#funding-sources-position-form")
  handleFundingSourcePopoverPositioning($fundingSourcePopover)
  $fundingSourcePopover.removeClass("hidden")
  $("#position_funding_source_autocomplete").focus()
  return handleSettingButtonStates((disable = true))
}

var cancelAddFundingSource = function () {
  let disable
  $("#funding-sources-position-form").addClass("hidden")
  $("#position_funding_source_autocomplete").val("")
  clearAddFundingSourceErrors()
  return handleSettingButtonStates((disable = false))
}

var addFundingSourceRow = function (e) {
  if (!validateAddFundingSourceForm()) {
    return
  }
  cancelAddFundingSource()
  renderFundingSourceRow()
  return updateExcludedIds()
}

var removeFundingSourceRow = function () {
  let show
  const rowToRemove = $(this).closest("[data-funding-source-row]")
  if (rowToRemove.find(".id-field").val()) {
    rowToRemove.addClass("fs-to-delete")
    rowToRemove.removeClass("fs-row")
    rowToRemove.find(".destroy-form").val(true)
    clearFundingSourceInputErrors(rowToRemove)
    rowToRemove.hide()
  } else {
    rowToRemove.remove()
  }
  const numRows = State.rowCount()
  if (numRows === 1) {
    toggleTotalRow((show = false))
  }
  updateFundingSourceAmounts()
  updateFundingSourceRowIndexes()
  updateExcludedIds()
  if (numRows === 0) {
    toggleTypeButton((show = false))
    return $(".funding-source-rows").addClass("hidden")
  }
}

var toggleAllocationType = function () {
  const clicked_type = $(this).data("selectorValue")
  if (!dropdownChanged(clicked_type)) {
    return
  }
  toggleFieldFormat(clicked_type)
  clearAllFields()
  clearFundingSourceInputErrors()
  updateFundingSourceAmounts()
  updateAllocationWarningTooltip()
  setAllocationFormValues(clicked_type)
  return Selectors.fundingSourceRows().first().find(".funding-source-amount-field").focus()
}

// Helpers

var handleFundingSourcePopoverPositioning = function ($fundingSourcePopover) {
  const $triggerButton = $("#add-new-funding-source")

  if (State.rowCount() <= 2) {
    // Positiom above
    const formHeight = $fundingSourcePopover.outerHeight()

    return $fundingSourcePopover.css(
      "top",
      -($triggerButton.position().top + Constants.SUB_FORM_MARGIN + formHeight),
    )
  } else {
    // Position below
    return $fundingSourcePopover.css(
      "top",
      $triggerButton.position().top + $triggerButton.outerHeight() + Constants.SUB_FORM_MARGIN,
    )
  }
}

const setAddFundingSourceButtonState = (disable) =>
  $("#add-new-funding-source")?.prop("disabled", disable).toggleClass("disabled", disable)

const setModalSubmitButtonState = function (disable) {
  const $modalSubmitButton = $(".modal-footer input[type=submit]")
  return $modalSubmitButton?.prop("disabled", disable).toggleClass("disabled", disable)
}

var handleSettingButtonStates = function (disable) {
  setAddFundingSourceButtonState(disable)
  return setModalSubmitButtonState(disable)
}

const createFundingSourceRow = function (index, data) {
  data ??= {}
  const $newRow = fetchOrCloneRow(index, data)

  updateFundingSourceRowIndex(index, $newRow)
  setRowFormValues(index, $newRow)
  $(".funding-source-rows").append($newRow).removeClass("hidden")
  if ($(".fs-to-delete").length > 0) {
    updateFundingSourceRowIndexes()
  }
  return $newRow
}

var fetchOrCloneRow = function (index, data) {
  data ??= {}
  data = Object.assign(State.autocompleteData(), data)
  let $newRow = null
  // Look for existing funding source in deleted rows, so as not to create a new record
  const $existingRow = $(`.fs-to-delete[data-funding-source-id='${data.fundingSourceId}']`)
  if ($existingRow.length > 0) {
    $newRow = $existingRow
    $newRow.removeClass("fs-to-delete")
    $newRow.addClass("fs-row")
    $newRow.find(".destroy-form").val(false)
    $newRow.show()
    if (index !== 0) {
      $newRow.find(".funding-source-amount-field").val("")
    }
  } else {
    $newRow = cloneRow()
    $newRow.find(".funding-source-name").text(data.name)
    $newRow.data(data)
  }
  return $newRow
}

var cloneRow = function () {
  const $newForm = $(".fs-template-row").clone()
  $newForm.removeClass("hidden fs-template-row")
  return $newForm.addClass("fs-row")
}

var setRowFormValues = function (index, $row) {
  const data = $row.data()
  const hiddenFields = ["fundingSourceId"]
  hiddenFields.forEach(function (dataKey) {
    const field = $row.find(`#position_funding_source_${index}_${Utils.camelToSnakeCase(dataKey)}`)
    return field.val(data[dataKey])
  })
  if (index === 0) {
    const valForFirstRow =
      State.allocationType() === Types.PERCENT ? 100 : State.totalAnnualAmount()
    return $row.find(".funding-source-amount-field").val(valForFirstRow)
  }
}

var renderFundingSourceRow = function () {
  let show
  const numRows = State.rowCount()

  const $newForm = createFundingSourceRow(numRows)

  if (numRows === 0) {
    toggleTypeButton((show = true))
  }
  if (numRows === 1) {
    toggleTotalRow((show = true))
  }
  updateFundingSourceAmounts()
  updateRowTooltip($newForm)
  attachFundingSourceRowEvents()
  if (numRows > 0) {
    return $newForm.find(".funding-source-amount-field").focus()
  }
}

var updateFundingSourceRowIndex = function (index, $row) {
  $row.attr("data-index", index)
  $row.find('[id^="position_funding_source_"]').each(function (i, el) {
    return $(this).attr(
      "id",
      $(this)
        .attr("id")
        .replace(/[-]?\d+/, index),
    )
  })
  return $row
    .find('[name^="position[funding_source_positions_attributes]"]')
    .each(function (i, el) {
      return $(this).attr(
        "name",
        $(this)
          .attr("name")
          .replace(/[-]?\d+/, index),
      )
    })
}

var updateFundingSourceRowIndexes = function () {
  Selectors.fundingSourceRows().each((index, row) => updateFundingSourceRowIndex(index, $(row)))
  const numRows = State.rowCount()
  return $(".fs-to-delete").each((index, row) =>
    updateFundingSourceRowIndex(index + numRows, $(row)),
  )
}

var updateFundingSourceAmounts = function () {
  updateFundingSourceAmountText()
  updateTotalRow()
  updateRowsTooltips()
  return updateAllocationWarningTooltip()
}

var updateAllocationWarningTooltip = function () {
  let asFloat
  if (State.rowCount() === 0) {
    return toggleAllocationWarning(0.0)
  }
  const $totalRow = Selectors.totalRow()
  if (State.allocationType() === Types.PERCENT) {
    if (State.totalAnnualAmount((asFloat = true)) === 0.0) {
      return toggleAllocationWarning(0.0)
    }
    const percent = parseFloat($totalRow.find("#funding_source_total_funding").val()) || 0.0
    return toggleAllocationWarning(100.0 - percent)
  } else {
    const budgetTotal = State.totalAnnualAmount((asFloat = true))
    const fundingTotal =
      App.Helpers.parseCurrency($totalRow.find("#funding_source_total_funding").val()) || 0.0
    return toggleAllocationWarning(budgetTotal - fundingTotal)
  }
}

var updateRowsTooltips = function () {
  if (!(State.rowCount() > 0)) {
    return
  }
  return Selectors.fundingSourceRows().each(function (i, el) {
    const $row = $(el)
    return updateRowTooltip($row)
  })
}

var updateRowTooltip = function ($row) {
  const $tooltip = $row.find(".tooltip-content")
  const data = State.tooltip($row)
  const dataKeys = Object.keys(data)
  return dataKeys.forEach(function (key) {
    const $field = $tooltip.find(`.${Utils.camelToKebabCase(key)}`)
    let val = data[key]
    if (val < 0 && key === "remainingUnallocated") {
      $field.addClass("badge--red")
    } else {
      $field.removeClass("badge--red")
    }
    if (!["name", "dataRange"].includes(key)) {
      val = App.Helpers.formatCurrency(val, { trailing: true })
    }
    return $field.text(val)
  })
}

var toggleAllocationWarning = function (budget_vs_funding_diff) {
  budget_vs_funding_diff ??= 0.0
  const $warningWrapper = $("#fund-allocation-warning-wrapper")
  $warningWrapper.toggle(budget_vs_funding_diff !== 0.0)
  $warningWrapper.find("#too_many_funds").toggle(budget_vs_funding_diff <= 0.0)
  return $warningWrapper.find("#not_enough_funds").toggle(budget_vs_funding_diff >= 0.0)
}

var updateFundingSourceAmountText = function () {
  if (!(State.rowCount() > 0)) {
    return
  }
  if (State.allocationType() !== Types.PERCENT) {
    return
  }
  const $rows = Selectors.fundingSourceRows()
  return $rows.each(function (i, el) {
    let asFloat
    const $amount_text = $(el).find('input[id^="amount_text"]')
    const percent = parseFloat($(el).find(".funding-source-amount-field").val()) / 100.0 || 0.0
    const total = State.totalAnnualAmount((asFloat = true))
    return $amount_text.val(
      App.Helpers.formatCurrency(total * percent, { omitSymbol: true, trailing: true }),
    )
  })
}

var updateTotalRow = function () {
  if (!(State.rowCount() > 0)) {
    return
  }
  const type = State.allocationType()
  let totalVal = 0
  let totalAmount = 0
  Selectors.fundingSourceRows().each(function (i, el) {
    const $el = $(el)
    const val = $el.find(".funding-source-amount-field").val() || 0.0
    if (type === Types.PERCENT) {
      totalVal += parseFloat(val) || 0.0
      return (totalAmount += App.Helpers.parseCurrency($el.find('input[id^="amount_text"]').val()))
    } else {
      return (totalVal += App.Helpers.parseCurrency(val))
    }
  })
  if (type === Types.AMOUNT) {
    totalVal = App.Helpers.formatCurrency(totalVal, { omitSymbol: true, trailing: true })
  }
  if (type === Types.PERCENT) {
    totalVal = totalVal.toFixed(2)
  }
  Selectors.totalRow().find("#funding_source_total_funding").val(totalVal)
  if (type === Types.PERCENT) {
    return Selectors.totalRow()
      .find('input[id^="amount_text"]')
      .val(App.Helpers.formatCurrency(totalAmount, { omitSymbol: true, trailing: true }))
  }
}

var toggleTypeButton = function (show) {
  show ??= true
  return $(".funding-source-type-dropdown").toggle(show)
}

var toggleTotalRow = function (show) {
  show ??= true
  return Selectors.totalRow().toggle(show)
}

var toggleFieldFormat = function (clicked_type = null) {
  const $input_add_on = $(".funding-source-position-table__amount-field .prefix")
  // On mount, there is no clicked type, so we retrive the current active link
  const active_type = clicked_type
    ? clicked_type
    : $(".funding-source-type-dropdown .dropdown-menu-link.active").data("selectorValue")
  const other_type = Object.values(Types).filter((k) => k !== active_type)[0]

  $input_add_on.each(function () {
    $(this).find(`[data-type='${active_type}']`).show()
    return $(this).find(`[data-type='${other_type}']`).hide()
  })
  Selectors.fundingSourceRows([".fs-template-row"]).each(function (index, row) {
    const $row = $(row)
    $row.find('[name^="position[funding_source_positions_attributes]"]').each(function (i, el) {
      return $(this).attr("name", $(this).attr("name").replace(other_type, active_type))
    })
    return $row.find('[id^="position_funding_source_"]').each(function (i, el) {
      return $(this).attr("id", $(this).attr("id").replace(other_type, active_type))
    })
  })
  toggleAmountText(active_type === Types.PERCENT)
  return toggleFillerCloseIcon(active_type === Types.PERCENT)
}

var setAllocationFormValues = (clicked_type) =>
  Selectors.fundingSourceRows([".fs-template-row"]).find(".allocation-type-field").val(clicked_type)

var dropdownChanged = (clicked_type) =>
  $(".amount-text-wrapper").is(":visible") === (clicked_type === Types.AMOUNT)

var clearAllFields = function () {
  Selectors.fundingSourceRows().each(function (index, row) {
    const $row = $(row)
    $row.find(".funding-source-amount-field").val("")
    return $row
      .find('input[id^="amount_text"]')
      .val(App.Helpers.formatCurrency(0, { omitSymbol: true, trailing: true }))
  })
  Selectors.totalRow().find(".funding-source-amount-field").val(0)
  return Selectors.totalRow()
    .find('input[id^="amount_text"]')
    .val(App.Helpers.formatCurrency(0, { omitSymbol: true, trailing: true }))
}

var toggleAmountText = function (show) {
  show ??= false
  $(".funding-source-position-table__row .amount-text-wrapper").toggleClass("hidden", !show)
  $(".funding-source-position-table__amount-field").toggleClass("sm:col-start-3", !show)
  return $(".funding-source-label-wrapper").toggleClass("sm:col-span-2", !show)
}

var toggleFillerCloseIcon = function (show) {
  show ??= false
  return $(".filler-close-icon").toggle(show)
}

var updateExcludedIds = function () {
  const excluded_ids = State.fundingSourceIds()
  const autocompleteData = $("#position_funding_source_autocomplete").data()
  const autocompleteParams = autocompleteData["autocompleteParams"]
  const updatedAutocompleteParams = Object.assign(autocompleteParams, {
    exclude: excluded_ids.join(","),
  })
  const updatedData = Object.assign(autocompleteData, updatedAutocompleteParams)
  return $("#position_funding_source_autocomplete").data(updatedData)
}

var validateAddFundingSourceForm = function () {
  const $input = $("#position_funding_source_autocomplete")
  const fundingSourceName = $input.data("name")
  const fundingSourceId = $input.data("funding-source-id")
  const fundingSourceIdPresent = State.fundingSourceIds().includes(fundingSourceId)
  if (fundingSourceId && !fundingSourceIdPresent && fundingSourceName === $input.val()) {
    return true
  } else {
    const $fundingSourcePositionForm = $("#funding-sources-position-form")
    $fundingSourcePositionForm.addClass("form-error")
    $("#add-funding-source-error").removeClass("hidden").addClass("flex")
    handleFundingSourcePopoverPositioning($fundingSourcePositionForm)
    return false
  }
}

var clearAddFundingSourceErrors = function () {
  $("#add-funding-source-error").removeClass("flex").addClass("hidden")
  return $("#funding-sources-position-form").removeClass("form-error")
}

var clearFundingSourceInputErrors = function ($rows) {
  $rows ??= fundingSourceRows()
  $rows.find(".form-error").removeClass("form-error")
  return $rows.find(".icon-error-wrapper").remove()
}

const addFundingSourceFormEvents = function ($addFundingSourceForm) {
  if ($addFundingSourceForm.length === 0) {
    return
  }
  const formIsVisible = () => $addFundingSourceForm.is(":visible")

  // Ensures the popover closes when clicking outside of it
  $(document).mousedown(function (e) {
    if (!formIsVisible()) {
      return
    }
    const isFundingSourceFormClicked =
      $addFundingSourceForm.is(e.target) || $addFundingSourceForm.has(e.target).length > 0

    if (!isFundingSourceFormClicked) {
      return Events.cancelAddFundingSource()()
    }
  })

  // Ensures the popover closes when tabbing out of it
  $addFundingSourceForm.on("focusout", function (e) {
    if (!formIsVisible()) {
      return
    }
    return setTimeout(function () {
      if (document.activeElement === document.body) {
        return
      }
      if ($addFundingSourceForm.has(document.activeElement).length) {
        return
      }
      return Events.cancelAddFundingSource()()
    }, 0)
  })

  $addFundingSourceForm
    .find("[data-action=cancel-add-funding-source]")
    .on("click", Events.cancelAddFundingSource())
  return $addFundingSourceForm
    .find("[data-action=add-funding-source-row]")
    .on("click", Events.addFundingSourceRow())
}

var attachFundingSourceRowEvents = function () {
  $("[data-action=remove-funding-source-row]")
    .off("click")
    .on("click", Events.removeFundingSourceRow())
  $(".fs-row .funding-source-amount-field")
    .off("keyup")
    .on("keyup", function () {
      updateFundingSourceAmounts()
      return updateRowTooltip($(this).closest("[data-funding-source-row]"))
    })
  return $(".select select, .relative .input:not([readonly])")
    .on("focus", function () {
      return $(this).parents(".select, .relative").addClass("active")
    })
    .on("blur", function () {
      return $(this).parents(".select, .relative").removeClass("active")
    })
}

const fundingSourceEvents = function (scope) {
  toggleFieldFormat()
  toggleTotalRow(State.rowCount() > 1)
  const $scope = $(scope)
  addFundingSourceFormEvents($scope.find("#funding-sources-position-form"))

  $scope.find("#add-new-funding-source").on("click", Events.addFundingSource())
  $scope
    .find("[data-action=remove-funding-source-row]")
    .on("click", Events.removeFundingSourceRow())
  $scope.find(".fs-row .funding-source-amount-field").on("keyup", function () {
    updateFundingSourceAmounts()
    return updateRowTooltip($(this).closest("[data-funding-source-row]"))
  })
  $scope
    .find(".funding-source-type-selector .dropdown-menu-link")
    .on("click", Events.toggleAllocationType())

  const $fundingSourceAutocomplete = $scope.find("#position_funding_source_autocomplete")

  $fundingSourceAutocomplete.on("autocomplete:renderSuggestions", function (e) {
    const $autocompleteList = $("#funding-sources-position-form").find(".autocomplete-list")
    if (!($autocompleteList.length > 0)) {
      return
    }

    const $autocompleteTarget = $(e.target)
    const $modalContainer = $scope.find('div[data-pjax-container="modal-content"]')
    const modalOrWindowBottom = $modalContainer.offset().top + $modalContainer.outerHeight()
    const inputBottom = $autocompleteTarget.offset().top + $autocompleteTarget.outerHeight()
    const availableMaxHeight = modalOrWindowBottom - inputBottom - 3 * Constants.SUB_FORM_MARGIN

    const currentMaxHeight = parseInt($autocompleteList.css("max-height").replace("px", ""))
    const autocompleteHeight = Math.min(availableMaxHeight, currentMaxHeight)

    return $autocompleteList.css("max-height", `${autocompleteHeight}px`)
  })

  $fundingSourceAutocomplete.on("autocomplete:selectSuggestion", function (e, selection) {
    $("#position_funding_source_autocomplete").data(selection.data())
    if (validateAddFundingSourceForm()) {
      return clearAddFundingSourceErrors()
    }
  })

  return $fundingSourceAutocomplete.on("autocomplete:inputChanged", function (e) {
    const $input = $(e.currentTarget)
    const inputValue = $input.val().trim()
    const fundingSourceName = $input.data("name")
    if (inputValue !== fundingSourceName) {
      $input.removeData("funding-source-name")
      return $input.removeData("funding-source-id")
    }
  })
}

export { fundingSourceEvents, updateFundingSourceAmounts }
