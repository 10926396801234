import { PaletteType } from "v2/redux/slices/MatrixSlice"

export type PaletteLookup = {
  [key in PaletteType]: string[]
}

// Order with 7 columns by 4 rows of dark, medium, light, very light:
//   Neutral
//   Primary/Blue
//   Purple
//   Red/Pink
//   Orange/Yellow
//   Teal/Aqua/Light Blue
//   Green
const colorWheel = [
  // dark
  "#333333",
  "#1D2C5F",
  "#510558",
  "#911D34",
  "#AE5A3B",
  "#094453",
  "#324305",

  // medium
  "#5B5B61",
  "#2358A3",
  "#703198",
  "#C85052",
  "#D9861E",
  "#428178",
  "#4A6E28",

  // light
  "#ADADB4",
  "#6FA7D7",
  "#9971D0",
  "#E78789",
  "#EFC238",
  "#8DC6BF",
  "#6DA63B",

  // very light
  "#DADAE2",
  "#B1D7EE",
  "#DBC2DC",
  "#F4C0C7",
  "#E2CF83",
  "#C8E2DC",
  "#B2D7A0",
]

const basePalettes: PaletteLookup = {
  palette_traditional: [
    colorWheel[18],
    colorWheel[20],
    colorWheel[19],
    colorWheel[11],
    colorWheel[18],
    colorWheel[20],
    colorWheel[3],
    colorWheel[11],
    colorWheel[18],
  ],
  palette_blue: [
    colorWheel[15],
    colorWheel[8],
    colorWheel[1],
    colorWheel[19],
    colorWheel[15],
    colorWheel[8],
    colorWheel[26],
    colorWheel[19],
    colorWheel[15],
  ],
  palette_red: [
    colorWheel[24],
    colorWheel[17],
    colorWheel[10],
    colorWheel[21],
    colorWheel[24],
    colorWheel[17],
    colorWheel[14],
    colorWheel[21],
    colorWheel[24],
  ],
  palette_custom: [],
}

export { colorWheel, basePalettes }
