/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/no-noninteractive-tabindex, jsx-a11y/anchor-has-content, react/jsx-props-no-spreading */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import dayjs from "dayjs"
import React from "react"
import { UseFieldArrayRemove } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"
import { useBoolean } from "usehooks-ts"

import { FundingSource, FundingSourcePosition } from "types/graphql"
import { FundingSourceAllocation } from "types/graphql.enums"
import {
  useBudgetedTotal,
  useFundingSourcePositionValues,
  useFundingSourcePositionValuesResetEffect,
  useFundingSourceState,
  usePositionController,
  usePositionFieldArray,
  usePositionWatchField,
} from "v2/react/components/positions/positionForm/hooks"
import { usePositionFormContext } from "v2/react/components/positions/positionForm/PositionFormProvider"
import { Subform } from "v2/react/components/positions/positionForm/Subform"
import { EntityWithIndex } from "v2/react/components/positions/positionForm/types"
import { Dropdown } from "v2/react/shared/collection/menus/Dropdown/Dropdown"
import {
  CurrencyInput,
  CurrencyInputWithoutWrapper,
  RegisteredPercentInputWithoutWrapper,
} from "v2/react/shared/forms"
import { InputErrorText } from "v2/react/shared/forms/InputErrorText"
import { SearchableDropdown } from "v2/react/shared/forms/SearchableDropdown"
import { Popover, PopoverContent, PopoverTrigger } from "v2/react/shared/overlay/Popover"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"
import { formatCurrency } from "v2/react/utils/currency"
import { safeNumber } from "v2/react/utils/safeNumber"

/** Based on app/views/v2/positions/form/funding_sources/_form_funding_source.html.slim */
function FormFundingSource() {
  const { t } = useTranslation()
  const {
    setFalse: hideAddFundingSourcePopover,
    setTrue: showAddFundingSourcePopover,
    setValue: setIsAddFundingSourcePopoverShown,
    value: isAddFundingSourcePopoverShown,
  } = useBoolean()

  const canManageFundingSourcePositions = true
  const {
    append,
    remove,
    fields: fundingSourcePositions,
  } = usePositionFieldArray({
    keyName: "formId", // Ensures react-hook-form doesn't overwrite actual id
    name: "position.fundingSourcePositionsAttributes",
  })
  const budgetedTotal = useBudgetedTotal()
  const { allocationType, budgetedVsFundedDiff, countNotMarkedForDestroy, unusedFundingSources } =
    useFundingSourceState()

  // This resets amounts/percents when the allocation type changes.
  useFundingSourcePositionValuesResetEffect()

  return (
    <div className="funding-source-position-table rounded-md-sm border border-solid border-neutral-8 bg-transparent">
      <div className="funding-source-position-table__row--header items-center p-4 flex">
        <div className="items-center gap-2 flex">
          {t("v2.simple_form.labels.position.funding")}

          {countNotMarkedForDestroy > 0 && budgetedVsFundedDiff < 0 && (
            <FundingSourcesTotalTooltip>
              <Trans
                components={{ strong: <strong /> }}
                i18nKey="v2.positions.form.too_many_funds_html"
              />
            </FundingSourcesTotalTooltip>
          )}

          {countNotMarkedForDestroy > 0 && budgetedVsFundedDiff > 0 && (
            <FundingSourcesTotalTooltip>
              <Trans
                components={{ strong: <strong /> }}
                i18nKey="v2.positions.form.not_enough_funds_html"
              />
            </FundingSourcesTotalTooltip>
          )}
        </div>
        <div className="ml-auto items-center gap-2 flex">
          {countNotMarkedForDestroy > 0 && <FundingSourceAllocationTypeDropdown />}
          {canManageFundingSourcePositions && (
            <Popover
              open={isAddFundingSourcePopoverShown}
              placement="top-end"
              onOpenChange={setIsAddFundingSourcePopoverShown}
            >
              <PopoverTrigger asChild>
                <button
                  className="btn--sm btn--secondary"
                  type="button"
                  onPointerUp={showAddFundingSourcePopover}
                >
                  <FontAwesomeIcon icon={["far", "plus"]} />
                  {t("v2.defaults.add")}
                </button>
              </PopoverTrigger>
              {isAddFundingSourcePopoverShown && (
                <AddFundingSourcePopoverContent
                  onCancel={hideAddFundingSourcePopover}
                  onSelect={(fs) => {
                    const entryAttributes = {
                      fundingSourceId: fs.id,
                      allocationType,
                      fundingSourcePosition: { id: "", fundingSource: fs },
                    }
                    const newIndex = fundingSourcePositions.length
                    const focusName =
                      allocationType === FundingSourceAllocation.PercentOfBudget
                        ? `position.fundingSourcePositionsAttributes.${newIndex}.percentOfBudget`
                        : `position.fundingSourcePositionsAttributes.${newIndex}.amount`

                    append(entryAttributes, { focusName, shouldFocus: true })
                    hideAddFundingSourcePopover()
                  }}
                  unusedFundingSources={unusedFundingSources}
                />
              )}
            </Popover>
          )}
        </div>
      </div>
      {countNotMarkedForDestroy > 0 && (
        <div className="funding-source-rows flex-col gap-4 border-0 border-t border-solid border-neutral-8 p-4 flex">
          {fundingSourcePositions?.map(({ formId, fundingSourcePosition: fsp }, index) => (
            <FundingSourcePositionForm
              entity={fsp ?? null}
              index={index}
              key={formId}
              budgetedTotal={budgetedTotal}
              onRemoveUnpersisted={remove}
            />
          ))}
        </div>
      )}

      {countNotMarkedForDestroy > 1 && <FundingSourcesTotalRow />}
    </div>
  )
}

function AddFundingSourcePopoverContent<UnusedFundingSource extends { id: string; label: string }>({
  unusedFundingSources,
  onCancel,
  onSelect,
}: {
  unusedFundingSources: UnusedFundingSource[]
  onCancel: () => void
  onSelect: (item: UnusedFundingSource) => void
}) {
  const { t } = useTranslation()
  const { setTrue: showError, value: isErrorShown } = useBoolean()

  // `onSelect` is expected to append the selected entry to the outer form, and
  // thus, submit is only ever fired with invalid input. Show the field error
  // on submit.
  const handleSubmit: React.FormEventHandler = (ev) => {
    ev.preventDefault()
    ev.stopPropagation()
    showError()
  }

  return (
    <PopoverContent focusManagerDisabled className="z-50">
      <Subform onSubmit={handleSubmit}>
        <div className={cn("input-group font-medium", isErrorShown && "form-error")}>
          <label htmlFor="position_fundingSourcePositionsAttributes_autocomplete">
            {t("v2.simple_form.labels.position.add_funding_source")}
          </label>
          <SearchableDropdown
            id="position_fundingSourcePositionsAttributes_autocomplete"
            items={unusedFundingSources}
            makeLabel={FundingSourceOption.renderer}
            onSelect={onSelect}
            menuClassName="!z-[51]"
          />
          {isErrorShown && (
            <InputErrorText message={t("v2.funding_sources.errors.autocomplete_error")} />
          )}
        </div>
        <div className="items-center justify-end gap-2 flex">
          <button className="btn btn--secondary" onPointerUp={onCancel} type="button">
            {t("v2.defaults.cancel")}
          </button>
          <button className="btn btn--primary" type="submit">
            {t("v2.defaults.add")}
          </button>
        </div>
      </Subform>
    </PopoverContent>
  )
}

const FundingSourceAllocationTypeDropdown = () => {
  const { t } = useTranslation()
  const {
    field: { onChange: changeAllocationType, value: allocationType },
  } = usePositionController({ name: "position.fundingSourceAllocationType" })

  return (
    <div className="input-with-dropdown-selector funding-source-type-dropdown">
      <div className="input-group dropdown-with-image !m-0">
        <Dropdown align="right">
          <Dropdown.Trigger triggerClassName="w-full p-0 bg-transparent">
            <div className="dropdown-link dropdown-link--sm gap-3 flex" tabIndex={0}>
              <div className="selection-icon">
                <div className="text">
                  {t(`v2.positions.form.funding_source_${allocationType}`)}
                </div>
              </div>
              <FontAwesomeIcon icon={["fas", "caret-down"]} />
            </div>
          </Dropdown.Trigger>

          <Dropdown.Menu className="dropdown-menu dropdown-menu-right w-[18.875rem]">
            <FundingSourceAllocationOption
              allocationType={FundingSourceAllocation.Amount}
              helpText={t("v2.positions.form.funding_source_amount_help_text")}
              isSelected={allocationType === FundingSourceAllocation.Amount}
              label={t("v2.positions.form.funding_source_amount")}
              onSelect={changeAllocationType}
            />
            <FundingSourceAllocationOption
              allocationType={FundingSourceAllocation.PercentOfBudget}
              helpText={t("v2.positions.form.funding_source_percent_help_text")}
              isSelected={allocationType === FundingSourceAllocation.PercentOfBudget}
              label={t("v2.positions.form.funding_source_percent_of_budget")}
              onSelect={changeAllocationType}
            />
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  )
}

type FundingSourceAllocationOptionProps = {
  allocationType: FundingSourceAllocation
  helpText?: string
  label?: string
  isSelected?: boolean
  onSelect: (allocationType: FundingSourceAllocation) => void
}

function FundingSourceAllocationOption({
  allocationType,
  helpText,
  isSelected,
  label,
  onSelect,
}: FundingSourceAllocationOptionProps) {
  const handleClick = () => onSelect(allocationType)

  return (
    <Dropdown.Item onClick={handleClick}>
      <div className="selection-icon items-center justify-between flex">
        <div className="text px-2">
          <div className="font-bold">{label}</div>
          <div className="help-text !text-base text-[0.625rem]/[0.8rem] text-neutral-64">
            {helpText}
          </div>
        </div>
        {isSelected && (
          <FontAwesomeIcon icon={["fas", "check-circle"]} className="selected-indicator" />
        )}
      </div>
    </Dropdown.Item>
  )
}

const FundingSourcesTotalTooltip = ({ children }: React.PropsWithChildren) => (
  <Tooltip>
    <TooltipTrigger>
      <FontAwesomeIcon icon={["fad", "info-circle"]} className="icon-14 icon--caution" />
    </TooltipTrigger>
    <TooltipContent className="react-tooltip-content w-[15.125rem] max-w-[initial]">
      {children}
    </TooltipContent>
  </Tooltip>
)

function FundingSourcesTotalRow() {
  const { t } = useTranslation()
  const { allocationType, totalAmount, totalPercent } = useFundingSourceState()

  return (
    <div
      className={cn(
        "funding-source-position-table__row gap-4 border-0 border-t border-solid border-neutral-8 bg-neutral-3 p-4 grid sm:grid-cols-[92fr_60fr_92fr] sm:gap-0",
      )}
    >
      <div className="items-center flex">
        <div className="total-label font-bold">
          {t("v2.positions.form.funding_source_total_funding")}
        </div>
      </div>

      {allocationType === FundingSourceAllocation.PercentOfBudget && (
        <div className="readonly relative flex-1 sm:ml-4">
          <div className="prefix">
            <FontAwesomeIcon icon={["far", "percent"]} />
          </div>
          <input
            type="number"
            className="input prefix-pad readonly"
            readOnly
            value={new Intl.NumberFormat(window.gon.current_user_locale, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(totalPercent)}
          />
        </div>
      )}

      <div
        className={cn(
          "funding-source-position-table__amount-field sm:ml-4",
          allocationType === FundingSourceAllocation.Amount && "sm:col-start-3",
        )}
      >
        <CurrencyInput
          readOnly
          id="amount_text_total"
          name="amount_text[total]"
          value={formatCurrency({ value: totalAmount, omitSymbol: true, trailing: true })}
          wrapperClassName={cn("relative readonly")}
        />
      </div>
    </div>
  )
}

const FundingSourceOption = ({ fundingSource }: { fundingSource: FundingSource }) => {
  const { t } = useTranslation()

  return (
    <div className="list-group-item-ellipsis">
      <div className="list-group-item-title funding-source-name mb-1 text-neutral-100">
        {fundingSource.name}
      </div>
      <div className="list-group-item-content funding-source-info font-medium text-neutral-64">
        <div className="items-center gap-2 flex">
          <div>
            {/* TODO: I18n? */}
            {dayjs(fundingSource.startDate).format("MMM D, YYYY")}
            &mdash;
            {dayjs(fundingSource.endDate).format("MMM D, YYYY")}
          </div>
          <div>|</div>
          <div
            className={cn(
              "h-4 !border-0 text-sm",
              (fundingSource.remainingUnallocatedOnChart ?? 0) < 0 ? "badge--red" : "badge--grey",
            )}
          >
            {t("v2.funding_sources.search.amount_available", {
              amount: formatCurrency({ value: fundingSource.remainingUnallocatedOnChart ?? 0 }),
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

FundingSourceOption.renderer = (fundingSource: FundingSource) => (
  <FundingSourceOption fundingSource={fundingSource} />
)

type FundingSourcePositionFormProps = EntityWithIndex<FundingSourcePosition | null> & {
  budgetedTotal: number
  onRemoveUnpersisted: UseFieldArrayRemove
}

function FundingSourcePositionForm({
  budgetedTotal,
  index,
  entity: fundingSourcePosition,
  onRemoveUnpersisted,
}: FundingSourcePositionFormProps) {
  const {
    formState: { errors },
  } = usePositionFormContext()
  const allocationType = usePositionWatchField({ name: "position.fundingSourceAllocationType" })
  const { field: destroyField } = usePositionController({
    name: `position.fundingSourcePositionsAttributes.${index}._destroy`,
  })
  const {
    id,
    _destroy: doDestroy,
    percentOfBudget: percentValue,
  } = usePositionWatchField({ name: `position.fundingSourcePositionsAttributes.${index}` })

  if (doDestroy) return null

  const handleRemoveClick: React.PointerEventHandler = () => {
    if (id) destroyField.onChange("true")
    else onRemoveUnpersisted(index)
  }

  const baseErrors = errors?.position?.fundingSourcePositionsAttributes?.[index]
  const allocatedAmountError =
    allocationType === FundingSourceAllocation.PercentOfBudget
      ? baseErrors?.percentOfBudget
      : baseErrors?.amount

  return (
    <div className="items-start gap-4 grid sm:grid-cols-[92fr_60fr_81fr_11fr] sm:gap-0">
      <div
        className={cn(
          "funding-source-label-wrapper min-w-0 items-center gap-2 leading-10 flex",
          allocationType === "amount" && "sm:col-span-2",
        )}
      >
        <div className="funding-source-name truncate">
          {fundingSourcePosition?.fundingSource?.name}
        </div>

        <FormFundingSourcePositionTooltip index={index} />
      </div>
      {allocationType === FundingSourceAllocation.PercentOfBudget && (
        <>
          <div
            className={cn(
              "funding-source-position-table__amount-field input-group mb-0 sm:ml-4",
              allocatedAmountError && "form-error",
            )}
          >
            <RegisteredPercentInputWithoutWrapper
              formatDefaultValue
              id={`position_funding_source_attributes_${index}_percent_of_budget`}
              name={`position.fundingSourcePositionsAttributes.${index}.percentOfBudget`}
              useSuffix={false}
            />
            {allocatedAmountError?.message && (
              <InputErrorText message={allocatedAmountError.message} />
            )}
          </div>
          <CurrencyInputWithoutWrapper
            id={`amount_text_${index}`}
            name={`amount_text[${index}]`}
            inputIconWrapperClassName={cn(
              "amount-text-wrapper sm:ml-4 relative readonly !w-[initial]",
            )}
            readOnly
            value={formatCurrency({
              value: (budgetedTotal * safeNumber(percentValue)) / 100,
              omitSymbol: true,
              trailing: true,
            })}
          />
        </>
      )}
      {allocationType === FundingSourceAllocation.Amount && (
        <CurrencyInput
          errors={allocatedAmountError?.message}
          formatDefaultValue
          id={`position_fundingSourcePositionsAttributes_${index}_amount`}
          name={`position.fundingSourcePositionsAttributes.${index}.amount`}
          showErrorMessage
          wrapperClassName="funding-source-position-table__amount-field mb-0 sm:ml-4 sm:col-start-3 w-[initial]"
          useInReactHookForm
        />
      )}
      <a className="ml-3 leading-10 sm:text-right" onPointerUp={handleRemoveClick}>
        <FontAwesomeIcon icon={["far", "times"]} className="text-neutral-80" />
      </a>
    </div>
  )
}

function FormFundingSourcePositionTooltip({ index }: { index: number }) {
  const {
    allocatedToThisActualOrCalculated,
    allocatedToOtherPositions,
    fundingSourceTotal,
    remainingUnallocated,
  } = useFundingSourcePositionValues({ index })
  const { fundingSourcePosition: fsp } = usePositionWatchField({
    name: `position.fundingSourcePositionsAttributes.${index}`,
  })
  const { t } = useTranslation()
  const fundingSource = fsp?.fundingSource

  return (
    <Tooltip placement="top-start">
      <TooltipTrigger>
        <FontAwesomeIcon icon={["far", "info-circle"]} className="icon-14" />
      </TooltipTrigger>
      <TooltipContent className="tooltip-content tooltip-content--light visible z-[100] !mb-0 w-[18.375rem] p-0 opacity-100">
        <div className="w-full flex-col whitespace-normal text-sm flex">
          <div className="border-0 border-b border-solid border-neutral-8 p-3">
            <div className="name font-bold">{fundingSource?.name}</div>
            <div className="date-range text-sm text-neutral-64">
              {fundingSource && (
                <>
                  {dayjs(fundingSource.startDate).format("MMM D, YYYY")}
                  &mdash;
                  {dayjs(fundingSource.endDate).format("MMM D, YYYY")}
                </>
              )}
            </div>
          </div>
          <div className="flex-col border-0 border-b border-solid border-neutral-8 p-3 flex">
            <div className="h-6 w-full flex">
              <div className="text-neutral-64">
                {t("v2.simple_form.labels.position.funding_source_tooltip.total_amount")}
              </div>
              <div className="funding-source-amount ml-auto">
                {formatCurrency({ value: fundingSourceTotal, trailing: true })}
              </div>
            </div>
            <div className="h-6 w-full flex">
              <div className="text-neutral-64">
                {t(
                  "v2.simple_form.labels.position.funding_source_tooltip.allocated_to_other_positions",
                )}
              </div>
              <div className="ml-auto flex">
                <div className="amount-allocated-to-other-positions ml-2 flex-1 truncate text-ellipsis">
                  {formatCurrency({ value: allocatedToOtherPositions, trailing: true })}
                </div>
              </div>
            </div>
            <div className="w-full flex">
              <div className="text-neutral-64">
                {t(
                  "v2.simple_form.labels.position.funding_source_tooltip.allocated_to_this_position",
                )}
              </div>
              <div className="allocated-to-this-position ml-2 flex-1 truncate text-right">
                {formatCurrency({ value: allocatedToThisActualOrCalculated, trailing: true })}
              </div>
            </div>
          </div>
          <div className="remaining w-full items-center p-3 text-sm flex">
            <div className="font-bold">
              {t("v2.simple_form.labels.position.funding_source_tooltip.remaining_unallocated")}
            </div>
            <div className="ml-2 flex-1 justify-end truncate">
              <div
                className={cn(
                  "remaining-unallocated ml-auto max-w-full !border-0",
                  `badge--${remainingUnallocated < 0 ? "red" : "gray"}`,
                )}
              >
                <span className="truncate text-ellipsis">
                  {formatCurrency({ value: remainingUnallocated, trailing: true })}
                </span>
              </div>
            </div>
          </div>
        </div>
      </TooltipContent>
    </Tooltip>
  )
}

export { FormFundingSource, FundingSourcesTotalRow, FundingSourcePositionForm }
