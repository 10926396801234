import { Approver, HeadcountPlanMember, Person } from "types/graphql.d"

export interface WithCreatedAtType {
  createdAt: Date
}

const approverSortOrderAsc = (a: Approver, b: Approver): number =>
  (a.sortOrder || 0) - (b.sortOrder || 0)

/**
 * Sorts any argument by `createdAt` property
 */
const createdAtDesc = <WithCreatedAt extends WithCreatedAtType>(
  a: WithCreatedAt,
  b: WithCreatedAt,
): number => {
  if (a.createdAt < b.createdAt) {
    return 1
  }
  if (a.createdAt > b.createdAt) {
    return -1
  }
  return 0
}

const headcountPlanMemberNameAsc = (a: HeadcountPlanMember, b: HeadcountPlanMember): number =>
  `${a.person.name}`.localeCompare(`${b.person.name}`)

const personNameAsc = (a: Person, b: Person): number => `${a.name}`.localeCompare(`${b.name}`)
const personSortOrderAsc = (a: Person, b: Person): number => (a.sortOrder || 0) - (b.sortOrder || 0)

export {
  approverSortOrderAsc,
  createdAtDesc,
  headcountPlanMemberNameAsc,
  personNameAsc,
  personSortOrderAsc,
}
