import React from "react"
import { useTranslation } from "react-i18next"

import { ProfilePanelMode } from "types/graphql.enums"
import { ModuleListItem } from "v2/react/components/orgChart/ProfilePanel/ModuleListItem"
import { useSelectedBox } from "v2/react/components/orgChart/ProfilePanel/Settings/hooks/useSelectedBox"
import { AccordionModulePanel } from "v2/react/shared/layout/AccordionModulePanel"
import { useAppSelector } from "v2/redux/store"

function ADP() {
  const { currentBox, toggleBox } = useSelectedBox("adp", ProfilePanelMode.Position)
  const position = useAppSelector((state) => state.profilePanel.position)
  const externalIdentifier = position?.externalIdentifier
  const { t } = useTranslation()

  if (!currentBox) return null

  const headerContent = <div className="title">{t("v2.profile_panel.adp")}</div>

  return (
    <AccordionModulePanel
      headerContent={headerContent}
      additionalToggleAction={toggleBox}
      collapsed={currentBox.collapsed}
      title={currentBox.name}
    >
      <ModuleListItem>
        <div>{t("v2.profile_panel.adp_position_id")}</div>
        <div>{externalIdentifier}</div>
      </ModuleListItem>
    </AccordionModulePanel>
  )
}

export { ADP }
