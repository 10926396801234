import { EntityId } from "@reduxjs/toolkit"

const UrlHelper = {
  careerLadderDetailsPath: (careerLadderId?: string | number | null) =>
    `/career_ladders/${careerLadderId || ""}`,
  careerLaddersRootPath: () => "/career_ladders",
  chartDuplicatePath: (chartId: string | number) => `/charts/${chartId}/duplicate`,
  chartMergePath: (chartId: string | number) => `/charts/${chartId}/merge_select`,
  chartSectionDeletePath: (id: EntityId) => `/chart_sections/${id}/delete`,
  chartSectionEditPath: (id: EntityId) => `/chart_sections/${id}/edit`,
  chartSectionNewPath: () => `/chart_sections/new`,
  editConnectionPath: (reqId: string) => `/job_requisitions/${reqId}/connections`,
  editPositionPath: (positionId: string, urlParams?: string) =>
    `/positions/${positionId}/edit${urlParams || ""}`,
  editPositionTypePath: (positionTypeId: string, urlParams?: string) =>
    `/position_types/${positionTypeId}/edit${urlParams || ""}`,
  editSuccessionPlanPath: (positionId: string) =>
    `/orgchart/positions/${positionId}/succession_plan`,
  headcountPlanPath: (headcountPlanId: string | number) => `/headcount_plans/${headcountPlanId}`,
  headcountPlanInvitationsPath: (headcountPlanId: string | number, urlParams?: string) =>
    `/headcount_plans/${headcountPlanId}/invitations${urlParams || ""}`,
  headcountPlanOverviewPath: (headcountPlanId: string | number) =>
    `/headcount_plans/${headcountPlanId}/overview`,
  headcountPlanOwnerDatasheetPath: (headcountPlanId: string | number) =>
    `/headcount_plans/${headcountPlanId}/owner`,
  headcountPlanOwnerOrgChartPath: (headcountPlanId: string | number) =>
    `/headcount_plans/${headcountPlanId}/owner/org_chart`,
  headcountPlanOwnerTimelinePath: (headcountPlanId: string | number) =>
    `/headcount_plans/${headcountPlanId}/owner/timeline`,
  headcountPlanParticipantDatasheetPath: (
    headcountPlanId: string | number,
    participantId: string | number,
  ) => `/headcount_plans/${headcountPlanId}/participants/${participantId}`,
  headcountPlanParticipantOrgChartPath: (
    headcountPlanId: string | number,
    participantId: string | number,
  ) => `/headcount_plans/${headcountPlanId}/participants/${participantId}/org_chart`,
  headcountPlanParticipantTimelinePath: (
    headcountPlanId: string | number,
    participantId: string | number,
  ) => `/headcount_plans/${headcountPlanId}/participants/${participantId}/timeline`,
  headcountPlansPath: () => `/headcount_plans`,
  historyLogPath: ({
    containerType,
    containerId,
  }: {
    containerType: "chart" | "list"
    containerId: string | number
  }) => `/${containerType}s/${containerId}/history_log`,
  jobArchitecturePath: () => `/job_architecture`,
  jobFamilyPath: (jobFamilyId: string) => `/job_families/${jobFamilyId}`,
  jobFamilyRootPath: () => "/job_families",
  jobFamilyWithSubFamilyPath: (jobFamilyId: string, parentId: string) =>
    `/job_families/${parentId}/subfamily/${jobFamilyId}`,
  jobLevelsRootPath: () => "/job_levels",
  newPositionChartPath: (chartId: string | number) =>
    `/positions/new?position[chart_id]=${chartId}`,
  newPositionPath: (positionTypeId?: string) =>
    positionTypeId ? `/positions/new?position_type_id=${positionTypeId}` : "/positions/new",
  opportunitiesRootPath: () => "/opportunities",
  opportunityDetailsPath: (positionTypeId: string | number) => `/opportunities/${positionTypeId}`,
  payGradesRootPath: () => "/pay_grades",
  personProfilePath: (personId: string) => `/people/${personId}`,
  positionHistoryLogPath: ({
    containerType,
    containerId,
    positionId,
  }: {
    containerType: "chart" | "list"
    containerId: string
    positionId: string
  }) => `/${containerType}s/${containerId}/history_log?position_id=${positionId}`,
  positionOnChartPath: (positionId: string) => `/orgchart/positions/${positionId}`,
  positionReportPath: (reportId: string) => `/reports/positions/${reportId}`,
  positionShowPath: (positionId: string) => `/positions/${positionId}`,
  positionTypePath: (positionTypeId: string) => `/position_types/${positionTypeId}/show`,
  positionTypeRootPath: () => "/position_types",
  positionsPath: () => `/positions`,
  positionSettingsPath: () => `/positions/settings`,
  reportsPath: () => "/reports",
  successionPlanPanelCompareSettingsPath: (positionId: string) =>
    `/succession_plan_panel/${positionId}/compare_settings`,
  successionPlanPanelMatrixPath: (positionId: string) =>
    `/succession_plan_panel/${positionId}/matrix`,
  successionPlanPanelPath: (positionId: string, tabName?: string) =>
    `/succession_plan_panel/${positionId}/${tabName || "summary"}`,
  unassignedJobFamilyPath: () => "/job_families/unassigned",
}

const setQueryString = (
  params: Record<string, string[] | string | number | boolean | null> | URLSearchParams,
) => {
  const searchParams = new URLSearchParams(window.location.search)

  if (params instanceof URLSearchParams) {
    params.forEach((value, key) => {
      searchParams.set(key, value)
    })
  } else {
    Object.keys(params).forEach((key) => {
      const value = params[key]

      if (Array.isArray(value)) {
        const arrayKey = `${key}[]`
        searchParams.delete(arrayKey)
        value.forEach((item) => {
          searchParams.append(arrayKey, item)
        })
      } else if (value) {
        searchParams.set(key, value.toString())
      } else {
        searchParams.delete(key)
      }
    })
  }

  const queryString = searchParams.toString()
  const newUrl = `${window.location.pathname}${queryString ? `?${queryString}` : ""}`

  window.history.replaceState({}, "", newUrl)
}

const getQueryParams = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const queryString: Record<string, string[]> = {}

  searchParams.forEach((value, key) => {
    if (key.includes("[]")) {
      const newKey = key.replace("[]", "")
      if (queryString[newKey]) {
        queryString[newKey].push(value)
      } else {
        queryString[newKey] = [value]
      }
    } else {
      queryString[key] = [value]
    }
  })

  return queryString
}

const mergeCurrentSearchParamsWithUrl = (urlString: string) => {
  const baseUrl = window.location.origin
  const searchParams = new URLSearchParams(window.location.search)
  const url = new URL(urlString, baseUrl)

  searchParams.forEach((value, key) => {
    url.searchParams.set(key, value)
  })

  return url.pathname + url.search
}

export { UrlHelper, setQueryString, getQueryParams, mergeCurrentSearchParamsWithUrl }
