import { t } from "i18next"
import React, { FormEvent } from "react"

import { CareerLadder, CareerLaddersDestroyInput } from "types/graphql"
import { Modal } from "v2/react/shared/overlay/Modal"
import { SaveFooter } from "v2/react/shared/overlay/Modal/SaveFooter"
import { useCareerLaddersDestroyMutation } from "v2/redux/GraphqlApi/CareerLaddersApi"

interface Props {
  careerLadder: CareerLadder
  isOpen: boolean
  onClose: () => void
  title: string
}

const DeleteCareerLadderForm = ({ careerLadder, isOpen, onClose, title }: Props) => {
  const [mutate, { isLoading }] = useCareerLaddersDestroyMutation()

  const onSubmit = async (event: FormEvent<Element>) => {
    event.preventDefault()

    const input: CareerLaddersDestroyInput = {
      careerLadderId: careerLadder.uniqueKey,
    } as CareerLaddersDestroyInput

    await mutate(input).unwrap()

    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="md">
      <form onSubmit={onSubmit}>
        <div className="react-modal__body flex-col gap-2 flex">
          {t("v2.career_ladders.index.confirm_delete")}
        </div>
        <SaveFooter
          isSaving={isLoading}
          onCancel={onClose}
          saveType="critical"
          enabledText={t("v2.defaults.delete")}
        />
      </form>
    </Modal>
  )
}

export { DeleteCareerLadderForm }
