import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"

interface PhoneIconProps {
  phone: string
  phoneTooltip: string
}

function PhoneIcon({ phone, phoneTooltip }: PhoneIconProps) {
  const [showTooltip, setShowTooltip] = useState(false)
  const { t } = useTranslation()

  return (
    <Tooltip open={showTooltip}>
      <TooltipTrigger
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <a href={`tel:${phone}`} className="text-neutral-80 inline-block">
          <FontAwesomeIcon icon={["fas", "phone"]} size="1x" />
        </a>
      </TooltipTrigger>
      <TooltipContent className="react-tooltip-content--sm">
        {t("v2.profile_panel.click_to_call", { phone: phoneTooltip })}
      </TooltipContent>
    </Tooltip>
  )
}

export { PhoneIcon }
