/* eslint-disable react/jsx-props-no-spreading */
import { debounce } from "lodash"
import React, { useMemo } from "react"

import { withSSRProvider } from "v2/react/components/RootProvider"
import { LinkModule, LinkModuleProps } from "v2/react/shared/layout/LinkModule"
import { useToggleReportLinksMutation } from "v2/redux/GraphqlApi/PeopleApi"

// See: app/graphql/mutations/person_settings/toggle_report_links.rb
type ReportCategory = "position_management" | "people" | "pto" | "timesheets"
type ReportLinks = Omit<LinkModuleProps, "onToggle"> | null

// See: app/helpers/reports_helper.rb
interface OverviewProps {
  positionManagementReportLinks: ReportLinks
  peopleReportLinks: ReportLinks
  ptoReportLinks: ReportLinks
  timesheetReportLinks: ReportLinks
}

const Overview = ({
  positionManagementReportLinks,
  peopleReportLinks,
  ptoReportLinks,
  timesheetReportLinks,
}: OverviewProps) => {
  const [toggleReportLinks] = useToggleReportLinksMutation()

  const toggleFns = useMemo(() => {
    // Debouncing here helps to prevent the server state from getting out of
    // sync with the client state when rapidly clicking toggles.
    const createDebouncedToggle = (reportCategory: ReportCategory) =>
      debounce(
        (collapsed?: boolean) => toggleReportLinks({ reportCategory, expanded: !collapsed }),
        300,
      )

    return {
      people: createDebouncedToggle("people"),
      position_management: createDebouncedToggle("position_management"),
      pto: createDebouncedToggle("pto"),
      timesheets: createDebouncedToggle("timesheets"),
    }
  }, [toggleReportLinks])

  return (
    <div className="page-pad flex-col items-center gap-4 flex sm:gap-8">
      {peopleReportLinks && <LinkModule {...peopleReportLinks} onToggle={toggleFns.people} />}
      {positionManagementReportLinks && (
        <LinkModule {...positionManagementReportLinks} onToggle={toggleFns.position_management} />
      )}
      {ptoReportLinks && <LinkModule {...ptoReportLinks} onToggle={toggleFns.pto} />}
      {timesheetReportLinks && (
        <LinkModule {...timesheetReportLinks} onToggle={toggleFns.timesheets} />
      )}
    </div>
  )
}

const OverviewWithSSR = withSSRProvider(Overview, "Overview")

export default OverviewWithSSR
