import React from "react"
import { useTranslation } from "react-i18next"

import { Position } from "types/graphql"
import { EmptyContent, EmptyState } from "v2/react/components/positionTypes/Show/EmptyState"
import { SmallEditButton } from "v2/react/components/positionTypes/Show/SmallEditButton"
import { pjaxModalFor } from "v2/react/utils/pjax"
import { sanitizeParse } from "v2/react/utils/sanitizeParse"
import { UrlHelper } from "v2/react/utils/urls"

interface Props {
  canEdit: boolean
  position: Position
}

const Description = ({ canEdit, position }: Props) => {
  const { t } = useTranslation()

  const handleEdit = () => {
    pjaxModalFor(
      UrlHelper.editPositionPath(
        position.id,
        "?selected_field=description&selected_tab=job_description",
      ),
    )
  }

  const emptyDescription: EmptyContent = {
    buttonText: t("v2.positions.show.add_job_description_cta"),
    note: t("v2.positions.show.add_job_description_note"),
    icon: "edit",
    onClick: handleEdit,
  }

  return (
    <div className="module-card mb-0">
      <div className="module-card__header">
        <div className="module-title">{t("v2.positions.show.job_description")}</div>
        {position.description?.length ? (
          <SmallEditButton
            disabled={!canEdit}
            disabledTooltipText={t("v2.positions.show.edit_disabled")}
            onClick={handleEdit}
          />
        ) : null}
      </div>
      <div className="module-card__body">
        {position.description?.length ? (
          <div>{sanitizeParse(position.description)}</div>
        ) : (
          <EmptyState
            buttonText={emptyDescription.buttonText}
            note={emptyDescription.note}
            icon={emptyDescription.icon}
            onClick={emptyDescription.onClick}
          />
        )}
      </div>
    </div>
  )
}

export { Description }
