import dayjs from "dayjs"

const totalTenureInYears = (hireDate?: string, createdAt?: string): number => {
  if (!hireDate || !createdAt) return 0
  const current = dayjs()
  const days: number = hireDate ? current.diff(hireDate, "days") : current.diff(createdAt, "days")
  const tenureInYears = days / 365
  return Math.ceil(tenureInYears * 100) / 100
}

export { totalTenureInYears }
