import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useTranslation } from "react-i18next"

import { Option } from "types/graphql"
import { EmptyState } from "v2/react/components/opportunities/Show/EmptyState"
import { TagListDisplay } from "v2/react/components/positionTypes/Show/TagListDisplay"
import { sanitizeParse } from "v2/react/utils/sanitizeParse"

interface RequirementsProps {
  skillTags: Option[]
  characteristicsTags: Option[]
}

const Requirements = ({ skillTags, characteristicsTags }: RequirementsProps) => {
  const { t } = useTranslation()
  const skills = t("v2.opportunities.show.skills")
  const characteristics = t("v2.opportunities.show.worker_characteristics")

  return (
    <div className="module-card">
      <div className="module-card__header">
        <div className="module-title">{t("v2.opportunities.show.requirements")}</div>
      </div>
      <div className="module-card__body">
        <div className="items-center gap-2 flex">
          <FontAwesomeIcon icon={["far", "pencil-ruler"]} />
          {skills}
        </div>
        {skillTags.length ? (
          <TagListDisplay tagList={skillTags} />
        ) : (
          <EmptyState
            note={sanitizeParse(
              t("v2.opportunities.show.empty_module_html", {
                count: 2,
                property: skills.toLowerCase(),
              }),
            )}
            icon="far pencil-ruler"
          />
        )}

        <hr className="mx-[-1rem] my-0" />

        <div className="items-center gap-2 flex">
          <FontAwesomeIcon icon={["far", "heart"]} />
          {characteristics}
        </div>
        {characteristicsTags.length ? (
          <TagListDisplay tagList={characteristicsTags} />
        ) : (
          <EmptyState
            note={sanitizeParse(
              t("v2.opportunities.show.empty_module_html", {
                count: 2,
                property: t("v2.opportunities.show.characteristics"),
              }),
            )}
            icon="far heart"
          />
        )}
      </div>
    </div>
  )
}

export { Requirements }
