import { CardType } from "v2/redux/slices/MatrixSlice"

const generateConditionalBorder = (style: CardType, color: string, borderSize: "sm" | "lg") => ({
  borderLeft:
    style === "color_edge" ? `${borderSize === "sm" ? ".5" : "1"}rem solid ${color}` : "0rem solid",
})

const generateConditionalBackground = (style: CardType, color: string) => ({
  backgroundColor: style === "color_full" ? color : "rgba(012, 020, 075, 0.03)",
})

const generateConditionalColors = (style: CardType, color: string, borderSize: "sm" | "lg") => ({
  backgroundColor: style === "color_full" ? color : "rgba(012, 020, 075, 0.03)",
  borderLeft:
    style === "color_edge" ? `${borderSize === "sm" ? ".5" : "1"}rem solid ${color}` : "0rem solid",
})

/**
 *
 * @param axisMode string
 * @returns { performance: string, potential: string }[]
 * Provides an array of potential/performance values that align with the corresponding matrix grid items. Useful for determining
 * where to place candidates within the matrix grid.
 */
const generateMatrixLevels = (axisMode: string) => {
  if (axisMode === "performance") {
    return [
      { performance: "low", potential: "high" },
      { performance: "medium", potential: "high" },
      { performance: "high", potential: "high" },
      { performance: "low", potential: "medium" },
      { performance: "medium", potential: "medium" },
      { performance: "high", potential: "medium" },
      { performance: "low", potential: "low" },
      { performance: "medium", potential: "low" },
      { performance: "high", potential: "low" },
    ]
  }

  return [
    { performance: "high", potential: "low" },
    { performance: "high", potential: "medium" },
    { performance: "high", potential: "high" },
    { performance: "medium", potential: "low" },
    { performance: "medium", potential: "medium" },
    { performance: "medium", potential: "high" },
    { performance: "low", potential: "low" },
    { performance: "low", potential: "medium" },
    { performance: "low", potential: "high" },
  ]
}

/**
 * Lookup used to determine the badge color for an attribute when high is the desired value for the attribute
 */
const attributeColorMapper: Record<string, string> = {
  none: "gray",
  low: "red",
  medium: "yellow",
  high: "green",
}

/**
 * Lookup used to determine the badge color for an attribute when high is the unwanted value for the attribute
 */
const attributeColorMapperInverted: Record<string, string> = {
  none: "gray",
  low: "green",
  medium: "yellow",
  high: "red",
}

/**
 * Lookup used to determine the background classname for an attribute when high is the desired value for the attribute
 */
const attributeBackgroundColorMapper: Record<string, string> = {
  none: "bg-neutral-8",
  low: "bg-status-critical",
  medium: "bg-accent-sunburst",
  high: "bg-status-success",
}

export {
  attributeBackgroundColorMapper,
  attributeColorMapper,
  attributeColorMapperInverted,
  generateConditionalBackground,
  generateConditionalBorder,
  generateConditionalColors,
  generateMatrixLevels,
}
