/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from "react"

type Props = Omit<React.InputHTMLAttributes<HTMLInputElement>, "value" | "onChange">

/*
  This component is necessary for browser compatibility. Only Chrome
  enforces our desired rules for numeric inputs by default, so we need
  this component to enforce those rules in other browsers.
*/
export const StandardCellInput = forwardRef<HTMLInputElement, Props>(
  ({ defaultValue, type, ...props }, ref) => {
    const [value, setValue] = React.useState(defaultValue ?? "")

    React.useEffect(() => {
      setValue(defaultValue ?? "")
    }, [defaultValue])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault()
      if (type !== "number") {
        setValue(event.target.value)
      } else if (validateNumericValue(event.target.value)) {
        setValue(event.target.value)
      }
    }

    // Note: you cannot pass a `type` prop to the input, or it will
    // ignore the `value` state. 🤷
    return (
      <input
        {...props}
        ref={ref}
        style={{ background: "white", ...props.style }}
        value={value}
        onChange={handleInputChange}
      />
    )
  },
)

function validateNumericValue(value: string) {
  return /^-?([0-9]*\.)?[0-9]*$/.test(value)
}
