import fp from "lodash/fp"

import { Direction } from "v2/react/utils/enums"

export interface Movement {
  key: string
  shiftKey: boolean
  direction: Direction
}

export interface MovementCollection {
  movements: Movement[]
  findMovement: (event: KeyboardEvent) => Movement | undefined
  matchEvent: (event: KeyboardEvent) => boolean
}

const matchMovement = (movement: Movement) => fp.matches(fp.omit("direction", movement))
const movementByKeyboardEvent = (event: KeyboardEvent) => (movement: Movement) =>
  matchMovement(movement)(event)

const arrowMovements = [
  { direction: Direction.Down, key: "ArrowDown", shiftKey: false },
  { direction: Direction.Left, key: "ArrowLeft", shiftKey: false },
  { direction: Direction.Right, key: "ArrowRight", shiftKey: false },
  { direction: Direction.Up, key: "ArrowUp", shiftKey: false },
]

const transitionMovements = [
  { direction: Direction.Down, key: "Enter", shiftKey: false },
  { direction: Direction.Left, key: "Tab", shiftKey: true },
  { direction: Direction.Right, key: "Tab", shiftKey: false },
]

export const ArrowKeys: MovementCollection = {
  movements: arrowMovements,
  findMovement: (event: KeyboardEvent) => fp.find(movementByKeyboardEvent(event), arrowMovements),
  matchEvent: (event: KeyboardEvent) => !!ArrowKeys.findMovement(event),
}

export const TransitionKeys: MovementCollection = {
  movements: transitionMovements,
  findMovement: (event: KeyboardEvent) =>
    fp.find(movementByKeyboardEvent(event), transitionMovements),
  matchEvent: (event: KeyboardEvent) => !!TransitionKeys.findMovement(event),
}

export const makeMovementMatchHandler =
  (event: KeyboardEvent, handler: (movement: Movement) => void) =>
  (...collections: MovementCollection[]) =>
    fp.each((collection) => {
      const movement = collection.findMovement(event)
      if (movement) handler(movement)
    }, collections)
