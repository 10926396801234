import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CellContext, createColumnHelper } from "@tanstack/react-table"
import React, { useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { JobLevel } from "types/graphql"
import { SortDirection } from "types/graphql.enums"
import { CreateModal } from "v2/react/components/jobLevels/Index/CreateModal"
import { DeleteModal } from "v2/react/components/jobLevels/Index/DeleteModal"
import { EditModal } from "v2/react/components/jobLevels/Index/EditModal"
import RootProvider from "v2/react/components/RootProvider"
import { useServerTable, useTableState } from "v2/react/hooks/useServerTable"
import { SearchInput } from "v2/react/shared/forms/SearchInput"
import PageNav from "v2/react/shared/navigation/PageNav"
import {
  TitleBlockLarge,
  TitleHeaderWithParent,
} from "v2/react/shared/navigation/PageNav/TitleBlock"
import { UtilityNav } from "v2/react/shared/navigation/UtilityNav"
import { MoreActionsCell } from "v2/react/shared/tables/Table/Cell/MoreActionsCell"
import { Table } from "v2/react/shared/tables/Table/Table"
import { UrlHelper } from "v2/react/utils/urls"
import { useJobLevelCollectionQuery } from "v2/redux/GraphqlApi/JobLevelApi"

function WithProvider() {
  const { t } = useTranslation()
  const [activeJobLevel, setActiveJobLevel] = useState<JobLevel | null>(null)
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const { sorting, pagination, search, setSorting, setPagination, setSearch } = useTableState()
  const scrollRef = useRef<HTMLDivElement>(null)

  const { data, isLoading } = useJobLevelCollectionQuery({
    searchTerm: search,
    // #paginate in rails is 1-based, but the table is 0-based
    page: pagination.pageIndex + 1,
    sortKey: sorting[0]?.id,
    sortDirection: sorting[0]?.desc ? SortDirection.Desc : SortDirection.Asc,
  })

  const rows = data?.collection || []

  const handleOpenEditModal = (jobLevel: JobLevel) => {
    setActiveJobLevel(jobLevel)
    setEditModalOpen(true)
  }

  const handleCloseEditModal = () => {
    setEditModalOpen(false)
    setActiveJobLevel(null)
  }

  const handleDeleteModal = (jobLevel: JobLevel) => {
    setActiveJobLevel(jobLevel)
    setDeleteModalOpen(true)
  }

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false)
    setActiveJobLevel(null)
  }

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<JobLevel>()

    const moreActions = ({ row }: CellContext<JobLevel, unknown>) => (
      <MoreActionsCell<JobLevel>
        activeItem={row.original}
        openDeleteModal={handleDeleteModal}
        openEditModal={handleOpenEditModal}
      />
    )

    return [
      columnHelper.display({
        id: "actions",
        cell: moreActions,
      }),
      columnHelper.accessor("code", {
        header: t("v2.job_levels.column_headers.code"),
      }),
      columnHelper.accessor("name", {
        header: t("v2.job_levels.column_headers.name"),
      }),
      columnHelper.accessor("order", {
        header: t("v2.job_levels.column_headers.order"),
        sortDescFirst: false,
      }),
    ]
  }, [t])

  const handlePageChange = () => {
    scrollRef.current?.scroll({ top: 0, behavior: "smooth" })
  }

  const table = useServerTable<JobLevel>({
    columns,
    data: rows,
    sorting,
    pagination,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    totalRows: data?.metadata?.totalCount || 0,
  })

  return (
    <div className="h-screen flex-col overflow-hidden flex">
      <PageNav>
        <TitleBlockLarge>
          <TitleHeaderWithParent
            parentTitle={t("v2.job_levels.header.job_architecture")}
            parentTitleUrl={UrlHelper.jobArchitecturePath()}
            title={t("v2.job_levels.header.title")}
          />
        </TitleBlockLarge>
      </PageNav>
      <div className="h-full overflow-y-auto" ref={scrollRef}>
        <UtilityNav makeSticky>
          <div className="ml-auto items-center gap-2 flex">
            <SearchInput
              value={search}
              onChange={(value: string) => setSearch(value)}
              onClear={() => setSearch("")}
            />
            <button
              className="btn btn--primary sm:btn--large sm:btn--primary tooltip tooltip-right"
              type="button"
              onClick={() => setCreateModalOpen(!createModalOpen)}
            >
              <FontAwesomeIcon icon={["far", "plus"]} />
              <span className="hidden sm:flex">{t("v2.job_levels.index.add_job_level")}</span>
            </button>
          </div>
        </UtilityNav>
        <div className="page-pad">
          <Table
            table={table}
            isLoading={isLoading}
            animateEntrance
            footerContent={t("v2.job_levels.index.found", {
              count: data?.metadata?.totalCount || 0,
            })}
            onPageChange={handlePageChange}
          />
        </div>
        <CreateModal isOpen={createModalOpen} onClose={() => setCreateModalOpen(false)} />
        {activeJobLevel && (
          <EditModal
            isOpen={editModalOpen}
            onClose={handleCloseEditModal}
            jobLevel={activeJobLevel}
          />
        )}
        {activeJobLevel && (
          <DeleteModal
            isOpen={deleteModalOpen}
            onClose={handleCloseDeleteModal}
            jobLevel={activeJobLevel}
          />
        )}
      </div>
    </div>
  )
}

function Index() {
  return (
    <RootProvider>
      <WithProvider />
    </RootProvider>
  )
}

export { Index }
