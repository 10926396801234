import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

import { prepareIconClass } from "v2/react/utils/misc"

interface OpenLinkInNewTabProps {
  url: string
}

function OpenLinkInNewTab({ url }: OpenLinkInNewTabProps) {
  return (
    <a href={url} target="_blank" rel="noreferrer" className="svg-inline-fa text-inherit">
      <FontAwesomeIcon className="cursor-pointer" icon={prepareIconClass("fac arrow-up-right")} />
    </a>
  )
}

export { OpenLinkInNewTab }
