import React, { useEffect, useMemo } from "react"

import { FilterType } from "types/graphql.enums"
import { PLACEHOLDER_KEY_SUFFIX } from "v2/react/components/dashboard/config"
import { getPlaceholderLabel } from "v2/react/components/dashboard/helpers/filters"
import { FormSelect } from "v2/react/shared/forms/FormSelect"
import { NONE_KEY_SUFFIX } from "v2/react/utils/collections"
import { idFromUniqueKey, optionEntityFromUniqueKeyOrGiven } from "v2/react/utils/uniqueKey"
import { useFetchDrillDownOptionsQuery } from "v2/redux/GraphqlApi/DashboardApi"
import { clearFilter, selectInitialFilter, updateFilter } from "v2/redux/slices/DrillDownSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

interface DrillDownFiltersProps {
  query: string
  handleDataLoaded: () => void
}

const DrillDownFilters = ({ query, handleDataLoaded }: DrillDownFiltersProps) => {
  const { filterKey, filterValue } = useAppSelector(selectInitialFilter)
  const dispatch = useAppDispatch()

  const { data, isSuccess } = useFetchDrillDownOptionsQuery(query)

  useEffect(() => {
    if (isSuccess) {
      handleDataLoaded()
    }
  }, [isSuccess, handleDataLoaded])

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const id = idFromUniqueKey(e.target.value)
    const field = e.target.id

    if (id === PLACEHOLDER_KEY_SUFFIX) {
      dispatch(clearFilter(field))
    } else if (id === NONE_KEY_SUFFIX) {
      dispatch(
        updateFilter({ type: FilterType.Inclusion, field, value: { in: [NONE_KEY_SUFFIX] } }),
      )
    } else {
      const selectedOption = e.target.options[e.target.selectedIndex]
      const value = { in: [selectedOption.text] }
      const newFilter = { type: FilterType.Inclusion, field, value }

      dispatch(updateFilter(newFilter))
    }
  }

  const filteredEntries = useMemo(() => {
    if (!data) return undefined
    return data.filter((d) => d.options?.nodes.length > 0)
  }, [data])

  return filteredEntries ? (
    <div className="flex-wrap-input-group">
      {filteredEntries.map((entry) => {
        const fieldKey = entry.name
        const idPrefix = optionEntityFromUniqueKeyOrGiven(entry.name)

        return (
          <FormSelect
            key={fieldKey}
            id={fieldKey}
            label={entry.label}
            options={entry.options?.nodes}
            selected={filterKey === fieldKey ? filterValue : undefined}
            placeholderOption={{
              id: `${idPrefix}_${PLACEHOLDER_KEY_SUFFIX}`,
              label: getPlaceholderLabel(entry),
            }}
            noneOption={{
              id: `${idPrefix}_${NONE_KEY_SUFFIX}`,
              label: "None".t("options"),
            }}
            onSelect={handleFilterChange}
          />
        )
      })}
    </div>
  ) : null
}

export { DrillDownFilters }
