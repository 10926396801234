import type { Args, Meta, StoryObj } from "@storybook/react"
import React from "react"

// https://medium.com/urbandataanalytics/changing-args-values-state-in-storybook-without-usestate-hook-15cc096716f7
import { PeopleConnectionQuery } from "types/graphql.d"

import { PersonSearchInput } from "./PersonSearchInput"

const meta: Meta<typeof PersonSearchInput> = {
  title: "shared/search/PersonSearchInput",
  component: PersonSearchInput,
  decorators: [
    (Story) => (
      <div style={{ margin: "20px 50% 20px 20px" }}>
        <Story />
      </div>
    ),
  ],
}

export default meta

// Credit to: https://medium.com/urbandataanalytics/changing-args-values-state-in-storybook-without-usestate-hook-15cc096716f7
const StateWrapperTemplate = ({ dataFetchFn, htmlForId, placeholder }: Args) => (
  <PersonSearchInput
    dataFetchFn={dataFetchFn}
    htmlForId={htmlForId}
    onSelect={() => true}
    placeholder={placeholder}
  />
)

type Story = StoryObj<typeof PersonSearchInput>

const peopleFixture: PeopleConnectionQuery = {
  people: {
    nodes: [
      {
        avatarThumbUrl: "http://localhost:3000/uploads/company/1/person/1/avatar/thumb_Built10.png",
        id: "36",
        name: "Adella Wiegand",
        primaryPosition: {
          id: "1",
          title: "CXO",
        },
      },
      {
        avatarThumbUrl: "http://localhost:3000/uploads/company/1/person/1/avatar/thumb_Built10.png",
        id: "15",
        name: "Alise Olson",
        primaryPosition: {
          id: "1",
          title: "CYO",
        },
      },
      {
        avatarThumbUrl: "http://localhost:3000/uploads/company/1/person/1/avatar/thumb_Built10.png",
        id: "62",
        name: "Amber Collier",
        primaryPosition: {
          id: "1",
          title: "CZO",
        },
      },
    ],
  },
}

export const Main: Story = {
  args: {
    htmlForId: "test_search_input",
    placeholder: "ex: John Doe",
    dataFetchFn: () => peopleFixture,
  },
  render: ({ dataFetchFn, placeholder }: Args) => (
    <StateWrapperTemplate dataFetchFn={dataFetchFn} placeholder={placeholder} />
  ),
}
