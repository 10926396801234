/* eslint-disable react/jsx-props-no-spreading */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import { TFunction } from "i18next"
import React from "react"
import { useTranslation } from "react-i18next"

import {
  usePositionController,
  usePositionWatchField,
} from "v2/react/components/positions/positionForm/hooks"
import { PersonAutocomplete } from "v2/react/components/positions/positionForm/inputs/PersonAutocomplete"
import { usePositionFormContext } from "v2/react/components/positions/positionForm/PositionFormProvider"

type PersonRowProps = {
  index: number
  onRemovePress: React.PointerEventHandler<HTMLButtonElement>
}

/**
 * Based on app/views/v2/shared/inputs/filled_by_input/_person_search_input.html.slim}
 * @todo Search results limited to subordinate only depending on person's
 *   privileges.
 * @todo Support replacing current occupant.
 */
function PersonRow({ index, onRemovePress }: PersonRowProps) {
  const { t } = useTranslation()
  const gon = typeof window !== "undefined" ? window.gon : undefined
  // @todo: There is a slight difference between a couple of these translations
  // from what the Coffeescript translations defined. Reconcile.
  const {
    positionData: { id: thisId },
    positionFormCollections: { employeeTypes },
    register,
  } = usePositionFormContext()

  const {
    field: { value: person, onChange: changePerson },
  } = usePositionController({ name: `position.people.${index}` })

  const positionEmployeeTypeId = usePositionWatchField({ name: "position.employeeTypeId" })
  const positionEmployeeType = employeeTypes.find(({ id }) => positionEmployeeTypeId === id)

  const personAlreadyInPosition = Boolean(person.person?.positions?.find(({ id }) => thisId === id))
  const personHasPrimary = Boolean(person.person?.primaryPosition)
  const handleClearPerson = () => changePerson({ id: null, name: "" })

  return (
    <div className="person-form" data-index={index}>
      <div className="relative w-full items-center justify-between flex">
        <PersonAutocomplete
          id={`position_people_${index}_name`}
          name={`position.people.${index}.name`}
          onClearPerson={handleClearPerson}
          onSelectPerson={changePerson}
          selected={person.person}
        />
        <button
          className={cn("skip-tab ml-3 bg-transparent !p-0")}
          title={t("v2.shared.inputs.filled_by_input.unassign_person")}
          tabIndex={-1}
          onClick={onRemovePress}
          type="button"
        >
          <FontAwesomeIcon icon={["far", "times"]} className="icon-14" />
        </button>
      </div>
      {personHasPrimary && !personAlreadyInPosition && (
        <div className="elevation mr-5 mt-2 rounded-xl bg-neutral-3 p-4 flex">
          <div className="items-start self-stretch">
            <FontAwesomeIcon
              icon={["far", "exclamation-triangle"]}
              className="fa-w-18 text-2xl text-neutral-60 mr-2"
            />
          </div>
          {gon?.company_settings?.positions?.disable_secondary_positions && (
            <span>{t("v2.positions.positions_manager.will_be_removed_from_other_positions")}</span>
          )}
          {!gon?.company_settings?.positions?.disable_secondary_positions && (
            <fieldset className="m-0">
              <legend className="mb-2 font-bold">
                {tAlreadyFills(t, {
                  name: person.name ?? "",
                  title: person.person?.primaryPosition?.title,
                  reportsToName: person.person?.primaryPosition?.reportsToName,
                })}{" "}
                {t("v2.positions.positions_manager.what_would_you_like_to_do")}
              </legend>
              <label className="radio font-medium">
                <input
                  defaultChecked
                  type="radio"
                  value="dotted"
                  {...register(`position.people.${index}.action`)}
                />
                <span>
                  {t("v2.positions.positions_manager.leave", {
                    name: person.person?.name,
                    title: person.person?.primaryPosition?.title,
                  })}
                </span>
              </label>
              <label className="radio font-medium">
                <input type="radio" value="move" {...register(`position.people.${index}.action`)} />
                <span>
                  {t("v2.positions.positions_manager.move", {
                    name: person.person?.name,
                    title: person.person?.primaryPosition?.title,
                  })}
                </span>
              </label>
              <label className="radio font-medium">
                <input
                  type="radio"
                  value="primary"
                  {...register(`position.people.${index}.action`)}
                />
                <span>
                  {t("v2.positions.positions_manager.make", {
                    name: person.person?.name,
                    title: person.person?.primaryPosition?.title,
                  })}
                </span>
              </label>
            </fieldset>
          )}
        </div>
      )}
      {positionEmployeeType && positionEmployeeType.id !== person.person?.employeeType?.id && (
        <div className="elevation mr-5 mt-2 rounded-xl bg-neutral-3 p-4 flex">
          <div className="items-start self-stretch">
            <FontAwesomeIcon
              icon={["far", "exclamation-triangle"]}
              className="fa-w-18 text-2xl text-neutral-60 mr-2"
            />
          </div>

          <span className="font-bold">
            {t("v2.positions.employee_type_manager.conflicting_types", {
              person_employee_type: person.person?.employeeType?.labelTranslated,
              position_employee_type: positionEmployeeType.label,
            })}
          </span>
        </div>
      )}
    </div>
  )
}

type TAlreadyFillsArg = {
  name: string
  title?: string | null
  reportsToName?: string | null
}

const tAlreadyFills = (t: TFunction, { name, title, reportsToName }: TAlreadyFillsArg) => {
  const tArg = {
    name,
    title,
    reports_to_name: reportsToName,
  }

  if (title && reportsToName)
    return t("v2.positions.positions_manager.already_fills_and_reports", tArg)
  if (title) return t("v2.positions.positions_manager.already_fills", tArg)
  if (reportsToName) return t("v2.positions.positions_manager.already_reports_to", tArg)
  return t("v2.already_fills_a_position", tArg)
}

export { PersonRow, PersonRowProps }
