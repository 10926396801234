import { z } from "zod"

import { globals } from "../constants/options"

const Point = z.object({
  x: z.number(),
  y: z.number(),
})
type PointData = z.infer<typeof Point>

interface OrgChartNode extends d3.layout.tree.Node {
  x0: number
  y0: number
  assistants?: OrgChartNode[]
}

const arrayOfPointsToString = (points: PointData[]): string =>
  points.map((point) => [point.x, point.y].join(",")).join(" ")

//  This is the link type that is used in the standard tree mode.
//
//                  ------
//                  |    |
//                  ------
//                     |
//      ------------------------------
//      |       |      |      |      |
//    ------  ------ ------ ------ ------
//    |    |  |    | |    | |    | |    |
//    ------  ------ ------ ------ ------
//
export const linkPathForNode = (
  d: d3.layout.tree.Link<OrgChartNode>,
  nodeHeight: number,
  assistantVerticalSpacing: number,
  fixedVerticalSpacing: number | null,
): string => {
  const xDistance = Math.abs(d.target.x0 - d.source.x0)
  // The curveDirection is set to -1 for left, 1 for right
  const curveDirection = d.target.x0 < d.source.x0 ? -1 : 1
  // Reduce the curve radius to fit close source/target x values
  const effectiveRadius = xDistance < 2 * globals.linkRadius ? xDistance / 2 : globals.linkRadius
  const appliedAssistantSpacing =
    d.source.assistants && d.source.assistants.length > 0 ? assistantVerticalSpacing : 0
  const midpointX = xDistance * curveDirection
  let midpointY = (d.target.y0 - d.source.y0 + appliedAssistantSpacing) / 2

  if (fixedVerticalSpacing !== null) {
    midpointY = (nodeHeight + fixedVerticalSpacing) / 2 + appliedAssistantSpacing
  }

  const start = { x: 0, y: nodeHeight / 2 + globals.linkDistanceFromTarget }
  const firstCurveStart = { x: 0, y: midpointY - effectiveRadius }
  const firstCurveEnd = { x: effectiveRadius * curveDirection, y: midpointY }
  const secondCurveStart = { x: midpointX - effectiveRadius * curveDirection, y: midpointY }
  const secondCurveEnd = { x: midpointX, y: midpointY + effectiveRadius }
  const end = {
    x: midpointX,
    y: d.target.y0 - d.source.y0 - nodeHeight / 2 - globals.linkDistanceFromTarget,
  }

  const translated = [
    start,
    firstCurveStart,
    firstCurveEnd,
    secondCurveStart,
    secondCurveEnd,
    end,
  ].map((point) => ({
    x: point.x + d.source.x0,
    y: point.y + d.source.y0 + nodeHeight / 2,
  }))

  // Construct the path string using curve interpolation
  return constructRoundedPath(translated)
}

// Curved line path between the source and target. The path essentially works
// to round the corners of the typical org chart links. We use 6 points to draw
// the curve.
const constructRoundedPath = (points: PointData[]): string => {
  const [start, firstCurveStart, firstCurveEnd, secondCurveStart, secondCurveEnd, end] = points

  const controlPoint1 = { x: firstCurveStart.x, y: firstCurveEnd.y }
  const controlPoint2 = { x: secondCurveEnd.x, y: secondCurveStart.y }

  // Basic explaination:
  // M Move to start (source node link coordinates)
  // L Draw a line to the first edge of the curve
  // C Draw a curve using the control points defined, between points 2 and 3
  // L Draw a line between the end of the curve and the start of the next curve
  // C Draw a curve using the control points defined, between points 4 and 5
  // L Draw a line between the end of the curve and the target node
  return (
    `M ${start.x},${start.y} ` +
    `L ${firstCurveStart.x},${firstCurveStart.y} ` +
    `C ${controlPoint1.x},${controlPoint1.y} ${firstCurveEnd.x},${firstCurveEnd.y} ${firstCurveEnd.x},${firstCurveEnd.y} ` +
    `L ${secondCurveStart.x},${secondCurveStart.y} ` +
    `C ${controlPoint2.x},${controlPoint2.y} ${secondCurveEnd.x},${secondCurveEnd.y} ${secondCurveEnd.x},${secondCurveEnd.y} ` +
    `L ${end.x},${end.y}`
  )
}

// This link type is only used for the 3rd level of the 3-level view.
//
// These links kind of swoop around, and require 8 points to render:
//   start point
//   curve 1 start
//   curve 1 end
//   curve 2 start
//   curve 2 end
//   curve 3 start
//   curve 3 end
//   end point
//
//    ------
//    |    |
//    ------
//     |
//     |  ------
//     |--|    |
//     |  ------
//     |
//     |  ------
//     |--|    |
//     |  ------
//
export const linkPathForThirdLevelNode = (
  d: d3.layout.tree.Link<OrgChartNode>,
  nodeHeight: number,
  sideLinkYPosition: number,
  nodeHeightWithVertialSpacing: number,
): string => {
  const start = { x: 0, y: nodeHeight / 2 + globals.linkDistanceFromTarget }
  const nodeVerticalSpacing = nodeHeightWithVertialSpacing - nodeHeight
  const startMiddle = {
    x: 0,
    y: start.y + nodeVerticalSpacing * (2 / 3) + globals.linkDistanceFromTarget,
  }

  const firstCurveStart = { x: startMiddle.x, y: startMiddle.y - globals.linkRadius }
  const firstCurveEnd = { x: startMiddle.x + globals.linkRadius * -1, y: startMiddle.y }

  // nodeWidth / 2 places the line at the leftmost side of a card, then the
  // design shows another 5px offset from the outer edge of the card.
  const pathOffsetFromLeftSideOfCard = globals.nodeWidth / 2 - 5
  const secondCurveStart = {
    x: startMiddle.x - pathOffsetFromLeftSideOfCard + globals.linkRadius,
    y: firstCurveEnd.y,
  }
  const secondCurveEnd = {
    x: startMiddle.x - pathOffsetFromLeftSideOfCard,
    y: secondCurveStart.y + globals.linkRadius,
  }

  const thirdCurveStart = {
    x: startMiddle.x - pathOffsetFromLeftSideOfCard,
    y: d.target.y0 - d.source.y0 - nodeHeight / 2 + sideLinkYPosition - globals.linkRadius,
  }
  const thirdCurveEnd = {
    x: startMiddle.x - pathOffsetFromLeftSideOfCard + globals.linkRadius,
    y: d.target.y0 - d.source.y0 - nodeHeight / 2 + sideLinkYPosition,
  }

  const end = {
    x: d.target.x0 - d.source.x0 - globals.nodeWidth / 2 - globals.linkDistanceFromTarget,
    y: d.target.y0 - d.source.y0 - nodeHeight / 2 + sideLinkYPosition,
  }

  const translated = [
    start,
    firstCurveStart,
    firstCurveEnd,
    secondCurveStart,
    secondCurveEnd,
    thirdCurveStart,
    thirdCurveEnd,
    end,
  ].map((point) => ({
    x: point.x + d.source.x0,
    y: point.y + d.source.y0 + nodeHeight / 2,
  }))

  return constructRoundedPathForThirdLevelNode(translated)
}

const constructRoundedPathForThirdLevelNode = (points: PointData[]): string => {
  const [
    start,
    firstCurveStart,
    firstCurveEnd,
    secondCurveStart,
    secondCurveEnd,
    thirdCurveStart,
    thirdCurveEnd,
    end,
  ] = points

  const controlPoint1 = { x: firstCurveStart.x, y: firstCurveEnd.y }
  const controlPoint2 = { x: secondCurveEnd.x, y: secondCurveStart.y }
  const controlPoint3 = { x: thirdCurveStart.x, y: thirdCurveEnd.y }

  return (
    `M ${start.x},${start.y} ` +
    `L ${firstCurveStart.x},${firstCurveStart.y}` +
    `C ${controlPoint1.x},${controlPoint1.y} ${firstCurveEnd.x},${firstCurveEnd.y} ${firstCurveEnd.x},${firstCurveEnd.y} ` +
    `L ${secondCurveStart.x},${secondCurveStart.y}` +
    `C ${controlPoint2.x},${controlPoint2.y} ${secondCurveEnd.x},${secondCurveEnd.y} ${secondCurveEnd.x},${secondCurveEnd.y} ` +
    `L ${secondCurveEnd.x},${secondCurveEnd.y}` +
    `L ${thirdCurveStart.x},${thirdCurveStart.y}` +
    `C ${controlPoint3.x},${controlPoint3.y} ${thirdCurveEnd.x},${thirdCurveEnd.y} ${thirdCurveEnd.x},${thirdCurveEnd.y} ` +
    `L ${end.x},${end.y}`
  )
}

// Example:
//
//                 ------
//                 |    |
//                 ------
//                   |
//  ------   ------  |  ------   ------
//  |    | --|    |--|--|    | --|    |
//  ------   ------  |  ------   ------
//
export const linkPointsForAssistant = (
  d: d3.layout.tree.Link<OrgChartNode>,
  cardVerticalCenter: number,
  nodeHeight: number,
): string => {
  // If rendering is not yet complete, x or y may not be set yet.
  if (
    d.target.x === undefined ||
    d.target.y === undefined ||
    d.source.x === undefined ||
    d.source.y === undefined
  ) {
    return ""
  }

  const linkDirection = d.target.x < d.source.x ? -1 : 1
  const finalLegTotalLength = globals.nodeWidth / 2 + globals.linkDistanceFromTarget

  const points = [
    {
      x: d.source.x,
      y: d.source.y + nodeHeight + globals.linkDistanceFromTarget,
    },
    {
      x: d.source.x,
      y: d.target.y + cardVerticalCenter,
    },
    {
      x: d.target.x - finalLegTotalLength * linkDirection,
      y: d.target.y + cardVerticalCenter,
    },
  ]

  return arrayOfPointsToString(points)
}

//  The "condensed tree" display mode uses links that look like this:
//
//                   ------
//                   |    |
//                   ------
//                      |
//      ------  ------  | ------  ------
//      |    |          |         |    |
//      ------          |         ------
//      ------  ------  | ------  ------
//      |    |                    |    |
//      ------                    ------
//
export const linkPathForCondensedTreeBranch = (
  d: d3.layout.tree.Link<OrgChartNode>,
  nodeHeight: number,
  sideLinkYPosition: number,
): string => {
  if (
    d.target.x0 === undefined ||
    d.target.y0 === undefined ||
    d.source.x0 === undefined ||
    d.source.y0 === undefined
  ) {
    return ""
  }

  const linkDirection = d.target.x0 < d.source.x0 ? -1 : 1
  const start = { x: 0, y: nodeHeight / 2 + globals.linkDistanceFromTarget }
  const firstCurveStart = {
    x: start.x,
    y: d.target.y0 - d.source.y0 + -(nodeHeight / 2) + sideLinkYPosition - globals.linkRadius,
  }
  const firstCurveEnd = {
    x: (start.x + globals.linkRadius) * linkDirection,
    y: firstCurveStart.y + globals.linkRadius,
  }
  const end = {
    x:
      (globals.clusterHorizontalSpacing / 2 -
        globals.linkDistanceFromTarget +
        // If the target node is currently being dragged, we render a longer
        // link.
        (d.target.x ? 0 : globals.closestNodeDistance)) *
      linkDirection,
    y: firstCurveEnd.y,
  }

  const translated = [start, firstCurveStart, firstCurveEnd, end].map((point) => ({
    x: point.x + d.source.x0,
    y: point.y + d.source.y0 + nodeHeight / 2,
  }))

  return constructRoundedPathForCondensedTreeBranch(translated)
}

const constructRoundedPathForCondensedTreeBranch = (points: PointData[]): string => {
  const [start, firstCurveStart, firstCurveEnd, end] = points

  const controlPoint = { x: firstCurveStart.x, y: firstCurveEnd.y }

  return (
    `M ${start.x},${start.y} ` +
    `L ${firstCurveStart.x},${firstCurveStart.y}` +
    `C ${controlPoint.x},${controlPoint.y} ${firstCurveEnd.x},${firstCurveEnd.y} ${firstCurveEnd.x},${firstCurveEnd.y} ` +
    `L ${end.x},${end.y}`
  )
}

export default {
  linkPathForCondensedTreeBranch,
  linkPathForNode,
  linkPathForThirdLevelNode,
  linkPointsForAssistant,
}
