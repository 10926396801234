import React from "react"

import { RequisitionImportLink } from "v2/react/components/jobRequisitions/RequisitionImportLink"
import RootProvider from "v2/react/components/RootProvider"
import PageNav from "v2/react/shared/navigation/PageNav"
import { ActionBlock } from "v2/react/shared/navigation/PageNav/ActionBlock"
import { LinkBlock } from "v2/react/shared/navigation/PageNav/LinkBlock"
import { Link, LinkGroup } from "v2/react/shared/navigation/PageNav/LinkGroup"
import { TitleBlock } from "v2/react/shared/navigation/PageNav/TitleBlock"

interface Props {
  links?: Link[]
  title: string
  importSource: string
  importPath: string
  isImporting: boolean
}

function RecruitingPageNav({ title, links = [], importSource, importPath, isImporting }: Props) {
  return (
    <RootProvider>
      <PageNav>
        <TitleBlock>
          <h1>{title}</h1>
        </TitleBlock>
        <LinkBlock>
          <LinkGroup links={links} />
        </LinkBlock>
        {importSource && (
          <ActionBlock>
            <RequisitionImportLink importPath={importPath} isImporting={isImporting} />
          </ActionBlock>
        )}
      </PageNav>
    </RootProvider>
  )
}

export { RecruitingPageNav }
