import { bindActionCreators, createAction, isAnyOf } from "@reduxjs/toolkit"
import fp from "lodash/fp"

import { startAppListening } from "v2/redux/listenerMiddleware"
import {
  arrangeDatasheet,
  arrangeDatasheetEffect,
} from "v2/redux/listeners/datasheetListeners/arrangeDatasheetEffect"
import type { FieldSavedPayload, FollowUpPayload } from "v2/redux/listeners/types"
import { collapseOrExpandChartSection } from "v2/redux/slices/ContainerSlice"
import { selectFieldIndex } from "v2/redux/slices/ContainerSlice/containerSelectors"
import { asyncPatchPreferences } from "v2/redux/slices/ContainerSlice/containerThunks"
import { setFollowUpModal, skeletonSelectors, transitionCursor } from "v2/redux/slices/GridSlice"
import {
  cursorUnit,
  endWriteWithCursor,
  moveCursor,
} from "v2/redux/slices/GridSlice/cursor/cursorActions"
import {
  dispatchCellCursorTargetChangeAfterFollowUp,
  dispatchCursorEvents,
} from "v2/redux/slices/GridSlice/cursor/cursorEvents"
import { selectCursor } from "v2/redux/slices/GridSlice/cursor/cursorSelectors"
import {
  inEitherReadOnEditable,
  inEitherWriteState,
  onNothing,
} from "v2/redux/slices/GridSlice/cursor/cursorStates"
import { asyncComputeAndSetSkeleton } from "v2/redux/slices/GridSlice/gridThunks"
import { GroupRow, RowType } from "v2/redux/slices/GridSlice/types"
import { endFieldTransition, removeNode, startFieldTransition } from "v2/redux/slices/NodeSlice"
import { NodeApi, updatePagedNodeState } from "v2/redux/slices/NodeSlice/NodeApi"
import type { Transition } from "v2/redux/slices/NodeSlice/types"
import { selectAutoPersistedSettings } from "v2/redux/slices/VisualizationSlice/visualizationSelectors"
import { useAppDispatch } from "v2/redux/store"

export const cancelFollowUp = createAction("datasheetListeners/cancelFollowUp", () => ({
  payload: undefined,
}))
export const followUp = createAction<FollowUpPayload>("datasheetListeners/followUpOnChange")
export const saveModalSuccess = createAction("datasheetListeners/saveModalSuccess")
export const fieldSaved = createAction<FieldSavedPayload>("datasheetListeners/fieldSaved")
export const useDatasheetListenerActions = () =>
  bindActionCreators({ cancelFollowUp, fieldSaved, followUp, saveModalSuccess }, useAppDispatch())

/**
 * Mounts listeners to help produce effects supporting the datasheet.
 *
 * - The datasheet does not reactively apply filters/grouping/sorting as the
 *   user makes changes. However, since we load person-position nodes in
 *   batches, we want incoming data to be placed appropriately. This attaches
 *   a listener to do just that.
 * - Some fields, when changed, will require additional details from the user.
 *   This helps guide the "followup" process to gather these details and
 *   include them in the request to the server.
 * - In order to support the prior bullet, this also helps guide field state
 *   transitions (i.e. when a field is transitioning from X to "saved"). Once a
 *   transition finishes, if the person-position node has been marked for
 *   deletion, it will be ejected from state. Callers can opt-out of this
 *   behavior.
 *
 * @public
 */
export function mountDatasheetListeners() {
  const cleanupStack = [
    startListeningForArrangeDatasheet(),
    startListeningForBatchesOfData(),
    startListeningForChangesThatInvalidateTheCellCursor(),
    startListeningForCollapsedChartSectionChanges(),
    startListeningForEmittableCursorChanges(),
    startListeningForFieldSaved(),
    startListeningForFollowUp(),
    startListeningForRemoveNode(),
    startListeningForSpecialSettingChanges(),
  ]
  return () => cleanupStack.forEach((cleanup) => cleanup())
}

const startListeningForArrangeDatasheet = () =>
  startAppListening({
    actionCreator: arrangeDatasheet,
    effect: async (action, api) => {
      await arrangeDatasheetEffect({ action, api })
    },
  })

const startListeningForBatchesOfData = () =>
  startAppListening({
    matcher: NodeApi.endpoints.fetchPageOfNodes.matchFulfilled,
    effect: (_action, api) => api.dispatch(asyncComputeAndSetSkeleton()),
  })

const startListeningForChangesThatInvalidateTheCellCursor = () =>
  startAppListening({
    predicate: (_, currentState) => {
      // Load the cursor; bail if it's on nothing.
      const cursor = selectCursor(currentState)
      if (onNothing(cursor)) return false

      // If we can't find an entry in the spreadsheet's skeleton, or if the
      // entry isn't for a node, the cursor needs to be removed.
      const skeletonRow = skeletonSelectors.selectById(currentState, cursor.rowId)
      if (!skeletonRow || skeletonRow?.rowType !== RowType.Node) return true

      let groupRow: GroupRow | undefined
      if (skeletonRow.groupId) {
        const maybeGroupRow = skeletonSelectors.selectById(currentState, skeletonRow.groupId)
        groupRow =
          maybeGroupRow && maybeGroupRow.rowType === RowType.Group ? maybeGroupRow : undefined
      }

      // If the group row is collapsed, or nested within a collapsed group,
      // the cursor has been invalidated.
      if (groupRow && (groupRow.isHidden || !groupRow.isExpanded)) return true

      // Final check: the cursor needs to be invalidated if its on a field
      // that's no longer in the spreadsheet.
      return !(cursor.fieldKey in selectFieldIndex(currentState))
    },
    effect: async (_, { dispatch }) => {
      dispatch(transitionCursor(cursorUnit))
    },
  })

const startListeningForCollapsedChartSectionChanges = () =>
  startAppListening({
    actionCreator: collapseOrExpandChartSection,
    effect: async (_, { dispatch, getState }) => {
      const index = getState().container.chartSectionsCollapsedIndex
      const collapsedIds = fp.keys(fp.pickBy(fp.identity, index))
      await dispatch(asyncPatchPreferences({ collapsed_chart_section_ids: collapsedIds }))
    },
  })

const startListeningForEmittableCursorChanges = () =>
  startAppListening({
    predicate: (_, current, prior) => selectCursor(current) !== selectCursor(prior),
    effect: async (_, api) => {
      dispatchCursorEvents({ api })
    },
  })

const startListeningForFieldSaved = () =>
  startAppListening({
    actionCreator: fieldSaved,
    effect: async ({ payload }, { getState, dispatch, delay }) => {
      const { id, fieldKey, duration = 800, skipCleanupAfterTransition = false } = payload
      const transition: Transition = { type: "saved", duration }

      if (getState().visualization.showMetrics && typeof window !== "undefined")
        window.App.OrgChart.reloadMetrics()

      // Ensure we exit the write state if we're still in it, and that we
      // restore keyboard event listeners (`transitionKeyCanMove`). The flag
      // not only restores listeners, but will also treat the next Tab, Enter,
      // Shift+Tab, etc as a navigation event.
      if (inEitherWriteState(selectCursor(getState())))
        dispatch(endWriteWithCursor({ transitionKeyCanMove: true }))

      dispatch(startFieldTransition({ id, fieldKey, transition }))
      await delay(duration)
      dispatch(endFieldTransition({ id, fieldKey, transition }))

      if (skipCleanupAfterTransition) return
      if (getState().node.idsMarkedForDeletion.indexOf(id) === -1) return

      // Clean up the record if it is marked for deletion. Specific state
      // slices can match on `removeNode` in order to drop references relating
      // to the row/node.
      dispatch(removeNode(id))
    },
  })

const startListeningForFollowUp = () =>
  startAppListening({
    actionCreator: followUp,
    effect: async ({ payload: { field, row, cursorOptions } }, api) => {
      const { dispatch, take } = api
      const openModal = setFollowUpModal({ isOpen: true, field, row })
      const closeModal = setFollowUpModal({ isOpen: false, field: null, row: null })

      // Open the modal and prompt the user for feedback.
      dispatch(openModal)

      // Wait for the user to provide additional info or cancel the flow.
      const cancelOrSave = take(isAnyOf(cancelFollowUp, saveModalSuccess))
      await cancelOrSave

      // Move the cursor if requested.
      if (cursorOptions && "moveAfterTo" in cursorOptions)
        dispatch(moveCursor({ direction: cursorOptions.moveAfterTo }))
      else if (cursorOptions && "transitionKeyCanMove" in cursorOptions)
        dispatch(endWriteWithCursor({ transitionKeyCanMove: cursorOptions.transitionKeyCanMove }))

      // Close the follow up modal.
      dispatch(closeModal)

      // Handle an annoying edge case where the cursor may not have focus after
      // the follow up. Failure to do this can lead to keyboard events not
      // working. One sequence of steps that leads to this wonky state:
      //
      // - Enter an autocomplete cell, making a change and then reverting it
      //   before the next step (goal is that the content is equal to the
      //   current value).
      // - Click "Add" in an autocomplete cell.
      // - Make whatever changes.
      // - Hit Enter to save.
      //
      // At this point, without this, the `beaconRef` used elsewhere isn't
      // focused and thus keyboard events don't surface.
      if (inEitherReadOnEditable(selectCursor(api.getState())))
        dispatchCellCursorTargetChangeAfterFollowUp({ api })
    },
  })

const startListeningForRemoveNode = () =>
  startAppListening({
    actionCreator: removeNode,
    effect: async ({ payload: id }, { dispatch }) => {
      // Ensure we remove the actual node entry from the API slice.
      updatePagedNodeState(dispatch, (adapter) => (state) => adapter.removeOne(state, id))
    },
  })

const startListeningForSpecialSettingChanges = () =>
  startAppListening({
    predicate: (_, currentState, priorState) =>
      selectAutoPersistedSettings(currentState) !== selectAutoPersistedSettings(priorState),
    effect: async (_, { dispatch, getState, getOriginalState }) => {
      const originalAutoPersistedSettings = selectAutoPersistedSettings(getOriginalState())
      const autoPersistedSettings = selectAutoPersistedSettings(getState())
      await dispatch(asyncPatchPreferences(autoPersistedSettings))

      // Remove the cursor from the datasheet when leaving edit mode.
      if (originalAutoPersistedSettings.edit_mode && !autoPersistedSettings.edit_mode)
        dispatch(transitionCursor(cursorUnit))
    },
  })
