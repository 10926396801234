import { useMemo } from "react"

import { usePositionFormContext } from "v2/react/components/positions/positionForm/PositionFormProvider"
import { FieldValues } from "v2/react/components/positions/positionForm/types"

/**
 * Convenience hook that simply returns the initial position type so it matches
 * its expected type.
 *
 * @todo Might toss this.
 */
const useDefaultValuesPositionType = ():
  | NonNullable<FieldValues["position"]["positionType"]>
  | undefined => {
  const {
    formState: { defaultValues },
  } = usePositionFormContext()

  const givenDefaultPositionType = defaultValues?.position?.positionType

  return useMemo(() => {
    if (!givenDefaultPositionType) return undefined

    const safeUneditableAttributeKeys =
      (givenDefaultPositionType.uneditablePositionTypeControlledAttributeKeys?.filter(
        (val) => val?.trim() !== "",
      ) ?? []) as string[]

    return {
      ...givenDefaultPositionType,
      id: givenDefaultPositionType.id ?? "",
      jobCodeTitleLabel: givenDefaultPositionType.jobCodeTitleLabel ?? "",
      uniqueKey: givenDefaultPositionType.uniqueKey ?? "position_type_try_create",
      uneditablePositionTypeControlledAttributeKeys: safeUneditableAttributeKeys,
    }
  }, [givenDefaultPositionType])
}

export { useDefaultValuesPositionType }
