/* eslint-disable no-underscore-dangle, jsx-a11y/anchor-is-valid, react/jsx-props-no-spreading */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import fp from "lodash/fp"
import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"

import { AllocationType } from "types/graphql"
import { CustomFieldField } from "types/graphql.enums"
import {
  useFieldLabelHelpers,
  usePositionFieldArray,
  usePositionWatchField,
} from "v2/react/components/positions/positionForm/hooks"
import { LegacyDateInput } from "v2/react/components/positions/positionForm/inputs"
import { usePositionFormContext } from "v2/react/components/positions/positionForm/PositionFormProvider"
import {
  Allocation,
  EntityWithIndex,
  FieldValues,
} from "v2/react/components/positions/positionForm/types"
import { PercentInput, TextInput } from "v2/react/shared/forms"
import { formatCurrency } from "v2/react/utils/currency"
import { safeNumber } from "v2/react/utils/safeNumber"
import { UrlHelper } from "v2/react/utils/urls"

type AllocationAmountFieldWithFormId = NonNullable<
  FieldValues["position"]["allocationAmountsAttributes"]
>[0] & {
  formId: string
}
type AllocationAmountsForm = EntityWithIndex<AllocationAmountFieldWithFormId>
type AllocationTypeSegmentErrorProps = {
  index: number
}

const getAllocatedLabel = (allocated: Allocation): string => {
  // @ts-ignore
  if (allocated.__typename === "OrgUnit") return allocated.fullName ?? ""
  if (allocated.__typename === "Location") return allocated.label ?? ""
  if (allocated.__typename !== "CustomFieldValue") return ""
  // @ts-ignore
  if (allocated.fieldType !== CustomFieldField.Currency) return allocated.value ?? ""

  const value = safeNumber(allocated.value, { fallback: null, from: "currency" })
  if (typeof value !== "number") return ""

  return formatCurrency({ value, omitSymbol: false })
}

/** Based on app/views/v2/positions/form/position_management_tabs/_allocations.html.slim */
function AllocationsTab() {
  const { t } = useTranslation()
  const { fields: segmentedAllocationAmounts } =
    useAllocationAmountsFieldArrayInGroupsByAllocationType()

  if (segmentedAllocationAmounts.length === 0) {
    return (
      <div id="allocations-tab" className="tab-panel p-4">
        {t("v2.positions.form.position_management_tabs.allocations.select_allocate_by")}{" "}
        <a href={UrlHelper.positionSettingsPath()}>
          {t("v2.positions.form.position_management_tabs.allocations.settings_link")}
        </a>
      </div>
    )
  }

  return (
    <div id="allocations-tab" className="tab-panel p-4">
      {segmentedAllocationAmounts?.map(({ allocationType, anchorIndex, fields }, index) => (
        <AllocationTypesForm
          anchorIndex={anchorIndex}
          allocationType={allocationType}
          fields={fields}
          index={index}
          key={allocationType.id}
        />
      ))}
    </div>
  )
}

type AllocationTypesFormProps = {
  anchorIndex: number
  allocationType: AllocationType
  fields: AllocationAmountFieldWithFormId[]
  index: number
}

function AllocationTypesForm({
  allocationType,
  anchorIndex,
  fields,
  index,
}: AllocationTypesFormProps) {
  const { t } = useTranslation()
  const { tLabel } = useFieldLabelHelpers()
  const {
    formState: { errors },
  } = usePositionFormContext()
  const allocationErrorRaw = errors.position?.allocationTypes?.[index]?.id?.message

  const requiresAdpInfo = allocationType.isAdpAllocation
  const hasAllocationError = Boolean(allocationErrorRaw)
  const allocationError = t("v2.positions.form.allocation_types_form.invalid_total")

  const amounts = usePositionWatchField({ name: "position.allocationAmountsAttributes" })
  const total =
    amounts
      ?.filter(({ allocationType: { id } }) => id === allocationType.id)
      ?.reduce((memo, { percent }) => safeNumber(percent) + memo, 0) ?? 0

  return (
    <div
      className={cn(
        "allocation-type-table mb-4 rounded-md-sm border border-solid border-neutral-8 bg-transparent",
        `allocation-type-${allocationType.id}`,
      )}
      data-allocation-type-id={allocationType.id}
    >
      <div className="items-center justify-between border-0 border-b border-solid border-neutral-8 p-4 font-semibold flex">
        {t("v2.allocation_types.form.allocate_by_type_label", { label: allocationType.label })}
      </div>
      <div className="p-4">
        <AllocationTypeSegmentError index={index} />

        {requiresAdpInfo && (
          <div className="alert alert-info mb-4 items-start gap-2 flex">
            <FontAwesomeIcon icon={["far", "exclamation-circle"]} className="mt-1" />
            <p>{t("v2.positions.form.allocation_types_form.adp_info")}</p>
          </div>
        )}

        <div className="allocation-types mt-[-1rem] flex-col flex">
          {fields.map((field, index) => (
            <AllocationAmountsForm entity={field} index={anchorIndex + index} key={field.formId} />
          ))}
        </div>

        {requiresAdpInfo && (
          <LegacyDateInput
            id="position_allocation_effective_date"
            name="position.allocationEffectiveDate"
            label={tLabel("position.allocationEffectiveDate")}
            wrapperClassName="mt-4"
          />
        )}
      </div>
      <div className="items-center gap-4 border-0 border-t border-solid border-neutral-8 bg-neutral-3 p-4 grid sm:grid-cols-3">
        <h3 className="sm:col-span-2">Total</h3>
        <div className={cn("ml-6 space-x-1", hasAllocationError && "!text-status-critical")}>
          {hasAllocationError && (
            <div className="items-center justify-between flex">
              <a className="tooltip tooltip-center" data-stats-toggle="actual">
                <FontAwesomeIcon
                  icon={["far", "exclamation-circle"]}
                  className="justify-self-start !text-status-critical"
                />
                <div className="tooltip-content tooltip-content--light">
                  <div className="items-center flex">
                    <FontAwesomeIcon
                      icon={["far", "exclamation-circle"]}
                      className="mr-2 !text-status-critical"
                    />
                    <p className="text-status-critical">{allocationError}</p>
                  </div>
                </div>
              </a>
              <span className="mr-2 items-center flex">
                <p className={cn("mr-2", `total-percent-text-${allocationType.id}`)}>{total}</p>
                <FontAwesomeIcon icon={["far", "percent"]} />
              </span>
            </div>
          )}
          {!hasAllocationError && (
            <div className="mr-2 items-center justify-end flex">
              <p className={cn("mr-2", `total-percent-text-${allocationType.id}`)}>{total}</p>
              <FontAwesomeIcon icon={["far", "percent"]} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const AllocationAmountsForm = ({
  entity: allocationAmount,
  index,
}: EntityWithIndex<AllocationAmountFieldWithFormId>) => {
  const { t } = useTranslation()

  const { allocated, allocationType } = allocationAmount
  const { isAdpAllocation } = allocationType
  const {
    positionFormCollections: { orgUnitTypes },
    setValue,
  } = usePositionFormContext()

  // Dynamically set the position number of this allocation amount if it
  // represents the currently configured cost number for the position *and* the
  // allocation type is considered an adp allocation.
  //
  // Next line is a little hacky...used to match on cost number regardless of
  // locale.
  const costNumberName = t("v2.defaults.org_unit_type.system_types.cost_number")
  const costNumberIndex = orgUnitTypes.findIndex(
    ({ label }) => label.toLowerCase() === costNumberName.toLowerCase(),
  )
  const costNumberId = usePositionWatchField({
    name: `position.orgUnitsAttributes.${costNumberIndex}.id`,
  })
  const isForActiveCostNumber =
    allocated.__typename === "OrgUnit" && allocated.id === `org_unit_${costNumberId}`
  useEffect(() => {
    if (isAdpAllocation && isForActiveCostNumber)
      setValue(`position.allocationAmountsAttributes.${index}.positionNumber`, "1")
  }, [setValue, index, isAdpAllocation, isForActiveCostNumber])

  return (
    <div
      className="allocation-amount-form grid-cols-2 items-start gap-4 space-y-4 grid"
      data-index={index}
      data-allocation-amount-row=""
    >
      {/* Layout hack? WTF. This ensures the labels center cause yeah. */}
      <input type="hidden" />

      <div className="allocation-amount-name-wrapper w-full leading-10">
        {/* The after-related styling here is a hack to prevent */}
        {/* Safari from rendering the native tooltip when there's text overflow. */}
        {/* See: https://stackoverflow.com/a/43915246 */}
        <div className="allocation-amount-name w-full truncate after:content-[''] after:block">
          {getAllocatedLabel(allocated)}
        </div>
      </div>

      <div className={cn("input-group items-end", false && "form-error")}>
        <div className="gap-4 flex">
          {allocationType.isAdpAllocation ? (
            <div className="relative w-32">
              <TextInput
                defaultValue={allocationAmount?.positionNumber ?? undefined}
                readOnly={isForActiveCostNumber}
                id={`position_allocation_amounts_attributes_${index}_position_number`}
                name={`position.allocationAmountsAttributes.${index}.positionNumber`}
                placeholder={t(
                  "v2.positions.form.allocation_amounts_form.position_number_placeholder",
                )}
                useInReactHookForm
              />
            </div>
          ) : null}

          <div className="relative w-32">
            <PercentInput
              id={`position_allocation_amounts_attributes_${index}_percent`}
              name={`position.allocationAmountsAttributes.${index}.percent`}
              inputClassName="text-right"
              useInReactHookForm
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function AllocationTypeSegmentError({ index }: AllocationTypeSegmentErrorProps) {
  const {
    formState: { errors },
  } = usePositionFormContext()
  const allocationError = errors.position?.allocationTypes?.[index]?.id?.message

  return allocationError ? (
    <div className="alert alert-critical form-error mb-4 items-center gap-2 flex">
      <FontAwesomeIcon icon={["far", "exclamation-circle"]} />
      <p>{allocationError}</p>
    </div>
  ) : null
}

/**
 * Server/client communication uses a flat array of allocation amounts, but we
 * present these in the UI grouped by allocation type. This hook returns an
 * array of hashes, one per allocation type. Each of these hashes embeds a list
 * of allocation amounts, provides an anchor index, and includes a reference to
 * the allocation type.
 *
 * When building field names, fetching errors, etc. use the result of
 * `anchorIndex` + index of amount in the nested fields array.
 */
function useAllocationAmountsFieldArrayInGroupsByAllocationType() {
  const allocationAmountAttributes = usePositionFieldArray({
    name: "position.allocationAmountsAttributes",
    keyName: "formId",
  })
  const fields = allocationAmountAttributes.fields

  const segmentedFields = useMemo(() => {
    const groupedByAllocationTypeId = fp.groupBy(fp.prop(["allocationType", "id"]), fields)

    const orderedAllocationTypeIdsWithAnchors = fp.uniqBy(
      fp.prop(["allocationType", "id"]),
      fields.map(({ allocationType }, index) => ({ allocationType, index })),
    )

    return orderedAllocationTypeIdsWithAnchors.map(({ allocationType, index: anchorIndex }) => ({
      allocationType,
      anchorIndex,
      fields: groupedByAllocationTypeId[allocationType.id] ?? [],
    }))
  }, [fields])

  return {
    ...allocationAmountAttributes,
    fields: segmentedFields,
  }
}

export { AllocationsTab }
