import barsClosed from "images/bars-closed.svg"
import barsOpen from "images/bars-open.svg"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { withRootProvider } from "v2/react/components/RootProvider"
import { useNavController } from "v2/react/shared/navigation/useNavController"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"

function NavToggleContent() {
  const [showTooltip, setShowTooltip] = useState(false)
  const { toggleNav, nav } = useNavController()
  const { t } = useTranslation()

  const handleClick = () => {
    toggleNav()
    setShowTooltip(false)
  }

  return (
    <Tooltip open={showTooltip} placement="right">
      <TooltipTrigger
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        className="w-full block"
      >
        <button
          onClick={handleClick}
          type="button"
          className="btn--icon btn--ghost nav-toggle group hover:bg-neutral-3"
        >
          <div className="nav-toggle--inner">
            <img
              src={barsOpen}
              alt="Navigation Toggle"
              className="open-icon opacity-[.64] group-hover:opacity-100"
            />
            <img
              src={barsClosed}
              alt="Navigation Toggle"
              className="closed-icon opacity-[.64] group-hover:opacity-100"
            />
          </div>
        </button>
      </TooltipTrigger>
      <TooltipContent className="react-tooltip-content--sm">
        <span className="break-all">
          {nav?.classList.contains("shrink-nav")
            ? t("v2.shared.nav.nav_closed_tooltip")
            : t("v2.shared.nav.nav_open_tooltip")}
        </span>
      </TooltipContent>
    </Tooltip>
  )
}

const NavToggle = withRootProvider(NavToggleContent, "NavToggle")

export { NavToggle }
