import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"

import { Candidate } from "types/graphql"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"

interface SuccessionPlanCandidateProps {
  candidate: Candidate
}

function SuccessionPlanCandidate({ candidate }: SuccessionPlanCandidateProps) {
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <Tooltip open={showTooltip}>
      <TooltipTrigger
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <li>
          {candidate.withinOrg && candidate.person?.avatarThumbUrl ? (
            <img src={candidate.person?.avatarThumbUrl} alt="Candidate Avatar" />
          ) : (
            <FontAwesomeIcon icon={["far", "briefcase"]} />
          )}
        </li>
      </TooltipTrigger>
      <TooltipContent className="react-tooltip-content">
        {candidate.person?.name || candidate.outsiderName}
      </TooltipContent>
    </Tooltip>
  )
}

export { SuccessionPlanCandidate }
