import {
  CursorState,
  type CellCursor as Cursor,
  type CursorOnEditableCell as OnEditable,
  type CursorOnEditableCellTransitionNext as OnEditableTransitionNext,
  type CursorOnNonEditableCell as OnNonEditable,
  type CursorUnit as OnNothing,
  type CursorWritingOnEditableCell as WritingOnEditable,
  type CursorWritingOnEditableCellWithInitial as WritingOnEditableWithInitial,
} from "v2/redux/slices/GridSlice/cursor/types"

type State = CursorState

const constantNewCellStates: State[] = [
  CursorState.OnEditable,
  CursorState.OnNonEditable,
  CursorState.Unit,
]
export const transitions: { [key in State]: { sameCell: State[]; newCell: State[] } } = {
  [CursorState.Unit]: {
    sameCell: [],
    newCell: [CursorState.OnEditable, CursorState.OnNonEditable],
  },
  [CursorState.OnEditable]: {
    sameCell: [CursorState.WritingOnEditable, CursorState.WritingOnEditableWithInitial],
    newCell: constantNewCellStates,
  },
  [CursorState.OnEditableTransitionNext]: {
    sameCell: [CursorState.WritingOnEditable, CursorState.WritingOnEditableWithInitial],
    newCell: constantNewCellStates,
  },
  [CursorState.OnNonEditable]: {
    sameCell: [],
    newCell: constantNewCellStates,
  },
  [CursorState.WritingOnEditable]: {
    sameCell: [CursorState.OnEditable, CursorState.OnEditableTransitionNext],
    newCell: constantNewCellStates,
  },
  [CursorState.WritingOnEditableWithInitial]: {
    sameCell: [CursorState.OnEditableTransitionNext, CursorState.OnEditable],
    newCell: constantNewCellStates,
  },
}

// String predicates
export const matchUnit = (s: string) => s === CursorState.Unit
export const matchOnNonEditable = (s: string) => s === CursorState.OnNonEditable
export const matchOnEditable = (s: string) => s === CursorState.OnEditable
export const matchOnEditableTransitionNext = (s: string) =>
  s === CursorState.OnEditableTransitionNext
export const matchWritingOnEditable = (s: string) => s === CursorState.WritingOnEditable
export const matchWritingOnEditableWithInitial = (s: string) =>
  s === CursorState.WritingOnEditableWithInitial

// State predicates (as type predicates)
export const onNothing = (c: Cursor): c is OnNothing => matchUnit(c.state)
export const onNonEditableCell = (c: Cursor): c is OnNonEditable => matchOnNonEditable(c.state)
export const onEditableCell = (c: Cursor): c is OnEditable => matchOnEditable(c.state)
export const onEditableCellTransitionNext = (c: Cursor): c is OnEditableTransitionNext =>
  matchOnEditableTransitionNext(c.state)
export const writingOnEditableCell = (c: Cursor): c is WritingOnEditable =>
  matchWritingOnEditable(c.state)
export const writingOnEditableCellWithInitial = (c: Cursor): c is WritingOnEditableWithInitial =>
  matchWritingOnEditableWithInitial(c.state)

// State union predicates
export const inEitherWriteState = (
  c: Cursor,
): c is WritingOnEditable | WritingOnEditableWithInitial =>
  writingOnEditableCell(c) || writingOnEditableCellWithInitial(c)
export const inEitherReadOnEditable = (c: Cursor): c is OnEditable | OnEditableTransitionNext =>
  onEditableCell(c) || onEditableCellTransitionNext(c)

/** @returns a value indicating whether two cursors are on the same thing. */
export const onSame = (lhs: Cursor, rhs: Cursor) => {
  if (onNothing(lhs) && onNothing(rhs)) return true
  if (onNothing(lhs) || onNothing(rhs)) return false
  return lhs.rowId === rhs.rowId && lhs.fieldKey === rhs.fieldKey
}

/** @returns a value indicating whether two cursors are on different things. */
export const onDifferent = (lhs: Cursor, rhs: Cursor) => !onSame(lhs, rhs)

/** @returns a value indicating whether the transition from `current` to `next` is valid. */
export const transitionValid = (current: Cursor, next: Cursor) => {
  const { sameCell: sameCellStates, newCell: newCellStates } = transitions[current.state]

  // For convenience, a transition w/ the same cell and state is ok.
  let transitionOk = onSame(current, next) && current.state === next.state
  transitionOk ||= onSame(current, next) && sameCellStates.includes(next.state)
  transitionOk ||= onDifferent(current, next) && newCellStates.includes(next.state)

  return transitionOk
}
