import type {
  AssetsTableSettingsQuery,
  AssetsTableSettingsQueryVariables,
  UpdatePersonAssetsTableSettingsInput,
  UpdatePersonAssetsTableSettingsMutation,
} from "types/graphql"
import OperationStore from "v2/operation_store"
import { GraphqlApi } from "v2/redux/GraphqlApi"
import { queryOperation } from "v2/redux/utils/endpoints"

export const AssetsApi = GraphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    getAssetsTableSettings: builder.query<
      AssetsTableSettingsQuery,
      AssetsTableSettingsQueryVariables
    >({
      query: queryOperation<AssetsTableSettingsQueryVariables>("AssetsTableSettings"),
      providesTags: ["AssetsTableSettings"],
    }),
    updatePersonAssetsTableSettings: builder.mutation<
      UpdatePersonAssetsTableSettingsMutation,
      UpdatePersonAssetsTableSettingsInput
    >({
      query: (input) => ({
        operationId: OperationStore.getOperationId("UpdatePersonAssetsTableSettings"),
        variables: { input },
      }),
      invalidatesTags: ["AssetsTableSettings"],
    }),
  }),
})

export const { useGetAssetsTableSettingsQuery, useUpdatePersonAssetsTableSettingsMutation } =
  AssetsApi
