import React, { useRef } from "react"

import { colorWheel } from "v2/react/components/successionSettings/palettes"
import { ColorPicker } from "v2/react/shared/forms/ColorPicker"
import { ReferenceColorCode } from "v2/redux/slices/VisualizationSlice/types"
import { selectColorFieldByActiveTab } from "v2/redux/slices/VisualizationSlice/visualizationSelectors"
import { asyncUpdateColorInCode } from "v2/redux/slices/VisualizationSlice/visualizationThunks"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

interface ColorCodeProps {
  color: string
  name: string
  editColor: (domPosition: { top: number; left: number }, color: string) => void
  style: React.CSSProperties
  themeOption: ReferenceColorCode
}

function ColorCode({ color, name, editColor, style, themeOption }: ColorCodeProps) {
  const dispatch = useAppDispatch()
  const divRef = useRef<HTMLDivElement>(null)
  const field = useAppSelector(selectColorFieldByActiveTab)
  const type = useAppSelector((state) => state.visualization.activeColorCodingTab)

  const onSubmit = (color: string) => {
    const ref = divRef.current
    if (!ref || !field) return

    const top = ref.getBoundingClientRect().top
    const position = { top, left: ref.clientWidth }
    editColor(position, color)
    dispatch(asyncUpdateColorInCode({ type, field, model: themeOption, color }))
  }

  return (
    <ColorPicker
      aria-label={name}
      colors={colorWheel}
      currentColor={color}
      dropdownPlacement="top-start"
      includeNoColorOption
      noColorHex="#ffffff"
      onSubmit={onSubmit}
      popoverTriggerActiveClassNames="!bg-neutral-8"
      popoverTriggerClassNames="color-coding-row m-0 rounded bg-transparent px-1 py-0.5 hover:bg-neutral-8"
      style={style}
    >
      <div className="items-center gap-2 flex" ref={divRef}>
        <div
          className="color-box border--main h-4 w-4 rounded border-neutral-8"
          style={{ backgroundColor: color }}
        />
        <span className="color-coding-color-label text-base">{name}</span>
      </div>
    </ColorPicker>
  )
}

export { ColorCode, ColorCodeProps }
