import { defaultMemoize } from "reselect"
import _ from "underscore"

import exportStyles from "../constants/exportStyles"
import { globals } from "../constants/options"
import { circleRadius } from "../node/peopleDots"
import { hasStats } from "./statsHelpers"

// Canvas used to measure text
const canvas = document.createElement("canvas")
const canvasContext = canvas.getContext("2d")

export const measureText = defaultMemoize((text, style = "text") => {
  canvasContext.font = [
    exportStyles[style]["font-weight"],
    exportStyles[style]["font-size"],
    exportStyles[style]["font-family"],
  ].join(" ")
  return canvasContext.measureText(text)
})

// Measuring svg text using getComputedTextLength triggers a render for each
// call, which makes it pretty slow. This is much faster.
export const measureTextInElement = (element, style = "text") => {
  if (element.textContent) {
    return measureText(element.textContent, style).width
  }
  return 0
}

function NodePositioningHelper(
  displayFields,
  chartOptions,
  extraLinesMap = {},
  forCompany = false,
) {
  let positionedFieldsList = []
  this.displayFields = displayFields
  this.chartOptions = chartOptions
  this.extraLinesMap = extraLinesMap
  this.shouldDisplayRole = false
  let lastSeenDisplayFields = null
  let extraFieldsCached = null

  const getExtraFields = () => {
    if (lastSeenDisplayFields === this.displayFields && extraFieldsCached) return extraFieldsCached

    lastSeenDisplayFields = this.displayFields
    extraFieldsCached = _.reject(this.displayFields, (field) =>
      _.includes(globals.defaultFields, field),
    )
    return extraFieldsCached
  }

  const shouldDisplayField = (field) => _.includes(this.displayFields, field)

  const setHeightsOfAllSelectedDisplayFields = () => {
    // First, handle special fields (Avatar, Name, and Title)
    if (shouldDisplayField("avatar")) {
      positionedFieldsList.push({
        name: "avatar",
        height: globals.avatarHeight,
        marginBottom: globals.fieldDefaultBottomSpacing,
        offsettYToBaseline: false,
      })
    }

    if (shouldDisplayField("name")) {
      positionedFieldsList.push({
        name: "name",
        height: globals.nameTextFieldHeight,
        marginBottom: globals.nameTextFieldMarginBottom,
        offsettYToBaseline: true,
      })
    }

    if (shouldDisplayField("title")) {
      const titleExtraLineHeight = 11.25
      const wrappedLineExtraHeight = titleExtraLineHeight * (this.extraLinesMap.title ?? 0)

      positionedFieldsList.push({
        name: "title",
        height: globals.titleTextFieldHeight + wrappedLineExtraHeight,
        wrappedLineExtraHeight,
        marginBottom: globals.nameTextFieldMarginBottom,
        offsettYToBaseline: true,
      })
    }

    const extraFields = getExtraFields()

    if (positionedFieldsList.length > 0 && !forCompany && extraFields.length > 0) {
      const previousField = positionedFieldsList.slice(-1)[0]
      let topMargin = globals.topDividerMarginY

      // We are accounting for baseline text alignment here.
      if (previousField.offsetYToBaseline === false) {
        topMargin += previousField.marginBottom * 2
      }

      // Tracking divider lines to more easily place the "extra" fields.
      positionedFieldsList.push({
        name: "divider-top",
        // Subtract the previous field's margin bottom value so we're not
        // doubling the spacing
        height: 1 + topMargin,
        marginBottom: globals.topDividerMarginY,
        offsettYToBaseline: true,
      })
    }

    extraFields.forEach((field, i) => {
      const shouldMarginBottom = i < extraFields.length - 1

      positionedFieldsList.push({
        name: field,
        height: globals.extraFieldBaseHeight,
        marginBottom: shouldMarginBottom ? globals.extraFieldMarginBottom : 0,
        offsettYToBaseline: true,
      })
    })
  }

  setHeightsOfAllSelectedDisplayFields()

  this.topSectionHeight = () =>
    this.displayedFieldHeight("avatar") +
    this.displayedFieldHeight("name") +
    this.displayedFieldHeight("title") +
    this.displayedFieldHeight("divider-top")

  const setOffsetsInPositionedFieldsList = () => {
    // Track previousTransformedField so that we can reference its offset
    // values in the .map() below.
    let previousTransformedField = null

    // Set y, bottomOffset, and topOffset for all fields
    positionedFieldsList = positionedFieldsList.map((field) => {
      let y = previousTransformedField ? previousTransformedField.bottomOffset : globals.nodePadding
      const bottomOffset = y + field.height + field.marginBottom
      const topOffset = y

      if (field.offsettYToBaseline === true) {
        y += field.height
      }

      if (field.wrappedLineExtraHeight) {
        y -= field.wrappedLineExtraHeight
      }

      previousTransformedField = {
        y,
        bottomOffset,
        topOffset,
        ...field,
      }

      return previousTransformedField
    })
  }

  setOffsetsInPositionedFieldsList()

  this.fieldPositionInfo = (fieldName) =>
    positionedFieldsList.find((listItem) => listItem.name === fieldName) ?? {
      yOffset: 0,
      name: null,
    }

  this.displayedFieldHeight = (fieldName) => {
    const field = this.fieldPositionInfo(fieldName)
    if (!field) return 0

    return field.height + field.marginBottom
  }

  this.getFieldYPosition = (field) => {
    const info = this.fieldPositionInfo(field)

    return info.y ?? 0
  }

  // This should reflect the total height of the card element, including stats
  // bars, padding, all displayed/wrapped fields, etc.
  this.getNodeHeight = () =>
    positionedFieldsList.slice(-1)[0].bottomOffset +
    this.statsBarHeight() +
    this.shouldDisplayRole * 16 +
    (getExtraFields().length > 1 ? globals.topDividerMarginY : globals.nodePadding)

  this.getNodeWidth = () => globals.nodeWidth

  this.statsBarHeight = () =>
    (this.chartOptions.displayMode !== "cards" && !forCompany) * globals.statsBars.height

  this.getPositionedFieldsList = () => positionedFieldsList ?? []

  this.getNodeHeightInContext = (d) => {
    if (!hasStats(d)) {
      return (
        this.getNodeHeight() -
        this.statsBarHeight() -
        // Ensure consistent spacing between node and and stats bar end. There
        // is a different bottom offset between text and the stats bar line vs
        // text and the bottom of the card.
        globals.topDividerMarginY +
        ((d.people_ids?.length ?? 0) > 1 ? circleRadius * 2 : 0) +
        globals.nodePadding
      )
    }

    return this.getNodeHeight()
  }

  this.getPeopleDotsYOffset = (d) => {
    const dividerTopY = this.getFieldYPosition("divider-top")

    if (dividerTopY) {
      return dividerTopY
    }

    let yOffsetFromBottomOfCard = 0
    // No stats, then place it near the bottom of the card
    if (!hasStats(d)) {
      yOffsetFromBottomOfCard += globals.nodePadding
    } else {
      yOffsetFromBottomOfCard += this.statsBarHeight() - 2
    }

    return this.getNodeHeightInContext(d) - yOffsetFromBottomOfCard
  }

  this.cardYPosition = () => 0
  this.nodeYPosition = () => 0

  this.measureTextInElement = measureTextInElement
  this.measureText = measureText

  this.getCardVerticalCenter = () => {
    let statsBarHeight = 0
    if (this.chartOptions.displayMode === "three_level") {
      statsBarHeight += globals.statsBars.height
    }
    return (
      (this.getNodeHeight() + statsBarHeight - globals.nodePadding - globals.linkStrokeWidth) / 2
    )
  }
}

export default NodePositioningHelper
