import React, { FormEvent, useState } from "react"
import { useTranslation } from "react-i18next"

import { Error, PayGrade } from "types/graphql"
import {
  createCurrencyOptions,
  CurrencyDropdown,
  CurrencyOption,
} from "v2/react/shared/forms/CurrencyDropdown"
import { CurrencyInput } from "v2/react/shared/forms/CurrencyInput"
import { InputErrorText } from "v2/react/shared/forms/InputErrorText"
import { InputWrapper } from "v2/react/shared/forms/InputWrapper"
import { Select } from "v2/react/shared/forms/Select"
import { TextInput } from "v2/react/shared/forms/TextInput"
import { minLessThanMax } from "v2/react/shared/forms/utils/validation"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import { SaveFooter } from "v2/react/shared/overlay/Modal"
import { useGetCurrencyCodeQuery } from "v2/redux/GraphqlApi"

interface Props {
  errors: Error[]
  handleClose: () => void
  handleSubmit: (event: FormEvent) => void
  isLoading: boolean
  payGrade?: PayGrade
  setErrors: React.Dispatch<React.SetStateAction<Error[]>>
}

function PayGradeForm({
  errors,
  handleClose,
  handleSubmit,
  isLoading,
  payGrade,
  setErrors,
}: Props) {
  const { t } = useTranslation()
  const { data, isLoading: isLoadingCurrencies } = useGetCurrencyCodeQuery({})
  const company = data?.currentCompany || null
  const [currentCurrencyIcon, setCurrentCurrencyIcon] = useState<string>()

  const validate = (event: FormEvent<Element>) => {
    event.preventDefault()
    let validationErrors: Error[] = []

    const form = event.target as HTMLFormElement
    const formData = new FormData(form)
    if (!formData.get("code")) {
      validationErrors = [
        ...validationErrors,
        { path: ["code"], message: t("v2.defaults.blank_validation") },
      ]
    }

    if (
      formData.get("min") &&
      formData.get("max") &&
      !minLessThanMax(Number(formData.get("min")), Number(formData.get("max")))
    ) {
      validationErrors = [
        ...validationErrors,
        { path: ["min"], message: t("v2.errors.messages.min_lt_max") },
      ]
    }

    if (
      (formData.get("min") && !formData.get("max")) ||
      (!formData.get("min") && formData.get("max"))
    ) {
      validationErrors = [
        ...validationErrors,
        { path: ["min"], message: t("v2.errors.messages.min_and_max") },
      ]
    }

    if (validationErrors.length > 0) {
      setErrors(validationErrors)
    } else {
      handleSubmit(event)
    }
  }

  const retrieveErrorFor = (path: string) => {
    const error = errors?.find((element) => element?.path && element.path[0] === path)
    if (error) return error.message
    return ""
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const onSelection = (option: CurrencyOption, changedFromInitial: boolean) => {
    setCurrentCurrencyIcon(option.icon)
  }

  if (isLoadingCurrencies || !company)
    return (
      <div className="relative py-8">
        <Spinner />
      </div>
    )

  const options = createCurrencyOptions(company.collections.currencies.options.nodes || [])

  const initialOption = options.find(
    (option) => option.value === (payGrade?.currencyCode || company.currencyCode),
  )
  const initialIcon = initialOption?.icon

  return (
    <form onSubmit={validate}>
      <div className="react-modal__body flex-col gap-2 flex">
        {retrieveErrorFor("general") && (
          <div className="alert alert-critical mt-4">
            <span>{retrieveErrorFor("general")}</span>
          </div>
        )}

        <section className="mb-4 grid-cols-4 gap-6 grid">
          <TextInput
            id="code"
            name="code"
            label={t("v2.pay_grades.fields.code")}
            errors={retrieveErrorFor("code")}
            defaultValue={payGrade?.code || ""}
            className="whitespace-nowrap"
          />
          <TextInput
            id="name"
            name="name"
            label={t("v2.pay_grades.fields.name")}
            className="col-span-3"
            errors={retrieveErrorFor("name")}
            defaultValue={payGrade?.name || ""}
          />
        </section>
        <InputWrapper
          className="input-group"
          id="period_type"
          label={t("v2.pay_grades.fields.period")}
        >
          <Select
            selected={payGrade?.periodType || ""}
            id="period_type"
            name="period_type"
            options={[
              { id: "yearly", label: t("v2.pay_grades.periods.yearly") },
              { id: "hourly", label: t("v2.pay_grades.periods.hourly") },
            ]}
          />
        </InputWrapper>
        <div className="grid-cols-[1fr_2fr] gap-6 grid">
          <InputWrapper id="currency_code" label={t("v2.pay_grades.fields.currency")}>
            <CurrencyDropdown
              initialCurrencyCode={payGrade?.currencyCode || company.currencyCode}
              onSelection={onSelection}
              selectionDisplay="value"
            />
          </InputWrapper>
          <div>
            <div className="gap-6 flex">
              <CurrencyInput
                defaultValue={payGrade?.minimum || ""}
                iconClass={currentCurrencyIcon ?? initialIcon}
                id="min"
                name="min"
                label={t("v2.pay_grades.fields.minimum")}
                errors={retrieveErrorFor("min")}
                showErrorMessage={false}
              />
              <CurrencyInput
                defaultValue={payGrade?.maximum || ""}
                iconClass={currentCurrencyIcon ?? initialIcon}
                id="max"
                name="max"
                label={t("v2.pay_grades.fields.maximum")}
                errors={retrieveErrorFor("min")}
                showErrorMessage={false}
              />
            </div>
            {retrieveErrorFor("min") ? <InputErrorText message={retrieveErrorFor("min")} /> : null}
          </div>
        </div>
      </div>
      <SaveFooter isSaving={isLoading} onCancel={handleClose} />
    </form>
  )
}

export { PayGradeForm }
