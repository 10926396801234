import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"

import sunburst, { type SunburstNode } from "v2/dashboards/charts/sunburst"

interface OpportunitiesSunburstProps {
  data: SunburstNode
  setSelectedJobFamily: React.Dispatch<React.SetStateAction<SunburstNode | null>>
}

// See: JobFamily::UNASSIGNED_JOB_FAMILY_KEY
const UNASSIGNED_JOB_FAMILY_KEY = "UNASSIGNED_KEY"

const OpportunitiesSunburst = ({ data, setSelectedJobFamily }: OpportunitiesSunburstProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()

  useEffect(() => {
    const chart = sunburst(data, {
      onNodeClick: setSelectedJobFamily,
      backButtonText: t("v2.opportunities.sunburst.back_button"),
      totalNodeCountText: t("v2.opportunities.sunburst.job_definition_count", {
        count: data.totalNestedNodes,
      }),
      tooltipNodeCountText: t("v2.opportunities.sunburst.total_jobs"),
    })

    containerRef.current?.append(chart)
    return () => {
      if (chart.parentNode) {
        chart.parentNode.removeChild(chart)
      }
    }
  }, [data, t, setSelectedJobFamily])

  // Ensures that we hide the tooltip when the user scrolls the page.
  useEffect(() => {
    const scrollableElement = document.getElementsByClassName("main-content")[0]

    const handleScroll = () => {
      const tooltip = document.getElementsByClassName("sunburst-tooltip")[0] as HTMLElement
      if (tooltip) {
        tooltip.style.opacity = "0"
      }
    }

    scrollableElement?.addEventListener("scroll", handleScroll)

    return () => {
      scrollableElement?.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <div className="w-full max-w-4xl items-center justify-center self-center overflow-hidden flex sm:mt-[-50px] md:mt-[-100px]">
      <div ref={containerRef} className="h-full w-full" id="job-family-sunburst" />
    </div>
  )
}

export { OpportunitiesSunburst, UNASSIGNED_JOB_FAMILY_KEY }
