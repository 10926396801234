import fp from "lodash/fp"
import { useMemo } from "react"

import { GqlQueryError } from "v2/graphqlClient/types"
import { errorHash } from "v2/react/utils/errors"

type HashOfAllMutationErrors = {
  getFieldError: (path: string | string[]) => string | undefined
  isEmpty: boolean
  fieldErrors: { [key: string]: string | HashOfAllMutationErrors["fieldErrors"] }
  rootErrors: string[]
}
type MutationErrors = GqlQueryError | undefined | null
type SafeError = { message: string; path?: string[] | null | undefined }

/**
 * Normalizes root and inline errors into a flat list of error messages.
 */
const useAllMutationErrors = (rootErrors: MutationErrors, inlineErrors: MutationErrors) =>
  useMemo(
    () => allErrorsWithAMessage(rootErrors, inlineErrors).map(({ message }) => message),
    [inlineErrors, rootErrors],
  )

/**
 * Combines root and inline errors from a mutation and uses them to build a
 * hash with two keys:
 *
 * - `rootErrors` - array of error messages that didn't have a non-empty path.
 * - `fieldErrors` - hash of error messages keyed by path (recursive).
 */
const useHashOfAllMutationErrors = (
  errorsFromRoot: MutationErrors,
  errorsFromMutationField: MutationErrors,
): HashOfAllMutationErrors =>
  useMemo(() => {
    const allErrors = allErrorsWithAMessage(errorsFromRoot, errorsFromMutationField)
    const fieldErrors = errorHash(allErrors)
    const rootErrors = allErrors.filter((err) => !err.path?.length).map(({ message }) => message)

    const getFieldError = (path: string | string[]) => {
      const actual = fp.prop(path, fieldErrors)
      return typeof actual === "string" ? actual : undefined
    }

    return {
      getFieldError,
      isEmpty: fp.isEmpty(rootErrors) && fp.isEmpty(fieldErrors),
      fieldErrors,
      rootErrors,
    }
  }, [errorsFromMutationField, errorsFromRoot])

const arrayWrap = (errors: MutationErrors) => {
  if (Array.isArray(errors)) return errors
  if (errors?.message) return [errors]
  return []
}

const allErrorsWithAMessage = (
  rootErrors: MutationErrors,
  inlineErrors: MutationErrors,
): SafeError[] => {
  const allErrors: SafeError[] = []

  arrayWrap(rootErrors).forEach(({ message, ...rest }) => {
    if (typeof message === "string") allErrors.push({ ...rest, message })
  })

  arrayWrap(inlineErrors).forEach(({ message, ...rest }) => {
    if (typeof message === "string") allErrors.push({ ...rest, message })
  })

  return allErrors
}

export { HashOfAllMutationErrors, useAllMutationErrors, useHashOfAllMutationErrors }
