import React, { useMemo } from "react"

import { FieldsetWithSearch } from "v2/react/shared/forms/FieldsetWithSearch"
import { useFieldsetWithSearch } from "v2/react/shared/forms/FieldsetWithSearch/hooks/useFieldsetWithSearch"

import { HeadcountPlanColumn } from "./types"

export function PlanColumns({ columns }: { columns: HeadcountPlanColumn[] }) {
  const hcpColumns = useMemo(
    () =>
      columns
        .filter((column) => !column.dependsOn)
        .map(({ name, isRemovable, ...rest }) => ({
          label: name,
          disabled: !isRemovable,
          ...rest,
        })),
    [columns],
  )

  const { selectedFields, unselectedFields, handleReorder, handleSearchSelect } =
    useFieldsetWithSearch({
      selectedFieldOptions: hcpColumns.filter((field) => field.isSelected),
      allFields: hcpColumns,
    })

  return (
    <div className="input-group">
      <FieldsetWithSearch
        label={"setup_columns_label".t("headcount_plan")}
        id="hcp-columns"
        selectedItems={selectedFields}
        searchableItems={unselectedFields}
        onReorder={handleReorder}
        onSearchSelect={handleSearchSelect}
        makeCheckboxName={() => "columns[]"}
      />
    </div>
  )
}
