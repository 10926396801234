import { parseDate } from "@internationalized/date"
import { useDateFormatter as ariaDateFormatter, type DateFormatterOptions } from "react-aria"

import { useTimeZone } from "v2/react/hooks/useTimeZone"

const useDateFormatter = (
  options: DateFormatterOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  },
) => {
  const timeZone = useTimeZone()
  const dateFormatter = ariaDateFormatter({ ...options, timeZone })

  /**
   * Parses the given value into a timezone-aware date and formats it in a
   * locale-aware manner.
   */
  const parseAndFormat = (value: string) => dateFormatter.format(parseDate(value).toDate(timeZone))

  return { dateFormatter, parseAndFormat }
}

type UseDateFormatter = ReturnType<typeof useDateFormatter>

export type { UseDateFormatter }
export { useDateFormatter }
