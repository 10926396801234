import { isEqual } from "lodash"
import { useCallback, useState } from "react"

import type { SortableItemType } from "v2/react/shared/forms/FieldsetWithSearch"

type Field = SortableItemType

interface Props<T extends Field> {
  /**
   * Pass in just the keys and the full options will be built using the
   * `allFields` prop.
   */
  selectedFieldKeys?: string[] | null
  selectedFieldOptions?: T[] | null
  allFields: T[] | null
}

const useFieldsetWithSearch = <T extends Field>({
  selectedFieldKeys,
  selectedFieldOptions,
  allFields,
}: Props<T>) => {
  const [selectedFields, setSelectedFields] = useState<T[]>(
    selectedFieldOptions ?? getInitialSelectedFields({ selectedFieldKeys, allFields }),
  )
  const [unselectedFields, setUnselectedFields] = useState<T[]>(
    allFields?.filter((field) => !selectedFields?.includes(field)) || [],
  )
  const [checkedFieldKeys, setCheckedFieldKeys] = useState(
    selectedFieldKeys ?? selectedFieldOptions?.map((field) => field.id) ?? [],
  )
  const [formKey, setFormKey] = useState(0)

  const fieldsChanged = !isEqual(checkedFieldKeys, selectedFieldKeys)

  const handleSearchSelect = (selectedField: T) => {
    setSelectedFields((prevSelectedFields) => [...prevSelectedFields, selectedField])
    setUnselectedFields((prevUnselectedFields) =>
      prevUnselectedFields.filter((field) => field.id !== selectedField.id),
    )
    setCheckedFieldKeys([...checkedFieldKeys, selectedField.id])
  }

  const handleReorder = (updatedFields: T[]) => {
    setSelectedFields(updatedFields)
    setCheckedFieldKeys(
      getCheckedFieldKeysInOrder({ selectedFields: updatedFields, checkedFieldKeys }),
    )
  }

  const handleFieldCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target

    if (checked) {
      setCheckedFieldKeys(
        getCheckedFieldKeysInOrder({
          selectedFields,
          checkedFieldKeys: [...checkedFieldKeys, name],
        }),
      )
    } else {
      setCheckedFieldKeys(checkedFieldKeys.filter((key) => key !== name))
    }
  }

  const resetState = useCallback(() => {
    const initialFields =
      selectedFieldOptions ?? getInitialSelectedFields({ selectedFieldKeys, allFields })
    setSelectedFields(initialFields)
    setCheckedFieldKeys(initialFields.map((field) => field.id))
    setUnselectedFields(allFields?.filter((field) => !initialFields?.includes(field)) || [])

    // We increment the form key in order to remount the form component, which
    // will reset the form checkboxes to the initial state. This is necessary
    // b/c the checkboxes aren't directly controlled.
    setFormKey((prev) => prev + 1)
  }, [selectedFieldKeys, selectedFieldOptions, allFields])

  return {
    selectedFields,
    unselectedFields,
    /**
     * Used to remount the form when the state is reset in order to repopulate
     * initial checkbox state when using uncontrolled checkboxes.
     */
    formKey,
    setSelectedFields,
    setUnselectedFields,
    handleSearchSelect,
    handleReorder,
    /**
     * Updates the checkedFieldKeys state, which is used to determine if the
     * selected fields have changed from the initial state. If not tracking
     * changes, there's no need to use this function.
     */
    handleFieldCheckboxChange,
    /**
     * Whether the checked selected fields have changed from the initial state.
     * In order for this to be accurate, be sure to use
     * `handleFieldCheckboxChange` & `handleSearchSelect` to update the
     * `checkedFieldKeys` state.
     */
    fieldsChanged,
    resetState,
  }
}

const getInitialSelectedFields = <T extends Field>({ selectedFieldKeys, allFields }: Props<T>) => {
  if (!selectedFieldKeys || !allFields) {
    return []
  }

  return allFields
    .filter((field) => selectedFieldKeys.includes(field.id))
    .sort((a, b) => selectedFieldKeys.indexOf(a.id) - selectedFieldKeys.indexOf(b.id))
}

const getCheckedFieldKeysInOrder = <T extends Field>({
  selectedFields,
  checkedFieldKeys,
}: {
  selectedFields: T[]
  checkedFieldKeys: string[]
}) =>
  selectedFields
    .map((field) => (checkedFieldKeys.includes(field.id) ? field.id : null))
    .filter(Boolean) as string[]

export { useFieldsetWithSearch }
