import React, { ReactNode } from "react"

interface LinkProps {
  to: string
  children: ReactNode
  newTab?: boolean
  classes?: string
}

const Link = ({ to, children, newTab, classes }: LinkProps) => {
  const rel = newTab ? "noopener noreferrer" : ""
  const target = newTab ? "_blank" : "_self"

  return (
    <a href={to} target={target} rel={rel} className={classes}>
      {children}
    </a>
  )
}

export { Link }
