import React from "react"

import { CompareValues } from "v2/react/components/headcountPlanning/TableDatasheet/CompareValues"
import { DatePickerCell as DatePickerCellPrimitive } from "v2/react/components/headcountPlanning/TableDatasheet/DatePickerCell"
import { StrikethroughCell } from "v2/react/components/headcountPlanning/TableDatasheet/StrikethroughCell"

import { useSaveCell } from "../hooks/useSaveCell"
import { EliminatePositionModal } from "./EliminatePositionModal"
import { HeadcountPlanDatasheetRow } from "./types"

type Props = {
  row: HeadcountPlanDatasheetRow
  headcountPlanId: string
  participantId?: string
  readOnly: boolean
}
export function EndDateCell({ row, participantId, headcountPlanId, readOnly }: Props) {
  const saveFn = useSaveCell(headcountPlanId, "end_date", row, participantId)
  const [modalContext, setModalContext] = React.useState<{ open: boolean; value?: string | null }>({
    open: false,
  })
  const currentValue = row.positionAttributesWithEdits.end_date ?? ""
  const compareValue =
    row.type === "modified" && "end_date" in row.payload ? row.positionAttributes.end_date : null

  if (row.excluded) {
    return <StrikethroughCell value={currentValue} />
  }

  if (readOnly) {
    return (
      <CompareValues compareValue={compareValue} className="non-editable-cell">
        <span>{currentValue}</span>
      </CompareValues>
    )
  }

  const openModalOnDateSelected = (value: string) => setModalContext({ open: true, value })

  return (
    <>
      {modalContext.open ? (
        <EliminatePositionModal
          initialEndDateValue={modalContext.value ?? ""}
          row={row}
          headcountPlanId={headcountPlanId}
          isOpen={modalContext.open}
          onClose={() => setModalContext({ open: false })}
          participantId={participantId}
        />
      ) : null}
      <DatePickerCellPrimitive
        allowBlank
        columnId="end_date"
        rowId={row.id}
        saveFn={saveFn}
        openModalOnDateSelected={row.type !== "new" ? openModalOnDateSelected : undefined}
        name="end_date"
        currentValue={currentValue}
        compareValue={compareValue}
      />
    </>
  )
}
