import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { useCurrentActiveSession } from "v2/react/hooks/useSessionHooks"
import { Modal, ModalFooter } from "v2/react/shared/overlay/Modal"
import {
  useCurrentCompanyPositionFormDataQuery,
  usePositionsGetForEditQuery,
} from "v2/redux/GraphqlApi/PositionsApi"

import { PositionData, PositionForm, useUpsertPositionSubmitHandler } from "./positionForm"

type UpsertModalProps = {
  chartKey: string
  isOpen: boolean
  onAfterClose?: () => void
  onClose: () => void
  parentKey?: string
  uniqueKey?: string
}

const FALLBACK_POSITION_DATA: PositionData = {}

function UpsertPositionModal({
  chartKey,
  isOpen,
  onAfterClose,
  onClose,
  parentKey,
  uniqueKey,
}: UpsertModalProps) {
  const { t } = useTranslation()

  // We don't want to immediately skip queries when the modal closes since that
  // effectively reverts `positionFormCollections` back to undefined and causes
  // the modal to unmount before it animates closed. We'll resume the "skip"
  // after the modal animates out.
  const [shouldSkip, setShouldSkip] = useState(!isOpen)

  const handleAfterClose = () => {
    setShouldSkip(true)
    onAfterClose?.()
  }

  // If `isOpen` changes to true, disable query skips.
  if (isOpen && shouldSkip) {
    setShouldSkip(false)
  }

  const { currentData: auxiliaryData } = useCurrentCompanyPositionFormDataQuery(
    {
      chartKey,
      parentKey,
      positionKey: uniqueKey,
    },
    { skip: shouldSkip },
  )
  const positionFormCollections = auxiliaryData?.positionFormCollections
  const { currentData: positionResult } = usePositionsGetForEditQuery(
    { positionId: uniqueKey ?? "", chartId: chartKey },
    { skip: !uniqueKey || shouldSkip },
  )
  const { hasFeature } = useCurrentActiveSession()
  const positionData = uniqueKey ? positionResult?.position : FALLBACK_POSITION_DATA
  const overlayRef = useRef<HTMLDivElement>(null)

  const saveLabel = uniqueKey ? t("v2.defaults.save") : t("v2.defaults.add")
  const savingLabel = uniqueKey ? t("v2.defaults.saving") : t("v2.defaults.adding")
  const { handleSubmit, isLoading } = useUpsertPositionSubmitHandler({
    onClose,
    positionFormCollections,
    uniqueKey,
  })

  useEffect(() => {
    if (!isOpen) return
    if (typeof window === "undefined") return
    if (typeof window.NProgress === "undefined") return

    if (positionFormCollections && positionData) {
      window.NProgress?.done?.()
    } else if (!window.NProgress?.isStarted?.()) {
      window.NProgress?.start?.()
    }
  }, [isOpen, positionFormCollections, positionData])

  return positionFormCollections && positionData ? (
    <Modal
      className="new-position-modal"
      isOpen={isOpen}
      onAfterClose={handleAfterClose}
      onClose={onClose}
      title={uniqueKey ? t("v2.positions.edit.modal_title") : t("v2.positions.new.modal_title")}
      size="md"
      overlayRef={overlayRef}
    >
      <PositionForm
        chartKey={chartKey}
        defaults={auxiliaryData.defaults}
        positionData={positionData ?? undefined}
        positionFormAbilities={{
          canShowCustomFieldsTab: Boolean(
            hasFeature("customFields") && positionFormCollections.customFields.length > 0,
          ),
          ...auxiliaryData.positionFormAbilities,
        }}
        positionFormCollections={positionFormCollections}
        onSubmit={handleSubmit}
      >
        <div className="flex-col gap-4 p-6 flex">
          <PositionForm.BaseFormErrors />

          <div className="flex-col flex">
            <PositionForm.PositionDetails />
            {hasFeature("positionManagement") && <PositionForm.PositionManagementTabs />}
            {!hasFeature("positionManagement") && <PositionForm.NonPositionManagementContent />}
          </div>
        </div>
        <hr className="my-0" />
        <ModalFooter
          className="justify-end px-6 py-3"
          disabled={isLoading}
          onClose={onClose}
          saveButtonText={isLoading ? savingLabel : saveLabel}
        />
      </PositionForm>
    </Modal>
  ) : null
}

export { UpsertPositionModal, UpsertModalProps }
