const HexRegex = Object.freeze(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i)

/** @todo See about re-using definitions from variables.scss */
const Colors = Object.freeze({
  LightMode: Object.freeze({
    /** Foreground represents text, borders, etc. */
    ForegroundColors: Object.freeze({
      Error: "rgba(167, 031, 082, 1)",
      /** A lighter grey, usually to indicate something is disabled. */
      Muted: "#c3c3c3",
      /**
       * Brand color, usually to indicate a user is interacting with
       * something.
       */
      Primary: "rgba(27, 98, 255, 1)",
    }),

    BackgroundColors: Object.freeze({
      Content: "#ffffff",
      Changed: "#e7f5fb",
    }),
  }),
})

/**
 * @returns a value indicating whether the hex value is dark.
 */
function isColorDark(hex: string) {
  // converting hex to rgb
  const test = hex.match(HexRegex)
  if (!test) return false

  const r = parseInt(test[1], 16)
  const g = parseInt(test[2], 16)
  const b = parseInt(test[3], 16)

  // Counting the perceptive luminance
  // human eye favors green color...
  const a = (0.299 * r + 0.587 * g + 0.114 * b) / 255 // eslint-disable-line no-mixed-operators
  return a <= 0.5
}

/**
 * @returns an rgba color when given a hex code and alpha value
 */
const convertHextoRGBA = (hexColor: string, alpha: number) => {
  const hex = hexColor.replace("#", "")

  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

export const { LightMode } = Colors
export { Colors, convertHextoRGBA, isColorDark }
