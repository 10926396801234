import React from "react"

import { HeadcountPlan, HeadcountPlanParticipant } from "types/graphql.d"

import { ChildStatusesBanner } from "../banners/ChildStatusesBanner"
import { ParticipantFinalizedBanner } from "../banners/ParticipantFinalizedBanner"
import { ProposalSubmittedBanner } from "../banners/ProposalSubmittedBanner"

type Props = {
  headcountPlan?: HeadcountPlan
  participant?: HeadcountPlanParticipant
  displayState: "approved" | "original"
  setDisplayState: (value: "approved" | "original") => void
}
export function ParticipantBanner({
  displayState,
  setDisplayState,
  headcountPlan,
  participant,
}: Props) {
  if (headcountPlan?.lockedAt) {
    return (
      <ParticipantFinalizedBanner
        displayState={displayState}
        setDisplayState={setDisplayState}
        positionsApproved={
          participant?.allPositionsInPlan?.filter(
            (position) => position.type === "new" && position.status === "approved",
          ).length ?? 0
        }
      />
    )
  }

  if (participant?.status === "complete") {
    return <ProposalSubmittedBanner participant={participant} />
  }

  return <ChildStatusesBanner participant={participant} />
}
