import { FloatingContext, FloatingFocusManager, FloatingPortal } from "@floating-ui/react"
import React, { CSSProperties, Ref } from "react"

interface Props {
  showList: boolean
  floatingRef: Ref<HTMLDivElement>
  floatingPortalId?: string
  floatingStyles: CSSProperties
  floatingProps: (userProps?: React.HTMLProps<HTMLElement> | undefined) => Record<string, unknown>
  wrapperClasses: string
  context: FloatingContext<HTMLInputElement>
  children: React.ReactNode | React.ReactNode[] | null
}

function DropdownMenu({
  showList,
  floatingRef,
  floatingPortalId,
  floatingStyles,
  floatingProps,
  wrapperClasses,
  context,
  children,
}: Props) {
  return (
    showList && (
      <FloatingPortal id={floatingPortalId}>
        <FloatingFocusManager
          context={context}
          initialFocus={-1}
          visuallyHiddenDismiss
          returnFocus={false}
        >
          <div
            ref={floatingRef}
            style={floatingStyles}
            className={wrapperClasses}
            /* eslint-disable react/jsx-props-no-spreading */
            {...floatingProps()}
            data-testid="dropdown-menu"
          >
            {children}
          </div>
        </FloatingFocusManager>
      </FloatingPortal>
    )
  )
}

export { DropdownMenu }
