import fp from "lodash/fp"
import { useEffect } from "react"

import { useAppDispatch } from "v2/redux/store"

import { ChannelSubscriptionHookArg } from "./types"
import { subscribe, unsubscribe } from "./utils"

export function useGraphqlChannelSubscription(subscriptionArg: ChannelSubscriptionHookArg) {
  const { localOnly, subjectId, args } = subscriptionArg
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!subjectId || !args || localOnly) return fp.noop

    dispatch(subscribe({ subjectId, args }))
    return () => dispatch(unsubscribe(subjectId))
  }, [dispatch, subjectId, localOnly, args])
}
