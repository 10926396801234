import { AnyAction } from "redux"

import { startAppListening } from "v2/redux/listenerMiddleware"
import {
  subscriptionInitialized,
  subscriptionUpdated,
  trackMessage,
  unsubscribed,
} from "v2/redux/slices/GraphqlCableSlice"
import { CableChannelSubscriptionArgs, ChannelState } from "v2/redux/slices/GraphqlCableSlice/types"
import { subscribe, unsubscribe } from "v2/redux/slices/GraphqlCableSlice/utils"

export const createChannelSubscription = <T = { [key in string]: AnyAction }>({
  dispatch,
  subjectId: id,
  args,
  initialized,
  connected,
  received,
  disconnected,
  rejected,
}: CableChannelSubscriptionArgs<T>) => {
  const subscription = consumer().subscriptions.create(
    { channel: "GraphqlChannel", id },
    {
      initialized() {
        dispatch(subscriptionInitialized({ subjectId: id, args, state: ChannelState.Initialized }))
        initialized?.()
      },
      connected() {
        dispatch(subscriptionUpdated({ id, changes: { state: ChannelState.Connected } }))
        this.perform("execute", { ...args, action: "execute" })
        connected?.()
      },
      received(message: { more: boolean; result: { data?: T } }) {
        received(message)
      },
      disconnected() {
        dispatch(subscriptionUpdated({ id, changes: { state: ChannelState.Disconnected } }))
        disconnected?.()
      },
      rejected() {
        dispatch(subscriptionUpdated({ id, changes: { state: ChannelState.Rejected } }))
        rejected?.()
      },
    },
  )

  return {
    ...subscription,
    unsubscribe: () => {
      subscription.unsubscribe()
      dispatch(unsubscribed(id))
    },
  }
}

export const startListeningForSubscribe = () =>
  startAppListening({
    actionCreator: subscribe,
    effect: async ({ payload: { subjectId, args, trackMessages } }, { condition, dispatch }) => {
      const cableSubscription = createChannelSubscription({
        dispatch,
        subjectId,
        args,
        received(message) {
          if (!message.result.data) return
          if (trackMessages) dispatch(trackMessage({ subjectId, message }))
          Object.entries(message.result.data).forEach(([, action]) => dispatch(action))
        },
      })

      await condition((action) => unsubscribe.match(action) && action.payload === subjectId)
      cableSubscription.unsubscribe()
      dispatch(unsubscribed(subjectId))
    },
  })

let consumerRef: ActionCable.Cable | undefined
const consumer = () => {
  if (!consumerRef) consumerRef = window.ActionCable.createConsumer("/cable")
  return consumerRef
}
