import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { motion } from "framer-motion"
import React from "react"
import { useTranslation } from "react-i18next"

import { opaqueScaleDelay } from "v2/react/utils/framerAnimationVariants"

function ApprovedBanner() {
  const { t } = useTranslation()

  return (
    <motion.div
      initial="hidden"
      animate="shown"
      variants={opaqueScaleDelay}
      className="banner alert--green items-center gap-1.5 rounded-none flex"
    >
      <FontAwesomeIcon icon={["far", "check-circle"]} />
      <p>{t("v2.position_types.show.ai_approved_banner")}</p>
    </motion.div>
  )
}

export { ApprovedBanner }
