import { Maybe } from "graphql/jsutils/Maybe"
import { isEmpty, isEqual } from "lodash"
import { useState } from "react"
import { useDebounce } from "usehooks-ts"

import { AUTOCOMPLETE_LIMIT } from "v2/react/constants"
import { useGetPositionAutocompleteQuery } from "v2/redux/GraphqlApi"

interface Props {
  filter: string
  chartKey: Maybe<string>
  exclude?: string
  excludeWithReqs?: boolean
  subordinateOnly?: boolean
  includeSelf?: boolean
}

const usePositionSearch = ({
  filter,
  chartKey,
  exclude,
  excludeWithReqs,
  subordinateOnly,
  includeSelf,
}: Props) => {
  const [returnEmpty, setReturnEmpty] = useState(false)

  const searchTerm = useDebounce(filter || "", 350)

  const { data, isFetching } = useGetPositionAutocompleteQuery(
    {
      searchTerm,
      chartKey,
      exclude,
      excludeWithReqs,
      subordinateOnly,
      includeSelf,
      first: AUTOCOMPLETE_LIMIT,
    },
    {
      skip: !searchTerm,
    },
  )

  const positions = data?.positions?.nodes || []

  // Handles the case where the input changes from empty to non-empty, and we
  // need to return an empty list of options until the fresh data returns.
  if (isEmpty(filter) && !returnEmpty) {
    setReturnEmpty(true)
  } else if (!isEmpty(filter) && isEqual(filter, searchTerm) && !isFetching && returnEmpty) {
    setReturnEmpty(false)
  }

  return {
    // Account for debounced input
    isFetching: isFetching || searchTerm !== filter,
    positions: returnEmpty ? [] : positions,
    returnEmpty,
    searchTerm,
  }
}

export { usePositionSearch }
