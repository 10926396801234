import { exportAsCSV } from "org_chart/grid/actions/toolbarActions"
import { Peripherals } from "packs/org_chart"
import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { selectNumericIdsForExport } from "v2/redux/combinedSelectors"

const Toolbar = ({ exportAsCSV, renderedPositionIds, peripheralsProps }) => (
  <div className="org-chart-toolbar datasheet">
    <Peripherals
      abilities={peripheralsProps.abilities}
      approvalProps={peripheralsProps.approvalProps}
      importDropdownProps={peripheralsProps.importDropdownProps}
      isOfficialChart={peripheralsProps.isOfficialChart}
      sharePath={peripheralsProps.sharePath}
      renderedPositionIds={renderedPositionIds}
      exportAsCSV={exportAsCSV}
    />
  </div>
)

Toolbar.propTypes = {
  exportAsCSV: PropTypes.func.isRequired,
  renderedPositionIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  peripheralsProps: PropTypes.object.isRequired,
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ exportAsCSV }, dispatch)

const mapStateToProps = (state) => ({ renderedPositionIds: selectNumericIdsForExport(state) })

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar)
