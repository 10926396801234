import React, { useRef, useState } from "react"

import { FollowUpModalProps } from "v2/react/components/orgChart/OrgChartDatasheet/FollowUpModals"
import {
  getActionType,
  getChangedFrom,
  getChangedTo,
  getDescription,
  getFieldType,
  getLabel,
  getTitle,
} from "v2/react/components/orgChart/OrgChartDatasheet/Modals/BudgetedPayTypeFollowUp/helpers"
import { ModalContent } from "v2/react/components/orgChart/OrgChartDatasheet/Modals/BudgetedPayTypeFollowUp/ModalContent"
import {
  ErrorsType,
  prepareErrors,
  useErrors,
} from "v2/react/components/orgChart/OrgChartDatasheet/Modals/errors"
import {
  UpdateNodeInput,
  useUpdateNodeWithMultipleInputs,
} from "v2/react/components/orgChart/OrgChartDatasheet/Modals/hooks/useUpdateNodeWithMultipleInputs"
import { Changes, InputFields } from "v2/react/components/orgChart/OrgChartDatasheet/Modals/types"
import { Modal } from "v2/react/shared/overlay/Modal"
import { SaveFooter } from "v2/react/shared/overlay/Modal/SaveFooter"
import { useDatasheetListenerActions } from "v2/redux/listeners/datasheetListeners"

type BudgetedPayFieldType =
  | "budgetedPayType"
  | "budgetedPayAmount"
  | "budgetedHoursPerWeek"
  | "variablePayType"
  | "variablePayAmount"
type ActionType = "edit" | "add"

type InputRef = React.RefObject<HTMLInputElement>
type SelectRef = React.RefObject<HTMLSelectElement>
type FocusRef = InputRef | SelectRef

const BudgetedPayTypeFollowUp = ({ isOpen, field, row }: FollowUpModalProps) => {
  const { cancelFollowUp: cancel, saveModalSuccess } = useDatasheetListenerActions()
  const [isSaving, setIsSaving] = useState(false)
  const { errors, setErrors, clearErrors } = useErrors()
  const focusRef: FocusRef = useRef(null)

  const [updateAttributesForNode] = useUpdateNodeWithMultipleInputs()

  const { fieldKey, label: fieldLabel, value } = field
  const { data } = row

  const fieldType: BudgetedPayFieldType = getFieldType(fieldKey)
  const actionType: ActionType = getActionType(fieldType, fieldKey, row)
  const changes: Changes = {
    from: getChangedFrom(fieldType, actionType, fieldKey, row),
    to: getChangedTo(fieldType, value),
  }

  const label = getLabel(actionType, fieldLabel, fieldType)
  const title = getTitle(actionType, label)
  const description = getDescription(actionType, fieldType, label, changes, data)

  const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
    setIsSaving(true)
    e.preventDefault()

    const form = e.target as HTMLFormElement
    const formData = new FormData(form)
    const formDataEntries: InputFields[] = Array.from(formData.entries()).map(
      ([fieldKey, value]) => ({ fieldKey, value }),
    ) as InputFields[]

    const hasExistingFieldKey = formDataEntries.some((entry) => entry.fieldKey === fieldKey)
    const inputs: InputFields[] = hasExistingFieldKey
      ? formDataEntries
      : [...formDataEntries, { fieldKey, value }]

    // Validate the inputs before saving
    const validationErrors = validateAndReturnErrors(inputs)
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
      setIsSaving(false)
      return
    }

    const response = await updateAttributesForNode(row.data, inputs as UpdateNodeInput[], fieldKey)

    if (response?.ok) {
      saveModalSuccess()
    } else {
      const errors = response?.errors
      setErrors(errors ? prepareErrors(errors) : {})
      setIsSaving(false)
    }
  }

  const focusOnInput = () => focusRef.current?.focus()

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      onClose={cancel}
      onAfterOpen={focusOnInput}
      size="md"
      shouldReturnFocusAfterClose={false}
    >
      <form onSubmit={handleSave}>
        <div className="react-modal__body">
          <div className="mb-4 cursor-default">{description}</div>
          {ModalContent({
            fieldKey,
            fieldType,
            actionType,
            changes,
            label,
            errors,
            clearErrors,
            focusRef,
          })}
        </div>
        <SaveFooter isSaving={isSaving} />
      </form>
    </Modal>
  )
}

const validateAndReturnErrors = (inputs: InputFields[]) =>
  inputs.reduce((acc, input) => {
    if (input.fieldKey && !input.value?.trim()) {
      const fieldKey = input.fieldKey
      acc[fieldKey] = "blank_validation".t("defaults")
    }
    return acc
  }, {} as ErrorsType)

export { BudgetedPayTypeFollowUp, BudgetedPayFieldType, ActionType, FocusRef, InputRef }
