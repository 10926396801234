import { useEffect } from "react"

export const useCustomEventListener = <Target extends EventTarget, EventDetail>(
  target: Target,
  type: string,
  listener: (event: CustomEvent<EventDetail>) => void,
) =>
  useEffect(() => {
    const safeListener = (ev: Event) => (isCustomEvent<EventDetail>(ev) ? listener(ev) : undefined)
    target.addEventListener(type, safeListener)
    return () => target.removeEventListener(type, safeListener)
  }, [listener, target, type])

const isCustomEvent = <T>(ev: Event): ev is CustomEvent<T> => "detail" in ev
