import dayjs from "dayjs"
import { Maybe } from "graphql/jsutils/Maybe"
import React from "react"

import { ApproverType, DatePropertyMap } from "v2/react/shared/status/statusUtils"

interface ApproverDateProps {
  approver: ApproverType
  isNextApprover: boolean
  workflowRequestDate: Date
  workflowStatus: Maybe<string> | undefined
}

function ApproverDate({
  approver,
  isNextApprover,
  workflowRequestDate,
  workflowStatus,
}: ApproverDateProps) {
  const dateDifference = dayjs(Date.now()).diff(workflowRequestDate, "days")

  if (
    (((approver.status && !approver.status) || (approver.state && !approver.state)) &&
      !isNextApprover) ||
    checkForNoDate(workflowRequestDate, approver)
  )
    return <span />
  if (isNextApprover && canApprove(workflowStatus))
    return <p className="ml-auto text-sm text-neutral-64">{calculatePendAmount(dateDifference)}</p>
  if (approver.status || (approver.state && approver.state !== "pending_approval"))
    return <p className="ml-auto text-sm text-neutral-64">{getStatusSubmitDate(approver)}</p>
  return <span />
}

export { ApproverDate }

const checkForNoDate = (requestDate: Date, approver: ApproverType) =>
  !requestDate &&
  (!approver.approvedAt ||
    !approver.changesRequestedAt ||
    !approver.deniedAt ||
    !approver.updatedAt)

const calculatePendAmount = (days: number) => {
  if (days < 1) {
    return "for_less_than_day".t("status_defaults")
  }
  return "for_x_days".t("status_defaults", null, null, null, [days])
}

const getStatusSubmitDate = (approver: ApproverType) => {
  const statusDateMap: DatePropertyMap = {
    approved: "approvedAt",
    denied: "deniedAt",
    changes_requested: "changesRequestedAt",
  }

  if (approver.status) {
    const dateProperty = statusDateMap[approver.status]
    return dayjs(approver[dateProperty]).format("MM/DD/YY")
  }

  if (approver.state) {
    return dayjs(approver.updatedAt).format("MM/DD/YY")
  }
  return <span />
}

const canApprove = (status: Maybe<string> | undefined) =>
  status !== "changes_requested" && status !== "denied"
