import React from "react"
import { ChangeHandler, RefCallBack } from "react-hook-form"

import { TextInput } from "v2/react/shared/forms/TextInput"
import { Info } from "v2/react/shared/status/Info"

function SystemUidField({
  defaultValue,
  name,
  onChange,
  show = true,
  textInputRef,
}: {
  defaultValue: string | undefined
  name: string
  onChange: ChangeHandler
  show: boolean
  textInputRef: RefCallBack
}) {
  if (!show) return null

  const label = (
    <div className="cursor-default">
      <span>{"field_requisition_id".t("ats")}</span>
      <Info description={"field_requisition_id_tooltip".t("ats")} tooltipSize="sm" />
    </div>
  )

  return (
    <div className="mb-4 cursor-default">
      <TextInput
        id="system_uid"
        name={name}
        inputRef={textInputRef}
        label={label}
        defaultValue={defaultValue}
        onChange={onChange}
      />
    </div>
  )
}

export { SystemUidField }
