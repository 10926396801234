import { svgPathData } from "icons/facSidebarFlip"

export const dimensions = {
  width: 14,
  height: 12,
}

const definePanelSidebarIcon = (svg: d3.Selection<SVGElement>): d3.Selection<SVGElement> => {
  svg
    .select("defs")
    .append("path")
    .attr("id", "info-circle-icon")
    .attr("d", svgPathData)
    .attr("viewBox", `0 0 ${dimensions.width} ${dimensions.height}`)
    .attr("fill", "rgba(12, 20, 75, 0.64)") // Set fill for dark text
  svg
    .select("defs")
    .append("path")
    .attr("id", "info-circle-icon-light")
    .attr("d", svgPathData)
    .attr("viewBox", `0 0 ${dimensions.width} ${dimensions.height}`)
    .attr("fill", "rgba(255, 255, 255, 0.64)") // Set fill for light text

  return svg
}

export default definePanelSidebarIcon
