/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions */
import cn from "classnames"
import React, { useEffect, useImperativeHandle } from "react"
import { FieldValues, UseFormRegister } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { useAutoComplete } from "v2/react/hooks/useAutocomplete"
import { DropdownMenu } from "v2/react/shared/collection/menus/DropdownMenu"
import { PositionTypesConnectionNode } from "v2/redux/GraphqlApi/PositionTypesApi"

type AutocompleteControlHandle = React.RefObject<{
  blur: () => void
  contains: (el: HTMLElement) => boolean
  focus: () => void
  getValue: () => string
}>
type AutocompleteControlProps = {
  autoFocus?: boolean
  canShowList: boolean
  canUseOptions: boolean
  controlRef: AutocompleteControlHandle
  disabled?: boolean
  floatingPortalId?: string
  id?: string
  inputClassName?: string
  label?: string
  name?: string
  onChoice: ChoiceHandler
  onFocus: () => void
  onSearchTermChange: (searchTerm: string) => void
  options: PositionTypesConnectionNode[]
  portalId?: string
  register?: UseFormRegister<FieldValues>
  searchTerm: string
  setShowList: React.Dispatch<React.SetStateAction<boolean>>
}
type ChoiceHandler = (arg: OnChoiceArg) => void
type OnChoiceArg =
  | { type: "choseCreateNewPositionType" }
  | { type: "choseExistingPositionType"; payload: PositionTypesConnectionNode }

function AutocompleteControl({
  autoFocus,
  canShowList,
  canUseOptions,
  controlRef,
  disabled,
  floatingPortalId,
  id = "position_position_type_attributes_job_code_title_label",
  inputClassName,
  label,
  name = "position[position_type_attributes][job_code_title_label]",
  onChoice,
  onFocus,
  onSearchTermChange,
  options,
  register,
  searchTerm,
  setShowList,
}: AutocompleteControlProps) {
  const { t } = useTranslation()

  const {
    activeIndex,
    context,
    floatingStyles,
    getFloatingProps,
    getItemProps,
    getReferenceProps,
    listRef,
    refs,
    setActiveIndex,
  } = useAutoComplete({ setShowList, showList: canShowList })

  useEffect(() => {
    if (canShowList && canUseOptions) setActiveIndex(0)
    return () => setActiveIndex(null)
  }, [setActiveIndex, canShowList, canUseOptions])

  const { domReference, floating } = refs
  useImperativeHandle(
    controlRef,
    () => ({
      blur: () => domReference?.current?.blur?.(),
      contains: (el) =>
        (domReference.current?.contains(el) || floating.current?.contains(el)) ?? false,
      focus: () => domReference.current?.focus?.(),
      getValue: () => domReference.current?.value ?? "",
    }),
    [domReference, floating],
  )

  const handleClickOnCreate: React.MouseEventHandler = (ev) => {
    ev.preventDefault()
    onChoice({ type: "choseCreateNewPositionType" })
  }
  const handleEnterKeyDown = (ev: React.KeyboardEvent) => {
    if (ev.key !== "Enter") return

    // If here, regardless of any other steps we'll take, we want to prevent
    // default behavior. Otherwise, any parent form will be submitted.
    ev.preventDefault()
    if (activeIndex === null || !canShowList || !canUseOptions) return

    const positionTypeNode = options[activeIndex]
    if (positionTypeNode) {
      onChoice({ type: "choseExistingPositionType", payload: positionTypeNode })
      // Let the parent know we still have focus (since we prevented default).
      onFocus()
      return
    }

    onChoice({ type: "choseCreateNewPositionType" })
  }
  const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    onSearchTermChange(event.target.value)
  const makeClickHandlerThatEmitsSelectChoice =
    (payload: PositionTypesConnectionNode) => (ev: React.MouseEvent) => {
      ev.preventDefault()
      onChoice({ payload, type: "choseExistingPositionType" })
    }

  return (
    <>
      <label htmlFor={id}>{label ?? t("v2.position_types.embedded_autocomplete.title")}</label>
      <input
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...register?.(name)}
        // The browser may obfuscate results with its own suggestions if we
        // don't include this.
        autoComplete="off"
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        className={cn("input", inputClassName)}
        disabled={disabled}
        id={id}
        name={name}
        placeholder={t("v2.position_types.embedded_autocomplete.placeholder")}
        ref={refs.setReference}
        type="text"
        value={searchTerm}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...getReferenceProps({
          onChange: handleSearchTermChange,
          onFocus,
          onKeyDown: handleEnterKeyDown,
        })}
      />
      <DropdownMenu
        context={context}
        floatingProps={getFloatingProps}
        floatingRef={refs.setFloating}
        floatingStyles={floatingStyles}
        floatingPortalId={floatingPortalId}
        showList={canShowList && canUseOptions}
        wrapperClasses="list-group autocomplete-list z-20"
      >
        {options.map((option, index) => (
          <button
            aria-selected={activeIndex === index}
            className={cn("list-group-item !cursor-pointer text-left", {
              active: activeIndex === index,
            })}
            data-id={option.uniqueKey}
            data-title={option.jobCodeTitleLabel}
            key={option.uniqueKey}
            ref={(node) => {
              listRef.current[index] = node
            }}
            role="option"
            type="button"
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...getItemProps({ onClick: makeClickHandlerThatEmitsSelectChoice(option) })}
          >
            <div className="list-group-item-title">{option.jobCodeTitleLabel}</div>
          </button>
        ))}
        {options.length > 0 ? <hr className="mx-[-0.5rem] my-2" /> : null}
        <div className="add-position-type group">
          <button
            aria-selected={activeIndex === options.length}
            className={cn(
              "list-group-item group-[.highlight]:bg-neutral-3",
              "hover:bg-neutral-3",
              "w-full rounded-md p-2.5 text-left text-neutral-100 block",
              { active: activeIndex === options.length },
            )}
            ref={(node) => {
              listRef.current[options.length] = node
            }}
            id="show-add-position-type-form"
            onClick={handleClickOnCreate}
            role="option"
            type="button"
          >
            <i className="far fa-plus mr-2" />
            <span className="truncate">
              {t("v2.defaults.create")} <span className="font-bold">{searchTerm}</span>
            </span>
          </button>
        </div>
      </DropdownMenu>
    </>
  )
}

export { AutocompleteControl, AutocompleteControlHandle, ChoiceHandler }
/* eslint-enable jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions */
