import {
  autoUpdate,
  flip,
  offset,
  OffsetOptions,
  ReferenceType,
  size,
  useClick,
  useDismiss,
  useFloating,
  UseFloatingOptions,
  useInteractions,
  useListNavigation,
  useRole,
} from "@floating-ui/react"
import { useRef, useState } from "react"

interface AutocompleteProps {
  showList: boolean
  setShowList: React.Dispatch<React.SetStateAction<boolean>>
  floatOverrides?: Partial<UseFloatingOptions<ReferenceType>>
  offset?: OffsetOptions
  disableSizeMiddleware?: boolean
}

const useSelectList = ({
  showList,
  setShowList,
  floatOverrides,
  offset: offsetOptions,
  disableSizeMiddleware,
}: AutocompleteProps) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const listRef = useRef<Array<HTMLElement | null>>([])

  const sizeMiddleware = disableSizeMiddleware
    ? []
    : [
        size({
          apply({ rects, availableHeight, elements, ...state }) {
            Object.assign(elements.floating.style, {
              width: `${rects.reference.width}px`,
              maxHeight: `${availableHeight}px`,
              top: state.placement === "top" ? "-1px" : "0px",
            })
          },
        }),
      ]

  const { refs, floatingStyles, context } = useFloating<HTMLInputElement>({
    placement: "bottom-start",
    whileElementsMounted: autoUpdate,
    open: showList,
    onOpenChange: (open: boolean, event: Event | undefined) => {
      if (event && event instanceof KeyboardEvent && ["ArrowDown", "ArrowUp"].includes(event.key)) {
        setShowList(false)
      } else {
        setShowList(!showList)
      }
    },
    middleware: [offset(offsetOptions), flip(), ...sizeMiddleware],
    ...floatOverrides,
  })

  const click = useClick(context, { event: "mousedown" })
  const role = useRole(context, { role: "listbox" })
  const dismiss = useDismiss(context)
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    virtual: true,
    loop: true,
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    role,
    dismiss,
    listNav,
    click,
  ])

  return {
    activeIndex,
    setActiveIndex,
    listRef,
    refs,
    floatingStyles,
    context,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
  }
}

export { useSelectList }
