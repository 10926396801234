import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import React from "react"
import { useTranslation } from "react-i18next"

import { CareerLaddersCreateCareerLadderTrackPositionTypeInput } from "types/graphql"
import { PositionTypeSearchInput } from "v2/react/shared/forms/PositionTypeSearchInput"
import { opaqueBasic } from "v2/react/utils/framerAnimationVariants"
import { useCareerLaddersCreateCareerLadderTrackPositionTypeMutation } from "v2/redux/GraphqlApi/CareerLaddersApi"
import { PositionTypesConnectionNode } from "v2/redux/GraphqlApi/PositionTypesApi"

type AddJobDefinitionItemProps = {
  careerLadderId: string
  isFocused?: boolean
  sortOrder: number
  trackId: string
  trackCount: number
  trackLength: number
}

const AddJobDefinitionItem = ({
  careerLadderId,
  sortOrder,
  trackId,
  trackCount,
  trackLength,
  isFocused = false,
}: AddJobDefinitionItemProps) => {
  const [mutate] = useCareerLaddersCreateCareerLadderTrackPositionTypeMutation()

  const addPositionType = async (input: CareerLaddersCreateCareerLadderTrackPositionTypeInput) => {
    const result = await mutate(input)

    const failed =
      "error" in result ||
      result.data.careerLaddersCreateCareerLadderTrackPositionType?.errors?.length

    if (failed) {
      // eslint-disable-next-line no-console
      console.error("Failed to add position type to track", result)
    }
  }

  const handlePositionTypeSelect = (positionType: PositionTypesConnectionNode) => {
    const input: CareerLaddersCreateCareerLadderTrackPositionTypeInput = {
      careerLadderId,
      careerLadderTrackId: trackId,
      positionTypeId: positionType.uniqueKey || "",
      sortOrder,
    }
    addPositionType(input)
  }

  return (
    <StyledJobDefinitionInput
      displayTrailingConnector={trackLength > 0}
      isFocused={isFocused}
      onSelectJobDefinition={handlePositionTypeSelect}
      spaceOnBottom={trackCount === 1}
    />
  )
}

type StyledJobDefinitionInputProps = {
  displayTrailingConnector?: boolean
  isFocused: boolean
  onPointerDown?: React.PointerEventHandler
  onSelectJobDefinition: (selectedPositionType: PositionTypesConnectionNode) => void
  spaceOnBottom?: boolean
}

const StyledJobDefinitionInput = ({
  displayTrailingConnector = false,
  isFocused,
  onPointerDown,
  onSelectJobDefinition,
  spaceOnBottom = false,
}: StyledJobDefinitionInputProps) => {
  const { t } = useTranslation()

  const borderStyles = "border-[6px] border-solid border-primary-8 bg-primary-8 rounded-xl"
  const spacingStyles = "box-border py-0"
  const widthStyles = "w-full min-w-[140px] max-w-[280px] px-0 sm:min-w-[240px]"

  const containerSpacingStyles = spaceOnBottom ? "m-0 -mb-6" : "m-0"

  return (
    <AnimatePresence>
      <motion.div variants={opaqueBasic} initial="hidden" animate="shown" exit="hidden">
        <div
          className={classNames("justify-center flex", containerSpacingStyles)}
          onPointerDown={onPointerDown}
        >
          <div className="w-full flex-col items-center justify-center flex">
            <div className={classNames("flex", borderStyles, spacingStyles, widthStyles)}>
              <PositionTypeSearchInput
                classes="w-full rw-rounded-xl"
                icon="fac rectangle-history-circle-plus"
                isFocused={isFocused}
                notInCareerLadder
                onSelect={onSelectJobDefinition}
                placeholder={t("v2.career_ladders.show.search_placeholder")}
              />
            </div>
            {displayTrailingConnector && <PositionConnector />}
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}

const PositionConnector = () => (
  <div className="w-full justify-center flex">
    <div className="my-1 h-4 w-1 border-0 border-l-2 border-solid border-primary-50" />
  </div>
)

export { AddJobDefinitionItem, StyledJobDefinitionInput }
