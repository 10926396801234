import { Placement } from "@floating-ui/react"
import React, { ReactNode, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import RootProvider from "v2/react/components/RootProvider"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"

/**
 * The PanelOpenerWithTooltip module provides reusable components for a link or
 * button styled trigger that opens a succession plan panel on the current page.
 */

interface PanelOpenerWithTooltipPageActionProps {
  children: ReactNode
  contentClasses?: string
  onClick: () => void
  placement?: Placement | "default-centered"
  triggerClasses?: string
}

/**
 * @note For the `placement` property, the `Tooltip` component defaults to use a
 * centered placement if the placement prop is not provided (undefined).
 * Apparently there is not a way to explicitly indicate this. This particular
 * component defaults to a bottom left aligned tooltip, so we need to add an
 * explicit "default-centered" string as an option for placement here, for the
 * time being.
 */
const PanelOpenerWithTooltipPageActionWithProvider = ({
  children,
  contentClasses,
  onClick,
  placement,
  triggerClasses,
}: PanelOpenerWithTooltipPageActionProps) => {
  const { t } = useTranslation()
  const triggerRef = useRef<HTMLButtonElement>(null)

  const [isVisible, setIsVisible] = useState(false)
  const handleClick = () => {
    setIsVisible(false)
    onClick()
  }

  useEffect(() => {
    if (!triggerRef.current) {
      setIsVisible(false)
    }
  }, [triggerRef])

  const defaultContentClasses = "react-tooltip-content--sm"
  const defaultPlacement = "bottom-start"
  const defaultTriggerClasses = "link text-inherit hover:no-underline active:text-inherit"
  // See: note above about placement prop
  const selectedPlacement =
    placement === "default-centered" ? undefined : placement || defaultPlacement

  return (
    <Tooltip placement={selectedPlacement} open={isVisible}>
      <TooltipTrigger
        onMouseOver={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        <button
          className={triggerClasses || defaultTriggerClasses}
          onClick={handleClick}
          ref={triggerRef}
          type="button"
        >
          {children}
        </button>
      </TooltipTrigger>
      <TooltipContent className={contentClasses || defaultContentClasses}>
        <span>{t("v2.succession.info_panel")}</span>
      </TooltipContent>
    </Tooltip>
  )
}

const PanelOpenerWithTooltipPageAction = ({
  children,
  contentClasses,
  onClick,
  placement,
  triggerClasses,
}: PanelOpenerWithTooltipPageActionProps) => (
  <RootProvider>
    <PanelOpenerWithTooltipPageActionWithProvider
      contentClasses={contentClasses}
      onClick={onClick}
      placement={placement}
      triggerClasses={triggerClasses}
    >
      {" "}
      {children}
    </PanelOpenerWithTooltipPageActionWithProvider>
  </RootProvider>
)

export { PanelOpenerWithTooltipPageAction }
