import cn from "classnames"
import React, { ReactNode } from "react"

interface LabelProps {
  label: ReactNode
  id?: string
  className?: string
  onMouseEnter?: React.MouseEventHandler
  onMouseLeave?: React.MouseEventHandler
}

const InputLabel = ({ id, label, className, onMouseEnter, onMouseLeave }: LabelProps) => (
  <label
    htmlFor={id || ""}
    className={cn("mb-1 cursor-default", className)}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    {label}
  </label>
)

export { InputLabel }
