import React from "react"

import { Maybe } from "types/graphql.d"
import { DisplayValueCell } from "v2/react/components/headcountPlanning/TableDatasheet/DisplayValueCell"
import { StrikethroughCell } from "v2/react/components/headcountPlanning/TableDatasheet/StrikethroughCell"

type Props = {
  rowId: string
  excluded?: boolean | null
  content?: Maybe<string>
}
export function SubmittedByCell({ rowId, excluded, content }: Props) {
  return excluded ? (
    <StrikethroughCell value={content ?? ""} />
  ) : (
    <DisplayValueCell id={`submitted_by-${rowId}`} className="non-editable-cell">
      <div className="h-full flex-col justify-center flex">{content}</div>
    </DisplayValueCell>
  )
}
