import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React, { useState } from "react"
import { CodeBlock, tomorrowNight } from "react-code-blocks"

function Modules() {
  const [collapsed, setCollapsed] = useState(false)

  return (
    <div>
      <h1>Modules</h1>
      <p className="mb-4 mt-2">
        Modules help gather information in a condensed area allowing users to quickly find
        information they need. This allows users to quickly scan the page&apos;s content and see
        which content will fit their needs.
      </p>
      <section>
        <h2 className="py-4">Basic Example</h2>

        <div className="module-card">
          <div className="module-card__header">
            <div className="text-base-bold">Emission Nebulae</div>
          </div>
          <div className="module-card__body">
            Emission nebulae are so named because they emit their own light. This type of nebula
            forms when the intense radiation of stars within or near the nebula energizes the gas. A
            star&apos;s ultraviolet radiation floods the gas with so much energy that it strips
            electrons from the nebula&apos;s hydrogen atoms, a process called ionization. As the
            energized electrons revert from their higher-energy state to a lower-energy state by
            recombining with atoms, they emit energy in the form of light, causing the nebula&apos;s
            gas to glow.
          </div>
        </div>
        <div className="col-span-5 py-4 font-mono text-base">
          <CodeBlock text={basicExample} theme={tomorrowNight} language="javascript" />
        </div>
      </section>

      <section>
        <h2 className="py-4">Example With Button</h2>

        <div className="module-card">
          <div className="module-card__header items-center justify-between flex">
            <div className="text-base-bold">Emission Nebulae</div>
            <button className="module-action btn--sm btn--secondary" type="button">
              {"Edit".t("defaults")}
            </button>
          </div>
          <div className="module-card__body">
            Emission nebulae are so named because they emit their own light. This type of nebula
            forms when the intense radiation of stars within or near the nebula energizes the gas. A
            star&apos;s ultraviolet radiation floods the gas with so much energy that it strips
            electrons from the nebula&apos;s hydrogen atoms, a process called ionization. As the
            energized electrons revert from their higher-energy state to a lower-energy state by
            recombining with atoms, they emit energy in the form of light, causing the nebula&apos;s
            gas to glow.
          </div>
        </div>
        <div className="col-span-5 py-4 font-mono text-base">
          <CodeBlock text={exampleWithButton} theme={tomorrowNight} language="javascript" />
        </div>
      </section>

      <section>
        <h2 className="py-4">Example With Items</h2>

        <div className="module-card">
          <div className="module-card__header">
            <div className="text-base-bold">Emission Nebulae</div>
            <p className="text-neutral-64">
              These ethereal veils of gas and dust tell the story of star birth and death.
            </p>
          </div>
          <div className="module-card__body">
            <p className="module-card-list-item">
              <label htmlFor="one" className="checkbox">
                <input type="checkbox" name="one" id="one" />
                High Interest
              </label>
            </p>
            <p className="module-card-list-item">
              <label htmlFor="two" className="checkbox">
                <input type="checkbox" name="two" id="two" />
                Medium Interest
              </label>
            </p>
            <p className="module-card-list-item">
              <label htmlFor="three" className="checkbox">
                <input type="checkbox" name="three" id="three" />
                Low Interest
              </label>
            </p>
          </div>
        </div>
        <div className="col-span-5 py-4 font-mono text-base">
          <CodeBlock text={exampleWithItems} theme={tomorrowNight} language="javascript" />
        </div>
      </section>

      <section>
        <h2 className="py-4">Accordion Example</h2>
        <p className="mb-4 mt-2">
          Accordion modules are collapsible. When clicking the icon, you can apply the{" "}
          <code>body-collapsed</code> class to the container element. Adding this class will
          effectively rotate the icon and collapse the body of the module. If you are using an
          accordion panel in a <code>slim</code>
          file, you can instead add the <code>data-acton=&quot;toggle-content&quot;</code> attribute
          to the icon.
        </p>

        <div className={classNames("accordion-module-panel", { "body-collapsed": collapsed })}>
          <div className="accordion-module-panel__header">
            <div>
              <div className="text-base-bold">Emission Nebulae</div>
              <p className="text-neutral-64">
                These ethereal veils of gas and dust tell the story of star birth and death.{" "}
                {collapsed}
              </p>
            </div>
            <button
              type="button"
              className="accordion-module-panel__header-icon"
              onClick={() => setCollapsed(!collapsed)}
            >
              <FontAwesomeIcon icon={["far", "chevron-up"]} />
            </button>
          </div>
          <div className="accordion-module-panel__body">
            <div className="accordion-module-panel__body-items">
              <p className="module-card-list-item">
                <label htmlFor="one" className="checkbox">
                  <input type="checkbox" name="one" id="one" />
                  High Interest
                </label>
              </p>
              <p className="module-card-list-item">
                <label htmlFor="two" className="checkbox">
                  <input type="checkbox" name="two" id="two" />
                  Medium Interest
                </label>
              </p>
              <p className="module-card-list-item">
                <label htmlFor="three" className="checkbox">
                  <input type="checkbox" name="three" id="three" />
                  Low Interest
                </label>
              </p>
            </div>
          </div>
        </div>
        <div className="col-span-5 py-4 font-mono text-base">
          <CodeBlock text={accordionExample} theme={tomorrowNight} language="javascript" />
        </div>
      </section>
    </div>
  )
}

export default Modules

const basicExample = `<div className="module-card">
  <div className="module-card__header">
    <div className="text-base-bold">Emission Nebulae</div>
  </div>
  <div className="module-card__body">
    Content Here
  </div>
</div>`

const exampleWithButton = `<div className="module-card">
  <div className="module-card__header flex items-center justify-between">
    <div className="text-base-bold">Emission Nebulae</div>
    <button className="module-action btn--sm btn--secondary" type="button">
      {"Edit".t("defaults")}
    </button>
  </div>
  <div className="module-card__body">
    Content Here
  </div>
</div>`

const exampleWithItems = `<div className="module-card">
  <div className="module-card__header">
    <div className="text-base-bold">Emission Nebulae</div>
    <p className="text-neutral-64">These ethereal veils of gas and dust tell the story of star birth and death.</p>
  </div>
  <div className="module-card__body">
    <p className="module-card-list-item">
      <label htmlFor="one" className="checkbox">
        <input type="checkbox" name="one" id="one" />
        High Interest
      </label>
    </p>
    <p className="module-card-list-item">
      <label htmlFor="two" className="checkbox">
        <input type="checkbox" name="two" id="two" />
        Medium Interest
      </label>
    </p>
    <p className="module-card-list-item">
      <label htmlFor="three" className="checkbox">
        <input type="checkbox" name="three" id="three" />
        Low Interest
      </label>
    </p>
  </div>
</div>`

const accordionExample = `<div className={classNames("accordion-module-panel", { 'body-collapsed': collapsed })}>
  <div className="accordion-module-panel__header">
    <p>Some Title</p>
    <button type="button" className="accordion-module-panel__header-icon" onClick={() => setCollapsed(!collapsed)}>
      <FontAwesomeIcon icon={['far', 'chevron-up']} />
    </button>
  </div>
  <div className="accordion-module-panel__body">
  <div className="accordion-module-panel__body-items">
    // ... content here
  </div>
  </div>
</div>`
