import { Maybe, SortDirection } from "types/graphql"
import { FieldKey, NodePropValue } from "v2/redux/slices/NodeSlice/types"

interface ComparatorConfig<T> {
  fieldKey: FieldKey
  toActual: (a: T) => NodePropValue
  direction?: `${SortDirection}`
}

// Regex intended to match on embedded percent values. It is meant to match on
// values like '(10%)', '(0.12 %)', or '(99 %)'.
const VARIABLE_PERCENT_REGEX = Object.freeze(/\((0|[1-9][0-9]*)(.[0-9]+)?\s*%\)/)

/**
 * Builds a function that compares two objects of type T and returns a number
 * indicating whether the LHS is less than, greater than, or equal to the RHS.
 *
 * @public
 */
function makeComparator<T>({ fieldKey, toActual, direction = "asc" }: ComparatorConfig<T>) {
  return (lhs: T, rhs: T) => {
    const lhsVal = getSortValue(toActual, lhs, fieldKey)
    const rhsVal = getSortValue(toActual, rhs, fieldKey)
    return direction === "asc" ? (lhsVal > rhsVal && 1) || -1 : (lhsVal < rhsVal && 1) || -1
  }
}

const isBlankString = (maybeString: Maybe<string>) =>
  typeof maybeString === "string" && maybeString.trim() === ""

const NumberOrNegativeInfinity = (value: string | number) => {
  if (typeof value === "number") return value

  // See https://camchenry.com/blog/parsefloat-vs-number for a decent table
  // that compares `parseFloat` with `Number`.
  //
  // ASSUMPTION: Restricting valid input to base 10 numbers is ok.
  const number = parseFloat(value)
  return Number.isNaN(number) ? Number.NEGATIVE_INFINITY : number
}

function getSortValue<T>(toActual: (a: T) => NodePropValue, row: T, fieldKey: string) {
  const actual = toActual(row)
  if (!actual) return ""
  if (Array.isArray(actual)) return actual.join(", ")

  if (["base_pay", "position_base_pay"].indexOf(fieldKey) !== -1) {
    return castToNumberFromMonetaryString(actual)
  }

  const related = window.RelationalNodeDataStore.instance
  if (!related.hasFieldDefinition(fieldKey)) return actual || ""

  const definition = related.getFieldDefinition(fieldKey) || {}
  // Special case: custom variable pays without a _type suffix should be sorted as a monetary
  // string.
  if (definition && fieldKey.startsWith("variable_pay_type_") && !fieldKey.endsWith("_type")) {
    const sanitized =
      typeof actual === "string" ? actual.replace(VARIABLE_PERCENT_REGEX, "") : actual

    return castToNumberFromMonetaryString(sanitized)
  }

  switch (definition.field_type) {
    case "numeric":
      return NumberOrNegativeInfinity(actual)
    case "currency":
      return castToNumberFromMonetaryString(actual)
    default:
      return actual || ""
  }
}

const castToNumberFromMonetaryString = (value: string | number) => {
  if (typeof value === "number") return value

  const Helpers = (window.App || {}).Helpers || {}
  const maybeParsed =
    Helpers.parseCurrency && !isBlankString(value) ? Helpers.parseCurrency(value) : value

  return NumberOrNegativeInfinity(maybeParsed)
}

export { makeComparator }
