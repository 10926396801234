import React from "react"
import { useTranslation } from "react-i18next"
import { useLocalStorage } from "usehooks-ts"

import { CareerLadder, CareerLadderTrack } from "types/graphql"
import { Toggle, ToggleButton, ToggleLabel, ToggleSwitch } from "v2/react/shared/forms/Toggle"
import { sanitizeParse } from "v2/react/utils/sanitizeParse"

import { EmptyState } from "./EmptyState"
import { JobDefinitionsForTrack } from "./JobDefinitionsForTrack/JobDefinitionsForTrack"
import { TitleColumnsForTrack } from "./TitleColumnsForTrack"

interface Builder {
  activePositionTypeId?: string
  careerLadder: CareerLadder
  positionAsLink?: boolean
  showEditToggle?: boolean
  smallMode?: boolean
}

const Builder: React.FC<Builder> = ({
  activePositionTypeId,
  careerLadder,
  positionAsLink = false,
  showEditToggle = false,
  smallMode = false,
}) => {
  const { t } = useTranslation()

  const primaryTrack = (careerLadder?.careerLadderTracks || []).find(
    (track: CareerLadderTrack) => !track.parentTrackId,
  )

  // Assumption: The action for this view will automatically create a main track
  // if it doesn't exist.
  const isEmpty = !primaryTrack || (primaryTrack.careerLadderTrackPositionTypes || []).length === 0

  const [editMode, setEditMode] = useLocalStorage(
    `careerLadders.builder.editMode.${careerLadder?.uniqueKey}`,
    isEmpty,
  )

  const handleToggleEditMode = () => {
    setEditMode(!editMode)
  }

  const canSplitTrack = careerLadder?.careerLadderTracks?.length === 1
  const positionInTrack = () =>
    !!careerLadder.careerLadderTracks?.flatMap(
      (tracks) =>
        tracks.careerLadderTrackPositionTypes?.filter(
          (positionType) => positionType.positionType?.id === activePositionTypeId,
        ),
    ).length

  if (activePositionTypeId && !positionInTrack()) return null

  return (
    <div>
      <div className="module-card">
        <div className="module-card__header items-center justify-between flex">
          <div className="text-base-bold">{t("v2.career_ladders.career_ladder")}</div>
          <div className="items-center justify-center flex">
            {showEditToggle && !isEmpty && (
              <Toggle initialValue={editMode} onToggle={handleToggleEditMode}>
                <ToggleButton classes="flex items-center gap-1.5 m-0">
                  <ToggleLabel
                    classes="text-base-bold"
                    disabledLabel={t("v2.defaults.edit_mode")}
                    enabledLabel={t("v2.defaults.edit_mode")}
                  />
                  <ToggleSwitch />
                </ToggleButton>
              </Toggle>
            )}
          </div>
        </div>
        {/* Adding className="group is-radial" to a parent of this component will apply radial bg */}
        <div className="module-card__body group-[.is-radial]:bg-radial-blue gap-0 py-6">
          {isEmpty ? (
            <div className="box-border px-[20%]">
              <EmptyState
                careerLadder={careerLadder}
                icon="fac ladder"
                note={sanitizeParse(t("v2.career_ladders.builder.add_job_definition_note_html"))}
                trackId={primaryTrack?.uniqueKey || ""}
              />
            </div>
          ) : (
            <div className="box-border w-full">
              <TitleColumnsForTrack
                careerLadder={careerLadder}
                editMode={showEditToggle && editMode}
                smallMode={smallMode}
                track={primaryTrack}
              />
              <JobDefinitionsForTrack
                activePositionTypeId={activePositionTypeId}
                careerLadder={careerLadder}
                editMode={showEditToggle && editMode}
                positionAsLink={positionAsLink}
                smallMode={smallMode}
                track={primaryTrack}
                canSplitTrack={canSplitTrack}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export { Builder }
