import cn from "classnames"
import React from "react"

type SubformProps = React.PropsWithChildren<{
  className?: string
  id?: string
  onSubmit?: React.FormEventHandler<HTMLFormElement>
}>

function Subform({ children, className, onSubmit, id }: SubformProps) {
  return (
    <form
      className={cn("elevation--overlay z-50 w-[23rem] rounded-lg bg-white p-4", className)}
      id={id}
      onSubmit={(ev) => {
        ev.stopPropagation()
        ev.preventDefault()
        onSubmit?.(ev)
      }}
    >
      {children}
    </form>
  )
}

export { Subform }
