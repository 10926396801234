import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useTranslation } from "react-i18next"

interface AlertBannerProps {
  handleGenerate: () => void
}

function AlertBanner({ handleGenerate }: AlertBannerProps) {
  const { t } = useTranslation()

  return (
    <div className="banner alert--red justify-between rounded-none border border-solid border-neutral-8 p-2 pl-3 shadow-none 1120:items-center 1120:p-3 1120:pl-4 1120:flex">
      <div>
        <div className="items-center gap-1.5 text-base-bold flex">
          <FontAwesomeIcon icon={["far", "info-circle"]} />
          {t("v2.position_types.show.unable_to_generate")}
        </div>
        <div className="ml-[19px]">{t("v2.position_types.show.unable_to_generate_subheading")}</div>
      </div>
      <button type="button" className="btn--large btn--secondary" onClick={handleGenerate}>
        <FontAwesomeIcon icon={["far", "sparkles"]} />
        {t("v2.position_types.show.generate_with_ai")}
      </button>
    </div>
  )
}

export { AlertBanner }
