/* eslint-disable react/jsx-props-no-spreading */
import { CalendarDate, createCalendar } from "@internationalized/date"
import React, { useEffect, useRef } from "react"
import {
  AriaRangeCalendarProps,
  DateValue,
  useLocale,
  usePress,
  useRangeCalendar,
} from "react-aria"
import { DateRangePickerState, useRangeCalendarState } from "react-stately"

import { Calendar, type OnDateClick } from "v2/react/shared/forms/DateInputs/shared/Calendar"
import { useCalendarDateContext } from "v2/react/shared/forms/DateInputs/shared/Calendar/hooks/useCalendarDateContext"

function RangeCalendar<T extends DateValue>({
  onDateClick,
  rangeValue,
  minValue,
  maxValue,
  ...props
}: AriaRangeCalendarProps<T> & {
  onDateClick?: OnDateClick
  /**
   * The range value from the DateRangePickerState. This may be different from
   * the value in the calendar state in the case the user only selects one date.
   */
  rangeValue: DateRangePickerState["value"]
}) {
  const { locale } = useLocale()
  const state = useRangeCalendarState({
    ...props,
    locale,
    createCalendar,
  })

  useEffect(() => {
    const isPartialRange = rangeValue && !(rangeValue.start && rangeValue.end)

    if (isPartialRange) {
      if (rangeValue.start && !(rangeValue.start === state.anchorDate)) {
        state.setAnchorDate(rangeValue.start as CalendarDate)
      }
      if (rangeValue.end && !(rangeValue.end === state.anchorDate)) {
        state.setAnchorDate(rangeValue.end as CalendarDate)
      }
    }
  }, [rangeValue, state])

  const ref = useRef(null)
  const { prevButtonProps, nextButtonProps, title } = useRangeCalendar(props, state, ref)
  const { pressProps } = usePress({
    onPress: () => {
      // If, while in the midst of selecting dates, the user clicks outside of
      // the calendar grid/controls, we set the current highlighted range as the
      // selected range.
      if (state.anchorDate) {
        state.setValue(state.highlightedRange)
      }
    },
  })

  const { months, activeMonth, years, activeYear } = useCalendarDateContext({
    focusedDate: state.focusedDate,
    startDate: props.value?.start,
    endDate: props.value?.end,
    minValue,
    maxValue,
  })

  return (
    <Calendar
      state={state}
      title={title}
      months={months}
      activeMonth={activeMonth}
      years={years}
      activeYear={activeYear}
      calendarRef={ref}
      prevButtonProps={prevButtonProps}
      nextButtonProps={nextButtonProps}
      calendarPressProps={pressProps}
      onDateClick={onDateClick}
      onSetVisibleRange={props.onFocusChange}
    />
  )
}

export { RangeCalendar }
export type { OnDateClick }
