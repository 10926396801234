import React from "react"

const DEFAULT_COLOR = "status-success"
const COLOR_STYLES = {
  "status-success": {
    className: "bg-status-success-light",
    textClassName: "text-status-success",
  },
  "status-excite": {
    className: "bg-status-excite-light",
    textClassName: "text-status-excite",
  },
  neutral: {
    className: "bg-neutral-3",
    textClassName: "text-neutral-1",
  },
  info: {
    className: "bg-status-info-light",
    textClassName: "text-status-info",
  },
}

interface Props {
  content: string
  colorStyle: keyof typeof COLOR_STYLES
}

export default function Tag({ content, colorStyle = DEFAULT_COLOR }: Props) {
  const containerColor = COLOR_STYLES[colorStyle].className
  const textClassName = COLOR_STYLES[colorStyle].textClassName

  return (
    <div className={`${containerColor} rounded-3xl border border-neutral-8 px-4 py-2`}>
      <div className={`${textClassName} text-xs font-bold leading-4`}>{content}</div>
    </div>
  )
}
