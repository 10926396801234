import cn from "classnames"
import React from "react"

import { Person } from "types/graphql"
import { IconSize } from "v2/react/shared/icons/BlueIcon"

interface Props {
  classNames?: string
  person: Person
  size?: IconSize
}

function Avatar({ classNames, person, size }: Props) {
  return (
    person.avatarThumbUrl && (
      <img
        className={cn(
          "circle m-0",
          {
            "circle-sm": size === "sm", // 1.5rem
            "circle-med": size === "med", // 2rem
            "circle-med-lg": size === "med-lg", // 2.5rem
            "circle-lg": size === "lg", // 4.5rem
          },
          classNames,
        )}
        src={person.avatarThumbUrl}
        alt={person.name}
      />
    )
  )
}

export { Avatar }
