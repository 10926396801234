/* eslint-disable @typescript-eslint/no-explicit-any */
import { Table } from "@tanstack/react-table"
import React from "react"

type DatasheetContext = {
  table: Table<any>
}

const DatasheetContext = React.createContext<DatasheetContext>({
  table: {} as Table<any>,
})

export function DatasheetContextProvider<TRow extends { id: string }>({
  table,
  children,
}: {
  table: Table<TRow>
  children: React.ReactNode
}) {
  const value = React.useMemo(() => ({ table }), [table])

  return <DatasheetContext.Provider value={value}>{children}</DatasheetContext.Provider>
}

export function useDatasheetContext() {
  return React.useContext(DatasheetContext)
}
