import { FloatingPortal } from "@floating-ui/react"
import dayjs from "dayjs"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { BioLicense, FeatureFlags, FileUpload, ProfilePanelPermissions } from "types/graphql"
import { ProfilePanelMode } from "types/graphql.enums"
import { ModuleListItem } from "v2/react/components/orgChart/ProfilePanel/ModuleListItem"
import { useSelectedBox } from "v2/react/components/orgChart/ProfilePanel/Settings/hooks/useSelectedBox"
import { AccordionModulePanel } from "v2/react/shared/layout/AccordionModulePanel"
import { AccordionModulePanelOverlay } from "v2/react/shared/layout/AccordionModulePanelOverlay"
import { PreviewContainer } from "v2/react/shared/overlay/PreviewContainer"
import { sanitizeParse } from "v2/react/utils/sanitizeParse"
import { useAppSelector } from "v2/redux/store"

interface BioLicensesProps {
  abilities: ProfilePanelPermissions
  featureFlags: FeatureFlags
}

function BioLicenses({ abilities, featureFlags }: BioLicensesProps) {
  const person = useAppSelector((state) => state.profilePanel.person)
  const { currentBox, toggleBox } = useSelectedBox("bio_licenses", ProfilePanelMode.Person)
  const [showOverlay, setShowOverlay] = useState(false)
  const [overlayContent, setOverlayContent] = useState<BioLicense | null>(null)
  const [filePreview, setFilePreview] = useState<FileUpload | null>(null)
  const { t } = useTranslation()

  if (!currentBox || !person) return null
  if (!featureFlags.bio || !person.bioLicenses?.length) return null

  const headerContent = <div className="title">{t("v2.profile_panel.bio_licenses")}</div>
  const overlayHeader = <div className="title">{overlayContent?.name}</div>
  const overlayBody = (
    <>
      <ModuleListItem>
        <div>
          <div>{t("v2.profile_panel.description")}</div>
          <div>{sanitizeParse(overlayContent?.description || "")}</div>
        </div>
      </ModuleListItem>
      {abilities.canReadSensitivePersonData && (
        <>
          <ModuleListItem>
            <div>{t("v2.profile_panel.license_or_certification_number")}</div>
            <div>{overlayContent?.number}</div>
          </ModuleListItem>
          <ModuleListItem>
            <div>{t("v2.profile_panel.effective_date")}</div>
            <div>
              {overlayContent?.effectiveDate
                ? dayjs(overlayContent?.effectiveDate).format("MMM D, YYYY")
                : ""}
            </div>
          </ModuleListItem>
          <ModuleListItem>
            <div>{t("v2.profile_panel.expiration_date")}</div>
            <div>
              {overlayContent?.expirationDate &&
                dayjs(overlayContent?.expirationDate).format("MMM D, YYYY")}
            </div>
          </ModuleListItem>
          {overlayContent?.fileUploads && (
            <ModuleListItem>
              <div>{t("v2.profile_panel.documentation")}</div>
              <div>
                {overlayContent.fileUploads.map((upload) => (
                  <div key={upload.id}>
                    <button
                      className="link-text"
                      type="button"
                      onClick={() => setFilePreview(upload)}
                    >
                      {window.App.Helpers.truncate(upload.title, 25, true)}
                    </button>
                    <FloatingPortal>
                      <PreviewContainer
                        url={filePreview?.file || ""}
                        title={filePreview?.title || ""}
                        showPreview={!!filePreview}
                        onClose={() => setFilePreview(null)}
                      />
                    </FloatingPortal>
                  </div>
                ))}
              </div>
            </ModuleListItem>
          )}
        </>
      )}
    </>
  )

  return (
    <AccordionModulePanel
      headerContent={headerContent}
      additionalToggleAction={toggleBox}
      collapsed={currentBox.collapsed}
      title={currentBox.name}
      showOverlay={showOverlay}
      overlayContent={
        <AccordionModulePanelOverlay
          isOpen={showOverlay}
          closePanel={() => {
            setOverlayContent(null)
            setShowOverlay(false)
          }}
          headerContent={overlayHeader}
        >
          {overlayBody}
        </AccordionModulePanelOverlay>
      }
    >
      <ModuleListItem>
        <div className="flex-wrap gap-2.5 flex">
          {person.bioLicenses?.map((license) => (
            <button
              key={license.id}
              type="button"
              className="badge--link hover mb-2"
              onClick={() => {
                setOverlayContent(license)
                setShowOverlay(true)
              }}
            >
              {license.name}
            </button>
          ))}
        </div>
      </ModuleListItem>
    </AccordionModulePanel>
  )
}

export { BioLicenses }
