import { GraphQLFormattedError } from "graphql"
import React from "react"
import type { DateRange as DateRangeType } from "react-aria"

import { DateRange } from "v2/react/shared/forms/DateInputs/DateRange/DateRange"
import { DateRangeOption } from "v2/react/shared/forms/DateInputs/utils/dates"
import { errorFor } from "v2/react/utils/errors"

const selectOptions: DateRangeOption[] = [
  "current_month",
  "current_quarter",
  "current_year",
  "next_week",
  "next_month",
  "next_quarter",
  "next_year",
]

export function PlanDates({
  disabled,
  dateRange,
  errors,
  onChange,
}: {
  disabled?: boolean
  dateRange: DateRangeType | null
  errors: GraphQLFormattedError[]
  onChange?: (range: DateRangeType) => void
}) {
  const startErrorMessage = errorFor("startDate", errors)
  const endErrorMessage = errorFor("endDate", errors)

  return (
    <div className="input-group">
      <DateRange
        id="hcp-date-range"
        label={"time_span_label".t("headcount_plan")}
        isDisabled={disabled}
        showTimeSpanSelection={false}
        quickSelectOptions={selectOptions}
        errorMessage={startErrorMessage || endErrorMessage}
        value={dateRange}
        onChange={onChange}
      />
    </div>
  )
}
