import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import fp from "lodash/fp"
import React from "react"

import { usePositionFormContext } from "./PositionFormProvider"

type BaseFormErrorsProps = {
  showAssistantError?: boolean
  showParentError?: boolean
  showRootError?: boolean
}

function BaseFormErrors({
  showAssistantError = true,
  showParentError = true,
  showRootError = true,
}: BaseFormErrorsProps) {
  const {
    formState: { errors },
  } = usePositionFormContext()

  const baseError: string | null = showRootError
    ? fp.prop(["root", "base", "message"], errors)
    : null
  const assistantError = showAssistantError ? errors.position?.isAssistant?.message : null
  const parentError = showParentError ? errors.position?.parentId?.message : null
  const anyErrors = baseError || assistantError || parentError

  return anyErrors ? (
    <div className="alert-critical mb-4 flex">
      <FontAwesomeIcon className="mr-2 mt-1" icon={["far", "exclamation-circle"]} />
      <div>
        {baseError && <div>{baseError}</div>}
        {assistantError && <div>{assistantError}</div>}
        {parentError && <div>{parentError}</div>}
      </div>
    </div>
  ) : null
}

export { BaseFormErrors, BaseFormErrorsProps }
