import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { Column, CursorConnection, NodeRow } from "v2/react/components/orgChart/Datasheet/types"
import { usePositionTypesAutocompleteQueryWithState } from "v2/react/components/positionTypes/hooks/usePositionTypesAutocompleteQueryWithState"
import {
  toEncodedIdNamePair,
  toEncodedInitialData,
} from "v2/redux/slices/GridSlice/gridHelpers/orgUnits"

import { CollectionAutocomplete, PrepareValueHandler } from "./CollectionAutocomplete"

type PositionTypeAutocompleteProps<TNode, CType> = {
  column: Column<CType>
  currentValue?: string
  cursorConnection: CursorConnection
  isFirst: boolean
  isLast: boolean
  row: NodeRow<TNode>
  style: React.CSSProperties
}

const prepareValue: PrepareValueHandler = (arg) => {
  switch (arg.type) {
    case "custom":
      return toEncodedInitialData(arg.inputValue)
    case "noChange":
      // Handle edge case where the initial value is an position type, it hasn't
      // changed, and the autocomplete component doesn't have an id for it. By
      // returning the unencoded value, a later check skips saving.
      return arg.inputValue
    case "suggestion":
      return toEncodedIdNamePair(arg.id, arg.label)
    default:
      // Default case is for "blank"
      return ""
  }
}

/**
 * Presents an autocomplete for the position type along with an action to add a
 * new position type.
 *
 * @public
 */
function PositionTypeAutocomplete<TNode, CType>({
  column,
  currentValue,
  cursorConnection,
  isFirst,
  isLast,
  row,
  style,
}: PositionTypeAutocompleteProps<TNode, CType>) {
  const { t } = useTranslation()

  const footerClassName = cn(
    "AutocompleteField__footer",
    "align-center flex justify-center py-2 px-4",
  )
  const [inputValue, setInputValue] = useState(currentValue ?? "")
  const handleAddNewClick = () => {
    cursorConnection.saveWrite(toEncodedInitialData(inputValue))
  }

  const { positionTypes } = usePositionTypesAutocompleteQueryWithState({
    currentTerm: currentValue ?? "",
    searchTerm: inputValue,
  })

  const options = positionTypes.map(({ jobCodeTitleLabel, uniqueKey }) => ({
    id: uniqueKey,
    label: jobCodeTitleLabel,
  }))

  return (
    <CollectionAutocomplete
      allowCustomInput
      callerManagedOptions
      callerOptions={options}
      column={column}
      cursorConnection={cursorConnection}
      fallbackOption={
        <div className={footerClassName}>
          <button className="btn--sm btn--primary" onMouseDown={handleAddNewClick} type="button">
            <FontAwesomeIcon icon={["far", "plus"]} />
            {t("v2.defaults.add_entity", { entity: column.label })}
          </button>
        </div>
      }
      focusCell={false}
      html={currentValue || null}
      isFirst={isFirst}
      isLast={isLast}
      noBorder
      onInputValueChange={setInputValue}
      prepareValue={prepareValue}
      row={row}
      style={style}
    />
  )
}

export { PositionTypeAutocomplete, PositionTypeAutocompleteProps }
