import { Placement } from "@floating-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { skipToken } from "@reduxjs/toolkit/dist/query"
import cn from "classnames"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { Position } from "types/graphql"
import RootProvider from "v2/react/components/RootProvider"
import { CandidateForm } from "v2/react/components/succession/SuccessionPlanPanel/Summary/CandidateForm"
import { Popover, PopoverContent, PopoverTrigger } from "v2/react/shared/overlay/Popover"
import { pjaxReload } from "v2/react/utils/pjax"
import { UrlHelper } from "v2/react/utils/urls"
import { useGetPanelSuccessionPlanQuery } from "v2/redux/GraphqlApi/SuccessionApi"
import { useAppSelector } from "v2/redux/store"

interface CandidateFormDropdownProps {
  buttonType: "primary" | "secondary"
  dropdownPlacement?: Placement
  positionId: string
}

function WithProvider({ buttonType, dropdownPlacement, positionId }: CandidateFormDropdownProps) {
  const { t } = useTranslation()
  const [showCandidateForm, setShowCandidateForm] = useState(false)
  const activeTab = useAppSelector(
    (state) => state.successionPlanPanel.activeSuccessionPlanPanelTab,
  )
  const { data } = useGetPanelSuccessionPlanQuery(positionId ? { positionId } : skipToken)
  const position: Position | undefined | null = data?.position

  if (!position || !activeTab) return null

  const handleSaveAndClose = () => {
    setShowCandidateForm(false)
    const selector = "#succession-plan-panel-pjax-container"

    pjaxReload({
      url: UrlHelper.successionPlanPanelPath(positionId, activeTab),
      container: selector,
    })
  }

  return (
    <Popover
      open={showCandidateForm}
      onOpenChange={setShowCandidateForm}
      placement={dropdownPlacement}
    >
      <PopoverTrigger
        className={cn("btn--large", {
          "btn--primary": buttonType === "primary",
          "btn--secondary": buttonType === "secondary",
        })}
        onClick={() => setShowCandidateForm(true)}
      >
        <FontAwesomeIcon icon={["far", "plus"]} />
        <span className="max-[1016px]:hidden">
          {t("v2.succession_plan_panel.summary.add_a_candidate")}
        </span>
      </PopoverTrigger>
      <PopoverContent className="elevation--overlay candidate-form z-5 w-[25rem] rounded-lg bg-white">
        <CandidateForm
          candidates={position.successionPlan?.candidates || []}
          filledBy={position.filledBy || []}
          onClose={handleSaveAndClose}
          positionId={position.id}
        />
      </PopoverContent>
    </Popover>
  )
}

const CandidateFormDropdown = ({
  buttonType,
  dropdownPlacement,
  positionId,
}: CandidateFormDropdownProps) => (
  <RootProvider>
    <WithProvider
      buttonType={buttonType}
      dropdownPlacement={dropdownPlacement}
      positionId={positionId}
    />
  </RootProvider>
)

export { CandidateFormDropdown }
