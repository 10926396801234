import React from "react"

interface Props {
  selectedTab?: string
  onClick?: React.Dispatch<React.SetStateAction<"approved" | "timeline" | "original">>
}

export function TabSwitcher({ selectedTab, onClick }: Props) {
  return (
    <div id="hcp-tabs-selector" className="btn-group absolute left-1/2">
      <button
        type="button"
        className="btn first"
        disabled={selectedTab !== "timeline"}
        onClick={() => onClick && onClick("approved")}
      >
        {"datasheet_tab".t("headcount_plan")}
      </button>
      <button
        type="button"
        className="btn"
        disabled={selectedTab === "timeline"}
        onClick={() => onClick && onClick("timeline")}
      >
        {"timeline_tab".t("headcount_plan")}
      </button>
    </div>
  )
}
