import React, { useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { Modal, ModalFooter, type ModalFooterProps } from "v2/react/shared/overlay/Modal"

interface ContextProps {
  showModal: (props: Pick<UnappliedChangesModalProps, "onConfirm">) => void
  hideModal: () => void
  unappliedChanges: boolean
  setUnappliedChanges: (value: boolean) => void
  setAfterChangesCanceled: React.Dispatch<React.SetStateAction<(() => () => void) | null>>
  /**
   * The callback to run immediately after the user confirms they to cancel any
   * unapplied changes.
   */
  onConfirm: UnappliedChangesModalProps["onConfirm"]
  /**
   * An optional callback to run once the user confirms they want to cancel
   * changes. Meant to run after the modal is closed.
   */
  onAfterChangesCanceled: (() => () => void) | null
}

const UnappliedChangesModalContext = React.createContext<ContextProps | undefined>(undefined)

const useUnappliedChangesModal = () => {
  const context = useContext(UnappliedChangesModalContext)

  if (context === undefined) {
    throw new Error("useUnappliedChangesModal must be used within a UnappliedChangesModalProvider")
  }

  return context
}

interface UnappliedChangesModalProviderProps {
  children: React.ReactNode
}

const UnappliedChangesModalProvider = ({ children }: UnappliedChangesModalProviderProps) => {
  const [open, setOpen] = useState(false)
  const [unappliedChanges, setUnappliedChanges] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const [confirmCallback, setConfirmCallback] = useState(() => () => {})
  const [afterChangesCanceled, setAfterChangesCanceled] =
    useState<ContextProps["onAfterChangesCanceled"]>(null)

  const showModal: ContextProps["showModal"] = ({ onConfirm }) => {
    setConfirmCallback(() => onConfirm)
    setOpen(true)
  }

  const hideModal = () => {
    setOpen(false)
  }

  const contextValue = useMemo(
    () => ({
      showModal,
      hideModal,
      unappliedChanges,
      setUnappliedChanges,
      setAfterChangesCanceled,
      onConfirm: confirmCallback,
      onAfterChangesCanceled: afterChangesCanceled,
    }),
    [confirmCallback, afterChangesCanceled, unappliedChanges],
  )

  return (
    <UnappliedChangesModalContext.Provider value={contextValue}>
      {children}
      {open && (
        <UnappliedChangesModal open={open} onConfirm={confirmCallback} onCancel={hideModal} />
      )}
    </UnappliedChangesModalContext.Provider>
  )
}

interface UnappliedChangesModalProps {
  open: boolean
  onConfirm: ModalFooterProps["onSave"]
  onCancel: () => void
}

const UnappliedChangesModal = ({ open, onConfirm, onCancel }: UnappliedChangesModalProps) => {
  const { t } = useTranslation()

  return (
    <Modal
      isOpen={open}
      title={t("v2.shared.unapplied_changes.title")}
      onClose={onCancel}
      showCloseButton={false}
      footer={
        <ModalFooter
          saveButtonText={t("v2.shared.unapplied_changes.save_button")}
          onClose={onCancel}
          onSave={onConfirm}
        />
      }
    >
      <div className="react-modal__body">{t("v2.shared.unapplied_changes.message")}</div>
    </Modal>
  )
}

/**
 * Sets the unapplied changes state for the unapplied changes modal.
 */
const useSetUnappliedChanges = (value: boolean) => {
  const { setUnappliedChanges } = useUnappliedChangesModal()

  useEffect(() => {
    setUnappliedChanges(value)
  }, [value, setUnappliedChanges])
}

UnappliedChangesModal.Provider = UnappliedChangesModalProvider
UnappliedChangesModal.useContext = useUnappliedChangesModal
UnappliedChangesModal.useSetUnappliedChanges = useSetUnappliedChanges

export { UnappliedChangesModal }
