import React from "react"

import RootProvider from "v2/react/components/RootProvider"
import { closeProfilePlanSidebar } from "v2/redux/slices/ProfilePanelSlice"
import { openSuccessionPlanSidebar } from "v2/redux/slices/SuccessionPlanPanelSlice"
import { useAppDispatch } from "v2/redux/store"

import { PanelOpenerWithTooltipPageAction } from "./PanelOpenerWithTooltip"

interface Props {
  linkText: string
  positionId: string
}

const WithProvider = ({ linkText, positionId }: Props) => {
  const dispatch = useAppDispatch()

  const showPlanPanel = () => {
    dispatch(openSuccessionPlanSidebar({ positionId }))
    dispatch(closeProfilePlanSidebar())
  }

  return (
    <PanelOpenerWithTooltipPageAction onClick={showPlanPanel} triggerClasses="link">
      <span>{linkText}</span>
    </PanelOpenerWithTooltipPageAction>
  )
}

const TextLink = ({ linkText, positionId }: Props) => (
  <RootProvider>
    <WithProvider linkText={linkText} positionId={positionId} />
  </RootProvider>
)

export { TextLink }
