import {
  PeopleInterestedInPositionTypeConnectionQuery,
  PeopleInterestedInPositionTypeConnectionQueryVariables,
  PositionsForPositionTypeConnectionQuery,
  PositionsForPositionTypeConnectionQueryVariables,
  PositionTypeDetailsQuery,
  PositionTypeDetailsQueryVariables,
  PositionTypeMetricsQuery,
  PositionTypeMetricsQueryVariables,
  PositionTypeQuery,
  PositionTypeQueryVariables,
  PositionTypesAutocompleteConnectionQuery,
  PositionTypesAutocompleteConnectionQueryVariables,
  PositionTypesConnectionQuery,
  PositionTypesConnectionQueryVariables,
  PositionTypesCreateInput,
  PositionTypesCreateMutation,
  PositionTypesTableSettingsQuery,
  PositionTypesTableSettingsQueryVariables,
  PositionTypesUpdateInput,
  PositionTypesUpdateMutation,
  ToggleInterestInJobInput,
  ToggleInterestInJobMutation,
  ToggleInterestInJobMutationVariables,
  UpdatePersonPositionTypesTableSettingsInput,
  UpdatePersonPositionTypesTableSettingsMutation,
} from "types/graphql.d"
import OperationStore from "v2/operation_store"
import { GraphqlApi } from "v2/redux/GraphqlApi"
import { flatMutationOperation, mutationOperation, queryOperation } from "v2/redux/utils/endpoints"

type PositionTypesConnection = {
  nodes: NonNullable<PositionTypesConnectionQuery["positionTypesConnection"]>["nodes"]
  pageInfo: NonNullable<PositionTypesConnectionQuery["positionTypesConnection"]>["pageInfo"]
}
type PositionTypesConnectionNode = PositionTypesConnection["nodes"][0]

const EMPTY_POSITION_TYPES_CONNECTION: PositionTypesConnection = Object.freeze({
  nodes: [],
  pageInfo: {
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  },
})

// Unique key used as a placeholder by the server when a new position type was
// only validated, but not created. Applies to the `positionTypesCreate`, where
// the caller can pass a flag `validateOnly`.
const UNIQUE_KEY_FOR_PENDING_POSITION_TYPE = "position_type_0"

export const PositionTypesApi = GraphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    getPositionType: builder.query<PositionTypeQuery, PositionTypeQueryVariables>({
      query: queryOperation<PositionTypeQueryVariables>("PositionType"),
    }),
    getPositionTypeDetails: builder.query<
      PositionTypeDetailsQuery,
      PositionTypeDetailsQueryVariables
    >({
      query: queryOperation<PositionTypeDetailsQueryVariables>("PositionTypeDetails"),
      providesTags: (result) => {
        const tags: (string | { type: string; id: string })[] = ["PositionTypeDetails"]
        if (result?.positionType?.id)
          tags.push({ type: "PositionType", id: result?.positionType?.id })
        return tags
      },
    }),
    getPositionTypeMetrics: builder.query<
      PositionTypeMetricsQuery,
      PositionTypeMetricsQueryVariables
    >({
      query: queryOperation<PositionTypeMetricsQueryVariables>("PositionTypeMetrics"),
    }),
    getPositionTypePositions: builder.query<
      PositionsForPositionTypeConnectionQuery,
      PositionsForPositionTypeConnectionQueryVariables
    >({
      query: queryOperation<PositionsForPositionTypeConnectionQueryVariables>(
        "PositionsForPositionTypeConnection",
      ),
    }),
    getPositionTypesTableSettings: builder.query<
      PositionTypesTableSettingsQuery,
      PositionTypesTableSettingsQueryVariables
    >({
      query: queryOperation<PositionTypesTableSettingsQueryVariables>("PositionTypesTableSettings"),
      providesTags: ["PositionTypesTableSettings"],
    }),
    updatePersonPositionTypesTableSettings: builder.mutation<
      UpdatePersonPositionTypesTableSettingsMutation,
      UpdatePersonPositionTypesTableSettingsInput
    >({
      query: (input) => ({
        operationId: OperationStore.getOperationId("UpdatePersonPositionTypesTableSettings"),
        variables: { input },
      }),
      invalidatesTags: ["PositionTypesTableSettings"],
    }),
    getPositionTypePeopleInterested: builder.query<
      PeopleInterestedInPositionTypeConnectionQuery,
      PeopleInterestedInPositionTypeConnectionQueryVariables
    >({
      query: queryOperation<PeopleInterestedInPositionTypeConnectionQueryVariables>(
        "PeopleInterestedInPositionTypeConnection",
      ),
    }),
    updatePositionType: builder.mutation<PositionTypesUpdateMutation, PositionTypesUpdateInput>({
      query: flatMutationOperation("PositionTypesUpdate"),
      invalidatesTags: ["PositionTypeDetails"],
    }),
    /**
     * @example
     * ```ts
     * const connection = usePositionTypesConnectionQuery({
     *   searchTerm: "Get em', Agnew!",
     *   notInCareerLadder: true,
     * })
     * ```
     */
    positionTypesConnection: builder.query<
      PositionTypesConnection,
      PositionTypesConnectionQueryVariables
    >({
      providesTags: ["PositionTypesConnection"],
      query: queryOperation<PositionTypesConnectionQueryVariables>("PositionTypesConnection"),
      transformResponse: (res: PositionTypesConnectionQuery) =>
        res.positionTypesConnection ?? EMPTY_POSITION_TYPES_CONNECTION,
    }),
    positionTypesAutocompleteConnection: builder.query<
      PositionTypesConnection,
      PositionTypesAutocompleteConnectionQueryVariables
    >({
      providesTags: ["PositionTypesConnection"],
      query: queryOperation<PositionTypesAutocompleteConnectionQueryVariables>(
        "PositionTypesAutocompleteConnection",
      ),
      transformResponse: (res: PositionTypesAutocompleteConnectionQuery) =>
        res.positionTypesConnection ?? EMPTY_POSITION_TYPES_CONNECTION,
    }),
    toggleInterestInJob: builder.mutation<
      ToggleInterestInJobMutation,
      ToggleInterestInJobMutationVariables
    >({
      query: mutationOperation<ToggleInterestInJobInput>("ToggleInterestInJob"),
    }),
    /**
     * Handles merging multiple fetches of this query. Useful for an infinite
     * list.
     *
     * @see https://redux-toolkit.js.org/rtk-query/api/createApi#forcerefetch
     */
    fetchInfiniteListOfPositionTypes: builder.query<
      PositionTypesConnection,
      PositionTypesConnectionQueryVariables
    >({
      query: queryOperation<PositionTypesConnectionQueryVariables>("PositionTypesConnection"),
      serializeQueryArgs: ({ endpointName, queryArgs }) =>
        `${endpointName}-${queryArgs.jobFamilyKey}-${queryArgs.excludeNestedJobFamilies}-${queryArgs.filterByIndicatedInterest}`,
      merge: (currentCache, newItems) => ({
        ...newItems,
        nodes: [...currentCache.nodes, ...newItems.nodes],
      }),
      forceRefetch({ currentArg, endpointState }) {
        const data = endpointState?.data as PositionTypesConnection

        // When this is the first fetch, return true.
        if (!data) return true
        if (!data.pageInfo.hasNextPage) return false
        if (data.pageInfo.endCursor !== currentArg?.after) return false

        return true
      },
      transformResponse: (res: PositionTypesConnectionQuery) =>
        res.positionTypesConnection ?? EMPTY_POSITION_TYPES_CONNECTION,
    }),
    positionTypesCreate: builder.mutation<PositionTypesCreateMutation, PositionTypesCreateInput>({
      query: flatMutationOperation<PositionTypesCreateInput>("PositionTypesCreate"),
      invalidatesTags: ["PositionTypesConnection"],
    }),
  }),
})

export {
  EMPTY_POSITION_TYPES_CONNECTION,
  PositionTypesConnection,
  PositionTypesConnectionNode,
  UNIQUE_KEY_FOR_PENDING_POSITION_TYPE,
}

export const {
  useFetchInfiniteListOfPositionTypesQuery,
  useGetPositionTypeDetailsQuery,
  useGetPositionTypeMetricsQuery,
  useGetPositionTypePositionsQuery,
  useGetPositionTypesTableSettingsQuery,
  useUpdatePersonPositionTypesTableSettingsMutation,
  useGetPositionTypeQuery,
  useLazyGetPositionTypePeopleInterestedQuery,
  useLazyGetPositionTypePositionsQuery,
  usePositionTypesConnectionQuery,
  usePositionTypesCreateMutation,
  usePositionTypesAutocompleteConnectionQuery,
  useToggleInterestInJobMutation,
  useUpdatePositionTypeMutation,
} = PositionTypesApi
