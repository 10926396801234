import { useRef, useState } from "react"

/**
 * Useful for controlling conditional tooltips. Sometimes we may only want to show a tooltip
 * if the text has been truncated.
 * <Tooltip open={showTooltip}>
 *    <TooltipTrigger
 *      onMouseEnter={determineTooltip}
 *      onMouseLeave={() => setShowTooltip(false)}
 *    >
 *      <p ref={ref}>text content</p>
 *    </TooltipTrigger>
 *    <TooltipContent>
 *      tooltip content
 *    </TooltipContent>
 *  </Tooltip>
 * @returns ref, showTooltip, setShowTooltip, determineTooltip
 */
function useTruncatedTooltip<T extends HTMLElement>() {
  const ref = useRef<T>(null)
  const [showTooltip, setShowTooltip] = useState(false)

  const determineTooltip = () => {
    if (!ref.current) return

    const textEllipsed = ref.current.offsetWidth < ref.current.scrollWidth
    setShowTooltip(textEllipsed)
  }

  return { ref, showTooltip, setShowTooltip, determineTooltip }
}

export { useTruncatedTooltip }
