import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import UpDownLoad from "images/up-down-load.svg"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { Dropdown } from "v2/react/shared/collection/menus/Dropdown/Dropdown"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"
import { mergeCurrentSearchParamsWithUrl } from "v2/react/utils/urls"

interface Props {
  exportUrl: string
  importUrl: string
  exportId?: string
  importId?: string
  /**
   * If true, the current search params will be used along with the exportUrl
   * when the export is clicked.
   */
  useCurrentSearchParamsWithExportUrl?: boolean
}

const ImportExportButton = ({
  exportUrl,
  importUrl,
  exportId = "export-records-link",
  importId = "import-records-link",
  useCurrentSearchParamsWithExportUrl,
}: Props) => {
  const { t } = useTranslation()
  const [imageLoaded, setImageLoaded] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const handleImageLoad = () => setImageLoaded(true)

  const handleClick = (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (useCurrentSearchParamsWithExportUrl && exportUrl) {
      const newUrl = mergeCurrentSearchParamsWithUrl(exportUrl)
      const event = e as React.MouseEvent<HTMLAnchorElement, MouseEvent>
      if (event) {
        event.currentTarget.href = newUrl
      }
    }
  }

  return (
    <Dropdown align="left" onOpenChange={(open) => setDropdownOpen(open)}>
      <Tooltip placement="bottom-start">
        <TooltipTrigger>
          <Dropdown.Trigger
            triggerClassName={cn("btn--large btn--secondary w-11", {
              hidden: !imageLoaded,
            })}
          >
            <img src={UpDownLoad} alt={t("v2.defaults.import_export")} onLoad={handleImageLoad} />
          </Dropdown.Trigger>
        </TooltipTrigger>
        <TooltipContent className={cn("react-tooltip-content--sm", { hidden: dropdownOpen })}>
          <span>{t("v2.defaults.import_export")}</span>
        </TooltipContent>
      </Tooltip>

      <Dropdown.Menu>
        <Dropdown.Item
          as="a"
          id={exportId}
          withIcon={<FontAwesomeIcon icon={["far", "download"]} />}
          href={exportUrl}
          download
          onClick={handleClick}
          useActiveStyles
        >
          {t("v2.defaults.export")}
        </Dropdown.Item>
        <Dropdown.Item
          as="a"
          id={importId}
          withIcon={<FontAwesomeIcon icon={["far", "upload"]} />}
          href={importUrl}
          useActiveStyles
        >
          {t("v2.defaults.import")}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export { ImportExportButton }
