import React from "react"

function FormError({ message }: { message: string | undefined }) {
  if (!message) {
    return null
  }
  return (
    <div className="alert alert-critical my-4">
      <span>{message}</span>
    </div>
  )
}

export { FormError }
