import fp from "lodash/fp"

/**
 * Extract the id from the unique key.
 *
 * Examples:
 * "position_importance_high" => "high"
 * "location_1" => "1"
 */
const idFromUniqueKey = (uniqueKey: string) => {
  const lastUnderscoreIndex = uniqueKey.lastIndexOf("_")

  if (lastUnderscoreIndex === -1) {
    throw new Error("Attempted to extract id from invalid unique key")
  }

  return uniqueKey.substring(lastUnderscoreIndex + 1)
}

const uniqueKeyFromEntityAndId = (entity: string, id: string | number) => `${entity}_${id}`

/**
 * Extracts a RTK Query tag from the unique key.
 *
 * @example
 * ```ts
 * rtkQueryTagFromUniqueKey("position_type_1")
 * //=> { type: "PositionType", id: "1" }
 * rtkQueryTagFromUniqueKey("position_type_1", { entityType: "PositionTypeDetails" })
 * //=> { type: "PositionTypeDetails", id: "1" }
 * ```
 */
const rtkQueryTagFromUniqueKey = (uniqueKey: string, options: { entityType?: string } = {}) => {
  const entity = entityFromUniqueKey(uniqueKey)
    .split("_")
    .map((string) => fp.startCase(string))
    .join("")
  const id = idFromUniqueKey(uniqueKey)

  return { type: options.entityType ?? entity, id }
}

/**
 * Extract the entity from the unique key.
 *
 * Examples:
 * "position_importance_high" => "position_importance"
 * "location_1" => "location"
 */
const entityFromUniqueKey = (uniqueKey: string) => {
  const lastUnderscoreIndex = uniqueKey.lastIndexOf("_")

  if (lastUnderscoreIndex === -1) {
    throw new Error("Attempted to extract entity from invalid unique key")
  }

  return uniqueKey.substring(0, lastUnderscoreIndex)
}

/**
 * Extracts the entity of options for a given key; returns the given key
 * otherwise.
 *
 * Examples:
 * "org_unit_type_1" => "org_unit"
 * "location" => "location"
 */
const optionEntityFromUniqueKeyOrGiven = (uniqueKey: string) =>
  uniqueKey.startsWith("org_unit_type_") ? "org_unit" : uniqueKey

/**
 * Tries to extract the entity type and id from a given key; if either cannot
 * be extracted, returns `null`.
 *
 * @example
 * ```ts
 * tryParseEntityAndId("position_1")
 * //=> { entity: "position", id: "1" }
 * tryParseEntityAndId("position_1", { only: "position" })
 * //=> { entity: "position", id: "1" }
 * tryParseEntityAndId("position_1", { only: ["position", "person"] })
 * //=> { entity: "position", id: "1" }
 * tryParseEntityAndId("position_1", { only: "person" })
 * //=> null
 * tryParseEntityAndId(null)
 * //=> null
 * ```
 */
const tryParseEntityAndId = (
  maybeUniqueKey?: string | null,
  options?: { only?: string | string[] },
) => {
  try {
    if (fp.isNil(maybeUniqueKey) || !isUniqueKey(maybeUniqueKey)) return null

    const entity = entityFromUniqueKey(maybeUniqueKey)
    if (options?.only) {
      if (typeof options.only === "string" && options.only !== entity) return null
      if (Array.isArray(options.only) && !options.only.includes(entity)) return null
    }

    return { entity, id: idFromUniqueKey(maybeUniqueKey) }
  } catch {
    return null
  }
}

/**
 * Check if the input is a unique key.
 *
 * Examples:
 * "position_importance_high" => true
 * "location_1" => true
 * "location" => false
 */
const isUniqueKey = (input: string | null | undefined): boolean => {
  if (!input) return false

  const pattern = /^\w+_[^\s_]+$/
  return pattern.test(input)
}

/**
 * Extract the id from the unique key, or return the input if it is not a unique key.
 *
 * Examples:
 * "position_importance_high" => "high"
 * "location_1" => "1"
 * "location" => "location"
 */
const maybeGetIDFromUniqueKey = (input: string | null | undefined) => {
  if (!input) return null

  return isUniqueKey(input) ? idFromUniqueKey(input) : input
}

export {
  idFromUniqueKey,
  entityFromUniqueKey,
  isUniqueKey,
  maybeGetIDFromUniqueKey,
  optionEntityFromUniqueKeyOrGiven,
  rtkQueryTagFromUniqueKey,
  tryParseEntityAndId,
  uniqueKeyFromEntityAndId,
}
