import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React, { useRef } from "react"
import { useTranslation } from "react-i18next"
import { useBoolean } from "usehooks-ts"

import { AllowedAttribute } from "types/graphql"
import { AddPositionFormRowModal } from "v2/react/components/headcountPlanning/AddPositionFromRowModal"
import { DisplayValueCell } from "v2/react/components/headcountPlanning/TableDatasheet/DisplayValueCell"
import { useCurrentActiveSession } from "v2/react/hooks/useSessionHooks"
import { Dropdown } from "v2/react/shared/collection/menus/Dropdown/Dropdown"

import { HeadcountPlanDatasheetRow } from "./types"

type Props = {
  allowedAttributes?: AllowedAttribute[]
  headcountPlanId: string
  row: HeadcountPlanDatasheetRow
}

const DEFAULT_ALLOWED_ATTRIBUTES: AllowedAttribute[] = []

export function FinalizedPositionMenuCell({ allowedAttributes, headcountPlanId, row }: Props) {
  const { t } = useTranslation()
  const containerRef = useRef<HTMLDivElement | null>(null)
  const {
    setFalse: hideAddPositionModal,
    setTrue: showAddPositionModal,
    value: isAddPositionModalShown,
  } = useBoolean()
  const isEitherMenuOptionAvailable = row.canCreateChartPosition || row.canCreateJobRequisition
  const canShowMenu = !row.position && !row.createdPosition && isEitherMenuOptionAvailable
  const { officialChartKey } = useCurrentActiveSession()

  if (!canShowMenu)
    return <DisplayValueCell className="non-editable-cell" style={{ borderLeft: "none" }} />

  return (
    <DisplayValueCell className="non-editable-cell" style={{ borderLeft: "none" }}>
      <div ref={containerRef} className="relative">
        <Dropdown>
          <Dropdown.Trigger triggerClassName={cn("btn--icon btn--ghost")}>
            <FontAwesomeIcon icon={["far", "ellipsis-h"]} />
          </Dropdown.Trigger>
          <Dropdown.Menu>
            {row.canCreateJobRequisition && (
              <Dropdown.Item
                onClick={() => {
                  // eslint-disable-next-line no-console
                  console.log("I'll show a modal to create a requisition or some such nonsense")
                }}
                withIcon={<FontAwesomeIcon icon={["far", "pen"]} />}
              >
                {t("v2.headcount_plan.datasheet.create_requisition")}
              </Dropdown.Item>
            )}
            {row.canCreateChartPosition && (
              <Dropdown.Item
                onClick={showAddPositionModal}
                withIcon={<FontAwesomeIcon icon={["far", "plus"]} />}
              >
                {t("v2.headcount_plan.datasheet.add_position")}
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
        {row.canCreateChartPosition && (
          <AddPositionFormRowModal
            allowedAttributes={allowedAttributes ?? DEFAULT_ALLOWED_ATTRIBUTES}
            chartKey={officialChartKey}
            headcountPlanId={headcountPlanId}
            isOpen={isAddPositionModalShown}
            onClose={hideAddPositionModal}
            row={row}
          />
        )}
      </div>
    </DisplayValueCell>
  )
}
