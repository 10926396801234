import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import dayjs from "dayjs"
import { AnimatePresence } from "framer-motion"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { Error, Position, SuccessionPlanComment } from "types/graphql"
import { MotionOpaqueScale } from "v2/react/components/succession/SuccessionPlanPanel/shared/MotionWrappers"
import { TextArea } from "v2/react/shared/forms/TextArea"
import { InlineSpinner } from "v2/react/shared/loaders/Spinner"
import { idFromUniqueKey } from "v2/react/utils/uniqueKey"
import {
  useCreateSuccessionPlanCommentMutation,
  useDeleteSuccessionPlanCommentMutation,
} from "v2/redux/GraphqlApi/SuccessionApi"
import { useAppSelector } from "v2/redux/store"

interface CommentsProps {
  comments: SuccessionPlanComment[]
  position: Position
}

function Comments({ comments, position }: CommentsProps) {
  const { t } = useTranslation()
  const [showTextarea, setShowTextarea] = useState(false)
  const [comment, setComment] = useState("")
  const [errors, setErrors] = useState<Error[]>([])
  const currentPersonId = useAppSelector((state) => state.session.currentPersonId)
  const [createComment, { isLoading }] = useCreateSuccessionPlanCommentMutation()

  const handleCancel = () => {
    setComment("")
    setShowTextarea(false)
  }

  const handleSave = () => {
    createComment({
      positionId: position.id,
      comment: comment.trim(),
    })
      .unwrap()
      .then((result) => {
        if (!result.createSuccessionPlanComment.errors?.length) {
          setComment("")
          setShowTextarea(false)
        } else {
          setErrors(result.createSuccessionPlanComment.errors)
        }
      })
  }

  if (!currentPersonId) return null

  return (
    <div className="module-card succession-plan-panel__comments-container mb-0">
      <div
        className={cn("module-card__header rounded-t-xl bg-primary-3", {
          "rounded-b-xl": !(comments.length > 0 || showTextarea),
        })}
      >
        <div className="text-base-bold">{t("v2.succession_plan_panel.summary.comments")}</div>
        {!showTextarea && (
          <button
            className="btn--icon btn--secondary"
            onClick={() => setShowTextarea(true)}
            type="button"
          >
            <FontAwesomeIcon icon={["far", "plus"]} />
          </button>
        )}
      </div>
      {(comments.length > 0 || showTextarea || isLoading) && (
        <div className="module-card__body gap-0 p-0">
          {showTextarea && (
            <>
              <div className="p-4">
                <TextArea
                  autoFocus
                  defaultValue={comment}
                  errors={errors[0]?.message || ""}
                  id="comment"
                  name="comment"
                  onChange={(event) => setComment(event.target.value)}
                  showErrorMessage={errors.length > 0}
                  textareaClassName="h-24"
                />
              </div>
              <div
                className={cn("justify-end gap-4 border-t-neutral-8 p-4 flex", {
                  "border-b-neutral-8": comments.length > 0,
                })}
              >
                <button
                  className="btn--large btn--secondary"
                  disabled={isLoading}
                  onClick={handleCancel}
                  type="button"
                >
                  {t("v2.defaults.cancel")}
                </button>
                <button
                  className="btn--large btn--primary"
                  disabled={isLoading || comment.trim().length === 0}
                  onClick={handleSave}
                  type="button"
                >
                  {t("v2.defaults.save")}
                </button>
              </div>
            </>
          )}
          {isLoading && (
            <div className="mx-auto my-4">
              <InlineSpinner className="spinner" />
            </div>
          )}
          <AnimatePresence>
            <MotionOpaqueScale classNames="comments" show={comments.length > 0}>
              <CommentsList
                comments={comments || []}
                currentPersonId={currentPersonId}
                positionId={position.id}
              />
            </MotionOpaqueScale>
          </AnimatePresence>
        </div>
      )}
    </div>
  )
}

export { Comments }

interface CommentsListProps {
  comments: SuccessionPlanComment[]
  currentPersonId: number | string
  positionId: string
}

const CommentsList = ({ comments, currentPersonId, positionId }: CommentsListProps) => {
  const { t } = useTranslation()
  const [deleteComment, { isLoading }] = useDeleteSuccessionPlanCommentMutation()

  const handleDeleteComment = (commentId: string) => {
    deleteComment({
      positionId,
      commentId,
    })
  }

  return comments.map((comment, index) => (
    <div
      key={comment.id}
      className={cn("group/comment p-4", { "border-b-neutral-8": index !== comments.length - 1 })}
    >
      <div className="justify-between flex">
        <p className="comment-author text-base-bold">{comment.person.fullName}</p>
        <div className="items-center gap-2 flex">
          <p
            className={cn("text-neutral-64", {
              "mr-8": idFromUniqueKey(currentPersonId.toString()) !== comment.person.id,
            })}
          >
            {dayjs(comment.createdAt).format("MMM DD, YYYY, hh:mm:ss A")}
          </p>
          {idFromUniqueKey(currentPersonId.toString()) === comment.person.id && (
            <button
              className="comment-delete btn--icon btn--ghost tooltip tooltip-right opacity-0 transition-all duration-150 ease-out group-hover/comment:opacity-100"
              disabled={isLoading}
              onClick={() => handleDeleteComment(comment.id)}
              type="button"
            >
              <FontAwesomeIcon icon={["far", "times"]} />
              <div className="tooltip-content tooltip-content--sm">
                {t("v2.succession_plan_panel.summary.remove_comment")}
              </div>
            </button>
          )}
        </div>
      </div>
      <p>{comment.comment}</p>
    </div>
  ))
}
