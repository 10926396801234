import React from "react"

import { AddChangeToHeadcountPlanInput } from "types/graphql.d"
import { HeadcountPlanChangeProjectionAction } from "types/graphql.enums"
import { Modal } from "v2/react/shared/overlay/Modal"
import { useSaveHeadcountPlanPositionMutation } from "v2/redux/GraphqlApi/HeadcountPlanningApi"

interface Props {
  headcountPlanId: string
  isOpen: boolean
  onClose: () => void
  participantId?: string
  priorRevisionNumber: number
  rootEventId?: string | null
}

function ConfirmDeleteModal({
  headcountPlanId,
  isOpen,
  onClose,
  participantId,
  priorRevisionNumber,
  rootEventId,
}: Props) {
  const [deletePosition, { isLoading }] = useSaveHeadcountPlanPositionMutation()

  const handleSubmit = () => {
    const input: AddChangeToHeadcountPlanInput = {
      action: HeadcountPlanChangeProjectionAction.DestroyExisting,
      headcountPlanId,
      headcountPlanParticipantId: participantId,
      revisionNumber: priorRevisionNumber + 1,
      rootEventId,
    }

    const doDeletePosition = async () => {
      const result = await deletePosition({ input }).unwrap()
      if (result.addChangeToHeadcountPlan?.headcountPlanChangeCreated) {
        onClose()
      } else {
        const errors = result.addChangeToHeadcountPlan?.errors || []
        // eslint-disable-next-line no-console
        console.error("Unexpected response", result.data, errors)
      }
    }

    doDeletePosition()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={"delete_position".t("headcount_plan")}
      footer={
        isLoading || !isOpen ? (
          <button className="btn--large btn--critical btn-disabled" type="button">
            {"delete_position_pending".t("headcount_plan")}
          </button>
        ) : (
          <button className="btn--large btn--critical" onClick={handleSubmit} type="button">
            {"delete_position".t("headcount_plan")}
          </button>
        )
      }
    >
      <div className="p-6">{"confirm_delete_position".t("headcount_plan")}</div>
    </Modal>
  )
}

export { ConfirmDeleteModal }
