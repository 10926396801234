import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { ReactNode } from "react"

import { CareerLadder } from "types/graphql"
import { prepareIconClass } from "v2/react/utils/misc"

import { AddJobDefinitionItem } from "./AddJobDefinitionItem"

interface EmptyStateProps {
  careerLadder: CareerLadder
  icon: string
  note: ReactNode
  trackId: string
}

function EmptyState({ careerLadder, icon, note, trackId }: EmptyStateProps) {
  return (
    <div className="flex-col gap-4 py-10 flex">
      <div className="flex-col justify-center py-10 flex">
        <IconCircle icon={icon} />
        <EmptyNote note={note} />
        <AddSection careerLadder={careerLadder} trackId={trackId} />
      </div>
    </div>
  )
}

const IconCircle = ({ icon }: { icon: string }) => (
  <div className="justify-center bg-white flex">
    <div className="icon--round">
      <FontAwesomeIcon icon={prepareIconClass(icon)} className="fa-lg" />
    </div>
  </div>
)

const EmptyNote = ({ note }: { note: ReactNode }) => (
  <div className="my-4 justify-center text-center text-neutral-64 flex">{note}</div>
)

const AddSection = ({ careerLadder, trackId }: { careerLadder: CareerLadder; trackId: string }) => (
  <div className="my-4 justify-center text-center flex">
    <AddJobDefinitionItem
      careerLadderId={careerLadder?.uniqueKey || ""}
      isFocused
      sortOrder={0}
      trackCount={(careerLadder?.careerLadderTracks || []).length}
      trackId={trackId}
      trackLength={0}
    />
  </div>
)

export { EmptyState }
