import React, { FormEvent, useState } from "react"
import { useTranslation } from "react-i18next"

import { Error, JobLevel, JobLevelUpdateInput } from "types/graphql"
import { JobLevelForm } from "v2/react/components/jobLevels/Index/JobLevelForm"
import { Modal } from "v2/react/shared/overlay/Modal"
import { useJobLevelUpdateMutation } from "v2/redux/GraphqlApi/JobLevelApi"

interface Props {
  isOpen: boolean
  jobLevel: JobLevel
  onClose: () => void
}

function EditModal({ isOpen, jobLevel, onClose }: Props) {
  const { t } = useTranslation()
  const [mutate, { isLoading }] = useJobLevelUpdateMutation()
  const [errors, setErrors] = useState<Error[] | undefined | null>([])
  const [codeError, setCodeError] = useState("")

  const handleClose = () => {
    setErrors([])
    setCodeError("")
    onClose()
  }

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()

    const form = event.target as HTMLFormElement
    const formData = new FormData(form)
    const input: JobLevelUpdateInput = {
      code: formData.get("code"),
      jobLevelId: jobLevel.uniqueKey,
      name: formData.get("name"),
      order: Number(formData.get("order")?.toString() || ""),
    } as JobLevelUpdateInput

    const result = await mutate(input).unwrap()

    if (result.jobLevelUpdate?.jobLevel?.id) {
      handleClose()
    } else if (result.jobLevelUpdate?.errors) {
      setErrors(result.jobLevelUpdate?.errors)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={t("v2.job_levels.index.edit_job_level", {
        code: jobLevel.code,
        name: jobLevel.name ? ` - ${jobLevel.name}` : "",
      })}
    >
      <JobLevelForm
        codeError={codeError}
        errors={errors}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        jobLevel={jobLevel}
        setCodeError={setCodeError}
      />
    </Modal>
  )
}

export { EditModal }
