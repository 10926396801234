import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { BioSkill, FeatureFlags } from "types/graphql"
import { ProfilePanelMode } from "types/graphql.enums"
import { ModuleListItem } from "v2/react/components/orgChart/ProfilePanel/ModuleListItem"
import { useSelectedBox } from "v2/react/components/orgChart/ProfilePanel/Settings/hooks/useSelectedBox"
import { AccordionModulePanel } from "v2/react/shared/layout/AccordionModulePanel"
import { AccordionModulePanelOverlay } from "v2/react/shared/layout/AccordionModulePanelOverlay"
import { sanitizeParse } from "v2/react/utils/sanitizeParse"
import { useAppSelector } from "v2/redux/store"

interface BioSkillsProps {
  featureFlags: FeatureFlags
}

function BioSkills({ featureFlags }: BioSkillsProps) {
  const person = useAppSelector((state) => state.profilePanel.person)
  const { currentBox, toggleBox } = useSelectedBox("bio_skills", ProfilePanelMode.Person)
  const [showOverlay, setShowOverlay] = useState(false)
  const [overlayContent, setOverlayContent] = useState<BioSkill | null>(null)
  const { t } = useTranslation()

  if (!currentBox || !person) return null
  if (!featureFlags.bio || !person.bioSkills?.length) return null

  const createStars = (level: number) =>
    Array.from(Array(level), (_, i) => <FontAwesomeIcon key={`star-${i}`} icon={["fas", "star"]} />)

  const headerContent = <div className="title">{t("v2.profile_panel.bio_skills")}</div>
  const overlayHeader = <div className="title">{overlayContent?.name}</div>
  const overlayBody = (
    <>
      <ModuleListItem>
        <div>{t("v2.profile_panel.level_of_expertise")}</div>
        <div>{overlayContent?.level && createStars(overlayContent?.level)}</div>
      </ModuleListItem>
      {overlayContent?.notes && (
        <ModuleListItem>
          <div>
            <div>{t("v2.profile_panel.notes")}</div>
            <div>{sanitizeParse(overlayContent?.notes || "")}</div>
          </div>
        </ModuleListItem>
      )}
    </>
  )

  return (
    <AccordionModulePanel
      headerContent={headerContent}
      additionalToggleAction={toggleBox}
      collapsed={currentBox.collapsed}
      title={currentBox.name}
      showOverlay={showOverlay}
      overlayContent={
        <AccordionModulePanelOverlay
          isOpen={showOverlay}
          closePanel={() => {
            setOverlayContent(null)
            setShowOverlay(false)
          }}
          headerContent={overlayHeader}
        >
          {overlayBody}
        </AccordionModulePanelOverlay>
      }
    >
      <ModuleListItem>
        <div className="flex-wrap gap-2.5 flex">
          {person.bioSkills?.map((skill) => (
            <button
              key={skill.id}
              type="button"
              className="badge--link hover"
              onClick={() => {
                setOverlayContent(skill)
                setShowOverlay(true)
              }}
            >
              <div>{skill.name}</div>
              <div className="gap-0.5 flex">{skill.level && createStars(skill.level)}</div>
            </button>
          ))}
        </div>
      </ModuleListItem>
    </AccordionModulePanel>
  )
}

export { BioSkills }
