import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import parse from "html-react-parser"
import React from "react"
import sanitizeHtml from "sanitize-html"

import { Modal, SaveFooter } from "v2/react/shared/overlay/Modal"
import { useFinalizeHeadcountPlanMutation } from "v2/redux/GraphqlApi/HeadcountPlanningApi"

type Props = {
  canBeFinalized: boolean
  onClick?: () => void
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  display?: "menuItem" | "button"
}
export function FinalizePlanButton({
  canBeFinalized,
  setIsOpen,
  onClick,
  display = "button",
}: Props) {
  return (
    <button
      className={cn("whitespace-nowrap", {
        "btn--large btn--secondary": display === "button",
        "dropdown-menu-link gap-2 flex": display === "menuItem",
        "btn-disabled": !canBeFinalized,
      })}
      type="button"
      onClick={() => {
        setIsOpen(true)
        onClick?.()
      }}
      disabled={!canBeFinalized}
    >
      <div className="justify-self-center">
        <FontAwesomeIcon className="icon--success" icon={["fad", "check-circle"]} />
      </div>
      {"finalize_plan_button".t("headcount_plan")}
    </button>
  )
}

type ModalProps = {
  headcountPlanId: string
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  headcountPlanName: string
}
export function FinalizePlanModal({
  isOpen,
  setIsOpen,
  headcountPlanId,
  headcountPlanName,
}: ModalProps) {
  const [mutate, mutation] = useFinalizeHeadcountPlanMutation()

  const handleSave = () => {
    mutate({
      id: headcountPlanId,
    })
      .unwrap()
      .then((response) => {
        if (response.finalizeHeadcountPlan.success) {
          setIsOpen(false)
        }
      })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title={"finalize_plan_modal_title".t("headcount_plan")}
    >
      <div className="react-modal__body flex-col gap-2 flex">
        <p>
          {parse(
            sanitizeHtml(
              "finalize_plan_modal_body_p1".t("headcount_plan", null, null, null, [
                headcountPlanName,
              ]),
            ),
          )}
        </p>
        <p>{"finalize_plan_modal_body_p2".t("headcount_plan")}</p>
      </div>
      <SaveFooter
        isSaving={mutation.isLoading}
        enabledText={"finalize_plan_button".t("headcount_plan")}
        disabledText={"finalize_plan_button_pending".t("headcount_plan")}
        onCancel={() => setIsOpen(false)}
        onSave={handleSave}
      />
    </Modal>
  )
}
