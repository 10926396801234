import { Table } from "@tanstack/react-table"
import React, { useEffect, useRef } from "react"

import RootProvider from "v2/react/components/RootProvider"

import { ButtonTypes, PaginateButton } from "./PaginateButton"

interface PaginationProps<TData> {
  /** @todo Maybe pass stuff and such */
  onPageChange?: () => void
  table: Table<TData>
}

const PaginationInner = <TData extends object>({ onPageChange, table }: PaginationProps<TData>) => {
  const pageNumbers = getPageNumbers(
    table.getPageCount(),
    table.getState().pagination.pageIndex + 1,
  )

  const currentPage = table.getState().pagination.pageIndex + 1
  const pageChangeCallbackRef = useRef(onPageChange)
  pageChangeCallbackRef.current = onPageChange
  useEffect(() => {
    pageChangeCallbackRef.current?.()
  }, [currentPage])

  return (
    <div className="paginate mt-6">
      <div className="pagination">
        <PaginateButton
          disabled={!table.getCanPreviousPage()}
          handleClick={() => table.previousPage()}
          type={ButtonTypes.Previous}
        />
        {pageNumbers.map((number, index) =>
          number === "..." ? (
            <span key={`paginate-ellipses-${index + number}`}>…</span>
          ) : (
            <PaginateButton
              key={`paginate-button-${number}`}
              disabled={currentPage === number}
              current={currentPage === number}
              handleClick={() => table.setPageIndex((number as number) - 1)}
              type={ButtonTypes.Page}
              pageNumber={number as number}
            />
          ),
        )}
        <PaginateButton
          disabled={!table.getCanNextPage()}
          handleClick={() => table.nextPage()}
          type={ButtonTypes.Next}
        />
      </div>
    </div>
  )
}

export const Pagination = <TData extends object>({
  onPageChange,
  table,
}: PaginationProps<TData>) => (
  <RootProvider>
    <PaginationInner onPageChange={onPageChange} table={table} />
  </RootProvider>
)

// The logic here is meant to emulate the pagination present
// in the will_paginate gem for Rails.
// See: https://github.com/mislav/will_paginate
const getPageNumbers = (totalPages: number, currentPage: number) => {
  const range = []

  for (let i = 1; i <= totalPages; i += 1) {
    // Always display first 2 and last 2
    if (i === 1 || i === 2 || i === totalPages - 1 || i === totalPages) {
      range.push(i)
    }
    // If currentPage is between 1 and 6, display pages 1 through 9
    else if (currentPage <= 6 && i <= 9) {
      range.push(i)
    }
    // If currentPage is between totalPages-5 and totalPages, display last 9 pages
    else if (currentPage >= totalPages - 5 && i >= totalPages - 8) {
      range.push(i)
    }
    // Display four pages on either side of current
    else if (i >= currentPage - 4 && i <= currentPage + 4) {
      range.push(i)
    }
    // Display ellipsis if there are more pages between
    else if (i === 3 && currentPage >= 8) {
      range.push("...")
    } else if (i === totalPages - 2 && currentPage <= totalPages - 7) {
      range.push("...")
    }
  }

  return range
}
