const getPageTopOffsets = () => {
  const alertTop: HTMLDivElement | null = document.querySelector(".alert-top")
  const pageNav: HTMLDivElement | null = document.querySelector(".page-nav")
  const utilityNav: HTMLDivElement | null = document.querySelector(".utility-nav")

  let totalHeaderHeight = 0
  let utilityNavHeight = 0
  if (alertTop) {
    totalHeaderHeight += alertTop.offsetHeight
  }
  if (pageNav) {
    totalHeaderHeight += pageNav.offsetHeight
  }
  if (utilityNav) {
    totalHeaderHeight += utilityNav.offsetHeight
    utilityNavHeight = utilityNav.offsetHeight
  }

  return {
    utilityNavHeight,
    totalHeaderHeight,
  }
}

export { getPageTopOffsets }
