import React from "react"

import {
  DrawerTabFragment,
  DrawerTabFragmentProps,
} from "v2/react/components/orgChart/SuperPanel/DrawerTabFragment"

interface DrawerTabProps extends DrawerTabFragmentProps {
  id: string
}

function DrawerTab({
  children,
  contentClassName,
  id,
  isActive,
  onClickClose,
  title,
}: DrawerTabProps) {
  return (
    <div id={id} className="drawer-contents panel grid-rows-[auto_1fr] grid">
      <DrawerTabFragment
        contentClassName={contentClassName}
        isActive={isActive}
        onClickClose={onClickClose}
        title={title}
      >
        {children}
      </DrawerTabFragment>
    </div>
  )
}

export { DrawerTab }
