import { useEffect } from "react"

import { UpdateProfilePanelModeSettingInput } from "types/graphql"
import { ProfilePanelMode } from "types/graphql.enums"
import { useLazyGetPersonQuery, useLazyGetPositionQuery } from "v2/redux/GraphqlApi"
import { useUpdateProfilePlanModeSettingMutation } from "v2/redux/GraphqlApi/PeopleApi"
import {
  setProfilePanelMode,
  setProfilePerson,
  setProfilePosition,
} from "v2/redux/slices/ProfilePanelSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

/**
 * Handles the data and effects for setting the person and/or position on the
 * Profile Panel.
 */

function usePersonPositionLoader(personId?: string | null, positionId?: string | null) {
  const dispatch = useAppDispatch()
  const person = useAppSelector((state) => state.profilePanel.person)
  const position = useAppSelector((state) => state.profilePanel.position)
  const [updateMode] = useUpdateProfilePlanModeSettingMutation()

  // These are needed and used as an effect to support legacy views triggering a
  // reload. "When the trigger function returned from a LazyQuery is called, it
  // always initiates a new request to the server even if there is cached data.
  // Set preferCacheValue(the second argument to the function) as true if you
  // want it to immediately return a cached value if one exists."
  // See: https://redux-toolkit.js.org/rtk-query/api/created-api/hooks#uselazyquery
  const [queryPersonTrigger, { data: personData }] = useLazyGetPersonQuery({})
  const [queryPositionTrigger, { data: positionData }] = useLazyGetPositionQuery({})

  useEffect(() => {
    if (personId) queryPersonTrigger({ personId })
  }, [personId, queryPersonTrigger])

  useEffect(() => {
    if (positionId) queryPositionTrigger({ positionId })
  }, [positionId, queryPositionTrigger])

  useEffect(() => {
    if (!personId) dispatch(setProfilePerson(null))
    if (!personId && !position?.people?.length) {
      dispatch(setProfilePanelMode(ProfilePanelMode.Position))
      const input: UpdateProfilePanelModeSettingInput = {
        profilePanelMode: ProfilePanelMode.Position,
      }
      updateMode(input)
    }
  }, [personId, position, dispatch, updateMode])

  useEffect(() => {
    if (!positionId) dispatch(setProfilePosition(null))
    if (!positionId && !person?.positions?.length) {
      dispatch(setProfilePanelMode(ProfilePanelMode.Person))
      const input: UpdateProfilePanelModeSettingInput = {
        profilePanelMode: ProfilePanelMode.Person,
      }
      updateMode(input)
    }
  }, [positionId, person, dispatch, updateMode])

  useEffect(() => {
    if (personData?.person) dispatch(setProfilePerson(personData.person))
  }, [personData, dispatch])

  useEffect(() => {
    if (positionData?.position) dispatch(setProfilePosition(positionData.position))
  }, [positionData, dispatch])

  return {
    person,
    position,
    queryPersonTrigger,
    queryPositionTrigger,
  }
}

/**
 * Handles the data and effects for setting the person and/or position on the
 * Profile Panel.
 */

function usePositionLoader(positionId?: string | null) {
  const dispatch = useAppDispatch()
  const position = useAppSelector((state) => state.profilePanel.position)
  const [updateMode] = useUpdateProfilePlanModeSettingMutation()

  // These are needed and used as an effect to support legacy views triggering a
  // reload. "When the trigger function returned from a LazyQuery is called, it
  // always initiates a new request to the server even if there is cached data.
  // Set preferCacheValue(the second argument to the function) as true if you
  // want it to immediately return a cached value if one exists."
  // See: https://redux-toolkit.js.org/rtk-query/api/created-api/hooks#uselazyquery
  const [queryPositionTrigger, { data: positionData }] = useLazyGetPositionQuery({})

  useEffect(() => {
    if (positionId) queryPositionTrigger({ positionId })
  }, [positionId, queryPositionTrigger])

  useEffect(() => {
    if (!positionId) dispatch(setProfilePosition(null))
  }, [positionId, dispatch, updateMode])

  useEffect(() => {
    if (positionData?.position) dispatch(setProfilePosition(positionData.position))
  }, [positionData, dispatch])

  return {
    position,
    queryPositionTrigger,
  }
}

export { usePersonPositionLoader, usePositionLoader }
