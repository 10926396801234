const defineParentPageNumTriangleMarker = function (
  svg: d3.Selection<SVGElement>,
): d3.Selection<SVGElement> {
  svg
    .select("defs")
    .append("svg:marker")
    .attr("id", "parent-page-number-marker")
    .attr("refX", 1.5)
    .attr("refY", 1.5)
    .attr("markerWidth", 10)
    .attr("markerHeight", 10)
    .attr("orient", "auto")
    .append("path")
    .attr("d", "M .5 .5 2.5 1.5 .5 2.5 z")
    .style("stroke-linejoin", "round")
    .style("stroke-linecap", "round")
    .style("stroke-width", ".5")
    .style("stroke", "#999")
    .style("fill", "#999")

  return svg
}

export default defineParentPageNumTriangleMarker
