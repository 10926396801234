import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import fp from "lodash/fp"
import React from "react"
import { useTranslation } from "react-i18next"

import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"

type ListItemDiffEntryProps = {
  label: React.ReactNode
  current: React.ReactNode
  initial: React.ReactNode
}

const ChangedFieldIcon = ({ initial }: Pick<ListItemDiffEntryProps, "initial">) => {
  const { t } = useTranslation()
  const blankValue = t("v2.defaults.none")
  const finalInitial = coerceToValueOrBlank(initial, blankValue)

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <div className="mx-1 inline-block">
          <FontAwesomeIcon
            icon={["far", "exclamation-triangle"]}
            className="fa-w-18 text-2xl text-status-caution"
          />
        </div>
      </TooltipTrigger>
      <TooltipContent className="react-tooltip-content max-w-[308px]">
        <div className="flex-col flex">
          <div className="text-neutral-3-solid">
            {t("v2.defaults.previously_was", { value: finalInitial })}
          </div>
        </div>
      </TooltipContent>
    </Tooltip>
  )
}

function ListItemDiffEntry({ current, initial, label }: ListItemDiffEntryProps) {
  const { t } = useTranslation()

  const blankValue = t("v2.defaults.none")
  const finalInitial = coerceToValueOrBlank(initial, blankValue)
  const finalCurrent = coerceToValueOrBlank(current, blankValue)

  return (
    <li className="mb-0">
      {label}: <s>{finalInitial}</s> &rarr; <strong>{finalCurrent}</strong>
    </li>
  )
}

function coerceToValueOrBlank<Value>(value: Value, blank: string) {
  return fp.isNil(value) || (typeof value === "string" && value.trim() === "") ? blank : value
}

export { ChangedFieldIcon, ListItemDiffEntry, ListItemDiffEntryProps }
