import { isNil } from "lodash"
import React, { ChangeEvent, useState } from "react"
import { useTranslation } from "react-i18next"

import { FilterOption } from "types/graphql"
import { FilterDataType } from "types/graphql.enums"
import { CurrencyRangeInput } from "v2/react/shared/forms/CurrencyRangeInput"
import { NumericRangeInput } from "v2/react/shared/forms/NumericRangeInput"
import { PercentRangeInput } from "v2/react/shared/forms/PercentRangeInput"
import type { FilterPanelState } from "v2/react/shared/tables/TableUtilities/FilterTable/hooks/useFilterPanelState"
import { RangeValue } from "v2/react/shared/tables/TableUtilities/FilterTable/utils/filters"
import { RemovableField } from "v2/react/shared/tables/TableUtilities/shared/RemovableField"

interface RangeFilterProps {
  type: FilterDataType.Numeric | FilterDataType.Percentage | FilterDataType.Currency
  field: FilterOption
  values: RangeValue
  onRemove: (id: string) => void
  onSelect?: (filterId: string, options: RangeValue) => void
  updateFieldErrors?: FilterPanelState["updateFieldErrors"]
}

function RangeFilter({
  type,
  field,
  values,
  onRemove,
  onSelect,
  updateFieldErrors,
}: RangeFilterProps) {
  const { t } = useTranslation()
  const [error, setError] = useState("")
  const { min: minValue, max: maxValue } = values

  const handleMinChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = getValue(e.target.value)

    onSelect?.(field.id, { min: value, max: maxValue })
    validate(value, maxValue)
  }

  const handleMaxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = getValue(e.target.value)

    onSelect?.(field.id, { min: minValue, max: value })
    validate(minValue, value)
  }

  const validate = (min: number | undefined, max: number | undefined) => {
    if (!isNil(max) && !isNil(min) && min > max) {
      setError(t("v2.errors.messages.min_lte_max"))
      updateFieldErrors?.(field.id, "add")
    } else {
      setError("")
      updateFieldErrors?.(field.id, "remove")
    }
  }

  const rangeProps = {
    minId: `${field.id}_min`,
    minName: "min",
    minDefaultValue: minValue,
    minOnChange: handleMinChange,
    minPlaceHolder: t("v2.defaults.min"),
    maxId: `${field.id}_max`,
    maxName: "max",
    maxDefaultValue: maxValue,
    maxOnChange: handleMaxChange,
    maxPlaceHolder: t("v2.defaults.max"),
    error,
    fieldLabel: field.label,
  }

  return (
    <RemovableField key={field.id} id={field.id} removeField={onRemove}>
      <div className="mb-6">{RangeInput(type)(rangeProps)}</div>
    </RemovableField>
  )
}

const RangeInput = (type: RangeFilterProps["type"]) =>
  (() => {
    switch (type) {
      case FilterDataType.Currency:
        return CurrencyRangeInput
      case FilterDataType.Numeric:
        return NumericRangeInput
      case FilterDataType.Percentage:
        return PercentRangeInput
      default:
        return NumericRangeInput
    }
  })()

const getValue = (value: string | undefined) =>
  !isNil(value) && value.trim() !== "" ? Number(value) : undefined

export { RangeFilter }
