import { motion } from "framer-motion"
import React from "react"
import { useTranslation } from "react-i18next"

import { EndOfLadder } from "v2/react/components/opportunities/Index/Card"
import { JobFamilyDisplay } from "v2/react/components/opportunities/Index/JobFamilyDisplay"
import { CareerLadderPositionType } from "v2/react/components/opportunities/Index/LadderSection"
import { TruncatedTitle } from "v2/react/components/opportunities/Index/TruncatedTitle"
import { idFromUniqueKey } from "v2/react/utils/uniqueKey"
import { UrlHelper } from "v2/react/utils/urls"

interface Props {
  currentPosition?: boolean
  positionType: CareerLadderPositionType | null
}

function HalfCard({ currentPosition, positionType }: Props) {
  const { t } = useTranslation()

  if (!positionType) {
    return null
  }

  return (
    <motion.a
      href={
        positionType.uniqueKey
          ? UrlHelper.opportunityDetailsPath(idFromUniqueKey(positionType.uniqueKey))
          : "#"
      }
      role="button"
      aria-label={`View details for ${positionType.title}`}
      className="card hover:elevation--overlay focus:elevation--overlay min-h-[5.5rem] justify-between overflow-hidden p-0 text-neutral-100 flex hover:no-underline"
      whileHover={{ scale: 1.015 }}
    >
      <div className="overflow-hidden py-4 pl-4 pr-3">
        <TruncatedTitle title={positionType?.title ?? ""} titleClasses="text-base-bold" />
        <JobFamilyDisplay
          jobFamily={positionType?.jobFamily?.name ?? ""}
          parentFamily={positionType?.jobFamily?.parent?.name ?? ""}
        />
        <div className="badge--blue badge--sm mt-2 text-sm">
          {t("v2.opportunities.index.position_count", {
            count: positionType?.totalPositionCount || 0,
          })}
        </div>
      </div>

      {positionType?.endOfLadder && <EndOfLadder currentPosition={currentPosition || false} />}
    </motion.a>
  )
}

export { HalfCard }
