import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import deleteAll from "images/delete-all.png"
import deleteAndMove from "images/delete-and-move.png"
import React from "react"
import { useTranslation } from "react-i18next"

import { DestroyPositionMode } from "types/graphql.enums"

interface SubordinatesProps {
  onSelect: (option: DestroyPositionMode) => void
  selectedRemoveOption?: DestroyPositionMode | null
}
const Subordinates = ({ onSelect, selectedRemoveOption }: SubordinatesProps) => {
  const { t } = useTranslation()

  return (
    <div className="p-6">
      <p>{t("v2.profile_panel.remove_position_manages")}</p>
      <div className="flex-row justify-center gap-8 pt-4 flex">
        <button
          className="flex-1 bg-transparent p-0 shadow-none"
          onClick={() => onSelect(DestroyPositionMode.MoveChildrenUp)}
          type="button"
        >
          <div
            className={classNames("module selector-module", {
              selected: selectedRemoveOption === DestroyPositionMode.MoveChildrenUp,
            })}
          >
            <div className="module-body !rounded-[0.75rem] bg-white text-center">
              <img src={deleteAndMove} alt="" />
              <hr />
              <p className="text-center">{t("v2.profile_panel.remove_move")}</p>
            </div>
            <div className="check">
              <FontAwesomeIcon icon={["far", "check"]} />
            </div>
          </div>
        </button>
        <button
          className="flex-1 bg-transparent p-0 shadow-none"
          onClick={() => onSelect(DestroyPositionMode.DestroyAllChildren)}
          type="button"
        >
          <div
            className={classNames("module selector-module", {
              selected: selectedRemoveOption === DestroyPositionMode.DestroyAllChildren,
            })}
          >
            <div className="module-body !rounded-[0.75rem] bg-white text-center">
              <img src={deleteAll} alt="" />
              <hr />
              <p className="text-center">{t("v2.profile_panel.remove_all")}s </p>
            </div>
            <div className="check">
              <i className="far fa-check" />
            </div>
          </div>
        </button>
      </div>
    </div>
  )
}

export { Subordinates }
