import { skipToken } from "@reduxjs/toolkit/dist/query"
import fp from "lodash/fp"
import { useEffect } from "react"

import { useGetColorCodesQuery } from "v2/redux/GraphqlApi"
import { selectColorCodesQueryVariables } from "v2/redux/slices/VisualizationSlice/visualizationSelectors"
import { useAppSelector } from "v2/redux/store"

/**
 * Shared hook that wraps the standard `useGetColorCodesQuery`. Pass
 * `useLegacyEventsToTriggerRefetch` as true to refetch when a common modal
 * triggers a save event. This is done to support our chart views.
 */
function useColorCodesQueryWithLegacySupport(useLegacyEventsToTriggerRefetch: boolean) {
  const { key, inDomains } = useAppSelector(selectColorCodesQueryVariables)
  const { isFetching, refetch } = useGetColorCodesQuery(key ? { key, inDomains } : skipToken)

  useEffect(() => {
    if (!useLegacyEventsToTriggerRefetch) return fp.noop

    const $modalContainer = window.$("[data-pjax-container=modal]")
    const modalsOfInterest = "[data-modal=standard], [data-modal=chart_section]"
    $modalContainer.on("modal:save", modalsOfInterest, refetch)

    return () => $modalContainer.off("modal:save", modalsOfInterest, refetch)
  }, [refetch, useLegacyEventsToTriggerRefetch])

  return { isFetching }
}

export { useColorCodesQueryWithLegacySupport }
