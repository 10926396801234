import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React from "react"

import { Dropdown } from "v2/react/shared/collection/menus/Dropdown/Dropdown"
import { Link } from "v2/react/shared/navigation/PageNav/LinkGroup"

interface Props {
  classes?: string
  links: Link[]
  menuText: string
}

function LinkMenuGroup({ classes, links, menuText }: Props) {
  return (
    <Dropdown align="right">
      <Dropdown.Trigger
        triggerClassName={classNames("btn dropdown-link items-center gap-1 !flex", classes)}
      >
        <span>{menuText}</span>
        <FontAwesomeIcon icon={["far", "chevron-down"]} />
      </Dropdown.Trigger>
      <Dropdown.Menu>
        {links.map((link: Link) => (
          <Dropdown.Item
            useActiveStyles
            key={link.url}
            as="a"
            href={link.url}
            withIcon={
              link.active && (
                <FontAwesomeIcon icon={["fas", "check-circle"]} className="selected-icon ml-auto" />
              )
            }
            iconPosition="right"
          >
            {link.text}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export { LinkMenuGroup }
