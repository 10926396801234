/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { camelCase } from "lodash"

import {
  AddChangeToHeadcountPlanInput,
  AddChangeToHeadcountPlanMutation,
  EliminateHeadcountPlanPositionInput,
  FinalizeHeadcountPlanInput,
  FinalizeHeadcountPlanMutation,
  HeadcountPlansCreateChartPositionInput,
  HeadcountPlansCreateChartPositionMutation,
  HeadcountPlansGetInitialChartPositionDataQuery,
  HeadcountPlansGetInitialChartPositionDataQueryVariables,
  HeadcountPlanTimelineOptionsQuery,
  HeadcountPlanTimelineOptionsQueryVariables,
  ListHeadcountPlansPageQuery,
  ListHeadcountPlansPageQueryVariables,
  OwnerOrgChartQuery,
  OwnerOrgChartQueryVariables,
  ParticipantOrgChartQuery,
  ParticipantOrgChartQueryVariables,
  SaveHeadcountPlanInput,
  StartNewHeadcountPlanInput,
  SubmitHeadcountPlanProposalInput,
  TimelinePageQuery,
  TimelinePageQueryVariables,
  TogglePositionExclusionInput,
  UpdateHeadcountPlanCollaboratorsInput,
  UpdateHeadcountPlanInput,
  UpdatePersonHeadcountPlanningSettingsInput,
} from "types/graphql.d"
import OperationStore from "v2/operation_store"
import { errorFor } from "v2/react/utils/errors"
import { GraphqlApi } from "v2/redux/GraphqlApi"
import { flatMutationOperation, mutationOperation, queryOperation } from "v2/redux/utils/endpoints"

const addChangeToHeadcountPlanOperationId = OperationStore.getOperationId(
  "AddChangeToHeadcountPlan",
)
const eliminateHeadcountPlanPositionOperationId = OperationStore.getOperationId(
  "EliminateHeadcountPlanPosition",
)
const getHeadcountPlanForEditOperationId = OperationStore.getOperationId("HeadcountPlanForEdit")
const getHeadcountPlanDefaultAttributesOperationId = OperationStore.getOperationId(
  "HeadcountPlanDefaultAttributes",
)
const getHeadcountPlanOperationId = OperationStore.getOperationId("HeadcountPlan")
const getParticipantDatasheetPageDataOperationId = OperationStore.getOperationId(
  "ParticipantDatasheetPage",
)
const getHeadcountPlanAllowedAttributesOperationId = OperationStore.getOperationId(
  "HeadcountPlanAllowedAttributes",
)
const getHeadcountPlanTimelineOptionsOperationId = OperationStore.getOperationId(
  "HeadcountPlanTimelineOptions",
)
const getListHeadcountPlansPageDataOperationId =
  OperationStore.getOperationId("ListHeadcountPlansPage")
const getOwnerDatasheetPageDataOperationId = OperationStore.getOperationId("OwnerDatasheetPage")
const saveHeadcountPlanOperationId = OperationStore.getOperationId("SaveHeadcountPlan")
const saveHeadcountPlanMembersOperationId = OperationStore.getOperationId(
  "SaveHeadcountPlanMembers",
)
const saveHeadcountPlanPositionOperationId = OperationStore.getOperationId(
  "AddChangeToHeadcountPlan",
)
const timelinePageOperationId = OperationStore.getOperationId("TimelinePage")
const startNewHeadcountPlanOperationId = OperationStore.getOperationId("StartNewHeadcountPlan")
const submitHeadcountPlanProposalOperationId = OperationStore.getOperationId(
  "SubmitHeadcountPlanProposal",
)
const togglePositionExclusionOperationId = OperationStore.getOperationId("TogglePositionExclusion")

const participantFragmentAtDepth = (depth: number): string => {
  if (depth === 0) {
    return `{
        ...participantAttributesNoChildren
      }`
  }
  return `{
      ...participantAttributesNoChildren
      children
        ${participantFragmentAtDepth(depth - 1)}
    }
    `
}

const TreeToDepthQuery = (maxChartDepth: number) => `
  fragment personAttributes on Person {
      avatarThumbUrl
      id
      name
      workEmail
    }
    fragment participantAttributesNoChildren on HeadcountPlanParticipant {
      id
      role
      position {
        id
        title
        orgUnits {
          id
          name
          orgUnitType {
            id
            name
          }
        }
      }
      status
      person {
        ...personAttributes
      }
      people {
        ...personAttributes
      }
    }
    fragment participantAttributes on HeadcountPlanParticipant
      ${participantFragmentAtDepth(maxChartDepth)}
    query HeadcountPlanWithTree($id: ID!, $rootPositionId: ID, $participantIds: [ID!]) {
      headcountPlan(id: $id) {
        endDate
        id
        currentUserCanManage
        lockedAt
        name
        startDate
        canBeFinalized
        planAggregations {
          newPositionsCount
        }
        company {
          id
          logoThumbUrl
          name
        }
        participants {
          id
          status
        }
        allPositionsInPlan {
          status
          type
        }
        participantsTree(rootPositionId: $rootPositionId, participantIds: $participantIds) {
          ...participantAttributes
        }
      }
    }
  `

export const HeadcountPlanningApi = GraphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    addChangeToHeadcountPlan: builder.mutation({
      query: (params: { input: AddChangeToHeadcountPlanInput }) => ({
        operationId: addChangeToHeadcountPlanOperationId,
        variables: { input: params.input },
      }),
      invalidatesTags: ["HeadcountPlanChanges"],
    }),
    eliminateHeadcountPlanPosition: builder.mutation({
      query: (params: { input: EliminateHeadcountPlanPositionInput }) => ({
        operationId: eliminateHeadcountPlanPositionOperationId,
        variables: { input: params.input },
      }),
      invalidatesTags: ["HeadcountPlanChanges"],
    }),
    finalizeHeadcountPlan: builder.mutation<
      FinalizeHeadcountPlanMutation,
      FinalizeHeadcountPlanInput
    >({
      query: (params: { id: string }) => ({
        operationId: OperationStore.getOperationId("FinalizeHeadcountPlan"),
        variables: { id: params.id },
      }),
      invalidatesTags: ["HeadcountPlan"],
    }),
    getHeadcountPlan: builder.query({
      query: (params: { id: string }) => ({
        operationId: getHeadcountPlanOperationId,
        variables: { id: params.id },
      }),
    }),
    getParticipantDatasheetPage: builder.query({
      query: (params: { headcountPlanId: string; participantId: string }) => ({
        operationId: getParticipantDatasheetPageDataOperationId,
        variables: { headcountPlanId: params.headcountPlanId, participantId: params.participantId },
      }),
      providesTags: ["HeadcountPlan", "HeadcountPlanChanges"],
    }),
    getParticipantOrgChart: builder.query<
      ParticipantOrgChartQuery,
      ParticipantOrgChartQueryVariables
    >({
      query: queryOperation<ParticipantOrgChartQueryVariables>("ParticipantOrgChart"),
      providesTags: ["HeadcountPlan"],
    }),
    getHeadcountPlanWithTree: builder.query({
      query: (params: {
        id: string
        maxChartDepth: number
        rootPositionId: string | undefined
        participantIds: string[]
      }) => ({
        query: TreeToDepthQuery(params.maxChartDepth),
        operationName: "HeadcountPlanWithTree",
        variables: {
          id: params.id,
          rootPositionId: params.rootPositionId,
          participantIds: params.participantIds,
        },
      }),
      providesTags: ["HeadcountPlan"],
    }),
    getHeadcountPlanForEdit: builder.query({
      query: (params: { id: number }) => ({
        operationId: getHeadcountPlanForEditOperationId,
        variables: { id: params.id },
      }),
    }),
    getHeadcountPlanDefaultAttributes: builder.query({
      query: () => ({
        operationId: getHeadcountPlanDefaultAttributesOperationId,
      }),
    }),
    getHeadcountPlanAllowedAttributes: builder.query({
      query: (params: { id: string }) => ({
        operationId: getHeadcountPlanAllowedAttributesOperationId,
        variables: { id: params.id },
      }),
    }),
    getListHeadcountPlansPage: builder.query<
      ListHeadcountPlansPageQuery,
      ListHeadcountPlansPageQueryVariables
    >({
      query: () => ({
        operationId: getListHeadcountPlansPageDataOperationId,
      }),
    }),
    getOwnerDatasheetPage: builder.query({
      query: (params: { headcountPlanId: string }) => ({
        operationId: getOwnerDatasheetPageDataOperationId,
        variables: { id: params.headcountPlanId },
      }),
      providesTags: ["HeadcountPlan", "HeadcountPlanChanges"],
    }),
    getOwnerOrgChart: builder.query<OwnerOrgChartQuery, OwnerOrgChartQueryVariables>({
      query: queryOperation<OwnerOrgChartQueryVariables>("OwnerOrgChart"),
      providesTags: ["HeadcountPlan"],
    }),
    headcountPlansCreateChartPosition: builder.mutation<
      HeadcountPlansCreateChartPositionMutation,
      HeadcountPlansCreateChartPositionInput
    >({
      query: flatMutationOperation<HeadcountPlansCreateChartPositionInput>(
        "HeadcountPlansCreateChartPosition",
      ),
      invalidatesTags: (_mutation, err) => (err ? [] : ["HeadcountPlan"]),
    }),
    headcountPlansGetInitialChartPositionData: builder.query<
      HeadcountPlansGetInitialChartPositionDataQuery,
      HeadcountPlansGetInitialChartPositionDataQueryVariables
    >({
      query: queryOperation<HeadcountPlansGetInitialChartPositionDataQueryVariables>(
        "HeadcountPlansGetInitialChartPositionData",
      ),
    }),
    saveHeadcountPlan: builder.mutation({
      query: (params: { input: SaveHeadcountPlanInput }) => ({
        operationId: saveHeadcountPlanOperationId,
        variables: { input: params.input },
      }),
    }),
    startNewHeadcountPlan: builder.mutation({
      query: (params: { input: StartNewHeadcountPlanInput }) => ({
        operationId: startNewHeadcountPlanOperationId,
        variables: { input: params.input },
      }),
    }),
    saveHeadcountPlanMembers: builder.mutation({
      query: (params: { input: UpdateHeadcountPlanInput }) => ({
        operationId: saveHeadcountPlanMembersOperationId,
        variables: { input: params.input },
      }),
    }),
    saveHeadcountPlanPosition: builder.mutation({
      query: (params: { input: AddChangeToHeadcountPlanInput }) => ({
        operationId: saveHeadcountPlanPositionOperationId,
        variables: { input: params.input },
      }),
      invalidatesTags: ["HeadcountPlan"],
    }),
    updateHeadcountPlanCollaborators: builder.mutation({
      query: mutationOperation<UpdateHeadcountPlanCollaboratorsInput>(
        "UpdateHeadcountPlanCollaborators",
      ),
      invalidatesTags: ["HeadcountPlan"],
    }),
    updateHeadcountPlanPosition: builder.mutation({
      query: (params: { fieldKey: string; input: AddChangeToHeadcountPlanInput }) => ({
        operationId: saveHeadcountPlanPositionOperationId,
        variables: { input: params.input },
      }),
      transformResponse: (response: AddChangeToHeadcountPlanMutation, _, { fieldKey }) => {
        if (
          response.addChangeToHeadcountPlan.errors &&
          response.addChangeToHeadcountPlan.errors.length
        ) {
          return {
            ok: false,
            error: {
              message:
                errorFor(camelCase(fieldKey), response.addChangeToHeadcountPlan.errors) ??
                "Unknown error",
            },
          }
        }

        return { ok: true } as { ok: true }
      },
      invalidatesTags: ["HeadcountPlan"],
    }),
    getHeadcountPlanTimelineOptions: builder.query<
      HeadcountPlanTimelineOptionsQuery,
      HeadcountPlanTimelineOptionsQueryVariables
    >({
      query: (params) => ({
        operationId: getHeadcountPlanTimelineOptionsOperationId,
        variables: { headcountPlanId: params.headcountPlanId },
      }),
    }),
    getTimelinePage: builder.query<TimelinePageQuery, TimelinePageQueryVariables>({
      query: (params) => ({
        operationId: timelinePageOperationId,
        variables: {
          headcountPlanId: params.headcountPlanId,
          participantId: params.participantId,
          intervalType: params.intervalType,
          metrics: params.metrics,
          groupBy: params.groupBy,
        },
      }),
    }),
    submitHeadcountPlanProposal: builder.mutation({
      query: (params: { input: SubmitHeadcountPlanProposalInput }) => ({
        operationId: submitHeadcountPlanProposalOperationId,
        variables: { input: params.input },
      }),
      invalidatesTags: ["HeadcountPlan"],
    }),
    togglePositionExclusion: builder.mutation({
      query: (params: { input: TogglePositionExclusionInput }) => ({
        operationId: togglePositionExclusionOperationId,
        variables: { input: params.input },
      }),
      invalidatesTags: ["HeadcountPlan", "HeadcountPlanChanges"],
    }),
    updatePersonHeadcountPlanningSettings: builder.mutation({
      query: mutationOperation<UpdatePersonHeadcountPlanningSettingsInput>(
        "UpdatePersonHeadcountPlanningSettings",
      ),
      invalidatesTags: ["HeadcountPlan"],
    }),
  }),
})

export const {
  useAddChangeToHeadcountPlanMutation,
  useEliminateHeadcountPlanPositionMutation,
  useFinalizeHeadcountPlanMutation,
  useGetHeadcountPlanAllowedAttributesQuery,
  useGetHeadcountPlanDefaultAttributesQuery,
  useGetHeadcountPlanForEditQuery,
  useGetHeadcountPlanQuery,
  useGetHeadcountPlanTimelineOptionsQuery,
  useGetHeadcountPlanWithTreeQuery,
  useGetListHeadcountPlansPageQuery,
  useGetOwnerDatasheetPageQuery,
  useGetOwnerOrgChartQuery,
  useGetParticipantDatasheetPageQuery,
  useGetParticipantOrgChartQuery,
  useGetTimelinePageQuery,
  useHeadcountPlansCreateChartPositionMutation,
  useHeadcountPlansGetInitialChartPositionDataQuery,
  useSaveHeadcountPlanMembersMutation,
  useSaveHeadcountPlanMutation,
  useSaveHeadcountPlanPositionMutation,
  useStartNewHeadcountPlanMutation,
  useSubmitHeadcountPlanProposalMutation,
  useTogglePositionExclusionMutation,
  useUpdateHeadcountPlanPositionMutation,
  useUpdateHeadcountPlanCollaboratorsMutation,
  useUpdatePersonHeadcountPlanningSettingsMutation,
} = HeadcountPlanningApi
