import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import RootProvider from "v2/react/components/RootProvider"
import { SuccessionMatrixModal } from "v2/react/components/succession/SuccessionPlanPanel/Matrix/Modal/SuccessionMatrixModal"
import { MatrixMedium } from "v2/react/components/successionSettings/MatrixMedium"
import { useGetSuccessionMatrixSettingsQuery } from "v2/redux/GraphqlApi"
import {
  AxisModeSchema,
  CardTypeSchema,
  GridItemSchema,
  PaletteSchema,
  setAxisMode,
  setCardType,
  setGridItems,
  setPalette,
} from "v2/redux/slices/MatrixSlice"
import { useAppDispatch } from "v2/redux/store"

function WithProvider() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { data: matrix } = useGetSuccessionMatrixSettingsQuery({})
  const [editModalIsOpen, setEditModalIsOpen] = useState(false)

  useEffect(() => {
    if (matrix) {
      dispatch(setCardType(CardTypeSchema.parse(matrix.cardType)))
      dispatch(setPalette(PaletteSchema.parse(matrix.paletteType)))
      dispatch(setGridItems(GridItemSchema.array().parse(matrix.gridItems)))
      dispatch(setAxisMode(AxisModeSchema.parse(matrix.axisMode)))
    }
  }, [matrix, dispatch])

  if (!matrix) return null

  const handleOpenModal = () => setEditModalIsOpen(true)
  const handleCloseModal = () => setEditModalIsOpen(false)

  return (
    <>
      <div className="module-card">
        <div className="module-card__header items-center justify-between flex">
          <div className="text-base-bold">{t("v2.succession_modal.matrix_settings")}</div>
          <button className="btn--sm btn--secondary" onClick={handleOpenModal} type="button">
            {t("v2.defaults.edit")}
          </button>
        </div>
        <div className="module-card__body pb-4">
          <MatrixMedium
            axisMode={AxisModeSchema.parse(matrix.axisMode)}
            cardType={CardTypeSchema.parse(matrix.cardType)}
            gridItems={GridItemSchema.array().parse(matrix.gridItems)}
          />
        </div>
      </div>
      <SuccessionMatrixModal isOpen={editModalIsOpen} onClose={handleCloseModal} matrix={matrix} />
    </>
  )
}

const SuccessionMatrixSettings = () => (
  <RootProvider>
    <WithProvider />
  </RootProvider>
)

export default SuccessionMatrixSettings
