import classNames from "classnames"
import React from "react"

import { CloseButton } from "v2/react/shared/layout/Sidebar/CloseButton"

interface DrawerTabFragmentProps extends React.PropsWithChildren {
  contentClassName?: string
  isActive: boolean
  onClickClose?: () => void
  title: string
}

function DrawerTabFragment({
  children,
  contentClassName,
  isActive,
  onClickClose,
  title,
}: DrawerTabFragmentProps) {
  const contentClass =
    contentClassName ||
    classNames("spreadsheet-drawer-tab drawer-section-content", { "is-inactive": !isActive })

  return (
    <>
      <div className="drawer-header items-center justify-between flex">
        <p className="drawer-title">{title}</p>
        <CloseButton onClose={onClickClose} />
      </div>
      <div className={contentClass}>{children}</div>
    </>
  )
}

export { DrawerTabFragment, DrawerTabFragmentProps }
