import React, { createContext, useContext, useMemo } from "react"

type FormStyleProviderProps = Partial<FormStyleContextState>

type FormStyleContextState = {
  defaultErrorIconPlacement: "top" | "center"
}

const DEFAULT: FormStyleContextState = {
  defaultErrorIconPlacement: "center",
}

const FormStyleContext = createContext<FormStyleContextState>(DEFAULT)

const useFormStyleContext = () => useContext(FormStyleContext)

function FormStyleProvider({
  children,
  defaultErrorIconPlacement,
}: React.PropsWithChildren<FormStyleProviderProps>) {
  const current = useMemo(
    () => ({
      defaultErrorIconPlacement: defaultErrorIconPlacement ?? DEFAULT.defaultErrorIconPlacement,
    }),
    [defaultErrorIconPlacement],
  )

  return <FormStyleContext.Provider value={current}>{children}</FormStyleContext.Provider>
}

export { FormStyleContext, FormStyleProvider, useFormStyleContext }
