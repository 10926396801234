import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { AnyTopicMessage, GraphqlCableState } from "./GraphqlCableSlice/types"
import { channelSubscriptionAdapter } from "./GraphqlCableSlice/utils"

const initialState: GraphqlCableState = {
  ...channelSubscriptionAdapter.getInitialState(),
  mailbox: [],
}

const GraphqlCableSlice = createSlice({
  name: "graphqlCable",
  initialState,
  reducers: {
    subscriptionInitialized: channelSubscriptionAdapter.addOne,
    subscriptionUpdated: channelSubscriptionAdapter.updateOne,
    unsubscribed: channelSubscriptionAdapter.removeOne,
    trackMessage: (state, { payload: message }: PayloadAction<AnyTopicMessage>) => {
      state.mailbox.push(message)
    },
  },
})

export const { unsubscribed, subscriptionInitialized, subscriptionUpdated, trackMessage } =
  GraphqlCableSlice.actions
export { GraphqlCableSlice }
export * from "./GraphqlCableSlice/utils"
export * from "./GraphqlCableSlice/types"
