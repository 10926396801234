import { get } from "lodash"

import {
  FormPositionShape,
  FormShape,
} from "v2/react/components/jobRequisitions/RequisitionForm/types/FormShape"

const parsePositionFieldsForSave = (formData: FormShape): FormShape =>
  parseCurrencyFields(parsExternalIdentifierField(formData))

const parseCurrencyFields = (formData: FormShape): FormShape => {
  const paths = [
    ["position", "basePay", "amount"],
    ["position", "basePay", "hoursPerWeek"],
  ]
  const updated = paths.reduce((accData: FormShape, path: string[]): FormShape => {
    const formValue = get(formData, path)
    if (formValue !== undefined) {
      const parsedCurrency = window.App.Helpers.parseCurrency(formValue)
      const currentCurrency = get(formData, path)
      if (parsedCurrency !== currentCurrency) {
        const positionFieldName = path[path.length - 1]
        const updatedPosition: FormPositionShape = {
          ...accData.position,
          [positionFieldName]: parsedCurrency,
        }
        return { ...accData, position: updatedPosition } as FormShape
      }
    }
    return accData
  }, formData)
  return updated
}

const parsExternalIdentifierField = (formData: FormShape): FormShape => {
  if (formData.schemaType !== "generic") return formData
  const formValue = get(formData, ["position", "externalIdentifier"])
  if (formValue !== undefined) {
    const positionCountValue = Number(get(formData, ["sourceOpenings"]))
    const positionCount = Number.isNaN(positionCountValue) ? 0 : positionCountValue
    // Reset externalId to blank if the sourceOpenings are for more than one
    // position.
    if (!formValue || positionCount > 1) {
      const updatedPosition: FormPositionShape = {
        ...formData.position,
        externalIdentifier: null,
      }
      return { ...formData, position: updatedPosition } as FormShape
    }
  }
  return formData
}

export { parsePositionFieldsForSave }
