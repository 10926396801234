import { FundingSourceAllocation } from "types/graphql.enums"
import { calculatePercent, safeNumber } from "v2/react/utils/safeNumber"

import { useBudgetedTotal } from "./useBudgetedTotal"
import { usePositionWatchField } from "./useConvenientReactHookFormHooks"

type UseFundingSourcePositionValuesArg = {
  index: number
}

type FundingSourcePositionValues = {
  /** Percent of budgeted total allocated to this (value from record). */
  allocatedPercentForThis: number
  /**
   * Percent of budgeted total allocated to this. When the allocation type is
   * "percent_of_budget" then this represents the value from the record.
   * Otherwise this is calculated.
   */
  allocatedPercentForThisActualOrCalculated: number
  /**
   * Amount of the funding source total that is allocated to other positions.
   */
  allocatedToOtherPositions: number
  /** Amount allocated to this (value from record). */
  allocatedToThis: number
  /**
   * Amount allocated to this. When the allocation type is "amount" then this
   * represents the value from the record. Otherwise this is calculated.
   */
  allocatedToThisActualOrCalculated: number
  /**
   * Derived amount using `allocatedPercentForThis` and the budgeted total.
   */
  calculatedAmount: number
  /**
   * Derived percent using `allocatedForThis` and the budgeted total.
   */
  calculatedPercent: number
  /** The funding source's configured amount. */
  fundingSourceTotal: number
  /**
   * The funding source's configured amount less `allocatedToOtherPositions`
   * and `allocatedToThisActualOrCalculated`.
   */
  remainingUnallocated: number
}

function useFundingSourcePositionValues({
  index,
}: UseFundingSourcePositionValuesArg): FundingSourcePositionValues {
  const budgetedTotal = useBudgetedTotal()
  const allocationType = usePositionWatchField({ name: "position.fundingSourceAllocationType" })
  const {
    amount: rawAmount,
    percentOfBudget: rawPercent,
    fundingSourcePosition: fsp,
  } = usePositionWatchField({ name: `position.fundingSourcePositionsAttributes.${index}` })
  const rawAllocatedToOtherPositions =
    fsp?.allocatedToOtherPositionsOnChart ?? fsp?.fundingSource?.amountAllocatedOnChart

  const percentOfBudget = safeNumber(rawPercent)
  const fundingSourceTotal = safeNumber(fsp?.fundingSource?.amount)
  const allocatedToThis = safeNumber(rawAmount)
  const allocatedPercentForThis = safeNumber(rawPercent)
  const allocatedToOtherPositions = safeNumber(rawAllocatedToOtherPositions)

  const calculatedPercent = calculatePercent({
    of: allocatedToThis,
    outOf: fundingSourceTotal,
    orElse: 0,
    forHumans: true,
  })
  const calculatedAmount = (percentOfBudget / 100) * budgetedTotal

  const allocatedPercentForThisActualOrCalculated =
    allocationType === FundingSourceAllocation.PercentOfBudget ? percentOfBudget : calculatedPercent

  const allocatedToThisActualOrCalculated =
    allocationType === FundingSourceAllocation.Amount ? allocatedToThis : calculatedAmount

  const remainingUnallocated =
    fundingSourceTotal - allocatedToOtherPositions - allocatedToThisActualOrCalculated

  return {
    allocatedPercentForThis,
    allocatedPercentForThisActualOrCalculated,
    allocatedToOtherPositions,
    allocatedToThis,
    allocatedToThisActualOrCalculated,
    calculatedAmount,
    calculatedPercent,
    fundingSourceTotal,
    remainingUnallocated,
  }
}

export { useFundingSourcePositionValues }
