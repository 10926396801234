import React from "react"
import { useTranslation } from "react-i18next"

import {
  ProfilePanelBoxSetting,
  ProfilePanelBoxSettingSelection,
  ProfilePanelMode,
} from "types/graphql"

import AdminLabel from "./AdminLabel"

interface SettingsBoxesHiddenProps {
  adminBoxes: ProfilePanelBoxSetting[]
  hiddenBoxes: ProfilePanelBoxSetting[]
  mode: ProfilePanelMode
  onUpdate: (items: ProfilePanelBoxSettingSelection[]) => void
  shownBoxes: ProfilePanelBoxSettingSelection[]
}

const SettingsBoxesHidden: React.FC<SettingsBoxesHiddenProps> = ({
  adminBoxes,
  hiddenBoxes,
  mode,
  onUpdate,
  shownBoxes,
}) => {
  const { t } = useTranslation()

  if (!hiddenBoxes.length) return null

  const handleUpdate = (item: ProfilePanelBoxSetting) => {
    onUpdate([
      ...shownBoxes,
      {
        name: item.name,
        collapsed: false,
        mode,
      },
    ])
  }

  return (
    <>
      <Header />
      <div className="list-group">
        {hiddenBoxes.map((item: ProfilePanelBoxSetting) => (
          <div key={`${item.name}`}>
            <div className="w-full gap-3 px-3 py-2 flex">
              <div className="!m-0 w-full min-w-0 items-start self-stretch font-medium transition-colors flex">
                <div className="w-full min-w-0 break-words">
                  {t(`v2.profile_panel.${item.name}`)}
                </div>
                <AdminLabel adminBoxes={adminBoxes} item={item} t={t} />
              </div>
              <div>
                <button
                  className="btn--sm btn--secondary"
                  type="button"
                  onClick={() => handleUpdate(item)}
                >
                  {t("v2.defaults.show")}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

const Header = () => {
  const { t } = useTranslation()

  return (
    <div className="justify-between py-4 flex">
      <h2 className="text-lg font-medium">{t("v2.profile_panel.hidden_boxes")}</h2>
    </div>
  )
}

export { SettingsBoxesHidden }
