import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import assistant from "images/assistant-position-type.svg"
import standard from "images/standard-position-type.svg"
import React, { useMemo } from "react"

import { FilterOption, Option } from "types/graphql"
import { SelectorModuleButtonGroup } from "v2/react/shared/buttons/SelectorModuleButtonGroup"
import { CheckboxMenu, MenuOption } from "v2/react/shared/CheckboxMenu"
import { RemovableField } from "v2/react/shared/tables/TableUtilities/shared/RemovableField"

const HAS_OPTIONS_WITH_IMAGES = ["position_type", "filled_status"]

interface MultiSelectionFilterProps {
  field: FilterOption
  collection: Option[]
  selectedFilters: string[]
  onRemove: (id: string) => void
  onSelect?: (filterId: string, options: MenuOption<string>[]) => void
}

function MultiSelectionFilter({
  field,
  collection,
  selectedFilters,
  onRemove,
  onSelect,
}: MultiSelectionFilterProps) {
  const filterBy = useMemo(
    () =>
      collection.map((option) => ({
        ...option,
        selected: selectedFilters.includes(option.id),
      })),
    [collection, selectedFilters],
  )

  const isBelowThreshold = (value: number, threshold: number) => value <= threshold
  const charCounts = filterBy.map((option) => option.label.length)

  const handleSelect = (optionId: string) => {
    const newSelection = filterBy.map((opt) =>
      opt.id === optionId ? { ...opt, selected: !opt.selected } : opt,
    )

    onSelect?.(field.id, newSelection)
  }

  switch (true) {
    case !filterBy.length:
      return (
        <RemovableField key={field.id} id={field.id} removeField={onRemove}>
          <CheckboxMenu
            fieldId={field.id}
            fieldLabel={field.label}
            options={filterBy}
            onSelect={handleSelect}
          />
        </RemovableField>
      )
    case filterBy.length <= 2 && charCounts.every((option) => isBelowThreshold(option, 12)):
    case filterBy.length <= 3 && charCounts.every((option) => isBelowThreshold(option, 8)):
    case filterBy.length <= 4 && charCounts.every((option) => isBelowThreshold(option, 5)):
      return (
        <RemovableField key={field.id} id={field.id} removeField={onRemove}>
          <SelectorModuleButtonGroup
            options={filterBy}
            onSelect={handleSelect}
            fieldId={field.id}
            fieldLabel={field.label}
            formatDisplay={
              HAS_OPTIONS_WITH_IMAGES.includes(field.id)
                ? handleImageDisplay(filterBy)
                : (option) => option.label
            }
          />
        </RemovableField>
      )
    default:
      return (
        <RemovableField key={field.id} id={field.id} removeField={onRemove}>
          <CheckboxMenu
            fieldId={field.id}
            fieldLabel={field.label}
            options={filterBy}
            onSelect={handleSelect}
          />
        </RemovableField>
      )
  }
}

const handleImageDisplay = (filterBy: MenuOption<string>[]) => (option: Option) => {
  const { label } = option
  return (
    <div className="items-center gap-1.5 flex">
      <div className="h-4 w-4 place-content-center rounded-sm border border-solid border-neutral-8 text-primary-100 grid">
        {optionImage(option)}
      </div>
      <span className={filterBy.length > 2 ? "text-xs" : "text-base"}>{label}</span>
    </div>
  )
}

const optionImage = (option: Option) => {
  switch (true) {
    case option.id.includes("assistant"):
      return <img className="h-2" src={assistant} alt="" />
    case option.id.includes("standard"):
      return <img className="h-2" src={standard} alt="" />
    case option.id.includes("open"):
      return <FontAwesomeIcon icon={["far", "chair-office"]} className="h-2" />
    case option.id.includes("filled"):
      return <FontAwesomeIcon icon={["far", "id-badge"]} className="h-2" />
    default:
      return <span />
  }
}

export { MultiSelectionFilter }
