import React, { useState } from "react"

import { Spinner } from "v2/react/shared/loaders/Spinner"
import PageNav from "v2/react/shared/navigation/PageNav"
import { ActionBlock } from "v2/react/shared/navigation/PageNav/ActionBlock"
import { TitleBlockLarge } from "v2/react/shared/navigation/PageNav/TitleBlock"
import { getCookie, setCookie } from "v2/react/utils/cookies"
import { UrlHelper } from "v2/react/utils/urls"
import { useGetHeadcountPlanWithTreeQuery } from "v2/redux/GraphqlApi/HeadcountPlanningApi"

import { HeadcountPlanningNavTitleWithSubtitle } from "./HeadcountPlanningNav"
import { FinalizePlanButton, FinalizePlanModal } from "./Overview/FinalizePlanButton"
import { Notifications } from "./Overview/Notifications"
import { ParticipantTable } from "./Overview/ParticipantTable"
import { PlanTopbarMenu } from "./PlanTopbarMenu"

interface Props {
  headcountPlanId: string
  rootPositionId: string | undefined
  participantIds: string[]
  maxChartDepth: number
}

function Overview({ headcountPlanId, rootPositionId, participantIds, maxChartDepth }: Props) {
  const { data } = useGetHeadcountPlanWithTreeQuery({
    id: headcountPlanId,
    maxChartDepth,
    rootPositionId,
    participantIds,
  })
  const headcountPlan = data?.headcountPlan
  const cookieName = `built__dismissed-invite-notification-for-hcp-${headcountPlanId}`
  const [showNotifications, setShowNotifications] = useState(getCookie(cookieName) !== "true")
  const isFinalized = !!headcountPlan?.lockedAt

  const [finalizePlanModalIsOpen, setFinalizePlanModalIsOpen] = useState(false)

  if (!headcountPlan) return <Spinner />

  const handleNotificationsCloseClick = () => {
    setShowNotifications(false)
    setCookie(cookieName, "true", 365 * 5) // 5 years since we'll have another solution by then
  }

  return (
    <>
      <PageNav>
        <TitleBlockLarge>
          <HeadcountPlanningNavTitleWithSubtitle
            title={headcountPlan.name}
            subtitle="Headcount Planning"
            subtitleLinkTo={UrlHelper.headcountPlansPath()}
          />
        </TitleBlockLarge>
        <ActionBlock>
          <div className="gap-2 flex">
            {!headcountPlan.currentUserCanManage || headcountPlan.lockedAt ? null : (
              <>
                <FinalizePlanModal
                  headcountPlanId={headcountPlanId}
                  headcountPlanName={headcountPlan.name}
                  isOpen={finalizePlanModalIsOpen}
                  setIsOpen={setFinalizePlanModalIsOpen}
                />
                <FinalizePlanButton
                  canBeFinalized={headcountPlan.canBeFinalized}
                  setIsOpen={setFinalizePlanModalIsOpen}
                />
              </>
            )}
            {!headcountPlan.currentUserCanManage || isFinalized ? null : (
              <PlanTopbarMenu headcountPlanId={headcountPlanId} isFinalized={isFinalized} />
            )}
          </div>
        </ActionBlock>
      </PageNav>

      <div className="p-12">
        <Notifications
          headcountPlan={headcountPlan}
          onClose={handleNotificationsCloseClick}
          showNotifications={showNotifications}
        />
        <ParticipantTable
          showEntireOrgRow={headcountPlan.currentUserCanManage}
          headcountPlanId={headcountPlan.id}
          logoThumbUrl={headcountPlan.company.logoThumbUrl || ""}
          companyName={headcountPlan.company.name || ""}
          participants={headcountPlan.participantsTree}
        />
      </div>
    </>
  )
}

export { Overview }
