import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import React from "react"

import { opaqueBasic } from "v2/react/utils/framerAnimationVariants"

import { FieldType } from "../types"

interface Props {
  showStyle: boolean
  fieldType: FieldType
  errored?: boolean
}

export function EditLayer({ showStyle, fieldType, errored }: Props) {
  return (
    <AnimatePresence>
      {showStyle && (
        <motion.div
          key="border"
          variants={opaqueBasic}
          animate="shown"
          exit="hidden"
          className={classNames("GridBody-cell__entered border border-solid", {
            "border-status-critical": errored,
            "border-primary-100": !errored,
          })}
        >
          {fieldType === FieldType.SelectDropdown && (
            <motion.div
              key="caret"
              variants={opaqueBasic}
              animate="shown"
              exit="hidden"
              className="GridBody-cell__caret GridBody-cell__caret-adjusted"
            >
              <FontAwesomeIcon
                className={classNames({
                  "text-status-critical": errored,
                  "text-primary-100": !errored,
                })}
                icon={["fas", "caret-up"]}
              />
            </motion.div>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
