import Ajv from "ajv"
import ajvFormats from "ajv-formats"

export const ajv = new Ajv({
  allErrors: true,
  verbose: true,
  strict: false,
  strictRequired: true,
  allowUnionTypes: true,
  $data: true,
  code: { optimize: false },
})
ajvFormats(ajv)
