import { type DateValue } from "@internationalized/date"
import type { TFunction } from "i18next"
import React, { memo, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"

import type { TimelineRow, TimelineTotalRow } from "types/graphql"
import { PositionsOverTimeMetrics, TimelineIntervalTypeEnum } from "types/graphql.enums"
import { DrillDown } from "v2/react/components/positions/reports/PositionsOverTime/DrillDown"
import { useDrillDownState } from "v2/react/components/positions/reports/PositionsOverTime/useDrillDownState"
import { useDateFormatter } from "v2/react/hooks/useDateFormatter"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import { Notice } from "v2/react/shared/status/Notice"
import { TimelineTable } from "v2/react/shared/tables/TimelineTable/TimelineTable"
import type { OnCellClick } from "v2/react/shared/tables/TimelineTable/types"
import { useGetPositionsOverTimeDataQuery } from "v2/redux/GraphqlApi/PositionReportsApi"

interface ReportTableProps {
  startDate: DateValue | null
  endDate: DateValue | null
  selectedInterval: TimelineIntervalTypeEnum
  selectedGroup: string
  selectedMetrics: PositionsOverTimeMetrics[]
  csvDownloadRef: React.RefObject<HTMLButtonElement>
  maxDate?: DateValue
  minDate?: DateValue
}

const MemoedTimelineTable = memo(TimelineTable)

const ReportTable = ({
  startDate,
  endDate,
  selectedInterval,
  selectedGroup,
  selectedMetrics,
  csvDownloadRef,
  maxDate,
  minDate,
}: ReportTableProps) => {
  const { t } = useTranslation()
  const { parseAndFormat } = useDateFormatter({
    year: "numeric",
    month: "long",
    day: "numeric",
  })
  const { setIsOpen: setIsDrillDownOpen, drillDownProps, setDrillDownProps } = useDrillDownState()

  const sortedSelectedMetrics = [...selectedMetrics].sort()
  const noMetricsSelected = selectedMetrics.length === 0
  const dateRangeValidationError = validateDateRange({
    startDate,
    endDate,
    maxDate,
    minDate,
    t,
    formattedMinDate: minDate && parseAndFormat(minDate.toString()),
  })
  const isDateRangeInvalid = !!dateRangeValidationError
  const startDateStr = startDate?.toString()
  const endDateStr = endDate?.toString()
  const isDateMissing = !startDateStr || !endDateStr

  const { data, isLoading, isFetching, isError } = useGetPositionsOverTimeDataQuery(
    {
      startDate: startDateStr,
      endDate: endDateStr,
      intervalType: selectedInterval,
      groupBy: selectedGroup,
      metrics: sortedSelectedMetrics,
    },
    { skip: noMetricsSelected || isDateRangeInvalid || isDateMissing },
  )

  const columns = data?.positionReports?.positionsOverTime?.columns
  const rows = data?.positionReports?.positionsOverTime?.rows
  const totals = data?.positionReports?.positionsOverTime?.totals

  const sortedRows = useMemo(() => reorderRowData(rows, selectedMetrics), [rows, selectedMetrics])
  const sortedTotals = useMemo(
    () => reorderTotalData(totals, selectedMetrics),
    [totals, selectedMetrics],
  )

  const handleCellClick: OnCellClick = useCallback(
    ({ metricKey, groupByCells, ...props }) => {
      setDrillDownProps({
        ...props,
        metricKey: metricKey as PositionsOverTimeMetrics,
        intervalType: selectedInterval,
        selectedGroups: groupByCells.length === 0 ? [] : [selectedGroup],
        isOpen: true,
      })
    },
    [selectedInterval, selectedGroup, setDrillDownProps],
  )

  if (isDateRangeInvalid) return <ErrorNotice message={dateRangeValidationError} />
  if (noMetricsSelected)
    return (
      <ControlInfoNotice
        message={t("v2.position_reports.positions_over_time.no_metrics_selected")}
      />
    )
  if (isLoading || isFetching) return <Spinner className="z-0" />

  if (isError || !columns || !sortedRows || !sortedTotals || isDateMissing)
    return <ErrorNotice message={t("v2.defaults.error")} />

  return (
    <>
      <div className="w-full overflow-x-auto pb-4">
        <MemoedTimelineTable
          columns={columns}
          rows={sortedRows}
          totals={sortedTotals}
          startDate={startDateStr}
          endDate={endDateStr}
          boundaryMismatchTooltipTranslationPath="v2.position_reports.positions_over_time"
          csvDownloadRef={csvDownloadRef}
          csvDownloadName={`${t(
            "v2.position_reports.exporter.positions_over_time",
          )}_${startDate}_${endDate}`}
          onCellClick={handleCellClick}
        />
      </div>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <DrillDown {...drillDownProps} setIsOpen={setIsDrillDownOpen} />
    </>
  )
}

const reorderTotalData = (
  totals: TimelineTotalRow[] | undefined | null,
  selectedMetrics: PositionsOverTimeMetrics[],
) => {
  if (!totals) return totals

  return [...totals].sort(
    (a, b) =>
      selectedMetrics.indexOf(a.metricKey as PositionsOverTimeMetrics) -
      selectedMetrics.indexOf(b.metricKey as PositionsOverTimeMetrics),
  )
}

const reorderRowData = (
  rows: TimelineRow[] | undefined | null,
  selectedMetrics: PositionsOverTimeMetrics[],
) => {
  if (!rows) return rows

  const groupedData = rows.reduce(
    (acc, row) => {
      const key = JSON.stringify(row.groupByCells)
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(row)
      return acc
    },
    {} as Record<string, TimelineRow[]>,
  )

  const sortedData = Object.values(groupedData).flatMap((group) =>
    group.sort(
      (a, b) =>
        selectedMetrics.indexOf(a.metricKey as PositionsOverTimeMetrics) -
        selectedMetrics.indexOf(b.metricKey as PositionsOverTimeMetrics),
    ),
  )

  return sortedData
}

// We return a validation error message under the following conditions:
// 1. The start date is after the end date.
// 2. The end date is beyond the current date.
// 3. The date range is before any chart data exists OR the company creation
//    data, whichever is later.
// 4. The start or end dates aren't selected.
const validateDateRange = ({
  startDate,
  endDate,
  maxDate,
  minDate,
  formattedMinDate,
  t,
}: Pick<ReportTableProps, "startDate" | "endDate" | "maxDate" | "minDate"> & {
  t: TFunction
  formattedMinDate?: string
}) => {
  if (!startDate || !endDate)
    return t("v2.position_reports.positions_over_time.date_range.errors.date_missing")
  if (startDate > endDate)
    return t("v2.position_reports.positions_over_time.date_range.errors.start_after_end")
  if (maxDate && endDate > maxDate)
    return t("v2.position_reports.positions_over_time.date_range.errors.end_in_future")
  if (minDate && startDate < minDate)
    return t("v2.position_reports.positions_over_time.date_range.errors.min_report_date", {
      date: formattedMinDate,
      interpolation: { escapeValue: false },
    })
  return null
}

const ControlInfoNotice = ({ message }: { message: string }) => (
  <Notice icon={["far", "exclamation-triangle"]} type="caution">
    {message}
  </Notice>
)

const ErrorNotice = ({ message }: { message: string }) => (
  <Notice icon={["far", "exclamation-triangle"]} type="critical">
    {message}
  </Notice>
)

export { ReportTable }
