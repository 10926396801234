import React, { ChangeEvent, useState } from "react"
import { useTranslation } from "react-i18next"

import { Candidate, Error, Person } from "types/graphql"
import { PersonSearchInput } from "v2/react/shared/forms/PersonSearchInput"
import { RadioGroup } from "v2/react/shared/forms/RadioGroup"
import { TextArea } from "v2/react/shared/forms/TextArea"
import { TextInput } from "v2/react/shared/forms/TextInput"
import { useGetCurrentPersonQuery } from "v2/redux/GraphqlApi"
import { useCreateSuccessionPlanCandidateMutation } from "v2/redux/GraphqlApi/SuccessionApi"

interface CandidateFormProps {
  candidates: Candidate[]
  filledBy: Person[]
  onClose: () => void
  positionId: string
}

const CandidateForm = ({ candidates, filledBy, onClose, positionId }: CandidateFormProps) => {
  const { t } = useTranslation()
  const omitIds: string[] = [
    ...candidates
      .filter((c): c is Candidate & { person: Person } => !!c.person?.id)
      .map((c) => c.person),
    ...filledBy,
  ].map((p) => p.id)

  const [candidateType, setCandidateType] = useState("within_org")
  const [personId, setPersonId] = useState("")
  const [candidateName, setCandidateName] = useState("")
  const [additionalInfo, setAdditionalInfo] = useState("")
  const [addCandidate, { isLoading }] = useCreateSuccessionPlanCandidateMutation()
  const { data } = useGetCurrentPersonQuery({})
  const [errors, setErrors] = useState<Error[]>([])
  const generalError = [
    {
      message: t("v2.succession_plan_panel.candidate_form.could_not_be_updated"),
      path: ["general"],
    },
  ]

  const handleCandidateType = (e: ChangeEvent<HTMLInputElement>) => {
    setCandidateType(e.target.value)
    setErrors([])
  }

  const handleCancel = () => onClose()

  const handleSave = async () => {
    try {
      const { createSuccessionPlanCandidate: response } = await addCandidate({
        personId: personId.length ? personId : null,
        outsiderName: candidateName.length ? candidateName : null,
        additionalInfo: additionalInfo.trim().length > 0 ? additionalInfo.trim() : null,
        positionId,
      }).unwrap()

      if (response.errors && response.errors.length > 0) {
        setErrors(response.errors)
      } else {
        onClose()
      }
    } catch (error) {
      setErrors(generalError)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSelectPerson = (selectedPerson: Person, index: number) =>
    setPersonId(selectedPerson.id)

  const retrieveErrorFor = (path: string) => {
    const error = errors?.find((element) => element?.path && element.path[0] === path)
    if (error) return error.message
    return ""
  }

  return (
    <>
      <div className="candidate-form flex-col gap-6 p-4 flex">
        <RadioGroup
          groupName="candidate_type"
          options={[
            {
              id: "within_org",
              label: t("v2.succession_plan_panel.candidate_form.within_organization"),
              value: "within_org",
            },
            {
              id: "outside_org",
              label: t("v2.succession_plan_panel.candidate_form.outside_organization"),
              value: "outside_org",
            },
          ]}
          defaultChecked="within_org"
          onChange={handleCandidateType}
        />
        <div className="label-bold">
          {candidateType === "within_org" ? (
            <div className="input-group m-0">
              <label htmlFor="person_name">
                {t("v2.succession_plan_panel.candidate_form.name")}
              </label>
              <PersonSearchInput
                errorMessage={retrieveErrorFor("name")}
                htmlForId="person_name"
                omitValues={omitIds}
                onSelect={handleSelectPerson}
                placeholder={t("v2.defaults.example_name")}
                retainNameOnSelect
                subordinateOnly={!data?.currentPerson?.isManager}
              />
            </div>
          ) : (
            <>
              <TextInput
                id="person_name"
                className="mb-6"
                errors={retrieveErrorFor("name")}
                label={t("v2.succession_plan_panel.candidate_form.name")}
                name="person_name"
                onChange={(e) => setCandidateName(e.target.value)}
              />
              <TextArea
                id="additional_info"
                label={t("v2.succession_plan_panel.candidate_form.additional_info")}
                name="additional_info"
                onChange={(e) => setAdditionalInfo(e.target.value)}
                placeholder={t(
                  "v2.succession_plan_panel.candidate_form.additional_info_placeholder",
                )}
              />
            </>
          )}
        </div>
      </div>

      <div className="justify-end gap-2 border-t-neutral-8 p-4 flex">
        <button
          className="btn btn--secondary"
          onClick={handleCancel}
          disabled={isLoading}
          type="button"
        >
          {t("v2.defaults.cancel")}
        </button>
        <button
          className="btn btn--primary"
          onClick={handleSave}
          disabled={isLoading}
          type="button"
        >
          {t("v2.defaults.save")}
        </button>
      </div>
    </>
  )
}

export { CandidateForm }
