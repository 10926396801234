import React, { ReactNode, useRef } from "react"

import { useDetectSticky } from "v2/react/components/succession/SuccessionPlanPanel/utils/useDetectSticky"

interface StickyHeaderProps {
  children: ReactNode
}

function StickyHeader({ children }: StickyHeaderProps) {
  const headerRef = useRef<HTMLElement>(null)
  const scrollRef = document.querySelector(".panel-scroll-container")
  const isSticky = useDetectSticky(headerRef, { root: scrollRef, threshold: [1] })

  return (
    <header ref={headerRef} className="sticky inset-x-0 top-[-1px] z-4 w-full">
      {isSticky ? <div className="border-b-neutral-8 bg-white p-2">{children}</div> : <span />}
    </header>
  )
}

export { StickyHeader }
