import React from "react"

interface ModuleListItemProps {
  className?: string
  children: React.ReactNode
}

function ModuleListItem({ className, children }: ModuleListItemProps) {
  return <div className={`module-list-item p-4 ${className || ""}`}>{children}</div>
}

export { ModuleListItem }
