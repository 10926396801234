import React, { forwardRef } from "react"

import { CurrencyInput } from "v2/react/shared/forms/CurrencyInput"
import { InputFieldset } from "v2/react/shared/forms/InputFieldset"
import { NumericInput } from "v2/react/shared/forms/NumericInput"

interface HourlyPayInputProps {
  currencyName: string
  hoursName: string
  idPrefix?: string
  iconClass?: string
  label?: string
  currencyErrors?: string
  hoursErrors?: string
  showErrorMessage?: boolean
  initialCurrencyValue?: string
  initialHoursPerWeek?: string | null
  useAttentionState?: boolean
  onAmountChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onHoursChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const HourlyPayInput = forwardRef<HTMLInputElement, HourlyPayInputProps>(
  (
    {
      currencyName,
      hoursName,
      iconClass = window.gon.currency_icon_class || "far fa-dollar-sign",
      idPrefix,
      label,
      currencyErrors,
      hoursErrors,
      showErrorMessage = true,
      initialCurrencyValue,
      initialHoursPerWeek,
      useAttentionState,
      onAmountChange,
      onHoursChange,
    },
    ref,
  ) => {
    const id = idPrefix || "hourly-pay"
    const currencyId = `${id}__${currencyName}`
    const hoursId = `${id}__${hoursName}`

    const errors = []
    if (currencyErrors) errors.push(formatErrors({ errors: currencyErrors, inputType: "currency" }))
    if (hoursErrors) errors.push(formatErrors({ errors: hoursErrors, inputType: "hours" }))

    return (
      <InputFieldset label={label} errors={errors} showErrorMessage={showErrorMessage}>
        <div className="hourly-pay-input">
          <div className="amount">
            <CurrencyInput
              ref={ref}
              id={currencyId}
              iconClass={iconClass}
              name={currencyName}
              errors={currencyErrors}
              showErrorMessage={false}
              useAttentionState={useAttentionState}
              defaultValue={initialCurrencyValue}
              onChange={onAmountChange}
            />
          </div>
          <div className="hours-per-week">
            <div>
              <NumericInput
                id={hoursId}
                name={hoursName}
                errors={hoursErrors}
                showErrorMessage={false}
                useAttentionState={useAttentionState}
                max="168" // There are only 168 hours in a week (unless you're Hermione Granger)
                defaultValue={initialHoursPerWeek || ""}
                onChange={onHoursChange}
              />
            </div>

            <span className="hours-per-week__label">{"hrs/wk".t("org_chart")}</span>
          </div>
        </div>
      </InputFieldset>
    )
  },
)

/**
 * Formats the given errors by prefixing them with a label that corresponds to the input type.
 *
 * @example
 * const result = formatErrors({ errors: "Some error message", inputType: "currency" });
 * // Output: "Base Pay Rate: Some error message"
 */
const formatErrors = ({
  errors,
  inputType,
}: {
  errors: string
  inputType: "currency" | "hours"
}) => {
  const errorLabel = inputType === "currency" ? "field_base_pay" : "field_hours_per_week"
  return `${errorLabel.t("org_chart")}: ${errors}`
}

export { HourlyPayInput }
