import React, { useEffect } from "react"

import { asyncPatchPreferences } from "v2/redux/slices/ContainerSlice/containerThunks"
import { useAppDispatch } from "v2/redux/store"

function ChartMenu() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    $.onmount()
    $("[data-pjax-container=charts]").on("pjax:end", () => {
      $.onmount()

      $("[data-pjax-container=charts] .tab-container").tabs({
        active: gon.chart_tab,
        activate(e, ui) {
          gon.chart_tab = ui.newTab.index()
          dispatch(asyncPatchPreferences({ org_chart: { chart_tab: gon.chart_tab } }))
        },
      })

      $("[data-action=show-archived]").click((e) => {
        e.preventDefault()
        $(e.target).parent().hide().siblings(".archived").removeClass("hidden")
      })

      $("[data-action=hide-archived]").click((e) => {
        e.preventDefault()
        $(e.target).parents(".archived").addClass("hidden").siblings("p").show()
      })
    })
  }, [])

  return window.isFeatureEnabled("multiple_charts") || window.isFeatureEnabled("lists") ? (
    <div className="org-chart-chart-menu" data-pjax-render="/charts" data-pjax-container="charts" />
  ) : null
}

export { ChartMenu }
