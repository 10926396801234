import * as d3 from "d3-hierarchy"
import OrgChart from "org_chart/chart/orgChart"
import RelationalNodeDataStore from "org_chart/chart/utils/relationalNodeDataStore"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { FeatureFlags, HeadcountPlanChangeProjection } from "types/graphql"
import { HeadcountPlanningNavTitleWithSubtitle } from "v2/react/components/headcountPlanning/HeadcountPlanningNav"
import {
  FinalizePlanButton,
  FinalizePlanModal,
} from "v2/react/components/headcountPlanning/Overview/FinalizePlanButton"
import { PlanTopbarMenu } from "v2/react/components/headcountPlanning/PlanTopbarMenu"
import { InProgressAlert } from "v2/react/components/headcountPlanning/shared/InProgressAlert"
import { getNavLinks } from "v2/react/components/headcountPlanning/shared/navigation"
import { PlanTypeIndicator } from "v2/react/components/headcountPlanning/ShowParticipant/PlanTypeIndicator"
import { DisplayState } from "v2/react/components/headcountPlanning/types"
import { OrgChartViewOptions } from "v2/react/components/orgChart/Navigation/OrgChartViewOptions"
import RootProvider from "v2/react/components/RootProvider"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import PageNav from "v2/react/shared/navigation/PageNav"
import { ActionBlock } from "v2/react/shared/navigation/PageNav/ActionBlock"
import { LinkBlockSmall } from "v2/react/shared/navigation/PageNav/LinkBlock"
import { LinkGroup } from "v2/react/shared/navigation/PageNav/LinkGroup"
import { TitleBlockLarge } from "v2/react/shared/navigation/PageNav/TitleBlock"
import { getCookie } from "v2/react/utils/cookies"
import { UrlHelper } from "v2/react/utils/urls"
import { useGetFeatureFlagsQuery } from "v2/redux/GraphqlApi"
import { useGetOwnerOrgChartQuery } from "v2/redux/GraphqlApi/HeadcountPlanningApi"

RelationalNodeDataStore.load({ keys: {}, index: {} })

interface Props {
  headcountPlanId: string
}

function OwnerOrgChartInner({ headcountPlanId }: Props) {
  const { t } = useTranslation()
  const displayStateCookie = getCookie(
    `built__display-state-for-hcp-${headcountPlanId}`,
  ) as DisplayState
  const [displayState, setDisplayState] = useState<DisplayState>(displayStateCookie || "approved")
  const featureFlags = useGetFeatureFlagsQuery()
  const ownerPage = useGetOwnerOrgChartQuery({ headcountPlanId })
  const currentCompany = ownerPage.data?.currentCompany
  const headcountPlan = ownerPage.data?.headcountPlan
  const isFinalized = !!headcountPlan?.lockedAt
  const hcpPositions =
    isFinalized && displayState === "approved"
      ? headcountPlan?.approvedPositionsForOrgchart
      : headcountPlan?.allPositionsForOrgchart

  const [finalizePlanModalIsOpen, setFinalizePlanModalIsOpen] = useState(false)

  if (
    ownerPage.isLoading ||
    !hcpPositions ||
    !headcountPlan ||
    featureFlags.isFetching ||
    !featureFlags.data?.currentCompany?.featureFlags
  )
    return <Spinner />

  const ff: FeatureFlags = featureFlags.data.currentCompany.featureFlags

  // Reset contents of org chart container
  const container = document.getElementById("organize-container")
  if (container) container.innerHTML = ""

  const stratify = d3
    .stratify()
    .id((d): string => (d as HeadcountPlanChangeProjection).id)
    .parentId((d): string => {
      const node = d as HeadcountPlanChangeProjection
      if (node.id === "company") return ""
      const reportsToId = node.positionAttributesWithEdits?.reports_to?.id || "company"
      if (reportsToId && reportsToId !== "company") {
        return `position_${reportsToId}`
      }
      return reportsToId
    })

  const positions = [...hcpPositions]
  const company = {
    id: "company",
    name: currentCompany?.name,
    avatar: currentCompany?.logoThumbUrl,
    klass: "Company",
  }
  positions.push(company)
  const chartData = stratify(positions)
  const chartOptions = {
    jsonData: chartData,
    loadAsync: true,
    positionsEndpoint: `/api/app/v1/headcount_plans/${headcountPlanId}/organize?display_state=${displayState}`,
    orgchartLite: true,
    displayFields: ["avatar", "name", "title"],
    showLabels: true,
    dragAndDropEnabled: false,
  }

  const chart = new OrgChart("#organize-container", chartOptions)
  chart?.loadFromJSON()

  return (
    <PageNav>
      <TitleBlockLarge>
        <HeadcountPlanningNavTitleWithSubtitle
          title={headcountPlan.name}
          subtitle={t("v2.headcount_plan.headcount_planning")}
          subtitleLinkTo={UrlHelper.headcountPlansPath()}
        />
      </TitleBlockLarge>
      <LinkBlockSmall>
        <LinkGroup
          links={getNavLinks({ isOwner: true, active: "Org Chart", headcountPlanId, ff, t })}
        />
      </LinkBlockSmall>
      <ActionBlock>
        <div className="gap-2 flex">
          {isFinalized ? <PlanTypeIndicator type={displayState} /> : null}
          {isFinalized ? null : (
            <>
              <FinalizePlanModal
                headcountPlanId={headcountPlanId}
                headcountPlanName={headcountPlan.name}
                isOpen={finalizePlanModalIsOpen}
                setIsOpen={setFinalizePlanModalIsOpen}
              />
              <FinalizePlanButton
                canBeFinalized={headcountPlan.canBeFinalized}
                setIsOpen={setFinalizePlanModalIsOpen}
              />
            </>
          )}
          <PlanTopbarMenu
            isFinalized={isFinalized}
            displayState={displayState}
            setDisplayState={setDisplayState}
            headcountPlanId={headcountPlanId}
          />
        </div>
      </ActionBlock>
      {!headcountPlan.canBeFinalized && <InProgressAlert />}
      <OrgChartViewOptions chart={chart} />
    </PageNav>
  )
}

const OwnerOrgChart = ({ headcountPlanId }: Props) => {
  const getVisualHeight = () => {
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight || 0
    const headerHeight = document.getElementsByClassName("page-nav")[0]?.scrollHeight || 0
    const alertHeight = document.getElementsByClassName("alert-top")[0]?.scrollHeight || 0
    const height = viewportHeight - headerHeight - alertHeight

    if (height < 600) return 600
    return height
  }
  return (
    <RootProvider>
      <OwnerOrgChartInner headcountPlanId={headcountPlanId} />

      <div
        className="organize-container bg-primary-3-solid"
        id="organize-container"
        style={{ height: getVisualHeight() }}
      />
    </RootProvider>
  )
}

export { OwnerOrgChart }
