import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

interface Props {
  importPath: string
  isImporting: boolean
}

function RequisitionImportLink({ importPath, isImporting }: Props) {
  const { t } = useTranslation()

  return (
    <a
      data-method="post"
      href={importPath}
      className={cn("tooltip tooltip-right btn--large btn--secondary", {
        disabled: isImporting,
      })}
    >
      <FontAwesomeIcon icon={["far", "upload"]} />
      <span className="tooltip-content tooltip-content--sm">
        {isImporting
          ? t("v2.job_requisitions.index.import_busy")
          : t("v2.job_requisitions.index.import")}
      </span>
    </a>
  )
}

export { RequisitionImportLink }
