/* eslint-disable react/jsx-props-no-spreading */
import cn from "classnames"
import fp from "lodash/fp"
import React, { useMemo, useRef } from "react"
import { flushSync } from "react-dom"
import { FieldArrayWithId } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useBoolean } from "usehooks-ts"

import { NodeInterface, Option } from "types/graphql"
import {
  AddOrgUnitFieldset,
  FieldsetFooterProps,
} from "v2/react/components/orgUnits/AddOrgUnitFieldset"
import { useAddOrgUnitMutationWithHandling } from "v2/react/components/orgUnits/hooks/useAddOrgUnitMutationWithHandling"
import {
  useIsFieldUneditable,
  usePositionController,
  usePositionFieldArray,
} from "v2/react/components/positions/positionForm/hooks"
import { Subform } from "v2/react/components/positions/positionForm/Subform"
import { FieldValues } from "v2/react/components/positions/positionForm/types"
import { useCollectionSearch } from "v2/react/hooks/useCollectionSearch"
import { Autocomplete } from "v2/react/shared/forms/Autocomplete"
import { Popover, PopoverContent, PopoverTrigger } from "v2/react/shared/overlay/Popover"
import { codeNameFromInputString, codeNameLabelFromAttributes } from "v2/react/utils/codeNames"
import { idFromUniqueKey } from "v2/react/utils/uniqueKey"

type OrgUnitInputsProps = {
  wrapperClassName?: string
}

type OrgUnitAutocompleteInputProps = {
  index: number
  orgUnitType: Option
}

type UpdateOrgUnitArg = {
  code?: string | null
  id?: string | null
  name?: string | null
  updateRef?: boolean
}

type OrgUnit = FieldArrayWithId<FieldValues, "position.orgUnitsAttributes", "formId">

/** Based on app/views/v2/position_types/form/_org_units_form_fields.html.slim */
function OrgUnitInputs({ wrapperClassName }: OrgUnitInputsProps) {
  const { fields: orgUnits } = usePositionFieldArray({
    keyName: "formId", // Ensures react-hook-form doesn't overwrite actual id
    name: "position.orgUnitsAttributes",
  })

  return useOrgUnitsInChunks(orgUnits).map(({ chunk, chunkKey }) => (
    <div
      key={chunkKey}
      className={cn("inline-org-units relative justify-between gap-4 flex", wrapperClassName)}
    >
      {chunk.map(({ orgUnitType, formId, index }) => (
        <OrgUnitAutocompleteInput index={index} key={formId} orgUnitType={orgUnitType} />
      ))}
    </div>
  ))
}

function OrgUnitAutocompleteInput({ index, orgUnitType }: OrgUnitAutocompleteInputProps) {
  const { t } = useTranslation()

  const {
    setFalse: hideCreateForm,
    setTrue: showCreateForm,
    setValue: setIsCreateFormShown,
    value: isCreateFormShown,
  } = useBoolean()

  const isUneditable = useIsFieldUneditable(orgUnitType.id)
  const orgUnitTypeId = idFromUniqueKey(orgUnitType.id)
  const {
    field: { onChange: changeOrgUnit, value: orgUnit },
    formState: { errors },
  } = usePositionController({ name: `position.orgUnitsAttributes.${index}` })

  const { collectionResult } = useCollectionSearch({
    fieldKey: orgUnitType.id as keyof NodeInterface,
    filter: orgUnit.fullName ?? "",
  })

  // Used as a point of reference to help manage autocomplete behavior. This
  // isn't currently wired up to support outside changes to `orgUnit`, e.g. due
  // to a `setValue` call from a disparate component. Assumption is we don't
  // need to handle this.
  const orgUnitRef = useRef(orgUnit)

  const updateOrgUnit = (arg: UpdateOrgUnitArg) => {
    const nextOrgUnit = {
      ...orgUnit,
      code: arg.code ?? "",
      name: arg.name ?? "",
      fullName: codeNameLabelFromAttributes(arg),
    }

    if (arg.updateRef) orgUnitRef.current = nextOrgUnit
    changeOrgUnit(nextOrgUnit)
  }

  const autocompleteRef = useRef<{ focus: () => void }>(null)
  const fieldsetRef = useRef<HTMLFieldSetElement>(null)
  const { handleSave, state } = useAddOrgUnitMutationWithHandling((ev) => {
    // Flush updating org unit *before* hiding the create form. Ensures we don't
    // accidentally clobber the new input on blur.
    flushSync(() => {
      updateOrgUnit({ ...ev, updateRef: true })
    })

    hideCreateForm()
  })

  const handleSubmitForCreateForm = () => {
    const { current: fieldset } = fieldsetRef

    handleSave({
      code: fieldset?.querySelector<HTMLInputElement>("input[name=org_unit_code]")?.value ?? "",
      name: fieldset?.querySelector<HTMLInputElement>("input[name=org_unit_name]")?.value ?? "",
      orgUnitTypeId,
    })
  }

  return (
    <div
      className={cn(
        "input-group org-unit-group relative mb-0 flex-1",
        errors.position?.orgUnitsAttributes?.[index] && "form-error",
      )}
      key={orgUnitType.id}
    >
      <label htmlFor={`position_org_units_attributes_${index}_full_name`}>
        {orgUnitType.label}
      </label>
      <Popover
        open={isCreateFormShown}
        placement="bottom-start"
        onOpenChange={setIsCreateFormShown}
      >
        <PopoverTrigger asChild>
          <Autocomplete
            canCreateFromValue
            className="input org-unit-group"
            disabled={isUneditable}
            id={`position_org_units_attributes_${index}_full_name`}
            name={`position.orgUnitsAttributes.${index}.fullName`}
            options={collectionResult}
            getKey={({ id }) => id}
            getValueFromSelected={({ label }) => label}
            onBlur={({ isRelatedAutocompleteOption, searchTerm }) => {
              if (orgUnitRef.current.fullName !== searchTerm && !isRelatedAutocompleteOption) {
                updateOrgUnit({ updateRef: true })
              }
            }}
            onChange={(ev) => updateOrgUnit(codeNameFromInputString(ev.target.value))}
            onCreatePress={showCreateForm}
            onSelect={({ id, label }) => {
              updateOrgUnit({ id, ...codeNameFromInputString(label), updateRef: true })
            }}
            onSelectCreate={showCreateForm}
            controlRef={autocompleteRef}
            value={orgUnit.fullName}
          />
        </PopoverTrigger>
        <PopoverContent className="z-50">
          <Subform id="add-org-unit-form" onSubmit={handleSubmitForCreateForm}>
            <AddOrgUnitFieldset
              fieldsetRef={fieldsetRef}
              initialData={orgUnit}
              inputNameForCode="org_unit_code"
              inputNameForName="org_unit_name"
              layoutSpacing="condensed"
              onCancel={() => {
                hideCreateForm()
                autocompleteRef.current?.focus()
              }}
              onSave={handleSave}
              orgUnitTypeId={orgUnitType.id}
              renderFooter={AddOrgUnitFooter}
              saving={state.isLoading}
              translations={{
                codeLabel: t("v2.org_units.add_fieldset.code_label", {
                  org_unit_type_name: orgUnitType.label,
                }),
                nameLabel: t("v2.org_units.add_fieldset.name_label", {
                  org_unit_type_name: orgUnitType.label,
                }),
                save: t("v2.defaults.save"),
                saving: t("v2.defaults.saving"),
                cancel: t("v2.defaults.cancel"),
              }}
            />
          </Subform>
        </PopoverContent>
      </Popover>
    </div>
  )
}

const AddOrgUnitFooter = ({ onCancel, onSavePress, saving, translations }: FieldsetFooterProps) => (
  <div className="mt-2 justify-end gap-2 flex">
    <button
      className={cn("btn btn--secondary", { disabled: saving })}
      disabled={saving}
      id="cancel-add-org-unit-action"
      onClick={onCancel}
      type="button"
    >
      {translations.cancel}
    </button>
    <button
      className={cn("btn btn--primary", { disabled: saving })}
      disabled={saving}
      onClick={onSavePress}
      type="submit"
    >
      {saving ? translations.saving : translations.save}
    </button>
  </div>
)

const useOrgUnitsInChunks = (orgUnits: OrgUnit[] | undefined) =>
  useMemo(
    () =>
      fp.chunk(3, getOrgUnitsArray(orgUnits)).map((chunk, index) => ({
        chunk,
        chunkKey: `org-units-chunk-${chunk[0].formId}`,
        index,
      })),
    [orgUnits],
  )

const getOrgUnitsArray = (orgUnits: OrgUnit[] | undefined) =>
  orgUnits?.map((orgUnit, index) => ({ ...orgUnit, index })) ?? []

export { OrgUnitInputs, OrgUnitInputsProps }
