import options, { globals } from "../constants/options"
import chainOfCommand from "../node/chainOfCommand"
import { NodeData, PositioningHelper } from "../node/types"

/* eslint-disable no-param-reassign */

// For 3-level mode, the chart section label and grouping is drawn based on
// chain-of-command if that's where the chart section head is. This returns
// true for either a chart section head or for a node that has a chain of
// command that includes a chart section head.
export const actsAsChartSectionHead = (d: NodeData) => {
  if (d.is_chart_section_head) return true
  if (d.chain_of_command) {
    return !!chainOfCommand.findChartSectionHead(d)
  }

  return false
}

/**
 * Set node data x and y positions based on sibling order. Note: this mutates
 * the given NodeData.
 */
export const adjustLayoutForThirdLevel = (
  d: NodeData,
  nodeHeightWithSpacing: number,
  extraChartSectionHeight: number,
  positioningHelper: PositioningHelper,
): NodeData => {
  if (!d.parent) return d

  d.x = d.parent.x + globals.threeLevelShiftAmount
  d.y = d.parent.y + nodeHeightWithSpacing
  const childIndex = d.parent.children?.findIndex((context) => context.id === d.id) || 0
  d.visualDepth = d.depth + childIndex
  d.visualShift = globals.threeLevelShiftAmount

  const baseYOffset = (d.parent.children || [])
    .slice(0, childIndex)
    .reduce(
      (accumulator, child) => accumulator + positioningHelper.getNodeHeightInContext(child),
      0,
    )
  const siblingOrSelfChartSectionCount = (d.parent.children ?? [])
    .slice(0, childIndex + 1)
    .filter((child) => actsAsChartSectionHead(child)).length
  d.y += globals.threeLevelThirdLevelNodeVerticalSpacing * childIndex
  d.y += baseYOffset
  d.y +=
    siblingOrSelfChartSectionCount *
    (extraChartSectionHeight +
      (options.verticalSpacing - globals.threeLevelThirdLevelNodeVerticalSpacing))

  return d
}

export default {
  adjustLayoutForThirdLevel,
}
