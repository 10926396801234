import { FloatingFocusManager, FloatingList, FloatingPortal } from "@floating-ui/react"
import cn from "classnames"
import React, { useContext } from "react"

import { DropdownContext } from "./Dropdown"

interface DropdownMenuProps {
  children: React.ReactNode
  id?: string
  className?: string
  captureInitialFocus?: boolean
  returnFocus?: boolean
}

const DropdownMenu = ({
  children,
  id,
  className,
  captureInitialFocus = true,
  returnFocus = true,
}: DropdownMenuProps) => {
  const { isOpen, floatingInfo } = useContext(DropdownContext)
  const { refs, floatingStyles, context, getFloatingProps, listRef } = floatingInfo

  return (
    isOpen && (
      <FloatingPortal>
        <FloatingFocusManager
          context={context}
          modal={false}
          initialFocus={captureInitialFocus ? 0 : -1}
          returnFocus={returnFocus}
        >
          <div
            id={id}
            className={cn(
              "dropdown-menu open zFloatingDropdown !mt-0 overflow-x-scroll",
              className,
            )}
            ref={refs.setFloating}
            style={{ ...floatingStyles }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getFloatingProps()}
          >
            <FloatingList elementsRef={listRef}>{children}</FloatingList>
          </div>
        </FloatingFocusManager>
      </FloatingPortal>
    )
  )
}

export { DropdownMenu }
