import fp from "lodash/fp"

import {
  AllocationType,
  CurrentCompanyPositionFormDataQuery,
  CurrentCompanyPositionFormDataQueryVariables,
  CustomField,
  DestroyPositionMutation,
  FundingSource,
  GetPositionQuery,
  GetPositionSuccessionPlanQuery,
  GetPositionSuccessionPlanQueryVariables,
  Option,
  PositionsCreateInput,
  PositionsCreateMutation,
  PositionsDestroyPositionInput,
  PositionsGetForEditQuery,
  PositionsGetForEditQueryVariables,
  PositionsUpdateInput,
  PositionsUpdateMutation,
} from "types/graphql.d"
import { idFromUniqueKey } from "v2/react/utils/uniqueKey"
import { GraphqlApi } from "v2/redux/GraphqlApi"
import { flatMutationOperation, queryOperation } from "v2/redux/utils/endpoints"

type PositionAuxiliaryFormData = ReturnType<typeof toAuxiliaryFormData>
type PositionFormCollections = PositionAuxiliaryFormData["positionFormCollections"]
type PositionFormDefaults = PositionAuxiliaryFormData["defaults"]
type Position = NonNullable<GetPositionQuery["position"]>
type EditablePosition = NonNullable<PositionsGetForEditQuery["position"]>

const DEFAULT_ALLOCATION_TYPES: AllocationType[] = []
const DEFAULT_CUSTOM_FIELDS: CustomField[] = []
const DEFAULT_FUNDING_SOURCES: FundingSource[] = []
const DEFAULT_OPTIONS: Option[] = []
const DEFAULT_POSITION_FORM_ABILITIES = {
  canCreateBudgetedVariablePayType: false,
  canShowPlanningTab: false,
  canUpdateBudgetingData: false,
  canManageFundingSources: false,
  canManageAllocations: false,
}

const pickOptions = (prop: string, data: CurrentCompanyPositionFormDataQuery): Option[] => {
  const optionsRaw = fp.propOr(
    DEFAULT_OPTIONS,
    ["currentCompany", "collections", prop, "options", "nodes"],
    data,
  )
  return fp.map((option: Option) => {
    try {
      return {
        ...option,
        id: idFromUniqueKey(option.id),
      }
    } catch {
      return option
    }
  }, optionsRaw)
}

export const toAuxiliaryFormData = (response: CurrentCompanyPositionFormDataQuery) => ({
  positionFormAbilities: fp.pipe(
    fp.assign(DEFAULT_POSITION_FORM_ABILITIES),
    fp.pick(fp.keys(DEFAULT_POSITION_FORM_ABILITIES)),
    fp.mapValues(Boolean),
  )(response.currentPerson ?? {}) as Conditions<typeof DEFAULT_POSITION_FORM_ABILITIES>,
  defaults: {
    location: response.currentCompany?.defaultLocation,
  },
  positionFormCollections: {
    allocationTypes: (response.currentCompany?.allocationTypes ??
      DEFAULT_ALLOCATION_TYPES) as AllocationType[],
    customFields: response.currentCompany?.customFields ?? DEFAULT_CUSTOM_FIELDS,
    eeocClassifications: pickOptions("eeocClassifications", response),
    employeeTypes: pickOptions("employeeTypes", response),
    flsaClassifications: pickOptions("flsaClassifications", response),
    fundingSources: response.currentCompany?.fundingSources ?? DEFAULT_FUNDING_SOURCES,
    hiringPriorities: pickOptions("hiringPriorities", response),
    locations: pickOptions("locations", response),
    orgUnitTypes:
      response?.currentCompany?.collections?.orgUnitTypes?.map((val) => ({
        id: val.name,
        label: val.label,
      })) ?? DEFAULT_OPTIONS,
    positionBasePayTypes: pickOptions("positionBasePayTypes", response),
    positionHierarchyTypes: pickOptions("positionHierarchyTypes", response),
    positionImportances: pickOptions("positionImportances", response),
    positionStatuses: pickOptions("positionStatuses", response),
    variablePayPayTypes: pickOptions("variablePayPayTypes", response),
    variablePayTypes: pickOptions("variablePayTypes", response),
  },
})

export const PositionsApi = GraphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    currentCompanyPositionFormData: builder.query<
      PositionAuxiliaryFormData,
      CurrentCompanyPositionFormDataQueryVariables
    >({
      query: queryOperation<CurrentCompanyPositionFormDataQueryVariables>(
        "CurrentCompanyPositionFormData",
      ),
      transformResponse: toAuxiliaryFormData,
    }),
    getPositionSuccessionPlan: builder.query<
      GetPositionSuccessionPlanQuery,
      GetPositionSuccessionPlanQueryVariables
    >({
      query: queryOperation<GetPositionSuccessionPlanQueryVariables>("GetPositionSuccessionPlan"),
    }),
    positionsCreate: builder.mutation<PositionsCreateMutation, PositionsCreateInput>({
      query: flatMutationOperation<PositionsCreateInput>("PositionsCreate"),
      invalidatesTags: ["PositionDetails", "EditablePositions"],
    }),
    positionsGetForEdit: builder.query<PositionsGetForEditQuery, PositionsGetForEditQueryVariables>(
      {
        query: queryOperation<PositionsGetForEditQueryVariables>("PositionsGetForEdit"),
        providesTags: (_1, _2, { positionId }) => [{ type: "EditablePositions", id: positionId }],
      },
    ),
    positionsUpdate: builder.mutation<PositionsUpdateMutation, PositionsUpdateInput>({
      query: flatMutationOperation<PositionsUpdateInput>("PositionsUpdate"),
      invalidatesTags: (result, _rootErrors, { id }) => {
        if (result?.positionsUpdate?.errors?.length) return []
        return ["PositionDetails", "EditablePositions", id]
      },
    }),
    destroyPosition: builder.mutation<DestroyPositionMutation, PositionsDestroyPositionInput>({
      query: flatMutationOperation<PositionsDestroyPositionInput>("DestroyPosition"),
    }),
  }),
})

export const {
  useCurrentCompanyPositionFormDataQuery,
  useDestroyPositionMutation,
  useGetPositionSuccessionPlanQuery,
  usePositionsCreateMutation,
  usePositionsGetForEditQuery,
  usePositionsUpdateMutation,
} = PositionsApi
export {
  DEFAULT_POSITION_FORM_ABILITIES,
  EditablePosition,
  PositionAuxiliaryFormData,
  PositionFormCollections,
  PositionFormDefaults,
  Position,
}
