import React from "react"
import { useTranslation } from "react-i18next"

import {
  AllocationAmount,
  AllocationType,
  FeatureFlags,
  ProfilePanelPermissions,
} from "types/graphql"
import { ProfilePanelMode } from "types/graphql.enums"
import { useSelectedBox } from "v2/react/components/orgChart/ProfilePanel/Settings/hooks/useSelectedBox"
import { AccordionModulePanel } from "v2/react/shared/layout/AccordionModulePanel"
import { useAppSelector } from "v2/redux/store"

interface AllocationsProps {
  abilities: ProfilePanelPermissions
  featureFlags: FeatureFlags
}

function Allocations({ abilities, featureFlags }: AllocationsProps) {
  const { currentBox, toggleBox } = useSelectedBox("allocations", ProfilePanelMode.Position)
  const position = useAppSelector((state) => state.profilePanel.position)
  const { t } = useTranslation()

  if (!currentBox || !position) return null
  if (!featureFlags.positionManagement || !position.allocationTypes?.length) return null
  if (!abilities.canEditPosition) return null

  const headerContent = <div className="title">{t("v2.profile_panel.allocations")}</div>

  const amountsFor = (type: AllocationType) =>
    position.allocationAmounts?.filter(
      (amount: AllocationAmount) =>
        amount.allocation_type_id === type.id && Number(amount.percent) > 0,
    )

  return (
    <AccordionModulePanel
      headerContent={headerContent}
      additionalToggleAction={toggleBox}
      collapsed={currentBox.collapsed}
      title={currentBox.name}
    >
      {position.allocationTypes.map((type: AllocationType, index: number) => (
        <div key={type.field}>
          {index > 0 && <div className="my-4 ml-[-1rem] h-[1px] w-screen bg-neutral-8-solid" />}
          <div
            className={`module-list-item mb-2 grid-cols-2 !border-t-0 pl-4 grid ${
              index === 0 ? "!mt-2" : ""
            }`}
          >
            <div className="text-neutral-80">
              {t("v2.allocation_types.form.allocate_by_type_label", { label: type.label })}
            </div>
            <div>
              <div className="gap-1 pr-4 grid">
                {amountsFor(type)?.map((amount: AllocationAmount) => (
                  <div key={amount.id} className="justify-end text-neutral-80 flex">
                    {amount.allocated_name}: {amount.percent}%
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </AccordionModulePanel>
  )
}

export { Allocations }
