import AJV, { AnySchema, ValidateFunction } from "ajv"

import { GenericFormShape } from "v2/react/components/jobRequisitions/RequisitionForm/types/GenericFormShape"
import { PosigenFormShape } from "v2/react/components/jobRequisitions/RequisitionForm/types/PosigenFormShape"
import {
  genericSchema as validateGeneric,
  other as validateOther,
  posigenSchema as validatePosigen,
} from "v2/react/components/jobRequisitions/RequisitionForm/validators/index"
import { ajv } from "v2/react/shared/jsonForms/JsonFormControls/ajv"

/**
 * Given a path from a graphql Error, translate it to an AJV instance path.
 *
 * See: https://github.com/ajv-validator/ajv-errors
 *
 * Example
 *   > ajvInstancePath('jobTitle')
 *   > /position/jobTitle
 * @param {string} errorPath The Built Graphql Error's path
 * @returns {string} An "instance path," which for json form data is prefixed
 *   with a forward slash.
 */
const ajvInstancePath = (errorPath: string): string => {
  if (errorPath === "systemUid") return "/systemUid"
  if (errorPath === "jobTitle") return "/position/jobTitle"
  if (errorPath === "externalIdentifier") return "/position/externalIdentifier"
  return `/${errorPath}`
}

/**
 * Given a path from a graphql Error, translate it to an AJV schema path.
 *
 * See: https://github.com/ajv-validator/ajv-errors
 *
 * Example
 *   > ajvInstancePath('jobTitle')
 *   > #/properties/position/properties/jobTitle/errorMessage
 * @param {string} errorPath The Built Graphql Error's path
 * @returns {string} A "schema path," which for json form data is prefixed
 *   with a forward slash.
 */
const ajvSchemaPath = (errorPath: string): string => {
  if (errorPath === "systemUid") return "#/properties/systemUid/errorMessage"
  if (errorPath === "jobTitle") return `#/properties/position/properties/${errorPath}/errorMessage`
  if (errorPath === "externalIdentifier") {
    return `#/properties/position/properties/${errorPath}/errorMessage`
  }
  return `#/properties/${errorPath}/errorMessage`
}

const overriddenAJV = (schemaType: string): [AJV, ValidateFunction] => {
  const validate =
    schemaType === "generic"
      ? (validateGeneric as ValidateFunction<GenericFormShape>)
      : (validatePosigen as ValidateFunction<PosigenFormShape>)

  const compileFn = (schema: AnySchema) => {
    if (typeof schema === "object" && schema && "contains" in schema) return validateOther
    return validate
  }

  type CompileFn = typeof ajv.compile

  ajv.compile = compileFn as CompileFn

  return [ajv, validate]
}

export { ajvInstancePath, ajvSchemaPath, overriddenAJV }
