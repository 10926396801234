import React, { StrictMode } from "react"
import { createRoot } from "react-dom/client"

import { SendToAts } from "v2/react/components/jobRequisitions/SendToAts/SendToAts"
import { OrgChartDatasheet } from "v2/react/components/orgChart/OrgChartDatasheet"
import { Peripherals, PeripheralsProps } from "v2/react/components/orgChart/Peripherals"
import { ProfilePanel } from "v2/react/components/orgChart/ProfilePanel/ProfilePanel"
import RootProvider from "v2/react/components/RootProvider"

interface MountSpreadsheetOptions {
  onDomElement: HTMLElement
  peripheralsProps: PeripheralsProps
}

function mountSpreadsheet({ onDomElement, peripheralsProps }: MountSpreadsheetOptions) {
  const root = createRoot(onDomElement)
  // createRoot(container!) when TypeScript is used, see:
  // https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-client-rendering-apis
  root.render(
    <StrictMode>
      <RootProvider>
        <OrgChartDatasheet peripheralsProps={peripheralsProps} />
      </RootProvider>
    </StrictMode>,
  )
}

function mountSpreadsheetDuringNextTick(options: MountSpreadsheetOptions) {
  setTimeout(mountSpreadsheet, 0, options)
}

export { Peripherals, ProfilePanel, SendToAts, mountSpreadsheetDuringNextTick }
