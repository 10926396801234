import React from "react"

import { useTruncatedTooltip } from "v2/react/hooks/useTruncatedTooltip"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"

export function HeadcountPlanningNavTitleWithSubtitle({
  title,
  subtitle,
  subtitleLinkTo,
}: {
  title: string
  subtitle: string
  subtitleLinkTo?: string | null
}) {
  const { ref, showTooltip, setShowTooltip, determineTooltip } =
    useTruncatedTooltip<HTMLHeadingElement>()
  const {
    ref: subtitleRef,
    showTooltip: showSubTooltip,
    setShowTooltip: setSubTooltip,
    determineTooltip: determineSubTooltip,
  } = useTruncatedTooltip<HTMLParagraphElement>()

  return (
    <div className="w-full">
      <Tooltip open={showSubTooltip}>
        <TooltipTrigger
          onMouseEnter={determineSubTooltip}
          onMouseLeave={() => setSubTooltip(false)}
        >
          {subtitleLinkTo ? (
            <a href={subtitleLinkTo} className="text-neutral-64">
              <p ref={subtitleRef} className="truncate text-sm">
                {subtitle}
              </p>
            </a>
          ) : (
            <p ref={subtitleRef} className="truncate text-sm">
              {subtitle}
            </p>
          )}
        </TooltipTrigger>
        <TooltipContent className="react-tooltip-content react-tooltip-content--sm">
          {subtitle}
        </TooltipContent>
      </Tooltip>

      <Tooltip open={showTooltip}>
        <TooltipTrigger onMouseEnter={determineTooltip} onMouseLeave={() => setShowTooltip(false)}>
          <h1 ref={ref} className="truncate">
            {title}
          </h1>
        </TooltipTrigger>
        <TooltipContent className="react-tooltip-content react-tooltip-content--sm">
          {title}
        </TooltipContent>
      </Tooltip>
    </div>
  )
}
