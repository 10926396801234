import { pipe } from "lodash/fp"
import React from "react"

import { HeadcountPlanParticipant } from "types/graphql"
import { AlertBanner, Message } from "v2/react/shared/status/AlertBanner"

type ChildStatusesProps = {
  participant?: HeadcountPlanParticipant
}

export function ChildStatusesBanner({ participant }: ChildStatusesProps) {
  const children = participant?.children

  if (!children || children.length === 0) {
    return null
  }

  const { invited, inProgress, complete } = buildChildGroups(children)
  const allComplete = complete.length === children.length

  return (
    <AlertBanner
      type={allComplete ? "success" : "caution"}
      className="mb-4"
      messages={pipe(
        maybeAddMessage("complete", complete),
        maybeAddMessage("in_progress", inProgress),
        maybeAddMessage("invited", invited),
      )([] as Message[])}
    />
  )
}

const maybeAddMessage =
  (status: string, participants: HeadcountPlanParticipant[]) =>
  (messages: Message[]): Message[] => {
    if (participants.length > 0) {
      const message: Message = {
        text: (
          <p
            className="font-normal"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `proposal_statuses_${status}`.t(
                "headcount_plan",
                null,
                "1",
                participants.length,
                [childNames(participants)],
              ),
            }}
          />
        ),
        icon: <ChildIcon status={status} />,
      }
      return [...messages, message]
    }
    return messages
  }

function ChildIcon({ status }: { status: string }) {
  return status === "complete" ? (
    <i className="far fa-check-circle h-4 w-4 text-status-success" />
  ) : (
    <i className="far fa-exclamation-triangle h-4 w-4 text-status-caution" />
  )
}

function childNames(children: HeadcountPlanParticipant[]) {
  if (children.length <= 2) return children.map((child) => bold(child.person?.name)).join(" and ")

  const firstChildren = children.slice(0, children.length - 1)
  const lastChild = children[children.length - 1]

  return [
    firstChildren.map((child) => bold(child.person?.name)).join(", "),
    bold(lastChild.person?.name),
  ].join(", and ")
}

function bold(text: string) {
  return `<b>${text}</b>`
}

function buildChildGroups(children: HeadcountPlanParticipant[]) {
  return children.reduce(
    (acc, child) => {
      if (child.status === "invited") acc.invited.push(child)
      if (child.status === "in_progress") acc.inProgress.push(child)
      if (child.status === "complete") acc.complete.push(child)

      return acc
    },
    {
      invited: [] as HeadcountPlanParticipant[],
      inProgress: [] as HeadcountPlanParticipant[],
      complete: [] as HeadcountPlanParticipant[],
    },
  )
}
