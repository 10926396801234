import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { CurrencyInfo, Error as ErrorType } from "types/graphql"
import { CurrencyDropdown, CurrencyOption } from "v2/react/shared/forms/CurrencyDropdown"
import { RadioGroup } from "v2/react/shared/forms/RadioGroup"
import { SaveFooter } from "v2/react/shared/overlay/Modal/SaveFooter"
import { AlertBanner } from "v2/react/shared/status/AlertBanner"
import { useGetCurrencyCodeQuery, useUpdateCurrencyCodeMutation } from "v2/redux/GraphqlApi"

interface Props {
  onSaveSuccess: (currencyInfo: CurrencyInfo) => void
}

function CurrencySettingsForm({ onSaveSuccess }: Props) {
  const { t } = useTranslation()
  const { data } = useGetCurrencyCodeQuery({})
  const company = data?.currentCompany || null
  const [currentCurrency, setCurrentCurrency] = useState<string>("")
  const [changedFromInitial, setChangedFromInitial] = useState(false)
  const [useUniqueCurrencySymbol, setUseUniqueCurrencySymbol] = useState<boolean>(
    company?.useUniqueCurrencySymbol || false,
  )
  const [formErrors, setFormErrors] = useState<string[]>([])
  const [mutate, mutation] = useUpdateCurrencyCodeMutation()

  const onSelection = (option: CurrencyOption, changedCurrency: boolean) => {
    setFormErrors([])
    setCurrentCurrency(option.value)
    setChangedFromInitial(changedCurrency)
  }

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const input = {
      currencyCode: currentCurrency,
      useUniqueCurrencySymbol,
    }

    try {
      const response = await mutate({ input }).unwrap()

      if (response.updateCompanyCurrencyCode.errors.length === 0) {
        const updatedCurrency = response.updateCompanyCurrencyCode.currency
        onSaveSuccess(updatedCurrency)
      } else {
        setFormErrors(response.updateCompanyCurrencyCode.errors.map((e: ErrorType) => e.message))
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : t("v2.defaults.error")
      setFormErrors([errorMessage])
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="react-modal__body">
        {formErrors.length > 0 && (
          <AlertBanner type="critical" messages={formErrors.map((text) => ({ text }))} />
        )}
        {changedFromInitial && (
          <div className="alert alert-caution mb-3 items-start gap-2 flex">
            <FontAwesomeIcon icon={["far", "exclamation-triangle"]} className="mt-1.5" />
            <p>{t("v2.general_settings.currency.currency_change_warning")}</p>
          </div>
        )}
        <h3>{t("v2.general_settings.currency.reporting_currency")}</h3>
        <h3 className="pb-2 text-sm text-neutral-64">
          {t("v2.general_settings.currency.reporting_currency_description")}
        </h3>
        <CurrencyDropdown onSelection={onSelection} selectionDisplay="label" />
        <div className="mt-3">
          <h3>{t("v2.general_settings.currency.unique_symbols")}</h3>
          <h3 className="pb-2 text-sm text-neutral-64">
            {t("v2.general_settings.currency.unique_symbols_description")}
          </h3>
          <div className="ml-0.5">
            <RadioGroup
              groupName="unique-currency-key"
              options={[
                { id: "0", value: "0", label: t("v2.defaults.no") },
                { id: "1", value: "1", label: t("v2.defaults.yes") },
              ]}
              defaultChecked={useUniqueCurrencySymbol ? "1" : "0"}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setUseUniqueCurrencySymbol(event.target.value === "1")
              }
            />
          </div>
        </div>
      </div>
      <SaveFooter isSaving={mutation.isLoading} />
    </form>
  )
}

export { CurrencySettingsForm }
