import fp from "lodash/fp"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { AddonRole, Role, RoleScope } from "types/graphql.enums"
import { PersonSearchInput } from "v2/react/shared/forms/PersonSearchInput"
import { Modal, useModalOverlayRef } from "v2/react/shared/overlay/Modal"
import { SaveFooter } from "v2/react/shared/overlay/Modal/SaveFooter"

import { AddonRoleInput } from "./AddonRoleInput"
import { useRoleInput } from "./hooks"
import { RoleScopeInputs } from "./RoleScopeInputs"
import { RoleItemForProps } from "./types"

interface ModalFormProps {
  addonRoles: RoleItemForProps<AddonRole>[]
  closeModal: () => void
  isOpen: boolean
  personId: string | null
  personName: string | null
  linkKeys?: string[]
  availableScopeTypes: RoleScope[]
  scopeType: RoleScope | null
  role: Role
}

const ADDON_ROLE_WEIGHTS = Object.freeze({
  [AddonRole.Compensation]: 0,
  [AddonRole.Budgeting]: 1,
  [AddonRole.PositionHierarchyManagement]: 2,
})
const EMPTY_LINK_KEYS: string[] = []

const sortByAddonRoleWeight = fp.sortBy<RoleItemForProps<AddonRole>>(
  ({ role }) => ADDON_ROLE_WEIGHTS[role],
)

function ModalForm({
  addonRoles,
  availableScopeTypes,
  closeModal,
  isOpen,
  personId,
  personName,
  linkKeys = EMPTY_LINK_KEYS,
  scopeType,
  role,
}: ModalFormProps) {
  const roleInput = useRoleInput({
    base: {
      role,
      linkKeys,
      scopeType: scopeType ?? fp.first(availableScopeTypes) ?? RoleScope.Organization,
    },
    addons: addonRoles,
    personId,
    personName,
  })
  const { t } = useTranslation()
  const { modalRef, scrollToTop } = useModalOverlayRef()
  const [error, setError] = useState("")

  const showError = (message: string) => {
    setError(message)
    scrollToTop()
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    try {
      const result = await roleInput.submit()
      const errors = result?.saveRole?.errors ?? []
      if (fp.isNil(result)) {
        showError(t("v2.permissions.edit.unexpected_error"))
        return
      }
      if (errors?.length > 0) {
        showError(errors.map((e) => e.message).join(" "))
        return
      }

      closeModal()
      window.location.reload()
    } catch (error) {
      const message = t("v2.permissions.edit.unexpected_error")
      showError(message)
    }
  }

  const clearAndCloseModal = () => {
    roleInput.clear()
    closeModal()
  }

  const handlePersonSelect = ({ id, name }: { id: number | string; name: string }) => {
    roleInput.pickPerson({ id: `person_${id}`, name })
  }

  const roleT = t(`v2.defaults.user_roles.${role}`)
  const modalTitle =
    roleInput.hadPersonInitially && roleInput.person
      ? t("v2.permissions.edit.modal_title", { role: roleT })
      : t("v2.permissions.new.modal_title", { role: roleT })

  return (
    <Modal
      isOpen={isOpen}
      onClose={clearAndCloseModal}
      overlayRef={modalRef}
      size="md"
      title={modalTitle}
    >
      <form onSubmit={onSubmit}>
        <div className="react-modal__body flex-col gap-2 flex">
          {error && (
            <div className="alert alert-critical my-4">
              <span>{error}</span>
            </div>
          )}

          <div className="input-group !mb-4">
            <label>{modalTitle}</label>
            {roleInput.person ? roleInput.person.name : null}
            {!roleInput.person ? (
              <PersonSearchInput
                autoFocus
                errorMessage={roleInput.errorSet.person}
                htmlForId="role_search_input"
                onSelect={handlePersonSelect}
                placeholder={`${t("v2.defaults.example_abbreviated")}: ${t(
                  "v2.defaults.example_name",
                )}`}
                retainNameOnSelect
              />
            ) : null}
          </div>

          {role === "limited_admin" && (
            <RoleScopeInputs
              availableScopeTypes={availableScopeTypes}
              errorMessage={roleInput.errorSet[role]}
              forBaseRole
              linkKeys={roleInput.getRoleState(role)?.linkKeys ?? linkKeys}
              onUpdate={roleInput.update}
              role={role}
              scopeType={roleInput.getRoleState(role)?.scopeType ?? scopeType}
            />
          )}

          {addonRoles.length > 0 ? (
            <label className="mb-0">{t("v2.permissions.edit.additional_permissions")}</label>
          ) : null}

          {sortByAddonRoleWeight(addonRoles).map((addonRole) => (
            <AddonRoleInput
              availableScopeTypes={addonRole.availableScopeTypes}
              errorMessage={roleInput.errorSet[role]}
              addonRole={roleInput.getRoleState(addonRole.role) ?? addonRole}
              isChecked={roleInput.isChecked(addonRole.role)}
              key={`addon-role-input-${addonRole.role}`}
              onToggle={roleInput.toggle}
              onUpdate={roleInput.update}
            />
          ))}
        </div>
        <SaveFooter isSaving={roleInput.isLoading} />
      </form>
    </Modal>
  )
}

export { ModalForm, ModalFormProps }
