import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome"
import currencyIcons from "app_config/currency_icons.json"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function maybeAddProperty(object: { [key: string]: any }, key: string, value: any) {
  if (typeof value !== "undefined") {
    return { ...object, [key]: value }
  }
  return object
}

type CurrencyIconClasses = (typeof currencyIcons)[keyof typeof currencyIcons]
type IconClass =
  | CurrencyIconClasses
  | "fac arrow-up-right"
  | "far sidebar-flip"
  | "fac short-text"
  | "fac arrow-left-right"

export function prepareIconClass(input: IconClass): FontAwesomeIconProps["icon"] {
  // @todo (MY): use zod to validate this input
  const words = input.split(" ")
  words[1] = words[1].replace("fa-", "")
  return [words[0], words[1]] as FontAwesomeIconProps["icon"]
}
