import cn from "classnames"
import { Reorder } from "framer-motion"
import React from "react"

import { DraggableListItem } from "./DraggableListItem"

interface Props<ItemType> {
  children: React.ReactNode
  dragContainerRef: React.RefObject<HTMLDivElement>
  draggableListClasses?: string
  handleDrop?: (updatedItems: ItemType[]) => void
  handleReorder: (updatedItems: ItemType[]) => void
  items: ItemType[]
  useListGroupStyle?: boolean
}

function DraggableList<ItemType>({
  children,
  dragContainerRef,
  draggableListClasses,
  handleDrop,
  handleReorder,
  items,
  useListGroupStyle = true,
}: Props<ItemType>) {
  // The Framer Reorder API does not have an explicit onDrop handler, in cases
  // where we need this handler we will deem any mouseup or mouseleave event
  // (where the user may drag the item but go out of the drag zone) as a drop
  // event.
  const handleDropEvent = () => {
    if (handleDrop) {
      handleDrop(items)
    }
  }
  return (
    <div
      className={cn(draggableListClasses, {
        "list-group is-draggable": useListGroupStyle,
      })}
      ref={dragContainerRef}
    >
      <Reorder.Group
        as="div"
        axis="y"
        values={items}
        onReorder={handleReorder}
        onMouseLeave={handleDropEvent}
        onMouseUp={handleDropEvent}
      >
        {children}
      </Reorder.Group>
    </div>
  )
}

DraggableList.Item = DraggableListItem

export { DraggableList }
