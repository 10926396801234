import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import { toggleShowGroupData } from "v2/redux/slices/VisualizationSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

function GroupDataButton() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const showGroupData = useAppSelector((state) => state.visualization.showGroupData)

  const toggleGroupData = () => {
    dispatch(toggleShowGroupData(!showGroupData))
  }

  return (
    <button
      className={cn(
        "tab btn sm:btn--large btn--secondary sm:btn--secondary tooltip column-grouping-toggle w-8 sm:w-10",
        {
          "!bg-neutral-3": showGroupData,
        },
      )}
      onClick={toggleGroupData}
      type="button"
    >
      <FontAwesomeIcon icon={["far", "layer-group"]} />
      <span className="tooltip-content tooltip-content--sm">
        {t("v2.orgchart.utilitynav.group_data")}
      </span>
    </button>
  )
}

export { GroupDataButton }
