import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { motion } from "framer-motion"
import React from "react"

import { opaqueScale } from "v2/react/utils/framerAnimationVariants"

interface BadgeProps {
  label: string
  filterKey: string
  valueKey?: string
  onRemove: (filterKey: string, valueKey?: string) => void
  onClick: () => void
}

const Badge = ({ label, filterKey, valueKey, onRemove, onClick }: BadgeProps) => {
  const handleRemove = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation()
    onRemove(filterKey, valueKey)
  }

  return (
    <motion.button
      layout
      type="button"
      className="badge--blue removable hover filter-badge"
      onClick={onClick}
      initial="hidden"
      animate="shown"
      variants={opaqueScale}
      exit="hidden"
    >
      <span className="removable-text max-w-[24rem] overflow-hidden text-ellipsis">{label}</span>
      <FontAwesomeIcon className="removable-icon" icon={["far", "times"]} onClick={handleRemove} />
    </motion.button>
  )
}

export { Badge }
