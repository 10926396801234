import React from "react"

import { PositionReportsWithSettings } from "types/graphql.enums"
import type { TableUtilitiesProps, Utility } from "v2/react/shared/tables/TableUtilities"
import { EditAssetsTableSettings } from "v2/react/shared/tables/TableUtilities/EditAssetsTableSettings"
import { EditPositionReportSettings } from "v2/react/shared/tables/TableUtilities/EditPositionReportSettings"
import { EditPositionTypesTableSettings } from "v2/react/shared/tables/TableUtilities/EditPositionTypesTableSettings"
import { EditSuccessionTableSettings } from "v2/react/shared/tables/TableUtilities/EditSuccessionTableSettings"
import { FilterTable } from "v2/react/shared/tables/TableUtilities/FilterTable"
import { SimpleFilterPositionReport } from "v2/react/shared/tables/TableUtilities/SimpleFilterPositionReport"

interface UtilityButtonsProps extends Omit<TableUtilitiesProps, "timeZone"> {
  selectedUtility: Utility
  onUtilityToggle: (utility: Utility) => void
}

interface UtilityButtonProps extends Pick<UtilityButtonsProps, "onUtilityToggle"> {
  active: boolean
}

const UtilityButtons = ({ table, selectedUtility, onUtilityToggle }: UtilityButtonsProps) => (
  <div className="items-center gap-2 flex">
    {renderButtonsFor(table, onUtilityToggle, selectedUtility)}
  </div>
)

const renderButtonsFor = (
  table: UtilityButtonsProps["table"],
  onUtilityToggle: UtilityButtonsProps["onUtilityToggle"],
  selectedUtility: UtilityButtonsProps["selectedUtility"],
) => {
  switch (table) {
    case PositionReportsWithSettings.PositionsPage:
      return (
        <>
          <FilterTable.Button
            active={selectedUtility === "filter-table"}
            onUtilityToggle={onUtilityToggle}
          />
          <EditPositionReportSettings.Button
            active={selectedUtility === "edit-position-report-table-settings"}
            onUtilityToggle={onUtilityToggle}
          />
        </>
      )
    case PositionReportsWithSettings.PositionAllocations:
      return (
        <EditPositionReportSettings.Button
          active={selectedUtility === "edit-position-report-table-settings"}
          onUtilityToggle={onUtilityToggle}
        />
      )
    case PositionReportsWithSettings.OpenPositions:
    case PositionReportsWithSettings.PositionCost:
    case PositionReportsWithSettings.RetirementRisk:
    case PositionReportsWithSettings.PositionFunding:
    case PositionReportsWithSettings.RemovedPositions:
      return (
        <>
          <SimpleFilterPositionReport.Button
            active={selectedUtility === "filter-table"}
            onUtilityToggle={onUtilityToggle}
          />
          <EditPositionReportSettings.Button
            active={selectedUtility === "edit-position-report-table-settings"}
            onUtilityToggle={onUtilityToggle}
          />
        </>
      )
    case "succession-plans":
      return (
        <EditSuccessionTableSettings.Button
          active={selectedUtility === "edit-succession-table-settings"}
          onUtilityToggle={onUtilityToggle}
        />
      )
    case "assets":
      return (
        <EditAssetsTableSettings.Button
          active={selectedUtility === "edit-assets-table-settings"}
          onUtilityToggle={onUtilityToggle}
        />
      )
    case "position-types":
      return (
        <EditPositionTypesTableSettings.Button
          active={selectedUtility === "edit-assets-table-settings"}
          onUtilityToggle={onUtilityToggle}
        />
      )
    default:
      return null
  }
}

export { UtilityButtons }
export type { UtilityButtonProps }
