import cn from "classnames"
import fp from "lodash/fp"
import React, { useEffect, useRef } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useBoolean } from "usehooks-ts"

import { Person } from "types/graphql"
import { useIdsOfPeopleWithSeatInPosition } from "v2/react/components/positions/positionForm/hooks"
import { usePositionFormContext } from "v2/react/components/positions/positionForm/PositionFormProvider"
import { Subform } from "v2/react/components/positions/positionForm/Subform"
import { FieldValues } from "v2/react/components/positions/positionForm/types"
import { useHashOfAllMutationErrors } from "v2/react/hooks/useAllMutationErrors"
import { usePersonSearch } from "v2/react/hooks/usePersonSearch"
import { Autocomplete, AutocompleteOptionProps } from "v2/react/shared/forms/Autocomplete"
import { InputErrorText } from "v2/react/shared/forms/InputErrorText"
import { Avatar } from "v2/react/shared/icons/Avatar"
import { Popover, PopoverContent, PopoverTrigger } from "v2/react/shared/overlay/Popover"
import { PersonFromCreate, usePeopleCreateMutation } from "v2/redux/GraphqlApi/PeopleApi"

type PersonAutocompleteProps = {
  selected?: Person
  id: string
  name: string
  onClearPerson?: () => void
  onSelectPerson: (personRow: FieldValues["position"]["people"][0]) => void
}

type AddNewPersonFormProps = {
  defaultName: string
  onAddPerson: (person: PersonFromCreate) => void
  onClose: React.PointerEventHandler
}

function PersonAutocomplete({
  selected,
  id,
  name,
  onClearPerson,
  onSelectPerson,
}: PersonAutocompleteProps) {
  const { t } = useTranslation()
  const otherOccupantIds = useIdsOfPeopleWithSeatInPosition()
  const { chartKey } = usePositionFormContext()
  const { inputValue, setInputValue, peopleResult, handleInputChange } = usePersonSearch({
    chartId: chartKey,
    fieldValue: selected?.name,
    omitValues: otherOccupantIds,
  })

  const personRef = useRef({
    id: selected?.id ?? null,
    name: selected?.name ?? "",
  })

  useEffect(() => {
    setInputValue(selected?.name ?? "")
    personRef.current = {
      id: selected?.id ?? null,
      name: selected?.name ?? "",
    }
  }, [selected, setInputValue])

  const handleSelectPerson = (person: Person) => {
    personRef.current = {
      id: person.id,
      name: person.name,
    }

    onSelectPerson({
      id: person.id,
      name: person.name,
      action: person.positions?.length ? "primary" : undefined,
      person,
    })
  }

  const handleAddPerson = (person: PersonFromCreate) => {
    handleSelectPerson(person)
    personRef.current = { id: person.id, name: person.name }
    hideCreatePersonForm()
  }

  const {
    setFalse: hideCreatePersonForm,
    setTrue: showCreatePersonForm,
    setValue: setIsCreatePersonFormShown,
    value: isCreatePersonFormShown,
  } = useBoolean()

  return (
    <Popover
      open={isCreatePersonFormShown}
      placement="bottom-start"
      onOpenChange={setIsCreatePersonFormShown}
    >
      <PopoverTrigger asChild>
        <Autocomplete
          AutocompleteOption={PersonOption}
          canCreateFromValue
          getKey={(person) => person.id}
          getValueFromSelected={(person) => person.name}
          id={id}
          name={name}
          placeholder={t("v2.shared.inputs.filled_by_input.jane_doe_or_empty")}
          onBlur={({ isRelatedAutocompleteOption }) => {
            if (isRelatedAutocompleteOption) return

            if (inputValue.trim() === "" || inputValue.trim() !== personRef.current.name.trim()) {
              onClearPerson?.()
              setInputValue("")
            }
          }}
          onChange={handleInputChange}
          onCreatePress={showCreatePersonForm}
          onSelect={handleSelectPerson}
          onSelectCreate={showCreatePersonForm}
          options={peopleResult}
          value={inputValue}
          wrapperClassName="w-full"
        />
      </PopoverTrigger>
      <PopoverContent className="z-50">
        <AddNewPersonForm
          defaultName={inputValue}
          onAddPerson={handleAddPerson}
          onClose={hideCreatePersonForm}
        />
      </PopoverContent>
    </Popover>
  )
}

const PersonOption = ({ option, searchTerm }: AutocompleteOptionProps<Person>) => {
  const { t } = useTranslation()

  return (
    <>
      {option.avatarThumbUrl ? (
        <div className="list-group-item-thumb shrink-0">
          <Avatar person={option} size="med" />
        </div>
      ) : null}

      <div>
        <div className="list-group-item-title break-all text-base-bold">
          {option.fullName ?? option.name}
        </div>
        <div className="text-neutral-64">{option.primaryPosition?.title}</div>
        {option.employeeId?.toLowerCase()?.includes(searchTerm.toLowerCase()) ? (
          <div className="text-neutral-64">
            {t("v2.display_fields.employee_id")}: {option.employeeId}
          </div>
        ) : null}
      </div>
    </>
  )
}

/** Based on app/views/v2/shared/inputs/filled_by_input/_add_new_person_form.html.slim */
function AddNewPersonForm({ defaultName, onClose, onAddPerson }: AddNewPersonFormProps) {
  const { t } = useTranslation()

  const parts = fp.split(" ", defaultName)
  const {
    formState: { errors },
    register,
    handleSubmit,
    setError,
  } = useForm({
    defaultValues: {
      firstName: fp.head(parts) ?? "",
      lastName: fp.pipe(fp.tail, fp.join(" "))(parts) ?? "",
    },
  })

  const [createPerson, state] = usePeopleCreateMutation()
  const gqlErrors = useHashOfAllMutationErrors(state.error, state.data?.peopleCreate?.errors)
  useEffect(() => {
    gqlErrors.rootErrors.forEach((error) => setError("root", { message: error }))
    const firstNameError = gqlErrors.getFieldError("first_name")
    const lastNameError = gqlErrors.getFieldError("last_name")
    if (firstNameError) setError("firstName", { message: firstNameError })
    if (lastNameError) setError("firstName", { message: firstNameError })
  }, [gqlErrors, setError])
  const doHandleSubmit = handleSubmit(async ({ firstName, lastName }) => {
    const result = await createPerson({ firstName, lastName })

    if ("data" in result && result.data.peopleCreate?.person)
      onAddPerson(result.data.peopleCreate.person)
  })

  return (
    // @todo Remove clunky id; in place to prevent legacy JS adding events
    <Subform onSubmit={doHandleSubmit}>
      <div className="mb-4 w-full flex-col flex">
        <div className={cn("input-group w-full", errors.firstName && "form-error")}>
          <label htmlFor="firstName">{t("v2.people.search.first_name")}</label>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <input id="firstName" className="input" autoComplete="off" {...register("firstName")} />
          {errors.firstName?.message && <InputErrorText message={errors.firstName.message} />}
        </div>
        <div className={cn("input-group w-full", errors.lastName && "form-error")}>
          <label htmlFor="lastName">{t("v2.people.search.last_name")}</label>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <input id="lastName" className="input" autoComplete="off" {...register("lastName")} />
          {errors.lastName?.message && <InputErrorText message={errors.lastName.message} />}
        </div>
      </div>

      <div className="items-center justify-end gap-2 flex">
        <button className="btn btn--secondary" type="button" onPointerUp={onClose}>
          {t("v2.defaults.cancel")}
        </button>
        <button className="btn btn--primary" id="create-person-action" type="submit">
          {t("v2.defaults.add")}
        </button>
      </div>
    </Subform>
  )
}

export { PersonAutocomplete }
