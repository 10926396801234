import React, { Ref } from "react"
import { GridCellRangeProps } from "react-virtualized"

interface CursorBeaconProps {
  beaconRef: Ref<HTMLButtonElement>
  scrollLeft: GridCellRangeProps["scrollLeft"]
  scrollTop: GridCellRangeProps["scrollTop"]
}

/**
 * The beacon complements the cursor by always being "visible" in the viewport
 * in order to act as a valid target for focus. It's the ideal target to use
 * for listening to keyboard events.
 */
function CursorBeacon({ beaconRef, scrollLeft, scrollTop }: CursorBeaconProps) {
  return (
    <button
      id="cursor-beacon"
      className="ActiveCursor__beacon"
      ref={beaconRef}
      type="button"
      tabIndex={-1}
      style={{ top: scrollTop + 20, left: scrollLeft + 20 }}
    >
      Cell Navigation
    </button>
  )
}

export { CursorBeacon }
