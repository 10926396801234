import { IconName } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React, { useState } from "react"

import { SaveApproverStateInput } from "types/graphql.d"
import { ApprovalActionModal } from "v2/react/components/approvals/ApprovalActionModal"
import { ApprovalActions } from "v2/react/components/approvals/ApprovalActions"
import {
  ApprovalActionType,
  ApproverType,
  currentApprover,
  currentApproverStatus,
  currentUserIsNextApprover,
} from "v2/react/shared/status/statusUtils"
import { useGetCurrentPersonQuery } from "v2/redux/GraphqlApi"
import { useSaveApproverStateMutation } from "v2/redux/GraphqlApi/ApprovalsApi"

interface RequisitionApprovalBarProps {
  approvers: ApproverType[]
  creatorName: string | undefined
  workflowStatus: string | null | undefined
}

function RequisitionApprovalBar({
  approvers,
  creatorName,
  workflowStatus,
}: RequisitionApprovalBarProps) {
  const { data, isLoading } = useGetCurrentPersonQuery({})
  const [saveApproverState] = useSaveApproverStateMutation()
  const [selectedAction, setSelectedAction] = useState<ApprovalActionType | null>(null)
  const [showApprovalBar, setShowApprovalBar] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  if (isLoading || !data?.currentPerson) return null

  const currentPerson = data.currentPerson
  const currentStatus = currentApproverStatus(approvers, currentPerson.id)

  const canTakeAction = () => {
    const onInitialStatus =
      currentUserIsNextApprover(approvers, currentPerson.id) &&
      workflowStatus === "pending_approval"
    const onChangeStatus =
      (workflowStatus === "changes_requested" && currentStatus === "changes_requested") ||
      (workflowStatus === "denied" && currentStatus === "denied")
    return onInitialStatus || onChangeStatus
  }

  const onCloseNoSave = () => {
    setIsOpen(false)
    setSelectedAction(null)
  }

  const onStatusSelection = (status: ApprovalActionType) => {
    setSelectedAction(status)
    setIsOpen(true)
  }

  const onSave = (status: ApprovalActionType, note: string) => {
    const approver = currentApprover(approvers)
    if (!approver) return undefined

    const input: SaveApproverStateInput = {
      approverId: approver.id,
      state: status,
      note,
    }

    const saveStatus = async () => {
      const result = await saveApproverState({ input }).unwrap()
      if (result.saveApproverState?.approvable) {
        onCloseNoSave()
      }
      return (result.saveApproverState?.errors as Error[]) || []
    }

    return saveStatus()
  }

  if (!canTakeAction() || !workflowStatus) return null

  const bannerTitle = (
    <p className="items-center gap-2 flex">
      <FontAwesomeIcon icon={["far", alertType(workflowStatus).icon as IconName]} />
      <span className="text-sm font-bold text-neutral-100">{alertType(workflowStatus).title}</span>
    </p>
  )

  const baseAlertStyles =
    "fixed bottom-3 flex flex-col justify-between h-fit gap-2 overflow-hidden p-2 sm:relative sm:left-0 sm:bottom-0 sm:items-center sm:flex-row sm:page-margin-b sm:gap-6 lg:p-4"
  const additionalStyles = "w-[calc(100%-2rem)] left-4 sm:w-auto"

  return (
    <>
      <div
        className={cn(baseAlertStyles, additionalStyles, alertType(workflowStatus).styles, {
          "flex sm:flex": showApprovalBar,
          "hidden sm:flex": !showApprovalBar,
        })}
      >
        <div className="m-0 font-bold">{bannerTitle}</div>
        <ApprovalActions selectedAction={currentStatus} onStatusSelection={onStatusSelection} />

        <button
          className="btn--ghost absolute right-2 top-2 hover:bg-transparent sm:hidden"
          onClick={() => setShowApprovalBar(false)}
          type="button"
        >
          <FontAwesomeIcon icon={["far", "minus"]} />
        </button>
      </div>

      <ApprovalActionModal
        approvable={"requisition".t("job_requisition").toLowerCase()}
        isOpen={isOpen}
        onSave={onSave}
        onCloseNoSave={onCloseNoSave}
        selectedAction={selectedAction}
        submitterName={creatorName}
      />

      <button
        className={cn(
          baseAlertStyles,
          alertType(workflowStatus).styles,
          "fixed bottom-3 right-3 sm:hidden",
          { hidden: showApprovalBar },
        )}
        onClick={() => setShowApprovalBar(true)}
        type="button"
      >
        <FontAwesomeIcon icon={["far", "paper-plane"]} />
      </button>
    </>
  )
}

export { RequisitionApprovalBar }

interface AlertLookup {
  [key: string]: Record<string, string>
}

const alertType = (status: string) => {
  const alertTypes: AlertLookup = {
    changes_requested: {
      styles: "alert-caution",
      title: "changes_requested".t("status_defaults"),
      icon: "exclamation-triangle",
    },
    denied: {
      styles: "alert-critical",
      title: "denied".t("status_defaults"),
      icon: "exclamation-circle",
    },
    pending_approval: {
      styles: "alert-pending",
      title: "status_bar_pending".t("job_requisition"),
      icon: "paper-plane",
    },
  }

  return alertTypes[status]
}
