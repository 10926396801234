import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

import { useNavController } from "v2/react/shared/navigation/useNavController"

function NavHamburger() {
  const { toggleNav } = useNavController()

  return (
    <div className="nav-hamburger no-print">
      <button
        className="btn--icon btn--ghost h-8 w-8 place-content-center grid hover:bg-neutral-3"
        onClick={toggleNav}
        type="button"
      >
        <FontAwesomeIcon icon={["far", "bars"]} />
      </button>
    </div>
  )
}

export { NavHamburger }
