import React from "react"

import { CompareValues } from "v2/react/components/headcountPlanning/TableDatasheet/CompareValues"
import { DatePickerCell as DatePickerCellPrimitive } from "v2/react/components/headcountPlanning/TableDatasheet/DatePickerCell"
import { StrikethroughCell } from "v2/react/components/headcountPlanning/TableDatasheet/StrikethroughCell"
import { Column } from "v2/react/components/headcountPlanning/TableDatasheet/types"

import { useSaveCell } from "../hooks/useSaveCell"
import { HeadcountPlanDatasheetRow } from "./types"

type Props = {
  row: HeadcountPlanDatasheetRow
  column: Column<HeadcountPlanDatasheetRow>
  headcountPlanId: string
  participantId?: string
  onSaved?: () => void
  onErrored?: () => void
  readOnly: boolean
  editable?: boolean
}
export function DatePickerCell({
  row,
  column,
  participantId,
  headcountPlanId,
  onSaved,
  onErrored,
  readOnly,
  editable,
}: Props) {
  const saveFn = useSaveCell(headcountPlanId, column.id, row, participantId)

  const currentValue = row.positionAttributesWithEdits[column.id] ?? ""
  const compareValue =
    row.type === "modified" && column.id in row.payload ? row.positionAttributes[column.id] : null

  if (row.excluded) {
    return <StrikethroughCell value={currentValue} />
  }

  if (readOnly) {
    return (
      <CompareValues compareValue={compareValue} className="non-editable-cell">
        {currentValue}
      </CompareValues>
    )
  }

  return (
    <DatePickerCellPrimitive
      rowId={row.id}
      columnId={column.id}
      saveFn={saveFn}
      name={column.id}
      allowBlank={column.id !== "start_date"}
      currentValue={currentValue}
      compareValue={compareValue}
      onSaved={onSaved}
      onErrored={onErrored}
      editable={editable}
    />
  )
}
