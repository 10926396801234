/**
 * Validates that 'min' is less than 'max'
 *
 * @param {number | undefined} min  - The minimum value
 * @param { number | undefined } max  - The maximum value
 * @returns true if 'min' is less than 'max'; otherwise, false.
 */
const minLessThanMax = (min: number | undefined, max: number | undefined) => {
  if (min === undefined || max === undefined) {
    return false
  }
  return min < max
}

export { minLessThanMax }
