import { LightMode } from "v2/react/utils/colors"

export interface StylesPosition {
  top: number
  left: number
  height: number
  width: number
}

type CellCol = "otherCol" | "lastCol"
type CellRow = "firstRow" | "otherRow"
type CompositeKey = `${CellRow}-${CellCol}`
type StyleBox = {
  height: number
  top: number
  width: number
}

const stylePatternMatcher = (row: CellRow, column: CellCol, position: StylesPosition) => {
  const { top, height, width } = position
  const patternStyles: Record<CompositeKey, StyleBox> = {
    "firstRow-otherCol": { height, top, width: width + 1 },
    "firstRow-lastCol": { height, top, width },
    "otherRow-otherCol": { height: height + 1, top: top - 1, width: width + 1 },
    "otherRow-lastCol": { height: height + 1, top: top - 1, width },
  }

  const styleKey: CompositeKey = `${row}-${column}`
  return patternStyles[styleKey]
}

/**
 * Updates an initial hash of cell positioning attributes to account for cells
 * along an edge of the spreadsheet.
 */
export function updatePositionStyles(
  firstRow: boolean,
  lastColumn: boolean,
  initial: StylesPosition,
) {
  const row = firstRow ? "firstRow" : "otherRow"
  const column = lastColumn ? "lastCol" : "otherCol"

  return stylePatternMatcher(row, column, initial)
}

const { ForegroundColors } = LightMode

/**
 * Derives the primary color of a cell.
 */
export function selectionColor(isEditable: boolean, hasError: boolean) {
  if (hasError) return ForegroundColors.Error
  if (isEditable) return ForegroundColors.Primary
  return ForegroundColors.Muted
}
