import React from "react"
import { useTranslation } from "react-i18next"

import { CrossCandidacy } from "types/graphql"

interface HighRiskTooltipProps {
  crossCandidacies: CrossCandidacy[]
  highFlightRisk: boolean
}

function HighRiskTooltip({ crossCandidacies, highFlightRisk }: HighRiskTooltipProps) {
  const { t } = useTranslation()

  return (
    (highFlightRisk || crossCandidacies.length > 0) && (
      <div className="max-w-[25rem]">
        <ul className="candidate-risks mb-0">
          {highFlightRisk && (
            <li className="m-0 list-none">
              {t("v2.succession_plan_panel.candidate.high_flight_risk")}
            </li>
          )}
          {crossCandidacies.length > 0 && (
            <li className="last:mb-0 only:list-none">
              {t("v2.succession_plan_panel.candidate.cross_candidacy_alert", {
                count: crossCandidacies.length,
              })}
              <ul className="ml-4 mt-2 list-none">
                {crossCandidacies.map((candidacy) => (
                  <li key={candidacy.id} className="mb-0">
                    {t("v2.succession_plan_panel.candidate.cross_candidacy", {
                      sort_order: candidacy.sortOrder,
                      position_title: candidacy.successionPlanPosition?.title || "",
                      report_to_name: candidacy.successionPlanPosition?.reportsToName || "None",
                    })}
                  </li>
                ))}
              </ul>
            </li>
          )}
        </ul>
      </div>
    )
  )
}

export { HighRiskTooltip }
