import cn from "classnames"
import React, { useRef } from "react"

import {
  useFieldLabelHelpers,
  usePositionController,
  usePositionFormState,
} from "v2/react/components/positions/positionForm/hooks"
import { usePositionFormContext } from "v2/react/components/positions/positionForm/PositionFormProvider"
import { useCollectionSearch } from "v2/react/hooks/useCollectionSearch"
import { Select } from "v2/react/shared/forms"
import { Autocomplete, AutocompleteBlurArg } from "v2/react/shared/forms/Autocomplete"
import { InputErrorText } from "v2/react/shared/forms/InputErrorText"
import { idFromUniqueKey } from "v2/react/utils/uniqueKey"

type LocationInputProps = {
  wrapperClassName?: string
}

function LocationInput({ wrapperClassName }: LocationInputProps) {
  const { tLabel } = useFieldLabelHelpers()
  const {
    positionFormCollections: { locations },
  } = usePositionFormContext()
  const {
    field: { onChange: changeLocationName, value: locationName },
  } = usePositionController({ name: "position.locationName" })
  const {
    field: { onChange: changeLocationId, value: locationId },
  } = usePositionController({ name: "position.locationId" })
  const { collectionResult: locationOptions } = useCollectionSearch({
    fieldKey: "location",
    filter: locationName ?? "",
  })
  const { errors } = usePositionFormState({ name: ["position.locationId"] })

  // Only changes when the selected location does.
  const locationRef = useRef({
    id: locationId ?? null,
    name: locationName,
  })

  const handleBlur = ({ isRelatedAutocompleteOption }: AutocompleteBlurArg) => {
    // Do nothing if the blur is due to the user choosing a relevant
    // option from the autocomplete options.
    if (isRelatedAutocompleteOption) return

    // Handles clearing the location on blur if the user cleared the
    // input itself.
    if (locationName === undefined || locationName.trim() === "") {
      changeLocationId(null)
      changeLocationName("")
      locationRef.current = {
        id: null,
        name: "",
      }

      return
    }

    // Resets the location name if the user didn't choose a new
    // location.
    const { current: pointOfReference } = locationRef
    if (locationId === pointOfReference.id && pointOfReference.name !== locationName) {
      changeLocationName(locationName)
    }
  }

  return (
    <>
      {locations.length > 0 && locations.length <= 10 && (
        <div
          className={cn(
            "input-group col-span-2 mb-4",
            wrapperClassName,
            errors?.position?.locationId?.message && "form-error",
          )}
        >
          <label htmlFor="position_location_id">{tLabel("position.locationId")}</label>
          <Select
            name="position.locationId"
            id="position_location_id"
            options={locations}
            useInReactHookForm
          />
          {errors?.position?.locationId?.message ? (
            <InputErrorText message={errors?.position?.locationId?.message} />
          ) : null}
        </div>
      )}
      {locations.length > 10 && (
        <div
          className={cn(
            "input-group col-span-2 mb-4",
            wrapperClassName,
            errors?.position?.locationId?.message && "form-error",
          )}
        >
          <label htmlFor="position_location_name">{tLabel("position.locationName")}</label>
          <Autocomplete
            getKey={({ id }) => id}
            getValueFromSelected={({ label }) => label}
            id="position_location_name"
            name="position.locationName"
            onBlur={handleBlur}
            onChange={(ev) => changeLocationName(ev.target.value)}
            onSelect={({ id, label }) => {
              changeLocationId(idFromUniqueKey(id))
              changeLocationName(label)
              locationRef.current = {
                id: idFromUniqueKey(id),
                name: label,
              }
            }}
            options={locationOptions}
            value={locationName}
          />
          {errors?.position?.locationId?.message && (
            <InputErrorText message={errors?.position?.locationId?.message} />
          )}
        </div>
      )}
    </>
  )
}

export { LocationInput }
