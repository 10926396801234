import classNames from "classnames"
import fp from "lodash/fp"
import React, { useEffect, useState } from "react"
import { AutoSizer, List } from "react-virtualized"

import {
  ColorCodeTypeTabs,
  Tab,
} from "v2/react/components/orgChart/SuperPanel/ColorCodingTab/ColorCodeTypeTabs"
import { ColorCodingToggle } from "v2/react/components/orgChart/SuperPanel/ColorCodingTab/ColorCodingToggle"
import { useColorCodeRenderer } from "v2/react/components/orgChart/SuperPanel/ColorCodingTab/hooks/useColorCodeRenderer"
import { useColorCodesQueryWithLegacySupport } from "v2/react/components/orgChart/SuperPanel/ColorCodingTab/hooks/useColorCodesQueryWithLegacySupport"
import { useColorCodingActions } from "v2/react/components/orgChart/SuperPanel/ColorCodingTab/hooks/useColorCodingActions"
import { useDynamicFieldUtils } from "v2/react/hooks/useDynamicFieldUtils"
import { Pulser } from "v2/react/shared/loaders/Pulser"
import { WithLoader } from "v2/react/shared/loaders/WithLoader"
import { configureColorCoding } from "v2/redux/slices/VisualizationSlice"
import {
  selectColorCodingConfigForActiveTab,
  selectColorCodingFields,
} from "v2/redux/slices/VisualizationSlice/visualizationSelectors"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

interface Props {
  useLegacyEventsToTriggerRefetch?: boolean
}

const useActiveTabColorCodingConfig = () => useAppSelector(selectColorCodingConfigForActiveTab)
const useColorCodingFields = () => useAppSelector(selectColorCodingFields)

function ColorCodingTabContent({ useLegacyEventsToTriggerRefetch = false }: Props) {
  const [selectedTab, setSelectedTab] = useState<Tab>("card")
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useAppDispatch()
  const displayMode = useAppSelector((state) => state.visualization.displayMode)
  const { isFetching } = useColorCodesQueryWithLegacySupport(useLegacyEventsToTriggerRefetch)
  const { getFieldLabel, hasFieldDefinition } = useDynamicFieldUtils()
  const { activeTab, field, isEnabled, colorCodes } = useActiveTabColorCodingConfig()
  const forChart = useAppSelector((state) => state.visualization.displayMode !== "grid")
  const colorCodingFields = useColorCodingFields()
  const { handleColorCodingFieldChange, handleEditColorClick, handleScroll, handleTabClick } =
    useColorCodingActions()
  const colorCodeRenderer = useColorCodeRenderer(colorCodes, handleEditColorClick)

  useEffect(() => {
    setIsLoading(true)
    setSelectedTab(activeTab)
  }, [activeTab])

  useEffect(() => {
    if (selectedTab === activeTab) {
      setIsLoading(false)
    }
  }, [activeTab, selectedTab])

  const handleTabChange = (tab: Tab) => {
    if (activeTab === tab) return
    setIsLoading(true)
    handleTabClick(activeTab === "card" ? "edge" : "card")
    setIsLoading(false)
  }

  useEffect(() => {
    if (displayMode === "grid") {
      dispatch(configureColorCoding({ activeColorCodingTab: "edge" }))
    }
  }, [dispatch, displayMode])

  return (
    <div
      className={classNames("h-full gap-4 p-3 grid", {
        "grid-rows-[1fr]": displayMode === "grid",
        "grid-rows-[auto_1fr]": displayMode !== "grid",
      })}
    >
      {forChart && <ColorCodeTypeTabs activeTab={activeTab} onTabClick={handleTabChange} />}
      <div
        className="flex-col rounded-lg border border-solid border-neutral-8 p-4 flex"
        data-active-tab={activeTab}
      >
        <WithLoader isLoading={isLoading}>
          <ColorCodingToggle />
          <div className="input-group mt-4">
            <label htmlFor={`${activeTab}-field-type`} className="text-base-bold">
              {"Color Code By".t("org_chart")}
            </label>
            <div className="select">
              <select
                id={`${activeTab}-field-type`}
                value={field?.toString()}
                onChange={handleColorCodingFieldChange}
                className={classNames({ "!opacity-48": !isEnabled })}
                disabled={!isEnabled}
              >
                {colorCodingFields
                  .map((field) => ({
                    field,
                    label: hasFieldDefinition(field)
                      ? getFieldLabel(field, field)
                      : `field_${field}`.t("org_chart"),
                  }))
                  .sort((a, b) => {
                    if (!a.label || !b.label) return 0
                    if (a.label < b.label) return -1
                    if (a.label > b.label) return 1
                    return 0
                  })
                  .map(({ field, label }) => (
                    <option value={field} key={`color-code-${field}`}>
                      {label}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          {field === "succession_plan_status" ? (
            <div className="alert alert-info mt-4">
              <div className="alert-description">
                {"succession_plan_section_alert".t("org_chart")}{" "}
                <a href="/succession/settings">
                  {"succession_plan_section_alert_cta".t("org_chart")}
                </a>
              </div>
            </div>
          ) : null}
          <div
            className={classNames("color-code-scroll grow overflow-auto", {
              "opacity-48": !isEnabled,
            })}
          >
            {isFetching ? (
              <Pulser />
            ) : (
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    height={height}
                    rowHeight={26}
                    width={width}
                    rowCount={fp.size(colorCodes)}
                    onScroll={handleScroll}
                    rowRenderer={colorCodeRenderer}
                  />
                )}
              </AutoSizer>
            )}
          </div>
        </WithLoader>
      </div>
    </div>
  )
}

export { ColorCodingTabContent }
