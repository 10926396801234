import cn from "classnames"
import React, { ReactNode } from "react"

interface Props {
  children: ReactNode
  makeSticky?: boolean
}

function UtilityNav({ children, makeSticky = false }: Props) {
  return (
    <div
      className={cn("utility-nav", {
        "zUtilityNav sticky top-0 backdrop-blur-sm": makeSticky,
      })}
    >
      {children}
    </div>
  )
}

export { UtilityNav }
