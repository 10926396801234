import OperationStoreAliasLookup from "./operation_store_aliases.json"

export type Lookup = typeof OperationStoreAliasLookup
export type OperationName = keyof Lookup

const client = Object.freeze("rails-client")
const operationNamesById: { [key in string]: OperationName } = {}
const normalizeIdForServer = (id: string) => `${client}/${id}`

// Populate the operationNamesById index.
for (const [operationName, operationId] of Object.entries(OperationStoreAliasLookup)) {
  operationNamesById[normalizeIdForServer(operationId)] = operationName as OperationName
}

const OperationStore = Object.freeze({
  getOperationId(operationName: OperationName) {
    const persistedAlias = OperationStore.getPersistedQueryAlias(operationName)
    return this.normalizeId(persistedAlias)
  },

  getOperationNameById(operationId: string): OperationName | undefined {
    return operationNamesById[operationId]
  },

  getPersistedQueryAlias(operationName: OperationName) {
    const persistedAlias = OperationStoreAliasLookup[operationName]
    if (typeof persistedAlias !== "string") {
      throw new Error(`Failed to find persisted alias for operation name: ${operationName}`)
    }

    return persistedAlias
  },

  normalizeId(operationId: string) {
    return operationId.startsWith(client) ? operationId : `${client}/${operationId}`
  },
})

// This is meant to match the basic API that GraphQL-Ruby's companion JS lib generates.
// See: https://graphql-ruby.org/javascript_client/sync.html#use-with-plain-javascript
//
// eslint-disable-next-line no-restricted-exports
export { OperationStore as default }
