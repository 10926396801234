import React from "react"
import { useDrop } from "react-dnd"

import { Column } from "v2/react/components/orgChart/Datasheet/types"
import { Dropzone, DropzoneFilter, EmptyDropzone } from "v2/react/shared/tables/Dropzone/Dropzone"

interface ColumnDropzoneProps<TNode> {
  columns: Column<TNode>[]
  groups: string[]
  onAddColumn: (columnKey: string) => void
  onRemoveColumn: (columnKey: string) => void
}

function ColumnDropzone<TNode>({
  columns,
  groups,
  onAddColumn,
  onRemoveColumn,
}: ColumnDropzoneProps<TNode>) {
  const [collected, drop] = useDrop<Column<TNode>, unknown, { isOver: boolean }>(() => ({
    accept: "column",
    drop: (_props, monitor) => onAddColumn(monitor.getItem().fieldKey.toString()),
    collect: (monitor) => ({ isOver: !!monitor.isOver() }),
  }))

  return (
    <Dropzone dropzoneRef={drop} isDraggingOver={collected.isOver}>
      {groups.length === 0 && <EmptyDropzone />}
      {groups.map((group) => (
        <DropzoneFilter
          key={group}
          label={columns.find((column) => column.fieldKey === group)?.label as string}
          onClick={() => onRemoveColumn(group)}
        />
      ))}
    </Dropzone>
  )
}

export { ColumnDropzone, ColumnDropzoneProps }
