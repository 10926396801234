import React, { useEffect } from "react"

import { ApprovalBadgeSm } from "v2/react/components/orgChart/ChartApproval/ApprovalBadgeSm"
import { currentApproverStatus } from "v2/react/shared/status/statusUtils"
import { useGetChartAndApproversQuery } from "v2/redux/GraphqlApi/ChartApprovalsApi"
import {
  CurrentUser,
  setChartId,
  setCurrentUser,
  setSelectedAction,
} from "v2/redux/slices/ApprovalSlice"
import { useAppDispatch } from "v2/redux/store"

import { ApprovalBadge } from "./ApprovalBadge"

export interface currentChartProps {
  chartId: number
}

export interface ApprovalDisplayProps {
  currentUser: CurrentUser
  currentChart: currentChartProps
  displayType: "dropdown" | "inline"
}

function ApprovalDisplay({ currentUser, currentChart, displayType }: ApprovalDisplayProps) {
  const dispatch = useAppDispatch()

  const { data: chart } = useGetChartAndApproversQuery({ id: currentChart.chartId })

  useEffect(() => {
    if (chart && currentUser) {
      const currentStatus = currentApproverStatus(chart.chartApprovers, currentUser.currentPersonId)
      if (currentStatus === "denied") dispatch(setSelectedAction("denied"))
      if (currentStatus === "changes_requested") dispatch(setSelectedAction("changes_requested"))
    }
  }, [chart, currentUser, dispatch])

  useEffect(() => {
    dispatch(setCurrentUser(currentUser))
    dispatch(setChartId(currentChart.chartId))
  }, [currentChart, currentUser, dispatch])

  if (!chart || !currentUser || !currentChart.chartId) return null

  return displayType === "dropdown" ? (
    <ApprovalBadge chart={chart} />
  ) : (
    <ApprovalBadgeSm chart={chart} />
  )
}

export { ApprovalDisplay }
