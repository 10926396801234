import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CellContext, TableOptions } from "@tanstack/react-table"
import classNames from "classnames"
import fp from "lodash/fp"
import React, { useCallback, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import sanitize from "sanitize-html"

import { AdpChangeRequestErrorDescriptionKey, AdpChangeRequestState } from "types/graphql.enums"
import { useClientTable } from "v2/react/hooks/useClientTable"
import { useSelectList } from "v2/react/hooks/useSelectList"
import { DropdownMenu } from "v2/react/shared/collection/menus/DropdownMenu"
import { MinStylingCell } from "v2/react/shared/tables/Table/Cell/MinStylingCell"
import { Table } from "v2/react/shared/tables/Table/Table"
import {
  changeProp,
  changePropFormattedDate,
  isBusy,
  isOpen,
  isPausedWithErrors,
  propIdFromUniqueKey,
} from "v2/redux/GraphqlApi/AdpChangeQueueApi"
import {
  ChangeRequestWithOneItem,
  FlattenedAdpChangeBatch,
  useScreen,
  useScreenStackActions,
} from "v2/redux/slices/AdpChangeSlice"

import { PausedWithErrorsAlert } from "./PausedWithErrorsModalParts"

type TableConfig = Omit<TableOptions<ChangeRequestWithOneItem>, "getCoreRowModel">
type ChangeBatchTableProps = {
  batch: FlattenedAdpChangeBatch
}

/** @todo Some general clean up :).  */
export function ChangeBatchTable({ batch }: ChangeBatchTableProps) {
  const { scrollTo } = useScreen("Batch")
  const tableRef = useRef<HTMLDivElement>(null)
  const handlePageChange = useCallback(() => {
    scrollTo({ top: 0, left: 0, behavior: "instant" })
    if (tableRef.current) {
      tableRef.current.scrollLeft = 0
      tableRef.current.scrollTop = 0
    }
  }, [scrollTo, tableRef])
  const { state } = batch
  const { columns, columnVisibility } = useRequestTableColumns(state)
  const table = useClientTable({
    autoResetPageIndex: false,
    columns,
    data: batch.changeRequests,
    initialState: { sorting: [{ id: "id", desc: true }] },
    state: { columnVisibility },
  })

  return (
    <>
      {isPausedWithErrors(batch) ? <PausedWithErrorsAlert batch={batch} /> : null}
      <Table
        table={table}
        tableRef={tableRef}
        className="table"
        onPageChange={handlePageChange}
        optOutOfScrollReset
      />
    </>
  )
}

const MoreActionsCell = ({ row: { original } }: CellContext<ChangeRequestWithOneItem, unknown>) => {
  const { pushScreens } = useScreenStackActions()
  const { t } = useTranslation()
  const [showList, setShowList] = useState(false)

  const { context, floatingStyles, getFloatingProps, getItemProps, listRef, refs } = useSelectList({
    showList,
    setShowList,
    floatOverrides: { placement: "bottom-start" },
    offset: -10,
  })

  const handleEllipsisClick = () => setShowList((curr) => !curr)
  const handleViewRawJsonClick = () => {
    pushScreens([{ screenKey: "ChangeRequest", changeRequest: original }])
    setShowList(false)
  }
  const setListActionRef = (node: HTMLElement | null) => {
    listRef.current[0] = node
  }

  return (
    <div className="dropdown--react nav-overflow-menu">
      <button
        className="btn dropdown-link"
        type="button"
        onClick={handleEllipsisClick}
        ref={refs.setReference}
      >
        <FontAwesomeIcon icon={["far", "ellipsis-h"]} className="icon-14" />
      </button>

      <DropdownMenu
        context={context}
        showList={showList}
        floatingRef={refs.setFloating}
        floatingStyles={floatingStyles}
        floatingProps={getFloatingProps}
        wrapperClasses="dropdown-menu !block !w-fit right-0 p-2 z-10 mt-2"
      >
        <button
          className="dropdown-menu-link"
          type="button"
          ref={setListActionRef}
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...getItemProps({ onClick: handleViewRawJsonClick })}
        >
          {t("v2.adp.change_requests.more_actions.view_raw_json_data")}
        </button>
      </DropdownMenu>
    </div>
  )
}

const FieldLabel = ({ row: { original } }: CellContext<ChangeRequestWithOneItem, unknown>) => {
  const { t } = useTranslation()

  const { field, fieldLabelFormat } = original.change
  if (fp.isEmpty(field) || fieldLabelFormat !== "translate") return field

  return t(`v2.adp.change_requests.fields.${field}`)
}

const ErrorDescriptionCell = ({
  row: { original },
}: CellContext<ChangeRequestWithOneItem, unknown>) => {
  const { t } = useTranslation()

  const { errorDescription } = original
  if (!errorDescription) return null
  if (errorDescription.key === AdpChangeRequestErrorDescriptionKey.InvalidField) {
    const field = (errorDescription.i18nVariables || {}).field
    return t(errorDescription.i18nKey, {
      field: t(`v2.adp.change_requests.inline_server_fields.${field}`),
    })
  }

  return t(errorDescription.i18nKey, {
    ...(errorDescription.i18nVariables ?? {}),
    interpolation: {
      escape: (value: string) => sanitize(value, { allowedTags: [], allowedAttributes: {} }),
    },
  })
}

/**
 * @todo Re-use StatusBadge component (possibly updating to use tailwind and
 *   and accept children as a prop?)
 */
const RequestStatusBadge = ({
  row: { original },
}: CellContext<ChangeRequestWithOneItem, unknown>) => {
  const { t } = useTranslation()

  const className = classNames(
    "elevation text-sm font-bold rounded-3xl py-1 px-2",
    STATUS_CLASS_NAMES[original.state],
  )

  return <span className={className}>{t(`v2.adp.change_requests.statuses.${original.state}`)}</span>
}

const STATUS_CLASS_NAMES = Object.freeze({
  [AdpChangeRequestState.Discarded]: "bg-status-success-light text-status-success",
  // "dropped" should never make it to the front end, but is included to be
  // exhaustive. (And appease the TS Gods)
  [AdpChangeRequestState.Dropped]: "bg-status-success-light text-status-success",
  [AdpChangeRequestState.Failed]: "bg-status-critical-light text-status-critical",
  [AdpChangeRequestState.FailedIgnored]: "bg-status-critical-light text-status-critical",
  [AdpChangeRequestState.FailedCopiedToNextBatch]: "bg-status-critical-light text-status-critical",
  [AdpChangeRequestState.Queued]: "bg-status-excite-light text-status-excite",
  [AdpChangeRequestState.Sending]: "bg-status-excite-light text-status-excite",
  [AdpChangeRequestState.Succeeded]: "bg-status-success-light text-status-success",
})

type RequestTableColumnsResult = {
  columns: TableConfig["columns"]
  columnVisibility: Record<string, boolean>
}

const useRequestTableColumns = (state: string): RequestTableColumnsResult => {
  const { t } = useTranslation()

  const columns = useMemo(() => {
    const tHeader = (key: string) => t(`v2.adp.change_requests.column_headers.${key}`)

    return [
      columnDef("actions", "", { cell: MoreActionsCell }),
      columnDef("id", tHeader("id"), {
        accessorFn: propIdFromUniqueKey("id"),
        cell: MinStylingCell.prepare({ alignment: "center" }),
      }),
      columnDef("state", tHeader("state"), { cell: RequestStatusBadge }),
      columnDef("name", tHeader("name"), { accessorFn: fp.prop("name") }),
      columnDef("employeeId", tHeader("employee_id"), { accessorFn: fp.prop("employeeId") }),
      columnDef("positionId", tHeader("position_id"), { accessorFn: fp.prop("positionId") }),
      columnDef("externalIdentifier", tHeader("external_id"), {
        accessorFn: fp.prop("externalIdentifier"),
      }),
      columnDef("field", tHeader("field"), { cell: FieldLabel }),
      columnDef("priorLabel", tHeader("changed_prior"), { accessorFn: changeProp("priorLabel") }),
      columnDef("currentLabel", tHeader("changed_current"), {
        accessorFn: changeProp("currentLabel"),
      }),
      columnDef("modifiedAt", tHeader("changed_on"), {
        accessorFn: changePropFormattedDate("modifiedAt"),
      }),
      columnDef("modifierName", tHeader("changed_by"), { accessorFn: changeProp("modifierName") }),
      columnDef("endpoint", tHeader("endpoint"), { accessorKey: "endpoint" }),
      columnDef("attemptsCounter", tHeader("attempts"), { accessorKey: "attemptsCounter" }),
      columnDef("errors", tHeader("errors"), { cell: ErrorDescriptionCell }),
    ]
  }, [t])

  const columnVisibility = useMemo(() => {
    // These columns are always visible.
    const base = {
      actions: false,
      id: true,
      state: false,
      name: true,
      employeeId: true,
      positionId: true,
      externalIdentifier: true,
      field: true,
      priorLabel: true,
      currentLabel: true,
      modifiedAt: true,
      modifierName: true,
      endpoint: true,
      attempts: false,
      errors: false,
    }

    if (isOpen(state)) return base
    if (isBusy(state)) return { ...base, state: true }
    return { ...base, actions: true, state: true, attempts: true, errors: true }
  }, [state])

  return { columns, columnVisibility }
}

const columnDef = (
  id: string,
  header: string,
  overrides: Partial<TableConfig["columns"][0]> = {},
) => ({
  id,
  header,
  ...overrides,
})
