import React from "react"

import { formatCurrency } from "v2/react/utils/currency"
import { useGetPositionQuery } from "v2/redux/GraphqlApi"

import PageSection from "./PageSection"
import PositionDetail from "./PositionDetail"

interface Props {
  positionId: string
}

export default function PositionDetails({ positionId }: Props) {
  const { data, isLoading } = useGetPositionQuery({ positionId })

  if (isLoading || !data?.position) return null
  const position = data.position

  return (
    <PageSection title="Details" columnCount={2}>
      <div className="col-span-2 p-3">
        <dl className="grid-cols-2 gap-3 grid">
          <PositionDetail label="Position ID" value={position.systemIdentifier} />
          <PositionDetail label="FLSA" value={position.flsa?.name} />
          <PositionDetail label="Department" value={position.department?.label} />
          <PositionDetail label="Business Unit" value={position.businessUnit?.label} />
          <PositionDetail label="Reports To" value={position.reportsToName} />
          <PositionDetail
            label="Base Pay"
            value={
              position.positionBasePayAnnualized
                ? formatCurrency({
                    value: position.positionBasePayAnnualized,
                    omitSymbol: false,
                    trailing: false,
                  })
                : null
            }
          />
          <PositionDetail label="Location" value={position.location?.label} />
          <PositionDetail
            label="Variable Pay"
            value={
              position.positionVariablePayAnnualized
                ? formatCurrency({
                    value: position.positionVariablePayAnnualized,
                    omitSymbol: false,
                    trailing: false,
                  })
                : null
            }
          />
        </dl>
      </div>
    </PageSection>
  )
}
