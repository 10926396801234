import cn from "classnames"
import React, { ReactNode } from "react"

import { InputWrapper } from "v2/react/shared/forms/InputWrapper"

interface TextAreaProps {
  autoFocus?: boolean
  defaultValue?: string
  className?: string
  disabled?: boolean
  errors?: string
  id: string
  inputRef?: React.Ref<HTMLTextAreaElement>
  label?: ReactNode
  maxLength?: number
  name?: string
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  placeholder?: string
  required?: boolean
  showAttention?: boolean
  showErrorMessage?: boolean
  textareaClassName?: string
}

const TextArea = ({
  autoFocus,
  className,
  defaultValue,
  disabled,
  errors,
  id,
  inputRef,
  label,
  maxLength,
  name,
  onChange,
  placeholder,
  required,
  showAttention,
  showErrorMessage,
  textareaClassName,
}: TextAreaProps) => (
  <InputWrapper
    className={cn(className, {
      attention: showAttention,
    })}
    errors={errors}
    id={id}
    label={label}
    showErrorMessage={showErrorMessage}
  >
    <textarea
      // The use of autoFocus may not be the best choice from an accessibility
      // perspective. Consider revising if possible.
      // https://brucelawson.co.uk/2009/the-accessibility-of-html-5-autofocus/
      // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/main/docs/rules/no-autofocus.md
      //
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      className={cn("input max-h-48 !min-h-24 resize-y", textareaClassName)}
      defaultValue={defaultValue || ""}
      disabled={disabled}
      id={id}
      maxLength={maxLength}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      ref={inputRef}
      required={required}
    />
  </InputWrapper>
)

export { TextArea }
