/* eslint-disable react/jsx-props-no-spreading, jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */
import cn from "classnames"
import React from "react"
import { useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"
import sanitize from "sanitize-html"

import { Option } from "types/graphql"
import { SourcePay, VariablePayModel } from "types/graphql.enums"
import {
  usePositionController,
  usePositionWatchField,
} from "v2/react/components/positions/positionForm/hooks"
import { usePositionFormContext } from "v2/react/components/positions/positionForm/PositionFormProvider"
import { Subform } from "v2/react/components/positions/positionForm/Subform"
import { EntityWithIndex } from "v2/react/components/positions/positionForm/types"
import { CurrencyInput, Select, TextInput } from "v2/react/shared/forms"
import { PercentInput } from "v2/react/shared/forms/PercentInput"
import { UrlHelper } from "v2/react/utils/urls"
import { useVariablePayTypesCreateMutation } from "v2/redux/GraphqlApi"
import { PositionFormCollections } from "v2/redux/GraphqlApi/PositionsApi"

type VariablePayType = PositionFormCollections["variablePayTypes"][0]
type CreateVariablePayPopoverProps = {
  onAdd: (option: Option) => void
  onCancel: React.PointerEventHandler
}

/** Based on app/views/v2/shared/budget_table/_variable_pay_form.html.slim */
function VariablePayForm({ index }: EntityWithIndex<VariablePayType | null>) {
  const {
    positionFormCollections: { variablePayPayTypes },
    setFocus,
    setValue,
  } = usePositionFormContext()

  const variablePayType = usePositionWatchField({
    name: `position.variablePaysAttributes.${index}.variablePayType`,
  })
  const {
    field: { onChange: changePayType, value: payType, ref: payTypeRef },
  } = usePositionController({ name: `position.variablePaysAttributes.${index}.payType` })

  const handlePayTypeSelect: React.ChangeEventHandler<HTMLSelectElement> = (ev) => {
    changePayType(ev.target.value)
    setValue(`position.variablePaysAttributes.${index}.amount`, "")
    // Small hack to ensure relevant input is mounted before trying to focus.
    setTimeout(() => {
      setFocus(`position.variablePaysAttributes.${index}.amount`)
    }, 32)
  }

  return (
    <div
      className={cn(
        "variable-pay-form budget-table__row items-start gap-4 grid",
        index === -1 ? "template-row hidden" : "grid-cols-1 sm:grid-cols-3",
      )}
      data-index={index}
      data-variable-pay-row=""
    >
      <div className="variable-pay-name-wrapper w-full leading-10">
        {/* The after-related styling here is a hack to prevent */}
        {/* Safari from rendering the native tooltip when there's text overflow. */}
        {/* See: https://stackoverflow.com/a/43915246 */}
        <div className="variable-pay-name w-full truncate after:content-[''] after:block">
          {variablePayType.label}
        </div>
      </div>

      <Select
        className={payType}
        id={`position_variable_pays_attributes_${index}_pay_type`}
        inputClassName="variable-pay-pay-type-field"
        name={`position.variablePaysAttributes.${index}.payType`}
        options={variablePayPayTypes}
        selected={payType}
        onSelect={handlePayTypeSelect}
        ref={payTypeRef}
      />

      {payType === SourcePay.Amount ? (
        <CurrencyInput
          formatDefaultValue
          id={`position_variable_pays_attributes_${index}_amount`}
          name={`position.variablePaysAttributes.${index}.amount`}
          useInReactHookForm
        />
      ) : (
        <PercentInput
          formatDefaultValue
          id={`position_variable_pays_attributes_${index}_amount`}
          name={`position.variablePaysAttributes.${index}.amount`}
          useInReactHookForm
          useSuffix={false}
        />
      )}
    </div>
  )
}

function CreateVariablePayPopover({ onAdd, onCancel }: CreateVariablePayPopoverProps) {
  const { t } = useTranslation()
  const {
    formState: { errors },
    handleSubmit,
    register,
    setError,
  } = useForm({ defaultValues: { name: "" } })

  const [createVariablePayType] = useVariablePayTypesCreateMutation()
  const doHandleSubmit = handleSubmit(async ({ name }) => {
    try {
      const response = await createVariablePayType({
        name,
        modelType: VariablePayModel.Position,
      }).unwrap()

      const result = response.variablePayTypesCreate
      if (result?.variablePayType) {
        onAdd({
          id: result.variablePayType.uniqueKey ?? "",
          label: result.variablePayType.name ?? "",
        })

        return
      }

      const errors = result?.errors ?? [{ message: t("v2.defaults.error") }]
      errors.forEach(({ message, path }) => {
        if (path && path[0] === "name") setError("name", { message })
        else setError("root", { message })
      })
    } catch (err) {
      setError("root", { message: t("v2.defaults.error") })
    }
  })

  const { ref, ...registerName } = register("name")

  return (
    <Subform onSubmit={doHandleSubmit}>
      <div id="create-new-variable-pay-type-form" className="w-full flex-col gap-6 flex">
        <div className="font-medium">
          <TextInput
            id="new_variable_pay_type_name"
            errors={errors.name?.message}
            label={t("v2.shared.budget_table.variable_pay.add")}
            className="mb-1"
            inputRef={ref}
            {...registerName}
          />
          <div className="text-neutral-64">
            <Trans
              components={{ a: <a /> }}
              i18nKey="v2.shared.budget_table.variable_pay.manage_variable_pay_types_in_settings_html"
              values={{
                path: UrlHelper.positionSettingsPath(),
                interpolation: {
                  escape: (value: string) =>
                    sanitize(value, { allowedTags: [], allowedAttributes: {} }),
                },
              }}
            />
          </div>
        </div>

        <div className="items-center justify-end gap-2 flex">
          <button className="btn btn--secondary" onPointerUp={onCancel} type="button">
            {t("v2.defaults.cancel")}
          </button>
          <button className="btn btn--primary" type="submit">
            {t("v2.defaults.add")}
          </button>
        </div>
      </div>
    </Subform>
  )
}

export { CreateVariablePayPopover, VariablePayForm }
