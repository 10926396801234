import fp from "lodash/fp"
import React, { ComponentType, useCallback } from "react"

import { Collection, Maybe, NodeInterface } from "types/graphql"
import { CursorSaveOptions, NodeRow } from "v2/react/components/orgChart/Datasheet/types"
import { AddOrgUnitFollowUp } from "v2/react/components/orgChart/OrgChartDatasheet/Modals/AddOrgUnitFollowUp"
import { BudgetedPayTypeFollowUp } from "v2/react/components/orgChart/OrgChartDatasheet/Modals/BudgetedPayTypeFollowUp"
import { CompensationFollowUp } from "v2/react/components/orgChart/OrgChartDatasheet/Modals/CompensationFollowUp"
import { EditEmployeeStatus } from "v2/react/components/orgChart/OrgChartDatasheet/Modals/EditEmployeeStatus"
import {
  hasBudgetedPayFollowUp,
  hasCompensationFollowUp,
  hasEmployeeTypeFollowUp,
  hasOrgUnitFollowUp,
  hasTitleChangeFollowUp,
} from "v2/react/components/orgChart/OrgChartDatasheet/Modals/helpers"
import { TitleChangeFollowUp } from "v2/react/components/orgChart/OrgChartDatasheet/Modals/TitleChangeFollowUp"
import { useDatasheetListenerActions } from "v2/redux/listeners/datasheetListeners"
import { GridState } from "v2/redux/slices/GridSlice/types"
import { EnhancedNodeInterface } from "v2/redux/slices/NodeSlice/types"
import { useAppSelector } from "v2/redux/store"

interface FollowUpModalProps {
  isOpen: boolean
  field: NonNullable<GridState["followUpModal"]["field"]>
  row: NodeRow<EnhancedNodeInterface>
}

const renderFollowUpModal = (followUpModal: GridState["followUpModal"]) => {
  const { isOpen, field, row } = followUpModal
  if (isOpen && field && row) {
    const FollowUpModal = followUpModals(field.fieldKey, row, field.value)
    return FollowUpModal ? <FollowUpModal isOpen={isOpen} field={field} row={row} /> : null
  }
  return null
}

const followUpModals = (
  fieldKey: keyof NodeInterface,
  row: NodeRow<EnhancedNodeInterface>,
  value?: Maybe<string>,
): ComponentType<FollowUpModalProps> | null =>
  fp.cond([
    [hasBudgetedPayFollowUp, () => BudgetedPayTypeFollowUp],
    [hasCompensationFollowUp, () => CompensationFollowUp],
    [hasOrgUnitFollowUp, () => AddOrgUnitFollowUp],
    [hasTitleChangeFollowUp, () => TitleChangeFollowUp],
    [({ fieldKey }) => fieldKey === "employee_status", () => EditEmployeeStatus],
    [fp.stubTrue, () => null],
  ])({ fieldKey, row, value })

const hasFollowUpAction = fp.anyPass([
  hasBudgetedPayFollowUp,
  hasCompensationFollowUp,
  hasEmployeeTypeFollowUp,
  hasOrgUnitFollowUp,
  hasTitleChangeFollowUp,
])

function useFollowUpAction() {
  const { followUp } = useDatasheetListenerActions()
  const { isOpen } = useAppSelector((state) => state.grid.followUpModal)

  const followUpAction = useCallback(
    (
      fieldKey: keyof NodeInterface,
      label: string,
      value: Maybe<string>,
      row: NodeRow<EnhancedNodeInterface>,
      collection?: Collection,
      cursorOptions?: CursorSaveOptions,
    ) => {
      // In cases where this action is called when a follow up modal is open, we
      // want to return true without triggering another follow up modal.
      if (isOpen) return true

      if (hasFollowUpAction({ fieldKey, row, value, collection })) {
        followUp({
          field: { fieldKey, label, value },
          row,
          cursorOptions,
        })
        return true
      }
      return false
    },
    [followUp, isOpen],
  )

  return [followUpAction]
}

export { renderFollowUpModal, useFollowUpAction, FollowUpModalProps }
