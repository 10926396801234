import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { Reorder } from "framer-motion"
import React from "react"
import { useTranslation } from "react-i18next"
import { useOnClickOutside } from "usehooks-ts"

import { Option } from "types/graphql.d"
import { truncateValue } from "v2/react/utils/strings"

type MenuOption = Option & { selected: boolean }

type NestingMenuProps = {
  id: string
  className?: string
  options: MenuOption[]
  setOptions: React.Dispatch<React.SetStateAction<MenuOption[]>>
}
export function NestingMenu({ id, className, options, setOptions }: NestingMenuProps) {
  const selectedOptions = options.filter((option) => option.selected)
  const [showList, setShowList] = React.useState(false)
  const dragContainerRef = React.useRef<HTMLDivElement>(null)
  const menuRef = React.useRef<HTMLDivElement>(null)
  const buttonRef = React.useRef<HTMLButtonElement>(null)
  useOnClickOutside(menuRef, () => {
    setShowList(false)
    buttonRef.current?.blur()
  })
  const { t } = useTranslation()

  const getButtonText = () => {
    const selectedOptions = options.filter((option) => option.selected)
    if (selectedOptions.length === 0) {
      return t("v2.defaults.select_an_option")
    }
    if (selectedOptions.length === 1) {
      return truncateValue(selectedOptions[0].label, 16)
    }
    return `${truncateValue(selectedOptions[0].label, 13)} +${selectedOptions.length - 1}`
  }

  const onReorder = (newOptions: MenuOption[]) => {
    setOptions((currentOptions) => [
      ...newOptions,
      ...currentOptions.filter((option) => !newOptions.includes(option)),
    ])
  }

  const handleButtonKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === "Tab" && showList) {
      setShowList(false)
    }
  }

  return (
    <div ref={menuRef}>
      <div className={classNames("select--responsive", className)}>
        <button
          id={id}
          type="button"
          onClick={() => setShowList((s) => !s)}
          ref={buttonRef}
          onKeyDown={handleButtonKeyDown}
          className="whitespace-nowrap"
        >
          {getButtonText()}
        </button>
      </div>
      <div
        className={classNames("basic-select-menu absolute z-1 mt-1", {
          invisible: !showList,
        })}
        style={{ width: selectedOptions.length > 1 ? "384px" : "192px" }}
      >
        <div className="gap-2 flex">
          <ol className="m-0 flex-1">
            {[...options].sort(optionSorter).map((option) => (
              <li
                key={option.id}
                className="m-0 grid-cols-[1rem_1fr] items-center gap-2 p-[10px] grid"
              >
                <input
                  type="checkbox"
                  id={option.id}
                  checked={option.selected}
                  className="h-4 w-4"
                  onChange={(event) =>
                    setOptions(
                      options.map((opt) =>
                        opt.id === option.id ? { ...opt, selected: event.target.checked } : opt,
                      ),
                    )
                  }
                />
                <label htmlFor={option.id} className="side-label">
                  {option.label}
                </label>
              </li>
            ))}
          </ol>
          {selectedOptions.length > 1 ? (
            <div className="flex-1 rounded-[4px] bg-primary-3 p-2">
              <div className="mb-1 border-0 border-b border-solid border-b-neutral-8 pb-1 text-sm font-bold">
                Nesting Order
              </div>
              <div ref={dragContainerRef}>
                <Reorder.Group as="div" axis="y" values={selectedOptions} onReorder={onReorder}>
                  {selectedOptions.map((option, index) => (
                    <Reorder.Item
                      as="div"
                      key={option.id}
                      value={option}
                      dragElastic={0}
                      dragConstraints={dragContainerRef}
                      className="grid-cols-[1fr_min-content] items-center rounded-sm bg-primary-3-solid py-[0.15rem] grid"
                    >
                      <div
                        className="grid-cols-[min-content_1fr] items-center grid"
                        style={{ paddingLeft: `${index * 6}px` }}
                      >
                        <FontAwesomeIcon icon={["far", "long-arrow-alt-right"]} />
                        <span className="pl-1 text-sm font-medium">{option.label}</span>
                      </div>
                      <FontAwesomeIcon
                        icon={["fas", "grip-vertical"]}
                        className="h-[8.94px] w-[5.5px] text-neutral-64"
                      />
                    </Reorder.Item>
                  ))}
                </Reorder.Group>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

function optionSorter(a: MenuOption, b: MenuOption) {
  return a.label.localeCompare(b.label)
}
