import classNames from "classnames"
import React from "react"

type TimelineTableThProps = {
  className?: string
  style?: React.CSSProperties
  hideBorderRight?: boolean
  hideBorderLeft?: boolean
  children: React.ReactNode
}
export function TimelineTableTh({
  className,
  style,
  hideBorderLeft,
  hideBorderRight,
  children,
}: TimelineTableThProps) {
  return (
    <th
      className={classNames(
        "min-w-[120px] max-w-[320px] border border-t-0 border-solid border-neutral-8",
        className,
        {
          "border-l-0": hideBorderLeft,
          "border-r-0": hideBorderRight,
        },
      )}
      style={style}
    >
      {children}
    </th>
  )
}
