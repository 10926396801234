import fp from "lodash/fp"
import { FieldValues, RegisterOptions, useFormContext, UseFormRegister } from "react-hook-form"

import { UseInReactHookFormProp } from "v2/react/shared/forms/types"

type UseWithReactHookFormFieldArg = {
  name?: string
  useInReactHookForm?: UseInReactHookFormProp
}

type UseWithReactHookFormFieldResult = {
  fieldName: string | undefined
  formContext: ReturnType<typeof useFormContext> | null
  isEnabled: boolean
  isInContext: boolean
  register: ReturnType<typeof useFormContext>["register"] | undefined
  registerOptions: RegisterOptions | undefined
}

/**
 * Utility hook which safely returns values useful for working with
 * react-hook-form.
 *
 * The raison d'être is to support writing components that can be configured
 * via props to interplay with react-hook-form without the constraint of having
 * to be within a react-hook-form context. Components using this can be used
 * safely anywhere in the app.
 */
function useWithReactHookFormField({
  name,
  useInReactHookForm,
}: UseWithReactHookFormFieldArg): UseWithReactHookFormFieldResult {
  // Danger point: while TS indicates this *always* returns an object, it will
  // be null if the caller is not within a FormProvider. We make this explicit
  // with our return type so callers use this safely.
  const formContext = useFormContext()
  const fieldName = typeof useInReactHookForm === "object" ? useInReactHookForm.name : undefined
  const registerOptions =
    typeof useInReactHookForm === "object" ? useInReactHookForm.registerOptions : undefined
  // TODO: If we are in a context, and we `useInReactHookForm` was an object
  // with register options, return a version of the function that applies those
  // options.
  const register =
    typeof useInReactHookForm === "function" ? useInReactHookForm : formContext?.register

  return {
    isEnabled: Boolean(useInReactHookForm),
    isInContext: Boolean(formContext),
    formContext,
    fieldName: fieldName ?? name,
    registerOptions,
    register,
  }
}

/**
 * Safely extracts an error message applied to a given field.
 */
function useReactHookFormFieldError(arg: UseWithReactHookFormFieldArg): string | undefined {
  const { isEnabled, formContext, fieldName } = useWithReactHookFormField(arg)

  if (!isEnabled || !formContext || fieldName === undefined) return undefined
  const {
    formState: { errors },
  } = formContext

  const maybeErrorObject = fp.prop(fieldName, errors)
  return maybeErrorObject && typeof maybeErrorObject.message === "string"
    ? maybeErrorObject.message
    : undefined
}

/**
 * Returns a register function (or undefined); provided as a convenience for
 * handling the `useInReactHookForm` prop.
 */
function useWithReactHookFormRegister(
  arg: UseWithReactHookFormFieldArg,
): UseFormRegister<FieldValues> | undefined {
  const { isEnabled, register } = useWithReactHookFormField(arg)

  return isEnabled ? register : undefined
}

export { useWithReactHookFormField, useReactHookFormFieldError, useWithReactHookFormRegister }
