import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React, { SyntheticEvent } from "react"
import { useTranslation } from "react-i18next"

import { IconWrapper } from "v2/react/shared/collection/menus/Dropdown/DropdownItem/IconWrapper"
import { ProgressBar } from "v2/react/shared/loaders/ProgressBar"

function Submit({
  onClick,
  onCancelClick,
  isSubmitting,
}: {
  onClick: (e: SyntheticEvent) => void
  onCancelClick: (e: SyntheticEvent) => void
  isSubmitting: boolean
}) {
  const { t } = useTranslation()

  return (
    <div className="send-to-ats__modal-form-footer space-x-2 flex">
      <button
        type="button"
        onClick={onCancelClick}
        className={cn("btn--large btn--secondary", { "btn-disabled": isSubmitting })}
        disabled={isSubmitting}
      >
        {t("v2.defaults.cancel")}
      </button>
      <button
        type="submit"
        onClick={onClick}
        className={cn("btn--large btn--primary", { "btn-disabled": isSubmitting })}
        disabled={isSubmitting}
      >
        <IconWrapper icon={<FontAwesomeIcon icon={["far", "paper-plane"]} />}>
          {isSubmitting
            ? t("v2.defaults.sending")
            : t("v2.job_requisitions.modals.send_to_ats.send_to_ats")}
        </IconWrapper>
      </button>
      {isSubmitting && <ProgressBar loading={isSubmitting} />}
    </div>
  )
}

export { Submit }
