import { EntityId } from "@reduxjs/toolkit"
import { useCallback } from "react"

import type { Maybe, NodeInterface } from "types/graphql"
import {
  RowType,
  type Column,
  type CursorSaveOptions,
  type NodeRow,
} from "v2/react/components/orgChart/Datasheet/types"
import { useFollowUpAction } from "v2/react/components/orgChart/OrgChartDatasheet/FollowUpModals"
import { useDatasheetListenerActions } from "v2/redux/listeners/datasheetListeners"
import { endWriteWithCursor, moveCursor } from "v2/redux/slices/GridSlice/cursor/cursorActions"
import type { Field } from "v2/redux/slices/GridSlice/types"
import { preparePersonPositionNodeAttributes } from "v2/redux/slices/NodeSlice/mutations/preparePersonPositionNodeAttributes"
import {
  nodeSelectors,
  useUpdatePersonPositionNodeMutation,
} from "v2/redux/slices/NodeSlice/NodeApi"
import { formattedNodeProp } from "v2/redux/slices/NodeSlice/nodeHelpers/nodeProps"
import type {
  EnhancedNodeInterface,
  UpdateInlineAttributeInput,
} from "v2/redux/slices/NodeSlice/types"
import { AppDispatch, useAppDispatch, useAppSelector } from "v2/redux/store"

/**
 * Standard save function for cell forms with basic semantics; returns a
 * function scoped to the cell and which accepts a new value and options.
 */
export function useSingleAttributeUpdateForPersonPositionNode(
  id: EntityId,
  column: Column<NodeInterface>,
) {
  const node = useAppSelector((state) => nodeSelectors.selectById(state, id))
  const { execute, mutationState } = useUpdatePersonPositionNodeFunc()

  const nextExecute = useCallback(
    async (value: Maybe<string>, options?: CursorSaveOptions) => {
      if (!node) return { ok: false }
      const row = { id: node.id, data: node, rowType: RowType.Node as RowType.Node }
      return execute(row, column, value, options)
    },
    [column, execute, node],
  )

  return { execute: nextExecute, mutationState }
}

export function useUpdatePersonPositionNodeFunc() {
  const dispatch = useAppDispatch()
  const [followUpAction] = useFollowUpAction()
  const { execute, mutationState } = usePreparedUpdatePersonPositionNodeMutation()

  const nextExecute = useCallback(
    async (
      row: NodeRow<EnhancedNodeInterface>,
      field: Field,
      value: Maybe<string>,
      options?: CursorSaveOptions,
    ) =>
      dispatch(async (_, getState) => {
        const node = nodeSelectors.selectById(getState(), row.id)
        if (!node) return { ok: false }

        // Skip a write if the field's value isn't changing. Manually act on
        // given options since we return early (in a success case).
        if (formattedNodeProp(field.fieldKey, node) === value) {
          processOptions(dispatch, options)
          return { ok: true }
        }

        const { fieldKey, label } = field
        const followUp = followUpAction(fieldKey, label, value, row, field.collection, options)
        if (followUp) return { ok: false }

        const attributes = preparePersonPositionNodeAttributes({
          featureFlags: getState().session.featureFlags,
          value,
          node,
          fieldKey,
        })
        return execute({ id: row.id, attributes, primaryTrigger: fieldKey }, options)
      }),
    [dispatch, execute, followUpAction],
  )

  return { execute: nextExecute, mutationState }
}

/**
 * Wraps our standard PersonPositionNode mutation in order to perform common
 * checks and handle options in a unified location.
 */
export function usePreparedUpdatePersonPositionNodeMutation() {
  const dispatch = useAppDispatch()
  const { fieldSaved } = useDatasheetListenerActions()
  const [performUpdateMutation, mutationState] = useUpdatePersonPositionNodeMutation()

  const execute = useCallback(
    async (input: UpdateInlineAttributeInput, options?: CursorSaveOptions) =>
      dispatch(async (_, getState) => {
        const nodeMissing = !nodeSelectors.selectById(getState(), input.id)
        if (nodeMissing) return { ok: false }

        // These options trigger regardless of success, and can occur prior to
        // retrieving a result from the server.
        processOptions(dispatch, options)

        const result = await performUpdateMutation(input).unwrap()
        const data = result.updatePersonPositionNode
        const errors = data?.errors ?? []
        const noErrors = errors.length === 0

        // Trigger a field transition indicating the field saved.
        if (data && noErrors) fieldSaved({ id: input.id, fieldKey: input.primaryTrigger })

        return { ok: data && noErrors }
      }),
    [dispatch, fieldSaved, performUpdateMutation],
  )

  return { execute, mutationState }
}

const processOptions = (dispatch: AppDispatch, options?: CursorSaveOptions) => {
  if (options && "moveAfterTo" in options) dispatch(moveCursor({ direction: options.moveAfterTo }))
  else if (options && "transitionKeyCanMove" in options)
    dispatch(endWriteWithCursor({ transitionKeyCanMove: options.transitionKeyCanMove }))
}
