import { createSelector } from "@reduxjs/toolkit"
import fp from "lodash/fp"

import { selectFieldIndex } from "v2/redux/slices/ContainerSlice/containerSelectors"
import {
  groupIdFromGroupValues,
  pickTieredGroupValues,
} from "v2/redux/slices/GridSlice/gridHelpers"
import { selectGroupFieldKeys, selectNodeFilterFunc } from "v2/redux/slices/GridSlice/gridSelectors"
import { GroupRow, RowType } from "v2/redux/slices/GridSlice/types"
import { nodeSelectors } from "v2/redux/slices/NodeSlice/NodeApi"
import { FieldKey } from "v2/redux/slices/NodeSlice/types"

/**
 * Selects an array of distinct group rows based on the nodes currently in
 * state.
 *
 * @public
 */
const selectDistinctGroupRows = createSelector(
  selectGroupFieldKeys,
  selectFieldIndex,
  selectNodeFilterFunc,
  nodeSelectors.selectAll,
  (fieldKeys, fieldIndex, filterNodes, nodes) =>
    fp.pipe(
      filterNodes,
      fp.flatMap(pickTieredGroupValues(fieldKeys)),
      fp.uniqBy(groupIdFromGroupValues),
      fp.map((groupValues) => toGroupRow(groupValues, fieldIndex, fieldKeys)),
    )(nodes),
)

function toGroupRow(
  groupValues: string[],
  columnIndex: ReturnType<typeof selectFieldIndex>,
  groupFieldKeys: FieldKey[],
): GroupRow {
  const value = fp.last(groupValues) || ""
  const depthAndIndex = groupValues.length - 1
  const groupId = groupIdFromGroupValues(groupValues)
  const groupKey = groupFieldKeys[depthAndIndex] as FieldKey

  return {
    childrenCount: 0,
    depth: depthAndIndex,
    fieldKey: groupKey,
    groupPath: groupValues,
    id: groupId,
    isExpanded: true,
    isHidden: false,
    label: columnIndex[groupKey]?.label || "",
    rowType: RowType.Group,
    value,
  }
}

export { selectDistinctGroupRows }
