import dayjs from "dayjs"
import React from "react"
import { useTranslation } from "react-i18next"

import { FeatureFlags, ProfilePanelPermissions } from "types/graphql"
import { ProfilePanelMode } from "types/graphql.enums"
import { ModuleListItem } from "v2/react/components/orgChart/ProfilePanel/ModuleListItem"
import { useSelectedBox } from "v2/react/components/orgChart/ProfilePanel/Settings/hooks/useSelectedBox"
import { AccordionModulePanel } from "v2/react/shared/layout/AccordionModulePanel"
import { useAppSelector } from "v2/redux/store"

interface CompensationProps {
  abilities: ProfilePanelPermissions
  featureFlags: FeatureFlags
}

function Compensation({ abilities, featureFlags }: CompensationProps) {
  const person = useAppSelector((state) => state.profilePanel.person)
  const { currentBox, toggleBox } = useSelectedBox("compensation", ProfilePanelMode.Person)
  const { t } = useTranslation()

  if (!currentBox || !person) return null
  if (!featureFlags.compensation || !abilities.canReadCompensation || !person.basePayType)
    return null

  const basePay = (
    <ModuleListItem>
      <div>{t("v2.profile_panel.base_pay")}</div>
      <div>
        {window.App.Helpers.formatCurrency(person.basePay || "", { currency: person.currencyCode })}
      </div>
    </ModuleListItem>
  )

  const basePayType = (
    <ModuleListItem>
      <div>{t("v2.profile_panel.base_pay_type")}</div>
      <div>{person.basePayType ? t(`v2.profile_panel.${person.basePayType}`) : ""}</div>
    </ModuleListItem>
  )

  const hoursPerWeek = (
    <ModuleListItem>
      <div>{t("v2.profile_panel.hours_per_week")}</div>
      <div>{person.hoursPerWeek?.toFixed(1) || ""}</div>
    </ModuleListItem>
  )

  const variablePays = (person.variablePays || []).map((payType) =>
    payType.display_name ? (
      <ModuleListItem key={payType.display_name}>
        <div>{payType.display_name}</div>
        <div>{payType.amount ? payType.formatted_value : null}</div>
      </ModuleListItem>
    ) : null,
  )

  const payEffectiveDate = (
    <ModuleListItem>
      <div>{t("v2.profile_panel.pay_effective_date")}</div>
      <div>{person.payEffectiveDate && dayjs(person.payEffectiveDate).format("MMM D, YYYY")}</div>
    </ModuleListItem>
  )

  const payHistory = (
    <ModuleListItem>
      <div />
      <a href={`/people/${person.id || ""}/compensation`}>
        {t("v2.profile_panel.pay_history_link")}
      </a>
    </ModuleListItem>
  )

  const headerContent = <div className="title">{t("v2.profile_panel.compensation")}</div>

  return (
    <AccordionModulePanel
      headerContent={headerContent}
      additionalToggleAction={toggleBox}
      collapsed={currentBox.collapsed}
      title={currentBox.name}
    >
      {basePay}
      {basePayType}
      {person.basePayType === "hourly" && hoursPerWeek}
      {variablePays}
      {payEffectiveDate}
      {payHistory}
    </AccordionModulePanel>
  )
}

export { Compensation }
