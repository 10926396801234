import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import fp from "lodash/fp"

import { ApprovalActionType } from "v2/react/shared/status/statusUtils"

export type CancelType = "cancel" | "edit"

interface CurrentUser {
  currentPersonId: number
  canConfigure: boolean
  isCollaborator: boolean
}

interface ApprovalUI {
  dropdown: boolean
  configureModal: boolean
  cancelModal: boolean
  cancelType: CancelType
  statusModal: boolean
}

interface ApprovalState {
  currentUser: CurrentUser | null
  chartId: number | null
  selectedAction: ApprovalActionType | null
  tempSelectedAction: ApprovalActionType | null
  approvalUI: ApprovalUI
}

const initialState: ApprovalState = {
  currentUser: null,
  chartId: null,
  selectedAction: null,
  tempSelectedAction: null,
  approvalUI: {
    dropdown: false,
    configureModal: false,
    cancelModal: false,
    cancelType: "cancel",
    statusModal: false,
  },
}

const ApprovalSlice = createSlice({
  name: "approval",
  initialState,
  reducers: {
    setCurrentUser: (state, { payload }: PayloadAction<CurrentUser>) =>
      fp.set("currentUser", payload)(state),
    setChartId: (state, { payload }: PayloadAction<number>) => fp.set("chartId", payload)(state),
    setSelectedAction: (state, { payload }: PayloadAction<ApprovalActionType | null>) =>
      fp.set("selectedAction", payload)(state),
    setTempSelectedAction: (state, { payload }: PayloadAction<ApprovalActionType | null>) =>
      fp.set("tempSelectedAction", payload)(state),
    toggleDropdown: (state, { payload }: PayloadAction<boolean>) =>
      fp.set("approvalUI.dropdown", payload)(state),
    toggleConfigureModal: (state, { payload }: PayloadAction<boolean>) =>
      fp.pipe(
        fp.set("approvalUI.configureModal", payload),
        fp.set("approvalUI.dropdown", false),
      )(state),
    toggleCancelModal: (
      state,
      { payload }: PayloadAction<{ open: boolean; isEditType: boolean }>,
    ) =>
      fp.pipe(
        fp.set("approvalUI.cancelModal", payload.open),
        fp.set("approvalUI.cancelType", payload.isEditType ? "edit" : "cancel"),
      )(state),
    toggleStatusModal: (state, { payload }: PayloadAction<boolean>) =>
      fp.set("approvalUI.statusModal", payload)(state),
  },
})

export const {
  setChartId,
  setCurrentUser,
  setSelectedAction,
  setTempSelectedAction,
  toggleDropdown,
  toggleConfigureModal,
  toggleCancelModal,
  toggleStatusModal,
} = ApprovalSlice.actions
export { ApprovalSlice, ApprovalState, CurrentUser }
