import { Collections, NodeInterface } from "types/graphql"
import { config as widgetConfig } from "v2/react/components/dashboard/config"
import { Widget } from "v2/react/components/dashboard/types"
import { DEFAULT_PAGE_SIZE } from "v2/react/constants"

const buildPositionQueryFromKeys = (
  keys: (keyof NodeInterface)[],
) => `query NodeContainerPeopleAndPositionsConnection(
  $key: String!
  $filters: [FilterAttributes!]
  $orderClauses: [OrderClauseAttributes!]
  $page: Int
) {
  nodeContainer(key: $key) {
    nodePage(
      filters: $filters
      orderClauses: $orderClauses
    ) {
      totalCount
      offsetBasedNodePage(
        page: $page
        perPage: ${DEFAULT_PAGE_SIZE}
      ) {
        ... on NodeInterface {
          id
          position_id
          person_id
          reports_to_position {
            id
            people {
              id
              name
            }
          }
        ${keys.join("\n")}
        }
      }
    }
  }
}`

const buildPositionQueryForDrillDown = (name: Widget) =>
  buildPositionQueryFromKeys(widgetConfig[name].columns)

const buildFilterOptionsQueryFromKeys = (
  keys: (keyof Collections)[],
) => `query CurrentCompanyCollections
  {
    currentCompany{
      collections{
        ${keys
          .map(
            (key) => `
          ${key}{
            name
            label
            options {
              nodes { id label }
            }
          }
        `,
          )
          .join("\n")}
      }
    }
  }`

const buildFilterOptionsQueryForDrillDown = (name: Widget) =>
  buildFilterOptionsQueryFromKeys(widgetConfig[name].filterOptions)

export { buildPositionQueryForDrillDown, buildFilterOptionsQueryForDrillDown }
