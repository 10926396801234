import cn from "classnames"
import React, { ReactNode } from "react"
import { useTranslation } from "react-i18next"

interface MatrixProps {
  axisMode: string
  children: ReactNode
  matrixClassNames?: string
}

function Matrix({ axisMode, children, matrixClassNames }: MatrixProps) {
  const { t } = useTranslation()

  return (
    <div className={cn("matrix", matrixClassNames)}>
      <div className="matrix__left-bar">
        <div className="matrix__left-bar-text matrix-bar-text">
          {axisMode === "performance"
            ? t("v2.succession_plan_panel.matrix.potential")
            : t("v2.succession_plan_panel.matrix.performance")}
        </div>
      </div>
      {children}
      <div className="matrix__bottom-bar">
        <div className="matrix-bar-text">{t(`v2.succession_plan_panel.matrix.${axisMode}`)}</div>
      </div>
    </div>
  )
}

export { Matrix }
