import React from "react"

import { Spinner } from "v2/react/shared/loaders/Spinner"

interface LoadingIndicatorProps {
  children?: React.ReactNode
  isLoading: boolean
  spinnerStyle?: React.CSSProperties
  wrapperStyle?: React.CSSProperties
}

function LoadingIndicator({
  children,
  isLoading,
  spinnerStyle,
  wrapperStyle,
}: LoadingIndicatorProps) {
  if (isLoading)
    return (
      <div style={wrapperStyle}>
        <Spinner style={spinnerStyle} />
      </div>
    )

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

export { LoadingIndicator }
