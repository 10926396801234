import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { skipToken } from "@reduxjs/toolkit/dist/query"
import { AnimatePresence, motion, useAnimation } from "framer-motion"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { Person, Position } from "types/graphql"
import { TruncatedText } from "v2/react/components/succession/SuccessionPlanPanel/shared/TruncatedText"
import {
  positionParentNamesDisplay,
  positionParentTitle,
} from "v2/react/components/succession/SuccessionPlanPanel/utils/positionMappers"
import { PaginationCircles } from "v2/react/shared/buttons/PaginationCircles"
import { Avatar } from "v2/react/shared/icons/Avatar"
import { BlueIcon } from "v2/react/shared/icons/BlueIcon"
import { pjaxModalFor } from "v2/react/utils/pjax"
import { UrlHelper } from "v2/react/utils/urls"
import { useGetSuccessionPlanPanelPermissionsQuery } from "v2/redux/GraphqlApi/SuccessionApi"
import { closeSuccessionPlanSidebar } from "v2/redux/slices/SuccessionPlanPanelSlice"
import { useAppDispatch } from "v2/redux/store"

interface FilledByProps {
  canEditPosition: boolean
  position: Position
}

function FilledBy({ canEditPosition, position }: FilledByProps) {
  const { t } = useTranslation()
  const { data: permissions } = useGetSuccessionPlanPanelPermissionsQuery(
    position.id ? { positionId: position.id } : skipToken,
  )
  const dispatch = useAppDispatch()

  const handleEdit = () => {
    dispatch(closeSuccessionPlanSidebar())
    pjaxModalFor(UrlHelper.editPositionPath(position.id))
  }

  if (!permissions) return null

  return (
    <div className="module-card filled-by mb-0">
      <div className="module-card__header rounded-t-xl bg-primary-3">
        <div className="w-full grid-cols-2 gap-2 grid">
          <p className="text-base-bold">
            {t("v2.succession_plan_panel.summary.position_filled_by")}
          </p>
          <p className="text-base-bold">{t("v2.succession_plan_panel.summary.reports_to")}</p>
        </div>
      </div>
      <div className="module-card__body max-h-16 justify-center">
        <div className="w-full grid-cols-2 items-center gap-2 grid">
          {position.filledBy && position?.filledBy?.length > 0 ? (
            <FilledByPersonDisplay people={position.filledBy} />
          ) : (
            <div className="items-center gap-2 flex">
              <BlueIcon icon={["far", "chair-office"]} size="med-lg" />
              {canEditPosition ? (
                <button className="badge--yellow cursor-pointer" onClick={handleEdit} type="button">
                  {t("v2.defaults.open_position")}
                </button>
              ) : (
                <div className="badge--yellow">{t("v2.defaults.open_position")}</div>
              )}
            </div>
          )}
          <div>
            {permissions.successionPlanPanelPermissions.canAccessPositionPage &&
            position.parent?.id ? (
              <a href={UrlHelper.positionShowPath(position.parent?.id)} className="text-link">
                {positionParentTitle(position, t)}
              </a>
            ) : (
              <p>{positionParentTitle(position, t)}</p>
            )}
            <TruncatedText
              displayClasses="text-sm text-neutral-64"
              displayContent={positionParentNamesDisplay(position, t)}
              tooltipContent={positionParentNamesDisplay(position, t)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export { FilledBy }

const FilledByPersonDisplay = ({ people }: { people: Person[] }) => {
  const { t } = useTranslation()
  const [activePerson, setActivePerson] = useState<Person>(people[0])
  const activePersonControls = useAnimation()

  useEffect(() => {
    activePersonControls.start({
      opacity: [0, 1],
      transition: { duration: 0.6, ease: [0.645, 0.045, 0.355, 1] },
    })
  }, [activePersonControls])

  const handleSetActive = (person: Person) => {
    if (person.id === activePerson.id) return
    setActivePerson(person)
    activePersonControls.start({
      opacity: [0, 1],
      transition: { duration: 0.6, ease: [0.645, 0.045, 0.355, 1] },
    })
  }

  const handleRotatePeople = (newIndex: number) => handleSetActive(people[newIndex])

  useEffect(() => {
    setActivePerson(people[0])
  }, [people])

  return (
    <div className="grid-cols-[auto_1fr] items-center gap-3 grid">
      <div className="gap-2 flex">
        {people.length > 1 && (
          <PaginationCircles
            itemCount={people.length}
            handlePagination={handleRotatePeople}
            verticalDisplay
          />
        )}

        <div className="circles max-w-[6rem] items-center">
          <AnimatePresence>
            <motion.div
              className="relative"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.4, ease: [0.645, 0.045, 0.355, 1] }}
            >
              <div className="tooltip tooltip-left -mx-1 rounded-full bg-transparent p-0">
                <Avatar
                  key={activePerson.id}
                  person={activePerson}
                  size="med-lg"
                  classNames="block circle-border"
                />
                <div className="tooltip-content tooltip-content--sm">{activePerson.fullName}</div>
              </div>
              {people.length > 1 && (
                <div className="tooltip tooltip-left tooltip tooltip-left circle circle-xs !absolute bottom-0 right-0 z-1 shrink-0 translate-x-2 translate-y-1 bg-neutral-80 p-0 text-xs !text-white">
                  <FontAwesomeIcon icon={["far", "plus"]} size="sm" className="!text-white" />
                  {people.length - 1}
                  <div className="tooltip-content tooltip-content--sm flex-col">
                    {people
                      .filter((p) => p.id !== activePerson.id)
                      .map((p) => (
                        <p className="text-sm" key={p.id}>
                          {p.fullName}
                        </p>
                      ))}
                  </div>
                </div>
              )}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
      <div className="min-w-0">
        <motion.div style={{ opacity: 0 }} animate={activePersonControls}>
          <a
            href={UrlHelper.personProfilePath(activePerson.id)}
            className="cursor-pointer p-0 text-left text-base text-neutral-100"
          >
            <TruncatedText
              displayClasses="text-base"
              displayContent={activePerson.fullName || ""}
              tooltipContent={activePerson.fullName || ""}
            />
          </a>
          <p className="text-sm text-neutral-64">
            {t("v2.succession_plan_panel.summary.years", {
              count: activePerson.employmentTenure || 0,
            })}
          </p>
        </motion.div>
      </div>
    </div>
  )
}
