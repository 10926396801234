import React from "react"

import { Table, Utility } from "v2/react/shared/tables/TableUtilities"
import { FilterTable } from "v2/react/shared/tables/TableUtilities/FilterTable"
import { isFilterableTable } from "v2/react/shared/tables/TableUtilities/FilterTable/utils/filters"

interface UtilityBadgesProps {
  table: Table
  open: boolean
  onUtilityToggle: (utility: Utility) => void
}

const UtilityBadges = ({ table, open, onUtilityToggle }: UtilityBadgesProps) => {
  if (isFilterableTable(table)) {
    return <FilterTable.Badges table={table} open={open} onUtilityToggle={onUtilityToggle} />
  }
  return null
}

export { UtilityBadges }
export type { UtilityBadgesProps }
