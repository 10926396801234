import dayjs from "dayjs"
import React from "react"
import { useTranslation } from "react-i18next"

import { FeatureFlags, ProfilePanelPermissions } from "types/graphql"
import { ProfilePanelMode } from "types/graphql.enums"
import { ModuleListItem } from "v2/react/components/orgChart/ProfilePanel/ModuleListItem"
import { useSelectedBox } from "v2/react/components/orgChart/ProfilePanel/Settings/hooks/useSelectedBox"
import { AccordionModulePanel } from "v2/react/shared/layout/AccordionModulePanel"
import { useAppSelector } from "v2/redux/store"

interface EmployeeInfoProps {
  abilities: ProfilePanelPermissions
  featureFlags: FeatureFlags
}

function EmployeeInfo({ abilities, featureFlags }: EmployeeInfoProps) {
  const person = useAppSelector((state) => state.profilePanel.person)
  const { currentBox, toggleBox } = useSelectedBox("employee_info", ProfilePanelMode.Person)
  const { t } = useTranslation()

  if (!currentBox || !person || !abilities.canReadSensitivePersonData) return null

  const canViewSuccessionFields = featureFlags.successionPlanning && abilities.canReadSuccessionPlan

  const headerContent = <div className="title">{t("v2.profile_panel.employee_info")}</div>

  const hireDateDisplay = (
    <ModuleListItem>
      <div>{t("v2.profile_panel.hire_date")}</div>
      <div>{person.hireDate && dayjs(person.hireDate).format("MMM D, YYYY")}</div>
    </ModuleListItem>
  )

  const yearsAtCompany = (
    <ModuleListItem>
      <div>{t("v2.profile_panel.years_at_company")}</div>
      <div>
        {person.employmentTenure
          ? "years_label".t("defaults", null, "%d years", person.employmentTenure, [
              person.employmentTenure,
            ])
          : ""}
      </div>
    </ModuleListItem>
  )

  const employeeStatus = (
    <ModuleListItem className="profile-panel-section-employee-info">
      <div>{t("v2.profile_panel.status")}</div>
      <div className={`status ${person.employeeStatus?.systemType}`}>
        {person.employeeStatus?.label
          ? person.employeeStatus?.label.t("profile", "header", null, null, [])
          : ""}
      </div>
    </ModuleListItem>
  )

  const employeeType = (
    <ModuleListItem>
      <div>{t("v2.profile_panel.employee_type")}</div>
      <div>{person.employeeType?.labelTranslated}</div>
    </ModuleListItem>
  )

  const employeeId = (
    <ModuleListItem>
      <div>{t("v2.profile_panel.employee_id")}</div>
      <div>{person.employeeId}</div>
    </ModuleListItem>
  )

  const performance = (
    <ModuleListItem>
      <div>{t("v2.profile_panel.performance")}</div>
      <div>{person.performance}</div>
    </ModuleListItem>
  )

  const potential = (
    <ModuleListItem>
      <div>{t("v2.profile_panel.potential")}</div>
      <div>{person.potential}</div>
    </ModuleListItem>
  )

  const flightRisk = (
    <ModuleListItem>
      <div>{t("v2.profile_panel.flight_risk")}</div>
      <div>{person.flightRisk}</div>
    </ModuleListItem>
  )

  const successionPlanFields = () => {
    if (!canViewSuccessionFields) return null
    return (
      <>
        {performance} {potential} {flightRisk}
      </>
    )
  }

  return (
    <AccordionModulePanel
      headerContent={headerContent}
      additionalToggleAction={toggleBox}
      collapsed={currentBox.collapsed}
      title={currentBox.name}
    >
      {hireDateDisplay}
      {yearsAtCompany}
      {employeeStatus}
      {employeeType}
      {employeeId}
      {successionPlanFields()}
    </AccordionModulePanel>
  )
}

export { EmployeeInfo }
