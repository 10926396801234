import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { PreloadedState } from "redux"

import { AppStore, RootState, setupStore } from "v2/redux/store"

type RecursiveHash = {
  [key in string]: string | RecursiveHash
}

type AppContainerSetupArg = {
  canFallbackToPreloadedStateInGlobalThis?: boolean
  initialGonHash?: GonHash
  initialReduxState?: PreloadedState<RootState>
  locale?: string
  localeData?: {
    [key in string]: RecursiveHash
  }
}

/**
 * Central point for accessing global values.
 *
 * This is written to provide a seamless interface that works well with SSR
 * and CSR. For SSR, property values may be defined via setters. These setters
 * may also be used for CSR, but generally isn't necessary.
 *
 * When properties aren't defined by setter calls, they will be defined lazily
 * upon first access. When defined lazily, they may try pulling data from
 * `globalThis` (assumed to be `window`).
 */
class AppContainerImpl {
  private currentI18nInstance: typeof i18n | undefined

  private currentReduxStore: AppStore | undefined

  private currentGonHash: GonHash | undefined

  private initialLocaleData: RecursiveHash | undefined

  /**
   * Hash of values provided by the Rails server.
   *
   * On lazy initialization: falls back to the `gon` hash defined on
   * `globalThis`.
   *
   * For SSR: unsafe to call unless `setGonIfBlank` has already been called.
   *
   * @see https://github.com/gazay/gon
   */
  get gon(): GonHash {
    if (this.currentGonHash) return this.currentGonHash

    // Fall back to globalThis. This should be reliable outside of SSR where
    // `gon` will be assigned on `window`.
    if (globalThis.gon) {
      this.currentGonHash = globalThis.gon
      return this.currentGonHash
    }

    throw new Error("Cannot access `AppContainer.gon` before it is initialized")
  }

  /**
   * Current I18next instance.
   *
   * On lazy initialization: decorates the default exported by I18next.
   *
   * For SSR: safe to call regardless of prior set calls. However, failure to
   * set translations before render may lead to console errors on the client
   * side.
   */
  get i18nInstance(): typeof i18n {
    this.setI18nInstanceIfBlank()
    if (!this.currentI18nInstance) throw new Error("Unable to initialize an i18n instance")

    return this.currentI18nInstance
  }

  /**
   * Current redux store.
   *
   * On lazy initialization: sets up the store and passes any initial state
   * defined on `globalThis`.
   *
   * For SSR: while safe to call before `setupReduxStoreIfBlank`, doing so will
   * prevent using any initial state from the server. Generally, this will only
   * happen if accessing this in the top-level of a module (i.e. not within a
   * function body).
   */
  get reduxStore(): AppStore {
    this.setupReduxStoreIfBlank()
    if (!this.currentReduxStore) throw new Error("Unable to setup a current Redux store")

    return this.currentReduxStore
  }

  setupForSSR(setupArg?: AppContainerSetupArg): this {
    return this.setGonIfBlank(setupArg)
      .setI18nInstanceIfBlank(setupArg)
      .maybeChangeI18nLocale(setupArg)
      .setLocaleDataIfBlank(setupArg)
      .setupReduxStoreIfBlank(setupArg)
  }

  setGonIfBlank(setupArg?: AppContainerSetupArg): this {
    if (!this.currentGonHash) this.currentGonHash = setupArg?.initialGonHash
    if (!this.currentGonHash) this.currentGonHash = globalThis?.gon
    if (!this.currentGonHash) throw new Error("Unable to set the current Gon hash")

    return this
  }

  setI18nInstanceIfBlank(arg?: AppContainerSetupArg): this {
    if (this.currentI18nInstance) return this

    const i18nInstance = i18n
    i18nInstance.use(initReactI18next).init({
      lng: arg?.locale,
      fallbackLng: "en",
      debug: false,
      interpolation: {
        prefix: "%{",
        suffix: "}",
        escapeValue: true,
      },
      nsSeparator: ".",
      resources: {},
    })

    this.currentI18nInstance = i18nInstance

    return this
  }

  maybeChangeI18nLocale(setupArg?: AppContainerSetupArg): this {
    if (!this.currentI18nInstance) return this
    if (!setupArg?.locale) return this
    if (this.currentI18nInstance.language === setupArg.locale) return this

    this.currentI18nInstance.changeLanguage(setupArg.locale)
    return this
  }

  setLocaleDataIfBlank(setupArg?: AppContainerSetupArg): this {
    if (this.initialLocaleData) return this
    if (!setupArg) return this
    if (!setupArg?.localeData) return this

    const locale = setupArg.locale ?? this.i18nInstance.language
    const localeData = setupArg.localeData
    this.initialLocaleData = localeData

    Object.keys(localeData[locale] ?? {}).forEach((key) => {
      this.i18nInstance.addResourceBundle(locale, key, localeData[locale][key], true, true)
    })

    return this
  }

  setupReduxStoreIfBlank(setupArg?: AppContainerSetupArg): this {
    if (this.currentReduxStore) return this

    const givenPreloadedState = setupArg?.initialReduxState
    if (givenPreloadedState) {
      this.currentReduxStore = setupStore(givenPreloadedState)
      return this
    }

    const shouldUseFallback = setupArg?.canFallbackToPreloadedStateInGlobalThis ?? true
    if (shouldUseFallback && globalThis.__PRELOADED_STATE__) {
      this.currentReduxStore = setupStore(globalThis.__PRELOADED_STATE__)
      delete globalThis.__PRELOADED_STATE__
      return this
    }

    this.currentReduxStore = setupStore()
    return this
  }
}

/**
 * Central point for accessing global values.
 *
 * This is written to provide a seamless interface that works well with SSR
 * and CSR. For SSR, property values may be defined via setters. These setters
 * may also be used for CSR, but generally isn't necessary.
 *
 * When properties aren't defined by setter calls, they will be defined lazily
 * upon first access. When defined lazily, they may try pulling data from
 * `globalThis` (assumed to be `window`).
 *
 * @property {GonHash} gon - Hash of values defined by the Rails server. It's
 *   best to treat this as a read-only hash of values that won't change w/o a
 *   page reload.
 * @property {typeof i18n} i18nInstance - I18n instance (i18next). This generally
 *   doesn't need to be accessed except when loading/setting more translations.
 * @property {AppStore} reduxStore - The configured redux store. This is handy
 *   for tracking "global" state, or state that may apply to disparate
 *   components, and is subject to changing while the user interacts with the
 *   UI.
 */
const appContainer = new AppContainerImpl()

export { appContainer, AppContainerSetupArg }
