import React from "react"

import { ProfilePanelMode } from "types/graphql.enums"
import { withRootProvider } from "v2/react/components/RootProvider"
import { openProfilePanelSidebar, setProfilePanelMode } from "v2/redux/slices/ProfilePanelSlice"
import { closeSuccessionPlanSidebar } from "v2/redux/slices/SuccessionPlanPanelSlice"
import { useAppDispatch } from "v2/redux/store"

import { PanelIconTooltip } from "./PanelIconTooltip"

interface Props {
  linkPath: string
  linkText: string
  mode: ProfilePanelMode
  personId?: string | null
  positionId?: string | null
}

const WithProvider = ({ linkPath, linkText, mode, personId = null, positionId = null }: Props) => {
  const dispatch = useAppDispatch()

  const handleOpenProfile = (event: React.MouseEvent) => {
    event.stopPropagation()
    if (mode === ProfilePanelMode.Position) {
      dispatch(setProfilePanelMode(ProfilePanelMode.Position))
    } else {
      dispatch(setProfilePanelMode(ProfilePanelMode.Person))
    }
    dispatch(openProfilePanelSidebar({ positionId, personId }))
    dispatch(closeSuccessionPlanSidebar())
  }

  // See: https://tailwindcss.com/docs/hover-focus-and-other-states#styling-based-on-parent-state
  return (
    <div className="group items-center gap-0 p-0 flex">
      {linkPath ? (
        <a className="link p-0" href={linkPath}>
          {linkText}
        </a>
      ) : (
        <span>{linkText}</span>
      )}

      <PanelIconTooltip handleOpenProfile={handleOpenProfile} />
    </div>
  )
}

const IconTextLink = withRootProvider(WithProvider, "IconTextLink")

export { IconTextLink }
