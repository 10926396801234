enum SsoProviderType {
  AZURE_SSO = "AZURE_SSO",
  GOOGLE_SSO = "GOOGLE_SSO",
  OKTA_SSO = "OKTA_SSO",
}

interface LoginSettings {
  isSsoRequired: boolean
  permittedSsoProviders: SsoProviderType[]
  ssoAzureTenantIdRestrictions: string[]
  ssoGoogleDomainRestrictions: string[]
}

interface LoginSettingsErrors {
  permitted_sso_providers?: string[]
}

interface CurrencySettings {
  currencyCode: string
}

type DisplayMode = "tree" | "three_level" | "grid"

export { LoginSettingsErrors, LoginSettings, SsoProviderType, DisplayMode, CurrencySettings }
