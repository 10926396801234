import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { ReactNode } from "react"

import { prepareIconClass } from "v2/react/utils/misc"

interface EmptyStateProps {
  buttonText: ReactNode
  icon: string
  note: ReactNode
  onClick: () => void
}

function EmptyState({ buttonText, icon, note, onClick }: EmptyStateProps) {
  return (
    <div className="flex-col items-center gap-4 pb-6 pt-8 flex">
      <div className="justify-center flex">
        <div className="icon--round">
          <FontAwesomeIcon icon={prepareIconClass(icon)} className="fa-lg" />
        </div>
      </div>
      <div className="justify-center text-center text-neutral-64 flex">{note}</div>
      <div className="justify-center flex">
        <button className="btn--large btn--secondary" type="button" onClick={onClick}>
          <FontAwesomeIcon icon={["far", "plus"]} />
          {buttonText}
        </button>
      </div>
    </div>
  )
}
export { EmptyState }
