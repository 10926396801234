import {
  PayGradeCollection,
  PayGradeCollectionQuery,
  PayGradeCollectionQueryVariables,
  PayGradeCreateInput,
  PayGradeCreateMutation,
  PayGradeDestroyInput,
  PayGradeDestroyMutation,
  PayGradeUpdateInput,
  PayGradeUpdateMutation,
} from "types/graphql"
import { GraphqlApi } from "v2/redux/GraphqlApi"
import { flatMutationOperation, queryOperation } from "v2/redux/utils/endpoints"

const invalidatesPayGradeTags = (payGradeId: string) => [
  "PayGrade",
  { type: "PayGrade", id: payGradeId },
]

const EMPTY_PAY_GRADE_CONNECTION: PayGradeCollection = {
  collection: [],
  metadata: {
    totalPages: 0,
    totalCount: 0,
    currentPage: 0,
    limitValue: 0,
  },
}

const PayGradeApi = GraphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    payGradeCollection: builder.query<PayGradeCollection, PayGradeCollectionQueryVariables>({
      query: queryOperation<PayGradeCollectionQueryVariables>("PayGradeCollection"),
      providesTags: (res) => {
        const nodeTags = (res?.collection ?? [])
          .filter(({ id }) => Boolean(id))
          .map(({ id }) => ({ type: "PayGrade", id: id as string }))
        return ["PayGrade", ...nodeTags]
      },
      transformResponse: (res: PayGradeCollectionQuery) =>
        res.payGrades ?? EMPTY_PAY_GRADE_CONNECTION,
    }),
    payGradeCreate: builder.mutation<PayGradeCreateMutation, PayGradeCreateInput>({
      invalidatesTags: (res) => invalidatesPayGradeTags(res?.payGradeCreate?.payGrade?.id ?? ""),
      query: flatMutationOperation<PayGradeCreateInput>("PayGradeCreate"),
    }),
    payGradeDestroy: builder.mutation<PayGradeDestroyMutation, PayGradeDestroyInput>({
      invalidatesTags: (res, _, arg) => invalidatesPayGradeTags(arg?.payGradeId),
      query: flatMutationOperation<PayGradeDestroyInput>("PayGradeDestroy"),
    }),
    payGradeUpdate: builder.mutation<PayGradeUpdateMutation, PayGradeUpdateInput>({
      invalidatesTags: (res) => invalidatesPayGradeTags(res?.payGradeUpdate?.payGrade?.id ?? ""),
      query: flatMutationOperation<PayGradeUpdateInput>("PayGradeUpdate"),
    }),
  }),
})

export const {
  usePayGradeCollectionQuery,
  usePayGradeCreateMutation,
  usePayGradeDestroyMutation,
  usePayGradeUpdateMutation,
} = PayGradeApi
