// This approximates the elevation box-shadow rule.
// @see styles/decorations.scss
// Note: This is written in a way that expects the SVG to already have <defs>
const defineShadowElevation = (svg: d3.Selection<SVGElement>): d3.Selection<SVGElement> => {
  const filter = svg.select("defs").append("filter").attr("id", "node-shadow-elevation")

  // Outer Shadow Components
  filter.append("feOffset").attr("dx", 0).attr("dy", 0).attr("in", "SourceAlpha")

  filter.append("feGaussianBlur").attr("stdDeviation", "1")

  const componentTransfer = filter
    .append("feComponentTransfer")
    .attr("result", "reducedOpacityOuterShadow")

  componentTransfer.append("feFuncA").attr("type", "linear").attr("slope", "0.16")

  // Inset shadow 1; AKA shadow as a border
  const innerComponentTransfer = filter.append("feComponentTransfer").attr("in", "SourceAlpha")
  // This type of component transfer (feFuncA 1 0) inverts the alpha channel.
  innerComponentTransfer.append("feFuncA").attr("type", "table").attr("tableValues", "1 0")
  filter.append("feGaussianBlur").attr("stdDeviation", "0.25")
  filter.append("feOffset").attr("dx", 0).attr("dy", 0).attr("result", "innerBorderOffset")

  filter.append("feComposite").attr("operator", "in").attr("in2", "innerBorderOffset")
  filter
    .append("feComposite")
    .attr("operator", "in")
    .attr("in2", "SourceAlpha")
    .attr("result", "innerBorderShadow")

  // Combine Shadows
  const merge = filter.append("feMerge")
  merge.append("feMergeNode").attr("in", "reducedOpacityOuterShadow")
  merge.append("feMergeNode").attr("in", "SourceGraphic")
  merge.append("feMergeNode").attr("in", "innerBorderShadow")

  return svg
}

export default defineShadowElevation
