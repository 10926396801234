import { skipToken } from "@reduxjs/toolkit/dist/query"
import cn from "classnames"
import iconMergeChart from "images/org_chart/icon-merge-chart.svg"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import RootProvider from "v2/react/components/RootProvider"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"
import { pjaxModalFor } from "v2/react/utils/pjax"
import { UrlHelper } from "v2/react/utils/urls"
import { useGetChartAndApproversQuery } from "v2/redux/GraphqlApi/ChartApprovalsApi"
import { useAppSelector } from "v2/redux/store"

interface Props {
  canManageOfficialChart: boolean
  chartId: number
  isOfficialChart: boolean
  type: "button" | "menu-link"
}

function MergeButton({ canManageOfficialChart, chartId, isOfficialChart, type }: Props) {
  const displayMode = useAppSelector((state) => state.visualization.displayMode)

  const { data: chart, isLoading } = useGetChartAndApproversQuery(
    chartId && !isOfficialChart && canManageOfficialChart ? { id: chartId } : skipToken,
  )
  const { t } = useTranslation()

  useEffect(() => {
    if (window.gon.selected_chart.merging || window.gon.show_merge_status) {
      window.App.OrgChart.initMergingStatus()
    }
  }, [])

  if (isLoading || isOfficialChart || !canManageOfficialChart || displayMode === "cards")
    return null

  const mergeChart = (e: React.MouseEvent) => {
    e.preventDefault()
    pjaxModalFor(UrlHelper.chartMergePath(chartId))
  }

  const disableMerge = !(
    chart?.status === "approved" ||
    (!chart?.status && chart?.chartApprovers.length === 0)
  )

  return (
    <RootProvider>
      {type === "menu-link" ? (
        <MergeButtonElement
          type={type}
          disableMerge={disableMerge}
          onClick={(e) => mergeChart(e)}
          message={t("v2.orgchart.pagenav.merge")}
        />
      ) : (
        <Tooltip placement="bottom-end">
          <TooltipTrigger>
            <MergeButtonElement
              type={type}
              disableMerge={disableMerge}
              onClick={(e) => mergeChart(e)}
              message={t("v2.orgchart.pagenav.merge")}
            />
          </TooltipTrigger>
          <TooltipContent className="react-tooltip-content--sm">
            {t("v2.orgchart.pagenav.merge")}
          </TooltipContent>
        </Tooltip>
      )}
    </RootProvider>
  )
}

const MergeButtonElement = ({
  type,
  disableMerge,
  onClick,
  message,
}: {
  type: Props["type"]
  disableMerge: boolean
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  message?: string
}) => (
  <button
    className={cn("merge", {
      disabled: disableMerge,
      "btn--large btn--secondary": type === "button",
      "dropdown-menu-link": type === "menu-link",
    })}
    disabled={disableMerge}
    onClick={onClick}
    type="button"
  >
    <img src={iconMergeChart} alt={message} />
    {type === "menu-link" && <span className="ml-2">{message}</span>}
  </button>
)

export { MergeButton }
