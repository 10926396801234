import fp from "lodash/fp"

import { Error as GqlError } from "types/graphql.d"

import { DatasheetErrorSet } from "../types"

/**
 * Builds a node error set with the given errors; merged into `original` if present.
 *
 * @public
 */
function makeNextDatasheetErrorSet(errors: GqlError[], id: string, original?: DatasheetErrorSet) {
  const incoming = makeDatasheetErrorSet(id, errors)
  if (!original) return incoming

  return fp.merge(original, incoming)
}

/**
 * Removes any field specific errors for `fields` from `errorSet`.
 *
 * @public
 */
function clearFieldErrors(fields: string[], errorSet: DatasheetErrorSet) {
  return { ...errorSet, fieldErrors: fp.omit(fields, errorSet.fieldErrors) }
}

/**
 * Determines if the given error set is empty.
 *
 * @public
 */
function isEmptyErrorSet(errorSet: DatasheetErrorSet) {
  return fp.isEmpty(errorSet.fieldErrors) && fp.isEmpty(errorSet.baseErrors)
}

/**
 * Builds a new node error set with the given errors.
 *
 * @public
 */
function makeDatasheetErrorSet(id: string, errors: GqlError[]): DatasheetErrorSet {
  return fp.reduce(intoErrorSet, makeEmptyDatasheetErrorSet(id), errors)
}

function makeEmptyDatasheetErrorSet(id: string): DatasheetErrorSet {
  return {
    id,
    fieldErrors: {},
    baseErrors: [],
  }
}

function intoErrorSet(errorSet: DatasheetErrorSet, error: GqlError) {
  const byAppendingMessage = byAppending(error.message)
  if (!error.path || error.path.length === 0)
    return fp.update("baseErrors", byAppendingMessage, errorSet)

  const head = fp.head(error.path)
  const tail = fp.tail(error.path)
  const fieldAttributePath = ["fieldErrors", ...tail]

  if (head !== "attributes" || fieldAttributePath.length === 0)
    return fp.update("baseErrors", byAppendingMessage, errorSet)

  return fp.update(fieldAttributePath, byAppendingMessage, errorSet)
}

function byAppending<T>(item: T) {
  return (items: T[] | undefined) => [...(items || []), item]
}

export { isEmptyErrorSet, clearFieldErrors, makeNextDatasheetErrorSet, makeDatasheetErrorSet }
