import React from "react"

import {
  useIsFieldUneditable,
  usePositionController,
} from "v2/react/components/positions/positionForm/hooks"
import { WysiwygTextAreaWithWrapper } from "v2/react/shared/forms"

/** Based on app/views/v2/positions/form/position_management_tabs/_job_description.html.slim */
function JobDescriptionTab() {
  const {
    field: { onBlur, onChange, value },
    fieldState: { error },
  } = usePositionController({ name: "position.description" })
  const isUneditable = useIsFieldUneditable("description")

  return (
    <div id="job-description-tab" className="tab-panel p-4">
      <WysiwygTextAreaWithWrapper
        className="tinmymce"
        defaultValue={value}
        disabled={isUneditable}
        errors={error?.message}
        id="position_description"
        name="position.description"
        onBlur={onBlur}
        onChange={onChange}
      />
    </div>
  )
}

export { JobDescriptionTab }
