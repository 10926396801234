import React, { FormEvent, useState } from "react"
import { useTranslation } from "react-i18next"

import { Error, PayGrade, PayGradeUpdateInput } from "types/graphql"
import { PayGradeForm } from "v2/react/components/payGrades/Index/PayGradeForm"
import { Modal } from "v2/react/shared/overlay/Modal"
import { usePayGradeUpdateMutation } from "v2/redux/GraphqlApi/PayGradeApi"

interface Props {
  isOpen: boolean
  payGrade: PayGrade
  onClose: () => void
}

function EditModal({ isOpen, payGrade, onClose }: Props) {
  const { t } = useTranslation()
  const [errors, setErrors] = useState<Error[]>([])
  const [mutate, { isLoading }] = usePayGradeUpdateMutation()

  const handleClose = () => {
    setErrors([])
    onClose()
  }

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()

    const form = event.target as HTMLFormElement
    const formData = new FormData(form)
    const input: PayGradeUpdateInput = {
      code: formData.get("code"),
      currencyCode: formData.get("currency_code"),
      minimum: Number(formData.get("min")),
      maximum: Number(formData.get("max")),
      name: formData.get("name"),
      payGradeId: payGrade.id,
      periodType: formData.get("period_type"),
    } as PayGradeUpdateInput

    const result = await mutate(input).unwrap()

    if (result.payGradeUpdate?.payGrade?.id) {
      handleClose()
    } else if (result.payGradeUpdate?.errors) {
      setErrors(result.payGradeUpdate?.errors)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={t("v2.pay_grades.index.edit_pay_grade", {
        code: payGrade?.code,
        name: payGrade?.name ? ` - ${payGrade?.name}` : "",
      })}
    >
      <PayGradeForm
        errors={errors}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        payGrade={payGrade}
        setErrors={setErrors}
      />
    </Modal>
  )
}

export { EditModal }
