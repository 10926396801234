import { createSelector, EntityId } from "@reduxjs/toolkit"
import { createStructuredSelector } from "reselect"

import { NodeInterface } from "types/graphql"
import { nodeSelectors } from "v2/redux/slices/NodeSlice/NodeApi"
import { formattedNodeProp } from "v2/redux/slices/NodeSlice/nodeHelpers/nodeProps"
import { RootState } from "v2/redux/store"

const withNode = (state: RootState, _2: unknown, id: EntityId) =>
  nodeSelectors.selectById(state, id)

const selectEdgeColorState = createStructuredSelector({
  edgeColorField: (state: RootState) => state.visualization.edgeColorField,
  edgeColorIdsByName: (state: RootState) => state.visualization.edgeColorIdsByName,
  edgeColors: (state: RootState) => state.visualization.edgeColors,
  isEdgeColorEnabled: (state: RootState) => state.visualization.isEdgeColorEnabled,
})

/**
 * @note defined in its own file to prevent circular dependencies.
 * @public
 * @returns the color applying to the given node; otherwise null.
 */
const selectNodeColor = createSelector(
  [selectEdgeColorState, withNode],
  ({ edgeColorField, edgeColorIdsByName, isEdgeColorEnabled, edgeColors }, node) => {
    if (!isEdgeColorEnabled || !edgeColorField || !node) return null

    const nodeValue: string =
      edgeColorField === "title"
        ? node.title ?? ""
        : formattedNodeProp(edgeColorField as keyof NodeInterface, node)
    const maybeId = edgeColorIdsByName[nodeValue]
    if (!maybeId) return null

    const colorCodes = edgeColors[edgeColorField] || {}
    const code = maybeId ? colorCodes[maybeId] : null
    return code ? code.color : null
  },
)

export { selectNodeColor }
