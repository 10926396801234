import classNames from "classnames"
import React from "react"

interface InputCollectionProps {
  children: React.ReactNode
  className?: string
}

const InputCollection = ({ children, className }: InputCollectionProps) => (
  <div className={classNames("input-collection", className)}>{children}</div>
)

export { InputCollection }
