import fp from "lodash/fp"
import { useTranslation } from "react-i18next"

import {
  JobCodeAndTitleFieldsetTranslations,
  PositionTypesConnectionNodeAttributes,
} from "v2/react/components/positionTypes/EmbeddedPositionTypeControl/types"
import { useHashOfAllMutationErrors } from "v2/react/hooks/useAllMutationErrors"
import { usePositionTypesCreateMutation } from "v2/redux/GraphqlApi/PositionTypesApi"

function useAddPositionTypeForm(validateOnly: boolean) {
  const [mutate, state] = usePositionTypesCreateMutation()

  const errors = useHashOfAllMutationErrors(state.error, state.data?.positionTypesCreate?.errors)
  const save = async (positionTypeNodeAttributes: PositionTypesConnectionNodeAttributes) => {
    try {
      const { positionTypesCreate } = await mutate({
        jobCode: positionTypeNodeAttributes.jobCode ?? "",
        title: positionTypeNodeAttributes.title ?? "",
        validateOnly,
      }).unwrap()
      if (!positionTypesCreate) return { success: false as const, positionTypeNodeAttributes }

      const { errors, positionType } = positionTypesCreate
      if (!positionType || !fp.isEmpty(errors))
        return { success: false as const, positionTypeNodeAttributes }

      return { success: true as const, positionTypeNode: positionType }
    } catch (error) {
      return { success: false as const, positionTypeNodeAttributes }
    }
  }

  const { t } = useTranslation("v2")
  const translations: JobCodeAndTitleFieldsetTranslations & { title: string } = {
    cancel: t("defaults.cancel"),
    codeLabel: t("position_types.add.code_label"),
    nameLabel: t("position_types.add.name_label"),
    save: t("defaults.add"),
    saving: t("defaults.adding"),
    title: t("position_types.add.title"),
  }

  return { errors, save, state, translations }
}

export { useAddPositionTypeForm }
