import cn from "classnames"
import React from "react"

import { DiffEntryRenderer, SingleEntryProps } from "./internal/DiffEntryRenderer"
import { usePositionFieldValuesDiffContext } from "./PositionFieldValuesDiffProvider"
import { ChangedFieldIcon, ListItemDiffEntry } from "./Presenters"
import { WatchFieldName } from "./types"

type ListDiffEntriesProps = {
  className?: string
  DiffEntryComponent?: (props: SingleEntryProps) => React.ReactNode
  ignore?: WatchFieldName[]
}

type SingleDiffEntryProps = {
  fieldName?: WatchFieldName
  render?: (props: SingleEntryProps) => React.ReactNode
}

type RenderWhenDiffEntriesNonEmptyProps = React.PropsWithChildren<{
  ignore?: WatchFieldName[]
}>

const RenderWhenDiffEntriesNonEmpty = ({
  children,
  ignore,
}: RenderWhenDiffEntriesNonEmptyProps) => {
  const diffContext = usePositionFieldValuesDiffContext()

  if (!diffContext?.diffEntries?.length) return null

  const filtered = diffContext.diffEntries.filter(({ fieldName }) => !ignore?.includes(fieldName))
  return filtered.length ? children : null
}

const ListDiffEntries = ({ DiffEntryComponent, className, ignore }: ListDiffEntriesProps) => {
  const { diffEntries, positionFormCollections } = usePositionFieldValuesDiffContext()

  const filteredDiffEntries = diffEntries.filter(({ fieldName }) => !ignore?.includes(fieldName))

  if (!positionFormCollections) {
    throw new Error("Invariant broken...think we have a lib for this")
  }

  return (
    <ul className={cn("!mb-0 list-none text-neutral-64 [&>li]:!m-0", className)}>
      {filteredDiffEntries.map((entry) => (
        <DiffEntryRenderer
          positionFormCollections={positionFormCollections}
          entry={entry}
          key={entry.fieldName}
          render={DiffEntryComponent ?? ListItemDiffEntry}
        />
      ))}
    </ul>
  )
}

const SingleDiffEntry = ({ fieldName, render }: SingleDiffEntryProps) => {
  const context = usePositionFieldValuesDiffContext()
  if (!context || !fieldName) return null

  const { diffEntries, positionFormCollections } = context
  const entry = diffEntries?.find(({ fieldName: entryFieldName }) => entryFieldName === fieldName)

  if (!entry || !positionFormCollections) return null

  return (
    <DiffEntryRenderer
      entry={entry}
      positionFormCollections={positionFormCollections}
      render={render ?? ChangedFieldIcon}
    />
  )
}

export { ListDiffEntries, SingleDiffEntry, RenderWhenDiffEntriesNonEmpty }
