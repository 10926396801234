import { useState } from "react"

interface Props {
  renderedPositionIds?: number[]
  exportAsCSV?: (ids: number[], columns: string[]) => void
  onClose: () => void
}

function useGridExportModal({ renderedPositionIds, exportAsCSV, onClose }: Props) {
  const [selectedExportColumns, setSelectedExportColumns] = useState<string[]>([])

  const [isSaving, setIsSaving] = useState(false)

  const handleSave = async () => {
    setIsSaving(true)
    if (exportAsCSV && renderedPositionIds) {
      exportAsCSV(renderedPositionIds, selectedExportColumns)
    }
    setIsSaving(false)
    onClose()
  }

  return {
    setSelectedExportColumns,
    isSaving,
    handleSave,
  }
}

export { useGridExportModal }
