import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import { FeatureFlags } from "types/graphql"
import RootProvider from "v2/react/components/RootProvider"
import { prepareIconClass } from "v2/react/utils/misc"
import { UrlHelper } from "v2/react/utils/urls"
import { useGetFeatureFlagsQuery } from "v2/redux/GraphqlApi"

function IndexInner({ canViewOrgBudget }: IndexProps) {
  const { t } = useTranslation()
  const { data, isFetching } = useGetFeatureFlagsQuery()

  if (!data || !data?.currentCompany?.featureFlags || isFetching) return null
  const ff: FeatureFlags = data.currentCompany?.featureFlags

  return (
    <div className="page-pad grid-cols-1 gap-8 grid md:grid-cols-2">
      <ModuleCard
        buttonText={t("v2.job_architecture.manage_job_definitions")}
        buttonUrl={UrlHelper.positionTypeRootPath()}
        heading={t("v2.job_architecture.job_definitions")}
        icon="far clipboard-check"
        iconBgClass="bg-status-info-light"
        iconTextClass="text-status-info"
        showBanner={false}
        subheading={t("v2.job_architecture.job_definitions_subheading")}
      />
      <ModuleCard
        buttonText={t("v2.job_architecture.manage_job_families")}
        buttonUrl={UrlHelper.jobFamilyRootPath()}
        heading={t("v2.job_architecture.job_families")}
        icon="fac folder-tree"
        iconBgClass="bg-status-caution-light"
        iconTextClass="text-status-caution"
        subheading={t("v2.job_architecture.job_families_subheading")}
      />
      {(ff.positionManagement || ff.successionPlanning) && (
        <ModuleCard
          buttonText={t("v2.job_architecture.manage_career_ladders")}
          buttonUrl={UrlHelper.careerLaddersRootPath()}
          heading={t("v2.job_architecture.career_ladders")}
          icon="fac ladder"
          iconBgClass="bg-status-excite-light"
          iconTextClass="text-status-excite"
          subheading={t("v2.job_architecture.career_ladders_subheading")}
        />
      )}
      {ff.positionManagement && (
        <ModuleCard
          buttonText={t("v2.job_architecture.manage_job_levels")}
          buttonUrl={UrlHelper.jobLevelsRootPath()}
          heading={t("v2.job_architecture.job_levels")}
          icon="fac list-ol"
          iconBgClass="bg-primary-8"
          iconTextClass="text-primary-100"
          subheading={t("v2.job_architecture.job_levels_subheading")}
        />
      )}
      {ff.positionManagement && canViewOrgBudget && (
        <ModuleCard
          buttonText={t("v2.job_architecture.manage_pay_grades")}
          buttonUrl={UrlHelper.payGradesRootPath()}
          heading={t("v2.job_architecture.pay_grades")}
          icon="far dollar-sign"
          iconBgClass="bg-status-success-light"
          iconTextClass="text-status-success"
          subheading={t("v2.job_architecture.pay_grades_subheading")}
        />
      )}
    </div>
  )
}

interface IndexProps {
  canViewOrgBudget: boolean
}

const Index = ({ canViewOrgBudget }: IndexProps) => (
  <RootProvider>
    <IndexInner canViewOrgBudget={canViewOrgBudget} />
  </RootProvider>
)

export { Index }

interface ModuleCardProps {
  buttonText?: string
  buttonUrl?: string
  heading: string
  icon: string
  iconBgClass: string
  iconTextClass: string
  showBanner?: boolean
  subheading: string
}

const ModuleCard = ({
  buttonText,
  buttonUrl,
  heading,
  icon,
  iconBgClass,
  iconTextClass,
  showBanner,
  subheading,
}: ModuleCardProps) => {
  const { t } = useTranslation()

  return (
    <div className="relative overflow-hidden">
      <div className="module-card mb-0 h-full flex-col gap-3 p-6 flex">
        <div className={cn("h-10 w-10 items-center justify-center rounded-full flex", iconBgClass)}>
          <FontAwesomeIcon className={iconTextClass} icon={prepareIconClass(icon)} />
        </div>
        <h2 className="font-large">{heading}</h2>
        <p className="text-neutral-64">{subheading}</p>
        {buttonUrl && buttonText && (
          <a className="btn btn--secondary" type="button" href={buttonUrl}>
            <span>{buttonText}</span>
            <FontAwesomeIcon icon={["far", "chevron-right"]} />
          </a>
        )}
      </div>
      {showBanner && (
        <div className="absolute right-0 top-0 w-44 whitespace-nowrap">
          <div className="translate-x-[20%] translate-y-[50%] rotate-45 transform bg-status-excite-light px-16 py-[5px] text-center text-base-bold text-status-excite">
            {t("v2.job_architecture.coming_soon")}
          </div>
        </div>
      )}
    </div>
  )
}
