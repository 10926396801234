import React from "react"

import { Person } from "types/graphql.d"

import { ANIMATION_DURATION, PersonListItem } from "./PersonListItem"

interface PersonListProps {
  isSaving: boolean
  onRemove: (selectedPerson: Person) => void
  selectedPeople: Person[]
}

function PersonList({ isSaving, onRemove, selectedPeople }: PersonListProps) {
  const handleRemoveValueClick = (e: React.MouseEvent<HTMLElement>) => {
    const removeValue: string = e.currentTarget.getAttribute("data-value") || ""
    const removedItem: Person | undefined = selectedPeople.find(
      (m: Person) => m.id.toString() === removeValue,
    )

    setTimeout(() => {
      // This is where the actual delete is happening, post-animation
      if (removedItem) {
        onRemove(removedItem)
      }
    }, ANIMATION_DURATION)
  }

  return (
    <div className="flex-col gap-2 flex">
      {selectedPeople.map((person) => (
        <PersonListItem
          isSaving={isSaving}
          key={`${person.id}`}
          person={person}
          onItemRemoved={handleRemoveValueClick}
        />
      ))}
    </div>
  )
}

export { PersonList }
