import classNames from "classnames"
import dayjs from "dayjs"
import React, { MutableRefObject, useEffect, useRef } from "react"

import { useWithReactHookFormRegister } from "v2/react/shared/forms/hooks/useWithReactHookFormHooks"
import { InputWrapper } from "v2/react/shared/forms/InputWrapper"
import { UseInReactHookFormProp } from "v2/react/shared/forms/types"

interface DateInputProps {
  defaultValue?: string
  /**
   * boolean value passed in to prevent today being set
   * as the default value when one is not provided
   */
  emptyDefaultValue?: boolean
  errors?: string
  focusOnMount?: boolean
  id?: string
  inputClassName?: string
  inputRef?: MutableRefObject<HTMLInputElement | null>
  label?: string
  name: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  useInReactHookForm?: UseInReactHookFormProp
  wrapperClassName?: string
}

const dateFormat = "YYYY-MM-DD"

const DateInput = ({
  defaultValue = "",
  emptyDefaultValue,
  errors,
  focusOnMount = false,
  id,
  inputClassName,
  inputRef,
  name,
  label,
  onChange,
  useInReactHookForm,
  wrapperClassName,
}: DateInputProps) => {
  const dateRef = useRef<HTMLInputElement>(inputRef?.current || null)

  useEffect(() => {
    if (focusOnMount && dateRef?.current) {
      dateRef.current.focus()
    }
  }, [focusOnMount, inputRef])

  return (
    <InputWrapper
      className={wrapperClassName}
      errors={errors}
      id={id}
      label={label}
      name={name}
      useInReactHookForm={useInReactHookForm}
    >
      <input
        id={id}
        type="date"
        className={classNames("input", inputClassName)}
        name={name}
        defaultValue={
          emptyDefaultValue ? defaultValue : defaultValue || dayjs(Date.now()).format(dateFormat)
        }
        onChange={onChange}
        ref={dateRef}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...useWithReactHookFormRegister({ useInReactHookForm, name })?.(name)}
      />
    </InputWrapper>
  )
}

export { DateInput }
