import type { Meta, StoryObj } from "@storybook/react"

import { Modal } from "./Modal"

const meta: Meta<typeof Modal> = {
  title: "Shared/Modal",
  component: Modal,
  parameters: {
    layout: "fullscreen",
  },
}

export default meta

type Story = StoryObj<typeof Modal>

export const Main: Story = {
  args: {
    children:
      "Content here. The styling of the body and footer are delegated to each child component",
    isOpen: true,
    // onChange: (values) => {},
    title: "Example Title",
  },
}
