import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { omit } from "lodash"
import React from "react"

import { HeadcountPlanChangeProjectionAction } from "types/graphql.d"
import { useAddChangeToHeadcountPlanMutation } from "v2/redux/GraphqlApi/HeadcountPlanningApi"

import { PositionMenuButton } from "../ShowParticipant/PositionMenuButton"
import { HeadcountPlanDatasheetRow } from "./types"

export function RestorePositionButton({
  row,
  headcountPlanId,
  participantId,
  onSuccess,
}: {
  row: HeadcountPlanDatasheetRow
  headcountPlanId: string
  participantId?: string
  onSuccess: () => void
}) {
  const [mutate] = useAddChangeToHeadcountPlanMutation()

  const restorePosition = () => {
    mutate({
      input: {
        action: HeadcountPlanChangeProjectionAction.UpdateExisting,
        headcountPlanId,
        headcountPlanParticipantId: participantId,
        rootEventId: row.rootEventId,
        payload: omit(row.payload, "end_date"),
        revisionNumber: row.revisionNumber + 1,
      },
    })
      .unwrap()
      .then((payload) => {
        if (payload.addChangeToHeadcountPlan?.headcountPlanChangeCreated) {
          onSuccess()
        }
      })
  }

  return (
    <PositionMenuButton
      icon={<FontAwesomeIcon icon={["far", "undo"]} />}
      text={"restore_position".t("headcount_plan")}
      onClick={() => restorePosition()}
    />
  )
}
