import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { AnimatePresence, motion } from "framer-motion"
import React from "react"

import { opaqueScale } from "v2/react/utils/framerAnimationVariants"

interface Props {
  errors: string[]
}

function ErrorBanner({ errors }: Props) {
  return (
    <AnimatePresence>
      {errors &&
        errors.map((error: string) => (
          <motion.div
            initial="hidden"
            animate="shown"
            exit="hidden"
            variants={opaqueScale}
            className="alert-critical"
            key={error}
          >
            <div className="items-center gap-1.5 flex">
              <FontAwesomeIcon icon={["far", "info-circle"]} />
              {error}
            </div>
          </motion.div>
        ))}
    </AnimatePresence>
  )
}

export { ErrorBanner }
