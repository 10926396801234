import React, { createContext, useContext, useMemo } from "react"

import { FieldValues } from "v2/react/components/positions/positionForm"
import { PositionFormCollections } from "v2/redux/GraphqlApi/PositionsApi"

import { useDiffOfFieldValues } from "./hooks/useDiffOfFieldValues"
import { WatchEntry, WatchFieldName } from "./types"

type PositionFieldValuesDiffProviderProps = React.PropsWithChildren<{
  basis?: Nullish<FieldValues>
  current?: Nullish<FieldValues>
  positionFormCollections: Nullish<PositionFormCollections>
  watchFieldNames: WatchFieldName[]
}>

type DiffContextState = {
  diffEntries: WatchEntry[]
  positionFormCollections: Nullish<PositionFormCollections>
}

const DiffContext = createContext<DiffContextState>({
  diffEntries: [],
  positionFormCollections: null,
})

function usePositionFieldValuesDiffContext() {
  return useContext(DiffContext)
}

function PositionFieldValuesDiffProvider({
  basis,
  children,
  current,
  positionFormCollections,
  watchFieldNames,
}: PositionFieldValuesDiffProviderProps) {
  const { diffEntries } = useDiffOfFieldValues({ basis, current, watchFieldNames })
  const contextState = useMemo(
    () => ({
      diffEntries,
      positionFormCollections,
    }),
    [diffEntries, positionFormCollections],
  )

  return <DiffContext.Provider value={contextState}>{children}</DiffContext.Provider>
}

export {
  DiffContext,
  DiffContextState,
  PositionFieldValuesDiffProvider,
  type PositionFieldValuesDiffProviderProps,
  usePositionFieldValuesDiffContext,
}
