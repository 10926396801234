import React, { AriaRole } from "react"

interface PulserProps {
  /** @default true */
  ariaBusy?: boolean
  /** @default `"Loading.t("defaults")` */
  ariaRoleDescription?: string
  /** @default "polite" */
  ariaLive?: "off" | "polite" | "assertive"
  /** @default "alert" */
  role?: AriaRole
  testId?: string
}

/** @see https://stackoverflow.com/a/38708396 for suggestions on classifying a spinner w/ Aria */
export const Pulser = ({
  ariaBusy = true,
  ariaLive = "polite",
  ariaRoleDescription = "Loading".t("defaults"),
  role = "alert",
  testId,
}: PulserProps) => (
  <span
    className="pulser"
    data-testid={testId}
    role={role}
    aria-roledescription={ariaRoleDescription}
    aria-busy={ariaBusy}
    aria-live={ariaLive}
  >
    <span />
    <span />
    <span />
  </span>
)
