import type { Cell, Row, Table } from "@tanstack/react-table"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { downloadCsv, sanitizeCsvValue } from "v2/react/utils/csv"

export function useCsvDownloadListener(
  csvDownloadRef: React.RefObject<HTMLButtonElement> | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  table: Table<any>,
  csvDownloadName?: string,
) {
  const { t } = useTranslation()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const determineCsvFormatForCell = (row: Row<any>, cell: Cell<any, unknown>) => {
      const value = cell.getValue() as string | undefined

      if (
        row.original.excluded &&
        cell.id.includes("_type") &&
        value === t("v2.headcount_plan.position_type.new")
      ) {
        return sanitizeCsvValue(`${value} - ${t("v2.headcount_plan.csv.excluded")}`)
      }
      return value ? sanitizeCsvValue(value) : ""
    }

    const handleClick = () => {
      downloadCsv(
        [
          table
            .getFlatHeaders()
            .filter((header) => header.column.columnDef.header !== "")
            .map((header) => sanitizeCsvValue(header.column.columnDef.header as string))
            .join(","),
          ...table
            // We use the 'PrePagination' row model here to ensure that our CSV
            // download is as close to the current table view as possible,
            // excluding pagination.
            // See: https://tanstack.com/table/latest/docs/guide/row-models#the-order-of-row-model-execution
            .getPrePaginationRowModel()
            .rows.filter((row) => !row.getIsGrouped())
            .map((row) =>
              row
                .getVisibleCells()
                .filter((cell) => cell.column.columnDef.header !== "")
                .map((cell) => determineCsvFormatForCell(row, cell))
                .join(","),
            ),
        ].join("\n"),
        csvDownloadName,
      )
    }

    const button = csvDownloadRef?.current
    button?.addEventListener("click", handleClick)
    return () => button?.removeEventListener("click", handleClick)
  }, [csvDownloadRef, table, csvDownloadName, t])
}
