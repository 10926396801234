import { AnimatePresence, motion } from "framer-motion"
import fp from "lodash/fp"
import React, { useRef, useState } from "react"
import { createRoot, Root } from "react-dom/client"
import { useIsMounted, useOnClickOutside } from "usehooks-ts"

import type { OrgUnit, OrgUnitType } from "types/graphql"
import { errorHash } from "v2/react/utils/errors"
import { StoreProvider } from "v2/redux/StoreProvider"

import { AddOrgUnitFieldset } from "./AddOrgUnitFieldset"
import { useAddOrgUnitMutationWithHandling } from "./hooks/useAddOrgUnitMutationWithHandling"

type InlineAddOrgUnitFormProps = {
  initialData?: { code?: string; name?: string }
  onCancel: (event?: Event) => void
  onSave: (orgUnit: Pick<OrgUnit, "id" | "org_unit_type_id" | "name" | "code">) => void
  orgUnitType: OrgUnitType
}

type MountArg = {
  inElement: HTMLElement
  props: InlineAddOrgUnitFormProps
}

function InlineAddOrgUnitForm({
  initialData,
  onCancel,
  onSave,
  orgUnitType,
}: InlineAddOrgUnitFormProps) {
  const { generalError, handleSave, state } = useAddOrgUnitMutationWithHandling(onSave)
  const errors = errorHash(state.data?.addOrgUnit?.errors)
  const fieldsetRef = useRef<HTMLFieldSetElement>(null)
  useOnClickOutside(fieldsetRef, (event) => {
    // In the off chance we're in the middle of saving, it's still ok to invoke
    // onCancel. While it won't prevent the network request from creating a new
    // org unit, it will prevent associating it with the backing position.
    onCancel(event)
  })

  return (
    <AddOrgUnitFieldset
      className="mb-0"
      errors={errors}
      fieldsetRef={fieldsetRef}
      generalError={generalError}
      initialData={{ name: "", code: "", ...initialData }}
      layoutSpacing="condensed"
      onCancel={onCancel}
      onSave={handleSave}
      orgUnitTypeId={orgUnitType.id}
      saving={(state.isSuccess && fp.isEmpty(errors)) || state.isLoading}
      translations={{
        codeLabel: "Code Label".t("org_units", null, null, null, [orgUnitType.name]),
        nameLabel: "Name Label".t("org_units", null, null, null, [orgUnitType.name]),
        save: "Add".t("defaults"),
        saving: "Adding".t("defaults"),
        cancel: "Cancel".t("defaults"),
      }}
    />
  )
}

const AnimatedInlineAddOrgUnitForm = ({
  initialData,
  onCancel,
  onSave,
  orgUnitType,
}: InlineAddOrgUnitFormProps) => {
  const [isActive, flagIsActive] = useState(true)
  const isMounted = useIsMounted()
  const triggerSave: InlineAddOrgUnitFormProps["onSave"] = (orgUnit) => {
    flagIsActive(false)
    setTimeout(() => isMounted() && onSave(orgUnit), 225)
  }
  const triggerCancel = (event?: Event) => {
    flagIsActive(false)
    setTimeout(() => isMounted() && onCancel(event), 0)
  }

  return (
    <AnimatePresence>
      {isActive ? (
        <motion.div
          key="content"
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { opacity: 1, scale: 1 },
            collapsed: { opacity: 0, scale: 0.95 },
          }}
          transition={{ duration: 0.25, ease: "easeInOut" }}
        >
          <InlineAddOrgUnitForm
            initialData={initialData}
            onCancel={triggerCancel}
            onSave={triggerSave}
            orgUnitType={orgUnitType}
          />
        </motion.div>
      ) : null}
    </AnimatePresence>
  )
}

// The root element for the inline form is meant to be used within modals
// not controlled by React. To do this, we need to contend with other JS.
// `Modal.startSave` and the `ButtonHelpers` mixin can cause some big issues,
// not with the inline form itself, but with the outer form *if* the inline
// form is still mounted (even if hidden).
//
// To try and avoid this, the root element is unmounted as soon as possible.
// However, we cannot call `createRoot` on the same element more than once, nor
// can we call `root.render` again, either. So to support using the inline form
// more than once in the same modal, this dynamically adds/removes an HTML
// element that is used as the root.
let mountedRoot: Root | undefined
const createAndAppendRootElement = (inElement: HTMLElement) => {
  const rootElement = Object.assign(document.createElement("div"), { id: "react-org-unit-form" })
  inElement.append(rootElement)
  return rootElement
}
const removeMounted = (inElement: HTMLElement) => {
  inElement.querySelector("#react-org-unit-form")?.remove()
  mountedRoot?.unmount()
}
const mountRoot = (inElement: HTMLElement) => {
  removeMounted(inElement)
  const rootElement = createAndAppendRootElement(inElement)
  mountedRoot = createRoot(rootElement)
  return mountedRoot
}

InlineAddOrgUnitForm.mount = ({
  inElement,
  props: { initialData, onCancel, onSave, orgUnitType },
}: MountArg) => {
  const handleCancel = (event?: Event) => {
    onCancel(event)
    removeMounted(inElement)
  }
  const handleSave: InlineAddOrgUnitFormProps["onSave"] = (arg) => {
    onSave(arg)
    removeMounted(inElement)
  }

  mountRoot(inElement).render(
    <StoreProvider>
      <AnimatedInlineAddOrgUnitForm
        initialData={initialData}
        onCancel={handleCancel}
        onSave={handleSave}
        orgUnitType={orgUnitType}
      />
    </StoreProvider>,
  )
}

export { InlineAddOrgUnitForm, InlineAddOrgUnitFormProps }
