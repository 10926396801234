import { ErrorObject } from "ajv"
import i18n from "i18next"

import { FormShape } from "v2/react/components/jobRequisitions/RequisitionForm/types/FormShape"

type FormVariablePayType = {
  amount: number | null
  id: string
  name: string
  type: string
}

// This validation utilizes the `additionalErrors` data structure to validate
// the `amount` variable pay type field. The amount can be a percent or a
// currency amount. A percent is in the range 0-100, a currency amount is
// larger. The json validation rules do not cover us in this case, as with
// handling certain backend errors, which also utilize `additionalErrors`.
// Future work may contemplate handling errors more generically and gracefully,
// with the help of more complex schema validations and/or middleware.
const variablePayErrors = (formData: FormShape): ErrorObject[] => {
  if (formData.schemaType !== "generic") return []

  const variablePays = formData.position?.variablePayTypes || []
  if (variablePays.length < 1) return []

  const errors: (ErrorObject | null)[] = variablePays.reduce(
    (errorsAcc: ErrorObject[], vp: FormVariablePayType, index: number) => {
      if (vp.amount === null) return errorsAcc

      let errorMessage = ""
      if (vp.type === "variable_pay_pay_type_amount" && (vp.amount >= 999999.99 || vp.amount < 0)) {
        errorMessage = i18n.t("v2.errors.messages.amount_between_exclusive", {
          min: "0",
          max: "$999,999.99",
        })
      }
      if (vp.type === "variable_pay_pay_type_percent" && (vp.amount > 100 || vp.amount < 0)) {
        errorMessage = i18n.t("v2.errors.messages.numeric_between_exclusive", {
          min: "0",
          max: "100",
        })
      }

      if (errorMessage) {
        return errorsAcc.concat({
          instancePath: `/position/variablePayTypes/${index}/amount`,
          message: errorMessage,
          schemaPath: `#/properties/position/properties/variablePayTypes/items/properties/amount/errorMessage`,
          keyword: "",
          params: {},
        })
      }
      return errorsAcc
    },
    [],
  )
  return errors.filter((n) => n !== null) as ErrorObject[]
}

export { variablePayErrors }
