import classNames from "classnames"
import fp from "lodash/fp"
import React, { useId, useRef } from "react"

import { TextInput } from "v2/react/shared/forms/TextInput"
import { AlertBanner } from "v2/react/shared/status/AlertBanner"

import { JobCodeAndTitleFieldsetFooterProps, JobCodeAndTitleFieldsetProps } from "./types"

function JobCodeAndTitleFieldset({
  className,
  isSaving,
  errors,
  fieldsContainerClassName,
  initialData,
  layoutSpacing = "normal",
  onCancel,
  onSave,
  translations,
}: JobCodeAndTitleFieldsetProps) {
  const id = useId()
  const nameRef = useRef<HTMLInputElement>(null)
  const codeRef = useRef<HTMLInputElement>(null)
  const handleSubmitOnEnterKeyDown: React.KeyboardEventHandler = (ev) => {
    if (ev.key !== "Enter") return

    ev.preventDefault()
    handleSave()
  }
  const handleSave = () => {
    const jobCode = codeRef.current?.value
    const title = nameRef.current?.value
    const jobCodeTitleLabel = fp.compact([jobCode, title]).join(" - ")

    onSave({ jobCode, jobCodeTitleLabel, title })
  }

  const spacingClass = layoutSpacing === "condensed" ? "gap-2" : "gap-4"
  const baseClasses = classNames("flex flex-col", spacingClass)

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <fieldset
        className={classNames(baseClasses, className)}
        data-testid="code-name-fieldset"
        onKeyDown={handleSubmitOnEnterKeyDown}
      >
        {errors?.rootErrors?.length ? (
          <AlertBanner messages={errors.rootErrors.map((text) => ({ text }))} type="critical" />
        ) : null}
        <div className={classNames(baseClasses, fieldsContainerClassName)}>
          <TextInput
            autoFocus
            defaultValue={initialData.title}
            errors={errors?.getFieldError?.("title")}
            id={`${id}-title`}
            inputRef={nameRef}
            label={translations.nameLabel}
            showErrorMessage={!!errors?.getFieldError?.("title")}
          />
          <TextInput
            defaultValue={initialData.jobCode}
            errors={errors?.getFieldError?.("job_code")}
            id={`${id}-job-code`}
            inputRef={codeRef}
            label={translations.codeLabel}
            showErrorMessage={!!errors?.getFieldError?.("job_code")}
          />
        </div>
      </fieldset>

      <hr className="mx-[-1rem] mb-3 mt-4" />

      <JobCodeAndTitleFieldsetFooter
        onCancel={onCancel}
        onSavePress={handleSave}
        isSaving={isSaving}
        translations={translations}
      />
    </>
  )
}

const JobCodeAndTitleFieldsetFooter = ({
  isSaving,
  onCancel,
  onSavePress,
  translations,
}: JobCodeAndTitleFieldsetFooterProps) => (
  <div className="justify-end gap-2 flex">
    <button
      id="cancel-add-position-type-action"
      className={classNames("btn btn--secondary", { disabled: isSaving })}
      disabled={isSaving}
      onClick={(ev) => onCancel?.(ev.nativeEvent)}
      type="button"
    >
      {translations.cancel}
    </button>
    <button
      className={classNames("btn btn--primary", { disabled: isSaving })}
      disabled={isSaving}
      id="add-position-type-action"
      onClick={onSavePress}
      type="button"
    >
      {isSaving ? translations.saving : translations.save}
    </button>
  </div>
)

export { JobCodeAndTitleFieldset }
