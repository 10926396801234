import { createEntityAdapter, createSelector } from "@reduxjs/toolkit"
import fp from "lodash/fp"

import { RowType, SkeletonNodeRow, SkeletonRow } from "v2/redux/slices/GridSlice/types"
import { RootState } from "v2/redux/store"

// (Re-)creates the skeleton adapter to avoid a circular dependency while still
// getting access to the handy skeleton selectors.
const tmpSkeletonAdapter = createEntityAdapter<SkeletonRow>()
const skeletonSelectors = tmpSkeletonAdapter.getSelectors((state: RootState) => state.grid.skeleton)

type SkeletonIndex = ReturnType<typeof skeletonSelectors.selectEntities>

/**
 * Builds a flat array of group and node rows with fully hydrated data based on
 * the current grid skeleton.
 */
const selectDatasheetRows = createSelector(
  skeletonSelectors.selectAll,
  skeletonSelectors.selectEntities,
  (rows, skeletonIndex): SkeletonRow[] => {
    const rowsWithRejectedEntriesAsNull = fp.map((row) => {
      // Return nothing if the group row is hidden, otherwise return the
      // skeleton entry as it represents a NormalizedGroupRow already.
      if (row.rowType === RowType.Group && row.isHidden) return null
      if (row.rowType === RowType.Group && row.childrenCount === 0) return null
      if (row.rowType === RowType.Group) return row

      // Return nothing if the node is hidden.
      if (isNodeRowHidden(row, skeletonIndex)) return null

      return row
    }, rows)

    return fp.compact(rowsWithRejectedEntriesAsNull)
  },
)

function isNodeRowHidden(row: SkeletonNodeRow, skeletonIndex: SkeletonIndex) {
  const maybeGroupRow = row.groupId ? skeletonIndex[row.groupId] : null
  if (!maybeGroupRow || maybeGroupRow.rowType !== RowType.Group) return false

  return !maybeGroupRow.isExpanded || maybeGroupRow.isHidden
}

export { selectDatasheetRows }
