import { CellContext, TableOptions } from "@tanstack/react-table"

import { AdpChangeBatch } from "types/graphql"

export type BatchOverviewProps = { batch: AdpChangeBatch; title: string }
export type BatchTableProps = { batches: AdpChangeBatch[]; title: string }
export type MakeTableConfigFn<T> = (data: T[]) => Omit<TableOptions<T>, "getCoreRowModel">
export type MakeTableConfigWithActionCellFn<T> = (
  data: T[],
  ActionComponent: (props: CellContext<T, unknown>) => React.ReactNode,
) => Omit<TableOptions<T>, "getCoreRowModel">
export enum Status {
  Success = "success",
  Caution = "caution",
  Info = "info",
  Excite = "excite",
}
