import React from "react"

import { Info } from "../../../../shared/status/Info"

interface TitleProps {
  title: string
  description?: string
}

export const Title = ({ title, description }: TitleProps) => (
  <div className="tooltip-title">
    <span>{title}</span>
    {description && <Info description={description} tooltipSize="sm" />}
  </div>
)
