import { FloatingContext, FloatingFocusManager, FloatingPortal } from "@floating-ui/react"
import React, { CSSProperties, Ref } from "react"

interface Props {
  showList: boolean
  floatingRef: Ref<HTMLUListElement>
  floatingStyles: CSSProperties
  floatingProps: (userProps?: React.HTMLProps<HTMLElement> | undefined) => Record<string, unknown>
  wrapperClasses: string
  context: FloatingContext<HTMLInputElement>
  children: React.ReactNode
}

export function DropdownMenu({
  showList,
  floatingRef,
  floatingStyles,
  floatingProps,
  wrapperClasses,
  context,
  children,
}: Props) {
  return (
    showList && (
      <FloatingPortal>
        <FloatingFocusManager context={context} initialFocus={-1} visuallyHiddenDismiss>
          <ul
            ref={floatingRef}
            style={{ ...floatingStyles, zIndex: 10, listStyleType: "none" }}
            className={wrapperClasses}
            /* eslint-disable react/jsx-props-no-spreading */
            {...floatingProps()}
          >
            {children}
          </ul>
        </FloatingFocusManager>
      </FloatingPortal>
    )
  )
}
