import React from "react"
import { useTranslation } from "react-i18next"

import { JobCodeAndTitleForm } from "v2/react/components/positionTypes/EmbeddedPositionTypeControl/JobCodeAndTitleForm"
import { Modal } from "v2/react/shared/overlay/Modal"
import { PositionTypesConnectionNode } from "v2/redux/GraphqlApi/PositionTypesApi"

type AddPositionTypeModalProps = {
  initialData: { jobCode?: string; title?: string }
  isOpen: boolean
  onClose: () => void
  onSave: (orgUnit: PositionTypesConnectionNode) => void
  validateOnly?: boolean
}

function AddPositionTypeModal({
  isOpen = true,
  initialData,
  onClose,
  onSave,
  validateOnly = false,
}: AddPositionTypeModalProps) {
  const { t } = useTranslation()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      shouldReturnFocusAfterClose={false}
      size="md"
      title={t("v2.position_types.new.modal_title")}
    >
      <form className="px-6 pb-3 pt-4">
        <JobCodeAndTitleForm
          initialData={initialData}
          onCancel={onClose}
          onSaved={onSave}
          createIsVirtual={validateOnly}
        />
      </form>
    </Modal>
  )
}

export { AddPositionTypeModal, AddPositionTypeModalProps }
