import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React from "react"

interface MenuToggleProps {
  onClick: () => void
  wrapperClasses?: string
}

const ChartSectionExpandToggle = ({ onClick, wrapperClasses }: MenuToggleProps) => (
  <div className={classNames("expand-collapse", wrapperClasses)}>
    <div className="color-mask">
      <button className="expand h-3 w-3 rounded-lg p-0" onClick={onClick} type="button">
        <FontAwesomeIcon icon="caret-right" className="icon-regular" />
      </button>
      <button className="collapse h-3 w-3 rounded-lg p-0" onClick={onClick} type="button">
        <FontAwesomeIcon icon="caret-down" className="icon-regular" />
      </button>
    </div>
  </div>
)

export { ChartSectionExpandToggle }
