import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useOnClickOutside } from "usehooks-ts"

import { EliminatePositionModal } from "../HeadcountPlanDatasheet/EliminatePositionModal"
import { RestorePositionButton } from "../HeadcountPlanDatasheet/RestorePositionButton"
import { RevertChangesButton } from "../HeadcountPlanDatasheet/RevertChangesButton"
import { HeadcountPlanDatasheetRow } from "../HeadcountPlanDatasheet/types"
import { ConfirmDeleteModal } from "./ConfirmDeleteModal"
import { DuplicatePositionButton } from "./DuplicatePositionButton"
import { PositionMenuButton } from "./PositionMenuButton"
import { TogglePositionExclusionButton } from "./TogglePositionExclusionButton"

interface Props {
  headcountPlanId: string
  headcountPlanStartDate: string | undefined
  participantId?: string
  participantPositionId?: string
  excluded: boolean | undefined | null
  rootEventId: string
  row: HeadcountPlanDatasheetRow
}

export function PositionMenu({
  headcountPlanId,
  headcountPlanStartDate,
  participantId,
  participantPositionId,
  excluded,
  rootEventId,
  row,
}: Props) {
  const [isOpen, setIsOpen] = React.useState(false)
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = React.useState(false)
  const [isEliminateModalOpen, setIsEliminateModalOpen] = React.useState(false)
  const ref = React.useRef<HTMLDivElement>(null)

  const isEliminated = row.type !== "new" && row.payload.end_date

  useOnClickOutside(ref, () => isOpen && setIsOpen(false))

  const handleDeletePositionConfirmClick = () => {
    setIsDeleteConfirmOpen(true)
    setIsOpen(false)
  }

  const handleEliminatePositionClick = () => {
    setIsEliminateModalOpen(true)
    setIsOpen(false)
  }

  const onDeletePositionClose = () => {
    setIsDeleteConfirmOpen(false)
  }

  const onEliminatePositionClose = () => {
    setIsEliminateModalOpen(false)
  }

  const onDuplicatePositionSuccess = React.useCallback(() => {
    setIsOpen(false)
  }, [])

  const onRestorePositionSuccess = React.useCallback(() => {
    setIsOpen(false)
  }, [])

  const onRevertChangesSuccess = React.useCallback(() => {
    setIsOpen(false)
  }, [])

  const onExcludePositionSuccess = React.useCallback(() => {
    setIsOpen(false)
  }, [])

  return (
    <div ref={ref} className="relative">
      {isDeleteConfirmOpen && (
        <ConfirmDeleteModal
          headcountPlanId={headcountPlanId}
          isOpen={isDeleteConfirmOpen}
          onClose={onDeletePositionClose}
          participantId={participantId}
          rootEventId={row.rootEventId}
          priorRevisionNumber={row.revisionNumber}
        />
      )}
      {isEliminateModalOpen && (
        <EliminatePositionModal
          row={row}
          headcountPlanId={headcountPlanId}
          isOpen={isEliminateModalOpen}
          onClose={onEliminatePositionClose}
          participantId={participantId}
        />
      )}
      <button
        id={`options-${row.id}`}
        type="button"
        className="btn btn--ghost mx-auto"
        onClick={() => setIsOpen((isOpen) => !isOpen)}
      >
        <FontAwesomeIcon icon={["fal", "ellipsis-h"]} size="lg" />
      </button>
      <div
        id={`position-menu-${row.id}`}
        className="hcp-position-menu"
        style={{
          display: isOpen ? "flex" : "none",
        }}
      >
        {row.type === "new" ? (
          <TogglePositionExclusionButton
            headcountPlanId={headcountPlanId}
            participantId={participantId}
            excluded={excluded}
            rootEventId={rootEventId}
            onSuccess={onExcludePositionSuccess}
          />
        ) : null}
        <DuplicatePositionButton
          headcountPlanId={headcountPlanId}
          participantId={participantId}
          participantPositionId={participantPositionId}
          payload={{ ...row.positionAttributesWithEdits, start_date: headcountPlanStartDate }}
          onSuccess={onDuplicatePositionSuccess}
        />
        {!isEliminated && row.type === "modified" ? (
          <RevertChangesButton
            headcountPlanId={headcountPlanId}
            participantId={participantId}
            rootEventId={rootEventId}
            onSuccess={onRevertChangesSuccess}
            revisionNumber={row.revisionNumber}
          />
        ) : null}
        {isEliminated ? (
          <RestorePositionButton
            row={row}
            headcountPlanId={headcountPlanId}
            participantId={participantId}
            onSuccess={onRestorePositionSuccess}
          />
        ) : null}
        {!isEliminated && row.type !== "new" ? (
          <PositionMenuButton
            icon={<FontAwesomeIcon icon={["fas", "times-circle"]} />}
            onClick={handleEliminatePositionClick}
            text={"eliminate_position".t("headcount_plan")}
          />
        ) : null}
        {row.deletable ? (
          <PositionMenuButton
            className={`text-status-critical delete-hcp-position-${rootEventId}`}
            icon={<FontAwesomeIcon icon={["far", "trash-alt"]} className="text-status-critical" />}
            onClick={handleDeletePositionConfirmClick}
            text={"delete_position".t("headcount_plan")}
          />
        ) : null}
      </div>
    </div>
  )
}
