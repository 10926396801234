import React from "react"

import { PositionTypesConnectionNode } from "v2/redux/GraphqlApi/PositionTypesApi"

interface PositionTypeAutocompleteResultProps {
  positionType: PositionTypesConnectionNode
  isSelected: boolean
  className: string
  refFn: (node: HTMLDivElement | null) => void
  getItemProps: (userProps?: React.HTMLProps<HTMLElement> | undefined) => Record<string, unknown>
  handleResultClick: (uniqueKey: string | undefined | null, positionLabel: string) => void
}

const PositionTypeAutocompleteResult: React.FC<PositionTypeAutocompleteResultProps> = ({
  positionType,
  isSelected,
  className,
  refFn,
  getItemProps,
  handleResultClick,
}) => {
  const positionTypeTitle = positionType.title || ""

  return (
    <div
      role="option"
      aria-selected={isSelected}
      key={positionType.uniqueKey}
      className={className}
      ref={refFn}
      /* eslint-disable react/jsx-props-no-spreading */
      {...getItemProps({
        onClick: () => handleResultClick(positionType.uniqueKey, positionTypeTitle),
      })}
    >
      <div className="justify-between flex">
        <div>{positionTypeTitle}</div>
        {positionType.jobCode && <div>{positionType.jobCode}</div>}
      </div>
    </div>
  )
}

export { PositionTypeAutocompleteResult }
