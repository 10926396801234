import {
  PeopleCreateInput,
  PeopleCreateMutation,
  ProfilePanelSettingsQuery,
  ProfilePanelSettingsQueryVariables,
  ToggleReportLinksInput,
  ToggleReportLinksMutation,
  UpdateProfilePanelBoxSettingsInput,
  UpdateProfilePanelBoxSettingsMutation,
  UpdateProfilePanelModeSettingInput,
  UpdateProfilePanelModeSettingMutation,
} from "types/graphql.d"
import OperationStore from "v2/operation_store"
import { GraphqlApi } from "v2/redux/GraphqlApi"
import { flatMutationOperation, queryOperation } from "v2/redux/utils/endpoints"

export type PersonFromCreate = NonNullable<
  NonNullable<PeopleCreateMutation["peopleCreate"]>["person"]
>

export const PeopleApi = GraphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    getProfilePlanSettings: builder.query<
      ProfilePanelSettingsQuery,
      ProfilePanelSettingsQueryVariables
    >({
      query: queryOperation<ProfilePanelSettingsQueryVariables>("ProfilePanelSettings"),
      providesTags: ["ProfilePanelSettings"],
    }),
    peopleCreate: builder.mutation<PeopleCreateMutation, PeopleCreateInput>({
      query: flatMutationOperation<PeopleCreateInput>("PeopleCreate"),
    }),
    UpdateProfilePlanBoxSettings: builder.mutation<
      UpdateProfilePanelBoxSettingsMutation,
      UpdateProfilePanelBoxSettingsInput
    >({
      query: (input) => ({
        operationId: OperationStore.getOperationId("UpdateProfilePanelBoxSettings"),
        variables: { input },
      }),
      invalidatesTags: ["ProfilePanelSettings"],
    }),
    UpdateProfilePlanModeSetting: builder.mutation<
      UpdateProfilePanelModeSettingMutation,
      UpdateProfilePanelModeSettingInput
    >({
      query: (input) => ({
        operationId: OperationStore.getOperationId("UpdateProfilePanelModeSetting"),
        variables: { input },
      }),
      invalidatesTags: ["ProfilePanelSettings"],
    }),
    toggleReportLinks: builder.mutation<ToggleReportLinksMutation, ToggleReportLinksInput>({
      query: flatMutationOperation<ToggleReportLinksInput>("ToggleReportLinks"),
    }),
  }),
})

export const {
  useGetProfilePlanSettingsQuery,
  usePeopleCreateMutation,
  useUpdateProfilePlanBoxSettingsMutation,
  useUpdateProfilePlanModeSettingMutation,
  useToggleReportLinksMutation,
} = PeopleApi
