import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import { constant, times } from "lodash"
import React, { ReactNode, useState } from "react"

interface PaginationCirclesProps {
  itemCount: number
  handlePagination: (increment: number) => void
  verticalDisplay?: boolean
}

function PaginationCircles({
  itemCount,
  handlePagination,
  verticalDisplay,
}: PaginationCirclesProps) {
  const circleCount = itemCount > 3 ? 3 : itemCount
  const circles = times(circleCount, constant(null))
  const [circleActiveIndex, setCircleActiveIndex] = useState(0)
  const [itemActiveIndex, setItemActiveIndex] = useState(0)

  const handleButtonClick = (increment: number) => {
    const newCircleIndex =
      (circleActiveIndex + increment < 0 ? circleCount - 1 : circleActiveIndex + increment) %
      circleCount
    const newItemIndex =
      (itemActiveIndex + increment < 0 ? itemCount - 1 : itemActiveIndex + increment) % itemCount

    setCircleActiveIndex(newCircleIndex)
    setItemActiveIndex(newItemIndex)
    handlePagination(newItemIndex)
  }

  const handleCircleClick = (index: number) => {
    const newIncrement = index < circleActiveIndex ? index - circleActiveIndex : index
    const newItemIndex =
      (itemActiveIndex + newIncrement < 0 ? itemCount - 1 : itemActiveIndex + newIncrement) %
      itemCount

    setCircleActiveIndex(index)
    setItemActiveIndex(newItemIndex)
    handlePagination(newItemIndex)
  }

  return (
    <div
      className={cn("group items-center justify-center text-neutral-64 flex", {
        "flex-col": verticalDisplay,
      })}
    >
      {itemCount > 3 && (
        <ButtonWrapper handleClick={() => handleButtonClick(-1)}>
          <FontAwesomeIcon
            icon={["fas", verticalDisplay ? "caret-up" : "caret-left"]}
            className="h-inherit opacity-0 transition-all duration-150 ease-out group-hover:opacity-100"
          />
        </ButtonWrapper>
      )}
      {circles.map((_, index) => (
        /* eslint-disable-next-line react/no-array-index-key */
        <ButtonWrapper key={index} handleClick={() => handleCircleClick(index)}>
          <div
            className={cn(
              "h-1.5 w-1.5 rounded-full border border-solid border-neutral-64 hover:bg-neutral-64",
              { "bg-neutral-64": circleActiveIndex === index },
            )}
          />
        </ButtonWrapper>
      ))}
      {itemCount > 3 && (
        <ButtonWrapper handleClick={() => handleButtonClick(1)}>
          <FontAwesomeIcon
            icon={["fas", verticalDisplay ? "caret-down" : "caret-right"]}
            className="h-inherit opacity-0 transition-all duration-150 ease-out group-hover:opacity-100"
          />
        </ButtonWrapper>
      )}
    </div>
  )
}

interface ButtonWrapperProps {
  children: ReactNode
  handleClick: () => void
}

const ButtonWrapper = ({ children, handleClick }: ButtonWrapperProps) => (
  <button
    className="h-2.5 w-2.5 place-content-center border-none bg-transparent grid"
    onClick={handleClick}
    type="button"
  >
    {children}
  </button>
)

export { PaginationCircles }
