import fp from "lodash/fp"
import { stringKeyToNumericId } from "org_chart/chart/utils/relationalNodeDataStore/dynamicFields"
import { createSelector } from "reselect"

import { NodeInterface } from "types/graphql.d"
import { makeComparator } from "v2/redux/slices/GridSlice/gridHelpers/makeComparator"
import { selectNodeFilterFunc } from "v2/redux/slices/GridSlice/gridSelectors"
import { Field } from "v2/redux/slices/GridSlice/types"
import { nodeSelectors } from "v2/redux/slices/NodeSlice/NodeApi"
import { nodeProp } from "v2/redux/slices/NodeSlice/nodeHelpers/nodeProps"

const EmptyFilterArray: { value: string | number; label: string }[] = []

/**
 * @public
 * @returns {function} a function accepting a field and isActive flag. It
 *   always returns an empty array (w/ a constant reference) when isActive is
 *   false -- helping to prevent unnecessary renders. Only returns values that
 *   are available based on any active filters.
 */
const selectFilterValuesFunc = createSelector(
  nodeSelectors.selectAll,
  selectNodeFilterFunc,
  (nodes) =>
    (field: Field, isActive = true) =>
      isActive
        ? fp.pipe(
            fp.flatMap((node: NodeInterface) => nodeProp(field.fieldKey, node)),
            fp.uniq,
            fp.compact,
            (rows) =>
              rows.sort(makeComparator({ fieldKey: field.fieldKey, toActual: fp.identity })),
            fp.map((value) => ({ value, label: value.toString() })),
          )(nodes)
        : EmptyFilterArray,
)

/** @private */
const getModelId = (node: NodeInterface) =>
  node.klass === "ChartPositionNode" ? node.position_id : node.person_id

/**
 * @public
 * @returns {array} an array of integer ids for each node matching all filters.
 */
const selectNumericIdsForExport = createSelector(
  nodeSelectors.selectAll,
  selectNodeFilterFunc,
  (nodes, applyAllFilters) =>
    fp.pipe(
      applyAllFilters,
      fp.reject((node) => !getModelId(node)),
      fp.map(getModelId),
      fp.map(stringKeyToNumericId),
    )(nodes),
)

export { selectFilterValuesFunc, selectNumericIdsForExport }
