import cn from "classnames"
import React from "react"
import AnimateHeight from "react-animate-height"
import { useTranslation } from "react-i18next"

import { RoleScope } from "types/graphql.enums"

import { RoleScopeInputs } from "./RoleScopeInputs"
import { RoleItem, RoleItemUpdate, RoleKey } from "./types"

type AddonRoleInputProps = {
  addonRole: RoleItem
  errorMessage?: string
  availableScopeTypes: RoleScope[]
  isChecked: boolean
  onToggle: (key: RoleKey) => void
  onUpdate: (input: RoleItemUpdate) => void
}

export function AddonRoleInput({
  addonRole,
  availableScopeTypes,
  errorMessage,
  isChecked,
  onToggle,
  onUpdate,
}: AddonRoleInputProps) {
  const { t } = useTranslation()
  const handleToggle = () => onToggle(addonRole.role)
  const addMarginIfChecked = availableScopeTypes.length > 1

  return (
    <div className="input-group mb-0" key={addonRole.role}>
      <label className={cn("checkbox", addMarginIfChecked && isChecked ? "!mb-2" : "!mb-0")}>
        <input
          type="checkbox"
          name={`${addonRole.role}_role`}
          checked={isChecked}
          onChange={handleToggle}
        />
        {t(`v2.permissions.form.addon_role_label_for_${addonRole.role}`, {
          count: availableScopeTypes.length,
        })}
      </label>
      <AnimateHeight duration={250} height={isChecked ? "auto" : 0}>
        <RoleScopeInputs
          role={addonRole.role}
          availableScopeTypes={availableScopeTypes}
          errorMessage={errorMessage}
          scopeType={addonRole.scopeType}
          linkKeys={addonRole.linkKeys}
          onUpdate={onUpdate}
        />
      </AnimateHeight>
    </div>
  )
}
