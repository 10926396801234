import React, { forwardRef, useMemo, useState } from "react"

import { BasePay } from "types/graphql.enums"
import { UpdateSumProps } from "v2/react/components/orgChart/OrgChartDatasheet/Modals/CompensationFollowUp/CompensationTable"
import { CurrencyInput } from "v2/react/shared/forms/CurrencyInput"
import { HourlyPayInput } from "v2/react/shared/forms/HourlyPayInput"
import { InputFieldset } from "v2/react/shared/forms/InputFieldset"
import { Select } from "v2/react/shared/forms/Select"
import { prepareCurrencyValue } from "v2/react/utils/currency"

type InputRef = React.RefObject<HTMLInputElement>
type SelectRef = React.RefObject<HTMLSelectElement>

interface BasePayInputProps {
  focusOn?: "type" | "amount"
  modelType?: "position" | "compensation"
  label?: string
  labelPlacement?: "top" | "left"
  iconClass?: string
  initialCurrencyValue?: string
  initialHoursPerWeek?: string | null
  initialPayType?: BasePay
  currencyErrors?: string
  hoursErrors?: string
  useAttentionState?: boolean
  clearErrors?: (keys: string[]) => void
  updateSum?: (props: UpdateSumProps) => void
}

const BasePayInput = forwardRef<HTMLInputElement | HTMLSelectElement, BasePayInputProps>(
  (
    {
      focusOn,
      modelType = "position",
      label,
      labelPlacement = "top",
      iconClass = window.gon.currency_icon_class || "far fa-dollar-sign",
      initialCurrencyValue,
      initialHoursPerWeek,
      initialPayType,
      currencyErrors,
      hoursErrors,
      useAttentionState,
      clearErrors,
      updateSum,
    },
    ref,
  ) => {
    const [payType, setPayType] = useState(initialPayType || BasePay.Salary)
    const [amount, setAmount] = useState(initialCurrencyValue || "")
    const [hoursPerWeek, setHoursPerWeek] = useState(initialHoursPerWeek || "")

    const amountRef = focusOn === "amount" ? (ref as InputRef) : undefined
    const typeRef = focusOn === "type" ? (ref as SelectRef) : undefined

    const prefix = modelType === "position" ? "position_" : ""
    const payTypeName = `${prefix}base_pay_type`
    const payRateName = `${prefix}base_pay`
    const hoursName = `${prefix}hours_per_week`

    const errors = []
    if (currencyErrors) errors.push(formatErrors({ errors: currencyErrors, inputType: "currency" }))
    if (hoursErrors) errors.push(formatErrors({ errors: hoursErrors, inputType: "hours" }))

    // eslint-disable-next-line @typescript-eslint/no-empty-function, no-empty-pattern
    let updateSumIfPresent = ({}: Partial<UpdateSumProps>) => {}
    if (updateSum) {
      updateSumIfPresent = ({
        value,
        payType: type,
        hoursPerWeek: hours,
      }: Partial<UpdateSumProps>) =>
        updateSum({
          key: payRateName,
          value: value === undefined ? amount : value,
          payType: type === undefined ? payType : type,
          hoursPerWeek: hours === undefined ? hoursPerWeek : hours,
        })
    }

    const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
      if (clearErrors) {
        clearErrors([payRateName, hoursName])
      }
      setPayType(event.target.value as BasePay)
      setHoursPerWeek("")
      updateSumIfPresent({ payType: event.target.value as BasePay, hoursPerWeek: "" })
    }

    const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setAmount(e.target.value)
      updateSumIfPresent({ value: e.target.value })
    }

    const handleHoursChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setHoursPerWeek(e.target.value)
      updateSumIfPresent({ hoursPerWeek: e.target.value })
    }

    const formattedInitialAmount = useMemo(
      () => prepareCurrencyValue({ value: initialCurrencyValue }),
      [initialCurrencyValue],
    )

    return (
      <InputFieldset label={label} errors={errors} labelPlacement={labelPlacement}>
        <div className="flex-col gap-4 flex md:flex-row">
          <div className="flex-2">
            <Select
              ref={typeRef}
              id={payTypeName}
              name={payTypeName}
              selected={payType}
              onSelect={handleSelect}
              options={[
                { id: BasePay.Salary, label: "salary".t("org_chart") },
                { id: BasePay.Hourly, label: "hourly".t("org_chart") },
              ]}
            />
          </div>

          <div className="flex-3">
            {payType === BasePay.Salary ? (
              <CurrencyInput
                ref={amountRef}
                id={payRateName}
                name={payRateName}
                iconClass={iconClass}
                defaultValue={initialCurrencyValue === amount ? formattedInitialAmount : amount}
                errors={currencyErrors}
                showErrorMessage={false}
                useAttentionState={useAttentionState}
                onChange={handleAmountChange}
              />
            ) : (
              <HourlyPayInput
                ref={amountRef}
                currencyName={payRateName}
                hoursName={hoursName}
                iconClass={iconClass}
                idPrefix="hourly-pay"
                currencyErrors={currencyErrors}
                hoursErrors={hoursErrors}
                showErrorMessage={false}
                initialCurrencyValue={
                  initialCurrencyValue === amount ? formattedInitialAmount : amount
                }
                initialHoursPerWeek={hoursPerWeek}
                useAttentionState={useAttentionState}
                onAmountChange={handleAmountChange}
                onHoursChange={handleHoursChange}
              />
            )}
          </div>
        </div>
      </InputFieldset>
    )
  },
)

/**
 * Formats the given errors by prefixing them with a label that corresponds to the input type.
 *
 * @example
 * const result = formatErrors({ errors: "Some error message", inputType: "currency" });
 * // Output: "Base Pay Rate: Some error message"
 */
const formatErrors = ({
  errors,
  inputType,
}: {
  errors: string
  inputType: "currency" | "hours"
}) => {
  const errorLabel = inputType === "currency" ? "field_base_pay" : "field_hours_per_week"
  return `${errorLabel.t("org_chart")}: ${errors}`
}

export { BasePayInput }
