import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import { CardType } from "v2/redux/slices/MatrixSlice"

interface ColorStyleIconProps {
  option: CardType
}

function ColorStyleIcon({ option }: ColorStyleIconProps) {
  const { t } = useTranslation()

  return (
    <div className="items-center flex">
      <div
        className={cn("mr-1.5 h-2.5 w-3.5 rounded-[1px]", {
          "bg-neutral-80": option === "color_full",
          "border-b border-l-4 border-r border-t border-solid border-neutral-80":
            option === "color_edge",
          "border border-solid border-neutral-80": option === "color_none",
        })}
      />
      <p>{t(`v2.succession_modal.${option}`)}</p>
    </div>
  )
}

export { ColorStyleIcon }
