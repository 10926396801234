import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

interface MatrixIconProps {
  addItemBorder?: boolean
  addMatrixBorder: boolean
  palette: string[]
  text?: string
}

function MatrixIcon({ addItemBorder, addMatrixBorder, palette, text }: MatrixIconProps) {
  const { t } = useTranslation()

  return (
    <div className="items-center flex">
      <div
        className={cn("mr-2", {
          "border--main h-10 w-10 rounded p-3": addMatrixBorder,
          "h-3.5 w-4": !addMatrixBorder,
        })}
      >
        <div className="h-full w-full grid-cols-3 gap-[1px] grid">
          {palette.map((color, index) => (
            <div
              className={cn("rounded-sm bg-neutral-8", { "border-neutral-8": addItemBorder })}
              style={{ backgroundColor: color }}
              // eslint-disable-next-line react/no-array-index-key
              key={`${color}-${index}`}
            />
          ))}
        </div>
      </div>
      {text ? <span>{t(`v2.succession_modal.${text}`)}</span> : ""}
    </div>
  )
}

export { MatrixIcon }
