import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { FormEvent, useState } from "react"
import { useTranslation } from "react-i18next"

import { CareerLaddersCreateInput, Error } from "types/graphql"
import { CareerLadderForm } from "v2/react/components/careerLadders/CareerLadderForm"
import { UrlHelper } from "v2/react/utils/urls"
import { useCareerLaddersCreateMutation } from "v2/redux/GraphqlApi/CareerLaddersApi"

const NewCareerLadderButton = () => {
  const { t } = useTranslation()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [errors, setErrors] = useState<Error[] | undefined | null>([])
  const generalError = [
    { message: t("v2.career_ladders.errors.could_not_be_created"), path: ["general"] },
  ]
  const [mutate, { isLoading }] = useCareerLaddersCreateMutation()

  const newCareerLadder = (e: React.MouseEvent) => {
    e.preventDefault()
    setModalIsOpen(true)
  }

  const onClose = () => {
    setModalIsOpen(false)
  }

  const onSubmit = async (event: FormEvent<Element>) => {
    event.preventDefault()

    const form = event.target as HTMLFormElement
    const formData = new FormData(form)
    const input: CareerLaddersCreateInput = {
      code: formData.get("code"),
      name: formData.get("name"),
      description: formData.get("description"),
    } as CareerLaddersCreateInput
    // Send data to backend and then redirect to detail page
    try {
      const result = await mutate(input).unwrap()

      if (result.careerLaddersCreate?.careerLadder?.id) {
        setModalIsOpen(false)
        window.location.href = UrlHelper.careerLadderDetailsPath(
          result.careerLaddersCreate.careerLadder?.id,
        )
      } else if (result.careerLaddersCreate?.errors) {
        setErrors(result.careerLaddersCreate?.errors)
      } else {
        setErrors(generalError)
      }
    } catch (error) {
      setErrors(generalError)
    }
  }

  return (
    <>
      <button
        className="btn btn--primary sm:btn--large sm:btn--primary tooltip tooltip-right"
        onClick={newCareerLadder}
        type="button"
      >
        <FontAwesomeIcon icon={["far", "plus"]} />
        <span className="hidden sm:flex">{t("v2.career_ladders.index.add_career_ladder")}</span>
      </button>

      <CareerLadderForm
        isOpen={modalIsOpen}
        isLoading={isLoading}
        onClose={onClose}
        title={t("v2.career_ladders.index.add_career_ladder")}
        onSubmit={onSubmit}
        errors={errors}
      />
    </>
  )
}

export { NewCareerLadderButton }
