import fp from "lodash/fp"

import { Error as GqlError } from "types/graphql.d"
import { NodeErrorSet } from "v2/redux/slices/NodeSlice/types"

/**
 * Builds a node error set with the given errors; merged into `original` if present.
 *
 * @public
 */
function makeNextNodeErrorSet(errors: GqlError[], nodeId: string, original?: NodeErrorSet) {
  const incoming = makeNodeErrorSet(nodeId, errors)
  if (!original) return incoming

  return fp.merge(original, incoming)
}

/**
 * Removes any field specific errors for `fields` from `errorSet`.
 *
 * @public
 */
function clearFieldErrors(fields: string[], errorSet: NodeErrorSet) {
  return { ...errorSet, fieldErrors: fp.omit(fields, errorSet.fieldErrors) }
}

/**
 * Builds a new node error set with the given errors.
 *
 * @public
 */
function makeNodeErrorSet(nodeId: string, errors: GqlError[]): NodeErrorSet {
  return fp.reduce(intoErrorSet, makeEmptyNodeErrorSet(nodeId), errors)
}

function makeEmptyNodeErrorSet(nodeId: string): NodeErrorSet {
  return {
    nodeId,
    fieldErrors: {},
    baseErrors: [],
  }
}

function intoErrorSet(errorSet: NodeErrorSet, error: GqlError) {
  const byAppendingMessage = byAppending(error.message)
  if (!error.path || error.path.length === 0)
    return fp.update("baseErrors", byAppendingMessage, errorSet)

  const head = fp.head(error.path)
  const tail = fp.tail(error.path)
  const fieldAttributePath = fp.cond([
    [fp.equals(["last_name"]), fp.always(["fieldErrors", "name"])],
    [fp.equals(["first_name"]), fp.always(["fieldErrors", "name"])],
    [fp.always(true), (actual: string[]) => ["fieldErrors", ...actual]],
  ])(tail)

  if (head !== "attributes" || fieldAttributePath.length === 0)
    return fp.update("baseErrors", byAppendingMessage, errorSet)

  return fp.update(fieldAttributePath, byAppendingMessage, errorSet)
}

function byAppending<T>(item: T) {
  return (items: T[] | undefined) => [...(items || []), item]
}

export { clearFieldErrors, makeNextNodeErrorSet, makeNodeErrorSet }
