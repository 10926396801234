import React from "react"
import { useTranslation } from "react-i18next"

import { usePositionFormContext } from "v2/react/components/positions/positionForm/PositionFormProvider"
import { Tab, Tabs } from "v2/react/shared/layout/Tabs"

import { AllocationsTab } from "./AllocationsTab"
import { CustomFieldsTab } from "./CustomFieldsTab"
import { JobDescriptionTab } from "./JobDescriptionTab"
import { PlanningTab } from "./PlanningTab"
import { PositionInfoTab } from "./PositionInfoTab"

function PositionManagementTabs() {
  const { t } = useTranslation()
  const { positionFormAbilities } = usePositionFormContext()

  return (
    <Tabs>
      <Tab
        Content={PositionInfoTab}
        label={t("v2.positions.form.position_info")}
        tabKey="positionInfo"
      />
      <Tab
        Content={JobDescriptionTab}
        label={t("v2.positions.form.job_description")}
        tabKey="jobDescription"
      />
      {positionFormAbilities.canShowPlanningTab && (
        <Tab Content={PlanningTab} label={t("v2.positions.form.planning")} tabKey="planning" />
      )}
      {positionFormAbilities.canManageAllocations && (
        <Tab
          Content={AllocationsTab}
          label={t("v2.positions.form.allocations")}
          tabKey="allocations"
        />
      )}
      {positionFormAbilities.canShowCustomFieldsTab && (
        <Tab
          Content={CustomFieldsTab}
          label={t("v2.positions.form.custom_fields")}
          tabKey="customFields"
        />
      )}
    </Tabs>
  )
}

export { PositionManagementTabs }
