import { t } from "i18next"
import React, { FormEvent, useState } from "react"

import { JobLevel } from "types/graphql"
import { Modal, SaveFooter } from "v2/react/shared/overlay/Modal"
import { ErrorNotice } from "v2/react/shared/status/ErrorNotice"
import { useJobLevelDestroyMutation } from "v2/redux/GraphqlApi/JobLevelApi"

interface Props {
  isOpen: boolean
  jobLevel: JobLevel
  onClose: () => void
}

const DeleteModal = ({ jobLevel, isOpen, onClose }: Props) => {
  const [mutate, { isLoading }] = useJobLevelDestroyMutation()
  const [isError, setIsError] = useState(false)

  const onSubmit = (event: FormEvent<Element>) => {
    event.preventDefault()

    mutate({
      jobLevelId: jobLevel.uniqueKey,
    })
      .unwrap()
      .then((result) => {
        if (result.jobLevelDestroy?.errors?.length === 0) {
          onClose()
        } else {
          setIsError(true)
        }
      })
      .catch(() => setIsError(true))
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t("v2.job_levels.index.delete_job_level", {
        code: jobLevel.code,
        name: jobLevel.name ? ` - ${jobLevel.name}` : "",
      })}
      size="md"
    >
      {isError && <ErrorNotice wrapperClassName="pt-6 px-6" />}
      <form onSubmit={onSubmit}>
        <div className="react-modal__body flex-col gap-2 flex">
          {t("v2.job_levels.index.confirm_delete")}
        </div>
        <SaveFooter
          isSaving={isLoading}
          onCancel={onClose}
          saveType="critical"
          enabledText={t("v2.defaults.delete")}
        />
      </form>
    </Modal>
  )
}

export { DeleteModal }
