import { TFunction } from "i18next"
import React from "react"

import { ProfilePanelBoxSetting, ProfilePanelBoxSettingSelection } from "types/graphql"

interface AdminLabelProps {
  adminBoxes: ProfilePanelBoxSetting[]
  item: ProfilePanelBoxSetting | ProfilePanelBoxSettingSelection
  t: TFunction
}

const AdminLabel: React.FC<AdminLabelProps> = ({ adminBoxes, item, t }) => {
  const isAdminBox = adminBoxes.find((box) => box.name === item.name)
  if (isAdminBox) {
    return (
      <span className="h-full items-center whitespace-nowrap text-xs text-neutral-64 flex">
        {t("v2.profile_panel.admin_only")}
      </span>
    )
  }
  return null
}

export default AdminLabel
