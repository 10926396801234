import React from "react"

import { sanitizeParse } from "v2/react/utils/sanitizeParse"

const HtmlListItem = (label: string, value?: string | null) => (
  <div className="module-card-list-item">
    <div>{label}</div>
    <div>{sanitizeParse(value || "")}</div>
  </div>
)

export { HtmlListItem }
