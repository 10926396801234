import classNames from "classnames"
import React from "react"

type Props = {
  id?: string
  className?: string
  style?: React.CSSProperties
  children?: React.ReactNode
}
// eslint-disable-next-line prefer-arrow-callback
export const DisplayValueCell = React.memo(function DisplayValueCell({
  id,
  className,
  style,
  children,
}: Props) {
  return (
    <div id={id} className={classNames("place-content-center px-4 grid", className)} style={style}>
      {children}
    </div>
  )
})
