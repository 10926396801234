/* eslint-disable react/jsx-props-no-spreading */
import i18n from "i18next"
import React, { PropsWithChildren, useEffect, useState } from "react"
import { I18nProvider } from "react-aria"
import { I18nextProvider } from "react-i18next"

import { appContainer, AppContainerSetupArg } from "v2/appContainer"
import i18nManifest from "v2/i18n-manifest.json"
import { loadedI18n } from "v2/redux/slices/SessionSlice"
import { AppStore, useAppDispatch, useAppSelector } from "v2/redux/store"
import { StoreProvider } from "v2/redux/StoreProvider"

// Done to ensure FontAwesome icons are loaded and registered during SSR.
import "v2/components/fontawesome"

interface I18nManifest {
  [localeFile: string]: string
}

interface LocaleValues {
  [key: string]: string | LocaleValues
}

interface LocaleData {
  [key: string]: LocaleValues
}

type RootProviderProps = PropsWithChildren<{
  canLoadI18nResources?: boolean
  i18n?: typeof i18n
  store?: AppStore
}>

type RootProviderWithSSRProps = PropsWithChildren<{ fromRails?: AppContainerSetupArg }>

const loadLocaleData = (locale: string): Promise<LocaleData | null> => {
  const manifest: I18nManifest = i18nManifest as I18nManifest
  const localeFileOriginalFilename = `app-${locale}.json`
  const localeFileWithDigest = manifest[localeFileOriginalFilename]

  return fetch(`/javascripts/i18n/${localeFileWithDigest}`)
    .then((response) => response.json())
    .catch(() => null)
}

const currentLanguage = globalThis.gon?.current_user_locale ?? "en"

export const I18nDataLoader = ({
  canLoadI18nResources,
  children,
}: PropsWithChildren<{ canLoadI18nResources: boolean }>) => {
  const isI18nLoaded = useAppSelector((state) => state.session.loadedI18n)
  const [loading, setLoading] = useState(canLoadI18nResources && !isI18nLoaded)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isI18nLoaded || !canLoadI18nResources) return

    loadLocaleData(currentLanguage)
      .then((localeData) => {
        if (localeData && localeData[currentLanguage]) {
          Object.keys(localeData[currentLanguage]).forEach((key) => {
            i18n.addResourceBundle(
              currentLanguage,
              key,
              localeData[currentLanguage][key],
              true,
              true,
            )
          })
          appContainer.maybeChangeI18nLocale({ locale: currentLanguage })
        }
        setLoading(false)
        dispatch(loadedI18n(true))
      })
      .catch(() => {
        setLoading(false)
      })
  }, [canLoadI18nResources, dispatch, isI18nLoaded])

  return loading ? null : children
}

function RootProvider({
  canLoadI18nResources = true,
  children,
  i18n: i18nFromCaller,
  store,
}: RootProviderProps) {
  return (
    <I18nextProvider i18n={i18nFromCaller ?? appContainer.i18nInstance}>
      <I18nProvider locale={currentLanguage}>
        <StoreProvider store={store}>
          <I18nDataLoader canLoadI18nResources={canLoadI18nResources}>{children}</I18nDataLoader>
        </StoreProvider>
      </I18nProvider>
    </I18nextProvider>
  )
}

function RootProviderWithSSR({ children, fromRails }: RootProviderWithSSRProps) {
  appContainer.setupForSSR(fromRails)

  return (
    <RootProvider
      canLoadI18nResources={false}
      i18n={appContainer.i18nInstance}
      store={appContainer.reduxStore}
    >
      {children}
    </RootProvider>
  )
}

function withSSRProvider<Props extends object>(Component: React.FC<Props>, name?: string) {
  const componentName = name ?? `${Component.name}WithSSRProvider`
  const builder = {
    // This quirky hack ensures that the generated function is named
    // `componentName` and shows as such in React Dev Tools. Assigning
    // `function.name = ...` resulted in type errors.
    [componentName]: (props: Props & RootProviderWithSSRProps) => (
      <RootProviderWithSSR {...props}>
        <Component {...props} />
      </RootProviderWithSSR>
    ),
  }

  return builder[componentName]
}

function withRootProvider<Props extends object>(Component: React.FC<Props>, name?: string) {
  const componentName = name ?? `${Component.name}WithRootProvider`
  const builder = {
    // This quirky hack ensures that the generated function is named
    // `componentName` and shows as such in React Dev Tools. Assigning
    // `function.name = ...` resulted in type errors.
    [componentName]: (props: Props & RootProviderProps) => (
      <RootProvider {...props}>
        <Component {...props} />
      </RootProvider>
    ),
  }

  return builder[componentName]
}

export default RootProvider
export { RootProviderWithSSR, withSSRProvider, withRootProvider }
