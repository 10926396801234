import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React, { useEffect, useState } from "react"

import { useAutoComplete } from "v2/react/hooks/useAutocomplete"
import { usePositionTypeSearch } from "v2/react/hooks/usePositionTypeSearch"
import { DropdownMenu } from "v2/react/shared/collection/menus/DropdownMenu"
import { prepareIconClass } from "v2/react/utils/misc"
import { PositionTypesConnectionNode } from "v2/redux/GraphqlApi/PositionTypesApi"

import { PositionTypeAutocompleteResult } from "./PositionTypeAutocompleteResult"

interface PositionTypeSearchInputProps {
  classes?: string
  errorMessage?: string
  excludeEmpty?: boolean
  htmlForId?: string
  icon?: string
  isFocused?: boolean
  notInCareerLadder?: boolean
  onSelect?: (selectedPositionType: PositionTypesConnectionNode) => void
  placeholder?: string
  retainNameOnSelect?: boolean
  disabled?: boolean
}

function PositionTypeSearchInput({
  errorMessage,
  excludeEmpty,
  htmlForId,
  onSelect,
  placeholder,
  retainNameOnSelect,
  classes = "",
  icon = "far fa-search",
  isFocused = false,
  notInCareerLadder = false,
  disabled,
}: PositionTypeSearchInputProps) {
  const [inputValue, setInputValue] = useState("")
  const [showResultList, setShowResultList] = useState(false)
  const { positionTypes, returnEmpty } = usePositionTypeSearch({
    filter: inputValue.trim(),
    excludeEmpty,
    notInCareerLadder,
  })

  const {
    activeIndex,
    setActiveIndex,
    listRef,
    refs,
    floatingStyles,
    context,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
  } = useAutoComplete({
    floatOverrides: { placement: "bottom-start" },
    setShowList: setShowResultList,
    showList: showResultList,
  })

  useEffect(() => {
    if (refs.domReference.current && isFocused) {
      refs.domReference.current?.focus()
    }
  }, [isFocused, refs.domReference])

  const handleChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    setInputValue(value || "")

    if (value.length > 0) {
      setShowResultList(true)
      setActiveIndex(0)
    } else {
      setShowResultList(false)
    }
  }

  const handleResultClick = (positionId: string | null | undefined, positionLabel: string) => {
    if (retainNameOnSelect) {
      setInputValue(positionLabel)
    } else {
      setInputValue("")
    }

    if (typeof activeIndex === "number" && activeIndex >= 0) {
      const selectedPositionType = positionTypes[activeIndex]
      if (onSelect && selectedPositionType) onSelect(selectedPositionType)
    }

    setActiveIndex(null)
    setShowResultList(false)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && activeIndex != null && positionTypes[activeIndex]) {
      const selectedPositionType = positionTypes[activeIndex]
      handleResultClick(selectedPositionType.uniqueKey, positionTypes[activeIndex].title || "")
    }
  }

  const showError = typeof errorMessage === "string" && errorMessage !== ""
  const responsiveStyles = "sm:text-base text-sm sm:h-auto h-6"

  const renderInput = () => (
    <div className="search-field relative">
      <div className="prefix pl-1 pr-1">
        <FontAwesomeIcon icon={prepareIconClass(icon)} />
      </div>
      <input
        aria-autocomplete="list"
        className={classNames("input pl-[1.9rem]", responsiveStyles)}
        id={htmlForId}
        placeholder={placeholder}
        type="text"
        value={inputValue}
        ref={refs.setReference}
        disabled={disabled}
        /* eslint-disable react/jsx-props-no-spreading */
        {...getReferenceProps({
          onChange: handleChangeEvent,
          onKeyDown: handleKeyDown,
        })}
      />
    </div>
  )

  return (
    <div className={classNames(classes, { "form-error": showError })}>
      <div className={classNames({ "form-error show": showError })}>
        {renderInput()}
        {showError && <span className="form-error-message">{errorMessage}</span>}
      </div>

      <DropdownMenu
        context={context}
        floatingProps={getFloatingProps}
        floatingRef={refs.setFloating}
        floatingStyles={{ ...floatingStyles, minWidth: "min(280px, 90vw)", right: 0 }}
        showList={showResultList && !!inputValue.trim().length}
        wrapperClasses="autocomplete-container"
      >
        <div className="list-group autocomplete-list">
          {positionTypes &&
            positionTypes.length > 0 &&
            positionTypes?.map((positionType: PositionTypesConnectionNode, index) => (
              <PositionTypeAutocompleteResult
                className={classNames("list-group-item", { highlight: activeIndex === index })}
                getItemProps={getItemProps}
                handleResultClick={handleResultClick}
                isSelected={activeIndex === index}
                key={positionType.uniqueKey}
                positionType={positionType}
                refFn={(node: HTMLDivElement | null) => {
                  listRef.current[index] = node
                }}
              />
            ))}
          {!returnEmpty && positionTypes?.length === 0 && inputValue.length > 0 && (
            <div className="list-group-item">
              <span>{"no_position_types_found".t("position")}</span>
            </div>
          )}
        </div>
      </DropdownMenu>
    </div>
  )
}

export { PositionTypeSearchInput }
