import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CalendarDate, parseDate } from "@internationalized/date"
import dayjs, { Dayjs } from "dayjs"
import quarterOfYear from "dayjs/plugin/quarterOfYear"
import React from "react"
import { DateRange, DateValue } from "react-aria"
import { useTranslation } from "react-i18next"

import {
  buildDateRangeOptions,
  DateRangeOption,
  type PresetDateRangeKey,
} from "v2/react/shared/forms/DateInputs/utils/dates"

const dailyOptions: DateRangeOption[] = ["tomorrow", "today", "yesterday"]
const currentOptions: DateRangeOption[] = ["current_month", "current_quarter", "current_year"]
const toDateOptions: DateRangeOption[] = ["month_to_date", "quarter_to_date", "year_to_date"]
const nextOptions: DateRangeOption[] = ["next_week", "next_month", "next_quarter", "next_year"]
const lastOptions: DateRangeOption[] = [
  "last_week",
  "last_month",
  "last_quarter",
  "last_year",
  "last_12_months",
]

interface QuickSelectMenuProps {
  quickSelectOptions: DateRangeOption[]
  selectedRange: string
  setActiveRange: ({ start, end }: DateRange) => void
  setDialogContent: (dialogContent: string) => void
  setIsOpen: (isOpen: boolean) => void
  setSelectedRange: (rangeOptionKey: PresetDateRangeKey, dateValue?: DateRange | null) => void
  dateRanges: ReturnType<typeof buildDateRangeOptions>
}

function QuickSelectMenu({
  quickSelectOptions,
  selectedRange,
  setActiveRange,
  setDialogContent,
  setIsOpen,
  setSelectedRange,
  dateRanges,
}: QuickSelectMenuProps) {
  const { t } = useTranslation()
  dayjs.extend(quarterOfYear)
  const formatDate = (date: Dayjs) => parseDate(date.format("YYYY-MM-DD"))

  const selectDateRange = (start: DateValue, end: DateValue, selectedValue: PresetDateRangeKey) => {
    const value = { start, end }
    setActiveRange(value)
    setSelectedRange(selectedValue, value)
    setIsOpen(false)
  }

  const selectCustomRange = () => {
    setDialogContent("calendar")
    setSelectedRange("custom_date_range")
  }

  return (
    <>
      {/* Daily Options */}
      {quickSelectOptions.includes("tomorrow") && (
        <DefaultDateSelection
          isActive={selectedRange}
          dataValue={dateRanges.tomorrow.value}
          textLabel={t(`v2.date.ranges.${dateRanges.tomorrow.value}`)}
          dateLabel={dateRanges.tomorrow.label}
          startDate={formatDate(dateRanges.tomorrow.start)}
          endDate={formatDate(dateRanges.tomorrow.end)}
          onClick={selectDateRange}
        />
      )}
      {quickSelectOptions.includes("today") && (
        <DefaultDateSelection
          isActive={selectedRange}
          dataValue={dateRanges.today.value}
          textLabel={t(`v2.date.ranges.${dateRanges.today.value}`)}
          dateLabel={dateRanges.today.label}
          startDate={formatDate(dateRanges.today.start)}
          endDate={formatDate(dateRanges.today.end)}
          onClick={selectDateRange}
        />
      )}
      {quickSelectOptions.includes("yesterday") && (
        <DefaultDateSelection
          isActive={selectedRange}
          dataValue={dateRanges.yesterday.value}
          textLabel={t(`v2.date.ranges.${dateRanges.yesterday.value}`)}
          dateLabel={dateRanges.yesterday.label}
          startDate={formatDate(dateRanges.yesterday.start)}
          endDate={formatDate(dateRanges.yesterday.end)}
          onClick={selectDateRange}
        />
      )}
      {dailyOptions.some((option) => quickSelectOptions.includes(option)) && (
        <hr className="mx-[-.5rem] my-2 h-px border-none bg-neutral-8" />
      )}

      {/* Current Options */}
      {quickSelectOptions.includes("current_week") && (
        <DefaultDateSelection
          isActive={selectedRange}
          dataValue={dateRanges.current_week.value}
          textLabel={t(`v2.date.ranges.${dateRanges.current_week.value}`)}
          dateLabel={dateRanges.current_week.label}
          startDate={formatDate(dateRanges.current_week.start)}
          endDate={formatDate(dateRanges.current_week.end)}
          onClick={selectDateRange}
        />
      )}
      {quickSelectOptions.includes("current_month") && (
        <DefaultDateSelection
          isActive={selectedRange}
          dataValue={dateRanges.current_month.value}
          textLabel={t(`v2.date.ranges.${dateRanges.current_month.value}`)}
          dateLabel={dateRanges.current_month.label}
          startDate={formatDate(dateRanges.current_month.start)}
          endDate={formatDate(dateRanges.current_month.end)}
          onClick={selectDateRange}
        />
      )}
      {quickSelectOptions.includes("current_quarter") && (
        <DefaultDateSelection
          isActive={selectedRange}
          dataValue={dateRanges.current_quarter.value}
          textLabel={t(`v2.date.ranges.${dateRanges.current_quarter.value}`)}
          dateLabel={dateRanges.current_quarter.label}
          startDate={formatDate(dateRanges.current_quarter.start)}
          endDate={formatDate(dateRanges.current_quarter.end)}
          onClick={selectDateRange}
        />
      )}
      {quickSelectOptions.includes("current_year") && (
        <DefaultDateSelection
          isActive={selectedRange}
          dataValue={dateRanges.current_year.value}
          textLabel={t(`v2.date.ranges.${dateRanges.current_year.value}`)}
          dateLabel={dateRanges.current_year.label}
          startDate={formatDate(dateRanges.current_year.start)}
          endDate={formatDate(dateRanges.current_year.end)}
          onClick={selectDateRange}
        />
      )}
      {currentOptions.some((option) => quickSelectOptions.includes(option)) && (
        <hr className="mx-[-.5rem] my-2 h-px border-none bg-neutral-8" />
      )}

      {/* To Date Options */}
      {quickSelectOptions.includes("month_to_date") && (
        <DefaultDateSelection
          isActive={selectedRange}
          dataValue={dateRanges.month_to_date.value}
          textLabel={t(`v2.date.ranges.${dateRanges.month_to_date.value}`)}
          dateLabel={dateRanges.month_to_date.label}
          startDate={formatDate(dateRanges.month_to_date.start)}
          endDate={formatDate(dateRanges.month_to_date.end)}
          onClick={selectDateRange}
        />
      )}
      {quickSelectOptions.includes("quarter_to_date") && (
        <DefaultDateSelection
          isActive={selectedRange}
          dataValue={dateRanges.quarter_to_date.value}
          textLabel={t(`v2.date.ranges.${dateRanges.quarter_to_date.value}`)}
          dateLabel={dateRanges.quarter_to_date.label}
          startDate={formatDate(dateRanges.quarter_to_date.start)}
          endDate={formatDate(dateRanges.quarter_to_date.end)}
          onClick={selectDateRange}
        />
      )}
      {quickSelectOptions.includes("year_to_date") && (
        <DefaultDateSelection
          isActive={selectedRange}
          dataValue={dateRanges.year_to_date.value}
          textLabel={t(`v2.date.ranges.${dateRanges.year_to_date.value}`)}
          dateLabel={dateRanges.year_to_date.label}
          startDate={formatDate(dateRanges.year_to_date.start)}
          endDate={formatDate(dateRanges.year_to_date.end)}
          onClick={selectDateRange}
        />
      )}
      {toDateOptions.some((option) => quickSelectOptions.includes(option)) && (
        <hr className="mx-[-.5rem] my-2 h-px border-none bg-neutral-8" />
      )}

      {/* Last Options */}
      {quickSelectOptions.includes("last_week") && (
        <DefaultDateSelection
          isActive={selectedRange}
          dataValue={dateRanges.last_week.value}
          textLabel={t(`v2.date.ranges.${dateRanges.last_week.value}`)}
          dateLabel={dateRanges.last_week.label}
          startDate={formatDate(dateRanges.last_week.start)}
          endDate={formatDate(dateRanges.last_week.end)}
          onClick={selectDateRange}
        />
      )}
      {quickSelectOptions.includes("last_month") && (
        <DefaultDateSelection
          isActive={selectedRange}
          dataValue={dateRanges.last_month.value}
          textLabel={t(`v2.date.ranges.${dateRanges.last_month.value}`)}
          dateLabel={dateRanges.last_month.label}
          startDate={formatDate(dateRanges.last_month.start)}
          endDate={formatDate(dateRanges.last_month.end)}
          onClick={selectDateRange}
        />
      )}
      {quickSelectOptions.includes("last_quarter") && (
        <DefaultDateSelection
          isActive={selectedRange}
          dataValue={dateRanges.last_quarter.value}
          textLabel={t(`v2.date.ranges.${dateRanges.last_quarter.value}`)}
          dateLabel={dateRanges.last_quarter.label}
          startDate={formatDate(dateRanges.last_quarter.start)}
          endDate={formatDate(dateRanges.last_quarter.end)}
          onClick={selectDateRange}
        />
      )}
      {quickSelectOptions.includes("last_year") && (
        <DefaultDateSelection
          isActive={selectedRange}
          dataValue={dateRanges.last_year.value}
          textLabel={t(`v2.date.ranges.${dateRanges.last_year.value}`)}
          dateLabel={dateRanges.last_year.label}
          startDate={formatDate(dateRanges.last_year.start)}
          endDate={formatDate(dateRanges.last_year.end)}
          onClick={selectDateRange}
        />
      )}
      {quickSelectOptions.includes("last_12_months") && (
        <DefaultDateSelection
          isActive={selectedRange}
          dataValue={dateRanges.last_12_months.value}
          textLabel={t(`v2.date.ranges.${dateRanges.last_12_months.value}`)}
          dateLabel={dateRanges.last_12_months.label}
          startDate={formatDate(dateRanges.last_12_months.start)}
          endDate={formatDate(dateRanges.last_12_months.end)}
          onClick={selectDateRange}
        />
      )}
      {lastOptions.some((option) => quickSelectOptions.includes(option)) && (
        <hr className="mx-[-.5rem] my-2 h-px border-none bg-neutral-8" />
      )}

      {/* Next Options */}
      {quickSelectOptions.includes("next_week") && (
        <DefaultDateSelection
          isActive={selectedRange}
          dataValue={dateRanges.next_week.value}
          textLabel={t(`v2.date.ranges.${dateRanges.next_week.value}`)}
          dateLabel={dateRanges.next_week.label}
          startDate={formatDate(dateRanges.next_week.start)}
          endDate={formatDate(dateRanges.next_week.end)}
          onClick={selectDateRange}
        />
      )}
      {quickSelectOptions.includes("next_month") && (
        <DefaultDateSelection
          isActive={selectedRange}
          dataValue={dateRanges.next_month.value}
          textLabel={t(`v2.date.ranges.${dateRanges.next_month.value}`)}
          dateLabel={dateRanges.next_month.label}
          startDate={formatDate(dateRanges.next_month.start)}
          endDate={formatDate(dateRanges.next_month.end)}
          onClick={selectDateRange}
        />
      )}
      {quickSelectOptions.includes("next_quarter") && (
        <DefaultDateSelection
          isActive={selectedRange}
          dataValue={dateRanges.next_quarter.value}
          textLabel={t(`v2.date.ranges.${dateRanges.next_quarter.value}`)}
          dateLabel={dateRanges.next_quarter.label}
          startDate={formatDate(dateRanges.next_quarter.start)}
          endDate={formatDate(dateRanges.next_quarter.end)}
          onClick={selectDateRange}
        />
      )}
      {quickSelectOptions.includes("next_year") && (
        <DefaultDateSelection
          isActive={selectedRange}
          dataValue={dateRanges.next_year.value}
          textLabel={t(`v2.date.ranges.${dateRanges.next_year.value}`)}
          dateLabel={dateRanges.next_year.label}
          startDate={formatDate(dateRanges.next_year.start)}
          endDate={formatDate(dateRanges.next_year.end)}
          onClick={selectDateRange}
        />
      )}
      {nextOptions.some((option) => quickSelectOptions.includes(option)) && (
        <hr className="mx-[-.5rem] my-2 h-px border-none bg-neutral-8" />
      )}

      <button
        className="w-full cursor-pointer items-center justify-between rounded bg-transparent px-3 py-2.5 flex hover:bg-neutral-3"
        onClick={selectCustomRange}
        type="button"
      >
        <span>{t("v2.date.ranges.custom_date_range")}</span>
        {selectedRange === "custom_date_range" ? (
          <FontAwesomeIcon icon={["fas", "check-circle"]} className="h-3.5 w-3.5" />
        ) : (
          <div className="w-1.5" />
        )}
      </button>
    </>
  )
}

export default QuickSelectMenu
export type { QuickSelectMenuProps }

interface defaultDateSelectionProps {
  isActive: string
  dataValue: PresetDateRangeKey
  textLabel: string
  dateLabel: string
  endDate: CalendarDate
  startDate: CalendarDate
  onClick: (start: DateValue, end: DateValue, selectedValue: PresetDateRangeKey) => void
}

function DefaultDateSelection({
  isActive,
  dataValue,
  textLabel,
  dateLabel,
  startDate,
  endDate,
  onClick,
}: defaultDateSelectionProps) {
  return (
    <button
      className="w-full cursor-pointer items-center gap-x-3 whitespace-nowrap rounded bg-transparent px-3 py-2.5 flex hover:bg-neutral-3"
      onClick={() => onClick(startDate, endDate, dataValue)}
      type="button"
    >
      <p>{textLabel}</p>
      <p className="ml-auto text-neutral-64">{dateLabel}</p>
      {isActive === dataValue ? (
        <FontAwesomeIcon icon={["fas", "check-circle"]} className="h-3.5 w-3.5" />
      ) : (
        <div className="w-3.5" />
      )}
    </button>
  )
}
