import { ColumnDef } from "@tanstack/react-table"
import React, { useMemo, useState } from "react"

import { NodeInterface } from "types/graphql"
import { getColumnsForDrillDown } from "v2/react/components/dashboard/helpers/columns"
import {
  buildFilterOptionsQueryForDrillDown,
  buildPositionQueryForDrillDown,
} from "v2/react/components/dashboard/helpers/queries"
import { Widget } from "v2/react/components/dashboard/types"
import { Spinner } from "v2/react/shared/loaders/Spinner"

import { DrillDownFilters } from "./DrillDownFilters"
import { DrillDownTable } from "./DrillDownTable"

export const DrillDown = ({ widget }: { widget: Widget }) => {
  const [isTableDataLoading, setTableDataLoading] = useState(true)
  const [isFilterDataLoading, setFilterDataLoading] = useState(true)

  const handleTableDataLoaded = () => setTableDataLoading(false)
  const handleFilterDataLoaded = () => setFilterDataLoading(false)

  const columns = useMemo<ColumnDef<NodeInterface>[]>(
    () => getColumnsForDrillDown(widget),
    [widget],
  )
  const tableQuery = useMemo<string>(() => buildPositionQueryForDrillDown(widget), [widget])
  const drillDownOptsQuery = useMemo<string>(
    () => buildFilterOptionsQueryForDrillDown(widget),
    [widget],
  )

  return (
    <div className="react-modal__body">
      <DrillDownFilters query={drillDownOptsQuery} handleDataLoaded={handleFilterDataLoaded} />
      <DrillDownTable
        columns={columns}
        query={tableQuery}
        handleDataLoaded={handleTableDataLoaded}
      />
      {isFilterDataLoading || isTableDataLoading ? (
        <Spinner style={{ position: "relative" }} />
      ) : null}
    </div>
  )
}
