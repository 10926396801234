import dayjs from "dayjs"
import React, { useEffect, useRef } from "react"

import { usePositionController } from "v2/react/components/positions/positionForm/hooks"
import { FieldName } from "v2/react/components/positions/positionForm/types"
import { InputWrapper } from "v2/react/shared/forms"

type LegacyDateInputProps = {
  id: string
  label: string
  name: FieldName
  wrapperClassName?: string
}

/**
 * Temporary input for gathering dates using Pikaday/jQuery. Swap out when
 * we're ready to patch the date input in our position modals.
 */
function LegacyDateInput({ id, label, name, wrapperClassName }: LegacyDateInputProps) {
  const {
    field: { onChange: changeFieldValue, value: fieldValue },
  } = usePositionController({ name })

  const inputRef = useRef<HTMLInputElement>(null)
  const isPikadayBoundRef = useRef(false)

  // Handles changes like clearing the input, or if Pikaday isn't available for
  // some reason.
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (ev) =>
    changeFieldValue(ev.target.value)

  // Effect assumes `changeFieldValue` is stable/never changes.
  useEffect(() => {
    const { current: input } = inputRef
    const { current: isPikadayBound } = isPikadayBoundRef
    if (!input || isPikadayBound) return
    if (typeof window === "undefined" || !("Pikaday" in window)) return

    isPikadayBoundRef.current = true

    // @ts-ignore
    input.picker = new window.Pikaday({
      field: input,
      onSelect: (date: Date) => changeFieldValue(dayjs(date).format("YYYY-MM-DD")),
      yearRange: [1920, new Date().getFullYear() + 1],
      format: "YYYY-MM-DD",
    })

    if (window.$) {
      try {
        // Handling for v1 (e.g. Org Chart).
        window.$(input.parentElement).maskInputs()
      } catch {
        try {
          // Handling for v2 (e.g. Headcount Planning). Unlike our helper code
          // in v1, v2 expects the input directly.
          window.$(input).maskInputs()
          // eslint-disable-next-line no-empty
        } catch {} // NOP if we can't set up masks.
      }
    }
  }, [changeFieldValue])

  return (
    <InputWrapper
      className={wrapperClassName}
      combineClassNames
      id={id}
      label={label}
      name={name}
      useInReactHookForm
    >
      <input
        autoComplete="off"
        className="input"
        data-maskedinput="date"
        id={id}
        name={name}
        onChange={handleChange}
        ref={inputRef}
        type="text"
        value={fieldValue ?? ""}
      />
    </InputWrapper>
  )
}

export { LegacyDateInput }
