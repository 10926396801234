import classNames from "classnames"
import React, { ReactNode } from "react"

import { InputErrorText } from "v2/react/shared/forms/InputErrorText"
import { InputLabel } from "v2/react/shared/forms/InputLabel"

import { useReactHookFormFieldError } from "./hooks/useWithReactHookFormHooks"
import { UseInReactHookFormProp } from "./types"

interface InputWrapperProps {
  children: React.ReactNode
  label?: ReactNode
  errors?: string
  showErrorMessage?: boolean
  renderErrors?: (errors: string) => React.ReactNode
  id?: string
  className?: string
  combineClassNames?: boolean
  name?: string
  onMouseEnterLabel?: React.MouseEventHandler
  onMouseLeaveLabel?: React.MouseEventHandler
  useInReactHookForm?: UseInReactHookFormProp
}

const InputWrapper = ({
  children,
  label,
  errors,
  showErrorMessage = true,
  id,
  className,
  combineClassNames = true,
  name,
  renderErrors,
  onMouseEnterLabel,
  onMouseLeaveLabel,
  useInReactHookForm,
}: InputWrapperProps) => {
  const rhfError = useReactHookFormFieldError({ name, useInReactHookForm })
  const errorMessage = errors || rhfError

  return (
    <div
      className={classNames(
        { "form-error": combineClassNames && errorMessage, "input-wrapper": combineClassNames },
        className,
      )}
    >
      {label && (
        <InputLabel
          id={id}
          label={label}
          onMouseEnter={onMouseEnterLabel}
          onMouseLeave={onMouseLeaveLabel}
        />
      )}
      {children}
      {errorMessage && showErrorMessage && renderErrors && renderErrors(errorMessage)}
      {errorMessage && showErrorMessage && !renderErrors && (
        <InputErrorText message={errorMessage} />
      )}
    </div>
  )
}

export { InputWrapper }
