import React from "react"

import { ProfilePanelMode } from "types/graphql.enums"
import { withRootProvider } from "v2/react/components/RootProvider"
import { openProfilePanelSidebar, setProfilePanelMode } from "v2/redux/slices/ProfilePanelSlice"
import { closeSuccessionPlanSidebar } from "v2/redux/slices/SuccessionPlanPanelSlice"
import { useAppDispatch } from "v2/redux/store"

import { PanelIconTooltip } from "./PanelIconTooltip"

interface Props {
  peopleIds?: string[]
  peopleNames?: string[]
  positionId?: string
}

const WithProvider = ({ peopleIds = [], peopleNames = [], positionId = "" }: Props) => {
  const dispatch = useAppDispatch()

  if (peopleIds.length < 1) return null

  // Assumption: The panel should use the first person in the list
  const handleOpenProfile = () => {
    dispatch(setProfilePanelMode(ProfilePanelMode.Person))
    dispatch(closeSuccessionPlanSidebar())
    dispatch(openProfilePanelSidebar({ positionId, personId: peopleIds[0] }))
  }

  // See: https://tailwindcss.com/docs/hover-focus-and-other-states#styling-based-on-parent-state
  return (
    <div className="link group gap-0 p-0">
      {peopleLinks(peopleIds, peopleNames)}
      <PanelIconTooltip handleOpenProfile={handleOpenProfile} />
    </div>
  )
}

const peopleLinks = (peopleIds: string[], peopleNames: string[]) => {
  const links = peopleNames.map((name: string, index: number) => {
    const personId = peopleIds[index]

    if (!personId) return ""
    return (
      /* eslint-disable-next-line react/no-array-index-key */
      <a key={personId + index} href={`/people/${personId}`}>
        {name}
      </a>
    )
  })

  return (
    <>
      {links
        .map<React.ReactNode>((t) => t)
        .reduce((prev, curr, index) => [
          prev,
          // eslint-disable-next-line react/no-array-index-key
          <span className="mr-1" key={index}>
            ,{" "}
          </span>,
          curr,
        ])}
    </>
  )
}

const IconTextLinkForPeople = withRootProvider(WithProvider, "IconTextLinkForPeople")

export { IconTextLinkForPeople }
