import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { FeatureFlags } from "types/graphql"
import { facChartSections } from "v2/components/fontawesome"
import { TabSwitchButton } from "v2/react/components/orgChart/SuperPanel/TabSwitchButton"
import {
  selectCanManageChart,
  selectIsLimitedAdminUsingOfficialChart,
} from "v2/redux/slices/AbilitySlice/abilitySelectors"
import { chartSectionSelectors } from "v2/redux/slices/ContainerSlice"
import { asyncPatchPreferences } from "v2/redux/slices/ContainerSlice/containerThunks"
import { toggleSuperPanelFooter } from "v2/redux/slices/VisualizationSlice"
import { TabKey } from "v2/redux/slices/VisualizationSlice/types"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

interface TabSwitchProps {
  ff: FeatureFlags
}

function TabSwitch({ ff }: TabSwitchProps) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const canManageChart = useAppSelector(selectCanManageChart)
  const isLimitedAdminUsingOfficialChart = useAppSelector(selectIsLimitedAdminUsingOfficialChart)

  const selectedTab = useAppSelector((state) => state.visualization.selectedTab)
  const superPanelOpen = useAppSelector((state) => state.visualization.superPanelOpen)
  const chartSectionsTotal = useAppSelector((state) => chartSectionSelectors.selectTotal(state))
  const displayMode = useAppSelector((state) => state.visualization.displayMode)
  const superPanelFooter = useAppSelector((state) => state.visualization.superPanelFooter)
  const isListView = window.location.pathname.indexOf("lists/") > 0

  const showBuilderTab =
    canManageChart && !window.gon.selected_chart.merging && displayMode !== "grid"
  const showColorCodingTab =
    ff.orgchartVisualization && (canManageChart || isLimitedAdminUsingOfficialChart)
  const showChartSectionTab = (canManageChart || chartSectionsTotal > 0) && !isListView
  const disabledChartBuilder =
    window.gon.editing_disabled && canManageChart && !window.gon.selected_chart.merging

  useEffect(() => {
    if (
      (!canManageChart && isListView && selectedTab === "tab-chart-builder") ||
      (!showChartSectionTab && selectedTab === "tab-chart-sections")
    ) {
      dispatch(asyncPatchPreferences({ selected_tab: "tab-display-options" }))
    } else if (
      (disabledChartBuilder || !showBuilderTab) &&
      selectedTab === "tab-chart-builder" &&
      !isListView
    ) {
      dispatch(asyncPatchPreferences({ selected_tab: "tab-chart-sections" }))
    }
  }, [
    dispatch,
    canManageChart,
    disabledChartBuilder,
    isListView,
    selectedTab,
    showBuilderTab,
    showChartSectionTab,
  ])

  const handleTabClick = (tabKey: TabKey) => {
    if (superPanelFooter) {
      /* eslint-disable no-alert, no-restricted-globals */
      const confirmed = confirm("You may lose changes".t("org_chart"))
      if (confirmed) {
        dispatch(toggleSuperPanelFooter(false))
        toggleTab(tabKey)
      }
    } else {
      toggleTab(tabKey)
    }
  }

  const toggleTab = (tabKey: TabKey) => {
    if (selectedTab === tabKey && superPanelOpen) {
      dispatch(
        asyncPatchPreferences({
          super_panel_open: false,
        }),
      )
    } else {
      dispatch(
        asyncPatchPreferences({
          super_panel_open: true,
          selected_tab: tabKey,
        }),
      )
    }
  }

  return (
    <ul className="tabs drawer-toggle-tabs m-0 list-none gap-2 flex">
      {showChartSectionTab && (
        <TabSwitchButton
          active={selectedTab === "tab-chart-sections"}
          icon={<FontAwesomeIcon icon={facChartSections} />}
          onClick={handleTabClick}
          tabKey="tab-chart-sections"
          title={t("v2.orgchart.utilitynav.chart_sections")}
        />
      )}
      <TabSwitchButton
        active={selectedTab === "tab-display-options"}
        icon={<FontAwesomeIcon icon={["far", "sitemap"]} />}
        onClick={handleTabClick}
        tabKey="tab-display-options"
        title={t("v2.orgchart.utilitynav.display_options")}
      />
      <TabSwitchButton
        active={selectedTab === "tab-data-options"}
        icon={<FontAwesomeIcon icon={["far", "sliders-h"]} />}
        onClick={handleTabClick}
        tabKey="tab-data-options"
        title={t("v2.orgchart.utilitynav.data_options")}
      />
      {showColorCodingTab && (
        <TabSwitchButton
          active={selectedTab === "tab-color-coding"}
          icon={<FontAwesomeIcon icon={["far", "fill-drip"]} />}
          onClick={handleTabClick}
          tabKey="tab-color-coding"
          title={t("v2.orgchart.utilitynav.color_coding")}
        />
      )}
      {showBuilderTab && (
        <TabSwitchButton
          active={selectedTab === "tab-chart-builder"}
          disabled={disabledChartBuilder}
          icon={<FontAwesomeIcon icon={["far", "plus-square"]} />}
          onClick={handleTabClick}
          tabKey="tab-chart-builder"
          title={
            isListView
              ? t("v2.orgchart.utilitynav.list_builder")
              : t("v2.orgchart.utilitynav.chart_builder")
          }
        />
      )}
    </ul>
  )
}

export { TabSwitch }
