import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React from "react"

import { Person } from "types/graphql"
import { Modal } from "v2/react/shared/overlay/Modal"
import { AlertBanner } from "v2/react/shared/status/AlertBanner"
import { useSubmitHeadcountPlanProposalMutation } from "v2/redux/GraphqlApi/HeadcountPlanningApi"

interface Props {
  disabled: boolean
  display?: "menuItem" | "button"
  onClick?: () => void
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export function SubmitProposalButton({ disabled, display = "button", onClick, setIsOpen }: Props) {
  return (
    <button
      type="button"
      className={classNames("whitespace-nowrap", {
        "btn--large btn--secondary": display === "button",
        "dropdown-menu-link gap-2 flex": display === "menuItem",
        disabled,
      })}
      onClick={() => {
        setIsOpen(true)
        onClick?.()
      }}
      disabled={disabled}
    >
      <div className="justify-self-center">
        <FontAwesomeIcon
          icon={["far", "paper-plane"]}
          className={classNames({ "mr-1": display === "button" })}
        />
      </div>
      {"submit_proposal_button".t("headcount_plan")}
    </button>
  )
}

type ModalProps = {
  submitsToPerson?: Person
  headcountPlanId: string
  participantId: string
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}
export function SubmitProposalModal({
  isOpen,
  setIsOpen,
  headcountPlanId,
  participantId,
  submitsToPerson,
}: ModalProps) {
  const [note, setNote] = React.useState("")
  const [mutate, mutation] = useSubmitHeadcountPlanProposalMutation()

  React.useEffect(() => {
    if (mutation.data?.submitHeadcountPlanProposal?.success) {
      mutation.reset()
      setIsOpen(false)
    }
  }, [mutation, setIsOpen])

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        mutation.reset()
        setIsOpen(false)
      }}
      title="Submit Proposal"
      footer={
        <button
          type="submit"
          className="btn--large btn--primary"
          disabled={mutation.data?.submitHeadcountPlanProposal?.success === false}
          onClick={() => {
            mutate({
              input: {
                headcountPlanId: headcountPlanId.toString(),
                participantId,
                justification: note,
              },
            })
          }}
        >
          {mutation.isLoading
            ? "submit_proposal_button_pending".t("headcount_plan")
            : "submit_proposal_button".t("headcount_plan")}
        </button>
      }
    >
      <div className="p-6">
        {mutation.data?.submitHeadcountPlanProposal?.success === false && (
          <div className="pb-4">
            <AlertBanner
              type="critical"
              messages={[
                {
                  icon: (
                    <FontAwesomeIcon
                      icon={["far", "exclamation-circle"]}
                      className="h-4"
                      style={{ width: "1rem" }}
                    />
                  ),
                  text: (
                    <span className="font-medium">
                      {mutation.data?.submitHeadcountPlanProposal?.errors?.[0]?.message}
                    </span>
                  ),
                },
              ]}
            />
          </div>
        )}
        {submitsToPerson ? (
          <>
            <div className="gap-4 bg-neutral-3 p-4 flex">
              <img
                src={submitsToPerson.avatarThumbUrl || ""}
                alt={`${submitsToPerson.name}'s avatar`}
                className="h-10 rounded-full"
              />
              <div className="flex-col justify-center flex">
                <div>
                  <span className="mr-2 font-bold">{submitsToPerson.name}</span>{" "}
                  {submitsToPerson?.primaryPosition?.title}
                </div>
              </div>
            </div>
            <div className={`input-group ${submitsToPerson ? "mt-6" : ""}`}>
              <label htmlFor="note">{"note_label".t("headcount_plan")}</label>
              <div style={{ width: "100%" }}>
                <textarea
                  id="note"
                  className="input"
                  name="note"
                  style={{ resize: "none" }}
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </div>
            </div>
          </>
        ) : (
          <p>{"confirm_submit_to_entire_org".t("headcount_plan")}</p>
        )}
      </div>
    </Modal>
  )
}
