import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useTranslation } from "react-i18next"

import { Position } from "types/graphql"
import { TruncatedText } from "v2/react/components/succession/SuccessionPlanPanel/shared/TruncatedText"
import { CandidateList } from "v2/react/components/succession/SuccessionPlanPanel/Summary/CandidateList"
import { Comments } from "v2/react/components/succession/SuccessionPlanPanel/Summary/Comments"
import { FilledBy } from "v2/react/components/succession/SuccessionPlanPanel/Summary/FilledBy"
import { Summary } from "v2/react/components/succession/SuccessionPlanPanel/Summary/Summary"
import { positionTitleDisplay } from "v2/react/components/succession/SuccessionPlanPanel/utils/positionMappers"

interface SummaryTabProps {
  canEditPosition: boolean
  canEditSuccessionPlan: boolean
  position: Position
}

function SummaryTab({ canEditPosition, canEditSuccessionPlan, position }: SummaryTabProps) {
  const { t } = useTranslation()

  return (
    <div className="flex-col gap-6 flex">
      <div className="w-full max-w-full overflow-hidden">
        <h3 className="text-sm text-neutral-64">{t("v2.succession_plan_panel.header.plan_for")}</h3>
        <TruncatedText
          displayClasses="text-large"
          displayContent={`${position.systemIdentifier} - ${positionTitleDisplay(position, t)}`}
          tooltipContent={positionTitleDisplay(position, t)}
        />
      </div>

      <FilledBy position={position} canEditPosition={canEditPosition} />
      <div className="succession-plan-alerts">
        {position.successionPlan?.alerts && position.successionPlan?.alerts.length > 0 && (
          <div className="alert alert-caution flex">
            <FontAwesomeIcon icon={["far", "exclamation-triangle"]} className="mt-1" />
            <div>
              <p className="text-base-bold">{t("v2.succession_plan_panel.summary.risks")}</p>
              <ul className="!mb-0 !mt-1.5 pl-4">
                {position.successionPlan.alerts.map((alert) => (
                  <li key={alert}>{alert}</li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
      <CandidateList canEditSuccessionPlan={canEditSuccessionPlan} position={position} />
      <Summary canEditSuccessionPlan={canEditSuccessionPlan} position={position} />
      <Comments position={position} comments={position.successionPlan?.comments || []} />
    </div>
  )
}

export { SummaryTab }
