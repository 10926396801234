import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"

interface EmailIconProps {
  email: string
}

function EmailIcon({ email }: EmailIconProps) {
  const [showTooltip, setShowTooltip] = useState(false)
  const { t } = useTranslation()

  return (
    <Tooltip open={showTooltip}>
      <TooltipTrigger
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <a href={`mailto:${email}`} className="text-neutral-80 inline-block">
          <FontAwesomeIcon icon={["far", "envelope"]} />
        </a>
      </TooltipTrigger>
      <TooltipContent className="react-tooltip-content--sm break-all">
        {t("v2.profile_panel.click_to_email", { email })}
      </TooltipContent>
    </Tooltip>
  )
}

export { EmailIcon }
