import React, { useCallback, useEffect, useRef, useState } from "react"
import { useOnClickOutside } from "usehooks-ts"

import { Chart, ChartApprover } from "types/graphql.d"
import CancelApprovalButton from "v2/react/components/orgChart/ChartApproval/ApprovalDropdown/CancelApprovalButton"
import { SubmittedDisplay } from "v2/react/components/orgChart/ChartApproval/ApprovalDropdown/SubmittedDisplay"
import { SubmittedFooter } from "v2/react/components/orgChart/ChartApproval/ApprovalDropdown/SubmittedFooter"
import { SubmittedStatusButton } from "v2/react/components/orgChart/ChartApproval/ApprovalDropdown/SubmittedStatusButton"
import { useSelectList } from "v2/react/hooks/useSelectList"
import { DropdownMenu } from "v2/react/shared/collection/menus/DropdownMenu"
import { CurrentUser, toggleDropdown } from "v2/redux/slices/ApprovalSlice"
import { selectDropdown } from "v2/redux/slices/ApprovalSlice/approvalSelectors"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

interface Props {
  chart: Chart
  approvers: ChartApprover[]
  currentUser: CurrentUser
  openConfigureModal: () => void
}

function SubmittedDropdown({ chart, approvers, currentUser, openConfigureModal }: Props) {
  const dropdownIsOpen: boolean = useAppSelector(selectDropdown)
  const [showMenu, setShowMenu] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const dispatch = useAppDispatch()

  const toggleDropdownModal = () => dispatch(toggleDropdown(!dropdownIsOpen))
  const closeDropdownModal = useCallback(() => dispatch(toggleDropdown(false)), [dispatch])

  const { refs, floatingStyles, context, getReferenceProps, getFloatingProps } = useSelectList({
    showList: showMenu,
    setShowList: setShowMenu,
    floatOverrides: { placement: "top-start" },
  })

  useEffect(() => {
    setShowMenu(dropdownIsOpen)
  }, [dropdownIsOpen])

  useEffect(() => {
    const handleDropdownVisibility = () => window.innerWidth < 640 && closeDropdownModal()
    // This prevents some odd display behavior if the dropdown is open,
    // then the window is resized < 640px and then resized > 640px
    window.addEventListener("resize", handleDropdownVisibility)
    return () => window.removeEventListener("resize", handleDropdownVisibility)
  }, [closeDropdownModal])

  useOnClickOutside(containerRef, ({ target }: MouseEvent | TouchEvent) => {
    if (target !== refs.floating.current && !refs.floating.current?.contains(target as Node)) {
      closeDropdownModal()
    }
  })

  return (
    <div ref={containerRef}>
      <SubmittedStatusButton
        ref={refs.setReference}
        /* eslint-disable react/jsx-props-no-spreading */
        referenceProps={getReferenceProps({ onClick: toggleDropdownModal })}
        approvers={approvers}
        chart={chart}
        displayType="button"
      />

      <DropdownMenu
        showList={showMenu}
        floatingRef={refs.setFloating}
        floatingStyles={{ ...floatingStyles, minWidth: "min(400px, 90vw)", left: 0 }}
        floatingProps={getFloatingProps}
        wrapperClasses="dropdown-menu hidden sm:!block z-10 mt-[2px] p-4"
        context={context}
      >
        <div className="grid-rows-[auto_1fr_auto] grid sm:max-h-[60vh]">
          <div className="mb-4 items-center justify-between flex">
            <p className="text-large">{"approval_request".t("org_chart")}</p>
            <CancelApprovalButton chart={chart} />
          </div>
          <SubmittedDisplay
            approvers={approvers}
            chart={chart}
            currentUser={currentUser}
            openConfigureModal={openConfigureModal}
          />
          <SubmittedFooter chart={chart} currentUser={currentUser} />
        </div>
      </DropdownMenu>
    </div>
  )
}

export { SubmittedDropdown }
