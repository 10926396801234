import cn from "classnames"
import React from "react"

interface Props {
  canSeeButton: boolean
  canUseButton: boolean
  onClick: () => void
}
const EditApproversButton = ({ canSeeButton, canUseButton, onClick }: Props): React.ReactNode => {
  if (canSeeButton) {
    return (
      <button
        className={cn("btn--sm btn--secondary", { "btn-disabled": !canUseButton })}
        type="button"
        onClick={onClick}
        disabled={!canUseButton}
      >
        {"edit".t("org_chart")}
      </button>
    )
  }

  return null
}

export { EditApproversButton }
