import React, { ReactNode } from "react"

interface Props {
  children: ReactNode
}

function ActionBlock({ children }: Props) {
  return <div className="action-block h-full items-center justify-end flex">{children}</div>
}

function ActionBlockLarge({ children }: Props) {
  return <div className="action-block-lg h-full items-center justify-end flex">{children}</div>
}

export { ActionBlock, ActionBlockLarge }
