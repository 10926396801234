import {
  autoUpdate,
  flip,
  offset,
  OpenChangeReason,
  Placement,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
} from "@floating-ui/react"
import { useRef, useState } from "react"

type OnOpenChange = (open: boolean, event?: Event, reason?: OpenChangeReason) => void

interface useDropdownSelectProps {
  showDropdown: boolean
  setShowDropdown: React.Dispatch<React.SetStateAction<boolean>> | OnOpenChange
  align?: "left" | "right"
  ariaRole?: "menu" | "combobox" | "listbox"
  /**
   * Use this if you need focus to remain on the reference element (such as an
   * input), but allow arrow keys to navigate list items.
   * See: https://floating-ui.com/docs/uselistnavigation#virtual
   */
  virtual?: boolean
  /**
   * If true, the floating element will dynamically resize based on available
   * space.
   */
  useDynamicSizing?: boolean
  /**
   * The maximum height of the floating element.
   */
  maxHeight?: number
}

const POSITION: Record<string, Placement> = {
  left: "bottom-start",
  right: "bottom-end",
}

export function useDropdownSelect({
  showDropdown,
  setShowDropdown,
  align = "left",
  ariaRole = "menu",
  virtual = false,
  useDynamicSizing = false,
  maxHeight,
}: useDropdownSelectProps) {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const listRef = useRef<Array<HTMLElement | null>>([])

  const padding = {
    top: 16,
    bottom: 16,
  }

  let middleware = [offset(8), flip({ padding })]
  if (useDynamicSizing) {
    // see: https://floating-ui.com/docs/size#usage
    middleware = [
      ...middleware,
      size({
        padding,
        apply({ availableHeight, elements, rects }) {
          const width = rects.reference.width
          const height = maxHeight ? Math.min(availableHeight, maxHeight) : availableHeight

          Object.assign(elements.floating.style, {
            maxHeight: `${height}px`,
            width: `${width}px`,
          })
        },
      }),
    ]
  }

  const { refs, floatingStyles, context } = useFloating<HTMLInputElement>({
    placement: POSITION[align],
    open: showDropdown,
    onOpenChange: setShowDropdown,
    whileElementsMounted: autoUpdate,
    middleware,
  })

  const click = useClick(context)
  const role = useRole(context, { role: ariaRole })
  const dismiss = useDismiss(context)
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    virtual,
    loop: true,
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    role,
    dismiss,
    listNav,
    click,
  ])

  let finalFloatingStyles = floatingStyles
  if (maxHeight && !useDynamicSizing) {
    finalFloatingStyles = {
      ...floatingStyles,
      maxHeight: `${maxHeight}px`,
    }
  }

  return {
    activeIndex,
    setActiveIndex,
    listRef,
    refs,
    floatingStyles: finalFloatingStyles,
    context,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
  }
}

export type { OnOpenChange }
