import React, { useState } from "react"
import type { DateRange as DateRangeType, DateValue } from "react-aria"
import { useTranslation } from "react-i18next"

import type { PositionsOverTimeMetrics, TimelineIntervalTypeEnum } from "types/graphql.enums"
import type { PositionsOverTimeProps } from "v2/react/components/positions/reports/PositionsOverTime"
import { BasicSelectMenu } from "v2/react/shared/collection/menus/BasicSelectMenu"
import { DateRange } from "v2/react/shared/forms/DateInputs/DateRange/DateRange"
import { DateRangeOption } from "v2/react/shared/forms/DateInputs/utils/dates"
import {
  ReorderableCheckboxMenu,
  type MenuOption,
} from "v2/react/shared/forms/ReorderableCheckboxMenu"

const quickSelectOptions: DateRangeOption[] = [
  "month_to_date",
  "quarter_to_date",
  "year_to_date",
  "last_month",
  "last_quarter",
  "last_year",
  "last_12_months",
]

interface ReportControlsProps
  extends Pick<PositionsOverTimeProps, "groupByOptions" | "intervalOptions"> {
  dateRange: DateRangeType
  maxDate?: DateValue
  minDate?: DateValue
  selectedInterval: TimelineIntervalTypeEnum
  selectedGroup: string
  metricMenuOptions: MetricMenuOption[]
  setGroupBy: (newGroup: string) => void
  setMetrics: (metricOptions: MetricMenuOption[]) => void
  setInterval: (interval: TimelineIntervalTypeEnum) => void
  handleDateRangeSelection: (dateRange?: DateRangeType | null) => void
}

type MetricMenuOption = MenuOption<PositionsOverTimeMetrics>

const ReportControls = ({
  dateRange,
  maxDate,
  minDate,
  intervalOptions,
  selectedInterval,
  groupByOptions,
  selectedGroup,
  metricMenuOptions,
  setGroupBy,
  setMetrics,
  setInterval,
  handleDateRangeSelection,
}: ReportControlsProps) => {
  const { t } = useTranslation()
  const [activeDateRange, setActiveDateRange] = useState<DateRangeType | null>(dateRange)

  return (
    <div className="relative w-full flex-wrap gap-2 flex 360:flex-row 360:gap-3 sm:w-fit">
      <div className="flex-1">
        <DateRange
          label={t("v2.position_reports.positions_over_time.date_range_label")}
          className="min-w-[240px]"
          id="positions-over-time-range"
          value={activeDateRange}
          onChange={setActiveDateRange}
          onDateRangeSelection={handleDateRangeSelection}
          maxValue={maxDate}
          minValue={minDate}
          quickSelectOptions={quickSelectOptions}
        />
      </div>
      <ControlWrapper
        label={t("v2.position_reports.positions_over_time.interval_label")}
        id="interval"
      >
        <BasicSelectMenu<TimelineIntervalTypeEnum>
          id="interval"
          className="sm:max-w-[160px] 1120:w-[160px]"
          selectedOptionId={selectedInterval}
          setSelectedOptionId={setInterval}
          options={intervalOptions}
        />
      </ControlWrapper>
      <ControlWrapper
        label={t("v2.position_reports.positions_over_time.group_by_label")}
        id="group_by"
      >
        <BasicSelectMenu
          id="group_by"
          className="sm:max-w-[160px] 1120:w-[160px]"
          selectedOptionId={selectedGroup}
          setSelectedOptionId={setGroupBy}
          options={groupByOptions}
        />
      </ControlWrapper>
      <ControlWrapper
        label={t("v2.position_reports.positions_over_time.metrics_label")}
        id="metrics"
      >
        <ReorderableCheckboxMenu<PositionsOverTimeMetrics>
          id="metrics"
          className="1120:min-w-[160px]"
          options={metricMenuOptions}
          setOptions={setMetrics}
        />
      </ControlWrapper>
    </div>
  )
}

const ControlWrapper = ({
  children,
  label,
  id,
}: {
  children: React.ReactNode
  label: string
  id: string
}) => (
  <div className="flex-1">
    <label htmlFor={id} className="mb-1">
      {label}
    </label>
    {children}
  </div>
)

export { ReportControls }
