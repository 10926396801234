import React from "react"

import { StatusBadge } from "v2/react/shared/status/StatusBadge"

export function PlanTypeIndicator({ type }: { type: "original" | "approved" }) {
  return (
    <div className="flex-col justify-center flex">
      {type === "original" ? (
        <div className="badge--yellow">{"original_proposal".t("headcount_plan")}</div>
      ) : (
        <StatusBadge status="plan_approved" displayType="button" checkmarkOnApproved />
      )}
    </div>
  )
}
