/* eslint-disable react/jsx-props-no-spreading, jsx-a11y/no-noninteractive-tabindex */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import assistantPositionType from "images/assistant-position-type.svg"
import standardPositionType from "images/standard-position-type.svg"
import fp from "lodash/fp"
import React, { useMemo, useRef } from "react"
import { Trans, useTranslation } from "react-i18next"

import { Position } from "types/graphql"
import { EmbeddedPositionTypeControl } from "v2/react/components/positionTypes/EmbeddedPositionTypeControl"
import { usePositionSearch } from "v2/react/hooks/usePositionSearch"
import { useCurrentActiveSession } from "v2/react/hooks/useSessionHooks"
import { Dropdown } from "v2/react/shared/collection/menus/Dropdown/Dropdown"
import { TextInput } from "v2/react/shared/forms"
import { Autocomplete, AutocompleteBlurArg } from "v2/react/shared/forms/Autocomplete"
import { sanitizeParse } from "v2/react/utils/sanitizeParse"
import { idFromUniqueKey } from "v2/react/utils/uniqueKey"

import { useDefaultValuesPositionType, useFieldLabelHelpers, usePositionController } from "./hooks"
import { IconWithBorder } from "./IconWithBorder"
import { FilledByInput } from "./positionDetails/FilledByInput"
import { usePositionFormContext } from "./PositionFormProvider"

const jobCodeErrorMessageProp = fp.prop(["position", "positionType", "jobCode", "message"])
const titleErrorMessageProp = fp.prop(["position", "positionType", "title", "message"])

/** Based on app/views/v2/positions/form/_position_details.html.slim */
function PositionDetails() {
  const { t } = useTranslation()
  const { tLabel } = useFieldLabelHelpers()
  const defaultPositionType = useDefaultValuesPositionType()
  const { isLimitedAdmin, isOfficialChartKey } = useCurrentActiveSession()
  const {
    chartKey,
    formState: { errors, defaultValues },
    register,
  } = usePositionFormContext()
  const {
    field: { onChange: changeReportsToName, value: reportsToName },
  } = usePositionController({ name: "position.reportsToName" })
  const {
    field: { onChange: changeParentId, value: parentId },
    fieldState: { error: reportsToError },
  } = usePositionController({ name: "position.parentId" })
  const positionSearch = usePositionSearch({
    filter: reportsToName ?? "",
    chartKey,
    subordinateOnly: isLimitedAdmin && isOfficialChartKey(chartKey),
    includeSelf: true,
  })
  const {
    field: { onChange: changePositionType },
  } = usePositionController({ name: "position.positionType" })
  const {
    field: { onChange: changePositionTypeId },
  } = usePositionController({ name: "position.positionTypeId" })

  const defaultSystemIdentifier = defaultValues?.position?.systemIdentifier ?? ""
  const positionTypeError = jobCodeErrorMessageProp(errors) ?? titleErrorMessageProp(errors)

  const parentPositionRef = useRef({
    id: parentId ?? null,
    name: reportsToName,
  })

  const handleReportsToBlur = ({ isRelatedAutocompleteOption }: AutocompleteBlurArg) => {
    if (isRelatedAutocompleteOption) return

    if (!reportsToName || reportsToName.trim() === "") {
      parentPositionRef.current = { id: null, name: "" }
      changeParentId(null)
      changeReportsToName("")
      return
    }

    if (reportsToName !== parentPositionRef.current.name) {
      changeReportsToName(parentPositionRef.current.name)
    }
  }

  return (
    <>
      <input
        type="hidden"
        {...register("position.chart_id")}
        id="position_chart_id"
        defaultValue={idFromUniqueKey(chartKey)}
      />
      <div className="gap-4 flex">
        <TextInput
          className="!w-[initial]"
          defaultValue={defaultSystemIdentifier.length > 0 ? defaultSystemIdentifier : "--"}
          inputClassName="max-w-7-1/2 readonly"
          id="position_system_identifier"
          label={tLabel("position.systemIdentifier")}
          name="position.systemIdentifier"
          readOnly
        />

        <EmbeddedPositionTypeControl
          autoFocus
          error={positionTypeError}
          idForIdInput="position_position_type_id"
          initialValue={defaultPositionType}
          nameForIdInput="position.positionTypeId"
          onChange={(positionType) => {
            changePositionType(positionType ?? null)
            changePositionTypeId(
              positionType?.uniqueKey ? idFromUniqueKey(positionType?.uniqueKey) : null,
            )
          }}
        />
      </div>

      <TitleChangeNotice />
      <FilledByInput />

      <div className="gap-4 flex">
        <div className={cn("input-group w-full", reportsToError && "form-error")}>
          <label htmlFor="position_reports_to_name">{tLabel("position.reportsToName")}</label>
          <Autocomplete
            emptyMessage={
              <div className="empty-message py-2">
                {t("v2.positions.search.no_positions_found")}
              </div>
            }
            id="position_reports_to_name"
            isFetching={positionSearch.isFetching}
            placeholder={t("v2.positions.form.jane_doe_or_ceo")}
            onBlur={handleReportsToBlur}
            onChange={(ev) => changeReportsToName(ev.target.value)}
            onSelect={(option) => {
              const name =
                option.people?.[0]?.name ??
                `${t("v2.positions.form.system_identifier")}: ${option.systemIdentifier}`
              parentPositionRef.current = { id: option.id, name }
              changeParentId(option.id)
              changeReportsToName(name)
            }}
            options={positionSearch.positions}
            getKey={({ uniqueKey }) => uniqueKey ?? ""}
            // (current_person.is_limited_admin? && @position.chart.official?)"
            getValueFromSelected={({ title }) => title ?? ""}
            AutocompleteOption={PositionSearchResult}
            value={reportsToName}
          />
        </div>

        <PositionTypeInput />
      </div>
    </>
  )
}

const TitleChangeNotice = () => {
  const { t } = useTranslation()
  const originalPositionType = useDefaultValuesPositionType()
  const {
    field: { value: positionType },
  } = usePositionController({ name: "position.positionType" })

  if (originalPositionType?.uniqueKey === positionType?.uniqueKey) return null
  if (fp.isEmpty(positionType?.controlledAttributeNamesWithSetValuesHash)) return null

  return (
    <div className="mb-6" id="title-change-notice">
      <div className="elevation rounded-xl bg-status-caution-light p-4 flex">
        <div className="items-start self-stretch">
          <FontAwesomeIcon
            icon={["far", "exclamation-triangle"]}
            className="fa-w-18 text-2xl text-neutral-60 mr-2"
          />
        </div>
        <div className="flex-col flex">
          <div id="title-change-notice-description">
            <Trans
              components={{ span: <span /> }}
              i18nKey="v2.positions.form.title_change_notice_html"
              values={{
                old_title: originalPositionType?.jobCodeTitleLabel,
                new_title: positionType?.jobCodeTitleLabel,
              }}
              t={t}
            />
          </div>
          <ul className="!mb-0 ml-2 text-neutral-64 [&>li]:!m-0" id="title-change-notice-data-list">
            {Object.entries(positionType?.controlledAttributeNamesWithSetValuesHash).map(
              ([key, value]) => (
                <li className="mb-0" key={key}>
                  {key}: {sanitizeParse(value as string)}
                </li>
              ),
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

const PositionTypeInput = () => {
  const { t } = useTranslation()
  const { tLabel } = useFieldLabelHelpers()
  const {
    field: { onChange: changeIsAssistant, value: isAssistant },
    fieldState: { error },
  } = usePositionController({ name: "position.isAssistant", defaultValue: false })

  return (
    <div className="input-with-dropdown-selector w-[18.875rem] grow-0">
      <div className={cn("input-group dropdown-with-image")}>
        <div id="position-type-label" className="mb-1 cursor-default font-bold">
          {tLabel("position.isAssistant")}
        </div>
        <Dropdown align="right">
          <Dropdown.Trigger triggerClassName="w-full p-0 bg-transparent">
            <div
              className={cn("dropdown-link", { "!border--error": error })}
              tabIndex={0}
              aria-labelledby="position-type-label"
            >
              <IconWithBorder
                src={isAssistant ? assistantPositionType : standardPositionType}
                variant="sm"
              />
              <div className="text px-2">
                {isAssistant ? t("v2.positions.form.assistant") : t("v2.positions.form.standard")}
              </div>

              <FontAwesomeIcon icon={["fas", "caret-down"]} />
            </div>
          </Dropdown.Trigger>
          <Dropdown.Menu>
            <PositionTypeOption
              isAssistant={false}
              isSelected={!isAssistant}
              iconSrc={standardPositionType}
              title={t("v2.positions.form.standard")}
              helpText={t("v2.positions.form.standard_help_text")}
              onSelect={changeIsAssistant}
            />
            <PositionTypeOption
              isAssistant
              isSelected={isAssistant}
              iconSrc={assistantPositionType}
              title={t("v2.positions.form.assistant")}
              helpText={t("v2.positions.form.assistant_help_text")}
              onSelect={changeIsAssistant}
            />
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  )
}

const doesIncludeTarget = (subject?: string | null, target?: string | null) =>
  subject?.toLowerCase().includes(target?.toLowerCase() ?? "")

const PositionSearchResult = ({ option, searchTerm }: { option: Position; searchTerm: string }) => {
  const { t } = useTranslation()
  const { hasFeature } = useCurrentActiveSession()
  const avatarThumbUrl = option.people?.[0]?.avatarThumbUrl

  const { bioSkills, employeeIds, licenses } = useMemo(
    () => ({
      bioSkills: option.people
        ?.flatMap(({ bioSkills: skills }) => skills ?? [])
        ?.filter(({ name }) => doesIncludeTarget(name, searchTerm))
        ?.map(({ name }) => name),
      employeeIds: option.people
        ?.filter(({ employeeId }) => doesIncludeTarget(employeeId, searchTerm))
        ?.map(({ employeeId }) => employeeId),
      licenses: option.people
        ?.flatMap(({ bioLicenses }) => bioLicenses ?? [])
        ?.filter(({ name }) => doesIncludeTarget(name, searchTerm))
        ?.map(({ name }) => name),
    }),
    [option.people, searchTerm],
  )

  return (
    <>
      <div className="list-group-item-thumb circle circle-med">
        {avatarThumbUrl && (
          <img alt={option.people?.map(({ name }) => name).join(", ")} src={avatarThumbUrl} />
        )}
      </div>
      <div>
        <div className="list-group-item-title text-base-bold">
          {option.people?.map(({ name }) => name).join(", ")}
        </div>
        <div className="list-group-item-content text-neutral-64">{option.title}</div>

        {employeeIds?.length ? (
          <div>
            {t("v2.people.form.employee_id")}: {employeeIds.join(", ")}
          </div>
        ) : null}

        {doesIncludeTarget(option.externalIdentifier, searchTerm) && (
          <div>
            {t("v2.positions.form.external_identifier")}: {option.externalIdentifier}
          </div>
        )}

        {doesIncludeTarget(option.systemIdentifier, searchTerm) && (
          <div>
            {t("v2.positions.form.system_identifier")}: {option.systemIdentifier}
          </div>
        )}

        {hasFeature("bio") && option.people?.length ? (
          <div className="list-group-item-search-extras text-neutral-64">
            {bioSkills?.length ? (
              <div data-testid="reports-to-search-extras-skills">
                {t("v2.positions.search.skills")}: {bioSkills?.join(", ")}
              </div>
            ) : null}

            {licenses?.length ? (
              <div data-testid="reports-to-search-extras-licenses">
                {t("v2.positions.search.lic_cert")}: {licenses?.join(", ")}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  )
}

type PositionTypeOptionProps = {
  isSelected?: boolean
  title: string
  helpText: string
  iconSrc: string
  isAssistant: boolean
  onSelect?: (isAssistant: boolean) => void
}

function PositionTypeOption({
  iconSrc,
  isAssistant,
  isSelected,
  onSelect,
  title,
  helpText,
}: PositionTypeOptionProps) {
  const handleClick = () => onSelect?.(isAssistant)

  return (
    <Dropdown.Item
      className={cn("dropdown-menu-link max-w-[18.875rem] p-2", isSelected && "active")}
      onClick={handleClick}
    >
      <div className="selection-icon !items-start gap-1.5 flex">
        <IconWithBorder src={iconSrc} variant="lg" />
        <div className="text px-2">
          <div className="font-bold">{title}</div>
          <div className="help-text !text-base text-[0.625rem]/[0.8rem] text-neutral-64">
            {helpText}
          </div>
        </div>
        <div className="icons min-w-[2rem] self-center">
          {isSelected && (
            <FontAwesomeIcon icon={["fas", "check-circle"]} className="selected-indicator" />
          )}
        </div>
      </div>
    </Dropdown.Item>
  )
}

export { PositionDetails }
