import fp from "lodash/fp"

import { Tag } from "types/graphql"

import { TagValue } from "./types"

type NormalizedDomainTagsPropFunc<RecordType> = (record: RecordType) => TagValue[] | null

export const normalizedDomainTagsProp = <RecordType>(
  domainName: string,
  path: string | string[],
): NormalizedDomainTagsPropFunc<RecordType> =>
  fp.pipe(
    fp.prop(path),
    fp.filter<Tag>(fp.propEq(["tagDomain", "name"], domainName)),
    fp.map<Tag, TagValue>((tag) => ({ id: tag.id ?? tag.value, label: tag.value })),
    fp.cond([
      [fp.isEmpty, fp.always(null)],
      [fp.stubTrue, fp.identity<TagValue[]>],
    ]),
  )

export const toTag = (string: string) => ({ id: string, label: string })
