import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SortDirection } from "@tanstack/react-table"
import React from "react"

type Status = SortDirection | false
interface SortProps {
  status: Status
}

export const SortIcon = ({ status }: SortProps) => {
  const iconsMap: Record<SortDirection, [IconPrefix, IconName]> = {
    // Note: These directions match how we currently sort in our tables
    // but they may be counter to the widely accepted convention.
    asc: ["fas", "sort-down"],
    desc: ["fas", "sort-up"],
  }

  const BaseIcon = ["fas", "sort"] as [IconPrefix, IconName]

  const icon = status ? iconsMap[status] : BaseIcon

  return <FontAwesomeIcon icon={icon} />
}
