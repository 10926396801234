import { CellContext, TableOptions } from "@tanstack/react-table"
import classNames from "classnames"
import dayjs from "dayjs"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { AdpPagedChangeBatchesQueryVariables } from "types/graphql"
import { AdpChangeBatchState } from "types/graphql.enums"
import { useClientTable } from "v2/react/hooks/useClientTable"
import { MinStylingCell } from "v2/react/shared/tables/Table/Cell/MinStylingCell"
import { Table } from "v2/react/shared/tables/Table/Table"
import {
  countFailedRequests,
  countRequests,
  propFormattedDate,
  propIdFromUniqueKey,
  propSyncInitiatorName,
  useAdpPagedChangeBatchesQuery,
} from "v2/redux/GraphqlApi/AdpChangeQueueApi"
import {
  FlattenedAdpChangeBatch,
  useScreen,
  useScreenStackForAdpChangeModal,
} from "v2/redux/slices/AdpChangeSlice"

type TableConfig = Omit<TableOptions<FlattenedAdpChangeBatch>, "getCoreRowModel">
export type ChangeBatchListTable = { batches: FlattenedAdpChangeBatch[] }

const PAGE_SIZE = 10
const DEFAULT_QUERY_ARG = Object.freeze({ first: PAGE_SIZE })
const EMPTY_BATCHES: FlattenedAdpChangeBatch[] = []

export function ChangeBatchListTable() {
  const { isActive, scrollTo } = useScreen("TableOfBatches")
  const { t } = useTranslation()
  const [queryArg, setQueryArg] = useState<AdpPagedChangeBatchesQueryVariables>(DEFAULT_QUERY_ARG)
  const { data } = useAdpPagedChangeBatchesQuery(queryArg, {
    refetchOnMountOrArgChange: true,
  })
  const pageInfo = data?.pageInfo
  const isNextDisabled = pageInfo?.hasNextPage !== true
  const isPreviousDisabled = pageInfo?.hasPreviousPage !== true
  const columns = useChangeBatchListTableColumns()
  const table = useClientTable({
    columns,
    data: data?.batches ?? EMPTY_BATCHES,
    initialState: {
      sorting: [{ id: "syncFinishedAt", desc: true }],
    },
  })

  // Reset the scroll position when changing pages.
  const activeRef = useRef(isActive)
  activeRef.current = isActive
  useEffect(() => {
    if (activeRef.current && pageInfo) scrollTo({ top: 0 })
  }, [activeRef, pageInfo, scrollTo])

  const handlePreviousClick = useCallback(() => {
    if (!pageInfo?.hasPreviousPage) return
    setQueryArg({ last: PAGE_SIZE, before: pageInfo?.startCursor })
  }, [pageInfo, setQueryArg])

  const handleNextClick = useCallback(() => {
    if (!pageInfo?.hasNextPage) return
    setQueryArg({ first: PAGE_SIZE, after: pageInfo?.endCursor })
  }, [pageInfo, setQueryArg])

  // For cursor based pagination, assume we're on the one and only page if there
  // is neither a next or previous page.
  const shouldPaginate = pageInfo?.hasNextPage || pageInfo?.hasPreviousPage

  return (
    <>
      <Table table={table} className="table" />
      {shouldPaginate ? (
        <div className="paginate mt-6">
          <div className="pagination">
            <button
              className={classNames("previous_page", isPreviousDisabled && "disabled")}
              disabled={isPreviousDisabled}
              onClick={handlePreviousClick}
              type="button"
            >
              {`← ${t("v2.defaults.previous")}`}
            </button>
            <button
              className={classNames("next_page", isNextDisabled && "disabled")}
              disabled={isNextDisabled}
              onClick={handleNextClick}
              type="button"
            >
              {`${t("v2.defaults.next")} →`}
            </button>
          </div>
        </div>
      ) : null}
    </>
  )
}

// Noting that discarded is an internal state, we reflect it as sent to end
// users.
const STATUS_CLASS_NAMES = Object.freeze({
  [AdpChangeBatchState.Busy]: "bg-status-excite-light text-status-excite",
  [AdpChangeBatchState.Discarded]: "bg-status-success-light text-status-success",
  [AdpChangeBatchState.Open]: "bg-status-excite-light text-status-excite",
  [AdpChangeBatchState.PausedWithErrors]: "bg-status-caution-light text-status-caution",
  [AdpChangeBatchState.Sent]: "bg-status-success-light text-status-success",
  [AdpChangeBatchState.SentWithErrors]: "bg-status-caution-light text-status-caution",
})

const ViewBatchDetails = ({ row: { original } }: CellContext<FlattenedAdpChangeBatch, unknown>) => {
  const { pushScreens } = useScreenStackForAdpChangeModal()
  const handleClick = () => pushScreens([{ screenKey: "Batch", batch: original }])
  const { t } = useTranslation()

  return (
    <button className="btn btn--sm btn--secondary" type="button" onClick={handleClick}>
      {t("v2.defaults.view_details")}
    </button>
  )
}

export const BatchStatus = ({ batch }: { batch: FlattenedAdpChangeBatch }) => {
  const { t } = useTranslation()

  const className = classNames(
    "elevation text-sm font-bold rounded-3xl py-1 px-2",
    STATUS_CLASS_NAMES[batch.state],
  )

  return <span className={className}>{t(`v2.adp.change_batches.statuses.${batch.state}`)}</span>
}

const BatchStatusCell = ({ row: { original } }: CellContext<FlattenedAdpChangeBatch, unknown>) => (
  <BatchStatus batch={original} />
)

const useChangeBatchListTableColumns = (): TableConfig["columns"] => {
  const { t } = useTranslation()

  return useMemo(
    () => [
      {
        accessorFn: propIdFromUniqueKey("id"),
        cell: MinStylingCell.prepare({ alignment: "center" }),
        enableSorting: false,
        header: t("v2.adp.change_batches.column_headers.id"),
      },
      {
        cell: BatchStatusCell,
        enableSorting: false,
        header: t("v2.adp.change_batches.column_headers.state"),
      },
      {
        accessorFn: propSyncInitiatorName,
        enableSorting: false,
        header: t("v2.adp.change_batches.column_headers.initiated_by"),
      },
      {
        accessorFn: propFormattedDate("syncFinishedAt"),
        enableSorting: false,
        id: "syncFinishedAt",
        sortingFn: (lhs, rhs) => {
          const lhsDt = dayjs(lhs.original.syncFinishedAt)
          const rhsDt = dayjs(rhs.original.syncFinishedAt)
          if (lhsDt.isAfter(rhsDt)) return 1
          if (lhsDt.isSame(rhsDt)) return 0
          return -1
        },
        header: t("v2.adp.change_batches.column_headers.sent_on"),
      },
      {
        accessorFn: countRequests,
        enableSorting: false,
        header: t("v2.adp.change_batches.column_headers.changes"),
      },
      {
        accessorFn: countFailedRequests,
        enableSorting: false,
        header: t("v2.adp.change_batches.column_headers.errors"),
      },
      { cell: ViewBatchDetails, enableSorting: false, header: "", id: "actions" },
    ],
    [t],
  )
}
