import { ListProps } from "react-virtualized"

import {
  changeActiveTab,
  changeColorCodingField,
  closeColorPicker,
  ColorCode as ColorCodeType,
  openColorPicker,
  toggleColorCodingEnabled,
} from "v2/redux/slices/VisualizationSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

type EditColorClickHandler = (
  a1: ColorCodeType,
  a2: { top: number; left: number },
  a3: string,
) => void

function useColorCodingActions() {
  const dispatch = useAppDispatch()

  const showColorPicker = useAppSelector((state) => state.visualization.showColorPicker)
  const handleColorCodingToggleClick = () => dispatch(toggleColorCodingEnabled())
  const handleTabClick = (tabType: "card" | "edge") => dispatch(changeActiveTab({ tabType }))
  const handleColorCodingFieldChange: React.ChangeEventHandler<HTMLSelectElement> = (ev) =>
    dispatch(changeColorCodingField({ field: ev.currentTarget.value }))
  const handleCloseColorPicker = () => dispatch(closeColorPicker())
  const handleEditColorClick: EditColorClickHandler = (themeOption, position, color) => {
    const updatedPosition = { ...position, top: position.top }
    dispatch(openColorPicker(updatedPosition, color, themeOption))
  }
  const handleScroll: ListProps["onScroll"] = () => {
    if (showColorPicker) handleCloseColorPicker()
  }

  return {
    handleCloseColorPicker,
    handleColorCodingFieldChange,
    handleColorCodingToggleClick,
    handleEditColorClick,
    handleScroll,
    handleTabClick,
  }
}

export { useColorCodingActions, EditColorClickHandler }
