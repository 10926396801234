import { ControlProps } from "@jsonforms/core"
import React, { ChangeEvent, FC } from "react"

const JsonNestedTextInput: FC<ControlProps> = ({
  data,
  id,
  handleChange,
  label,
  path,
  visible,
}) => {
  const handleChangeEvent = (event: ChangeEvent<HTMLInputElement>) =>
    handleChange(path, event.target.value)

  if (!visible) return null

  return (
    <div className="input-group input-group-nested">
      <label htmlFor={id}>{label}</label>
      <input className="input" name={id} onChange={handleChangeEvent} type="text" value={data} />
    </div>
  )
}

export { JsonNestedTextInput }
