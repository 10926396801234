import { TFunction } from "i18next"
import fp from "lodash/fp"
import { Dispatch, useCallback, useMemo, useReducer } from "react"
import { useTranslation } from "react-i18next"

import { AddonRoleAttributes, SaveRoleInput } from "types/graphql"
import { AddonRole, Role, RoleScope } from "types/graphql.enums"
import { useSaveRoleMutation } from "v2/redux/GraphqlApi"

import {
  ErrorSet,
  InitialRoleInput,
  ReducerState,
  RoleInputAction,
  RoleItem,
  RoleItemUpdate,
  RoleKey,
} from "./types"

const EMPTY_ERROR_SET: ErrorSet = {}
const ALL_ADDONS: string[] = Object.values(AddonRole)
const isAddonRole = (name: string): name is AddonRole => ALL_ADDONS.includes(name)

export function useRoleInput(initialArg: InitialRoleInput) {
  const { t } = useTranslation()
  const [state, dispatch] = useReducer(roleInputReducer, initialArg, initializer)

  const clear = useCallback(() => dispatch({ type: "roleInput/cleared" }), [dispatch])
  const toggle = useCallback(
    (addonRole: RoleKey) =>
      isAddonRole(addonRole) && dispatch({ type: "roleInput/toggled", payload: addonRole }),
    [dispatch],
  )
  const update = useCallback(
    (attributes: RoleItemUpdate) => dispatch({ type: "roleInput/updated", payload: attributes }),
    [dispatch],
  )
  const pickPerson = useCallback(
    (payload: { id: string; name: string }) =>
      dispatch({ type: "roleInput/pickedPerson", payload }),
    [dispatch],
  )

  const { checked, byKey, hadPersonInitially, person, submitted } = state
  const isChecked = useCallback((role: AddonRole) => checked.includes(role), [checked])
  const getRoleState = useCallback((role: RoleKey) => byKey[role], [byKey])
  const getAddonRoles = useCallback(() => selectAddonRoles(checked, byKey), [byKey, checked])
  const errorSet = useMemo(() => validate(state, t), [state, t])
  const isValid = fp.isEmpty(errorSet)
  const { submit, isLoading } = useSubmitFn(
    dispatch,
    getRoleState(state.baseKey),
    getAddonRoles(),
    person,
    isValid,
  )

  return {
    clear,
    errorSet: submitted ? errorSet : EMPTY_ERROR_SET,
    getAddonRoles,
    getRoleState,
    hadPersonInitially,
    isChecked,
    isValid,
    person,
    pickPerson,
    submit,
    isLoading,
    toggle,
    update,
  }
}

const useSubmitFn = (
  dispatch: Dispatch<RoleInputAction>,
  baseRole: RoleItem | undefined,
  addonRoles: AddonRoleAttributes[],
  person: ReducerState["person"],
  isValid: boolean,
) => {
  const [mutate, { isLoading }] = useSaveRoleMutation()

  const submit = useCallback(async () => {
    dispatch({ type: "roleInput/submitted" })

    if (!person || !baseRole || !isValid) return undefined

    const input: SaveRoleInput = {
      addonRoles,
      linkKeys: doesScopeTypeSupportLinks(baseRole.scopeType) ? baseRole.linkKeys : [],
      scopeType: baseRole.scopeType,
      personId: person.id,
      role: baseRole.role as Role,
    }

    return mutate({ input }).unwrap()
  }, [addonRoles, baseRole, dispatch, mutate, isValid, person])

  return { submit, isLoading }
}

const roleInputReducer = (
  state: ReducerState,
  { type, payload }: RoleInputAction,
): ReducerState => {
  switch (type) {
    case "roleInput/cleared":
      return { ...state, ...state.initial }
    case "roleInput/submitted":
      return { ...state, submitted: true }
    case "roleInput/toggled":
      return { ...state, checked: toggleChecked(state.checked, payload) }
    case "roleInput/pickedPerson":
      return { ...state, person: { ...payload, id: normalizedPersonId(payload.id) } }
    case "roleInput/updated":
      // eslint-disable-next-line no-underscore-dangle
      return fp.update(["byKey", payload.role], fp.assign(fp.__, payload), state)
    default:
      throw new Error("Invalid action type")
  }
}

function initializer(initialState: InitialRoleInput) {
  const state: Omit<ReducerState, "initial"> = {
    baseKey: initialState.base.role,
    byKey: { [initialState.base.role]: initialState.base },
    addonKeys: initialState.addons.map((role) => role.role),
    checked: [],
    hadPersonInitially: false,
    person: undefined,
    submitted: false,
  }

  if (initialState.personId && initialState.personName) {
    state.person = { id: normalizedPersonId(initialState.personId), name: initialState.personName }
    state.hadPersonInitially = true
  }

  initialState.addons.forEach((el) => {
    if (el.availableScopeTypes.includes(el.scopeType)) {
      state.byKey[el.role] = { ...el, scopeType: el.scopeType }
    } else if (el.availableScopeTypes.length > 0) {
      state.byKey[el.role] = { ...el, scopeType: el.availableScopeTypes[0] }
    } else {
      throw new Error("An addon role *must* have available scope types")
    }
    state.addonKeys.push(el.role)
    if (el.hasRole) state.checked.push(el.role)
  })

  return { ...state, initial: state }
}

const selectAddonRoles = (checked: RoleKey[], byKey: ReducerState["byKey"]) => {
  const addons: AddonRoleAttributes[] = []
  fp.props(checked, byKey).forEach((roleState) => {
    if (isAddonRole(roleState.role)) {
      addons.push({
        role: roleState.role,
        scopeType: roleState.scopeType,
        linkKeys: doesScopeTypeSupportLinks(roleState.scopeType) ? roleState.linkKeys : [],
      })
    }
  })

  return addons
}

const toggleChecked = (checked: RoleKey[], key: RoleKey) =>
  checked.includes(key) ? fp.without([key], checked) : fp.union([key], checked)

function validate(state: ReducerState, t: TFunction) {
  const errorSet: ErrorSet = {}

  if (!state.person) errorSet.person = t("v2.defaults.blank_validation")

  const relevantKeys = [...state.checked, state.baseKey]
  relevantKeys.forEach((key) => {
    const role = state.byKey[key]
    if (role && role.scopeType === RoleScope.LinkedSubordinates && fp.isEmpty(role.linkKeys))
      errorSet[key] = t("v2.defaults.blank_validation")
  })

  return errorSet
}

const normalizedPersonId = (id: unknown) =>
  `${id}`.startsWith("person") ? `${id}` : `person_${id}`

const doesScopeTypeSupportLinks = (roleScope: RoleScope) =>
  roleScope === RoleScope.LinkedSubordinates
