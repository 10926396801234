// @see styles/decorations.scss
// Note: This is written in a way that expects the SVG to already have <defs>
const defineCardBackgroundLinearGradient = (
  svg: d3.Selection<SVGElement>,
): d3.Selection<SVGElement> => {
  const linearGradient = svg
    .select("defs")
    .append("linearGradient")
    .attr("id", "node-background-linear-gradient")
    .attr("x1", "0%")
    .attr("x2", "0%")
    .attr("y1", "0%")
    .attr("y2", "100%")

  linearGradient
    .append("stop")
    .attr("offset", "0%")
    .attr("stop-color", "rgba(12, 20, 75, 0.00)")
    .attr("stop-opacity", 1)

  linearGradient
    .append("stop")
    .attr("offset", "100%")
    .attr("stop-color", "rgba(12, 20, 75, 0.02)")
    .attr("stop-opacity", 1)

  return svg
}

export default defineCardBackgroundLinearGradient
