import React from "react"

function Borders() {
  return (
    <div className="cols-1 gap-4 grid">
      <p className="mb-0 mt-4">
        Focus indicator for non-bordered focusable elements: <code>focus</code>
      </p>
      <button type="button" className="btn focus w-full bg-transparent">
        Main Focus
      </button>

      <p className="mb-0 mt-4">
        Error indicator for non-bordered focusable elements: <code>border--error</code>
      </p>
      <button type="button" className="btn border--error w-full bg-transparent">
        Main Error
      </button>

      <p className="mb-0 mt-4">
        Main border style for elements: <code>border--main</code>
      </p>
      <button type="button" className="btn border--main w-full bg-transparent">
        Border Main
      </button>

      <p className="mb-0 mt-4">
        Hover style for bordered elements: <code>border--elevation</code>
      </p>
      <button type="button" className="btn border--elevation w-full bg-transparent">
        Border Elevation
      </button>

      <p className="mb-0 mt-4">
        Focus style for bordered elements: <code>border--focus</code>
      </p>
      <button type="button" className="btn border--focus w-full bg-transparent">
        Border Focus
      </button>

      <p className="mb-0 mt-4">
        Error style for bordered elements: <code>border--error</code>
      </p>
      <button type="button" className="btn border--error w-full bg-transparent">
        Border Error
      </button>

      <p className="mb-0 mt-4">
        Main elevation style for elements: <code>elevation</code>
      </p>
      <button type="button" className="btn elevation w-full bg-transparent">
        Elevation
      </button>

      <p className="mb-0 mt-4">
        Alternative hover style for secondary buttons: <code>elevation--secondary</code>
      </p>
      <button type="button" className="btn elevation--secondary w-full bg-transparent">
        Elevation Secondary
      </button>

      <p className="mb-0 mt-4">
        Elevation style for tooltips and popovers: <code>elevation--overlay</code>
      </p>
      <button type="button" className="btn elevation--overlay w-full bg-transparent">
        Elevation Overlay
      </button>

      <p className="mb-0 mt-4">
        Alternative hover style for elements: <code>elevation--bottom</code>
      </p>
      <button type="button" className="btn elevation--bottom w-full bg-transparent">
        Elevation Bottom
      </button>
    </div>
  )
}

export default Borders
