import { globals } from "../constants/options"
import { NodeData } from "../node/types"

interface Point {
  x: number
  y: number
}

interface NodesByDepth {
  [key: number]: NodeData[]
}

interface DepthInfo {
  depth: string
  visualShift: number
  minX: number
  maxX: number
  minY: number
  maxY: number
}

/**
 * Calculates the minimum and maximum X and Y coordinates for a given depth level.
 */
const depthWithMinMax = (
  depth: string,
  nodes: NodeData[],
  standardNodeHeight: number,
): DepthInfo => {
  const xs = nodes.map((node) => node.x0)
  const ys = nodes.map((node) => node.y0)
  const visualShift = Math.max(...nodes.map((node) => node.visualShift ?? 0))

  return {
    depth,
    visualShift,
    minX: Math.min(...xs) - globals.nodeWidth / 2,
    maxX: Math.max(...xs) + globals.nodeWidth / 2,
    minY: Math.min(...ys),
    maxY: Math.max(...ys) + standardNodeHeight + globals.colorGroupPadding / 2,
  }
}

/**
 * Builds the left side boundary points for the chart section grouping, ordered top to bottom.
 */
const buildLeftSidePoints = (depthsInfo: DepthInfo[]): Point[] => {
  const leftSide: Point[] = []

  depthsInfo.forEach((pointData, i) => {
    const previousPoint = leftSide[leftSide.length - 1]
    const nextDepth = depthsInfo[i + 1]
    const yMin = previousPoint ? previousPoint.y : pointData.minY - globals.colorGroupPadding * 2
    const yMax = nextDepth
      ? pointData.maxY + (nextDepth.minY - pointData.maxY) / 2
      : pointData.maxY + globals.colorGroupPadding * 2
    leftSide.push({
      x: pointData.minX - pointData.visualShift - globals.colorGroupPadding,
      y: yMin,
    })
    leftSide.push({
      x: pointData.minX - pointData.visualShift - globals.colorGroupPadding,
      y: yMax,
    })
  })

  return leftSide.map((point) => ({ x: point.x, y: point.y - globals.colorGroupPadding * 2 }))
}

/**
 * Builds the right side boundary points for the chart section grouping.
 */
const buildRightSidePoints = (depthsInfo: DepthInfo[]): Point[] => {
  const rightSide: Point[] = []

  const reversedDepthsInfo = depthsInfo.slice().reverse()

  reversedDepthsInfo.forEach((pointData: DepthInfo, i) => {
    const previousPoint = rightSide[rightSide.length - 1]
    const previousDepth = reversedDepthsInfo[i - 1]
    const nextDepth = reversedDepthsInfo[i + 1]
    const yMin = nextDepth
      ? pointData.minY - (pointData.minY - nextDepth.maxY) / 2
      : pointData.minY - globals.colorGroupPadding * 2
    const yMax = previousPoint ? previousPoint.y : pointData.maxY + globals.colorGroupPadding * 2
    rightSide.push({
      x: pointData.maxX + (previousDepth?.visualShift ?? 0) + globals.colorGroupPadding,
      y: yMax,
    })
    rightSide.push({
      x: pointData.maxX + (previousDepth?.visualShift ?? 0) + globals.colorGroupPadding,
      y: yMin,
    })
  })

  return rightSide.map((point) => ({ x: point.x, y: point.y - globals.colorGroupPadding * 2 }))
}

const nodeDescendants = (d: NodeData): NodeData[] => {
  const descendants: NodeData[] = [d]

  const recurse = (currentNode: NodeData): void => {
    if (currentNode.children) {
      currentNode.children.forEach((child) => {
        descendants.push(child)
        recurse(child)
      })
    }
  }

  recurse(d)

  return descendants
}

/**
 * Generates a series of points that outline a chart section grouping based on
 * node depths. This function is used to draw an outline around a section of
 * the chart. The points are generated from the top-left in a counter clockwise
 * direction.
 */
const chartSectionGroupingPointsGenerator = (d: NodeData, standardNodeHeight: number): Point[] => {
  const branchByDepth = nodeDescendants(d).reduce((acc: NodesByDepth, node: NodeData) => {
    acc[node.depth] = acc[node.depth] || []
    acc[node.depth].push(node)
    return acc
  }, {})

  const depthsInfo = Object.entries(branchByDepth).map(([depth, nodes]) =>
    depthWithMinMax(depth, nodes, standardNodeHeight),
  )

  return [...buildLeftSidePoints(depthsInfo), ...buildRightSidePoints(depthsInfo)]
}

export default chartSectionGroupingPointsGenerator
