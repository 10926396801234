import type { Meta, StoryObj } from "@storybook/react"
import React, { useState } from "react"

import { FormDropdown } from "./FormDropdown"

const meta: Meta<typeof FormDropdown> = {
  title: "Shared/FormDropdown",
  component: FormDropdown,
  decorators: [
    (story, context) => {
      const [selected, setSelected] = useState(context.args.selected)
      const onSelect = (value: string) => setSelected(value)

      return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div style={{ width: "300px" }}>
            {/* NOTE: This rule will need an updated TS config for storybook */}
            {/* Until then we drop the <string> and add a ts ignore */}
            {/* const meta: Meta<typeof FormDropdown<string>> */}
            {/* @ts-ignore */}
            {story({ ...context, args: { ...context.args, selected, onSelect } })}
          </div>
        </div>
      )
    },
  ],
}

export default meta
type Story = StoryObj<typeof FormDropdown>

const longOptionText =
  "Really, really, really, really, really, really, really, really, really, really, really, really, really, long option."

export const Main: Story = {
  args: {
    label: "Basic Dropdown",
    options: [
      { label: "Option 1", value: "option1" },
      { label: "Option 2", value: "option2" },
      { label: "Option 3", value: "option3" },
    ],
    selected: "option1",
  },
}

export const WithImages: Story = {
  args: {
    label: "Dropdown With Images",
    options: [
      {
        label: "Option 1",
        value: "option1",
        iconPath: "https://via.placeholder.com/15/0000FF/0000FF",
        labelDescription: "A description of option 1",
      },
      {
        label: "Option 2",
        value: "option2",
        iconPath: "https://via.placeholder.com/15/FF0000/FF0000",
        labelDescription: "A description of option 2",
      },
      {
        label: "Option 3",
        value: "option3",
        iconPath: "https://via.placeholder.com/15/00FF00/00FF00",
        labelDescription: "A description of option 3",
      },
    ],
    selected: "option1",
  },
}

export const LongText: Story = {
  args: {
    label: "Really, really, really, really, really, really, really, long label.",
    options: [
      {
        label: longOptionText,
        value: "option1",
      },
      { label: "Option 2", value: "option2" },
      { label: "Option 3", value: "option3" },
    ],
    selected: "option1",
  },
}
