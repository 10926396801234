import React from "react"

import { ApprovalActions } from "v2/react/components/approvals/ApprovalActions"
import { ApprovalActionType } from "v2/react/shared/status/statusUtils"
import { setTempSelectedAction, toggleStatusModal } from "v2/redux/slices/ApprovalSlice"
import { selectCurrentAction } from "v2/redux/slices/ApprovalSlice/approvalSelectors"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

function ChartApprovalActions() {
  const dispatch = useAppDispatch()
  const selectedAction = useAppSelector(selectCurrentAction)

  const openActionModal = (action: ApprovalActionType) => {
    dispatch(setTempSelectedAction(action))
    dispatch(toggleStatusModal(true))
  }

  return <ApprovalActions selectedAction={selectedAction} onStatusSelection={openActionModal} />
}

export { ChartApprovalActions }
