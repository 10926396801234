import { flow } from "lodash"

export function downloadCsv(csvContent: string, csvDownloadName = "data") {
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" })
  const url = URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.setAttribute("href", url)
  link.setAttribute("download", `${csvDownloadName}.csv`)
  link.style.visibility = "hidden"
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

function wrapInDoubleQuotes(value?: string) {
  return `"${value ?? ""}"`
}

function maybePrependSingleQuote(value?: string) {
  if (typeof value === "string" && /^[\n\t\r@=+-]/.test(value)) {
    return `'${value ?? ""}`
  }
  return value
}

function escapeDoubleQuotes(value?: string | number) {
  if (typeof value !== "string") return String(value)
  return value?.replace(/"/g, '""') ?? ""
}

export function sanitizeCsvValue(value?: string | number) {
  // This is the recommended sanitization approach in order to avoid
  // CSV injection attacks. See https://owasp.org/www-community/attacks/CSV_Injection.
  return flow(escapeDoubleQuotes, maybePrependSingleQuote, wrapInDoubleQuotes)(value)
}
