import { createAction, createEntityAdapter, createSlice, EntityId } from "@reduxjs/toolkit"

import { RootState } from "v2/redux/store"

import {
  BeginGeneratingFieldSuggestionArg,
  CancelGeneratingFieldSuggestionArg,
  FieldSuggestionEntry,
} from "./FieldSuggestionSlice/types"

const fieldsAdapter = createEntityAdapter<FieldSuggestionEntry>()
export const fieldsSelectors = fieldsAdapter.getSelectors(
  (state: RootState) => state.fieldSuggestion,
)
const initialState = {
  ...fieldsAdapter.getInitialState(),
  registeredSubjects: [] as EntityId[],
}

export const beginGeneratingFieldSuggestion = createAction<BeginGeneratingFieldSuggestionArg>(
  "fieldSuggestions/beginGeneratingFieldSuggestion",
)
export const beginInitializingFieldSuggestion = createAction<BeginGeneratingFieldSuggestionArg>(
  "fieldSuggestion/beginInitializingFieldSuggestion",
)
export const cancelGeneratingFieldSuggestion = createAction<CancelGeneratingFieldSuggestionArg>(
  "fieldSuggestion/cancelGeneratingFieldSuggestion",
)

export const FieldSuggestionSlice = createSlice({
  name: "fieldSuggestions",
  initialState,
  reducers: {
    subscribedField: fieldsAdapter.addOne,
    unsubscribedField: fieldsAdapter.removeOne,
    updatedField: fieldsAdapter.updateOne,
  },
})

export const { subscribedField, unsubscribedField, updatedField } = FieldSuggestionSlice.actions
export * from "./FieldSuggestionSlice/types"
export * from "./FieldSuggestionSlice/helpers"
