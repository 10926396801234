import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import { Recruiter } from "types/graphql"
import { RecruiterList } from "v2/react/components/approvals/RecruiterList/RecruiterList"

interface RecruiterProps {
  onShowUpdateModal: React.MouseEventHandler<HTMLButtonElement>
  recruiters: Recruiter[]
  approvalState: string | null | undefined
  externalStatus: string
}

function Recruiters({
  onShowUpdateModal,
  recruiters,
  approvalState,
  externalStatus,
}: RecruiterProps) {
  const { t } = useTranslation()
  const closedStates = ["closed", "canceled"]
  return (
    <div className="module-card mb-0">
      <div className="module-card__header">
        <p className="mb-0 text-base-bold">{t("v2.job_requisitions.recruiters.recruiters")}</p>
        {!window.gon.limited_or_nonadmin_manager &&
          !closedStates.includes(approvalState || externalStatus) && (
            <button className="btn--sm btn--secondary" onClick={onShowUpdateModal} type="button">
              {t("v2.defaults.edit")}
            </button>
          )}
      </div>
      <div className={cn("module-card__body gap-0", { "border-none !p-0": recruiters.length })}>
        {!recruiters.length && <p>{t("v2.job_requisitions.recruiters.recruiters_empty")}</p>}
        {recruiters.length > 0 && <RecruiterList recruiters={recruiters} />}
      </div>
    </div>
  )
}

export { Recruiters }
