import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { ReactNode } from "react"

import { prepareIconClass } from "v2/react/utils/misc"

interface EmptyStateProps {
  icon: string
  note: ReactNode
}

function EmptyState({ icon, note }: EmptyStateProps) {
  return (
    <div className="flex-col items-center gap-4 pb-6 pt-8 flex">
      <div className="justify-center flex">
        <div className="icon--round">
          <FontAwesomeIcon icon={prepareIconClass(icon)} className="fa-lg" />
        </div>
      </div>
      <div className="max-w-[16rem] justify-center text-center text-neutral-64 flex">{note}</div>
    </div>
  )
}

export { EmptyState }
