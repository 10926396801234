const defineCardPulseShadow = (svg: d3.Selection<SVGElement>): d3.Selection<SVGElement> => {
  const filter = svg
    .select("defs")
    .append("filter")
    .attr("id", "card-pulse-shadow")
    .attr("x", "-20%")
    .attr("y", "-20%")
    .attr("width", "140%")
    .attr("height", "140%")

  filter.append("feGaussianBlur").attr("in", "SourceGraphic").attr("stdDeviation", "2.5")

  return svg
}

export default defineCardPulseShadow
