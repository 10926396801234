import cn from "classnames"
import React, { ReactNode } from "react"

import { useTruncatedTooltip } from "v2/react/hooks/useTruncatedTooltip"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"

const TruncatedText = ({
  displayClasses,
  displayContent,
  tooltipContent,
}: {
  displayClasses?: string
  displayContent: ReactNode
  tooltipContent: ReactNode
}) => {
  const { ref, showTooltip, setShowTooltip, determineTooltip } =
    useTruncatedTooltip<HTMLParagraphElement>()

  return (
    <Tooltip open={showTooltip}>
      <TooltipTrigger onMouseEnter={determineTooltip} onMouseLeave={() => setShowTooltip(false)}>
        <p className={cn("truncate", displayClasses)} ref={ref}>
          {displayContent}
        </p>
      </TooltipTrigger>
      <TooltipContent className="react-tooltip-content react-tooltip-content--sm">
        {tooltipContent}
      </TooltipContent>
    </Tooltip>
  )
}

export { TruncatedText }
