import { motion } from "framer-motion"
import React, { ReactNode } from "react"

import { opaqueScale } from "v2/react/utils/framerAnimationVariants"

export const MotionOpaqueScale = ({
  classNames,
  show,
  children,
}: {
  classNames?: string
  show: boolean
  children: ReactNode
}) =>
  show && (
    <motion.div
      className={classNames}
      initial="hidden"
      animate="shown"
      exit="hidden"
      variants={opaqueScale}
    >
      {children}
    </motion.div>
  )

export const MotionOpaqueBasic = ({
  classNames,
  show,
  children,
}: {
  classNames?: string
  show: boolean
  children: ReactNode
}) =>
  show && (
    <motion.div
      className={classNames}
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: {
          opacity: { delay: 0.2, duration: 0.2 },
        },
      }}
      exit={{
        opacity: 0,
      }}
    >
      {children}
    </motion.div>
  )

export const MotionLayout = ({
  children,
  classNames,
  delay,
  duration,
  layout,
}: {
  children: ReactNode
  classNames?: string
  delay?: number
  duration?: number
  layout: true | "position" | "preserve-aspect" | "size"
}) => (
  <motion.div
    className={classNames}
    layout={layout}
    transition={{
      layout: {
        delay: delay || 0.2,
        duration: duration || 0.4,
      },
    }}
  >
    {children}
  </motion.div>
)
