import React from "react"

import { Link } from "v2/react/shared/navigation/Links/Link"
import { UrlHelper } from "v2/react/utils/urls"

const PersonLink = ({ id, value }: { id: string; value: string }) => (
  <Link to={UrlHelper.personProfilePath(id)} newTab>
    {value}
  </Link>
)

export { PersonLink }
