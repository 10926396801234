import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import { Maybe } from "graphql/jsutils/Maybe"
import React, { ReactNode, useState } from "react"
import { useTranslation } from "react-i18next"

import { Candidate, CrossCandidacy } from "types/graphql"
import { HighRiskTooltip } from "v2/react/components/succession/SuccessionPlanPanel/shared/HighRiskTooltip"
import { MotionLayout } from "v2/react/components/succession/SuccessionPlanPanel/shared/MotionWrappers"
import { CandidateAttributeList } from "v2/react/components/succession/SuccessionPlanPanel/Summary/CandidateAttributeList"
import { CandidateNoteForm } from "v2/react/components/succession/SuccessionPlanPanel/Summary/CandidateNoteForm"
import { EditDeleteMenu } from "v2/react/components/succession/SuccessionPlanPanel/Summary/EditDeleteMenu"
import { Avatar } from "v2/react/shared/icons/Avatar"
import { BlueIcon } from "v2/react/shared/icons/BlueIcon"
import { removeHtmlTagsAndTrim, sanitizeParse } from "v2/react/utils/sanitizeParse"
import { UrlHelper } from "v2/react/utils/urls"
import { useDeleteSuccessionPlanCandidateMutation } from "v2/redux/GraphqlApi/SuccessionApi"

interface CandidateCardProps {
  candidate: Candidate
  canEditSuccessionPlan: boolean
  positionId: string
  showCandidateNotes: boolean
}

const CandidateCard = ({
  candidate,
  canEditSuccessionPlan,
  positionId,
  showCandidateNotes,
}: CandidateCardProps) => {
  const { t } = useTranslation()
  const person = candidate.person
  const [showEditor, setShowEditor] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [removeCandidate] = useDeleteSuccessionPlanCandidateMutation()

  const handleDeleteCandidate = () => {
    setShowMenu(false)
    removeCandidate({
      candidateId: candidate.id,
      positionId,
    })
  }

  const handleEditDetails = () => {
    setShowMenu(false)
    setShowEditor(true)
  }

  // Internal Candidate
  if (candidate.withinOrg && person) {
    return (
      <CandidateWrapper
        crossCandidacies={candidate.crossCandidacies || []}
        highFlightRisk={candidate.person ? candidate.flightRiskLevel === "high" : false}
        sortOrder={candidate.sortOrder}
      >
        <div className="flex-col gap-4 p-4 flex">
          <div className="justify-between flex">
            <MotionLayout layout>
              <div className="items-center gap-2 flex">
                <Avatar person={person} size="med-lg" />
                <div>
                  <a
                    href={UrlHelper.personProfilePath(person.id)}
                    className="link person-link cursor-pointer p-0 text-left"
                  >
                    {person.name}
                  </a>
                  <p className="text-sm text-neutral-64">
                    {candidate.primaryPositionTitle ||
                      t("v2.succession_plan_panel.candidate.position_none")}
                  </p>
                  <p className="text-sm text-neutral-64">
                    {t("v2.succession_plan_panel.candidate.years", {
                      count: person.employmentTenure || 0,
                    })}
                  </p>
                </div>
              </div>
            </MotionLayout>
            {canEditSuccessionPlan && (
              <button
                className="btn--icon btn--ghost tooltip tooltip-right opacity-0 transition-all duration-150 ease-out group-hover/candidate:opacity-100"
                type="button"
                onClick={handleDeleteCandidate}
              >
                <FontAwesomeIcon icon={["far", "times"]} />
                <div className="tooltip-content tooltip-content--sm">
                  {t("v2.succession_plan_panel.candidate.remove")}
                </div>
              </button>
            )}
          </div>

          <CandidateAttributeList candidate={candidate} positionId={positionId} />

          {showCandidateNotes && (
            <CandidateNoteForm
              candidateId={candidate.id}
              info={candidate.additionalInfo || ""}
              internalCandidate
              positionId={positionId}
              setShowEditor={setShowEditor}
              showEditor={showEditor}
            />
          )}
        </div>
      </CandidateWrapper>
    )
  }

  // External Candidate
  return (
    <CandidateWrapper
      crossCandidacies={candidate.crossCandidacies || []}
      highFlightRisk={false}
      sortOrder={candidate.sortOrder}
    >
      {!showEditor && (
        <div className="justify-between p-4 flex">
          <MotionLayout layout="preserve-aspect">
            <div className="min-w-0 grid-cols-[auto_1fr] gap-2 grid">
              <div className="flex">
                <BlueIcon icon={["far", "briefcase"]} size="med-lg" />
              </div>
              <div className="h-auto w-full min-w-0">
                <p>{candidate.outsiderName}</p>
                <div
                  className={cn("formatted-block text-sm-all text-sm text-neutral-64", {
                    truncate: !showCandidateNotes,
                  })}
                >
                  {showCandidateNotes
                    ? sanitizeParse(candidate.additionalInfo || "")
                    : removeHtmlTagsAndTrim(candidate.additionalInfo || "")}
                </div>
              </div>
            </div>
          </MotionLayout>

          <EditDeleteMenu
            buttonClasses="btn--icon btn--ghost tooltip tooltip-right opacity-0 transition-all duration-150 ease-out group-hover/candidate:opacity-100"
            deleteButtonText={t("v2.succession_plan_panel.summary.actions.delete_candidate")}
            editButtonText={t("v2.succession_plan_panel.summary.actions.edit_candidate")}
            onDelete={handleDeleteCandidate}
            onEdit={handleEditDetails}
            setShowMenu={setShowMenu}
            showMenu={showMenu}
          />
        </div>
      )}

      {showEditor && (
        <CandidateNoteForm
          candidateId={candidate.id}
          candidateName={candidate.outsiderName || ""}
          info={candidate.additionalInfo || ""}
          internalCandidate={false}
          positionId={positionId}
          setShowEditor={setShowEditor}
          showEditor={showEditor}
        />
      )}
    </CandidateWrapper>
  )
}

export { CandidateCard }

interface CandidateWrapperProps {
  children: ReactNode
  crossCandidacies: CrossCandidacy[]
  highFlightRisk: boolean
  sortOrder: Maybe<number>
}

const CandidateWrapper = ({
  children,
  crossCandidacies,
  highFlightRisk,
  sortOrder,
}: CandidateWrapperProps) => (
  <div className="group/candidate h-auto w-full border-b-neutral-8 flex">
    <div
      className={cn(
        "tooltip tooltip-side-align-right w-6 shrink-0 place-content-center border-r-neutral-8 bg-neutral-3 grid",
        {
          "risky bg-status-caution-light": highFlightRisk || crossCandidacies.length > 0,
        },
      )}
    >
      <MotionLayout
        classNames="candidate__order"
        delay={0.1}
        duration={0.2}
        layout="preserve-aspect"
      >
        {sortOrder}
      </MotionLayout>

      {(highFlightRisk || crossCandidacies.length > 0) && (
        <div className="tooltip-content">
          <HighRiskTooltip crossCandidacies={crossCandidacies} highFlightRisk={highFlightRisk} />
        </div>
      )}
    </div>
    <div className="candidate__body h-auto w-full min-w-0">{children}</div>
  </div>
)
