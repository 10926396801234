import { changeColorInCode } from "v2/redux/slices/VisualizationSlice"
import { ReferenceColorCode } from "v2/redux/slices/VisualizationSlice/types"
import { RootState } from "v2/redux/store"

type UpdateColorInCodePayload = {
  type: "edge" | "card"
  field: string
  model?: ReferenceColorCode
  color: string
}

/**
 * @public
 * @returns {function} an async thunk which toggles the node container to/from
 *   the given chart section. This executes a network request which stores the
 *   change in the user's preference. Resolves to nothing.
 */
function asyncUpdateColorInCode({ type, field, model, color }: UpdateColorInCodePayload) {
  return async (dispatch: (action: unknown) => unknown, getState: () => RootState) => {
    const { gon, $ } = window
    const colorCode = model || getState().visualization.colorPickerColorCode
    if (!colorCode) return

    dispatch(changeColorInCode({ type, field, id: colorCode.id, color }))

    const companyId = gon.current_company_id
    const data = JSON.stringify({
      color,
      color_type: type === "card" ? "secondary" : "primary",
      group_key: field,
      value: `${colorCode.true_value || ""}`.toLowerCase(),
    })

    $.ajax({
      data,
      contentType: "application/json",
      url: `/api/app/v1/companies/${companyId}/color_codings/update.json`,
      type: "PUT",
      // eslint-disable-next-line no-console
      error: (err: unknown) => console.log("ERR", err),
    })
  }
}

export { asyncUpdateColorInCode }
