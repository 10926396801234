import React from "react"
import { useTranslation } from "react-i18next"

import { FeatureFlags } from "types/graphql"
import { ProfilePanelMode } from "types/graphql.enums"
import { ModuleListItem } from "v2/react/components/orgChart/ProfilePanel/ModuleListItem"
import { useSelectedBox } from "v2/react/components/orgChart/ProfilePanel/Settings/hooks/useSelectedBox"
import { AccordionModulePanel } from "v2/react/shared/layout/AccordionModulePanel"
import { sanitizeParse } from "v2/react/utils/sanitizeParse"
import { useAppSelector } from "v2/redux/store"

interface BioDescriptionProps {
  featureFlags: FeatureFlags
}

function BioDescription({ featureFlags }: BioDescriptionProps) {
  const person = useAppSelector((state) => state.profilePanel.person)
  const { currentBox, toggleBox } = useSelectedBox("bio", ProfilePanelMode.Person)
  const { t } = useTranslation()

  if (!currentBox || !person) return null
  if (!featureFlags.bio || !person.bio) return null

  const headerContent = <div className="title">{t("v2.profile_panel.bio")}</div>

  return (
    <AccordionModulePanel
      headerContent={headerContent}
      additionalToggleAction={toggleBox}
      collapsed={currentBox.collapsed}
      title={currentBox.name}
    >
      <ModuleListItem>
        <div>{sanitizeParse(person.bio)}</div>
      </ModuleListItem>
    </AccordionModulePanel>
  )
}

export { BioDescription }
