import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import { includes } from "lodash/fp"
import React, { SyntheticEvent } from "react"

import { IconWrapper } from "v2/react/shared/collection/menus/Dropdown/DropdownItem/IconWrapper"

const RIGHT_ARROW_KEY_CODE = "39"
const ENTER_KEY_CODE = "13"
const KEYS = [RIGHT_ARROW_KEY_CODE, ENTER_KEY_CODE]

function Trigger({
  onClick,
  asDropdownMenuLink,
  asLink,
  withIcon,
  linkTitle,
  smallButton,
}: {
  onClick: (e: SyntheticEvent) => void
  asDropdownMenuLink: boolean
  asLink?: boolean
  withIcon?: boolean
  linkTitle?: string
  smallButton?: boolean
}) {
  if (asLink) {
    return (
      <a
        href="/"
        className="text-center"
        onClick={onClick}
        onKeyUp={(e) => (includes(e.key, KEYS) ? onClick(e) : null)}
      >
        {withIcon ? (
          <IconWrapper icon={<FontAwesomeIcon icon={["far", "paper-plane"]} />}>
            {linkTitle ?? "send_to_ats".t("ats")}
          </IconWrapper>
        ) : (
          linkTitle ?? "send_to_ats".t("ats")
        )}
      </a>
    )
  }
  if (asDropdownMenuLink) {
    return (
      <a
        href="/"
        className="dropdown-menu-link"
        onClick={onClick}
        onKeyUp={(e) => (includes(e.key, KEYS) ? onClick(e) : null)}
      >
        {withIcon ? (
          <IconWrapper icon={<FontAwesomeIcon icon={["far", "paper-plane"]} />}>
            {"send_to_ats".t("ats")}
          </IconWrapper>
        ) : (
          "send_to_ats".t("ats")
        )}
      </a>
    )
  }
  return (
    <button
      className={cn(smallButton ? "btn--sm" : "btn--large", "btn--primary")}
      onClick={onClick}
      type="button"
    >
      {"send_to_ats".t("ats")}
    </button>
  )
}

export { Trigger }
