import { AnyAction, PayloadAction } from "@reduxjs/toolkit"

import { cancelGeneratingFieldSuggestion } from "v2/redux/slices/FieldSuggestionSlice"
import { entityEventOccurred } from "v2/redux/slices/NotificationSlice"

import {
  BaseSuggestionEntry,
  FieldSuggestionEntry,
  MatchFieldSuggestionEventArg,
  MatchGenerateFieldSuggestionCancelationArg,
  MessageData,
  SocketMessage,
  SocketMessageSchema,
  StringFieldEventPayload,
  StringFieldEventPayloadSchema,
  StringSuggestionEntry,
  TagsFieldEventPayload,
  TagsFieldEventPayloadSchema,
  TagsSuggestionEntry,
} from "./types"

export const isSocketMessage = (thing: unknown): thing is SocketMessage =>
  SocketMessageSchema.safeParse(thing).success

export const isStringEntry = (thing: FieldSuggestionEntry): thing is StringSuggestionEntry =>
  thing.type === "string"
export const isStringEventPayload = (thing: unknown): thing is StringFieldEventPayload =>
  StringFieldEventPayloadSchema.safeParse(thing).success

export const isTagsEntry = (thing: FieldSuggestionEntry): thing is TagsSuggestionEntry =>
  thing.type === "tags"
export const isTagsPayload = (thing: unknown): thing is TagsFieldEventPayload =>
  TagsFieldEventPayloadSchema.safeParse(thing).success

/**
 * Returns a matcher which will match an `entityEventOccurred` action which:
 *
 * - Is for a field suggestion
 * - Has a matching `entityId` and `field`
 * - Has an `eventType` in `into`
 */
export function matchFieldSuggestionEvent({ entityId, field, into }: MatchFieldSuggestionEventArg) {
  return (action: AnyAction): action is PayloadAction<SocketMessage> => {
    if (!entityEventOccurred.match(action)) return false

    const parsed = SocketMessageSchema.safeParse(action.payload)
    if (!parsed.success) return false

    const { subjectId: entityIdInEvent, data } = parsed.data
    return entityIdInEvent === entityId && data.field === field && into.includes(data.eventType)
  }
}

export function matchGenerateFieldSuggestionCancelation({
  entityId,
  field,
}: MatchGenerateFieldSuggestionCancelationArg) {
  return (action: AnyAction): action is ReturnType<typeof cancelGeneratingFieldSuggestion> => {
    if (!cancelGeneratingFieldSuggestion.match(action)) return false

    const { entityId: entityIdInAction, field: actionField } = action.payload
    return entityIdInAction === entityId && actionField === field
  }
}

export function prepareUpdate(entry: FieldSuggestionEntry, { data }: SocketMessage) {
  if (isStringEventPayload(data) && isStringEntry(entry))
    return { ...deriveCommonByEventType(entry, data), ...withValues(data) }
  if (isTagsPayload(data) && isTagsEntry(entry))
    return { ...deriveCommonByEventType(entry, data), ...withValues(data) }
  return deriveCommonByEventType(entry, data)
}

const withValues = <Data extends MessageData>({ initializedValue, value }: Data) => ({
  initializedValue,
  value,
})

const deriveCommonByEventType = (entry: FieldSuggestionEntry, data: MessageData) => {
  const common: Partial<BaseSuggestionEntry> = { state: data.eventType }

  // Guard against out-of-order events for critical state values. These changes
  // should be "one way" and have an outsized effect on the UI.
  //
  // hasInitialized: false -> true - initial value was generated.
  // isAwaitingAction: true -> false - initial suggestion was accepted/declined
  if (!entry.hasInitialized && data.hasInitialized) common.hasInitialized = data.hasInitialized
  if (entry.isAwaitingAction && !data.isAwaitingAction)
    common.isAwaitingAction = data.isAwaitingAction

  return common
}
