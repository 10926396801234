import { useBudgetedBasePayTotal } from "./useBudgetedBasePayTotal"
import { useBudgetedVariablePays } from "./useBudgetedVariablePays"

/**
 * Computed total of budgeted pay for the position based on the position form's
 * context.
 */
function useBudgetedTotal() {
  const { current: totalBasePay } = useBudgetedBasePayTotal()
  const { current: budgetedVariablePays } = useBudgetedVariablePays()

  return budgetedVariablePays.reduce(
    (memo, budgetedVariablePay) => budgetedVariablePay.calculatedVariablePayAmount + memo,
    totalBasePay,
  )
}

export { useBudgetedTotal }
