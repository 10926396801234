import {
  JobLevelCollection,
  JobLevelCollectionQuery,
  JobLevelCollectionQueryVariables,
  JobLevelCreateInput,
  JobLevelCreateMutation,
  JobLevelDestroyInput,
  JobLevelDestroyMutation,
  JobLevelUpdateInput,
  JobLevelUpdateMutation,
} from "types/graphql"
import { GraphqlApi } from "v2/redux/GraphqlApi"
import { flatMutationOperation, queryOperation } from "v2/redux/utils/endpoints"

const invalidatesJobLevelTags = (jobLevelId: string) => [
  "JobLevel",
  { type: "JobLevel", id: jobLevelId },
]

const EMPTY_JOB_LEVEL_CONNECTION: JobLevelCollection = {
  collection: [],
  metadata: {
    totalPages: 0,
    totalCount: 0,
    currentPage: 0,
    limitValue: 0,
  },
}

const JobLevelApi = GraphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    jobLevelCollection: builder.query<JobLevelCollection, JobLevelCollectionQueryVariables>({
      query: queryOperation<JobLevelCollectionQueryVariables>("JobLevelCollection"),
      providesTags: (res) => {
        const nodeTags = (res?.collection ?? [])
          .filter(({ id }) => Boolean(id))
          .map(({ id }) => ({ type: "JobLevel", id: id as string }))
        return ["JobLevel", ...nodeTags]
      },
      transformResponse: (res: JobLevelCollectionQuery) =>
        res.jobLevels ?? EMPTY_JOB_LEVEL_CONNECTION,
    }),
    jobLevelCreate: builder.mutation<JobLevelCreateMutation, JobLevelCreateInput>({
      invalidatesTags: (res) => invalidatesJobLevelTags(res?.jobLevelCreate?.jobLevel?.id ?? ""),
      query: flatMutationOperation<JobLevelCreateInput>("JobLevelCreate"),
    }),
    jobLevelDestroy: builder.mutation<JobLevelDestroyMutation, JobLevelDestroyInput>({
      invalidatesTags: (res, _, arg) => invalidatesJobLevelTags(arg?.jobLevelId),
      query: flatMutationOperation<JobLevelDestroyInput>("JobLevelDestroy"),
    }),
    jobLevelUpdate: builder.mutation<JobLevelUpdateMutation, JobLevelUpdateInput>({
      invalidatesTags: (res) => invalidatesJobLevelTags(res?.jobLevelUpdate?.jobLevel?.id ?? ""),
      query: flatMutationOperation<JobLevelUpdateInput>("JobLevelUpdate"),
    }),
  }),
})

export const {
  useJobLevelCollectionQuery,
  useJobLevelCreateMutation,
  useJobLevelDestroyMutation,
  useJobLevelUpdateMutation,
} = JobLevelApi
