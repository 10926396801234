import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { CodeBlock, tomorrowNight } from "react-code-blocks"

function Badges() {
  const [removableBadges, setRemovableBadges] = useState([
    "Leonardo",
    "Donatello",
    "Raphael",
    "Michelangelo",
  ])

  const filterBadges = (badge: string) => {
    const filtered = removableBadges.filter((b) => b !== badge)
    setRemovableBadges(filtered)
  }

  return (
    <>
      <h1>Badges</h1>
      <div className="my-2">
        <p>
          Status badges in UI design must effectively convey relevant information and provide clear
          visual cues to guide user understanding. Through thoughtful use of typography, colors, and
          visual indicators, well-designed status labels enhance the user experience, ensuring users
          are informed and engaged with the interface.
        </p>
        <p className="pt-1">
          Because badges are used for interactable and non-interactable content displays, you will
          need to add the <code>hover</code> helper class to apply a hover effect to the element.
        </p>
      </div>
      <section className="my-8">
        <div className="my-2 gap-3 flex">
          <p className="badge--blue">Blue</p>
          <p className="badge--gray">Gray</p>
          <p className="badge--green">Green</p>
          <p className="badge--purple">Purple</p>
          <p className="badge--red">Red</p>
          <p className="badge--white">White</p>
          <p className="badge--yellow">Yellow</p>
        </div>
        <div className="my-2 gap-3 flex">
          <p className="badge--blue hover">Blue</p>
          <p className="badge--gray hover">Gray</p>
          <p className="badge--green hover">Green</p>
          <p className="badge--purple hover">Purple</p>
          <p className="badge--red hover">Red</p>
          <p className="badge--white hover">White</p>
          <p className="badge--yellow hover">Yellow</p>
        </div>
        <div className="col-span-5 my-4 font-mono text-base">
          <CodeBlock text={badgeExamples} theme={tomorrowNight} language="javascript" />
        </div>
      </section>

      <div className="my-2">
        <p>
          We also have a smaller badge style that is not as frequently used. Just apply a size class
          in addition to the badge color class. The link badge is formatted similarly to the status
          badges but is meant to indicate interactability.
        </p>
      </div>
      <section className="my-8">
        <div className="my-2 gap-3 flex">
          <p className="badge--blue badge--sm">Blue</p>
          <p className="badge--gray badge--sm">Gray</p>
          <p className="badge--green badge--sm">Green</p>
          <p className="badge--purple badge--sm">Purple</p>
          <p className="badge--red badge--sm">Red</p>
          <p className="badge--white badge--sm">White</p>
          <p className="badge--yellow badge--sm">Yellow</p>
        </div>
        <div className="my-2 gap-3 flex">
          <p className="badge--blue badge--sm hover">Blue</p>
          <p className="badge--gray badge--sm hover">Gray</p>
          <p className="badge--green badge--sm hover">Green</p>
          <p className="badge--purple badge--sm hover">Purple</p>
          <p className="badge--red badge--sm hover">Red</p>
          <p className="badge--white badge--sm hover">White</p>
          <p className="badge--yellow badge--sm hover">Yellow</p>
        </div>
        <div className="col-span-5 my-4 font-mono text-base">
          <CodeBlock text={smallBadgeExamples} theme={tomorrowNight} language="javascript" />
        </div>
        <div className="my-2 gap-3 flex">
          <p className="badge--link">Link Badge</p>
          <p className="badge--link">Link Badge</p>
        </div>
        <div className="col-span-5 my-4 font-mono text-base">
          <CodeBlock text={linkBadgeExample} theme={tomorrowNight} language="javascript" />
        </div>
      </section>
      <div className="my-2">
        <p>
          Some badges serve the purpose of tags for an item. If these are interactive and meant to
          be removed, you will need to apply both <code>hover</code> and <code>removable</code>{" "}
          classes to the component with the <code>badge</code> classname. Inside the badge, you will
          need to further apply classes to the text content with <code>removable-text</code> and the
          icon content with <code>removable-icon</code> to apply the necessary padding. This
          provides adequate padding for a click event to be applied to the icon element.
        </p>
      </div>
      <section className="my-8 gap-2 flex">
        {removableBadges.map((badge) => (
          <span key={badge} className="badge--purple removable hover">
            <span className="removable-text">{badge}</span>
            <FontAwesomeIcon
              className="removable-icon"
              icon={["far", "times"]}
              onClick={() => filterBadges(badge)}
            />
          </span>
        ))}
      </section>
      <div className="col-span-5 my-4 font-mono text-base">
        <CodeBlock text={removableBadgeExample} theme={tomorrowNight} language="javascript" />
      </div>
    </>
  )
}

export default Badges

const badgeExamples = `<p className="badge--blue">Blue</p>
<p className="badge--gray">Gray</p>
<p className="badge--green">Green</p>
<p className="badge--purple">Purple</p>
<p className="badge--red hover">Red</p>
<p className="badge--white hover">White</p>
<p className="badge--yellow hover">Yellow</p>`

const smallBadgeExamples = `<p className="badge--blue badge--sm">Blue</p>
<p className="badge--gray badge--sm hover">Gray</p>
<p className="badge--green badge--sm hover">Green</p>
<p className="badge--purple badge--sm hover">Purple</p>
<p className="badge--red badge--sm">Red</p>
<p className="badge--white badge--sm">White</p>
<p className="badge--yellow badge--sm">Yellow</p>`

const linkBadgeExample = `<p className="badge--link">Link Badge</p>`

const removableBadgeExample = `<span className="badge--purple removable hover">
  <span className="removable-text">{badge}</span>
  <FontAwesomeIcon
    className="removable-icon"
    icon={["far", "times"]}
    onClick={removeItem}
  />
</span>`
