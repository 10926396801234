import React from "react"

const ListItem = (label: string, value?: number | string | null) => (
  <div className="module-card-list-item">
    <div>{label}</div>
    <div>{(value || "").toString()}</div>
  </div>
)

export { ListItem }
