import cn from "classnames"
import React from "react"

import { AccordionModulePanel } from "v2/react/shared/layout/AccordionModulePanel"

interface Link {
  id: string
  url: string
  label: string
}

interface LinkModuleProps {
  links: Link[] | null
  title: string
  expanded?: boolean
  onToggle?: (collapsed?: boolean) => void
}

const LinkModule = ({ links, title, expanded = true, onToggle }: LinkModuleProps) => {
  if (!isPresent(links)) return null

  const linksLength = links.length
  const evenNumberOfLinks = linksLength % 2 === 0

  return (
    <div className="w-full sm:max-w-7xl">
      <AccordionModulePanel
        headerContent={<span className="text-base-bold text-neutral-100">{title}</span>}
        headerClassNames="bg-white"
        contentClassNames="bg-white"
        contentItemsClassNames="p-0"
        title={title}
        collapsed={!expanded}
        additionalToggleAction={onToggle}
      >
        <div className="grid-cols-1 grid sm:grid-cols-2">
          {links?.map(({ id, url, label }, i) => {
            const isLast = i === linksLength - 1
            const isSecondToLast = i === linksLength - 2
            const rowIsOdd = i % 2 === 1

            return (
              <div
                key={id}
                className={cn("border-0 border-b border-solid border-neutral-8 p-4", {
                  "border-b-0": isLast,
                  "sm:border-b-0": isSecondToLast && evenNumberOfLinks,
                  "sm:border-l": rowIsOdd,
                })}
              >
                <a href={url} className="text-base text-primary-100">
                  {label}
                </a>
              </div>
            )
          })}
        </div>
      </AccordionModulePanel>
    </div>
  )
}

const isPresent = (links: LinkModuleProps["links"]): links is Link[] =>
  !!(links && links.length > 0)

export type { Link, LinkModuleProps }
export { LinkModule }
