import React from "react"

function getDateFromCurrentValue(currentValue: string) {
  let defaultDate

  if (currentValue?.includes("-")) {
    defaultDate = new Date(`${currentValue}T00:00:00`)
  } else {
    defaultDate = currentValue && new Date(currentValue)
  }

  return defaultDate
}

export function useSelectedDate(currentValue: string) {
  const [selectedDate, setSelectedDate] = React.useState(getDateFromCurrentValue(currentValue))

  React.useEffect(() => {
    setSelectedDate(getDateFromCurrentValue(currentValue))
  }, [currentValue])

  return [selectedDate, setSelectedDate] as const
}
