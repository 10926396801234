import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { BioOrganization, FeatureFlags } from "types/graphql"
import { ProfilePanelMode } from "types/graphql.enums"
import { ModuleListItem } from "v2/react/components/orgChart/ProfilePanel/ModuleListItem"
import { useSelectedBox } from "v2/react/components/orgChart/ProfilePanel/Settings/hooks/useSelectedBox"
import { AccordionModulePanel } from "v2/react/shared/layout/AccordionModulePanel"
import { AccordionModulePanelOverlay } from "v2/react/shared/layout/AccordionModulePanelOverlay"
import { sanitizeParse } from "v2/react/utils/sanitizeParse"
import { useAppSelector } from "v2/redux/store"

interface BioOrganizationsProps {
  featureFlags: FeatureFlags
}

function BioOrganizations({ featureFlags }: BioOrganizationsProps) {
  const person = useAppSelector((state) => state.profilePanel.person)
  const { currentBox, toggleBox } = useSelectedBox("bio_organizations", ProfilePanelMode.Person)
  const [showOverlay, setShowOverlay] = useState(false)
  const [overlayContent, setOverlayContent] = useState<BioOrganization | null>(null)
  const { t } = useTranslation()

  if (!currentBox || !person) return null
  if (!featureFlags.bio || !person.bioOrganizations?.length) return null

  const headerContent = <div className="title">{t("v2.profile_panel.bio_organizations")}</div>
  const overlayHeader = <div className="title">{overlayContent?.name}</div>

  const overlayBody = () => {
    if (!overlayContent?.description) return null
    return (
      <ModuleListItem>
        <div>
          <div>{t("v2.profile_panel.description")}</div>
          <p>{sanitizeParse(overlayContent?.description)}</p>
        </div>
      </ModuleListItem>
    )
  }

  return (
    <AccordionModulePanel
      headerContent={headerContent}
      additionalToggleAction={toggleBox}
      collapsed={currentBox.collapsed}
      title={currentBox.name}
      showOverlay={showOverlay}
      overlayContent={
        <AccordionModulePanelOverlay
          isOpen={showOverlay}
          closePanel={() => {
            setOverlayContent(null)
            setShowOverlay(false)
          }}
          headerContent={overlayHeader}
        >
          {overlayBody()}
        </AccordionModulePanelOverlay>
      }
    >
      <ModuleListItem>
        <div className="flex-wrap gap-2.5 flex">
          {person.bioOrganizations?.map((organization) => (
            <button
              key={organization.id}
              type="button"
              className="badge--link hover mb-2"
              onClick={() => {
                setOverlayContent(organization)
                setShowOverlay(true)
              }}
            >
              {organization.name}
            </button>
          ))}
        </div>
      </ModuleListItem>
    </AccordionModulePanel>
  )
}

export { BioOrganizations }
