import { useMemo } from "react"

import { usePositionWatchField } from "./useConvenientReactHookFormHooks"

/**
 * Returns a memoized array of `Person.id` values who hold a seat in the
 * position (based on the form). This is primarily useful for omitting
 * them from search results when adding new seats.
 */
const useIdsOfPeopleWithSeatInPosition = () => {
  const people = usePositionWatchField({ name: "position.people" })
  const idsOfOccupantsInOtherSeats = useMemo(
    () => people.map(({ id }) => id ?? "").filter((val) => val !== ""),
    [people],
  )

  return idsOfOccupantsInOtherSeats
}

export { useIdsOfPeopleWithSeatInPosition }
