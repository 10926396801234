import { ControlProps } from "@jsonforms/core"
import { useJsonForms } from "@jsonforms/react"
import React, { FC } from "react"

import { GenericFormShape } from "v2/react/components/jobRequisitions/RequisitionForm/types/GenericFormShape"

import { annualBasePay, compensationLabelPrefix, variablePayLabel } from "./utils/compensation"
import { formNumericValue, formStringValue } from "./utils/forms"

type ControlPropsSubset = Pick<ControlProps, "visible">
type VariablePayTypeData = { amount: number | null; id: string; name: string; type: string }

const JsonModuleListVariableCompensation: FC<ControlPropsSubset> = ({ visible }) => {
  const context = useJsonForms()
  const formData: GenericFormShape = context?.core?.data

  if (!visible) return null

  const amount = formNumericValue(formData, ["position", "basePay", "amount"])
  const basePayTypeId = formStringValue(formData, ["position", "basePay", "payType", "id"])
  const hours = formNumericValue(formData, ["position", "basePay", "hoursPerWeek"])
  const annualBasePayAmount: number = annualBasePay(basePayTypeId, amount, hours)

  return (
    <>
      {(formData?.position?.variablePayTypes || []).map((payType: VariablePayTypeData) => (
        <div className="module-card-list-item" key={`${payType.id}`}>
          <label className="text-neutral-64">
            {compensationLabelPrefix(formData)} {payType.name}
          </label>
          <div data-testid="module-list-item-value">
            {variablePayLabel(annualBasePayAmount, payType.type, payType.amount)}
          </div>
        </div>
      ))}
    </>
  )
}

export { JsonModuleListVariableCompensation }
