import cn from "classnames"
import React, { forwardRef } from "react"

import { Option } from "types/graphql"

import { useWithReactHookFormRegister } from "./hooks/useWithReactHookFormHooks"
import { InputWrapper } from "./InputWrapper"
import { UseInReactHookFormProp } from "./types"

interface SelectProps {
  className?: string
  defaultValue?: boolean | string
  disabled?: boolean
  id?: string
  inputClassName?: string
  name?: string
  onSelect?: (event: React.ChangeEvent<HTMLSelectElement>) => void
  options: Option[]
  required?: boolean
  selected?: string
  useInReactHookForm?: UseInReactHookFormProp
}

type SuperDuperSelectPropsEtc = SelectProps & {
  errors?: string
  label?: string
  showErrorMessage?: boolean
  showAttention?: boolean
  wrapperClassName?: string
}

const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      className,
      defaultValue,
      disabled,
      id,
      inputClassName,
      name,
      onSelect,
      options,
      required = false,
      selected,
      useInReactHookForm,
    },
    ref,
  ) => (
    <div className={cn("select", className)}>
      <select
        className={inputClassName}
        defaultValue={selected}
        disabled={disabled}
        id={id}
        name={name}
        onChange={onSelect}
        ref={ref}
        required={required}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...useWithReactHookFormRegister({ useInReactHookForm, name })?.(name ?? "")}
      >
        {defaultValue && (
          <option value="">{typeof defaultValue === "boolean" ? "" : defaultValue}</option>
        )}
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  ),
)

const SelectWithWrapper = forwardRef<HTMLSelectElement, SuperDuperSelectPropsEtc>(
  (
    {
      className,
      defaultValue,
      disabled,
      errors,
      id,
      inputClassName,
      label,
      name,
      onSelect,
      options,
      required = false,
      selected,
      showErrorMessage,
      showAttention,
      wrapperClassName,
      useInReactHookForm,
    },
    ref,
  ) => (
    <InputWrapper
      label={label}
      errors={errors}
      showErrorMessage={showErrorMessage}
      id={id}
      className={cn(wrapperClassName, {
        attention: showAttention,
      })}
      name={name}
      useInReactHookForm={useInReactHookForm}
    >
      <Select
        className={className}
        defaultValue={defaultValue}
        disabled={disabled}
        id={id}
        inputClassName={inputClassName}
        name={name}
        onSelect={onSelect}
        options={options}
        ref={ref}
        required={required}
        selected={selected}
        useInReactHookForm={useInReactHookForm}
      />
    </InputWrapper>
  ),
)

export { Select, SelectWithWrapper }
