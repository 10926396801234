import { skipToken } from "@reduxjs/toolkit/dist/query"
import { useDebounce } from "usehooks-ts"

import {
  EMPTY_POSITION_TYPES_CONNECTION,
  usePositionTypesAutocompleteConnectionQuery,
} from "v2/redux/GraphqlApi/PositionTypesApi"

type ControlState = {
  searchTerm: string
  currentTerm: string
}

function usePositionTypesAutocompleteQueryWithState({ searchTerm, currentTerm }: ControlState) {
  const debouncedSearchTerm = useDebounce(searchTerm, 255)

  const doesSearchTermHaveContent = searchTerm.trim() !== ""
  const isSearchTermDifferentFromCurrent = searchTerm !== currentTerm

  const canUseOptions = doesSearchTermHaveContent && isSearchTermDifferentFromCurrent
  const queryArg = canUseOptions ? { searchTerm: debouncedSearchTerm, first: 10 } : skipToken
  const connection = usePositionTypesAutocompleteConnectionQuery(queryArg, {
    selectFromResult: ({ data }) => data ?? EMPTY_POSITION_TYPES_CONNECTION,
  })

  return { canUseOptions, positionTypes: connection.nodes }
}

export { usePositionTypesAutocompleteQueryWithState }
