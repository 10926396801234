import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { Dropdown } from "v2/react/shared/collection/menus/Dropdown/Dropdown"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"
import { mergeCurrentSearchParamsWithUrl } from "v2/react/utils/urls"

interface ExportUrl {
  url: string
  id: string
  label: string
}

interface Props {
  exportUrls: ExportUrl[]
  /**
   * If true, the current search params will be used along with the exportUrl
   * when the export is clicked.
   */
  useCurrentSearchParamsWithExportUrl?: boolean
}

const ExportButtonMenu = ({ exportUrls, useCurrentSearchParamsWithExportUrl }: Props) => {
  const { t } = useTranslation()
  const [dropdownOpen, setDropdownOpen] = useState(false)

  return (
    <Dropdown align="left" onOpenChange={(open) => setDropdownOpen(open)}>
      <Tooltip placement="bottom-start">
        <TooltipTrigger>
          <Dropdown.Trigger id="export-csvs" triggerClassName="btn--large btn--secondary w-11">
            <FontAwesomeIcon icon={["far", "download"]} />
          </Dropdown.Trigger>
        </TooltipTrigger>
        <TooltipContent className={cn("react-tooltip-content--sm", { hidden: dropdownOpen })}>
          <span>{t("v2.defaults.export")}</span>
        </TooltipContent>
      </Tooltip>

      <Dropdown.Menu>
        {exportUrls.map((exportUrl) => {
          const handleClick = (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
            if (useCurrentSearchParamsWithExportUrl && exportUrl.url) {
              const newUrl = mergeCurrentSearchParamsWithUrl(exportUrl.url)
              const event = e as React.MouseEvent<HTMLAnchorElement, MouseEvent>
              if (event) {
                event.currentTarget.href = newUrl
              }
            }
          }

          return (
            <Dropdown.Item
              as="a"
              key={exportUrl.id}
              id={exportUrl.id}
              withIcon={<FontAwesomeIcon icon={["far", "download"]} />}
              href={exportUrl.url}
              onClick={handleClick}
              useActiveStyles
            >
              {exportUrl.label}
            </Dropdown.Item>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export { ExportButtonMenu }
export type { ExportUrl }
