import classNames from "classnames"
import React, { useState } from "react"

import { Position } from "types/graphql"
import { useAutoComplete } from "v2/react/hooks/useAutocomplete"
import { usePositionSearch } from "v2/react/hooks/usePositionSearch"
import { DropdownMenu } from "v2/react/shared/collection/menus/DropdownMenu"
import { AutocompleteResult } from "v2/react/shared/forms/AutocompleteResult"

interface PositionSearchInputProps {
  errorMessage?: string
  htmlForId?: string
  onSelect?: (selectedPosition: Position) => void
  placeholder?: string
  retainNameOnSelect?: boolean
}

function PositionSearchInput({
  errorMessage,
  htmlForId,
  onSelect,
  placeholder,
  retainNameOnSelect,
}: PositionSearchInputProps) {
  const [inputValue, setInputValue] = useState("")
  const [showResultList, setShowResultList] = useState(false)
  const { positions, returnEmpty, searchTerm } = usePositionSearch({
    filter: inputValue.trim(),
    chartKey: `chart_${window.gon.official_chart_id}`,
  })

  const {
    activeIndex,
    setActiveIndex,
    listRef,
    refs,
    floatingStyles,
    context,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
  } = useAutoComplete({ showList: showResultList, setShowList: setShowResultList })

  const handleChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setInputValue(value || "")

    if (value.length > 0) {
      setShowResultList(true)
      setActiveIndex(0)
    } else {
      setShowResultList(false)
    }
  }

  const handleResultClick = (positionId: string | null | undefined, positionLabel: string) => {
    if (retainNameOnSelect) {
      setInputValue(positionLabel)
    } else {
      setInputValue("")
    }

    if (typeof activeIndex === "number" && activeIndex >= 0) {
      const selectedPosition = positions[activeIndex]
      if (onSelect && selectedPosition) onSelect(selectedPosition)
    }

    setActiveIndex(null)
    setShowResultList(false)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && activeIndex != null && positions[activeIndex]) {
      const selectedPosition = positions[activeIndex]
      handleResultClick(selectedPosition.uniqueKey, getPositionLabel(positions[activeIndex]))
    }
  }

  const showError = typeof errorMessage === "string" && errorMessage !== ""

  const renderInput = () => (
    <input
      aria-autocomplete="list"
      className="input"
      id={htmlForId}
      placeholder={placeholder}
      type="text"
      value={inputValue}
      ref={refs.setReference}
      /* eslint-disable react/jsx-props-no-spreading */
      {...getReferenceProps({
        onChange: handleChangeEvent,
        onKeyDown: handleKeyDown,
        // onBlur: handleBlurEvent,
      })}
    />
  )

  return (
    <div className={classNames("position-input", { "form-error": showError })}>
      <div className={classNames({ "form-error show": showError })}>
        {renderInput()}
        {showError && <span className="form-error-message">{errorMessage}</span>}
      </div>

      <DropdownMenu
        showList={showResultList && !!inputValue.trim().length}
        floatingRef={refs.setFloating}
        floatingStyles={floatingStyles}
        floatingProps={getFloatingProps}
        wrapperClasses="AutocompleteField"
        context={context}
      >
        <>
          {positions &&
            positions.length > 0 &&
            positions?.map((position, index) => (
              <AutocompleteResult
                key={position.uniqueKey}
                position={position}
                isSelected={activeIndex === index}
                className={positionClassName(activeIndex, index)}
                searchTerm={searchTerm}
                refFn={(node: HTMLDivElement | null) => {
                  listRef.current[index] = node
                }}
                getItemProps={getItemProps}
                handleResultClick={handleResultClick}
              />
            ))}
          {!returnEmpty && positions?.length === 0 && inputValue.length > 0 && (
            <div className="AutocompleteField__no-result">
              <p className="AutocompleteField__no-result-text">
                {"no_positions_found".t("position")}
              </p>
            </div>
          )}
        </>
      </DropdownMenu>
    </div>
  )
}

const positionClassName = (activeIndex: number | null, index: number) =>
  classNames("AutocompleteField__result", { highlight: activeIndex === index })

const getPositionLabel = (position: Position) => {
  const people = position.people
  const peopleNames = people?.map((person) => person.name).join(", ")
  const positionTitle = position.title
  const positionSystemID = position.systemIdentifier
  return [peopleNames, positionTitle || positionSystemID].filter((n) => n).join(", ")
}

export { PositionSearchInput }
