import { z } from "zod"

const VacancyRateDataSchema = z.object({
  value: z.number(),
  metadata: z.object({
    comparison: z.object({
      percent_change: z.string().nullable(),
      date: z.string().nonempty(),
    }),
  }),
})
type VacancyRateData = z.infer<typeof VacancyRateDataSchema>

const GroupDataSchema = z.object({
  id: z.string().nonempty(),
  label: z.string().nonempty(),
  value: z.number(),
})
type GroupData = z.infer<typeof GroupDataSchema>

const GroupOptionSchema = z.object({
  id: z.string().nonempty(),
  label: z.string().nonempty(),
})
type GroupOption = z.infer<typeof GroupOptionSchema>

const FullVacancyRateDataSchema = z.object({
  vacancyRate: VacancyRateDataSchema.array().nonempty(),
  vacancyRateByGroup: GroupDataSchema.array(),
  groupByOptions: GroupOptionSchema.array().nonempty(),
})

type Widget = "vacancyRate"

export {
  FullVacancyRateDataSchema,
  GroupDataSchema,
  GroupData,
  GroupOption,
  VacancyRateData,
  Widget,
}
