import { BasePay } from "types/graphql.enums"
import { usePositionFormContext } from "v2/react/components/positions/positionForm/PositionFormProvider"
import { MaybeNumeric, safeNumber } from "v2/react/utils/safeNumber"

import { usePositionWatchField } from "./useConvenientReactHookFormHooks"

/**
 * Reactive; computes the total budgeted base pay for the position based on the
 * current form state.
 */
function useBudgetedBasePayTotal() {
  const {
    formState: { defaultValues },
  } = usePositionFormContext()

  const amount = usePositionWatchField({ name: "position.positionBasePay" })
  const hoursPerWeek = usePositionWatchField({ name: "position.positionHoursPerWeek" })
  const payType = usePositionWatchField({ name: "position.positionBasePayType" })

  const initialAmount = defaultValues?.position?.positionBasePay
  const initialHoursPerWeek = defaultValues?.position?.positionHoursPerWeek
  const initialPayType = defaultValues?.position?.positionBasePayType

  return {
    current: calculateBudgetedTotal(amount, hoursPerWeek, payType),
    initial: calculateBudgetedTotal(initialAmount, initialHoursPerWeek, initialPayType),
  }
}

const calculateBudgetedTotal = (
  amount?: MaybeNumeric,
  hoursPerWeek?: MaybeNumeric,
  payType?: BasePay,
) =>
  payType === BasePay.Hourly
    ? safeNumber(amount) * safeNumber(hoursPerWeek) * 52
    : safeNumber(amount)

export { calculateBudgetedTotal, useBudgetedBasePayTotal }
