import React, { PropsWithChildren } from "react"

interface Props extends PropsWithChildren {
  title: string
  columnCount?: 1 | 2
}

export default function PageSection({ title, children, columnCount = 1 }: Props) {
  return (
    <div className="elevation mb-8 w-full rounded-xl bg-white">
      <div className={`grid grid-cols-${columnCount} gap-0 divide-y divide-neutral-8`}>
        <div className={`col-span-${columnCount} w-full`}>
          <div className="p-4">
            <p className="font-bold">{title}</p>
          </div>
        </div>

        {children}
      </div>
    </div>
  )
}
