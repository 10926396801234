import { TFunction } from "i18next"
import React from "react"
import { useTranslation } from "react-i18next"

import { Person, Position } from "types/graphql"
import { EmptyState } from "v2/react/components/positionTypes/Show/EmptyState"
import { Avatar } from "v2/react/shared/icons/Avatar"
import { BlueIcon } from "v2/react/shared/icons/BlueIcon"
import { pjaxModalFor } from "v2/react/utils/pjax"
import { UrlHelper } from "v2/react/utils/urls"

import { totalTenureInYears } from "./utils"

interface Props {
  position: Position
}

function FilledBy({ position }: Props) {
  const { t } = useTranslation()

  return (
    <div className="module-card">
      <div className="module-card__header">
        <div className="module-title">{t("v2.positions.show.filled_by")}</div>
      </div>
      {position.filledBy && position.filledBy.length > 0 ? (
        <PersonRows people={position.filledBy || []} t={t} />
      ) : (
        <EmptyState
          buttonIcon="user-plus"
          buttonClass="btn--large btn--primary"
          buttonText={t("v2.positions.show.add_position_cta")}
          icon="chair-office"
          note={t("v2.positions.show.add_position_note")}
          onClick={() =>
            pjaxModalFor(UrlHelper.editPositionPath(position.id, "?selected_field=filled_by"))
          }
        />
      )}
    </div>
  )
}

const PersonRows = ({ people, t }: { people: Person[]; t: TFunction }) => (
  <div className="module-card__body border-0 p-0">
    <div className="max-h-[400px] overflow-y-auto">
      {(people || []).map((person: Person) => (
        <PersonRow person={person} key={person.id} t={t} />
      ))}
    </div>
  </div>
)

const PersonRow = ({ person, t }: { person: Person; t: TFunction }) => (
  <div className="border-0 border-t border-solid border-neutral-8 px-4 py-4 flex">
    <div className="items-center pr-2 flex">
      {person.avatarThumbUrl ? (
        <Avatar person={person} size="lg" />
      ) : (
        <BlueIcon icon={["far", "chair-office"]} size="lg" />
      )}
    </div>
    <div>
      <div className="pt-2 text-large">
        <div>{person.name}</div>
      </div>
      <div className="text-neutral-64">
        <div>
          {t("v2.positions.show.tenure_in_years", {
            count: totalTenureInYears(person.hireDate, person.createdAt),
          })}
        </div>
      </div>
    </div>
  </div>
)

export { FilledBy }
