import { parseDate } from "@internationalized/date"
import { useState } from "react"
import type { DateRange as DateRangeType, DateValue } from "react-aria"

interface UseDateRangeProps {
  startDate?: string
  endDate?: string
}

const makeDateRange = (startDate?: string | null, endDate?: string | null) => ({
  start: (startDate ? parseDate(startDate) : null) as unknown as DateValue,
  end: (endDate ? parseDate(endDate) : null) as unknown as DateValue,
})

const useDateRange = ({ startDate, endDate }: UseDateRangeProps) => {
  const initialDateRange = startDate || endDate ? makeDateRange(startDate, endDate) : null

  const [dateRange, setDateRange] = useState<DateRangeType | null>(initialDateRange)

  const handleDateRangeChange = (range: DateRangeType) => {
    setDateRange(range)
  }

  return {
    dateRange,
    handleDateRangeChange,
  }
}

export { useDateRange, makeDateRange }
