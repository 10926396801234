import { flexRender, Table } from "@tanstack/react-table"
import classNames from "classnames"
import React from "react"

interface LayoutProps<TData> {
  table: Table<TData>
  isFetching?: boolean
}

export const TableBody = <TData extends object>({ table, isFetching }: LayoutProps<TData>) => (
  <tbody className={classNames({ loading: isFetching })}>
    {table.getRowModel().rows.map((row) => (
      <tr key={row.id}>
        {row.getVisibleCells().map((cell) => (
          <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
        ))}
      </tr>
    ))}
  </tbody>
)
