import { JsonForm } from "types/graphql"
import { FormShape } from "v2/react/components/jobRequisitions/RequisitionForm/types/FormShape"
import { initialPosigenValues } from "v2/react/components/jobRequisitions/RequisitionForm/utils/initialPosigenValues"

const initialValuesForSchema = (jsonForm: JsonForm): FormShape => {
  // This should apply for all non-posigen schema definitions
  if (jsonForm.schema?.initial_values) {
    return jsonForm.schema.initial_values
  }

  // Presently we generate dynamic fields like variable pay types on the server
  // for initialValues. Some legacy data may be missing that info from schemas,
  // (Posigen only) so we fallback to these frontend values, until beta data is
  // reset or a data migration is in place.
  return initialPosigenValues
}

export { initialValuesForSchema }
