const focusInputOnSelection = () => {
  window?.$(document).ready(() => {
    window
      ?.$("[data-autocomplete=search]")
      .on("autocomplete:selectSuggestion", (event: Event, selected: Element) => {
        const $selected = window?.$(selected)
        window?.$(document).trigger("orgChart:toolbar:focus", $selected.data("id"))
        window.App.OrgChart.focus($selected.data("id"))
        window?.$("[data-autocomplete=search]").val("")
        window?.$("[data-autocomplete=search]").trigger("blur")
      })
  })
}

export { focusInputOnSelection }
