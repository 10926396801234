import React from "react"
import { CodeBlock, tomorrowNight } from "react-code-blocks"

function Colors() {
  return (
    <>
      <h1>Colors</h1>
      <p>
        Colors communicate how things function. They breath live into any interface. Consistent use
        of colors turns into visual patterns for easy and predictable interactions. Our products
        should be accessible to all people as much as possible. Therefore we want to comply with the
        Web Content Accessibility Guidelines (WCAG) contrast ratio at a minimum level APCA score of
        60.
      </p>
      <div className="col-span-5 my-4 font-mono text-base">
        <CodeBlock text={colorExamples} theme={tomorrowNight} language="javascript" />
      </div>

      <section>
        <h2>Primary Colors</h2>
        <div className="grid-cols-5 gap-1 grid">
          <div>
            <h3>primary-100</h3>
            <div className="h-14 bg-primary-100" />
            <span>buttons and links</span>
          </div>
          <div>
            <h3>primary-80</h3>
            <div className="h-14 bg-primary-80" />
            <span>buttons and links</span>
          </div>
          <div>
            <h3>primary-50</h3>
            <div className="h-14 bg-primary-50" />
            <span>buttons and links</span>
          </div>
          <div>
            <h3>primary-10</h3>
            <div className="h-14 bg-primary-10" />
            <span>buttons and links</span>
          </div>
          <div>
            <h3>primary-3</h3>
            <div className="h-14 bg-primary-3" />
            <span>app background</span>
          </div>
        </div>
      </section>

      <section>
        <h2>Neutral Colors</h2>
        <div className="grid-cols-5 gap-1 grid">
          <div>
            <h3>neutral-100</h3>
            <div className="h- h-14 bg-neutral-100" />
            <span>headers</span>
          </div>
          <div>
            <h3>neutral-80</h3>
            <div className="h-14 bg-neutral-80" />
            <span>normal text</span>
          </div>
          <div>
            <h3>neutral-64</h3>
            <div className="h-14 bg-neutral-64" />
            <span>light text</span>
          </div>
          <div>
            <h3>neutral-8</h3>
            <div className="h-14 bg-neutral-8" />
            <span>hairlines (borders)</span>
          </div>
          <div>
            <h3>neutral-3</h3>
            <div className="h-14 bg-neutral-3" />
            <span>content background</span>
          </div>
        </div>
      </section>

      <section>
        <h2>White Colors</h2>
        <div className="grid-cols-3 gap-1 grid">
          <div>
            <h3>white</h3>
            <div className="h- bg-white-gradient h-14" />
            <span>white text</span>
          </div>
          <div>
            <h3>white/80</h3>
            <div className="h-14 bg-white/80" />
            <span>subtle light body text</span>
          </div>
          <div>
            <h3>bg-gradient-neutral</h3>
            <div className="bg-gradient-neutral h-14" />
            <span>subtle gradient for interactive elements</span>
          </div>
        </div>
      </section>

      <section>
        <h2>Accent Colors</h2>
        <div className="grid-cols-6 gap-1 grid">
          <div>
            <h3>accent-flamingo</h3>
            <div className="h- h-14 bg-accent-flamingo" />
            <span>accent</span>
          </div>
          <div>
            <h3>accent-flamingo-50</h3>
            <div className="h-14 bg-accent-flamingo-50" />
            <span>accent</span>
          </div>
          <div>
            <h3>accent-sunburst</h3>
            <div className="h-14 bg-accent-sunburst" />
            <span>accent</span>
          </div>
          <div>
            <h3>accent-sunburst-50</h3>
            <div className="h-14 bg-accent-sunburst-50" />
            <span>accent</span>
          </div>
          <div>
            <h3>accent-breeze</h3>
            <div className="h-14 bg-accent-breeze" />
            <span>accent</span>
          </div>
          <div>
            <h3>accent-breeze-50</h3>
            <div className="h-14 bg-accent-breeze-50" />
            <span>accent</span>
          </div>
        </div>
      </section>

      <section>
        <h2>Status Colors</h2>
        <div className="grid-cols-2 gap-1 grid">
          <div>
            <h3>Success</h3>
            <div className="grid-cols-2 gap-1 grid">
              <div>
                <h3>status-success</h3>
                <div className="h- h-14 bg-status-success" />
                <span>text</span>
              </div>
              <div>
                <h3>status-success-light</h3>
                <div className="h-14 bg-status-success-light" />
                <span>background</span>
              </div>
            </div>
          </div>

          <div>
            <h3>Critical</h3>
            <div className="grid-cols-2 gap-1 grid">
              <div>
                <h3>status-critical</h3>
                <div className="h-14 bg-status-critical" />
                <span>text</span>
              </div>
              <div>
                <h3>status-critical-light</h3>
                <div className="h-14 bg-status-critical-light" />
                <span>background</span>
              </div>
            </div>
          </div>

          <div>
            <h3>Caution</h3>
            <div className="grid-cols-2 gap-1 grid">
              <div>
                <h3>status-caution</h3>
                <div className="h-14 bg-status-caution" />
                <span>text</span>
              </div>
              <div>
                <h3>status-caution-light</h3>
                <div className="h-14 bg-status-caution-light" />
                <span>background</span>
              </div>
            </div>
          </div>

          <div>
            <h3>Excite</h3>
            <div className="grid-cols-2 gap-1 grid">
              <div>
                <h3>status-excite</h3>
                <div className="h- h-14 bg-status-excite" />
                <span>text</span>
              </div>
              <div>
                <h3>status-excite-light</h3>
                <div className="h-14 bg-status-excite-light" />
                <span>background</span>
              </div>
            </div>
          </div>

          <div>
            <h3>Info</h3>
            <div className="grid-cols-2 gap-1 grid">
              <div>
                <h3>status-info</h3>
                <div className="h-14 bg-status-info" />
                <span>text</span>
              </div>
              <div>
                <h3>status-info-light</h3>
                <div className="h-14 bg-status-info-light" />
                <span>background</span>
              </div>
            </div>
          </div>

          <div>
            <h3>Neutral</h3>
            <div className="grid-cols-2 gap-1 grid">
              <div>
                <h3>status-neutral</h3>
                <div className="h-14 bg-status-neutral" />
                <span>text</span>
              </div>
              <div>
                <h3>status-neutral-light</h3>
                <div className="h-14 bg-status-neutral-light" />
                <span>background</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Colors

const colorExamples = `<p className='text-primary-100'>Some Content</p> // applies color changes to text
<div className='bg-neutral-100'>Some Content</div> // applies background color changes
<div className='bg-accent-red-light'>Some Content</div> // applies lighter accent background color changes
// in scss file:
.my-item {
  background: theme("colors.status.critical-light") // applies critical-light background color
}`
