import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"

import { BioSocialLink } from "types/graphql"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"

interface SocialLinkProps {
  socialLink: BioSocialLink
}

function SocialLink({ socialLink }: SocialLinkProps) {
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <Tooltip open={showTooltip}>
      <TooltipTrigger
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <a
          href={socialLink.url}
          target="_blank"
          rel="noreferrer"
          className="text-neutral-80 inline-block"
        >
          <FontAwesomeIcon icon={socialNetworkFaClass(socialLink.socialNetwork)} />
        </a>
      </TooltipTrigger>
      <TooltipContent className="react-tooltip-content">
        <span className="break-all">{socialLink.url}</span>
      </TooltipContent>
    </Tooltip>
  )
}

const socialNetworkFaClass = (socialNetwork: string): [IconPrefix, IconName] => {
  // See: https://odoe.net/blog/ts-hashmaps
  interface NetworksMap {
    [key: string]: [IconPrefix, IconName]
  }
  const socialNetworkMapping: NetworksMap = {
    behance: ["fab", "behance"],
    blogger: ["fab", "blogger-b"],
    dribbble: ["fab", "dribbble"],
    dropbox: ["fab", "dropbox"],
    facebook: ["fab", "facebook-f"],
    github: ["fab", "github"],
    hubspot: ["fab", "hubspot"],
    instagram: ["fab", "instagram"],
    linkedin: ["fab", "linkedin-in"],
    medium: ["fab", "medium-m"],
    pinterest: ["fab", "pinterest"],
    snapchat: ["fab", "snapchat-ghost"],
    twitter: ["fab", "twitter"],
    vimeo: ["fab", "vimeo-v"],
    wordpress: ["fab", "wordpress-simple"],
    youtube: ["fab", "youtube"],
    other: ["fas", "link"],
  }

  return socialNetworkMapping[socialNetwork]
}

export { SocialLink }
