import React from "react"

import { Chart, ChartApprover } from "types/graphql"
import { ApproverOrderedList } from "v2/react/components/approvals/ApproverList/ApproverOrderedList"
import { SubmissionNote } from "v2/react/components/orgChart/ChartApproval/ApprovalDropdown/SubmissionNote"
import { SubmittedListHeader } from "v2/react/components/orgChart/ChartApproval/ApprovalDropdown/SubmittedListHeader"
import { CurrentUser } from "v2/redux/slices/ApprovalSlice"

interface Props {
  approvers: ChartApprover[]
  chart: Chart
  currentUser: CurrentUser
  openConfigureModal: () => void
}

function SubmittedDisplay({ approvers, chart, currentUser, openConfigureModal }: Props) {
  return (
    <div className="sm:max-h-[60vh] sm:overflow-y-auto sm:overflow-x-hidden">
      <div>
        <SubmittedListHeader
          currentUser={currentUser}
          chart={chart}
          openConfigureModal={openConfigureModal}
        />
        <ApproverOrderedList
          approvers={approvers}
          notes={chart.chartNotes}
          workflowRequestDate={chart.approvalRequestedAt}
          workflowStatus={chart.status}
        />
      </div>
      <SubmissionNote chart={chart} />
    </div>
  )
}

export { SubmittedDisplay }
