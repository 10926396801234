import { CellEvent, CellState, CellStateMachine } from "./types"

export type IdObject = {
  rowId: string
  columnId: string
}

export function buildId(obj: IdObject) {
  return `${obj.rowId}.${obj.columnId}`
}

export function separateId(id: string): IdObject {
  const [rowId, columnId] = id.split(".")
  return { rowId, columnId }
}

export function initialCellState(obj: IdObject): CellState {
  return {
    id: buildId(obj),
    state: "idle",
  }
}

export function selectedCellState(obj: IdObject): CellState {
  return {
    id: buildId(obj),
    state: "selected",
  }
}

export function erroredSelectedCellState(obj: IdObject): CellState {
  return {
    id: buildId(obj),
    state: "erroredSelected",
  }
}

export function editingCellState(obj: IdObject): CellState {
  return {
    id: buildId(obj),
    state: "editing",
  }
}

export function erroredEditingCellState(obj: IdObject): CellState {
  return {
    id: buildId(obj),
    state: "erroredEditing",
  }
}

export const machine: CellStateMachine = {
  idle: {},
  selected: {},
  editing: {
    save: (event, state) => ({
      id: state.id,
      state: "saving",
      value: event.value,
    }),
  },
  saving: {
    noop: (event, state) => ({ id: state.id, state: "selected" }),
    saveError: (event, state) => ({
      id: state.id,
      state: "errored",
      value: state.value,
      errorMessage: event.message,
    }),
    saveSuccess: (event, state) => ({
      id: state.id,
      state: "saved",
      value: state.value,
    }),
  },
  saved: {
    reset: (event, state) => ({ id: state.id, state: "selected" }),
  },
  errored: {},
  erroredSelected: {},
  erroredEditing: {
    save: (event, state) => ({
      id: state.id,
      state: "erroredSaving",
      value: event.value,
      errorMessage: state.errorMessage,
    }),
  },
  erroredSaving: {
    noop: (event, state) => ({
      id: state.id,
      state: "errored",
      value: state.value,
      errorMessage: state.errorMessage,
    }),
    saveError: (event, state) => ({
      id: state.id,
      state: "errored",
      value: state.value,
      errorMessage: event.message,
    }),
    saveSuccess: (event, state) => ({
      id: state.id,
      state: "saved",
      value: state.value,
    }),
  },
}

export function cellReducer(state: CellState, event: CellEvent, machine: CellStateMachine) {
  const transitionFn = machine[state.state][event.type]

  if (transitionFn) {
    return transitionFn(event, state)
  }

  return state
}
