import { ErrorObject } from "ajv"
import i18n from "i18next"

import { FormShape } from "v2/react/components/jobRequisitions/RequisitionForm/types/FormShape"

const reportsToError = (formData: FormShape, submitting: boolean): ErrorObject[] => {
  if (!submitting) return []

  // A top level position has a truthy assignedParent property. No reports to
  // value is required for backfill requisitions.
  if (formData.position.assignedParent && formData.reqType === "backfill") return []
  if (formData.position.reportsTo.id) return []

  const errorMessage = i18n.t("v2.errors.messages.blank")

  return [
    {
      instancePath: `/position/reportsTo`,
      message: errorMessage,
      schemaPath: `#/properties/position/properties/reportsTo/errorMessage`,
      keyword: "",
      params: {},
    },
  ]
}

export { reportsToError }
