import { TFunction } from "i18next"
import { filter, flatMap, flow, get, getOr, map, set } from "lodash/fp"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { EnrichedFilterOption } from "types/graphql"
import { FilterableTables, FilterDataType, FilterType } from "types/graphql.enums"
import { useDateFormatter, type UseDateFormatter } from "v2/react/hooks/useDateFormatter"
import { useFilters } from "v2/react/shared/tables/TableUtilities/FilterTable/hooks/useFilters"
import {
  Filter,
  filterIsEmpty,
  getEmptyFilter,
} from "v2/react/shared/tables/TableUtilities/FilterTable/utils/filters"
import { prepareCurrencyValue } from "v2/react/utils/currency"
import {
  getEnrichedSelectedFilters,
  selectInitialFilters,
} from "v2/redux/slices/TableFilterSlice/tableFiltersSelectors"
import { applyFilters } from "v2/redux/slices/TableFiltersSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

type FilterBadge = NonNullable<ReturnType<typeof processFilterForBadge>>

interface useFilterBadgesProps {
  table: FilterableTables
}

/**
 * Hook to process filters for display as badges.
 *
 * Used in conjunction with `useFilters` to manage the full filtering
 * experience.
 */
const useFilterBadges = ({ table }: useFilterBadgesProps) => {
  const { t } = useTranslation()
  const { parseAndFormat } = useDateFormatter()
  const dispatch = useAppDispatch()
  const { anyErrors } = useFilters({ table, mountListener: true })
  const initialFilters = useAppSelector(selectInitialFilters)
  const enrichedSelectedFilters = useAppSelector(getEnrichedSelectedFilters)

  const onRemove = (filters: Filter[]) => dispatch(applyFilters({ filters, table }))

  const filtersToShow = useMemo(
    () =>
      processFiltersForBadges(
        enrichedSelectedFilters,
        t,
        parseAndFormat,
      )(initialFilters) as FilterBadge[],
    [enrichedSelectedFilters, initialFilters, t, parseAndFormat],
  )

  const clearAllFilters = () => {
    const emptyFilters = initialFilters.map((filter) =>
      getEmptyFilter({ field: filter.field, filterType: filter.type }),
    )
    onRemove(emptyFilters)
  }

  const removeFilterValue = (filterKey: string, valueKey?: string) => {
    let filteredFilters: Filter[] = []
    if (valueKey) {
      filteredFilters = removeFilterValueFromFilters(filterKey, valueKey)(initialFilters)
    } else {
      filteredFilters = initialFilters.map((f) => {
        if (f.field === filterKey) {
          return getEmptyFilter({ field: filterKey, filterType: f.type })
        }
        return f
      })
    }

    onRemove(filteredFilters)
  }

  return {
    anyErrors,
    clearAllFilters,
    filtersToShow,
    removeFilterValue,
  }
}

const removeFilterValueFromFilters = (filterKey: string, valueKey?: string) =>
  flow(
    map((filter: Filter) => {
      if (filter.field === filterKey && filter.type === FilterType.Inclusion && filter.value.in) {
        return {
          ...filter,
          value: {
            ...filter.value,
            in: filter.value.in.filter((v) => v !== valueKey),
          },
        }
      }
      return filter
    }),
  )

const processFiltersForBadges = (
  enrichedFilters: EnrichedFilterOption[],
  t: TFunction,
  dateFormatter: UseDateFormatter["parseAndFormat"],
) =>
  flow(
    filter((f: Filter) => !filterIsEmpty(f)),
    flatMap((filter) =>
      getOr([], ["value", "in"], filter).length > 0
        ? map((value) => set(["value", "in"], [value], filter), get(["value", "in"], filter))
        : [filter],
    ),
    map((filter) => processFilterForBadge(filter, enrichedFilters, t, dateFormatter)),
    filter((f) => f !== null),
  )

const processFilterForBadge = (
  filter: Filter,
  enrichedFilters: EnrichedFilterOption[],
  t: TFunction,
  dateFormatter: UseDateFormatter["parseAndFormat"],
) => {
  const enrichedFilter = enrichedFilters.find((f) => f.id === filter.field)
  if (!enrichedFilter) return null

  const filterKey = filter.field
  const filterLabel = enrichedFilter.label
  const dataType = enrichedFilter.dataType
  let label: string
  let valueKey: string | undefined

  const formatValue = (value: string | number | undefined) =>
    formatRangeValue(dataType, value, dateFormatter)

  const lte = filter.type === FilterType.Range ? "≤" : t("v2.defaults.before")
  const gte = filter.type === FilterType.Range ? "≥" : t("v2.defaults.after")

  if (filter.type === FilterType.Inclusion) {
    // Because we roll out the values, this should typically just be a single
    // value.
    valueKey = filter.value.in[0]
    const collectionLabel = enrichedFilter.collection?.find((c) => c.id === valueKey)?.label
    if (!collectionLabel) return null

    label = `${filterLabel}: ${collectionLabel}`
  } else if (filter.value.min === undefined) {
    label = `${filterLabel}: ${lte} ${formatValue(filter.value.max)}`
  } else if (filter.value.max === undefined) {
    label = `${filterLabel}: ${gte} ${formatValue(filter.value.min)}`
  } else if (filter.value.min === filter.value.max) {
    label = `${filterLabel}: ${formatValue(filter.value.min)}`
  } else {
    label = `${filterLabel}: ${formatValue(filter.value.min)} – ${formatValue(filter.value.max)}`
  }

  return {
    label,
    filterKey,
    valueKey,
  }
}

const formatRangeValue = (
  type: FilterDataType,
  value: string | number | undefined,
  dateFormatter: UseDateFormatter["parseAndFormat"],
) => {
  switch (type) {
    case FilterDataType.Currency:
      return value === undefined ? "" : `${prepareCurrencyValue({ value, omitSymbol: false })}`
    case FilterDataType.Percentage:
      return value === undefined ? "" : `${value}%`
    case FilterDataType.Date:
      return value === undefined ? "" : dateFormatter(value.toString())
    default:
      return value === undefined ? "" : value.toString()
  }
}

export { useFilterBadges }
