import { AnimatePresence, motion } from "framer-motion"
import React from "react"

import { ProfilePanelBoxSettingSelection, ProfilePanelPermissions } from "types/graphql"
import { appContainer } from "v2/appContainer"
import { ADP } from "v2/react/components/orgChart/ProfilePanel/PositionModules/ADP"
import { Allocations } from "v2/react/components/orgChart/ProfilePanel/PositionModules/Allocations"
import { CustomFields } from "v2/react/components/orgChart/ProfilePanel/PositionModules/CustomFields"
import { JobDescription } from "v2/react/components/orgChart/ProfilePanel/PositionModules/JobDescription"
import { PlanningAndForecasting } from "v2/react/components/orgChart/ProfilePanel/PositionModules/PlanningAndForecasting"
import { PositionInfo } from "v2/react/components/orgChart/ProfilePanel/PositionModules/PositionInfo"
import { Recruiting } from "v2/react/components/orgChart/ProfilePanel/PositionModules/Recruiting"
import { SuccessionPlan } from "v2/react/components/orgChart/ProfilePanel/PositionModules/SuccessionPlan"
import { useSortedBoxes } from "v2/react/components/orgChart/ProfilePanel/Settings/hooks/useSortedBoxes"
import { useCurrentActiveSession } from "v2/react/hooks/useSessionHooks"
import { opaqueBasic } from "v2/react/utils/framerAnimationVariants"

interface PositionModulesProps {
  abilities: ProfilePanelPermissions
}

function PositionModules({ abilities }: PositionModulesProps) {
  const { featureFlags: ff } = useCurrentActiveSession()
  const { sortedBoxes } = useSortedBoxes()
  const currentChart = appContainer.gon.selected_chart
  const inOfficialContext = currentChart === undefined || currentChart.official

  const positionModuleMap: Record<string, React.ReactNode> = {
    position_info: <PositionInfo key="position_info" abilities={abilities} featureFlags={ff} />,
    job_description: (
      <JobDescription key="job_description" abilities={abilities} featureFlags={ff} />
    ),
    position_planning: (
      <PlanningAndForecasting key="position_planning" abilities={abilities} featureFlags={ff} />
    ),
    succession_plan: (
      <SuccessionPlan key="succession_plan" abilities={abilities} featureFlags={ff} />
    ),
    recruiting: inOfficialContext && (
      <Recruiting key="recruiting" abilities={abilities} featureFlags={ff} />
    ),
    adp: <ADP key="adp" />,
    custom_fields: <CustomFields key="custom_fields" featureFlags={ff} />,
    allocations: <Allocations key="allocations" abilities={abilities} featureFlags={ff} />,
  }

  return (
    <AnimatePresence>
      <motion.div
        className="react-profile-panel__module"
        variants={opaqueBasic}
        initial="hidden"
        animate="shown"
        exit="hidden"
      >
        {sortedBoxes
          .filter((box: ProfilePanelBoxSettingSelection) => box.mode === "position")
          .map((box: ProfilePanelBoxSettingSelection) => positionModuleMap[box.name])}
      </motion.div>
    </AnimatePresence>
  )
}

export { PositionModules }
