import { globals } from "../../constants/options"

// Define a circle clip path for the avatar images. This is used to display
// the avatar as a circle.
const defineAvatarClipPath = (svg: d3.Selection<SVGElement>): d3.Selection<SVGElement> => {
  const radius = globals.avatarHeight / 2

  svg
    .select("defs")
    .append("clipPath")
    .attr("id", "avatar-clip-circle")
    .append("circle")
    .attr("cx", 0)
    .attr("cy", globals.nodePadding + globals.avatarHeight / 2)
    .attr("r", radius)

  return svg
}

export default defineAvatarClipPath
