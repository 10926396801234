import { StartOrgChartExportInput } from "types/graphql"
import { GraphqlApi } from "v2/redux/GraphqlApi"
import { mutationOperation } from "v2/redux/utils/endpoints"

export const OrgChartApi = GraphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    startOrgChartExport: builder.mutation({
      query: mutationOperation<StartOrgChartExportInput>("StartOrgChartExport"),
    }),
  }),
})

export const { useStartOrgChartExportMutation } = OrgChartApi
