import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { CodeBlock, tomorrowNight } from "react-code-blocks"

import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"

function Tooltips() {
  return (
    <div className="desktop:max-w-4xl">
      <section className="mb-12">
        <h1>Tooltips</h1>
        <p className="mb-4 mt-2">
          Tooltips are helpful for showing additional information. We most commonly use tooltips to
          display text descriptions for icon buttons, display error messages or the full content of
          text that has been truncated in the UI. We currently have a few different methods for
          applying tooltip classes to elements.
        </p>

        <h2>Good to Know: </h2>
        <ul>
          <li>
            The react tooltip component is built using Floating UI. Benefits of this include
            responding to placement within the viewport and allowing the tooltip to be visible
            outside of an overflow: hidden parent.
          </li>
          <li>
            The basic tooltip does not allow for visibility outside of an overflow: hidden parent.
          </li>
          <li>
            We also have a <code>popper-tooltip</code> class that does allow for visibility outside
            of an overflow: hidden parent but it&apos;s use is limited within the app.
          </li>
        </ul>
      </section>

      <section>
        <h2>Tooltip Types</h2>
        <p className="mb-4 mt-2">
          We have two basic types of tooltip styles: default and error. Beyond this, there are a few
          instances of alternative tooltip styles such as the tooltip within the funding source
          panel of the position modal and the job vote tooltip.
        </p>
        <div className="gap-8 flex">
          <button className="btn btn--secondary tooltip" type="button">
            <span>Default Style</span>
            <span className="tooltip-content">Can I offer you an egg in this trying time?</span>
          </button>
          <button className="btn btn--secondary tooltip" type="button">
            <span>Error Style</span>
            <span className="tooltip-content tooltip-content-error flex">
              <FontAwesomeIcon icon={["far", "exclamation-circle"]} />
              <span>How much cheese is too much cheese?</span>
            </span>
          </button>
        </div>
      </section>

      <section>
        <h2>Tooltip Sizes</h2>
        <p className="mb-4 mt-2">
          We have two basic sizes of tooltip - small and regular. As a general rule of thumb, small
          tooltips are used to display 1-3 words of additional information while the regular tooltip
          is used to display longer messages.
        </p>
        <div className="gap-8 flex">
          <button className="btn btn--secondary tooltip" type="button">
            <span>Regular Size</span>
            <span className="tooltip-content">Can I offer you an egg in this trying time?</span>
          </button>
          <button className="btn btn--secondary tooltip" type="button">
            <span>Small Size</span>
            <span className="tooltip-content tooltip-content--sm">
              How much cheese is too much cheese?
            </span>
          </button>
        </div>
      </section>

      <section>
        <h2>Basic Tooltip</h2>
        <p className="my-2">
          At the most basic level, you can apply the <code>tooltip</code> class to an element to
          show the <code>tooltip-content</code> contained within the element. By default, the
          tooltip will display below the <code>tooltip</code> element. You can also provide an
          additional placement class to change this location.
        </p>
        <section className="grid-cols-3 gap-8 py-4 grid">
          <button className="btn btn--primary tooltip" type="button">
            <span>Tooltip Default</span>
            <span className="tooltip-content">What&apos;s your spaghetti policy?</span>
          </button>

          <button className="btn btn--primary tooltip tooltip-center" type="button">
            <span>Tooltip Center</span>
            <span className="tooltip-content">I got followed here by like ten cats.</span>
          </button>

          <button className="btn btn--primary tooltip tooltip-right" type="button">
            <span>Tooltip Right</span>
            <span className="tooltip-content">The economy is in shambles.</span>
          </button>
        </section>
        <section className="grid-cols-3 gap-8 py-4 grid">
          <button className="btn btn--primary tooltip tooltip-side-align-right" type="button">
            <span>Side Align Right</span>
            <span className="tooltip-content">I&apos;ve got the stride of a gazelle.</span>
          </button>

          <div />

          <button className="btn btn--primary tooltip tooltip-side-align-left" type="button">
            <span>Side Align Left</span>
            <span className="tooltip-content">I&apos;ve got the stride of a gazelle.</span>
          </button>
        </section>
        <section className="grid-cols-3 gap-8 py-4 grid">
          <button className="btn btn--primary tooltip tooltip-top-align-left" type="button">
            <span>Tooltip Top Align Left</span>
            <span className="tooltip-content">The economy is in shambles.</span>
          </button>

          <button className="btn btn--primary tooltip tooltip-top" type="button">
            <span>Tooltip Top</span>
            <span className="tooltip-content">Where jobs grow on jobbies.</span>
          </button>

          <button className="btn btn--primary tooltip tooltip-top-align-right" type="button">
            <span>Tooltip Top Align Right</span>
            <span className="tooltip-content">
              I am too muscular, and I can&apos;t fit through.
            </span>
          </button>
        </section>

        <section>
          <h2>React Tooltip</h2>
          <div className="my-2">
            <p>
              On the react side of things, we still have the option to use the basic tooltip class
              but we also have a reusable Tooltip component. By default, the tooltip will default to
              the best position if that property is left undefined. Use the{" "}
              <code>react-tooltip-content</code> class for the tooltip content itself. The default
              sizing is regular, similar to the buttons.
            </p>
            <p>
              You can use <code>react-tooltip-content--sm</code> instead in order to use the smaller
              tooltip size. If you are displaying an error tooltip, you can add
              <code>react-tooltip-content-error</code> to change the style of the tooltip.
            </p>
          </div>

          <section className="gap-8 py-4 flex">
            <Tooltip>
              <TooltipTrigger className="w-fit block">
                <button className="btn btn--primary" type="button">
                  Tooltip Default
                </button>
              </TooltipTrigger>
              <TooltipContent className="react-tooltip-content">
                <span>I am untethered and my rage knows no bounds.</span>
              </TooltipContent>
            </Tooltip>

            <Tooltip placement="top">
              <TooltipTrigger className="w-fit block">
                <button className="btn btn--primary" type="button">
                  Tooltip Top
                </button>
              </TooltipTrigger>
              <TooltipContent className="react-tooltip-content react-tooltip-content-error">
                <span>Science is a liar sometimes.</span>
              </TooltipContent>
            </Tooltip>

            <Tooltip placement="left">
              <TooltipTrigger className="w-fit block">
                <button className="btn btn--primary" type="button">
                  Tooltip Left
                </button>
              </TooltipTrigger>
              <TooltipContent className="react-tooltip-content">
                <span>I do backflips every single day of my life.</span>
              </TooltipContent>
            </Tooltip>
          </section>
        </section>

        <section className="grid-cols-2 gap-4 grid">
          <div>
            <p className="my-2">Code examples for basic tooltip classes:</p>
            <CodeBlock text={basicTooltipExamples} theme={tomorrowNight} language="javascript" />
          </div>
          <div>
            <p className="my-2">Code examples for react tooltip classes:</p>
            <CodeBlock text={reactTooltipExamples} theme={tomorrowNight} language="javascript" />
          </div>
        </section>
      </section>
    </div>
  )
}

export default Tooltips

const basicTooltipExamples = `<button className="tooltip">
  <span>Tooltip Default</span>
  <span className="tooltip-content">tooltip content</span>
</button>

<button className="tooltip tooltip-right">
  <span>Tooltip Right</span>
  <span className="tooltip-content">tooltip content</span>
</button>

<button className="tooltip tooltip-top-align-right">
  <span>Tooltip Top Align Right</span>
  <span className="tooltip-content">tooltip content</span>
</button>

<button className="tooltip tooltip-top">
  <span>Tooltip Top</span>
  <span className="tooltip-content">tooltip content</span>
</button>

<button className="tooltip tooltip-side-align-right">
  <span>Side Align Right</span>
  <span className="tooltip-content">tooltip content</span>
</button>

<button className="tooltip tooltip-center">
  <span>Tooltip Center</span>
  <span className="tooltip-content">tooltip content</span>
</button>`

const reactTooltipExamples = `<Tooltip>
  <TooltipTrigger className="block w-fit">
    <button>
      Tooltip Default
    </button>
  </TooltipTrigger>
  <TooltipContent className="react-tooltip-content">
    <span>tooltip content</span>
  </TooltipContent>
</Tooltip>

<Tooltip placement="top">
  <TooltipTrigger className="block w-fit">
    <button>
      Tooltip Top
    </button>
  </TooltipTrigger>
  <TooltipContent className="react-tooltip-content react-tooltip-content-error">
    <span>tooltip content</span>
  </TooltipContent>
</Tooltip>
`
