/* eslint-disable no-param-reassign */
import { EntityId } from "@reduxjs/toolkit"
import fp from "lodash/fp"

import type { Maybe } from "types/graphql"
import { InlineAddOrgUnitForm } from "v2/react/components/orgUnits/InlineAddOrgUnitForm"
import { idFromUniqueKey } from "v2/react/utils/uniqueKey"

type InlineOrgUnitFormConfig = {
  contextEl: Maybe<HTMLElement>
  unitInput: Maybe<HTMLInputElement>
}

type OuterForm = {
  contextEl: HTMLElement
  disable: () => void
  enable: () => void
  outerSaveButton: HTMLButtonElement
  targetUnitsContainer: HTMLElement
  unitIdInput: HTMLInputElement
  unitInput: HTMLInputElement
  unitNameInput: HTMLInputElement
  unitCodeInput: HTMLInputElement
  setUnitInputValue: (value: string) => void
}

type AddOrgUnitForm = {
  container: HTMLElement
  reactForm: HTMLElement
  expand: () => void
  collapse: () => void
}

export type CancelInitiatorAction = "cancelButton" | "focusOutside"

enum BlurAction {
  None,
  ClearAllOrgUnitData,
  ClearOrgUnitId,
}

const autocompleteDatasetIdRegex = /_attributes_([1-9][0-9]*)_org_unit_id$/

const toFullOrgUnitName = (code: Maybe<string> | undefined, name: Maybe<string> | undefined) =>
  fp.pipe(fp.compact, fp.join(" - "))([code, name])

export const InlineOrgUnitForm = {
  /**
   * Supports clearing an org unit if the user
   * blurs and the input is incomplete.
   */
  attachOrgUnitAutocompleteBehavior(ev: MouseEvent) {
    const input = ev.target instanceof HTMLInputElement ? ev.target : null
    const $input = window.$(input)
    const typeId = input?.dataset?.id?.match(autocompleteDatasetIdRegex)?.[1]
    const inputGroup = input?.closest<HTMLElement>(".input-group")
    if (!typeId || !inputGroup) return

    const outerForm = makeOuterFormContext(input, input, { typeId })

    const behavior = makeAutocompleteBlurBehaviorState(input, typeId)
    const handlers = {
      blur(event: FocusEvent) {
        // If it's blurring from opening the subform, don't clear the data.
        const isOnHTMLElement = event.relatedTarget instanceof HTMLElement
        const isOnSubform = isOnHTMLElement && event.relatedTarget.closest(".InlineOrgUnitForm")
        if (isOnSubform) behavior.setBlurAction(BlurAction.None)

        behavior.applyBlurAction()
        cleanUp()
      },
      keyDown(event: Event) {
        const isKeyboardEvent = event instanceof KeyboardEvent
        const isNotEnterPress = isKeyboardEvent && event.key !== "Enter"
        if (!isKeyboardEvent || isNotEnterPress) return

        // We only want to proceed if the only element with "highlight" is the
        // "Add Org Unit" button.
        const highlighted = inputGroup.querySelector<HTMLElement>(".highlight")
        const triggerLink = inputGroup.querySelector<HTMLElement>("#show-add-org-unit-form")
        const isNotOnAddOrgUnit = !highlighted || !highlighted.classList.contains("add-org-unit")
        if (isNotOnAddOrgUnit || !triggerLink) return

        behavior.setBlurAction(BlurAction.None)
        input.blur()

        // See: webpack/v2/modals/position_modal.js for the event listener.
        window.$(triggerLink).trigger("mousedown")
      },
      mouseDown(event: Event) {
        const isOnHTMLElement = event.target instanceof HTMLElement
        const isWithinAutocomplete = isOnHTMLElement && event.target.closest(".autocomplete-list")
        if (isWithinAutocomplete) behavior.setBlurAction(BlurAction.None)
      },
      transitionOnBlurActionFromEvent(event: Event) {
        const isAutocompleteSelect = event.type === "autocomplete:selectSuggestion"
        const isOnInput = event.currentTarget instanceof HTMLInputElement
        const isInputBlank = isOnInput && event.currentTarget.value.trim() === ""

        if (isAutocompleteSelect) behavior.setBlurAction(BlurAction.None)
        else if (isOnInput)
          behavior.setBlurAction(
            BlurAction[isInputBlank ? "ClearOrgUnitId" : "ClearAllOrgUnitData"],
          )
      },
      focus(event: Event) {
        // If the input receives focus, and there's incomplete data, clear it on blur.
        const isOnInput = event.currentTarget instanceof HTMLInputElement
        const isInputIncomplete = outerForm.unitIdInput.value.trim() === ""

        if (isOnInput && isInputIncomplete) {
          behavior.setBlurAction(BlurAction.ClearAllOrgUnitData)
        }
      },
    }

    input.addEventListener("blur", handlers.blur)
    input.addEventListener("input", handlers.transitionOnBlurActionFromEvent)
    input.addEventListener("keydown", handlers.keyDown)
    input.addEventListener("focus", handlers.focus)
    inputGroup.addEventListener("mousedown", handlers.mouseDown)
    $input.on("autocomplete:selectSuggestion", handlers.transitionOnBlurActionFromEvent)
    const cleanUp = () => {
      input.removeEventListener("blur", handlers.blur)
      input.removeEventListener("keydown", handlers.keyDown)
      input.removeEventListener("input", handlers.transitionOnBlurActionFromEvent)
      input.removeEventListener("focus", handlers.focus)
      inputGroup.removeEventListener("mousedown", handlers.mouseDown)
      $input.off("autocomplete:selectSuggestion", handlers.transitionOnBlurActionFromEvent)
    }
  },

  mount(config: InlineOrgUnitFormConfig) {
    const { contextEl, unitInput } = config
    const outerForm = makeOuterFormContext(contextEl, unitInput)
    const form = makeAddFormContext()

    // Extract initial data from the outer form/input and mount the inline
    // form. We use its callbacks to manage anything outside the React
    // component tree.
    const fullOrgUnit = fp.split(/\s*-\s*/, outerForm.unitInput.value)
    let unitCode = fp.head(fullOrgUnit) || ""
    let unitName = fp.pipe(fp.tail, fp.join(" - "))(fullOrgUnit)
    if (!unitName && unitCode) {
      unitName = unitCode
      unitCode = ""
    }

    InlineAddOrgUnitForm.mount({
      inElement: form.reactForm,
      props: {
        initialData: { code: unitCode, name: unitName },
        onCancel(event?: Event) {
          const isOnHTMLElement = event?.target instanceof HTMLElement
          const isOnCancelButton =
            isOnHTMLElement && event?.target.id === "cancel-add-org-unit-action"

          collapseSubFormAndUpdateOuterInput({
            outerForm,
            form,
            cancelInitiator: isOnCancelButton ? "cancelButton" : "focusOutside",
          })
        },
        onSave({ id, code, name }) {
          outerForm.unitIdInput.value = id ? idFromUniqueKey(id) : ""
          outerForm.unitCodeInput.value = code || ""
          outerForm.unitNameInput.value = name || ""

          collapseSubFormAndUpdateOuterInput({
            outerForm,
            form,
            nextInputValue: toFullOrgUnitName(code, name),
          })
        },
        orgUnitType: {
          id: `org_unit_type_${contextEl?.dataset.id || ""}`,
          name: contextEl?.dataset.typename || "",
        },
      },
    })

    positionSubformRelativeToInput(outerForm, form)
    outerForm.disable()
    form.expand()
  },
}

function clearOrgUnitDataWithOuterForm(outerForm: OuterForm) {
  outerForm.unitIdInput.value = ""
  outerForm.unitNameInput.value = ""
  outerForm.unitCodeInput.value = ""
  outerForm.unitInput.value = ""
}

function makeAutocompleteBlurBehaviorState(input: HTMLInputElement, typeId: string) {
  const outerForm = makeOuterFormContext(input, input, { typeId })
  let blurAction: BlurAction = BlurAction.None
  return {
    applyBlurAction() {
      if (blurAction === BlurAction.ClearOrgUnitId) {
        outerForm.unitIdInput.value = ""
      } else if (blurAction === BlurAction.ClearAllOrgUnitData) {
        clearOrgUnitDataWithOuterForm(outerForm)
      }
    },
    setBlurAction(updatedAction: BlurAction) {
      blurAction = updatedAction
    },
  }
}

function makeOuterFormContext(
  contextEl: Maybe<HTMLElement>,
  unitInput: Maybe<HTMLInputElement>,
  options?: { typeId?: EntityId },
): OuterForm {
  const typeId = options?.typeId ?? contextEl?.dataset?.id
  const container = unitInput?.closest<HTMLElement>(".inline-org-units")
  // In some cases, the submit button maybe rendered outside of the form.
  // Ascend to the top modal element before seeking for the submit button.
  const outerSaveButton = container
    ?.closest<HTMLElement>(".modal")
    ?.querySelector<HTMLButtonElement>("[type=submit]")
  const selectOwnerInput = (suffix: string) =>
    container?.querySelector<HTMLInputElement>(
      `#position_org_units_attributes_${typeId}_${suffix}`,
    ) ||
    container?.querySelector<HTMLInputElement>(
      `#position_type_org_unit_position_type_links_attributes_${typeId}_${suffix}`,
    )

  return asValidContext<OuterForm>({
    contextEl,
    outerSaveButton,
    unitInput,
    unitIdInput: selectOwnerInput("org_unit_id"),
    unitNameInput: selectOwnerInput("org_unit_name"),
    unitCodeInput: selectOwnerInput("org_unit_code"),
    targetUnitsContainer: container,
    disable() {
      if (!outerSaveButton?.classList?.contains("btn-disabled"))
        outerSaveButton?.classList?.add("btn-disabled")
      if (outerSaveButton) outerSaveButton.disabled = true
    },
    enable() {
      outerSaveButton?.classList?.remove("btn-disabled")
      if (outerSaveButton) outerSaveButton.disabled = false
    },
    setUnitInputValue(value) {
      if (unitInput) unitInput.value = value
    },
  })
}

function makeAddFormContext(): AddOrgUnitForm {
  const container = document.querySelector<HTMLElement>(".InlineOrgUnitForm")

  return asValidContext({
    container,
    reactForm: container,
    expand() {
      container?.classList.remove("hidden")
      container?.classList.add("block")
    },
    collapse() {
      container?.classList.remove("block")
      container?.classList.add("hidden")
    },
  })
}

function asValidContext<T>(input: Partial<{ [Prop in keyof T]: Maybe<T[Prop]> }>): {
  [Prop in keyof T]: NonNullable<T[Prop]>
} {
  const pairs: [string, HTMLElement | null][] = fp.toPairs(input)
  const badPairs = fp.filter(([, maybeEl]) => fp.isNil(maybeEl), pairs)
  const badKeys = fp.compact(badPairs.map(([key]) => key))

  if (badKeys.length > 0) {
    throw new Error(`Unable to build a valid form context due to ${JSON.stringify(badKeys)}`)
  }

  return input as { [Prop in keyof T]: NonNullable<T[Prop]> }
}

// Places the subform below the input, but will shift it to the left or right
// if it overflows the parent container. Eventually we may want to consider offloading
// this to floating-ui.
function positionSubformRelativeToInput(outerForm: OuterForm, form: AddOrgUnitForm) {
  const SUBFORM_MARGIN = 8

  const { unitInput } = outerForm
  const {
    left: unitInputLeft,
    top: unitInputTop,
    right: unitInputRight,
  } = unitInput.getBoundingClientRect()

  const parentRect = outerForm.targetUnitsContainer.getBoundingClientRect()
  const parentLeft = parentRect?.left || 0
  const parentRight = parentRect?.right || 0
  const parentTop = parentRect?.top || 0

  const subformStyle = window.getComputedStyle(form.container)
  const subformWidth = parseInt(subformStyle.width.replace("px", ""), 10) || 0

  const overflowsRight = parentRight - unitInputLeft < subformWidth
  const overflowsLeft = unitInputRight - parentLeft < subformWidth

  if (overflowsRight && !overflowsLeft) {
    form.container.style.left = "auto"
    form.container.style.right = `${parentRight - unitInputRight}px`
  } else {
    form.container.style.left = `${unitInputLeft - parentLeft}px`
    form.container.style.right = "auto"
  }

  form.container.style.top = `${
    unitInputTop - parentTop + unitInput.offsetHeight + SUBFORM_MARGIN
  }px`
}

function collapseSubFormAndUpdateOuterInput({
  outerForm,
  form,
  nextInputValue,
  cancelInitiator,
}: {
  outerForm: OuterForm
  form: AddOrgUnitForm
  nextInputValue?: string | undefined
  cancelInitiator?: CancelInitiatorAction
}) {
  form.collapse()

  if (nextInputValue && !cancelInitiator) {
    // Update the input value.
    outerForm.setUnitInputValue(nextInputValue)
  } else if (cancelInitiator === "focusOutside") {
    clearOrgUnitDataWithOuterForm(outerForm)
  } else if (cancelInitiator === "cancelButton") {
    // Clear the org unit id.
    outerForm.unitIdInput.value = ""
    // Focus back on the input and show the autocomplete dropdown.
    const $input = outerForm.unitInput
    $input.focus()
    window.$($input).siblings(".autocomplete-container").show()
  }

  outerForm.enable()
}
/* eslint-enable no-param-reassign */
