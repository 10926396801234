import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React, { forwardRef } from "react"

import { Chart, ChartApprover } from "types/graphql"
import { statusColorLookup } from "v2/react/shared/status/statusUtils"

interface Props {
  approvers: ChartApprover[]
  chart: Chart
  displayType: "button" | "badge"
  referenceProps?: Record<string, unknown>
}

const SubmittedStatusButton = forwardRef<HTMLButtonElement, Props>(
  ({ approvers, chart, displayType, referenceProps }, ref) => {
    const buttonClasses = cn(chart.status && `!h-6 badge--${statusColorLookup(chart.status)}`, {
      hover: displayType === "button",
      "cursor-default": displayType === "badge",
      "btn--large btn--secondary": !chart.status,
    })

    const countApprovers = (approvers: ChartApprover[]): number =>
      approvers.reduce(
        (sum: number, val: ChartApprover) => (val.status === "approved" ? sum + 1 : sum),
        0,
      )

    const statusText: Record<string, string> = {
      denied: "denied_approval".t("org_chart"),
      changes_requested: "changes_requested".t("org_chart"),
      approved: "approved".t("org_chart"),
      awaiting_approval: `${countApprovers(approvers)}/${approvers.length} ${"approved".t(
        "org_chart",
      )}`,
    }

    return (
      <button
        type="button"
        className={buttonClasses}
        ref={ref}
        /* eslint-disable react/jsx-props-no-spreading */
        {...referenceProps}
      >
        <span className="text-sm font-bold">{chart.status && statusText[chart.status]}</span>
        {displayType === "button" && <FontAwesomeIcon icon={["fas", "caret-down"]} size="1x" />}
      </button>
    )
  },
)

export { SubmittedStatusButton }
