import { isEqual } from "lodash"

import {
  appendFiltersToQueryParams,
  extractFiltersFromQueryParams,
  prepareFiltersForSubmission,
} from "v2/react/shared/tables/TableUtilities/FilterTable/utils/filters"
import { getPjaxContainerForTable } from "v2/react/shared/tables/TableUtilities/UtilityPanel"
import { pjaxReload } from "v2/react/utils/pjax"
import { applyFilters } from "v2/redux/slices/TableFiltersSlice"

import { startAppListening } from "../listenerMiddleware"

const startListeningForFilterSubmission = () =>
  startAppListening({
    actionCreator: applyFilters,
    effect: async ({ payload: { table, filters } }) => {
      const previousFilters = extractFiltersFromQueryParams({})
      const currentFilters = prepareFiltersForSubmission({
        filters,
        excludeEmpty: true,
      })

      // No need to reload the table if the active filters haven't changed.
      if (isEqual(previousFilters, currentFilters)) return

      appendFiltersToQueryParams({ filters: currentFilters })

      const container = getPjaxContainerForTable(table)
      if (!container) return

      pjaxReload({
        container,
        fragment: container,
      })
    },
  })

export { startListeningForFilterSubmission }
