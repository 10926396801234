import { isEqual } from "lodash"
import { createSelector, createStructuredSelector } from "reselect"

import { FilterableTables } from "types/graphql.enums"
import { TablesApi } from "v2/redux/GraphqlApi/TablesApi"
import { RootState } from "v2/redux/store"

const selectFilterSettingsResult = TablesApi.endpoints.getFilterSettings.select({
  table: FilterableTables.PositionsPage,
})

const selectFilterSettings = createSelector(
  selectFilterSettingsResult,
  (result) => result?.data?.currentPerson?.settings?.tableFilterSettings,
)

const getAllFilterOptions = createSelector(
  selectFilterSettings,
  (settings) => settings?.filterableColumns || [],
)

const getSelectedFilters = createSelector(
  selectFilterSettings,
  (settings) => settings?.selectedFilters || [],
)

const getEnrichedSelectedFilters = createSelector(
  selectFilterSettings,
  (settings) => settings?.enrichedSelectedFilters || [],
)

/**
 * Finds a specific enriched selected filter by its id.
 */
const getEnrichedSelectedFilter = (filterId: string) =>
  createSelector(getEnrichedSelectedFilters, (enrichedFilters) =>
    enrichedFilters.find((filter) => filter.id === filterId),
  )

const selectFormKey = (state: RootState) => state.tableFilters.formKey
const selectFilters = (state: RootState) => state.tableFilters.filters
const selectInitialFilters = (state: RootState) => state.tableFilters.initialFilters
const selectTable = (state: RootState) => state.tableFilters.table

const selectFiltersAndInitialFilters = createStructuredSelector({
  filters: selectFilters,
  initialFilters: selectInitialFilters,
})

const selectAnyFilterChanges = createSelector(
  [selectFilters, selectInitialFilters],
  (filters, initialFilters) => !isEqual(filters, initialFilters),
)

/**
 * Contains all of the variables needed for the filter panel.
 */
const filterPanelSelectors = createStructuredSelector({
  allFilterOptions: getAllFilterOptions,
  anyChanges: selectAnyFilterChanges,
  filters: selectFilters,
  formKey: selectFormKey,
  initialFilters: selectInitialFilters,
})

export {
  filterPanelSelectors,
  getAllFilterOptions,
  getEnrichedSelectedFilter,
  getEnrichedSelectedFilters,
  getSelectedFilters,
  selectAnyFilterChanges,
  selectFiltersAndInitialFilters,
  selectInitialFilters,
  selectFilterSettings,
  selectTable,
}
