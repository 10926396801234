import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useTranslation } from "react-i18next"

import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"

interface Props {
  importUrl: string
}

const ImportButton = ({ importUrl }: Props) => {
  const { t } = useTranslation()

  return (
    <Tooltip placement="bottom-start">
      <TooltipTrigger>
        <a id="import-link" className="btn--large btn--secondary w-11" href={importUrl}>
          <FontAwesomeIcon icon={["far", "upload"]} />
        </a>
      </TooltipTrigger>
      <TooltipContent className="react-tooltip-content--sm">
        <span>{t("v2.defaults.import")}</span>
      </TooltipContent>
    </Tooltip>
  )
}

export { ImportButton }
