import { createCalendar } from "@internationalized/date"
import React, { useRef } from "react"
import { AriaCalendarProps, DateValue, useCalendar, useLocale } from "react-aria"
import { useCalendarState } from "react-stately"

import { Calendar } from "v2/react/shared/forms/DateInputs/shared/Calendar"
import { useCalendarDateContext } from "v2/react/shared/forms/DateInputs/shared/Calendar/hooks/useCalendarDateContext"

const PickerCalendar = <T extends DateValue>({ ...props }: AriaCalendarProps<T>) => {
  const ref = useRef(null)
  const { locale } = useLocale()
  const state = useCalendarState({
    ...props,
    locale,
    createCalendar,
  })

  const { prevButtonProps, nextButtonProps, title } = useCalendar(props, state)

  const { months, activeMonth, years, activeYear } = useCalendarDateContext({
    focusedDate: state.focusedDate,
    startDate: props.value,
    minValue: props.minValue,
    maxValue: props.maxValue,
  })

  return (
    <Calendar
      state={state}
      title={title}
      months={months}
      activeMonth={activeMonth}
      years={years}
      activeYear={activeYear}
      calendarRef={ref}
      prevButtonProps={prevButtonProps}
      nextButtonProps={nextButtonProps}
      onSetVisibleRange={state.setFocusedDate}
    />
  )
}

export { PickerCalendar }
