import classNames from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import { UpdateProfilePanelModeSettingInput } from "types/graphql"
import { ProfilePanelMode } from "types/graphql.enums"
import { useUpdateProfilePlanModeSettingMutation } from "v2/redux/GraphqlApi/PeopleApi"
import { setProfilePanelMode } from "v2/redux/slices/ProfilePanelSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

function PanelToggle({ panelMode }: { panelMode: ProfilePanelMode }) {
  const person = useAppSelector((state) => state.profilePanel.person)
  const position = useAppSelector((state) => state.profilePanel.position)

  const [updateMode] = useUpdateProfilePlanModeSettingMutation()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const buttonStyles = (mode: ProfilePanelMode) =>
    classNames("btn", {
      active: mode === panelMode,
      "btn-disabled":
        (mode === ProfilePanelMode.Person && !person) ||
        (mode === ProfilePanelMode.Position && !position),
    })

  const changePanelMode = (mode: ProfilePanelMode) => {
    dispatch(setProfilePanelMode(mode))
    const input: UpdateProfilePanelModeSettingInput = { profilePanelMode: mode }
    updateMode(input)
  }

  return (
    <div className="react-profile-panel__toggle btn-group">
      <button
        type="button"
        disabled={!position}
        className={buttonStyles(ProfilePanelMode.Position)}
        onClick={() => changePanelMode(ProfilePanelMode.Position)}
      >
        {t("v2.profile_panel.position")}
      </button>
      <button
        type="button"
        disabled={!person}
        className={buttonStyles(ProfilePanelMode.Person)}
        onClick={() => changePanelMode(ProfilePanelMode.Person)}
      >
        {t("v2.profile_panel.person")}
      </button>
    </div>
  )
}

export { PanelToggle }
