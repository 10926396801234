import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React, { useState } from "react"

import { Popover, PopoverContent, PopoverTrigger } from "v2/react/shared/overlay/Popover"

export interface SelectOption {
  headerDisplay: React.ReactNode
  fieldDisplay: React.ReactNode
  text: string
}
interface Props<ItemOption extends SelectOption> {
  disabled?: boolean
  dropdownClassNames?: string
  options: ItemOption[]
  initialOption: ItemOption
  onSelection: (selected: ItemOption) => void
  triggerClassNames?: string
}

function SelectDropdown<ItemOption extends SelectOption>({
  disabled,
  dropdownClassNames,
  options,
  initialOption,
  onSelection,
  triggerClassNames,
}: Props<ItemOption>) {
  const [isOpen, setIsOpen] = useState(false)
  const [updatedOption, setUpdatedOption] = useState<ItemOption>()
  const toggleDropdown = () => setIsOpen(!isOpen)
  const selectedOption: ItemOption = initialOption || updatedOption
  const onOptionClicked = (value: ItemOption) => {
    setUpdatedOption(value)
    onSelection(value)
    setIsOpen(false)
  }

  return (
    <Popover placement="bottom-end" open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger
        onClick={toggleDropdown}
        className={cn(
          "btn--large btn--secondary min-w-[8.5rem] items-center justify-between flex",
          triggerClassNames,
          { "btn-disabled": disabled },
        )}
        disabled={disabled}
      >
        {selectedOption.headerDisplay}
        <FontAwesomeIcon icon={["fas", "caret-down"]} />
      </PopoverTrigger>
      <PopoverContent
        className={cn(
          "elevation--overlay z-20 min-w-[12.5rem] rounded-lg bg-white p-2",
          dropdownClassNames,
        )}
      >
        {options.map((option) => (
          <li
            role="menuitem"
            onClick={() => onOptionClicked(option)}
            onKeyDown={() => onOptionClicked(option)}
            className={cn("select-dropdown__option", {
              active: option.text === selectedOption.text,
            })}
            key={option.text}
          >
            {option.fieldDisplay}
            <FontAwesomeIcon
              icon={["fas", "check-circle"]}
              className="selected-indicator ml-auto"
            />
          </li>
        ))}
      </PopoverContent>
    </Popover>
  )
}

export { SelectDropdown }
