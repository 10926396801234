import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import AnimateHeight from "react-animate-height"

import { ListItem } from "./OrderedList"

interface AnimatedListItem extends ListItem {
  height: "auto" | 0
}

interface OrderedListItemProps {
  isFirst: boolean
  isLast: boolean
  item: ListItem
  onItemRemoved: (item: ListItem) => void
}

const ANIMATION_DURATION = 250
const OrderedListItem = ({ isFirst, isLast, item, onItemRemoved }: OrderedListItemProps) => {
  // For list item animations, an adaptation with credit to:
  // https://muffinman.io/react-animate-height/
  // https://github.com/Stanko/react-animate-height#demo
  const itemClasses = `grow OrderedList__list-item${itemSuffix(isFirst, isLast)} ${
    item.disable ? "OrderedList__list-item-disabled" : ""
  }`
  const indexClasses = `OrderedList__list-item-index OrderedList__list-item-index-secondary${
    item.disable ? "-light" : ""
  }`

  const animatedItem: AnimatedListItem = {
    disable: item.disable,
    height: "auto",
    index: item.index,
    label: item.label,
    thumbUrl: item.thumbUrl,
    value: item.value,
  }

  const handleRemoveValueClick = () => {
    const removedItem: AnimatedListItem = {
      ...item,
      height: 0,
    }
    setTimeout(() => {
      if (!item) return
      // This is where the actual delete is happening
      if (onItemRemoved) onItemRemoved(removedItem)
    }, ANIMATION_DURATION)
  }

  return (
    <AnimateHeight duration={ANIMATION_DURATION} height={animatedItem.height}>
      <div className="bg-transparent flex">
        <div className={itemClasses}>
          <p className={indexClasses}>{animatedItem.index + 1}</p>
          <div className="OrderedList__list-item-content">
            {animatedItem.thumbUrl && (
              <div className="circle OrderedList__list-item-thumb">
                <img alt={animatedItem.label} src={animatedItem.thumbUrl} />
              </div>
            )}
            <span>{animatedItem.label}</span>
          </div>
          {animatedItem.disable && (
            <span className="ml-auto mr-3 self-center">{animatedItem.disable}</span>
          )}
        </div>
        {animatedItem.disable ? (
          <span className="dragging-hide ml-3 mr-1 w-[8.75px] block" />
        ) : (
          <button
            className="dragging-hide ml-3 mr-1 bg-transparent p-0"
            onClick={handleRemoveValueClick}
            data-value={animatedItem.value}
            type="button"
          >
            <FontAwesomeIcon icon={["far", "times"]} className="icon-14" />
          </button>
        )}
      </div>
    </AnimateHeight>
  )
}

export const itemSuffix = (isFirst: boolean, isLast: boolean): string => {
  if (isFirst && isLast) return " list-item-first list-item-last"
  if (isFirst) return " list-item-first"
  if (isLast) return " list-item-last"
  return ""
}

export { OrderedListItem }
