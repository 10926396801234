import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { Position } from "types/graphql"
import { EditDeleteMenu } from "v2/react/components/succession/SuccessionPlanPanel/Summary/EditDeleteMenu"
import { WysiwygTextArea } from "v2/react/shared/forms/WysiwygTextArea"
import { BlueIcon } from "v2/react/shared/icons/BlueIcon"
import { InlineSpinner } from "v2/react/shared/loaders/Spinner"
import { Modal, ModalFooter } from "v2/react/shared/overlay/Modal"
import { removeHtmlTagsAndTrim, sanitizeParse } from "v2/react/utils/sanitizeParse"
import { useUpdateSuccessionPlanSummaryMutation } from "v2/redux/GraphqlApi/SuccessionApi"

interface SummaryProps {
  canEditSuccessionPlan: boolean
  position: Position
}

function Summary({ canEditSuccessionPlan, position }: SummaryProps) {
  const { t } = useTranslation()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showEditor, setShowEditor] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [updatedSummary, setUpdatedSummary] = useState(position.successionPlan?.summary || "")
  const [updateSummary, { isLoading }] = useUpdateSuccessionPlanSummaryMutation()

  const showActionMenu =
    position.successionPlan?.summary &&
    position.successionPlan?.summary.length > 0 &&
    canEditSuccessionPlan &&
    !showEditor

  const showSummary =
    position.successionPlan?.summary &&
    position.successionPlan?.summary.length > 0 &&
    !showEditor &&
    position.successionPlan.summary === updatedSummary

  const showEmptyState =
    (!position.successionPlan?.summary || position.successionPlan?.summary?.length === 0) &&
    canEditSuccessionPlan &&
    !showEditor

  const handleDelete = () => {
    setShowMenu(false)
    setShowConfirmation(true)
  }

  const handleEdit = () => {
    setShowMenu(false)
    setShowEditor(true)
  }

  const handleSave = () => {
    const trimmedSummary = removeHtmlTagsAndTrim(updatedSummary)

    updateSummary({
      positionId: position.id,
      summary: trimmedSummary.length > 0 ? updatedSummary : null,
    })

    if (!trimmedSummary.length) {
      setUpdatedSummary("")
    }

    setShowEditor(false)
  }

  const handleConfirmationClose = () => {
    setShowConfirmation(false)
  }

  return (
    <>
      <div className="module-card succession-plan-summary mb-0">
        <div className="module-card__header rounded-t-xl bg-primary-3">
          <div className="text-base-bold">{t("v2.succession_plan_panel.summary.plan_summary")}</div>
          {showActionMenu && (
            <EditDeleteMenu
              buttonClasses="btn--icon btn--secondary"
              deleteButtonText={t("v2.succession_plan_panel.summary.actions.delete_plan_summary")}
              editButtonText={t("v2.succession_plan_panel.summary.actions.edit_plan_summary")}
              onDelete={handleDelete}
              onEdit={handleEdit}
              setShowMenu={setShowMenu}
              showMenu={showMenu}
            />
          )}
        </div>
        <div className="module-card__body">
          {showEditor && (
            <div className="flex-col gap-2 flex">
              <WysiwygTextArea
                defaultValue={updatedSummary}
                id={`plan_${position.successionPlan?.id}_summary`}
                onChange={setUpdatedSummary}
              />
              <div className="justify-end gap-2 flex">
                <button
                  className="btn btn--secondary"
                  onClick={() => setShowEditor(false)}
                  type="button"
                >
                  {t("v2.defaults.cancel")}
                </button>
                <button
                  className="btn btn--primary"
                  disabled={position.successionPlan?.summary === updatedSummary}
                  onClick={handleSave}
                  type="button"
                >
                  {t("v2.defaults.save")}
                </button>
              </div>
            </div>
          )}
          {showSummary && (
            <div className="formatted-block">
              {sanitizeParse(position.successionPlan?.summary || "")}
            </div>
          )}
          {isLoading && (
            <div className="mx-auto my-4">
              <InlineSpinner />
            </div>
          )}
          {showEmptyState && (
            <div className="flex-col items-center gap-4 flex">
              <BlueIcon icon={["far", "edit"]} size="med-lg" />
              <div className="w-[70%] text-center">
                <p>{t("v2.succession_plan_panel.summary.plan_summary_instructions")}</p>
              </div>
              <button
                className="btn--large btn--secondary"
                type="button"
                onClick={() => setShowEditor(true)}
              >
                <FontAwesomeIcon icon={["far", "plus"]} />
                {t("v2.succession_plan_panel.summary.add_plan_summary")}
              </button>
            </div>
          )}
        </div>
      </div>
      <PlanSummaryConfirmationModal
        isOpen={showConfirmation}
        onClose={handleConfirmationClose}
        positionId={position.id}
      />
    </>
  )
}

export { Summary }

interface PlanSummaryConfirmationModalProps {
  isOpen: boolean
  onClose: () => void
  positionId: string
}

const PlanSummaryConfirmationModal = ({
  isOpen,
  onClose,
  positionId,
}: PlanSummaryConfirmationModalProps) => {
  const { t } = useTranslation()
  const [deleteSummary, { isLoading }] = useUpdateSuccessionPlanSummaryMutation()

  const handleSubmit = () => {
    deleteSummary({
      positionId,
      summary: null,
    })
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t("v2.succession_plan_panel.modals.clear_plan_summary_confirm.delete")}
      footer={
        <ModalFooter
          disabled={isLoading}
          onClose={onClose}
          onSave={handleSubmit}
          saveButtonText={t("v2.defaults.delete")}
          useCriticalButton
        />
      }
    >
      <div className="p-6">
        {t("v2.succession_plan_panel.modals.clear_plan_summary_confirm.message")}
      </div>
    </Modal>
  )
}
