import React, { Ref, useEffect, useImperativeHandle, useRef } from "react"
import { defaultCellRangeRenderer, Grid } from "react-virtualized"

import { Cell, CellProps, MemoCell } from "v2/react/components/orgChart/Datasheet/Cell"
import {
  Column,
  CursorConnection,
  GroupRow,
  NodeRow,
} from "v2/react/components/orgChart/Datasheet/types"
import { ROW_HEIGHT_PX } from "v2/react/components/orgChart/Datasheet/utils/constants"

interface GridBodyProps<TNode, CType = TNode> {
  CellComponent?: (props: CellProps<TNode, CType>) => React.ReactNode
  onCellClick: (row: NodeRow<TNode>, column: Column<CType>) => void
  onExpandCollapseGrouping: (row: GroupRow) => void
  columns: Column<CType>[]
  cellRangeRenderer?: typeof defaultCellRangeRenderer
  cursorConnection?: CursorConnection
  sheetRef?: Ref<{
    contains: (element: Node | null) => boolean
    scrollToCell: (c: { rowIndex: number; columnIndex: number }) => void
  }>
  height: number
  width: number
  rows: (GroupRow | NodeRow<TNode>)[]
  rowCount: number
  containerRef: React.RefObject<HTMLDivElement>
}

type CellRenderer = (a: {
  columnIndex: number
  key: string
  rowIndex: number
  style: React.CSSProperties
}) => ReturnType<typeof MemoCell>

function GridBody<TNode, CType = TNode>({
  CellComponent = Cell,
  onExpandCollapseGrouping,
  onCellClick,
  cellRangeRenderer = defaultCellRangeRenderer,
  columns,
  cursorConnection,
  sheetRef,
  height,
  rows,
  rowCount,
  width,
  containerRef,
}: GridBodyProps<TNode, CType>) {
  const gridRef = useRef<Grid>(null)
  const htmlRef = useRef<HTMLDivElement>(null)

  useImperativeHandle(
    sheetRef,
    () => ({
      contains: (maybeNode) => htmlRef.current?.contains?.(maybeNode) ?? false,
      scrollToCell: (params) => {
        gridRef.current?.scrollToCell?.(params)
        const cellButton = document.getElementById(
          `cell-${rows[params.rowIndex].id}-${String(columns[params.columnIndex].fieldKey)}`,
        )
        const cell = cellButton?.firstChild
        if (cell instanceof HTMLElement && containerRef.current) {
          const cellRect = cell.getBoundingClientRect()
          const tableContainerRect = containerRef.current.getBoundingClientRect()

          if (cellRect.right > tableContainerRect.right)
            cell.scrollIntoView({
              behavior: "auto",
              block: "nearest",
              inline: "start",
            })

          if (cellRect.left < tableContainerRect.left)
            cell.scrollIntoView({
              behavior: "auto",
              block: "nearest",
              inline: "end",
            })
        }
      },
    }),
    [gridRef, containerRef, columns, rows],
  )

  useEffect(() => {
    if (gridRef.current === null) return
    gridRef.current.recomputeGridSize()
  }, [width])

  const cellRenderer: CellRenderer = ({ columnIndex, key, rowIndex, style }) => (
    <CellComponent
      boundary={htmlRef.current ?? undefined}
      column={columns[columnIndex]}
      cursorConnection={cursorConnection}
      isFirst={columnIndex === 0}
      isFirstRow={rowIndex === 0}
      isLast={columnIndex + 1 >= columns.length}
      isLastRow={rowIndex === rowCount - 1}
      key={key}
      onExpandCollapseGrouping={onExpandCollapseGrouping}
      row={rows[rowIndex]}
      onClick={onCellClick}
      style={style}
    />
  )

  return (
    <div className="grid-body" ref={htmlRef}>
      <Grid
        ref={gridRef}
        cellRenderer={cellRenderer}
        cellRangeRenderer={cellRangeRenderer}
        columnCount={columns.length}
        style={{ width }}
        containerStyle={innerGridStyles(width, rowCount, ROW_HEIGHT_PX)}
        columnWidth={({ index }) => columns[index].width}
        height={height}
        rowCount={rowCount}
        rowHeight={ROW_HEIGHT_PX}
        width={width}
      />
    </div>
  )
}

export { GridBody }

const innerGridStyles = (width: number, rowCount: number, rowHeight: number) => ({
  width,
  maxWidth: width,
  height: rowCount * rowHeight,
  maxHeight: rowCount * rowHeight,
})
