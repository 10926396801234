import { AnimatePresence, motion } from "framer-motion"
import React, { useState } from "react"
import { useIsMounted } from "usehooks-ts"

import { useAddPositionTypeForm } from "v2/react/components/positionTypes/hooks/useAddPositionTypeForm"
import { PositionTypesConnectionNode } from "v2/redux/GraphqlApi/PositionTypesApi"

import { JobCodeAndTitleFieldset } from "./JobCodeAndTitleFieldset"
import { SaveJobCodeAndTitleFormAttributesFn } from "./types"

type PositionTypeCodeNameAttributes = {
  jobCode: string
  title: string
}

type SubFormProps = {
  className?: string
  createIsVirtual?: boolean
  initialData: Partial<PositionTypeCodeNameAttributes>
  onCancel: () => void
  onSaved: (arg: PositionTypesConnectionNode) => void
}

function JobCodeAndTitleForm({
  createIsVirtual = false,
  initialData,
  onCancel,
  onSaved,
}: Omit<SubFormProps, "className">) {
  const { errors, save, state, translations } = useAddPositionTypeForm(createIsVirtual)
  const handleSave: SaveJobCodeAndTitleFormAttributesFn = async (input) => {
    const result = await save(input)
    if (result.success) onSaved(result.positionTypeNode)
    return result
  }

  const getFieldError = (path: string | string[]) => {
    if (path === "code") return errors.getFieldError("job_code")
    if (path === "name") return errors.getFieldError("title")
    return errors.getFieldError(path)
  }

  return (
    <JobCodeAndTitleFieldset
      className="mb-0"
      errors={{ ...errors, getFieldError }}
      initialData={initialData}
      isSaving={(state.isSuccess && errors.isEmpty) || state.isLoading}
      layoutSpacing="condensed"
      onCancel={onCancel}
      onSave={handleSave}
      translations={translations}
    />
  )
}

function AnimatedJobCodeAndTitleForm({
  className,
  createIsVirtual,
  initialData,
  onCancel,
  onSaved,
}: SubFormProps) {
  const [isActive, flagIsActive] = useState(true)
  const isMounted = useIsMounted()

  const handleSaved: typeof onSaved = async (result) => {
    flagIsActive(false)
    setTimeout(() => isMounted() && onSaved(result), 225)
  }
  const handleCancel = () => {
    flagIsActive(false)
    setTimeout(() => isMounted() && onCancel(), 0)
  }

  return (
    <AnimatePresence>
      {isActive ? (
        <motion.div
          className={className}
          key="content"
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { opacity: 1, scale: 1 },
            collapsed: { opacity: 0, scale: 0.95 },
          }}
          transition={{ duration: 0.25, ease: "easeInOut" }}
        >
          <JobCodeAndTitleForm
            createIsVirtual={createIsVirtual}
            initialData={initialData}
            onCancel={handleCancel}
            onSaved={handleSaved}
          />
        </motion.div>
      ) : null}
    </AnimatePresence>
  )
}

export { AnimatedJobCodeAndTitleForm, JobCodeAndTitleForm }
