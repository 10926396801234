import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React, { FormEvent, useEffect } from "react"
import { useTranslation } from "react-i18next"

import type { FilterOption } from "types/graphql"
import { SearchableDropdown } from "v2/react/shared/forms/SearchableDropdown"
import { Footer } from "v2/react/shared/layout/Sidebar/Footer"
import { Filter } from "v2/react/shared/tables/TableUtilities/FilterTable/Filter"
import { useFilterPanelState } from "v2/react/shared/tables/TableUtilities/FilterTable/hooks/useFilterPanelState"
import type { Filter as FType } from "v2/react/shared/tables/TableUtilities/FilterTable/utils/filters"
import { UnappliedChangesModal } from "v2/react/shared/tables/TableUtilities/UnappliedChangesModal"
import { updateFilters } from "v2/redux/slices/TableFiltersSlice"
import { useAppDispatch } from "v2/redux/store"

interface PanelContentProps {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void
}

const PanelContent = ({ onSubmit }: PanelContentProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { setUnappliedChanges, setAfterChangesCanceled } = UnappliedChangesModal.useContext()

  const handleSetFilters = (filters: FType[]) => {
    dispatch(updateFilters(filters))
  }

  const {
    anyChanges,
    anyErrors,
    filters,
    formKey,
    handleClearChanges,
    handleFilterSelect,
    removeField,
    selectedFields,
    updateFieldErrors,
    unselectedFields,
  } = useFilterPanelState()

  useEffect(() => {
    setUnappliedChanges(anyChanges)
    setAfterChangesCanceled(() => handleClearChanges)
  }, [anyChanges, handleClearChanges, setUnappliedChanges, setAfterChangesCanceled])

  return (
    <form key={formKey} onSubmit={onSubmit} className={cn({ "h-full !pb-10": anyChanges })}>
      <div className="form--inner h-full overflow-y-scroll p-3">
        <div className="mb-6">
          <SearchableDropdown<FilterOption>
            id="filter-table-search"
            items={unselectedFields}
            onSelect={handleFilterSelect}
            searchPlaceholder={`${t("v2.defaults.filter_by")}...`}
          />
        </div>
        {!selectedFields.length ? (
          <EmptyState />
        ) : (
          selectedFields.map((field) => (
            <Filter
              key={field.id}
              field={field}
              filters={filters}
              removeField={removeField}
              setFilters={handleSetFilters}
              updateFieldErrors={updateFieldErrors}
            />
          ))
        )}
      </div>
      <Footer disableSubmit={anyErrors} show={anyChanges} onCancel={handleClearChanges} />
    </form>
  )
}

const EmptyState = () => {
  const { t } = useTranslation()

  return (
    <div className="mt-4">
      <div className="icon--round mx-auto mb-4">
        <FontAwesomeIcon icon={["far", "filter"]} className="fa-lg" />
      </div>
      <p className="mx-auto max-w-[80%] text-center text-neutral-64">
        {t("v2.defaults.no_filters")}
      </p>
    </div>
  )
}

export { PanelContent }
