import { EntityId, EntityState } from "@reduxjs/toolkit"

import { Maybe } from "types/graphql"
import { AppDispatch } from "v2/redux/store"

export type AnyMessage = { more?: boolean; result?: unknown }
export type AnyTopicMessage = {
  subjectId: EntityId
  topic?: unknown
  message: AnyMessage
}

export interface GraphqlCableState extends EntityState<ChannelSubscription> {
  mailbox: AnyTopicMessage[]
}

export type ChannelSubscription = {
  subjectId: EntityId
  args: ChannelSubscriptionArgs
  state: ChannelState
}

export enum ChannelState {
  Initialized = "initialized",
  Connected = "connected",
  Disconnected = "disconnected",
  Rejected = "rejected",
}

export type ChannelSubscriptionArgs =
  | { operationId: string; variables?: Variables }
  | { operationName: string; query: string; variables?: Variables }

export type Variables = {
  [key in string]?: number | string | null | Variables | Variables[]
}

export interface CableChannelSubscriptionArgs<T> extends Omit<ChannelSubscription, "state"> {
  dispatch: AppDispatch
  connected?: () => void
  disconnected?: () => void
  initialized?: () => void
  received: (message: { more?: boolean; result: { data?: T } }) => void
  rejected?: () => void
}

export interface ChannelSubscribePayload extends Pick<ChannelSubscription, "subjectId" | "args"> {
  trackMessages?: boolean
}

export interface ChannelSubscriptionHookArg {
  subjectId?: Maybe<EntityId>
  args?: Maybe<ChannelSubscriptionArgs>
  localOnly?: boolean
  trackMessages?: boolean
}
