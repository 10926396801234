import React from "react"

import { Chart } from "types/graphql.d"
import {
  currentApproverStatus,
  currentUserIsNextApprover,
} from "v2/react/shared/status/statusUtils"
import { CurrentUser } from "v2/redux/slices/ApprovalSlice"

import { ChartApprovalActions } from "../ChartApprovalActions"

interface Props {
  chart: Chart
  currentUser: CurrentUser
}

function SubmittedFooter({ chart, currentUser }: Props) {
  const currentStatus = currentApproverStatus(chart?.chartApprovers, currentUser.currentPersonId)

  const canTakeAction = () => {
    const onInitialStatus =
      currentUserIsNextApprover(chart?.chartApprovers, currentUser.currentPersonId) &&
      chart?.status === "awaiting_approval"
    const onChangeStatus =
      (chart?.status === "changes_requested" && currentStatus === "changes_requested") ||
      (chart?.status === "denied" && currentStatus === "denied")
    return onInitialStatus || onChangeStatus
  }

  return canTakeAction() ? (
    <>
      <hr className="mx-[-1rem] my-4" />
      <div className="mb-4 justify-center flex">
        <ChartApprovalActions />
      </div>
    </>
  ) : null
}

export { SubmittedFooter }
