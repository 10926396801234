import cn from "classnames"
import React from "react"

import { Position } from "types/graphql"
import { PositionList } from "v2/react/shared/collection/PositionList"
import { PositionSearchInput } from "v2/react/shared/forms/PositionSearchInput"

interface Props {
  errorMessage?: string
  label: string
  onPositionsUpdated: (selectedIds: string[]) => void
  positionIds: string[]
}

function PositionSelection({ errorMessage, label, onPositionsUpdated, positionIds }: Props) {
  const onPositionRemove = (selectedId: string) => {
    const updatedPositions: string[] = (positionIds || []).filter((p) => p !== selectedId)
    onPositionsUpdated(updatedPositions)
  }

  const onPositionSelect = (selectedPosition: Position) => {
    // Array.from(new Set(..)) removes duplicates
    const updatedPositionIds: string[] = Array.from(
      new Set([...positionIds, selectedPosition.uniqueKey || ""]),
    )
    onPositionsUpdated(updatedPositionIds)
  }

  return (
    <div className="input-group">
      <label>{label}</label>
      <PositionSearchInput
        errorMessage={errorMessage}
        htmlForId="position_search_input"
        onSelect={onPositionSelect}
        placeholder={`${"example_abbreviated".t("defaults")}: ${"example_name".t("defaults")}`}
      />
      <div className={cn({ "mt-4": positionIds.length > 0 })}>
        <PositionList isSaving={false} onRemove={onPositionRemove} positionIds={positionIds} />
      </div>
    </div>
  )
}

export { PositionSelection }
