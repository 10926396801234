import { QueryStatus } from "@reduxjs/toolkit/dist/query"
import React from "react"

import { useAppSelector } from "v2/redux/store"
import { StoreProvider } from "v2/redux/StoreProvider"

/** @see https://stackoverflow.com/a/71525408 */
const InnerQueryMonitor = () => {
  const status = useAppSelector((state) => {
    const mutations = Object.values(state.GraphqlApi.mutations)
    const queries = Object.values(state.GraphqlApi.queries)
    const anyActive = [...mutations, ...queries].some(
      (thing) => thing?.status === QueryStatus.pending,
    )
    return anyActive ? "yes" : "no"
  })

  return <div id="query-monitor" style={OutOfTheWay} data-fetching={status} />
}

const QueryMonitor = () => (
  <StoreProvider>
    <InnerQueryMonitor />
  </StoreProvider>
)

const OutOfTheWay = Object.freeze({
  position: "absolute",
  width: 0,
  height: 0,
  top: 0,
  left: 0,
})

export { QueryMonitor }
