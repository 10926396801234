import { useEffect, useRef } from "react"

import { usePositionFormContext } from "v2/react/components/positions/positionForm/PositionFormProvider"

import { usePositionWatchField } from "./useConvenientReactHookFormHooks"

/**
 * Resets amount/percent_of_budget for each funding_source_position when the
 * configured allocation type changes.
 */
function useFundingSourcePositionValuesResetEffect() {
  const allocationType = usePositionWatchField({ name: "position.fundingSourceAllocationType" })
  const allocationTypeRef = useRef(allocationType)

  const fundingSourcePositions = usePositionWatchField({
    name: "position.fundingSourcePositionsAttributes",
  })

  const { setValue } = usePositionFormContext()
  useEffect(() => {
    if (allocationTypeRef.current === allocationType) return

    allocationTypeRef.current = allocationType
    fundingSourcePositions?.forEach((_, index) => {
      setValue(`position.fundingSourcePositionsAttributes.${index}.amount`, "")
      setValue(`position.fundingSourcePositionsAttributes.${index}.percentOfBudget`, "")
    })
  }, [fundingSourcePositions, allocationType, setValue])
}

export { useFundingSourcePositionValuesResetEffect }
