import React, { ChangeEvent, FormEvent } from "react"
import { useTranslation } from "react-i18next"

import { Error, JobLevel } from "types/graphql"
import { TextInput } from "v2/react/shared/forms/TextInput"
import { SaveFooter } from "v2/react/shared/overlay/Modal"

interface Props {
  codeError: string
  errors: Error[] | undefined | null
  handleClose: () => void
  handleSubmit: (event: FormEvent) => void
  isLoading: boolean
  jobLevel?: JobLevel
  setCodeError: React.Dispatch<React.SetStateAction<string>>
}

function JobLevelForm({
  codeError,
  errors,
  handleClose,
  handleSubmit,
  isLoading,
  jobLevel,
  setCodeError,
}: Props) {
  const { t } = useTranslation()

  const validate = (event: FormEvent<Element>) => {
    event.preventDefault()

    const form = event.target as HTMLFormElement
    const formData = new FormData(form)
    if (!formData.get("code")) {
      setCodeError(t("v2.defaults.blank_validation"))
    } else {
      setCodeError("")
      handleSubmit(event)
    }
  }

  const retrieveErrorFor = (path: string) => {
    const error = errors?.find((element) => element?.path && element.path[0] === path)
    if (error) return error.message
    return ""
  }

  const forceNumeric = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target) e.target.value = e.target.value.replace(/\D/g, "")
  }

  return (
    <form onSubmit={validate}>
      <div className="react-modal__body flex-col gap-2 flex">
        {retrieveErrorFor("general") && (
          <div className="alert alert-critical mt-4">
            <span>{retrieveErrorFor("general")}</span>
          </div>
        )}

        <section className="mb-4 grid-cols-4 gap-6 grid">
          <TextInput
            id="code"
            name="code"
            label={t("v2.job_levels.fields.code")}
            errors={codeError || retrieveErrorFor("code")}
            defaultValue={jobLevel?.code || ""}
            className="whitespace-nowrap"
          />
          <TextInput
            id="name"
            name="name"
            label={t("v2.job_levels.fields.name")}
            className="col-span-3"
            errors={retrieveErrorFor("name")}
            defaultValue={jobLevel?.name || ""}
          />
        </section>
        <TextInput
          id="order"
          name="order"
          label={t("v2.job_levels.fields.order")}
          className="col-span-3"
          errors={retrieveErrorFor("order")}
          defaultValue={jobLevel?.order?.toString() || ""}
          onChange={forceNumeric}
        />
      </div>
      <SaveFooter isSaving={isLoading} onCancel={handleClose} />
    </form>
  )
}

export { JobLevelForm }
