import fp from "lodash/fp"

import { BasePay, CustomFieldField, SourcePay } from "types/graphql.enums"
import {
  CustomFieldValueEntry,
  CustomFieldValueFieldName,
  OrgUnitEntry,
  OrgUnitFieldName,
  SimpleEntry,
  VariablePayEntry,
  VariablePayFieldName,
  WatchEntry,
  WatchFieldName,
} from "v2/react/components/positions/positionFieldValuesDiff/types"
import { FieldValues } from "v2/react/components/positions/positionForm"
import { formatCurrency } from "v2/react/utils/currency"
import { safeNumber } from "v2/react/utils/safeNumber"

import { calculateBudgetedTotal } from "../../positionForm/hooks/useBudgetedBasePayTotal"
import { getBudgetedVariablePayHash } from "../../positionForm/hooks/useBudgetedVariablePays"
import {
  isCustomFieldValueFieldName,
  isOrgUnitFieldName,
  isSimpleEntryFor,
  isVariablePayFieldName,
} from "./entryHelpers"

type BuildEntryArg<FieldName extends WatchFieldName> = {
  basis: FieldValues
  current: FieldValues
  fieldName: FieldName
}

const EMPTY_RECORD: Record<string, WatchEntry> = {}

/**
 * Generates a diff between initial and current position data.
 *
 * There's a lot that this isn't handling yet, but the key thing is that
 * values aren't normalized/coerced. Initial data might have a number for a
 * field which gets collected as a string.
 */
function diffPositionData(
  basis: Nullish<FieldValues>,
  current: Nullish<FieldValues>,
  fieldNames: WatchFieldName[],
) {
  if (!basis || !current) return EMPTY_RECORD

  return fieldNames.reduce(
    (memo, fieldName) => {
      const entry = buildEntry({ basis, current, fieldName })

      // Position type may load with extra data that causes the basic check to
      // return a false positive. Match on jobCodeTitleLabel.
      if (isSimpleEntryFor(entry, "position.positionType")) {
        return areValuesEqual(entry.current?.jobCodeTitleLabel, entry.initial?.jobCodeTitleLabel)
          ? memo
          : { ...memo, [entry.fieldName]: entry }
      }

      // We don't want to show a diff entry for hours per week when the field
      // values currently indicate the pay type is salary.
      if (
        entry.fieldName === "position.positionHoursPerWeek" &&
        current.position.positionBasePayType === BasePay.Salary
      ) {
        return memo
      }

      return areValuesEqual(entry.initial, entry.current)
        ? memo
        : { ...memo, [entry.fieldName]: entry }
    },
    {} as Record<string, WatchEntry>,
  )
}

const buildEntry = ({ fieldName, ...arg }: BuildEntryArg<WatchFieldName>) => {
  if (isCustomFieldValueFieldName(fieldName))
    return buildCustomFieldValueEntry({ ...arg, fieldName })
  if (isOrgUnitFieldName(fieldName)) return buildOrgUnitEntry({ ...arg, fieldName })
  if (isVariablePayFieldName(fieldName)) return buildVariablePayEntry({ ...arg, fieldName })
  if (fieldName === "position.isAssistant") return buildIsAssistantEntry({ ...arg, fieldName })
  if (fieldName === "position.parentId") return buildParentIdEntry({ ...arg, fieldName })

  return {
    fieldName,
    initial: coerceToValueOrBlank(fp.prop(fieldName, arg.basis)),
    current: coerceToValueOrBlank(fp.prop(fieldName, arg.current)),
  } as SimpleEntry
}

const buildVariablePayEntry = ({
  basis,
  current,
  fieldName,
}: BuildEntryArg<VariablePayFieldName>): VariablePayEntry => {
  const initialVp = basis.position.variablePaysAttributes?.find(
    ({ variablePayType }) => `variable_pay_type_${variablePayType.id}` === fieldName,
  )
  const currentVp = current.position.variablePaysAttributes?.find(
    ({ variablePayType }) => `variable_pay_type_${variablePayType.id}` === fieldName,
  )

  return {
    fieldName,
    label: initialVp?.variablePayType.label ?? currentVp?.variablePayType?.label ?? "",
    initial: normalizeVariablePayRecord(initialVp, basis),
    current: normalizeVariablePayRecord(currentVp, current),
  }
}

const buildOrgUnitEntry = ({
  basis,
  current,
  fieldName,
}: BuildEntryArg<OrgUnitFieldName>): OrgUnitEntry => {
  const initialOu = basis.position.orgUnitsAttributes?.find(
    ({ orgUnitType }) => orgUnitType.id === fieldName,
  )
  const currentOu = current.position.orgUnitsAttributes?.find(
    ({ orgUnitType }) => orgUnitType.id === fieldName,
  )

  return {
    fieldName,
    label: initialOu?.orgUnitType?.label ?? currentOu?.orgUnitType?.label ?? "",
    initial: normalizeOrgUnit(initialOu),
    current: normalizeOrgUnit(currentOu),
  }
}

const buildCustomFieldValueEntry = ({
  basis,
  current,
  fieldName,
}: BuildEntryArg<CustomFieldValueFieldName>): CustomFieldValueEntry => {
  const initialCfv = basis.position.customFieldValuesAttributes?.find(
    ({ customField }) => `custom_field_${customField.id}` === fieldName,
  )
  const currentCfv = current.position.customFieldValuesAttributes?.find(
    ({ customField }) => `custom_field_${customField.id}` === fieldName,
  )

  return {
    fieldName,
    label: initialCfv?.customField?.name ?? currentCfv?.customField?.name ?? "",
    initial: normalizeCustomFieldValue(initialCfv),
    current: normalizeCustomFieldValue(currentCfv),
  }
}

const buildIsAssistantEntry = ({
  basis,
  current,
  fieldName,
}: BuildEntryArg<"position.isAssistant">): SimpleEntry<"position.isAssistant"> => {
  // Weird case: assume standard when undefined...value is likely a string until
  // I can get around to tightening this up.
  const extractBoolean = (value: string | boolean | undefined) =>
    typeof value === "boolean" ? value : value === undefined || value === "true"

  return {
    initial: extractBoolean(basis.position.isAssistant),
    current: extractBoolean(current.position.isAssistant),
    fieldName,
  }
}

const buildParentIdEntry = ({
  basis,
  current,
  fieldName,
}: BuildEntryArg<"position.parentId">): SimpleEntry<"position.parentId"> => ({
  initial: basis.position.reportsToName,
  current: current.position.reportsToName,
  fieldName,
})

const areValuesEqual = (initial: unknown, current: unknown) =>
  fp.isEqual(coerceToValueOrBlank(initial), coerceToValueOrBlank(current))

const normalizeOrgUnit = (orgUnit: OrgUnitEntry["current"]) => {
  if (!orgUnit) {
    return orgUnit
  }

  return {
    ...orgUnit,
    code: fp.isNil(orgUnit.code) ? "" : orgUnit.code,
    name: fp.isNil(orgUnit.name) ? "" : orgUnit.name,
    fullName: fp.isNil(orgUnit.fullName) ? "" : orgUnit.fullName,
  }
}

const normalizeCustomFieldValue = (customFieldValue: CustomFieldValueEntry["current"]) => {
  const fieldType = customFieldValue?.customField?.field_type
  if (!fieldType) {
    // Ensures TS is happy about the return.
    return customFieldValue
  }

  if (fieldType === CustomFieldField.Numeric) {
    const normalizedValue = safeNumber(customFieldValue?.value, { fallback: null })
    return { ...customFieldValue, value: normalizedValue === null ? "" : `${normalizedValue}` }
  }

  if (fieldType === CustomFieldField.Currency) {
    const normalizedValue = safeNumber(customFieldValue?.value, {
      fallback: null,
      from: "currency",
    })
    return {
      ...customFieldValue,
      value:
        normalizedValue === null
          ? ""
          : formatCurrency({ value: normalizedValue, omitSymbol: false, trailing: false }),
    }
  }

  return customFieldValue
}

function coerceToValueOrBlank<Value>(value: Value) {
  return fp.isNil(value) || (typeof value === "string" && value.trim() === "") ? null : value
}

type VariablePayAttributes = NonNullable<FieldValues["position"]["variablePaysAttributes"]>[0]

function normalizeVariablePayRecord(
  value: VariablePayAttributes | undefined,
  fieldValues: FieldValues,
) {
  if (!value) return value

  const budgeted = calculateBudgetedTotal(
    fieldValues.position.positionBasePay,
    fieldValues.position.positionHoursPerWeek,
    fieldValues.position.positionBasePayType,
  )

  const hash = getBudgetedVariablePayHash({ variablePay: value, budgetedBasePayTotal: budgeted })
  const formattedTotal = formatCurrency({
    value: hash.calculatedVariablePayAmount,
    omitSymbol: false,
    trailing: true,
  })

  return {
    ...value,
    calculatedAmount: hash.calculatedVariablePayAmount,
    formattedString:
      value.payType === SourcePay.Percent ? `${formattedTotal} (${value.amount}%)` : formattedTotal,
  }
}

export { diffPositionData }
