export function truncateValue(value: string, maxTextLength: number) {
  if (value.length <= maxTextLength) return value

  return `${value.slice(0, maxTextLength - 3).trim()}...`
}

export function measureText(text: string, font = "700 13px Satoshi, sans-serif") {
  const canvas = document.createElement("canvas")
  const context = canvas.getContext("2d")
  if (!context) return null
  context.font = font
  return context.measureText(text)
}
