import React from "react"

import { HeadcountPlanMember, HeadcountPlanRole } from "types/graphql.d"

import { ANIMATION_DURATION, MemberListItem } from "./PlanMemberList/MemberListItem"

interface PlanMemberListProps {
  currentPersonId: number
  isSaving: boolean
  onRemove: (selectedHeadcountPlanMember: HeadcountPlanMember) => void
  onUpdate: (selectedHeadcountPlanMember: HeadcountPlanMember) => void
  selectedMembers: HeadcountPlanMember[]
  setErrorMessage: (message: string | React.ReactNode[]) => void
}

function PlanMemberList({
  currentPersonId,
  isSaving,
  onRemove,
  onUpdate,
  selectedMembers,
  setErrorMessage,
}: PlanMemberListProps) {
  const handleRemoveValueClick = (e: React.MouseEvent<HTMLElement>) => {
    const removeValue: string = e.currentTarget.getAttribute("data-value") || ""
    const removedItem: HeadcountPlanMember | undefined = selectedMembers.find(
      (m) => m.uniqueKey === removeValue,
    )

    if (removedItem && removedItem.startedPlanning) {
      return setErrorMessage(
        "can_not_remove_participant".t("headcount_plan", null, null, null, [
          removedItem.person.name,
        ]),
      )
    }

    return setTimeout(() => {
      // This is where the actual delete is happening, post-animation
      if (removedItem) {
        onRemove(removedItem)
      }
    }, ANIMATION_DURATION)
  }

  const handleRoleChange = (member: HeadcountPlanMember, role: HeadcountPlanRole) => {
    if (member.startedPlanning && role === HeadcountPlanRole.Owner) {
      return setErrorMessage(
        "can_not_remove_participant".t("headcount_plan", null, null, null, [member.person.name]),
      )
    }
    const updatedHeadcountPlanMember: HeadcountPlanMember = {
      ...member,
      role,
    }
    return onUpdate(updatedHeadcountPlanMember)
  }

  return (
    <div className="mt-1">
      {selectedMembers.map((member) => (
        <MemberListItem
          currentPersonId={currentPersonId}
          isSaving={isSaving}
          key={member.uniqueKey}
          canBeRemoved={!member.startedPlanning}
          member={member}
          onItemRemoved={handleRemoveValueClick}
          onItemRoleChange={handleRoleChange}
        />
      ))}
    </div>
  )
}

export { PlanMemberList }
