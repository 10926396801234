import React from "react"
import { useTranslation } from "react-i18next"

import {
  PositionActions,
  type PositionActionsProps,
} from "v2/react/components/orgChart/ProfilePanel/PositionHeader/PositionActions"
import { ExportButton } from "v2/react/shared/buttons/ExportButton"
import PageNav from "v2/react/shared/navigation/PageNav"
import { ActionBlock } from "v2/react/shared/navigation/PageNav/ActionBlock"
import {
  TitleBlockLarge,
  TitleHeaderWithParent,
} from "v2/react/shared/navigation/PageNav/TitleBlock"
import { StatusBadge } from "v2/react/shared/status/StatusBadge"

type PositionActionsInfo = Pick<
  PositionActionsProps,
  | "canDelete"
  | "canEdit"
  | "canViewHistory"
  | "canViewSystemIdentifier"
  | "positionId"
  | "containerKey"
  | "hasChildren"
  | "isHeadcountPlanner"
  | "containerIsOfficialChart"
>

interface Props {
  exportUrl?: string | null
  isOpen: boolean
  parentTitle: string | null
  parentTitleUrl?: string
  title: string
  /**
   * If provided, renders an ellipsis menu with standard position actions.
   */
  positionActionsInfo?: PositionActionsInfo
}

function PositionsSubPageNav({
  exportUrl,
  isOpen,
  parentTitle,
  parentTitleUrl,
  title,
  positionActionsInfo,
}: Props) {
  const { t } = useTranslation()
  return (
    <PageNav>
      <TitleBlockLarge>
        <TitleHeaderWithParent
          parentTitle={parentTitle}
          parentTitleUrl={parentTitleUrl}
          title={title || t("v2.positions.show.untitled_position")}
        />
      </TitleBlockLarge>

      <ActionBlock>
        {isOpen && (
          <StatusBadge displayType="button" fontSizeClass="text-sm" status="open_position" />
        )}
        {exportUrl && <ExportButton exportUrl={exportUrl} useCurrentSearchParamsWithExportUrl />}
        {positionActionsInfo && (
          <PositionActions
            isV2
            buttonClassName="btn--large btn--secondary w-11 h-11"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...positionActionsInfo}
          />
        )}
      </ActionBlock>
    </PageNav>
  )
}

export { PositionsSubPageNav }
