import { TextColors } from "v2/color_coding/color_coder"

/** @private */
const whereKlassIsSupported = (d) => ["Position", "Person", "ListPerson"].indexOf(d.klass) >= 0

let lastSeenCoder = null
let updater = null

/** @private */
const updateColorProps = (coder) => {
  if (lastSeenCoder !== coder || !updater) {
    lastSeenCoder = coder
    updater = (d) =>
      Object.assign(d || {}, {
        card_color: coder.codeCardColor(d, "#fff"),
        edge_color: coder.codeEdgeColor(d, "none"),
      })
  }

  return updater
}

/**
 * Applies color coding to each node in the selection.
 *
 * IMPORTANT: This mutates any node in the bound data that has color coding
 * applied with the card and edge color.
 *
 * @param {d3.Selection} selection A selection of nodes to color code.
 * @param {ColorCoder} coder A color coder instance.
 * @returns {d3.Selection} The given selection.
 */
function colorCodeChartNodes(selection, coder) {
  const filtered = selection.filter(whereKlassIsSupported).datum(updateColorProps(coder))
  filtered.each(function (d) {
    d3.select(this)
      .selectAll("rect.card")
      .style("fill", () => d.card_color)

    d3.select(this)
      .selectAll("line.people-circles-mask")
      .style("stroke", () => d.card_color)

    d3.select(this).each((d) => {
      const cardColor = coder.codeCardColor(d, "#FFFFFF")
      const textColorDomain = coder.codeTextColorDomain(cardColor)

      d.textColorDomain = textColorDomain // Storing the current text color domain so we can handle hover styles
    })

    d3.select(this)
      .selectAll("g.people-dots")
      .each(function (d) {
        const dotsColor = coder.codeTextColor(d, TextColors.darkText.light, "light")
        const cardColor = coder.codeCardColor(d, "#FFFFFF")
        const textColorDomain = coder.codeTextColorDomain(cardColor)
        window.d3
          .select(this)
          .selectAll("circle")
          .attr("fill", (d) => (d.selected ? dotsColor : "transparent"))
          .attr("stroke", (d) => (d.selected ? "transparent" : dotsColor))
          // eslint-disable-next-line no-param-reassign
          .each((d) => (d.textColorDomain = textColorDomain)) // Storing the current text color domain so we can handle hover styles
      })

    d3.select(this)
      .selectAll("line.divider-top, line.divider")
      .attr("stroke", (d) => {
        const cardColor = coder.codeCardColor(d, "#FFFFFF")
        const textColorDomain = coder.codeTextColorDomain(cardColor)

        return TextColors[textColorDomain].extraLight
      })

    d3.select(this)
      .selectAll("rect.color")
      .style("fill", () => d.edge_color)
    d3.select(this)
      .selectAll("text")
      .filter(":not(.direct-reports)")
      .filter(":not(.chart-section-label)")
      .filter(":not(.sub-page-number)")
      .filter(":not(.chain-of-command-text)")
      .filter(":not(.role)")
      .filter(":not(.display-field-label)")
      .filter(":not(.title)")
      .filter(":not(.stats-bar-text)")
      .filter(":not(.stats-bar-progress-text)")
      .style("fill", () => coder.codeTextColor(d, TextColors.darkText.solid))

    // Display field labels and title text use a lower opacity
    d3.select(this)
      .selectAll("text.title")
      .style("fill", () => coder.codeTextColor(d, TextColors.darkText.medium, "medium"))
    d3.select(this)
      .selectAll("text.display-field-label")
      .style("fill", () => coder.codeTextColor(d, TextColors.darkText.light, "light"))
    d3.select(this)
      .selectAll("text.stats-bar-text")
      .style("fill", () => coder.codeTextColor(d, TextColors.darkText.light, "light"))
    d3.select(this)
      .selectAll("use.stats-icon")
      .attr("xlink:href", () => {
        const cardColor = coder.codeCardColor(d, "#FFFFFF")
        const textColorDomain = coder.codeTextColorDomain(cardColor)

        if (textColorDomain === "darkText") {
          return "#descendants-icon-dark"
        }
        return "#descendants-icon-light"
      })

    d3.select(this)
      .selectAll("text.role")
      .style("fill", () => coder.codeTextColor(d, "#ffffff"))
    d3.select(this)
      .selectAll("path.role-background")
      .style("stroke-width", "0.25")
      .style("stroke-dasharray", "142, 182")
      .style("stroke", () => coder.codeTextColor(d, "#ffffff"))
      .style("fill", () => coder.codeCardColor(d, "rgba(27, 98, 255, 1)"))
  })

  return selection
}

export default colorCodeChartNodes
