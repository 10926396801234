import { FormShape } from "v2/react/components/jobRequisitions/RequisitionForm/types/FormShape"

const initialPosigenValues: FormShape = {
  companyVehicle: "yes",
  createdAt: "",
  createdBy: "",
  incentiveCommission: "yes",
  otherBenefits: "",
  remoteOption: "yes",
  reqType: "new_position",
  sourceOpenings: 1,
  schemaType: "posigen",
  systemUid: "",

  backfillPosition: {
    filledBy: "",
    id: "",
    lastFilledBy: "",
    name: "",
  },
  departmentHead: {
    id: "",
    name: "",
  },
  externalCandidate: {
    fileBase64Data: "",
    title: "",
    uploadId: "",
    uploadUrl: "",
  },
  hiringManager: {
    id: "",
    name: "",
  },
  incentivePlan: {
    fileBase64Data: "",
    title: "",
    uploadId: "",
    uploadUrl: "",
  },
  internalEmployee: {
    id: "",
    name: "",
  },
  jobDescription: {
    fileBase64Data: "",
    title: "",
    uploadId: "",
    uploadUrl: "",
  },
  recruitmentSource: {
    optionValues: [],
    otherDetails: "",
  },
  vehicleType: {
    optionValues: "existing_vehicle",
    otherDetails: "",
  },
  position: {
    assignedParent: false,
    budgetedBasePayAnnualized: "",
    budgetNumber: "",
    projectedHireDate: "",
    schemaType: "posigen",

    basePay: {
      amount: 0,
      hoursPerWeek: 0,
      id: "base-pay",
      name: "basePay",
      payType: {
        name: "Salary",
        id: "position_base_pay_type_salary",
      },
    },
    department: {
      id: "",
      name: "",
    },
    employeeType: {
      id: "",
      name: "",
    },
    flsa: {
      id: "",
      name: "",
    },
    jobTitle: {
      id: "",
      jobCode: "",
      jobCodeTitleLabel: "",
      title: "",
    },
    location: {
      id: "",
      name: "",
    },
    reportsTo: {
      id: "",
      name: "",
    },
  },
}

export { initialPosigenValues }
