import i18n from "i18next"

// Presently, there is a short list of validationErrors that arise from json
// schema validations and need custom handling.
// In the future these will be handled more robustly.
const formatForJsonSchemaErrors = (errorMessage: string): string => {
  // NOTE: JsonForms validations from the schema can have spaces appended to
  // them, making formatting tricky.
  if (errorMessage.includes("must be <= 999999.99")) {
    return i18n.t("v2.errors.messages.amount_between_exclusive", {
      min: "0",
      max: "$999,999.99",
    })
  }
  if (errorMessage.includes("must be <=")) {
    return i18n.t("v2.errors.messages.numeric_between_exclusive", {
      min: "0",
      max: numberConstraint(errorMessage),
    })
  }
  if (errorMessage.includes("must be >=")) {
    return i18n.t("v2.errors.messages.gte", { value: numberConstraint(errorMessage) })
  }
  return errorMessage
}

const numberConstraint = (errorMessage: string): string => {
  const maybeDecimalNumberExpression = /\d*\.?\d+/g
  const matches = errorMessage.match(maybeDecimalNumberExpression)
  if (matches && matches.length === 1) return matches[0]
  return ""
}

export { formatForJsonSchemaErrors }
