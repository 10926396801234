import { ProfilePanelBoxSettingSelection } from "types/graphql"
import { ProfilePanelMode } from "types/graphql.enums"
import {
  useGetProfilePlanSettingsQuery,
  useUpdateProfilePlanBoxSettingsMutation,
} from "v2/redux/GraphqlApi/PeopleApi"

function useSelectedBox(boxName: string, mode: ProfilePanelMode) {
  const { data: ppSettingsData } = useGetProfilePlanSettingsQuery({})
  const [updateSettings] = useUpdateProfilePlanBoxSettingsMutation()

  const sortedBoxes =
    ppSettingsData?.currentPerson?.settings?.profilePanelSettings?.profilePanelSortedBoxes || []
  const currentBox = sortedBoxes.find(
    (box: ProfilePanelBoxSettingSelection) => box.name === boxName && box.mode === mode,
  )

  const toggleBox = () => {
    const updatedBoxes = sortedBoxes.map((box: ProfilePanelBoxSettingSelection) =>
      box.name === boxName && box.mode === mode ? { ...box, collapsed: !box.collapsed } : box,
    )
    const updatedInput: ProfilePanelBoxSettingSelection[] = updatedBoxes
    updateSettings({
      params: updatedInput,
    })
  }

  return {
    currentBox,
    toggleBox,
  }
}

export { useSelectedBox }
