import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import { generateConditionalColors } from "v2/react/components/succession/SuccessionPlanPanel/utils/successionUtils"
import { AxisModeType, CardType, GridItem } from "v2/redux/slices/MatrixSlice"

interface MediumMatrixProps {
  axisMode: AxisModeType
  cardType: CardType
  gridItems: GridItem[]
}

function MatrixMedium({ axisMode, cardType, gridItems }: MediumMatrixProps) {
  const { t } = useTranslation()

  const labelForAxisMode = (independent: boolean) =>
    independent
      ? t(`v2.succession_modal.${axisMode}`)
      : t(`v2.succession_modal.${axisMode === "performance" ? "potential" : "performance"}`)

  return (
    <div className="matrix h-64 gap-[0.35rem]">
      <div className="matrix__left-bar bg-primary-8-solid">
        <div className="matrix__left-bar-text matrix-bar-text">{labelForAxisMode(false)}</div>
      </div>
      {gridItems.map((item, index) => (
        <div
          className="matrix-medium__grid-item border--main min-h-[4rem] min-w-0 rounded bg-neutral-3"
          style={generateConditionalColors(cardType, item.color, "sm")}
          // eslint-disable-next-line react/no-array-index-key
          key={`${item.color}-${index}`}
        >
          <div
            className={cn("h-full place-content-center p-1 text-center text-base grid", {
              "bg-white/50": cardType !== "color_edge",
              "!h-4/5": cardType === "color_full",
            })}
          >
            <p className="line-clamp-2 text-neutral-64">{item.label}</p>
          </div>
        </div>
      ))}
      <div className="matrix__bottom-bar bg-primary-8-solid">
        <div className="matrix-bar-text">{labelForAxisMode(true)}</div>
      </div>
    </div>
  )
}

export { MatrixMedium }
