import classNames from "classnames"
import React, {
  CSSProperties,
  KeyboardEvent,
  MouseEventHandler,
  useImperativeHandle,
  useMemo,
  useState,
} from "react"
import { defaultMemoize } from "reselect"

import { Collection, Maybe, Option } from "types/graphql.d"
import { useFuncOnNextKeyUp } from "v2/react/components/orgChart/Datasheet/Cell/hooks"
import {
  CursorConnection,
  CursorSaveOptions,
  NodeRow,
} from "v2/react/components/orgChart/Datasheet/types"
import { TransitionKeys } from "v2/react/components/orgChart/OrgChartDatasheet/hooks/cursorKeyMovements"
import { useSelectList } from "v2/react/hooks/useSelectList"
import { DropdownMenu } from "v2/react/shared/collection/menus/DropdownMenu"

interface SelectDropdownProps<TNode> {
  cursorConnection: CursorConnection
  row: NodeRow<TNode>
  isFirst: boolean
  isLast: boolean
  noBorder?: boolean
  style: CSSProperties
  html: string | null
  collection: Collection
}

function SelectDropdown<TNode>({
  cursorConnection,
  row,
  isFirst,
  isLast,
  noBorder,
  style,
  html,
  collection,
}: SelectDropdownProps<TNode>) {
  const [showResultList, setShowResultList] = useState(false)
  const [inputValue, setInputValue] = useState(html || "")

  const { nodes: options } = collection.options
  const selectedOption = useMemo(
    () => options.find((option) => option.label === inputValue),
    [inputValue, options],
  )

  const {
    activeIndex,
    setActiveIndex,
    listRef,
    refs,
    floatingStyles,
    context,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
  } = useSelectList({ showList: showResultList, setShowList: setShowResultList })

  useImperativeHandle(
    cursorConnection.cellInputRef,
    () => ({
      focus: () => {
        refs.domReference.current?.focus?.()
        setShowResultList(true)
      },
      blur: () => {
        setActiveIndex(null)
        setShowResultList(false)
        refs.domReference.current?.blur?.()
      },
      getValue: () => inputValue,
    }),
    [inputValue, refs.domReference, setActiveIndex, setShowResultList],
  )
  const [handleKeyUp, deferUntilKeyUp] = useFuncOnNextKeyUp({
    stopPropagation: true,
    preventDefault: true,
  })
  const maybeDefer = (ev?: KeyboardEvent) => (func: () => void) => {
    if (ev) deferUntilKeyUp(func)
    else func()
  }

  const closingList = (key: string) => key === "Escape" && showResultList
  const togglingInput = (submitKey: boolean) => submitKey && activeIndex === null && showResultList

  const handleSubmit = (item?: Option, ev?: KeyboardEvent) => {
    setActiveIndex(null)
    setShowResultList(false)
    if (ev === undefined) refs.domReference.current?.blur?.()

    const saveOptions: CursorSaveOptions = ev
      ? { moveAfterByEvent: ev.nativeEvent }
      : { transitionKeyCanMove: true }
    // Wait to execute this logic if we entered due to a keydown event. This is
    // to prevent a keyup event bubbling to a possible follow-up modal, causing
    // weird behavior in the process (like an immediate submission).
    if (item && item.label !== inputValue)
      maybeDefer(ev)(() => cursorConnection.saveWrite(item.id, saveOptions))
    else maybeDefer(ev)(() => cursorConnection.stopWriting(saveOptions))
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const isTransitionKey = TransitionKeys.matchEvent(event.nativeEvent)
    if (closingList(event.key) || togglingInput(isTransitionKey)) {
      handleSubmit(selectedOption, event)
    }
    if (isTransitionKey && activeIndex != null && options[activeIndex]) {
      setInputValue(options[activeIndex].label)
      handleSubmit(options[activeIndex], event)
    }
  }

  const makeHandleResultClick = (item: Option) => (ev: React.MouseEvent) => {
    ev.preventDefault()
    ev.stopPropagation()
    if (item.label !== inputValue) {
      setInputValue(item.label)
    }
    handleSubmit(item)
  }

  const handleCellClick: MouseEventHandler = (ev) => {
    if (!cursorConnection.inWrite) return
    ev.preventDefault()
    ev.stopPropagation()
    cursorConnection.stopWriting()
  }

  return (
    <>
      <input
        style={prepareStyle(style, isFirst, row.color, noBorder)}
        className={nodeClassName(isLast)}
        aria-autocomplete="list"
        value={inputValue}
        ref={refs.setReference}
        readOnly
        /* eslint-disable react/jsx-props-no-spreading */
        {...getReferenceProps({
          onClick: handleCellClick,
          onKeyDown: handleKeyDown,
          onKeyUp: handleKeyUp,
        })}
      />

      <DropdownMenu
        showList={showResultList}
        floatingRef={refs.setFloating}
        floatingStyles={floatingStyles}
        floatingProps={getFloatingProps}
        wrapperClasses="SelectField"
        context={context}
      >
        {options && options.length > 0
          ? options.map((item, index) => (
              <div
                role="option"
                aria-selected={activeIndex === index}
                key={item.id}
                className={textClassName(activeIndex, index, inputValue === item.label)}
                ref={(node) => {
                  listRef.current[index] = node
                }}
                /* eslint-disable react/jsx-props-no-spreading */
                {...getItemProps({
                  onClick: makeHandleResultClick(item),
                })}
              >
                <div className="SelectField__result-text truncate">{item.label}</div>
              </div>
            ))
          : null}
      </DropdownMenu>
    </>
  )
}

const nodeClassName = (isLast: boolean) =>
  classNames("GridBody-cell Cell__select-field bg-transparent", { last: isLast })
const textClassName = (activeIndex: number | null, index: number, isSelected: boolean) =>
  classNames("SelectField__result", { highlight: activeIndex === index, selected: isSelected })

const prepareStyle = defaultMemoize((style, isFirst, color?: Maybe<string>, noBorder?: boolean) => {
  const base = color && isFirst ? { ...(style || {}), borderLeft: `5px solid ${color}` } : style
  if (noBorder) {
    return {
      ...base,
      borderRightWidth: 0,
      borderTopWidth: 0,
      zIndex: 21,
      cursor: "pointer",
    }
  }
  return { ...base, zIndex: 21 }
})

export { SelectDropdown }
