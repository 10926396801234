/* eslint-disable react/jsx-props-no-spreading */
import cn from "classnames"
import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react"
import { AriaDateRangePickerProps, useDateRangePicker } from "react-aria"
import { Label as AriaLabel, type DateRange, type DateValue } from "react-aria-components"
import { createPortal } from "react-dom"
import { useTranslation } from "react-i18next"
import { useDateRangePickerState } from "react-stately"

import { QuickSelectButton } from "v2/react/shared/forms/DateInputs/DateRange/QuickSelectButton"
import QuickSelectMenu from "v2/react/shared/forms/DateInputs/DateRange/QuickSelectMenu"
import {
  RangeCalendar,
  type OnDateClick,
} from "v2/react/shared/forms/DateInputs/DateRange/RangeCalendar"
import { useCurrentDate } from "v2/react/shared/forms/DateInputs/shared/Calendar/hooks/useCalendarDateContext"
import { DateField, type DateFieldProps } from "v2/react/shared/forms/DateInputs/shared/DateField"
import { Dialog } from "v2/react/shared/forms/DateInputs/shared/Dialog"
import {
  buildDateRangeOptions,
  matchedSelectedRange,
  type DateRangeOption,
  type PresetDateRangeKey,
} from "v2/react/shared/forms/DateInputs/utils/dates"
import { InputErrorText } from "v2/react/shared/forms/InputErrorText"

interface Props {
  className?: string
  /**
   * Optional boolean will display the dialog dropdown in a portal. The benefits
   * of this is that the dialog can display outside of an overflow-hidden
   * parent. The drawback is that the focus when tabbing will not move from the
   * date input segments to the calendar month/year dropdowns
   */
  dialogInPortal?: boolean
  errorMessage?: string
  id: string
  label?: string
  /**
   * Controls whether the 'quick select' option is shown alongside the label.
   * @default true
   */
  showTimeSpanSelection?: boolean
  onDateRangeSelection?: (dateRange?: DateRange | null) => void
  /**
   * @param isOpen boolean
   * Optional function that provides external access to the isOpen value
   */
  onMenuOpenChange?: (isOpen: boolean) => void
  quickSelectOptions: DateRangeOption[]
  /**
   * If the Date Range is displayed in a more compact location, like the filters
   * panel, passing in "sm" limits the quick select menu height to 290px
   * (matching the calendar height)
   */
  size?: "sm"
  /**
   * When false, the date range picker will allow for partial range selection.
   * @default true
   */
  enforceRange?: boolean
}

type DateRangeProps<T extends DateValue> = AriaDateRangePickerProps<T> & Props

function DateRange<T extends DateValue>({
  className,
  dialogInPortal,
  errorMessage,
  id,
  showTimeSpanSelection = true,
  label,
  maxValue,
  minValue,
  onMenuOpenChange,
  quickSelectOptions,
  size,
  onDateRangeSelection,
  enforceRange = true,
  ...props
}: DateRangeProps<T>) {
  const { t } = useTranslation()
  const [clickedSegmentRef, setClickedSegmentRef] = useState<
    React.RefObject<HTMLDivElement> | undefined
  >(undefined)
  const [selectedRange, setSelectedRange] = useState("last_12_months")
  const [dialogContent, setDialogContent] = useState("quick_select")
  const [calendarFocusDate, setCalendarFocusDate] = useState<DateValue | undefined>(
    props.value?.start,
  )
  const groupRef = useRef<HTMLDivElement>(null)
  const dateRangeRef = useRef<HTMLDivElement>(null)
  const startFieldRef = useRef<HTMLDivElement>(null)
  const endFieldRef = useRef<HTMLDivElement>(null)

  const state = useDateRangePickerState(props)
  const { isOpen, setOpen } = state

  const {
    buttonProps,
    calendarProps,
    dialogProps,
    endFieldProps,
    groupProps,
    labelProps,
    startFieldProps,
  } = useDateRangePicker({ "aria-labelledby": `${id}--range-fields`, ...props }, state, groupRef)

  const dialogIsCalendar = dialogContent === "calendar"
  const dialogIsQuickSelect = dialogContent === "quick_select"
  const calendarIsOpen = isOpen && dialogIsCalendar
  const isDisabled = props.isDisabled || false

  const currentDate = useCurrentDate()
  const dateRanges = useMemo(
    () => buildDateRangeOptions(currentDate, quickSelectOptions),
    [currentDate, quickSelectOptions],
  )

  const determineSelectedRange = useCallback(
    (value: DateRange | null | undefined) => {
      if (!value || !value.start || !value.end) {
        setSelectedRange("")
      } else {
        const matchedRange = matchedSelectedRange(value.start, value.end, dateRanges)
        setSelectedRange(matchedRange || "custom_date_range")
      }
    },
    [setSelectedRange, dateRanges],
  )

  useLayoutEffect(() => {
    determineSelectedRange(state.value)
  }, [state.value, determineSelectedRange])

  const handleIsOpen = useCallback(
    (dialogIsOpen: boolean) => {
      setOpen(dialogIsOpen)
      onMenuOpenChange?.(dialogIsOpen)
    },
    [onMenuOpenChange, setOpen],
  )

  const handleClickOutside = () => {
    setDialogContent("quick_select")
    handleIsOpen(false)
    onDateRangeSelection?.(state.value)
  }

  const handleQuickSelectClick = () => {
    if (dialogIsCalendar) {
      setDialogContent("quick_select")
    } else {
      handleIsOpen(!isOpen)
    }
  }

  const handleSegmentFocus: DateFieldProps["onSegmentFocus"] = (_e, date, ref) => {
    if (!calendarIsOpen) {
      setDialogContent("calendar")
      setCalendarFocusDate(date || currentDate)
      handleIsOpen(true)
    }
    setClickedSegmentRef(ref)
  }

  const handleDateFieldChange: NonNullable<DateFieldProps["onDateFieldChange"]> = useCallback(
    (date) => setCalendarFocusDate(date || currentDate),
    [currentDate],
  )

  const handlePresetRangeSelect = (
    rangeOptionKey: PresetDateRangeKey,
    dateValue?: DateRange | null,
  ) => {
    setSelectedRange(rangeOptionKey)
    onDateRangeSelection?.(dateValue)
  }

  const handleDateRangePickerSelect = (dateRange: DateRange | null) => {
    if (!dateRange) return
    handleIsOpen(false)
    state.setDateRange(dateRange)
    onDateRangeSelection?.(dateRange)
  }

  const handleDateFieldSelection: DateFieldProps["onDateFieldSelection"] = (e) => {
    const target = e.target
    if (!(target instanceof HTMLElement)) return

    // Hitting enter on the end field should close the dialog and trigger the
    // callback.
    if (endFieldRef.current?.contains(target)) {
      target.blur()
      handleIsOpen(false)
      onDateRangeSelection?.(state.value)
    }

    // Hitting enter on the start field should transfer focus to the end field.
    if (startFieldRef.current?.contains(target)) {
      target.blur()
      // The segment is wrapped in a span, so we need to drill one more level
      // down to focus on the actual segment.
      const firstChild = endFieldRef.current?.firstElementChild?.firstElementChild
      if (firstChild instanceof HTMLElement) {
        firstChild.focus()
      }
    }
  }

  const handleDateClick: OnDateClick = (date) => {
    if (enforceRange) return

    const focusedDateField =
      startFieldRef.current &&
      clickedSegmentRef?.current &&
      startFieldRef.current.contains(clickedSegmentRef.current)
        ? "start"
        : "end"

    if (focusedDateField === "start") {
      const end = null as unknown as DateValue
      state.setValue({ start: date, end })
    } else {
      const start = null as unknown as DateValue
      state.setValue({ start, end: date })
    }
  }

  const dialog = groupRef.current && (
    <Dialog
      {...dialogProps}
      focusRefOnMount={dialogIsCalendar ? clickedSegmentRef : undefined}
      className={cn({ "box-border w-[280px]": dialogIsCalendar })}
      dialogInPortal={dialogInPortal}
      onClickOutside={handleClickOutside}
      fieldId={id}
      fieldRef={dateRangeRef}
      size={size}
      triggerCoordinates={groupRef.current?.getBoundingClientRect()}
    >
      {dialogIsQuickSelect && (
        <QuickSelectMenu
          quickSelectOptions={quickSelectOptions}
          selectedRange={selectedRange}
          setSelectedRange={handlePresetRangeSelect}
          setActiveRange={state.setValue}
          setDialogContent={setDialogContent}
          setIsOpen={handleIsOpen}
          dateRanges={dateRanges}
        />
      )}
      {dialogIsCalendar && (
        <RangeCalendar
          {...calendarProps}
          onDateClick={handleDateClick}
          autoFocus={false}
          minValue={minValue}
          maxValue={maxValue}
          focusedValue={calendarFocusDate}
          onFocusChange={setCalendarFocusDate}
          onChange={handleDateRangePickerSelect}
          rangeValue={state.value}
        />
      )}
    </Dialog>
  )

  return (
    <div className={cn("Date-Range relative", className)} ref={dateRangeRef} id={id}>
      <div className="mb-1 items-center gap-x-1 flex">
        <AriaLabel
          id={`${id}--range-fields`}
          className="!mr-0 font-bold text-neutral-100"
          {...labelProps}
        >
          {label}
          {showTimeSpanSelection && (selectedRange ? ": " : null)}
        </AriaLabel>
        {showTimeSpanSelection && (
          <span className="text-neutral-64">
            {selectedRange ? t(`v2.date.ranges.${selectedRange}`) : null}
          </span>
        )}
      </div>
      <div
        {...groupProps}
        ref={groupRef}
        className={cn("w-full rounded-lg bg-white flex", errorMessage ? "border--error" : "")}
      >
        <DateField
          {...startFieldProps}
          fieldRef={startFieldRef}
          inputClassName={cn("rounded-r-none", {
            "range-error focus-within:!border--main-hover": errorMessage,
            "focus-within:border--focus": !errorMessage,
            disabled: isDisabled,
          })}
          onDateFieldChange={handleDateFieldChange}
          onSegmentFocus={handleSegmentFocus}
          onDateFieldSelection={handleDateFieldSelection}
          minValue={minValue}
        />
        <DateField
          {...endFieldProps}
          fieldRef={endFieldRef}
          inputClassName={cn("rounded-none relative -left-px", {
            "range-error focus-within:!border--main-hover": errorMessage,
            "focus-within:border--focus": !errorMessage,
            disabled: isDisabled,
          })}
          onDateFieldChange={handleDateFieldChange}
          onSegmentFocus={handleSegmentFocus}
          onDateFieldSelection={handleDateFieldSelection}
          maxValue={maxValue}
        />
        <QuickSelectButton
          isActive={isOpen && dialogIsQuickSelect}
          isDisabled={isDisabled}
          showBorderOnFocus={!isOpen && !errorMessage}
          onClick={handleQuickSelectClick}
          {...buttonProps}
        />
      </div>
      {errorMessage && <InputErrorText message={errorMessage} />}
      {isOpen && dialogInPortal && createPortal(dialog, document.body)}
      {isOpen && !dialogInPortal && dialog}
    </div>
  )
}

export { DateRange }
