import axios from "axios"
import React, { useEffect, useState } from "react"

import { SsoProviderType } from "v2/react/components/company/generalSettings/types"
import { REST_API_BASE_URL } from "v2/react/constants"
import { Modal } from "v2/react/shared/overlay/Modal"

import { AuthenticationFormContainer } from "./authenticationForm/AuthenticationFormContainer"
import { LoginSettings } from "./types"

interface AuthenticationSettingsProps {
  companyId: number
  isOktaConfigured: boolean
}

function AuthenticationSettings({ companyId, isOktaConfigured }: AuthenticationSettingsProps) {
  const [editModalIsOpen, setEditModalIsOpen] = useState(false)
  const [loginSettings, setLoginSettings] = useState<LoginSettings | undefined>()

  useEffect(() => {
    const fetchLoginSettings = async () => {
      const response = await axios.get(
        `${REST_API_BASE_URL}/companies/${companyId}/login_settings.json`,
      )
      const withDefaults = loginSettingsWithDefaults(response.data)
      setLoginSettings(withDefaults)
    }

    fetchLoginSettings()
      // eslint-disable-next-line no-console
      .catch(console.error)
  }, [companyId])

  const handleEditButtonClick = () => {
    setEditModalIsOpen(true)
  }

  const closeModal = () => {
    setEditModalIsOpen(false)
  }

  const onSaveSuccess = (updatedData: LoginSettings) => {
    setLoginSettings(updatedData)
    setEditModalIsOpen(false)
  }

  return (
    <div>
      <div className="module-card" data-pjax-container="company-settings-authentication">
        <div className="module-card__header">
          <div className="module-title">{"authentication".t("general_settings")}</div>
          <button
            className="module-action company-settings-authentication-action"
            onClick={handleEditButtonClick}
            type="button"
          >
            {"Edit".t("defaults")}
          </button>
        </div>
        <div className="module-card__body authentication">
          <div className="module-card-list-item">
            <div>{"do_require_sso".t("general_settings")}</div>
            {loginSettings?.isSsoRequired ? (
              <div>{"Yes".t("defaults")}</div>
            ) : (
              <div>{"No".t("defaults")}</div>
            )}
          </div>
          <div className="module-card-list-item">
            <div>{"allowed_sso_login_methods".t("general_settings")}</div>
            <div>{providersLabel(loginSettings)}</div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={editModalIsOpen}
        onClose={closeModal}
        title={"authentication".t("general_settings")}
      >
        <AuthenticationFormContainer
          companyId={companyId}
          isOktaConfigured={isOktaConfigured}
          loginSettings={loginSettings}
          onSaveSuccess={onSaveSuccess}
        />
      </Modal>
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const loginSettingsWithDefaults = (settings: any): LoginSettings => {
  const toArray = (val?: Array<string> | null) => val || []

  return {
    isSsoRequired:
      settings?.sso_required === "yes" ||
      settings?.sso_required === true ||
      settings?.sso_required === "true" ||
      (settings?.require_sso_as_of && settings?.permitted_sso_providers?.length > 0),
    permittedSsoProviders: toArray(settings?.permitted_sso_providers) as SsoProviderType[],
    ssoAzureTenantIdRestrictions: toArray(settings?.sso_azure_tenant_id_restrictions),
    ssoGoogleDomainRestrictions: toArray(settings?.sso_google_domain_restrictions),
  }
}

const providersLabel = (loginSettings?: LoginSettings): string => {
  if (!loginSettings?.permittedSsoProviders || !loginSettings?.isSsoRequired) {
    return ""
  }
  return loginSettings.permittedSsoProviders
    .map((p) => {
      if (p === SsoProviderType.GOOGLE_SSO) {
        return "sso_provider_google".t("general_settings")
      }
      if (p === SsoProviderType.AZURE_SSO) {
        return "sso_provider_microsoft".t("general_settings")
      }
      return "sso_provider_okta".t("general_settings")
    })
    .sort()
    .join(", ")
}

export default AuthenticationSettings
