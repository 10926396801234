import dayjs from "dayjs"
import { motion } from "framer-motion"
import React from "react"
import { useTranslation } from "react-i18next"

import { Person, ProfilePanelPermissions, QueryPersonArgs } from "types/graphql"
import { opaqueDelay } from "v2/react/utils/framerAnimationVariants"
import { pjaxModalFor } from "v2/react/utils/pjax"

import { PersonHeader } from "./PersonHeader"
import { PersonModules } from "./PersonModules/PersonModules"

interface PersonTabContentProps {
  abilities: ProfilePanelPermissions
  person: Person | null
  personQuery: (params: QueryPersonArgs) => void
}
const PersonTabContent = ({ abilities, person, personQuery }: PersonTabContentProps) => {
  const { t } = useTranslation()

  return (
    <motion.div key="person" initial="hidden" animate="shown" exit="hidden" variants={opaqueDelay}>
      <PersonHeader abilities={abilities} onUpdate={personQuery} />
      {abilities.canManagePerson && person && person.invitationSentAt && !person.lastSignInAt && (
        <div className="alert alert-info mx-6">
          {t("v2.profile_panel.invitation_not_accepted_react", {
            sent: dayjs(person.invitationSentAt).format("MMM D, YYYY") || person.id,
          })}{" "}
          <a
            href={`/people/${person.id}/messages/new?invite=true`}
            data-feature="multiple_users"
            data-action="send-invitation"
            onClick={(event) => {
              event.preventDefault()
              pjaxModalFor(`/people/${person.id}/messages/new?invite=true`)
            }}
          >
            {t("v2.profile_panel.invitation_not_accepted_click_react")}
          </a>
        </div>
      )}
      <PersonModules abilities={abilities} />
    </motion.div>
  )
}

export default PersonTabContent
