import invariant from "tiny-invariant"
import { z } from "zod"

import { pjaxModalFor } from "v2/react/utils/pjax"
import { entityFromUniqueKey, idFromUniqueKey } from "v2/react/utils/uniqueKey"
import { UrlHelper } from "v2/react/utils/urls"

const ContainerTypeSchema = z.enum(["chart", "list"])

interface ViewHistoryProps {
  containerKey?: string | null
  positionId: string
}

export const handleViewHistoryModalOpen = ({ containerKey, positionId }: ViewHistoryProps) => {
  invariant(containerKey, "containerKey is required")
  const containerType = ContainerTypeSchema.parse(entityFromUniqueKey(containerKey))
  const containerId = idFromUniqueKey(containerKey)

  pjaxModalFor(UrlHelper.positionHistoryLogPath({ containerType, containerId, positionId }))
}
