import React from "react"
import { CodeBlock, tomorrowNight } from "react-code-blocks"

function FileStructure() {
  return (
    <div>
      <h1>File structure</h1>

      <section className="my-8">
        <h2>Shared Folder Structure</h2>
        <p className="my-2">
          The shared folder in our application serves as a centralized repository for reusable
          components that enhance the maintainability of our codebase. These subfolders are
          structured to facilitate easy access and consistency of components. This folder is
          organized into several subfolders, each dedicated to specific types of components:
        </p>
        <ul>
          <li>
            <code>buttons</code> contains various button components used throught the app.
          </li>
          <li>
            <code>collection</code> houses composite or container components such as menus, lists
            and drag-and-drop interfaces.
          </li>
          <li>
            <code>forms</code> includes input components and related form elements, supporting
            various input types and UI elements like error banners and text.
          </li>
          <li>
            <code>layout</code> consists of components that define the structural layout of the UI
            including cards, grids and panels.
          </li>
          <li>
            <code>loaders</code> features components that provide visual feedback during data
            fetching or processing tasks such as loading indicators, progress bars and spinners.
          </li>
          <li>
            <code>navigation</code> contains navigation-related components including links and page
            navigation elements.
          </li>
          <li>
            <code>overlay</code> includes components that appear on top of other content such as
            modals and tooltips, providing layered interactions.
          </li>
          <li>
            <code>status</code> focuses on components that convy status or feedback to users such as
            success, error and other state indicators.
          </li>
        </ul>
      </section>

      <section className="my-8">
        <h2>Component Folder Structure</h2>

        <ul>
          <li>
            Components that use subfiles should live in a folder with an index file, and follow the
            &quot;barrel file&quot; approach (there are benefits/drawbacks to this, including
            performance of import issues). See example below.
          </li>
          <li>Folder names should be lowercase, unless the folder houses a component</li>
          <li>
            Components that are reusable as general, design-level elements that are shared across
            the application live in the <code>/webpack/v2/react/shared</code>folder.
          </li>
          <li>
            Generally, components live under a domain/feature-specific folder within the components
            directory, e.g. <code>/webpack/v2/react/components/positionTypes/MuComponent</code>
          </li>
          <li>Page level/root components will live in a separate folder (future implementation)</li>
          <li>
            Resource/Influence:{" "}
            <a href="https://react-spectrum.adobe.com/react-aria/components.html">
              Adobe React Aria
            </a>
          </li>
        </ul>
        <CodeBlock
          text={componentFolderStructureExample}
          theme={tomorrowNight}
          language="javascript"
        />
      </section>

      <section className="my-8">
        <h2>Styles Folder Structure</h2>

        <p>
          Our styles&apos; file structure is organized to help you quickly find your desired style.
        </p>
        <p className="mb-2">
          The application&apos;s <code>app/assets/styles</code>folder structure has been broken down
          to the following:
        </p>

        <CodeBlock
          text={stylesFolderStructureExample}
          theme={tomorrowNight}
          language="javascript"
        />

        <h3 className="mt-2">application.css</h3>

        <p>
          All of the files will be called in this main CSS file. GulpJS will then take everything in{" "}
          <code>main.scss</code>, add prefixes, compiles it to CSS, and minify it.
        </p>
      </section>
    </div>
  )
}

export default FileStructure

const componentFolderStructureExample = `  myFeature/MyComponent
  |- hooks
  |- tests
  |- testUtils
  |- types
  |- utils
  ASubComponentWithManyFiles
  ASubComponent.tsx
  index.ts
  index.stories.tsx
  aTestFile.test.ts`

const stylesFolderStructureExample = `  styles
  |- sass-partials
  |--- mixins
  |--- variables
  |- _css-component-name.scss
  |- _css-component-name.scss
  application.scss`
