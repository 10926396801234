import classNames from "classnames"
import React from "react"

interface Props {
  icon?: React.ReactNode
  iconSrc?: string
  iconAlt?: string
  onClick?: () => void
  text: string
  className?: string
  disabled?: boolean
}

export function PositionMenuButton({
  icon,
  iconSrc,
  iconAlt,
  onClick,
  text,
  className,
  disabled = false,
}: Props) {
  const handleButtonClick = () => {
    if (onClick) onClick()
  }
  return (
    <button
      disabled={disabled}
      className={classNames(
        `button-icon-grid justify-start bg-white py-1 text-start ${disabled && "disabled"}`,
        className,
      )}
      onClick={handleButtonClick}
      type="button"
    >
      {icon ? (
        <div className="justify-self-center">{icon}</div>
      ) : (
        <img
          src={iconSrc}
          alt={iconAlt}
          style={{ width: "1rem" }}
          className="justify-self-center"
        />
      )}
      <p>{text}</p>
    </button>
  )
}
