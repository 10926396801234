import { width as originalWidth, svgPathData } from "@fortawesome/pro-regular-svg-icons/faEllipsisH"

import { TextColors } from "v2/color_coding/color_coder"

export const dimensions = {
  width: 14,
  height: 14,
}

const scale = dimensions.width / originalWidth

// Define an SVG icon that is used next to the children count in a node stats
// bars (bottom of the card)
const defineEllipsisIcon = (svg: d3.Selection<SVGElement>): d3.Selection<SVGElement> => {
  svg
    .select("defs")
    .append("path")
    .attr("id", "ellipsis-icon")
    .attr("d", svgPathData)
    .attr("viewBox", `0 0 ${dimensions.width} ${dimensions.height}`)
    .attr("transform", `scale(${scale})`)
    .attr("fill", TextColors.darkText.light)
  svg
    .select("defs")
    .append("path")
    .attr("id", "ellipsis-icon-light")
    .attr("d", svgPathData)
    .attr("viewBox", `0 0 ${dimensions.width} ${dimensions.height}`)
    .attr("transform", `scale(${scale})`)
    .attr("fill", TextColors.lightText.light)

  return svg
}

export default defineEllipsisIcon
