import React from "react"

import PageNav from "v2/react/shared/navigation/PageNav"
import { LinkBlock } from "v2/react/shared/navigation/PageNav/LinkBlock"
import { Link, LinkGroup } from "v2/react/shared/navigation/PageNav/LinkGroup"
import { TitleBlock } from "v2/react/shared/navigation/PageNav/TitleBlock"

interface Props {
  links: Link[]
  title: string
}

function SettingsNav({ links, title }: Props) {
  return (
    <PageNav>
      <TitleBlock>
        <h1>{title}</h1>
      </TitleBlock>
      <LinkBlock>
        <LinkGroup links={links} />
      </LinkBlock>
    </PageNav>
  )
}

export { SettingsNav }
