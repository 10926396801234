import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

import { ArrowNavigatorButton } from "v2/react/components/orgChart/ProfilePanel/ArrowNavigatorButton"

type ItemWithId<T> = T & { id: string }

interface ArrowNavigatorProps<T> {
  items: ItemWithId<T>[]
  disableButtons: boolean
  selectItem: (item: T) => void
  currentItemIndex: number
  tooltipLookup: (item: ItemWithId<T>) => string
}

function ArrowNavigator<T>({
  items,
  disableButtons,
  selectItem,
  tooltipLookup,
  currentItemIndex,
}: ArrowNavigatorProps<T>) {
  const prevIndex = (currentItemIndex - 1 + items.length) % items.length
  const nextIndex = (currentItemIndex + 1) % items.length

  const handleSelect = (item: T) => selectItem(item)

  return (
    <div className="btn-group ml-auto flex">
      <ArrowNavigatorButton
        disableButton={disableButtons}
        onClick={() => handleSelect(items[prevIndex])}
        tooltipContent={tooltipLookup(items[prevIndex])}
        classes="!rounded-r-none"
      >
        <FontAwesomeIcon className="m-0" icon={["far", "chevron-left"]} />
      </ArrowNavigatorButton>
      <ArrowNavigatorButton
        disableButton={disableButtons}
        onClick={() => handleSelect(items[nextIndex])}
        tooltipContent={tooltipLookup(items[nextIndex])}
        classes="!rounded-l-none"
      >
        <FontAwesomeIcon className="m-0" icon={["far", "chevron-right"]} />
      </ArrowNavigatorButton>
    </div>
  )
}

export { ArrowNavigator }
