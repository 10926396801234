import React from "react"

import { Person } from "types/graphql"

export function useShowGroupByDropzone(currentPerson?: Person) {
  const serverValue = currentPerson?.settings?.headcountPlanningSettings?.showGroupByDropzone
  const [showGroupByDropzone, setShowGroupByDropzone] = React.useState(serverValue)

  React.useEffect(() => {
    setShowGroupByDropzone(serverValue)
  }, [serverValue])

  return {
    showGroupByDropzone,
    setShowGroupByDropzone,
  }
}
