import { ControlProps } from "@jsonforms/core"
import classNames from "classnames"
import React, { ChangeEvent, FC } from "react"

import { isDisabledFromSchema } from "v2/react/shared/jsonForms/JsonFormControls/utils/forms"

import { useValidation } from "./hooks/useValidation"
import { InputErrorText } from "./InputErrorText"
import { formatForJsonSchemaErrors } from "./utils/errors"

type ControlPropsSubset = Pick<
  ControlProps,
  | "config"
  | "data"
  | "enabled"
  | "handleChange"
  | "id"
  | "label"
  | "path"
  | "schema"
  | "uischema"
  | "visible"
  | "errors"
>

const JsonNumericInput: FC<ControlPropsSubset> = ({
  config,
  data,
  enabled,
  handleChange,
  id,
  label,
  path,
  schema,
  uischema,
  visible,
  errors,
}: ControlPropsSubset) => {
  const { showError, errorMessage } = useValidation({
    path,
    schema,
    submitting: config.submitting,
    schemaError: errors,
  })
  const isDisabled = isDisabledFromSchema({ enabled, schema })
  const handleChangeEvent = (event: ChangeEvent<HTMLInputElement>) => {
    // Handle decimal values that are zero leading up to other values like .001
    if (event.target.value.includes(".") && Number(event.target.value) === 0) {
      handleChange(path, event.target.value)
      return
    }
    handleChange(path, Number(event.target.value))
  }

  if (!visible) return null

  return (
    <div className={classNames("input-group", { "form-error": showError })}>
      {/* Assumption: We use either a label or sublabel, not both */}
      {label && !uischema.options?.subLabel && (
        <label htmlFor={id} className="cursor-default">
          {label}
        </label>
      )}
      {uischema.options?.subLabel ? (
        <div className="gap-4 flex">
          <input
            className="input"
            disabled={isDisabled}
            id={id}
            min={0}
            name={id}
            onChange={handleChangeEvent}
            type="number"
            value={data || ""}
          />
          <span
            className={classNames("items-center flex", {
              "text-status-critical": showError,
            })}
          >
            {uischema.options?.subLabel}
          </span>
        </div>
      ) : (
        <input
          className="input"
          disabled={isDisabled}
          id={id}
          min={0}
          name={id}
          onChange={handleChangeEvent}
          type="number"
          value={data || ""}
        />
      )}
      <InputErrorText message={formatForJsonSchemaErrors(errorMessage)} showError={showError} />
    </div>
  )
}

export { JsonNumericInput }
