import { Link } from "v2/react/shared/navigation/PageNav/LinkGroup"

const hasSingularOrEmptyLinks = (links: Link[]) => {
  if (links.length <= 1) return true
  const shownLinksCount = links.reduce(
    (count: number, link: Link) => (link.show ? count + 1 : count),
    0,
  )
  if (shownLinksCount <= 1) return true
  return false
}

export { hasSingularOrEmptyLinks }
