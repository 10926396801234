import axios from "axios"
import cn from "classnames"
import React from "react"

import { StartOrgChartExportInput } from "types/graphql"
import { Modal } from "v2/react/shared/overlay/Modal"
import { useStartOrgChartExportMutation } from "v2/redux/GraphqlApi/OrgChartApi"
import {
  chartSectionSelectors,
  selectChartSectionsTree,
} from "v2/redux/slices/ContainerSlice/containerSelectors"
import { ChartSectionTreeNode } from "v2/redux/slices/ContainerSlice/types"
import { useAppSelector } from "v2/redux/store"

import { ChooseColumnsListFormInner } from "./ChooseColumnsListForm"

type Props = {
  isOpen: boolean
  onClose: () => void
  canViewMetrics: boolean
}
export function ChartExportModal({ isOpen, onClose, canViewMetrics }: Props) {
  const [fileType, setFileType] = React.useState("pdf")

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={"Export".t("org_chart")}>
      {fileType === "csv" ? (
        <CsvExportForm fileType={fileType} setFileType={setFileType} onClose={onClose} />
      ) : (
        <ChartExportForm
          fileType={fileType}
          setFileType={setFileType}
          onClose={onClose}
          canViewMetrics={canViewMetrics}
        />
      )}
    </Modal>
  )
}

function ChartExportForm({
  fileType,
  setFileType,
  onClose,
  canViewMetrics,
}: {
  fileType: string
  setFileType: (fileType: string) => void
  onClose: () => void
  canViewMetrics: boolean
}) {
  const chartSectionsTree = useAppSelector(selectChartSectionsTree)
  const totalChartSections = useAppSelector(chartSectionSelectors.selectTotal)
  const [mutate, mutation] = useStartOrgChartExportMutation()
  const [chartType, setChartType] = React.useState("current")
  const [options, setOptions] = React.useState<string[]>([])
  const [selectedChartSectionIds, setSelectedChartSectionIds] = React.useState<string[]>([])
  const [chartSectionError, setChartSectionError] = React.useState(false)

  const handleChartTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChartType(e.target.value)
  }

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setOptions((options) => [...options, e.target.name])
    } else {
      setOptions((options) => options.filter((option) => option !== e.target.name))
    }
  }

  const handleSelectedChartSectionIdsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChartSectionError(false)
    if (e.target.checked) {
      setSelectedChartSectionIds((ids) => [...ids, e.target.value])
    } else {
      setSelectedChartSectionIds((ids) => ids.filter((id) => id !== e.target.value))
    }
  }

  const handleSubmit = () => {
    setChartSectionError(false)

    let effectiveSelectedSectionIds = selectedChartSectionIds
    let effectiveChartType = chartType

    const chartViewOptions = window.App.OrgChart.getChartViewOptions()
    const currentViewExport = chartType === "current"

    // Treat current view of chart sections as a regular chart sections export.
    if (currentViewExport && chartViewOptions.type === "chart_section" && chartViewOptions.id) {
      effectiveSelectedSectionIds = [chartViewOptions.id]
      effectiveChartType = "chart_sections"
    }

    const input: StartOrgChartExportInput = {
      type: effectiveChartType,
      showDate: options.includes("show_date"),
      showMetrics: options.includes("show_metrics"),
      fitToPage: options.includes("fit_to_page"),
      showChartDetails: options.includes("show_chart_details"),
      chartSectionIds: effectiveSelectedSectionIds,
      fileType,
      currentViewExport,
      collapsedNodeIds: currentViewExport ? window.App.OrgChart.chart.getCollapsedNodeIds() : [],
    }

    if (effectiveChartType === "current" && chartViewOptions.id) {
      input.chartViewType = chartViewOptions.type
      input.chartViewId = chartViewOptions.id
    }

    if (effectiveChartType === "chart_sections" && effectiveSelectedSectionIds.length === 0) {
      setChartSectionError(true)
      return
    }

    mutate({ input })
      .unwrap()
      .then((response) => {
        if (response.startOrgChartExport.started) {
          window.$.pjax({
            url: "/orgchart/export_status",
            container: ".org-chart-status",
            push: false,
          }).done(() => window.$(".org-chart-status").addClass("slide-in"))
          onClose()
        }
      })
  }

  const allIds = React.useMemo(
    () =>
      chartSectionsTree.flatMap((chartSection) => [chartSection.id, ...chartSection.descendantIds]),
    [chartSectionsTree],
  )

  const allChartSectionsSelected = selectedChartSectionIds.length === allIds.length

  const handleSelectAll = () => {
    if (allChartSectionsSelected) {
      setSelectedChartSectionIds([])
    } else {
      setSelectedChartSectionIds(allIds)
    }
  }

  return (
    <>
      <div className="react-modal__body">
        <FileTypeInput fileType={fileType} setFileType={setFileType} />

        <div className="input-group with-hidden-options chart-type">
          <label>{"What would you like to export?".t("org_chart")}</label>
          <label className="radio">
            <input
              type="radio"
              value="current"
              checked={chartType === "current"}
              onChange={handleChartTypeChange}
            />
            {"Current View".t("org_chart")}
          </label>
          <label className="radio">
            <input
              type="radio"
              value="full"
              checked={chartType === "full"}
              onChange={handleChartTypeChange}
            />
            {"Full Org Chart".t("org_chart")}
          </label>
          {totalChartSections > 0 ? (
            <>
              <label className="radio">
                <input
                  type="radio"
                  value="chart_sections"
                  checked={chartType === "chart_sections"}
                  onChange={handleChartTypeChange}
                />
                {"One or More Chart Sections".t("org_chart")}
              </label>
              <div id="chart-section-checkboxes">
                <div
                  id="chart-section-error"
                  className={cn("form-error", {
                    hidden: !chartSectionError,
                  })}
                >
                  <div className="form-error-message input-help-text">
                    {"Select one or more chart sections".t("org_chart")}
                  </div>
                </div>
                <div className={cn("card mt-1", { hidden: chartType !== "chart_sections" })}>
                  <div className="max-h-56 overflow-y-hidden hover:overflow-y-auto">
                    <div className="section">
                      <button
                        type="button"
                        className="link select-all-button mb-1 p-0"
                        id="select-all-chart-sections"
                        onClick={handleSelectAll}
                      >
                        {allChartSectionsSelected
                          ? "Deselect All".t("org_chart")
                          : "Select All".t("org_chart")}
                      </button>
                      {chartSectionsTree.map((chartSection) => (
                        <ChartSectionCheckbox
                          key={chartSection.id}
                          chartSection={chartSection}
                          onChange={handleSelectedChartSectionIdsChange}
                          setSelectedChartSectionIds={setSelectedChartSectionIds}
                          selectedChartSectionIds={selectedChartSectionIds}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <div className="input-group export-options mb-0">
          <label>{"Additional Options".t("org_chart")}</label>
          <label className="checkbox">
            <input
              type="checkbox"
              name="show_date"
              checked={options.includes("show_date")}
              onChange={handleOptionChange}
            />
            {"Include date".t("org_chart")}
          </label>
          <label className="checkbox">
            <input
              type="checkbox"
              name="show_chart_details"
              checked={options.includes("show_chart_details")}
              onChange={handleOptionChange}
            />
            {"Include chart details".t("org_chart")}
          </label>
          {canViewMetrics ? (
            <label className="checkbox">
              <input
                type="checkbox"
                name="show_metrics"
                checked={options.includes("show_metrics")}
                onChange={handleOptionChange}
              />
              {"Include key metrics".t("org_chart")}
            </label>
          ) : null}
          <label className="checkbox">
            <input
              type="checkbox"
              name="fit_to_page"
              checked={options.includes("fit_to_page")}
              onChange={handleOptionChange}
            />
            {"Fit to page".t("org_chart")}
          </label>
        </div>
      </div>
      <div className="react-modal__footer">
        <button
          type="button"
          className="btn--large btn--primary"
          onClick={handleSubmit}
          disabled={mutation.isLoading}
        >
          {"Export".t("org_chart")}
        </button>
      </div>
    </>
  )
}

type ChartSectionCheckboxProps = {
  chartSection: ChartSectionTreeNode
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  setSelectedChartSectionIds: React.Dispatch<React.SetStateAction<string[]>>
  selectedChartSectionIds: string[]
}
function ChartSectionCheckbox({
  chartSection,
  onChange,
  setSelectedChartSectionIds,
  selectedChartSectionIds,
}: ChartSectionCheckboxProps) {
  const selfAndDescendantIds = [chartSection.id, ...chartSection.descendantIds]
  const selfAndChildrenSelected = selfAndDescendantIds.every((id) =>
    selectedChartSectionIds.includes(id),
  )

  const handleSelectAll = () => {
    if (selfAndChildrenSelected) {
      setSelectedChartSectionIds((ids) => ids.filter((id) => !selfAndDescendantIds.includes(id)))
    } else {
      setSelectedChartSectionIds((ids) => {
        const allIds = [...ids, ...selfAndDescendantIds]
        return Array.from(new Set(allIds))
      })
    }
  }

  return (
    <div className="section">
      <div className="flex">
        <div className="group items-center gap-2 flex">
          <label className="checkbox checkbox__with-sub-selection !m-0">
            <input
              type="checkbox"
              name="chart_section_ids"
              value={chartSection.id}
              checked={selectedChartSectionIds.includes(chartSection.id)}
              onChange={onChange}
            />
            {chartSection.name}
          </label>
          {chartSection.children.length > 0 ? (
            <button
              type="button"
              className="link select-all-button group-hover:translate-z-0 p-0 opacity-0 transition-all group-hover:opacity-100"
              onClick={handleSelectAll}
            >
              {selfAndChildrenSelected
                ? "Deselect All".t("org_chart")
                : "Select All".t("org_chart")}
            </button>
          ) : null}
        </div>
      </div>
      {chartSection.children.length > 0 ? (
        <div className="ml-2">
          {chartSection.children.map((childSection) => (
            <ChartSectionCheckbox
              key={childSection.id}
              chartSection={childSection}
              onChange={onChange}
              setSelectedChartSectionIds={setSelectedChartSectionIds}
              selectedChartSectionIds={selectedChartSectionIds}
            />
          ))}
        </div>
      ) : null}
    </div>
  )
}

function CsvExportForm({
  fileType,
  setFileType,
  onClose,
}: {
  fileType: string
  setFileType: (fileType: string) => void
  onClose: () => void
}) {
  const [selectedExportColumns, setSelectedExportColumns] = React.useState<string[]>([])
  const [isSaving, setIsSaving] = React.useState(false)

  const handleSubmit = async () => {
    setIsSaving(true)
    const data = {
      chartOptions: window.App.OrgChart.chart.visiblePositionsJSON(),
      fields: selectedExportColumns,
    }
    await axios.post("/api/app/v1/org_chart", data).then(() => {
      window.location.href = "/api/app/v1/org_chart.csv"
    })
    setIsSaving(false)
    onClose()
  }

  return (
    <>
      <div className="react-modal__body">
        <FileTypeInput fileType={fileType} setFileType={setFileType} />
        <ChooseColumnsListFormInner onSelectedColumnsChange={setSelectedExportColumns} />
      </div>
      <div className="react-modal__footer">
        <button
          type="button"
          className="btn--large btn--primary"
          onClick={handleSubmit}
          disabled={isSaving}
        >
          {"Export".t("org_chart")}
        </button>
      </div>
    </>
  )
}

function FileTypeInput({
  fileType,
  setFileType,
}: {
  fileType: string
  setFileType: (fileType: string) => void
}) {
  return (
    <div className="input-group">
      <label>{"What format".t("org_chart")}</label>

      <label className="radio">
        <input
          type="radio"
          name="file_type"
          value="pdf"
          checked={fileType === "pdf"}
          onChange={(e) => setFileType(e.target.value)}
        />
        {"PDF".t("org_chart")}
      </label>
      <label className="radio">
        <input
          type="radio"
          name="file_type"
          value="png"
          checked={fileType === "png"}
          onChange={(e) => setFileType(e.target.value)}
        />
        {"PNG".t("org_chart")}
      </label>
      <label className="radio">
        <input
          type="radio"
          name="file_type"
          value="csv"
          checked={fileType === "csv"}
          onChange={(e) => setFileType(e.target.value)}
        />
        {"CSV".t("org_chart")}
      </label>
    </div>
  )
}
