import { Maybe } from "graphql/jsutils/Maybe"
import React, { useRef } from "react"

import { DraggableList } from "v2/react/shared/collection/DraggableList/DraggableList"

import { OrderedListItem } from "./OrderListItem"

export interface ListItem {
  disable: Maybe<string> | undefined
  index: number
  label: string
  thumbUrl?: Maybe<string> | undefined
  value: string
}

interface OrderedListProps {
  draggableListClasses?: string
  handleValues: (items: ListItem[]) => void
  listClasses: string
  onRemove: (removedItem: ListItem) => void
  values: ListItem[]
}
function OrderedList({
  draggableListClasses,
  handleValues,
  listClasses,
  onRemove,
  values,
}: OrderedListProps) {
  const dragContainerRef = useRef(null)

  const onItemRemoved = (item: ListItem) => {
    if (onRemove && item) onRemove(item)
  }

  const createListItem = (item: ListItem, index: number) => (
    <OrderedListItem
      isFirst={index === 0}
      isLast={index === values.length - 1}
      item={item}
      key={item.value}
      onItemRemoved={onItemRemoved}
    />
  )

  const createDraggableListItem = (item: ListItem, index: number) => (
    <DraggableList.Item<ListItem> key={item.value} item={item} dragContainerRef={dragContainerRef}>
      <OrderedListItem
        isFirst={index === 0}
        isLast={index === values.length - 1}
        item={item}
        // Assumed to be unique (we skip duplicates in the logic)
        key={item.value}
        onItemRemoved={onItemRemoved}
      />
    </DraggableList.Item>
  )

  if (!values.length) return null

  return (
    <div className={`OrderedList ${listClasses}`}>
      <DraggableList<ListItem>
        items={values}
        handleReorder={handleValues}
        dragContainerRef={dragContainerRef}
        draggableListClasses={draggableListClasses}
      >
        {values.map((item: ListItem, index: number) =>
          item.disable ? createListItem(item, index) : createDraggableListItem(item, index),
        )}
      </DraggableList>
    </div>
  )
}

export { OrderedList }
