import { pathOr } from "lodash/fp"
import React, { Ref, useMemo } from "react"

import { Option } from "types/graphql"

import { Select } from "./Select"

interface FormSelectProps {
  id?: string
  name?: string
  label?: string
  options: Option[]
  required?: boolean
  selected?: string
  placeholderOption?: Option
  noneOption?: Option
  selectRef?: Ref<HTMLSelectElement>
  onSelect?: (event: React.ChangeEvent<HTMLSelectElement>) => void
  disabled?: boolean
}

export const FormSelect = ({
  id,
  name,
  label,
  options,
  required,
  selected,
  placeholderOption,
  noneOption,
  selectRef,
  onSelect,
  disabled,
}: FormSelectProps) => {
  const defaultSelected = useMemo(() => {
    if (selected) {
      return selected
    }
    if (placeholderOption) {
      return placeholderOption.id
    }
    return pathOr("", [0, "id"], options)
  }, [selected, placeholderOption, options])

  const selectOptions = useMemo(() => {
    let newOptions = [...options]
    if (placeholderOption) {
      newOptions = [placeholderOption, ...newOptions]
    }
    if (noneOption) {
      newOptions = [...newOptions, noneOption]
    }
    return newOptions
  }, [placeholderOption, noneOption, options])

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => onSelect && onSelect(event)

  return (
    <div className="input-group">
      <label htmlFor={id}>
        {label}
        {required && "*"}
      </label>
      <Select
        id={id}
        name={name}
        ref={selectRef}
        options={selectOptions}
        required={required}
        selected={defaultSelected}
        disabled={disabled}
        onSelect={handleSelect}
      />
    </div>
  )
}
