import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

interface PreviewProps {
  onClose: (event: React.MouseEvent) => void
  showPreview: boolean
  title: string
  url: string
}

const PreviewContainer = ({ onClose, showPreview, title, url }: PreviewProps) => {
  if (!showPreview) return null

  return (
    <div className="preview-container">
      <div className="preview-toolbar">
        <h3 className="preview-title">{title}</h3>

        {DownloadLink(url)}

        <button
          className="modal-close-icon cursor-pointer bg-transparent"
          type="button"
          onClick={onClose}
        >
          <FontAwesomeIcon icon={["far", "times"]} />
        </button>
      </div>

      <div className="preview-source">
        <div className="preview-source-content">
          {isImgUrl(title) ? (
            <img src={url} alt={title} />
          ) : (
            <div className="preview-source-download-link">{DownloadLink(url)}</div>
          )}
        </div>
      </div>
    </div>
  )
}

const DownloadLink = (url: string): React.ReactElement => (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    className="preview-action items-center flex"
  >
    <FontAwesomeIcon icon={["far", "download"]} />
    {"download".t("button_defaults")}
  </a>
)

const isImgUrl = (title: string): boolean => /\.(jpg|jpeg|png|webp|avif|gif)$/.test(title)

export { PreviewContainer }
