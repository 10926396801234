import { ControlProps, JsonSchema } from "@jsonforms/core"
import { useJsonForms } from "@jsonforms/react"
import React from "react"

import { Option } from "types/graphql"

import { JsonCurrencyInput } from "./CurrencyControl"
import { JsonNumericInput } from "./NumericControl"
import { JsonSelectInput } from "./SelectControl"
import { compensationLabelPrefix } from "./utils/compensation"

type BasePayTypeData = {
  amount: number | null
  hoursPerWeek: number | null
  id: string
  name: string
  payType: { id: string; name: string }
}
type ControlPropsSubset = Pick<
  ControlProps,
  "config" | "data" | "handleChange" | "label" | "schema" | "uischema" | "errors"
>

const JsonBasePayInput = ({
  config,
  data,
  handleChange,
  label,
  schema,
  uischema,
  errors,
}: ControlPropsSubset) => {
  const context = useJsonForms()
  const formData = context?.core?.data

  if (!data) return null

  const handleTypeChangeEvent = (value: Option) => {
    if (!data) return

    // When the type is changed, reset the hoursPerWeek when salaried.
    const updatedItem: BasePayTypeData =
      value.id === "position_base_pay_type_hourly"
        ? { ...data, payType: { id: "position_base_pay_type_hourly", name: "Hourly" } }
        : {
            ...data,
            payType: { id: "position_base_pay_type_salary", name: "Salary" },
          }

    // When the type is changed, reset the value to zero.
    if (data.payType.id !== value.id) {
      updatedItem.amount = 0
    }
    handleChange("position.basePay", updatedItem)
  }

  const handleAmountChangeEvent = (value: number | null) =>
    handleChange("position.basePay.amount", value)

  const handleHoursChangeEvent = (value: number | null) =>
    handleChange("position.basePay.hoursPerWeek", value)

  return (
    <>
      <div className="mb-2 font-bold">
        {compensationLabelPrefix(formData)} {label}
      </div>
      <div className="items-top flex" key={data.id}>
        <div className="basis-1/3">
          <JsonSelectInput
            config={config}
            errors={errors}
            data={{ id: data.payType.id, name: data.payType.name }}
            enabled={formData.reqType !== "backfill"}
            handleChange={(_path, value) => handleTypeChangeEvent(value)}
            id="base-pay-type"
            label=""
            path="position.basePay.payType"
            schema={schema.properties?.payType as JsonSchema}
            uischema={{
              ...uischema,
              options: {
                ...uischema.options,
                fieldKey: "position_base_pay_type",
                allowCustomInput: false,
              },
            }}
          />
        </div>
        <div className="basis-1/3 pl-2">
          {/* Important that the schema aligns with the path given */}
          <JsonCurrencyInput
            config={config}
            errors={errors}
            data={data.amount}
            enabled={formData.reqType !== "backfill"}
            handleChange={(_path: string, value: number | null) => handleAmountChangeEvent(value)}
            id="base-pay-amount"
            label=""
            path="position.basePay.amount"
            schema={schema.properties?.amount as JsonSchema}
          />
        </div>
        <div className="basis-1/3 pl-2">
          {data.payType.id === "position_base_pay_type_hourly" && (
            <div>
              <JsonNumericInput
                config={config}
                errors={errors}
                data={data.hoursPerWeek}
                enabled={formData.reqType !== "backfill"}
                handleChange={(_path: string, value: number | null) => {
                  handleHoursChangeEvent(value)
                }}
                id="base-pay-hours-per-week"
                label=""
                path="position.basePay.hoursPerWeek"
                schema={schema.properties?.hoursPerWeek as JsonSchema}
                uischema={{ ...uischema, options: { ...uischema.options, subLabel: "hrs/wk" } }}
                visible={data.payType.id === "position_base_pay_type_hourly"}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export { JsonBasePayInput }
